import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export function withRouter<ComponentProps>(
  Component: React.FunctionComponent<ComponentProps>,
) {
  function ComponentWithRouterProp(props: ComponentProps) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();

    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

//https://stackoverflow.com/questions/71097375/how-can-i-use-withrouter-in-react-router-v6-for-passing-props
