import { create } from "zustand";

import { OktaValidationState } from "../../types/okta/store";

import { oktaValidationSlice } from "./oktaSlice";

const useOktaValidationStore = create<OktaValidationState>()((...a) => ({
  ...oktaValidationSlice(...a),
}));

export default useOktaValidationStore;
