import React, { useEffect, useMemo } from "react";

import { FormHelperText, TextField } from "@mui/material";

import { SearchCriteria } from "../../../../types/common/search";
import { UWITabPanelType } from "../../../../types/panels/searchPanel/search";

import { ATTRIBUTES_TAB } from "../../../../constants/constants";
import { RECORD_TYPES } from "../../../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { SEARCH_TYPES } from "../../../../constants/panels/searchPanel/search";
import {
  UWI_SEARCH_FIELD_ERROR,
  UWI_SEARCH_FILE_FORMAT_ERROR,
} from "../../../../constants/validation";

import usePanelsStore from "../../../../store/panels/panelsStore";
import useUWISearchStore from "../../../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../../../store/useStore";

import useSearchPanelActions from "../../../../customHooks/search/useSearchPanelActions";
import useUwiChangeHandler from "../../../../customHooks/search/uwi/useUwiChangeHandler";

import { createRecordTypeBounds } from "../../../../utils/common/boundsData";

import LoadingBackDrop from "../../../common/LoadingBackDrop";
import ActionButtons from "../common/ActionButtons";
import ExpectedCount from "../common/ExpectedCount";
import TabPanel from "../common/TabPanel";
import UWIUploadBox from "./UWIUploadBox";

const UWITabPanel: React.FC<UWITabPanelType> = ({ hidden }) => {
  const {
    searchCallback,
    resetCallback,
    resetAttribsPolygons,
    hasLoadedUWISavedSearch,
    hasLoadedAttribSavedSearch,
    uwiSearchType,
  } = useSearchPanelActions();

  const lastSearchedBy = useStore((state) => state.lastSearchedBy);
  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);
  const updateSavedSearchFileName = useStore(
    (state) => state.updateSavedSearchFileName
  );
  const updateActiveSearchPanelTab = usePanelsStore(
    (state) => state.updateActiveSearchPanelTab
  );
  const activeSearchPanelTab = usePanelsStore(
    (state) => state.activeSearchPanelTab
  );

  // Search UWI
  const isLoadingUwiFileUpload = useUWISearchStore(
    (state) => state.isLoadingUwiFileUpload
  );

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const updateUploadedUWIFile = useUWISearchStore(
    (state) => state.updateUploadedUWIFile
  );
  const uploadedUwiFileId = useUWISearchStore(
    (state) => state.uploadedUwiFileId
  );

  const searchUWIText = useUWISearchStore((state) => state.searchUWIText);
  const isFileFormatNotAllowed = useUWISearchStore(
    (state) => state.isFileFormatNotAllowed
  );
  const setIfFileUploadFormatNotAllowed = useUWISearchStore(
    (state) => state.setIfFileUploadFormatNotAllowed
  );
  const isValidUwiFile = useUWISearchStore((state) => state.isValidUwiFile);
  const setIsValidUwiFile = useUWISearchStore(
    (state) => state.setIsValidUwiFile
  );
  const isValidatingUWITexts = useUWISearchStore(
    (state) => state.isValidatingUWITexts
  );
  const hasErrorUploadingUWI = useUWISearchStore(
    (state) => state.hasErrorUploadingUWI
  );
  const uwisToSearch = useUWISearchStore((state) => state.uwisToSearch);

  const updateIsFromUploadedUWI = useUWISearchStore(
    (state) => state.updateIsFromUploadedUWI
  );
  const isInvalidUWIText = useUWISearchStore((state) => state.isInvalidUWIText);
  const isLoadingUwiExpectedCount = useUWISearchStore(
    (state) => state.isLoadingUwiExpectedCount
  );
  const resetUWIsToSearch = useUWISearchStore(
    (state) => state.resetUWIsToSearch
  );
  const resetUWIStates = useUWISearchStore((state) => state.resetUWIStates);

  const { handleUwiTextOnChange } = useUwiChangeHandler();

  const isSearchDisabled = useMemo(
    () =>
      (!uwisToSearch.length && !uploadedUWIFile?.name) ||
      (isInvalidUWIText && !!uploadedUWIFile) ||
      isLoadingUwiFileUpload,
    [uwisToSearch, isInvalidUWIText, uploadedUWIFile, isLoadingUwiFileUpload]
  );

  const isUWIUploadError = useMemo(
    () =>
      !searchUWIText &&
      !!uploadedUWIFile &&
      !isValidUwiFile &&
      !isLoadingUwiFileUpload,
    [searchUWIText, uploadedUWIFile, isValidUwiFile, isLoadingUwiFileUpload]
  );

  useEffect(() => {
    if (!searchUWIText.length && !uploadedUwiFileId && !isValidUwiFile) {
      setIsValidUwiFile(false);
    }
  }, [isValidUwiFile, uploadedUwiFileId, searchUWIText, activeSearchPanelTab]);

  const handleSearchUWI = () => {
    const newSearchCriteria: Partial<SearchCriteria> = {
      drawnPolygons: [],
      polygonType: "",
      currentBounds: [createRecordTypeBounds(RECORD_TYPES.WELLS_AND_PERMIT)],
      searchedUWIs: uwisToSearch,
      shapeId: "",
      fileId: uploadedUwiFileId ?? "",
    };

    searchCallback(newSearchCriteria, !hasLoadedAttribSavedSearch);

    if (uploadedUwiFileId) updateIsFromUploadedUWI(true);

    updateLastSearchedBy(uwiSearchType);
    resetAttribsPolygons();
  };

  const handleResetUploadErrorOnTextFieldClicked = (
    e: React.MouseEvent<HTMLDivElement>
  ) => {
    if (isInvalidUWIText && uploadedUwiFileId && !!uploadedUWIFile) return;
    if ((isInvalidUWIText && !!uploadedUWIFile) || isFileFormatNotAllowed) {
      updateUploadedUWIFile(null);
      setIfFileUploadFormatNotAllowed(false);
      setIsValidUwiFile(false);
    }

    const target = e.currentTarget as HTMLDivElement;
    if (!target.innerText && target.innerText !== undefined)
      resetUWIsToSearch();
  };

  const handleResetUWI = () => {
    updateSavedSearchFileName("");
    resetUWIStates();
    resetCallback(hasLoadedUWISavedSearch);
  };

  return (
    <TabPanel hidden={hidden} name="uwi">
      <div className="search-panel-body-uwi">
        <LoadingBackDrop
          isOpen={
            // do not show spinner when manually uploading a file
            !!searchUWIText &&
            (isLoadingUwiFileUpload || isLoadingUwiExpectedCount)
          }
          className="uwi-textbox loader"
        />
        <TextField
          multiline
          minRows={12}
          disabled={
            (!searchUWIText && !!uploadedUWIFile && isValidUwiFile) ||
            isLoadingUwiFileUpload ||
            isLoadingUwiExpectedCount
          }
          value={searchUWIText}
          label={"Identifiers"}
          placeholder="Comma Delimited UWI14s or UWI16s"
          onChange={(e) => handleUwiTextOnChange(e.target.value)}
          // onBlur={(e) => handleUwiTextOnBlur(e.target.value)}
          error={isInvalidUWIText}
          helperText={isInvalidUWIText && UWI_SEARCH_FIELD_ERROR}
          onClick={handleResetUploadErrorOnTextFieldClicked}
        />
        <UWIUploadBox />
        {isUWIUploadError && (
          <FormHelperText error={isUWIUploadError}>
            {UWI_SEARCH_FIELD_ERROR}
          </FormHelperText>
        )}
        {isFileFormatNotAllowed && (
          <FormHelperText error={isFileFormatNotAllowed}>
            {UWI_SEARCH_FILE_FORMAT_ERROR}
          </FormHelperText>
        )}
      </div>
      <div className="search-panel-footer">
        <div className="expected-well-counts">
          <ExpectedCount
            searchType={uwiSearchType}
            isDefaultZero={
              (!searchUWIText && !uploadedUwiFileId) ||
              (!!searchUWIText && isInvalidUWIText) ||
              (!!uploadedUwiFileId && !isValidUwiFile)
            }
          />
        </div>
        <ActionButtons
          searchType={uwiSearchType}
          isResetDisabled={
            (!uwisToSearch.length && !uploadedUWIFile?.name) ||
            (isInvalidUWIText && !uploadedUWIFile?.name) ||
            isLoadingUwiFileUpload
          }
          isSaveDisabled={isSearchDisabled}
          isSearchDisabled={
            (!uwisToSearch.length && !uploadedUwiFileId) ||
            isInvalidUWIText ||
            isLoadingUwiFileUpload ||
            hasErrorUploadingUWI ||
            isValidatingUWITexts ||
            isLoadingUwiExpectedCount
          }
          isShowPrompt={lastSearchedBy === SEARCH_TYPES.ATTRIBUTE_SEARCH}
          hasLoadedSavedSearch={hasLoadedUWISavedSearch}
          handleResetClick={handleResetUWI}
          onClickSaveCallback={resetAttribsPolygons}
          handleSearchClick={handleSearchUWI}
          handleCancelClick={() => updateActiveSearchPanelTab(ATTRIBUTES_TAB)}
        />
      </div>
    </TabPanel>
  );
};

export default UWITabPanel;
