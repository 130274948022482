import { create } from "zustand";

import { AlertMessageStore } from "../../../types/map/alertStore";

import { alertMessageSlice } from "./alertMessageSlice";

const useAlertPopupStore = create<AlertMessageStore>()((...a) => ({
  ...alertMessageSlice(...a),
}));

export default useAlertPopupStore;
