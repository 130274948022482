import FileSaver from "file-saver";
import XLSX from "sheetjs-style";

import {
  ARRAY_TYPE,
  EXCEL,
  EXPORT_TYPE,
  FILE_EXTENSION_EXCEL,
  FILE_TYPE,
} from "../../constants/constants";

import useStore from "../../store/useStore";

const useExportToExcel = () => {
  const updateToastMessage = useStore((state) => state.updateToastMessage);
  const dateTimeFormatting = (
    type: string,
    day: string,
    month: string,
    year: string,
    hour: string,
    minute: string,
    seconds: string
  ) => {
    let formattedDateTime: string;
    switch (type) {
      case EXPORT_TYPE.DATA_GRID:
        formattedDateTime = `${year}-${month}-${day}_${hour}-${minute}-${seconds}`;
        break;
      default:
        formattedDateTime = `${year}${month}${day}${hour}${minute}`;
    }
    return formattedDateTime;
  };

  const convertDateTimeToString = (data: number) => {
    return data.toString();
  };

  const formatDateTime = (data: string) => {
    return `0${data}`;
  };

  const exportToExcel = (
    excelData: any[],
    fileName: string,
    customHeaders?: string[],
    exportType: string = EXPORT_TYPE.DEFAULT
  ) => {
    const dateTime = new Date();
    const yyyy = convertDateTimeToString(dateTime.getFullYear());
    let mm = convertDateTimeToString(dateTime.getMonth() + 1);
    let dd = convertDateTimeToString(dateTime.getDate());
    let hr = convertDateTimeToString(dateTime.getHours());
    let min = convertDateTimeToString(dateTime.getMinutes());
    let ss = convertDateTimeToString(dateTime.getSeconds());

    //Adding 0 at the beginning
    if (mm.length === 1) mm = formatDateTime(mm);
    if (dd.length === 1) dd = formatDateTime(dd);
    if (hr.length === 1) hr = formatDateTime(hr);
    if (min.length === 1) min = formatDateTime(min);
    if (ss.length === 1) ss = formatDateTime(ss);

    const formattedToday = dateTimeFormatting(
      exportType,
      dd,
      mm,
      yyyy,
      hr,
      min,
      ss
    );
    const fileNameWithDate = fileName + "_" + formattedToday;

    let ws;
    let wb;

    const sheetName = "Sheet";
    if (customHeaders && customHeaders.length) {
      wb = XLSX.utils.book_new();
      ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_aoa(ws, [customHeaders]);
      XLSX.utils.sheet_add_json(ws, excelData, {
        origin: "A2",
        skipHeader: true,
      });
      XLSX.utils.book_append_sheet(wb, ws, sheetName);
    } else {
      ws = XLSX.utils.json_to_sheet(excelData);
      wb = { Sheets: { Sheet: ws }, SheetNames: [sheetName] };
    }

    const excelBuffer = XLSX.write(wb, { bookType: EXCEL, type: ARRAY_TYPE });
    const dataFile = new Blob([excelBuffer], { type: FILE_TYPE });
    FileSaver.saveAs(dataFile, fileNameWithDate + FILE_EXTENSION_EXCEL);
    updateToastMessage("Excel is now exporting");
  };
  return {
    exportToExcel,
  };
};

export default useExportToExcel;
