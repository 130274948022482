interface UWITabIconProps {
  isSelected?: boolean;
}

const AttributeTabIcon = ({ isSelected = false }: UWITabIconProps) => {
  return (
    <svg
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.5 4.5V3H10.75V0H12.25V3H14.5V4.5H8.5ZM10.75 14V6H12.25V14H10.75ZM2.75 14V11H0.5V9.5H6.5V11H4.25V14H2.75ZM2.75 8V0H4.25V8H2.75Z"
        fill={isSelected ? "#42A5F5" : "#FFFFFF"}
      />
    </svg>
  );
};

export default AttributeTabIcon;
