import { StateCreator } from "zustand";

import {
  ExportCardStateStore,
  ExportUWIDataStore,
} from "../../types/exports/export-card/exportCardState";
import { ResetExportStateStore } from "../../types/exports/export-card/resetExportCardState";

import { exportCardSlice, exportUWIDataSlice } from "./exportCardSlice";

const resetExportCardStateSlice: StateCreator<
  ResetExportStateStore & ExportCardStateStore & ExportUWIDataStore,
  [],
  [],
  ResetExportStateStore
> = (...a) => ({
  resetExportCard: () => {
    exportCardSlice(...a).updateExportCard(false);
    exportCardSlice(...a).updateIsSearchCriteriaChange(false);
  },
  resetExportUWIData: () => {
    exportUWIDataSlice(...a).updateExportUwiList([]);
  },
  resetExportStates: () => {
    exportCardSlice(...a).updateExportCard(false);
    exportCardSlice(...a).updateIsSearchCriteriaChange(false);
    exportUWIDataSlice(...a).updateExportUwiList([]);
  },
});

export { resetExportCardStateSlice };
