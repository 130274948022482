import { RECORD_TYPE } from "../../../attributes";

export const RECORD_TYPES = {
  WELL: "Well",
  PERMIT: "Permit",
  WELLS_AND_PERMIT: "Wells and Permits",
} as const;

export const initialAttributeValues = {
  [RECORD_TYPE.key]: [RECORD_TYPES.WELL],
};
