import { getTopLeft, getWidth } from "ol/extent";
import TileLayer from "ol/layer/Tile";
import { get as getProjection } from "ol/proj";
import { WMTS as oLWMTS } from "ol/source";
import WMTSTileGrid from "ol/tilegrid/WMTS";

import { Layer, WMTS } from "../../../types/map";

import { DYNAMIC_VECTOR_TILES } from "../../../constants/constants";

import { tileLoadFetcher } from "./tileUtils";

const getWMTSLayer = (
  layer: WMTS,
  layers: Layer[],
  definitionKey: string,
  token: string
) => {
  const layerDefinitionHasDVT = layers.find(
    (layer) => layer.fetchType === DYNAMIC_VECTOR_TILES
  );

  if (layerDefinitionHasDVT) return null;

  const tileCalculations = getTileCalculations(layer);
  if (tileCalculations && layer.projection) {
    const layerAttributes = {
      title: definitionKey,
      source: new oLWMTS({
        matrixSet: layer.projection,
        url: layer.url,
        tileLoadFunction: (tile, src) => {
          tileLoadFetcher(tile, src, token);
        },
        projection: tileCalculations.projection,
        tileGrid: new WMTSTileGrid({
          origin: getTopLeft(tileCalculations.projectionExtent),
          resolutions: tileCalculations.resolutions,
          matrixIds: tileCalculations.matrixIds,
        }),
        wrapX: true,
        crossOrigin: "anonymous",
        layer: "",
        style: "",
      }),
      ...(layer.maxZoom && {
        maxZoom: layer.maxZoom,
      }),
      ...(layer.zIndex && { zIndex: layer.zIndex }),
    };
    return new TileLayer(layerAttributes);
  }
};

const getTileCalculations = (layer: WMTS) => {
  const projection = getProjection(layer.projection);
  if (projection) {
    const projectionExtent = projection.getExtent();
    const size = getWidth(projectionExtent) / 256;
    const resolutions = new Array(19);
    const matrixIds = new Array(19);
    for (let z = 0; z < 19; ++z) {
      resolutions[z] = size / Math.pow(2, z);
      matrixIds[z] = `${layer.projection}:${z}`;
    }
    return { projection, projectionExtent, resolutions, matrixIds };
  }
};

export { getWMTSLayer, getTileCalculations };
