import { useMemo } from "react";

import { ATTRIBUTES_TAB, UWI_TAB } from "../../constants/constants";
import {
  SAVED_SEARCH_VISIBILITY_PRIVATE,
  SAVED_SEARCH_VISIBILITY_PUBLIC,
} from "../../constants/panels/savedSearchPanel/hooks";
import {
  SEARCH_TYPES,
  UWI_SEARCH_TYPES,
} from "../../constants/panels/searchPanel/search";

import useDataGridStore from "../../store/grid/dataGridStore";
import usePanelsStore from "../../store/panels/panelsStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../store/useStore";

import { formatToNonAutoSearchType } from "../../utils/savedSearch/savedData";

const useSearchPanel = () => {
  const selectedSavedSearchData = useStore(
    (state) => state.selectedSavedSearchData
  );
  const savedSearchFileName = useStore((state) => state.savedSearchFileName);

  const activeSearchPanelTab = usePanelsStore(
    (state) => state.activeSearchPanelTab
  );

  const uploadedUwiFileId = useUWISearchStore(
    (state) => state.uploadedUwiFileId
  );
  const isAutoConvertedFileFromText = useUWISearchStore(
    (state) => state.isAutoConvertedFileFromText
  );

  const allGridData = useDataGridStore((state) => state.allGridData);

  const nonAutoSaveSearchType = useMemo(
    () => formatToNonAutoSearchType(selectedSavedSearchData?.searchType ?? ""),
    [selectedSavedSearchData]
  );

  const hasLoadedAttribSavedSearch = useMemo(
    () => nonAutoSaveSearchType === SEARCH_TYPES.ATTRIBUTE_SEARCH,
    [nonAutoSaveSearchType]
  );

  const hasLoadedUWISavedSearch = useMemo(
    () => UWI_SEARCH_TYPES.includes(nonAutoSaveSearchType),
    [nonAutoSaveSearchType]
  );

  const uwiSearchType = useMemo(() => {
    let searchType = SEARCH_TYPES.UWI_SEARCH;
    if (isAutoConvertedFileFromText && !!uploadedUwiFileId) {
      searchType = SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH;
    } else if (!!uploadedUwiFileId) {
      searchType = SEARCH_TYPES.UWI_FILE_SEARCH;
    }
    return searchType;
  }, [isAutoConvertedFileFromText, uploadedUwiFileId]);

  const searchTypeByActiveTab = useMemo(
    () =>
      activeSearchPanelTab === ATTRIBUTES_TAB
        ? SEARCH_TYPES.ATTRIBUTE_SEARCH
        : uwiSearchType,
    [activeSearchPanelTab, uwiSearchType]
  );

  const searchTitle = useMemo(() => {
    const searchType = nonAutoSaveSearchType;

    if (
      (savedSearchFileName &&
        activeSearchPanelTab === ATTRIBUTES_TAB &&
        searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) ||
      (savedSearchFileName &&
        activeSearchPanelTab === UWI_TAB &&
        (searchType === SEARCH_TYPES.UWI_SEARCH ||
          searchType === SEARCH_TYPES.UWI_FILE_SEARCH ||
          searchType === SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH))
    ) {
      return savedSearchFileName;
    } else {
      return "SEARCH";
    }
  }, [nonAutoSaveSearchType, activeSearchPanelTab, savedSearchFileName]);

  const disableSharedSearch = useMemo(
    () => !Boolean(allGridData.length),
    [allGridData]
  );

  const isFilterIncluded = useMemo(() => {
    const checkSearchTitle = Boolean(
      (selectedSavedSearchData &&
        selectedSavedSearchData.visibility ===
          SAVED_SEARCH_VISIBILITY_PRIVATE &&
        searchTitle !== "SEARCH") ||
        (selectedSavedSearchData.visibility ===
          SAVED_SEARCH_VISIBILITY_PUBLIC &&
          searchTitle === "SEARCH")
    );

    return !selectedSavedSearchData?.searchType || checkSearchTitle;
  }, [searchTitle, selectedSavedSearchData]);

  return {
    hasLoadedAttribSavedSearch,
    hasLoadedUWISavedSearch,
    searchTitle,
    searchTypeByActiveTab,
    uwiSearchType,
    disableSharedSearch,
    isFilterIncluded,
  };
};

export default useSearchPanel;
