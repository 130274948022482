import { PRODUCTION_DATA_FIELDS } from "../../constants/charts/chartConfig";
import { CHART_GROUP_BY_TYPE } from "../../constants/charts/charts";
import {
  CDATA,
  FORECAST,
  FORECAST_CHECK,
  MDATA,
  PRODUCTION_PLOT,
  TYPE_CURVE,
} from "../../constants/constants";

import { convertStringToDate } from "./aggregator";

export const chartToExcel = (
  productionData,
  productionDataForecast,
  chartType,
  groupBy,
  chartShowDailyValues
) => {
  let excelData = [];

  const sortObj = (obj) => {
    return Object.keys(obj)
      .sort((a, b) => a - b)
      .reduce(function (result, key) {
        result[key] = obj[key];
        return result;
      }, {});
  };

  const rearrangeArrayKeys = (arr) => {
    let rearrangedArr = arr.map((item) => {
      let obj = {};
      PRODUCTION_DATA_FIELDS.forEach((field) => {
        if (chartShowDailyValues && field.hasDaily) {
          if (item[field.dailyDisplayName] !== undefined) {
            obj[field.dailyDisplayName] = item[field.dailyDisplayName];
          }
        } else {
          if (
            item[field.displayName] !== undefined ||
            (groupBy === CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS && field.alwaysOn)
          ) {
            obj[field.displayName] = item[field.displayName];
          }
        }
      });
      return obj;
    });
    return rearrangedArr;
  };

  const sortObjDateOrSequenceName = (obj) => {
    return obj.sort((a, b) => {
      if (groupBy !== CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS) {
        // Sort by name in alphabetical order
        const nameComparison = a.Name.localeCompare(b.Name);
        if (nameComparison !== 0) {
          return nameComparison;
        }
      } else {
        return a.Name - b.Name;
      }

      if (chartType === PRODUCTION_PLOT) {
        // If names are equal, sort by date in ascending order
        return new Date(a["Date"]).getTime() - new Date(b["Date"]).getTime();
      } else {
        return a["Date"] - b["Date"];
      }
    });
  };

  const headerField = (key, productionDate, uwi, wellNumber, wellName) => {
    let obj = {};
    if (groupBy === CHART_GROUP_BY_TYPE.DEFAULT) {
      if (chartType === PRODUCTION_PLOT) {
        obj = { Name: "ALL", Date: key };
      } else {
        obj = { Name: "ALL", "Sequence Month": key };
      }
    } else if (groupBy === CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS) {
      obj = { UWI: uwi, "Well Name": wellName, "Well Number": wellNumber };
      if (chartType === PRODUCTION_PLOT) {
        obj = { ...obj, Date: productionDate };
      } else {
        obj = { ...obj, "Sequence Month": productionDate };
      }
    } else {
      if (chartType === PRODUCTION_PLOT) {
        obj = { Name: key, Date: productionDate };
      } else {
        obj = { Name: key, "Sequence Month": productionDate };
      }
    }
    return obj;
  };

  const defaultData = (type, forecastDatum, key, mergeDataObj) => {
    const dataObj = {};

    Object.entries(forecastDatum).forEach(([key, value]) => {
      dataObj[`${key}-f`] = value;
    });

    const dataProp = type === MDATA ? MDATA : CDATA;

    if (key in mergeDataObj) {
      mergeDataObj[key][0][dataProp] = {
        ...mergeDataObj[key][0][dataProp],
        ...dataObj,
      };
    } else {
      mergeDataObj[key] = [{ [dataProp]: dataObj, production: key }];
    }
  };

  const groupByIndividualData = (value, key, MergeDataObj, propName) => {
    const dataObj = {};
    Object.entries(value[propName]).forEach(([propKey, propValue]) => {
      dataObj[`${propKey}-f`] = propValue;
    });

    if (MergeDataObj[key]) {
      const checkSameProductionDate = MergeDataObj[key].findIndex(
        (mergeDatum) => mergeDatum.production === value.production
      );

      if (checkSameProductionDate > -1) {
        MergeDataObj[key][checkSameProductionDate][propName] = {
          ...MergeDataObj[key][checkSameProductionDate][propName],
          ...dataObj,
        };
      } else {
        const newObj = {};
        newObj[propName] = dataObj;
        newObj["production"] = value.production;
        newObj["uwi"] = value.uwi;
        newObj["wellName"] = value.wellName;
        newObj["wellNumber"] = value.wellNumber;

        if (!MergeDataObj[key]) {
          MergeDataObj[key] = [newObj];
        } else {
          MergeDataObj[key].push(newObj);
        }
      }
    }
  };

  const otherData = (value, key, MergeDataObj) => {
    if (value.mdata) {
      groupByIndividualData(value, key, MergeDataObj, MDATA);
    }

    if (value.cdata) {
      groupByIndividualData(value, key, MergeDataObj, CDATA);
    }
  };

  function combineMonthlyData(MergeDataObj) {
    let combinedData = {};
    const checkKey = ["production", "uwi", "wellName", "wellNumber"];

    if (groupBy === CHART_GROUP_BY_TYPE.DEFAULT) {
      Object.entries(MergeDataObj).forEach(([key, value]) => {
        combinedData[key] = [value[0]];
        value.forEach((data, index) => {
          if (index !== 0) {
            Object.entries(data).forEach(([dataKey, dataValue]) => {
              if (!checkKey.includes(dataKey)) {
                combinedData[key][0][dataKey] = Object.assign(
                  {},
                  combinedData[key][0][dataKey],
                  dataValue
                );
              }
            });
          }
        });
      });
    } else {
      Object.entries(MergeDataObj).forEach(([key, value]) => {
        combinedData[key] = [];
        value.forEach((data) => {
          let checkSameSequenceMonth = combinedData[key].findIndex(function (
            mergeDatum
          ) {
            return mergeDatum.production === data.production;
          });

          if (checkSameSequenceMonth > -1) {
            Object.entries(data).forEach(function ([dataKey, dataValue]) {
              if (!checkKey.includes(dataKey)) {
                combinedData[key][checkSameSequenceMonth][dataKey] =
                  Object.assign(
                    {},
                    combinedData[key][checkSameSequenceMonth][dataKey],
                    dataValue
                  );
              }
            });
          } else {
            combinedData[key].push(data);
          }
        });
      });
    }
    return combinedData;
  }

  const MergeOfDataAndForecast = (dataObj, forecastObj) => {
    let monthly = sortObj(dataObj);
    const forecast = sortObj(forecastObj);
    const MergeDataObj = combineMonthlyData(monthly);

    if (chartType === PRODUCTION_PLOT || chartType === TYPE_CURVE) {
      Object.entries(forecast).forEach(([key, value]) => {
        if (groupBy === CHART_GROUP_BY_TYPE.DEFAULT) {
          if (value[0].mdata) {
            defaultData(MDATA, value[0].mdata, key, MergeDataObj);
          }

          if (value[0].cdata) {
            defaultData(CDATA, value[0].cdata, key, MergeDataObj);
          }
        } else {
          value.forEach((data) => {
            otherData(data, key, MergeDataObj);
          });
        }
      });

      return MergeDataObj;
    } else {
      return MergeDataObj;
    }
  };

  const processFieldData = (fieldData, dataOrigin, fieldObject) => {
    PRODUCTION_DATA_FIELDS.forEach((dataFields) => {
      Object.entries(fieldData).forEach(([fieldKey, fieldValue]) => {
        checkDateParse(fieldObject);
        const parsedFieldValue = parseFloat(fieldValue);
        if (
          fieldKey.includes(FORECAST_CHECK) &&
          (chartType === PRODUCTION_PLOT || chartType === TYPE_CURVE)
        ) {
          const name = fieldKey.replace(/-f/g, "");
          if (
            dataFields.responseDataFieldName === name &&
            dataFields.responseDataOrigin === dataOrigin &&
            dataFields.hasForecast
          ) {
            if (chartShowDailyValues && dataFields.hasDaily) {
              fieldObject[`${dataFields.dailyDisplayName} ${FORECAST}`] =
                parsedFieldValue;
            } else {
              fieldObject[`${dataFields.displayName} ${FORECAST}`] =
                parsedFieldValue;
            }
          }
        } else if (
          dataFields.responseDataFieldName === fieldKey &&
          dataFields.responseDataOrigin === dataOrigin
        ) {
          if (chartShowDailyValues && dataFields.hasDaily) {
            fieldObject[dataFields.dailyDisplayName] = parsedFieldValue;
          } else {
            fieldObject[dataFields.displayName] = parsedFieldValue;
          }
        }
      });
    });
  };

  const checkDateParse = (fieldObject) => {
    if (chartType === TYPE_CURVE) {
      fieldObject["Sequence Month"] = parseInt(fieldObject["Sequence Month"]);
    } else {
      const prodDate = fieldObject["Date"];
      fieldObject["Date"] = convertStringToDate(prodDate);
    }
    return fieldObject;
  };

  const createExcelData = (obj) => {
    const dataAndForecast = [];

    Object.entries(obj).forEach(([key, objValue]) => {
      objValue.forEach((element) => {
        let fieldObject = headerField(
          key,
          element.production,
          element.uwi,
          element.wellNumber,
          element.wellName
        );
        if (MDATA in element) {
          processFieldData(element.mdata, MDATA, fieldObject);
        }

        if (CDATA in element) {
          processFieldData(element.cdata, CDATA, fieldObject);
        }

        dataAndForecast.push(fieldObject);
      });
    });

    return dataAndForecast;
  };

  const transformerData = MergeOfDataAndForecast(
    productionData,
    productionDataForecast
  );

  if (transformerData) {
    excelData = createExcelData(transformerData);
    sortObjDateOrSequenceName(excelData);
  }

  // this will do final arrange of column based on chart settings
  const arrangeColumn = rearrangeArrayKeys(excelData);
  return arrangeColumn;
};

export default chartToExcel;
