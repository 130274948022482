import { useEffect, useMemo } from "react";

import { ProcessColors } from "../../../../types/map/mapSettings/layerColoring";

import {
  SURFACE_WELL_SPOTS,
  WELL_SPOTS,
} from "../../../../constants/constants";
import * as mapSettings from "../../../../constants/map/mapSettings";

import useMapStore from "../../../../store/map/mapStore";
import useMapSettingsStore from "../../../../store/map/settings/mapSettingsStore";

import { isLayerSelected } from "../../../../utils/map/mapSettings";

import useWellSpotStyling from "../useWellSpotStyling";

const useProcessSurfaceColors = ({
  searchCriteria,
  initialGridSearchMade,
  shouldApplyColorToLayer,
}: ProcessColors) => {
  const layers = useMapStore((state) => state.layers);
  const compressedSurfaceData = useMapSettingsStore(
    (state) => state.compressedSurfaceData
  );
  const updateSurfaceDataInfo = useMapSettingsStore(
    (state) => state.updateSurfaceDataInfo
  );
  const layerColorsLoading = useMapSettingsStore(
    (state) => state.layerColorsLoading
  );
  const updateLayerColorsLoading = useMapSettingsStore(
    (state) => state.updateLayerColorsLoading
  );

  const {
    isLoading: isSurfaceValuesLoading,
    data: surfaceValues,
    getWellSpotAttribValues: getSurfaceValues,
  } = useWellSpotStyling();

  const isSurfaceSelected = useMemo(
    () => isLayerSelected(WELL_SPOTS, layers),
    [layers]
  );

  useEffect(() => {
    if (isSurfaceValuesLoading || !initialGridSearchMade) return;

    updateSurfaceDataInfo(surfaceValues);
    updateLayerColorsLoading(WELL_SPOTS, false);
  }, [isSurfaceValuesLoading, surfaceValues]);

  const callSurfaceStylingApi = (attributeKey: string) => {
    if (
      isSurfaceSelected &&
      shouldApplyColorToLayer(SURFACE_WELL_SPOTS, attributeKey)
    ) {
      updateLayerColorsLoading(WELL_SPOTS, true);
      getSurfaceValues({
        attribute: attributeKey,
        searchCriteria,
        layer: mapSettings.STYLING_API_KEY_MAPPER[WELL_SPOTS].request,
      });
    } else {
      updateSurfaceDataInfo([]);
    }
  };

  return {
    isSurfaceSelected,
    isSurfaceColorsLoading: layerColorsLoading[WELL_SPOTS],
    compressedSurfaceData,
    callSurfaceStylingApi,
  };
};

export default useProcessSurfaceColors;
