import {
  CropFree as CropFreeIcon,
  FullscreenExit as FullscreenExitIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { AnalysisChartToolbarControlsProps } from "../../../types/panels/analysisChartsPanel/analysisChartsPanel";

import { CHART_MODE_VIEW } from "../../../constants/charts/chartModeView";

import useModularityStore from "../../../store/modularity/modularityStore";

const AnalysisChartToolbarControls = ({
  chartMode,
}: AnalysisChartToolbarControlsProps) => {
  const toggleIsFullScreenAnalysisPane = useModularityStore(
    (state) => state.toggleFullScreenAnalysisPane
  );

  const componentExpand = () => {
    toggleIsFullScreenAnalysisPane(true);
  };

  const handleClose = () => {
    toggleIsFullScreenAnalysisPane(false);
  };

  return (
    <>
      {chartMode === CHART_MODE_VIEW.DEFAULT ? (
        <IconButton onClick={() => componentExpand()}>
          <CropFreeIcon className="text-white" />
        </IconButton>
      ) : (
        <IconButton onClick={handleClose}>
          <FullscreenExitIcon className="text-white" />
        </IconButton>
      )}
    </>
  );
};

export default AnalysisChartToolbarControls;
