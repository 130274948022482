import { useState } from "react";

import axios from "axios";

import { OktaTokenStorage } from "../../types/okta/token";
import {
  UploadedUWIFile,
  UwiFileUploadResponse,
} from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import {
  REGEXP_UWI_CSV_FILE_SCIENTIFIC_NOTATION,
  REGEXP_UWI_DELIMITERS,
} from "../../constants/regexp";
import {
  AUDIT_VERBOSITY,
  FILE_UPLOAD_FOLDER,
  LIFE_CYCLE_365_DAYS,
} from "../../constants/uwiSearchUpload";

import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../store/useStore";

const useUwiFileUpload = () => {
  const [error, setError] = useState<Error | null>(null);
  const [responseData, setResponseData] = useState<string | null>(null);

  const authUser = useStore((state) => state.authUser);

  const updateUploadFileProgress = useUWISearchStore(
    (state) => state.updateUploadFileProgress
  );
  const setIsLoadingUWIFileUpload = useUWISearchStore(
    (state) => state.setIsLoadingUWIFileUpload
  );

  const updateUploadedUwiFileId = useUWISearchStore(
    (state) => state.updateUploadedUwiFileId
  );
  const updateHasErrorUploadingUWI = useUWISearchStore(
    (state) => state.updateHasErrorUploadingUWI
  );

  const oktaTokenStorage: OktaTokenStorage | null = JSON.parse(
    localStorage.getItem("okta-token-storage") || "{}"
  );
  const accessToken = oktaTokenStorage?.accessToken?.accessToken;
  const tokenType = oktaTokenStorage?.accessToken?.tokenType;

  const getUWIFileIdWithRetry = async (
    file: File | string
  ): Promise<string | null> => {
    const MAX_RETRY_COUNT = 5;
    let retryCount = 0;

    const performGetFileId = async (): Promise<string | null> => {
      try {
        const fileIdUrl = `${config.endpoints.wellService}api/file-upload`;
        const response = await axios.post<UwiFileUploadResponse>(
          fileIdUrl,
          {
            fileName: typeof file === "string" ? file : file?.name,
            folderName: `${FILE_UPLOAD_FOLDER}/${authUser.uid}`,
            lifeCycle: LIFE_CYCLE_365_DAYS,
          },
          {
            ignoreResponseInterceptor: true,
          }
        );

        const fileId = response.data.fileId;

        if (fileId) {
          updateUploadedUwiFileId(fileId);
          setResponseData(fileId);
          updateUploadFileProgress(100);
          return fileId;
        }
      } catch (error) {
        if (retryCount < MAX_RETRY_COUNT) {
          retryCount++;
          return performGetFileId();
        } else {
          updateHasErrorUploadingUWI(true);
          setIsLoadingUWIFileUpload(false);
          throw error;
        }
      }
      return null;
    };

    return performGetFileId();
  };

  const getPresignedUrl = async (
    file: UploadedUWIFile | Blob
  ): Promise<void> => {
    updateUploadFileProgress(25);
    setIsLoadingUWIFileUpload(true);

    const ContentType =
      file && file?.name && file?.name.endsWith(".csv")
        ? "text/csv"
        : "text/plain";

    try {
      const response = await fetch(config.endpoints.presignedURL, {
        method: "POST",
        body: JSON.stringify({
          FileName: file && file.name,
          FolderName: `${FILE_UPLOAD_FOLDER}/${authUser.uid}`,
          ContentType,
          LifeCycle: LIFE_CYCLE_365_DAYS,
          AuditVerbosity: AUDIT_VERBOSITY,
        }),
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
      });

      const data = await response.json();

      if (data.PreSignedUrl) {
        updateUploadFileProgress(50);
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          const fileContent = fileReader.result as string;
          const uwiList = fileContent
            .trim()
            .split(REGEXP_UWI_DELIMITERS)
            .filter(Boolean);
          const sanitizedText = uwiList.join("\n");
          const fileBlob = new Blob([sanitizedText], {
            type: ContentType,
          });

          try {
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", data.PreSignedUrl, true);
            xhr.setRequestHeader("Content-Type", ContentType);
            xhr.onload = async () => {
              if (xhr.status >= 200 && xhr.status < 300) {
                await getUWIFileIdWithRetry(file as File);
                updateUploadFileProgress(75);
                setIsLoadingUWIFileUpload(false);
                updateHasErrorUploadingUWI(false);
              } else {
                console.error(xhr.statusText);
              }
            };
            xhr.onerror = (e) => {
              updateHasErrorUploadingUWI(true);
              console.error("Network error", e);
            };
            xhr.send(fileBlob);
          } catch (error) {
            updateHasErrorUploadingUWI(true);
            console.error("Error during file upload:", error);
          }
        };

        fileReader.readAsText(file as Blob);
      } else {
        console.log("Error creating Presigned URL.");
        updateHasErrorUploadingUWI(true);
      }
    } catch (error) {
      updateHasErrorUploadingUWI(true);
      setIsLoadingUWIFileUpload(false);
      setError(error as Error);
    }
  };

  return {
    responseData,
    error,
    getPresignedUrl,
    getUWIFileIdWithRetry,
  };
};

export default useUwiFileUpload;
