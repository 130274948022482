import { memo, useMemo } from "react";

import { GunBarrelInfo } from "../../../types/panels/wellPanel/gunBarrelType";
import {
  MapLayer,
  WellPanelInfo,
  WellPermitPanelSection,
  WellSystemData,
} from "../../../types/panels/wellPanel/wellPanel";

import { GUN_BARREL } from "../../../constants/constants";
import { PRODUCTION_PLOT_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../store/panels/panelsStore";

import GunBarrelTab from "../../charts/chartType/GunBarrelTab";
import ProductionPlot from "./sections/ProductionPlot";

interface WellPanelChartExports {
  wellPanelInfo: WellPanelInfo;
  exportChart: any;
  gunBarrelRef: any;
  productionRef: any;
  gunBarrelData: GunBarrelInfo | undefined;
  groupedWellID: number;
  layer: MapLayer;
  data: WellSystemData;
}

const WellPanelChartExports = ({
  wellPanelInfo,
  exportChart,
  gunBarrelRef,
  productionRef,
  gunBarrelData,
  data,
  groupedWellID,
  layer,
}: WellPanelChartExports) => {
  const updateWellPanelActiveSectionByKey = usePanelsStore(
    (state) => state.updateWellPanelActiveSectionByKey
  );

  const getGunBarrel =
    exportChart.length > 0 &&
    exportChart.find((chart: any) => chart.chartType === GUN_BARREL);
  const getProductionData =
    exportChart.length > 0 &&
    exportChart.find(
      (chart: any) => chart.chartType === PRODUCTION_PLOT_SECTION.key
    );

  const handleOnChange = (section: WellPermitPanelSection) => {
    updateWellPanelActiveSectionByKey(
      layer,
      groupedWellID,
      data.wellId,
      section
    );
  };
  const productionExport = useMemo(() => {
    let getProdPlot;
    if (exportChart.length > 0) {
      getProdPlot = exportChart.find(
        (chart: any) => chart.chartType === PRODUCTION_PLOT_SECTION.key
      );
    }
    return Boolean(getProdPlot);
  }, [exportChart, data]);

  return (
    <>
      {getGunBarrel && data.gunBarrelData && (
        <div
          className="gun-barrel-export-chart export-chart gun-barrel"
          ref={gunBarrelRef}
        >
          <GunBarrelTab
            gunBarrelData={gunBarrelData}
            uwiNumber={getGunBarrel.chartId}
            chartExport
          />
        </div>
      )}
      {getProductionData && data.productionData && (
        <div
          className="export-chart production-plot-section"
          ref={productionRef}
        >
          <ProductionPlot
            wellData={data}
            activePage={wellPanelInfo.activePage}
            layer={layer}
            groupedWellID={groupedWellID}
            isExpanded
            productionRef={productionRef}
            onChange={(section) => handleOnChange(section)}
            isChartExport={productionExport}
          />
        </div>
      )}
    </>
  );
};

export default memo(WellPanelChartExports);
