export const CHART_COLOR_PRIMARY_PALLETTE = [
  "#00C853",
  "#00BFA5",
  "#00B8D4",
  "#0091EA",
  "#304FFE",
  "#6200EA",
  "#AA00FF",
  "#FFD600",
  "#DD2C00",
  "#C51162",
];
export const CHART_COLOR_SECONDARY_PALLETTE = [
  "#B9F6CA",
  "#A7FFEB",
  "#84FFFF",
  "#80D8FF",
  "#8C9EFF",
  "#B388FF",
  "#EA80FC",
  "#FFFF8D",
  "#FF9E80",
  "#FF4081",
];

export const CHART_COLOR_TERTIARY_PALLETTE = [
  "#00E676",
  "#1DE9B6",
  "#00E5FF",
  "#00B0FF",
  "#536DFE",
  "#7C4DFF",
  "#D500F9",
  "#FFFF00",
  "#FF3D00",
  "#F50057",
];

export const STACKED_AREA_COLOR_PALLETE = [
  ...CHART_COLOR_PRIMARY_PALLETTE,
  ...CHART_COLOR_SECONDARY_PALLETTE,
  ...CHART_COLOR_TERTIARY_PALLETTE,
];
