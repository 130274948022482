//positive numbers only, allow commas
//disallow negative values
import { REGEX_UP_TO_TWO_DECIMAL } from "../constants/regexp";

//default: up to 2 decimal point
export const regexPositiveDecimalNumber = (
  decimals = REGEX_UP_TO_TWO_DECIMAL
) => {
  return new RegExp(`^(\\d+|\\d{1,3}(\\,\\d{3})*)(\\.\\d{${decimals}})?$`);
};

//commas become optional as long as they're consistent,
//allow negative(-)
//default: up to two decimals
export const regexNumber = (decimals = REGEX_UP_TO_TWO_DECIMAL) => {
  return new RegExp(
    `(^$)|^-?(\\d+|\\d{1,3}(\\,\\d{3})*)(\\.\\d{${decimals}})?$`
  );
};

//commas become optional as long as they're consistent,
//required parenthesis,
//allow empty string,
//default up to 2 decimal places
export const regexParenthesis = (decimals = REGEX_UP_TO_TWO_DECIMAL) => {
  return new RegExp(
    `(^$)|\\((\\d+|\\d{1,3}(,\\d{3})*)(\\.\\d{${decimals}})?\\)$`
  );
};
