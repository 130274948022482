import { FC } from "react";

import { PermitPanelSectionProp } from "../../../../types/panels/permitPanel/permitPanel";

import * as attributes from "../../../../constants/attributes";
import {
  PERMIT_INFO_HEADER_ATTRIBUTES,
  PERMIT_PANEL_SECTION,
} from "../../../../constants/panels/permitPanel/permit";

import useAttributeUnit from "../../../../customHooks/common/useAttributeUnit";
import usePanelFormattingRule from "../../../../customHooks/panels/usePanelFormattingRule";

import { formatAttributeByKey } from "../../../../utils/formatters/attributeFormatter";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../../wellPanel/common/WellPanelAccordion";

const Header: FC<PermitPanelSectionProp> = ({ isExpanded, onChange, data }) => {
  const { getAttributeUnit } = useAttributeUnit();
  const { formatLatLongAttributes } = usePanelFormattingRule();
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={PERMIT_PANEL_SECTION.HEADER_SECTION.displayLabel}
      onChange={() => onChange(PERMIT_PANEL_SECTION.HEADER_SECTION)}
      isLoading={false}
    >
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.label}
            value={formatLatLongAttributes(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.key] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.label}
            value={formatLatLongAttributes(
              data[
                PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.label}
            value={formatLatLongAttributes(
              data[PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.key] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.label}
            value={formatLatLongAttributes(
              data[
                PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.label}
            value={`${formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key] as number
            )} ${getAttributeUnit(attributes.PERMIT_TOTAL_DEPTH.key)}`}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key,
              data[PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.label}
            value={formatAttributeByKey(
              PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.key,
              data[
                PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.key
              ] as boolean
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default Header;
