//default grid filter operators
export const CONTAINS = "contains";
export const EQUALS = "equals";
export const STARTS_WITH = "startsWith";
export const ENDS_WITH = "endsWith";
export const IS_EMPTY = "isEmpty";
export const IS_NOT_EMPTY = "isNotEmpty";
export const IS_ANY_OF = "isAnyOf";

//additional grid filter operators
export const GREATER_THAN = "greaterThan";
export const GREATER_THAN_OR_EQUAL_TO = "greaterThanOrEqualTo";
export const LESS_THAN = "lessThan";
export const LESS_THAN_OR_EQUAL_TO = "lessThanOrEqualTo";
export const BETWEEN = "between";
export const BEFORE = "before";
export const AFTER = "after";
export const YEAR = "year";

export const FILTER_LOGICAL_OPERATOR_OR = "OR";
export const FILTER_LOGICAL_OPERATOR_AND = "AND";

export const COLUMN_FILTER_TYPE_NUMBER = "number";
export const COLUMN_FILTER_TYPE_VARCHAR = "varchar";
export const COLUMN_FILTER_TYPE_DATE = "date";

export const FILTER_TYPE_CHECKLIST = "checklist";
export const FILTER_TYPE_EXPRESSION_BUILDER = "expressionbuilder";

export const FILTER_BLANK_TEXT_DISPLAY = "Blank";
