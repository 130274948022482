import {
  FormControl,
  InputLabel,
  MenuItem,
  Select as MuiSelect,
  SelectChangeEvent,
} from "@mui/material";

interface SelectProps {
  label: string;
  labelId: string;
  value: string | number;
  options: { key: number; label: string }[];
  onChange: (
    event: SelectChangeEvent<string | number>,
    child: React.ReactNode
  ) => void;
  className?: string;
  children?: React.ReactNode;
  disabled?: boolean;
}

const Select: React.FC<SelectProps> = ({
  label,
  labelId,
  value,
  options = [],
  onChange,
  className,
  children,
  disabled = false,
}) => {
  return (
    <FormControl className={className}>
      <InputLabel id={labelId}>{label}</InputLabel>
      <MuiSelect
        labelId={labelId}
        label={label}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {children
          ? children
          : options.map(({ key, label }) => (
              <MenuItem key={key} value={key}>
                {label}
              </MenuItem>
            ))}
      </MuiSelect>
    </FormControl>
  );
};

export default Select;
