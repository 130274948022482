import { FC, PropsWithChildren } from "react";

import { ExpandMore } from "@mui/icons-material";
import { AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import { styled } from "@mui/material/styles";

import classNames from "classnames";

import Loading from "../../../common/Loading";

interface WellPanelAccordionProps {
  expanded: boolean;
  header: string;
  isLoading: boolean;
  onChange?: () => void;
  className?: string;
  id?: string;
  isChartExport?: boolean;
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  backgroundColor: "unset",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const WellPanelAccordion: FC<PropsWithChildren<WellPanelAccordionProps>> = ({
  expanded,
  header,
  children,
  onChange,
  isLoading,
  className = "",
  id = "",
  isChartExport,
}) => {
  const handleOnChange = () => {
    if (onChange) {
      onChange();
    }
  };
  return (
    <Accordion
      expanded={expanded}
      onChange={handleOnChange}
      className={classNames(`well-panel-accordion ${className}`, {
        loading: isLoading,
      })}
      id={id}
    >
      {!isChartExport && (
        <AccordionSummary
          expandIcon={
            isLoading ? (
              <Loading className="loading-spinner" size={20} />
            ) : (
              <ExpandMore />
            )
          }
          className={classNames("header", { expanded: expanded })}
        >
          <Typography>{header}</Typography>
        </AccordionSummary>
      )}
      <AccordionDetails className="content">{children}</AccordionDetails>
    </Accordion>
  );
};

export default WellPanelAccordion;
