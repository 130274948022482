import { FormControlLabel, Switch, Typography } from "@mui/material";

import { DailyValuesCheckboxProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

const DailyValuesCheckbox = ({
  showDailyValues,
  handleDailyValuesSelectionChange,
}: DailyValuesCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={showDailyValues}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleDailyValuesSelectionChange(e);
          }}
        />
      }
      label={
        <Typography sx={{ fontSize: "14px", color: "white" }}>
          Plot Daily Values
        </Typography>
      }
    />
  );
};

export default DailyValuesCheckbox;
