import { GridFilterModel } from "@mui/x-data-grid-premium";

import useDataGridStore from "../../store/grid/dataGridStore";

import { filterRequestFormat, removeFilterFields } from "../../utils/datagrid";

import { clone } from "../../utils";

export const useGridFilter = () => {
  const allSelectedFilterAttributes = useDataGridStore(
    (state) => state.allSelectedFilterAttributes
  );
  const updateSearchCriteria = useDataGridStore(
    (state) => state.updateSearchCriteria
  );
  const updateFilterModel = useDataGridStore(
    (state) => state.updateFilterModel
  );
  const updateGridWellDataTrigger = useDataGridStore(
    (state) => state.updateGridWellDataTrigger
  );

  const removeSearchCriteriaFilter = () => {
    updateSearchCriteria({
      filters: [],
    });
  };

  const getMapGridFilterData = (newFilterModel: GridFilterModel) => {
    updateFilterModel(newFilterModel);
    updateGridWellDataTrigger("filter");
    const filters = clone(filterRequestFormat(newFilterModel));
    if (filters.length) {
      //remove fields (all selected) from filter
      if (allSelectedFilterAttributes.length) {
        const filteredFilters = removeFilterFields(
          allSelectedFilterAttributes,
          filters
        );

        if (filteredFilters.length) {
          updateSearchCriteria({
            filters: filteredFilters,
          });
        } else {
          removeSearchCriteriaFilter();
        }
      } else {
        updateSearchCriteria({
          filters,
        });
      }
    } else {
      removeSearchCriteriaFilter();
    }
  };

  return {
    getMapGridFilterData,
  };
};
