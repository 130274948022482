import { Attribute, AttributeBound } from "../../types/common/attributes";
import { OperationTypeKeys } from "../../types/panels/searchPanel/queryBuilder";

export const getAttributeBounds: (
  attribute: Attribute,
  values: string[] | number[],
  operationType: OperationTypeKeys
) => AttributeBound = (attribute, values, operationType) => {
  const { key, dataType } = attribute;
  const attributeBound: AttributeBound = {
    bound: key,
    operations: [
      {
        type: operationType,
        values: values.map((value: string | number) =>
          dataType === "Boolean" ? value === "True" : value
        ),
      },
    ],
  };
  return attributeBound;
};
