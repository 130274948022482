import { StateCreator } from "zustand";

import { WellSelectSlice } from "../../types/grid";

const wellSelectSlice: StateCreator<WellSelectSlice> = (set, get) => ({
  wellSelectTrigger: "",
  updateWellSelectTrigger: (wellSelectTrigger) =>
    set((state) => ({ wellSelectTrigger })),
});

export { wellSelectSlice };
