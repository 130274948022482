import { useState } from "react";

import axios from "axios";

import { DataLakeRequestBody } from "../../../types/panels/wellPanel/dataLakeRequest";
import { DatalakeDocuments } from "../../../types/panels/wellPanel/dataLakeType";

import config from "../../../configs/appSettings";

import { settledPromise } from "../../../utils/api/request";

const useDatalake = <T extends DatalakeDocuments>() => {
  const [dataLakeResponse, setDataLakeResponse] = useState<T["Documents"]>();
  const [successRequest, setSuccessRequest] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [errorRequest, setErrorRequest] = useState(null);

  const getDataLakeData = async (requestBody: DataLakeRequestBody) => {
    setLoadingRequest(true);
    setSuccessRequest(false);
    setDataLakeResponse(undefined);
    setErrorRequest(null);
    try {
      const initialResponse = await axios.post<T>(
        `${config.endpoints.dataLake}/Search`,
        requestBody
      );

      const totalCount = initialResponse.data.Total;
      const totalIterations = Math.ceil(totalCount / requestBody.Size);

      if (totalIterations > 0) {
        const responses = [...Array(totalIterations).keys()].map((num, i) => {
          return axios.post<T>(`${config.endpoints.dataLake}/Search`, {
            ...requestBody,
            From: i * requestBody.Size,
          });
        });

        settledPromise(responses, (values) => {
          const data = values.flatMap((response) => response.data.Documents);
          setDataLakeResponse(data);
          setLoadingRequest(false);
        });
      } else {
        setDataLakeResponse([]);
        setLoadingRequest(false);
      }

      setSuccessRequest(true);
    } catch (error: any) {
      console.debug("Error fetching data lake data", error);
      setErrorRequest(error);
      setLoadingRequest(false);
    }
  };

  return {
    dataLakeResponse,
    successRequest,
    loadingRequest,
    errorRequest,
    getDataLakeData,
  };
};

export default useDatalake;
