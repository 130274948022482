import { useEffect } from "react";

import {
  Download as DownloadIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { Box, Menu, MenuItem } from "@mui/material";

import { ChartSettingsModalParams } from "../../../../../types/charts/chartStore/chartStore";
import { MenuControlsProps } from "../../../../../types/charts/chartType/chartTypeHeader";

import { CHART_TYPES } from "../../../../../constants/charts/charts";

import useChartStore from "../../../../../store/chart/chartStore";
import useStore from "../../../../../store/useStore";

import useExportImage from "../../../../../customHooks/exports/useExportImage";

import NestedMenuItem from "../../../../common/NestedMenuItem";

const MenuControls = ({
  anchorEl,
  popoverAnchorEl,
  onClose,
  onExportClick,
  onPopoverClose,
  chartType,
  chartId,
  chartRendered,
  chartRef,
  chartTitle,
  chartMode,
  onChartExportData,
}: MenuControlsProps) => {
  const openMenu = Boolean(anchorEl);
  const parentMenuOpen = Boolean(popoverAnchorEl);
  const updateToastMessage = useStore((state) => state.updateToastMessage);
  const updateExportChart = useStore((state) => state.updateExportChart);
  const exportChart = useStore((state) => state.exportChart);
  const updateChartModalParam = useChartStore(
    (state) => state.setChartSettingsModalParams
  );
  const { exportImage } = useExportImage();

  const handleExportPng = () => {
    updateToastMessage("Image is now being exported");
    updateExportChart({ chartType, chartId, chartMode });
    onClose();
  };

  const handleExportData = () => {
    updateToastMessage("Excel is now exporting");
    onChartExportData();
    onClose();
  };

  const handleSettingSelection = () => {
    const chartConfigModalParams: ChartSettingsModalParams = {
      open: true,
      chartId: chartId,
      chartType: chartType,
    };
    updateChartModalParam(chartConfigModalParams);
    onClose();
  };

  useEffect(() => {
    if (
      chartRendered &&
      exportChart?.chartId &&
      exportChart?.chartType &&
      chartRef?.current
    ) {
      exportImage(chartId, chartTitle, chartType, chartRef);
    }
  }, [JSON.stringify(exportChart), chartRendered]);

  return (
    <Menu
      id="chart-menu"
      anchorEl={anchorEl}
      open={openMenu}
      onClose={() => onClose()}
      className="export-menu-dialog"
    >
      <NestedMenuItem
        item={
          <>
            <DownloadIcon />
            <Box className="export-menu-dialog-labels">Export</Box>
          </>
        }
        parentMenuOpen={parentMenuOpen}
        actionType="click"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          onExportClick(e)
        }
        nestedMenuAnchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        nestedMenutransformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(popoverAnchorEl)}
      >
        <Menu
          anchorEl={popoverAnchorEl}
          open={parentMenuOpen}
          onClose={() => onPopoverClose()}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          <div className="export-select-item">
            <MenuItem onClick={() => handleExportPng()}>
              <div className="export-select-item-labels">Export Image</div>
            </MenuItem>
            <hr className="export-select-item-divider" />
            <MenuItem onClick={() => handleExportData()}>
              <div className="export-select-item-labels">Export to Excel</div>
            </MenuItem>
          </div>
        </Menu>
      </NestedMenuItem>
      <hr className="export-menu-item-divider" />

      <MenuItem onClick={() => handleSettingSelection()}>
        <SettingsIcon />
        <Box className="export-menu-dialog-labels">Settings</Box>
      </MenuItem>
    </Menu>
  );
};

export default MenuControls;
