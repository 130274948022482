import { FC, useEffect, useState } from "react";

import { PanelDrawerProps } from "../../../types/panels/common/panelDrawer";
import { PermitPanelInfo } from "../../../types/panels/permitPanel/permitPanel";

import usePanelsStore from "../../../store/panels/panelsStore";

import usePermitsPanelData from "../../../customHooks/panels/permitsPanel/usePermitsPanelData";

import { formatUWI10ByCountry } from "../../../utils/common/wells";

import { clone } from "../../../utils";
import Loading from "../../common/Loading";
import PanelDrawer from "../common/PanelDrawer";
import PermitPanelContent from "./PermitPanelContent";
import PermitPanelHeader from "./PermitPanelHeader";

interface PermitsPanelProps extends PanelDrawerProps {
  wellHeaderPermitKey: string;
}

const PermitsPanel: FC<PermitsPanelProps> = ({
  open,
  handleCloseMenuClick,
  wellHeaderPermitKey,
}) => {
  const [currentPermitPanelInfo, setCurrentPermitPanelInfo] = useState<
    PermitPanelInfo | undefined
  >(undefined);
  const permitsList = usePanelsStore((state) => state.permitsList);
  const updatePermitDataByKey = usePanelsStore(
    (state) => state.updatePermitDataByKey
  );

  const {
    getPermitData,
    permitData,
    isLoadingPermitData,
    isSuccessPermitData,
  } = usePermitsPanelData();

  useEffect(() => {
    const currentPermitInfo: PermitPanelInfo[] = clone(permitsList);
    const searchedPermitPanelInfo = currentPermitInfo.find(
      (permit) => permit.wellHeaderPermitKey === wellHeaderPermitKey
    );

    if (searchedPermitPanelInfo) {
      setCurrentPermitPanelInfo(searchedPermitPanelInfo);

      if (!searchedPermitPanelInfo.permitSystemData?.permitData.isDataLoaded) {
        const UWI10 = formatUWI10ByCountry(
          searchedPermitPanelInfo.uwi,
          searchedPermitPanelInfo.country
        );

        getPermitData(searchedPermitPanelInfo.permitID, UWI10);
      }
    }
  }, [permitsList]);

  useEffect(() => {
    if (
      !isLoadingPermitData &&
      isSuccessPermitData &&
      permitData?.permitData.isDataLoaded
    ) {
      updatePermitDataByKey(permitData, wellHeaderPermitKey);
    }
  }, [isLoadingPermitData, permitData, isSuccessPermitData]);

  return (
    <PanelDrawer
      className="well-panel"
      open={open && Boolean(currentPermitPanelInfo?.isActive)}
      handleCloseMenuClick={handleCloseMenuClick}
    >
      {!isLoadingPermitData && currentPermitPanelInfo ? (
        <div className="well-panel-container">
          <div className="well-panel-body">
            <PermitPanelHeader
              permitInfo={currentPermitPanelInfo}
              wellHeaderPermitKey={wellHeaderPermitKey}
            />
            <PermitPanelContent
              wellHeaderPermitKey={wellHeaderPermitKey}
              permitSystem={currentPermitPanelInfo.permitSystemData}
            />
          </div>
        </div>
      ) : (
        <div className="d-flex justify-content-center py-5">
          <Loading />
        </div>
      )}
    </PanelDrawer>
  );
};

export default PermitsPanel;
