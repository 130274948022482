import { useCallback, useEffect } from "react";

import { FetchExtentProps } from "../../types/grid/useAllWellData";
import { Extent, ExtentResponse } from "../../types/map/gis/extent";

import config from "../../configs/appSettings";

import useExtentInfoStore from "../../store/map/gis/extent/extentStore";
import useMapStore from "../../store/map/mapStore";
import useStore from "../../store/useStore";

import { applyZoomToFit, cleanExtentData } from "../../utils/map/extent";

import { callServiceAPI } from "../../action/callServiceAPI";
import useZoomToFitPadding from "../map/useZoomToFitPadding";

const useFetchExtentData = () => {
  const map = useMapStore((state) => state.map);

  const { data: extentData, isLoading: extentDataLoading } = useExtentInfoStore(
    (state) => state.extentInfo
  );
  const updateExtentInfo = useExtentInfoStore(
    (state) => state.updateExtentInfo
  );

  const enableZoomFit = useStore((state) => state.enableZoomFit);
  const toggleEnableZoomFit = useStore((state) => state.toggleEnableZoomFit);

  const allWellGridDataLoading = useStore(
    (state) => state.allWellGridDataLoading
  );

  const DVTProcessing = useStore((state) => state.DVTProcessing);

  const {
    zoomToFitLeftPadding,
    zoomToFitRightPadding,
    zoomToFitBottomPadding,
  } = useZoomToFitPadding();

  const getExtentData = useCallback(
    async ({
      body,
      getUWIFileIdWithRetry,
      uploadedUWIFile,
      isFromUploadedUWIFile,
    }: FetchExtentProps) => {
      try {
        // NOTE:
        // Resetting of the states for extent is
        // handled in Search Triggers UWI Tab Panel and Search Panel

        const getWellsExtentUrl = `${config.endpoints.wellService}api/wells/coordinates/extent`;

        const extentRes: any = await callServiceAPI(
          getWellsExtentUrl,
          body,
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          isFromUploadedUWIFile
        );
        const exData: ExtentResponse = extentRes?.data;
        const extent: Extent | undefined = cleanExtentData(exData);

        if (extent) {
          updateExtentInfo({
            isLoading: false,
            data: extent,
            error: "",
          });
          toggleEnableZoomFit(true);
        }
      } catch (error: any) {
        console.debug("getExtentData error", error);
        updateExtentInfo({
          isLoading: false,
          data: [],
          error,
        });
      }
    },
    [updateExtentInfo, toggleEnableZoomFit]
  );

  useEffect(() => {
    if (
      map &&
      extentData &&
      extentData.length &&
      !extentDataLoading &&
      enableZoomFit &&
      !allWellGridDataLoading &&
      !DVTProcessing
    ) {
      const zoomToFitPadding = {
        top: 50,
        right: zoomToFitRightPadding,
        bottom: zoomToFitBottomPadding,
        left: zoomToFitLeftPadding,
      };

      applyZoomToFit(map, extentData, zoomToFitPadding);

      toggleEnableZoomFit(false);
    }
  }, [
    map,
    extentData,
    extentDataLoading,

    allWellGridDataLoading,
    DVTProcessing,
    enableZoomFit,
    toggleEnableZoomFit,
    zoomToFitBottomPadding,
    zoomToFitLeftPadding,
    zoomToFitRightPadding,
  ]);

  return {
    getExtentData,
  };
};

export default useFetchExtentData;
