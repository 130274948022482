interface LineSymbolProp {
  color: string;
  width?: number;
  height?: number;
  strokeWidth?: number;
}

const LineSymbol = ({
  color,
  width = 20,
  height = 20,
  strokeWidth = 2,
}: LineSymbolProp) => {
  return (
    <svg height={height} width={width}>
      <line
        x1={0}
        y1={height / 2}
        x2={width}
        y2={height / 2}
        stroke={color}
        strokeWidth={strokeWidth}
      />
    </svg>
  );
};

export default LineSymbol;
