/**
 * Link Reference:
 * https://deck.gl/docs/developer-guide/loading-data#example-use-image-from-a-programmatically-generated-svg-string
 */

export const createPermitIcon = (
  isSelected: boolean,
  color: number[],
  multiplier: number
) => {
  const size = 52 * multiplier;
  const center = size / 2;
  return `\
  <svg xmlns="http://www.w3.org/2000/svg" width="${size}px" height="${size}px" viewBox="0 0 ${size} ${size}" id="a">
    <defs>
        <style>
            .f{fill:none;stroke:rgb(170, 0, 113, ${
              isSelected ? 1 : 0
            });stroke-linecap:round;stroke-linejoin:round;stroke-width:16px}
            .f1{fill:none;stroke:rgba(${color[0]}, ${color[1]}, ${
    color[2]
  }, 1);stroke-linecap:round;stroke-linejoin:round;;stroke-width:${
    10 * multiplier
  }px}
  .f2{fill:transparent}
        </style>
    </defs>
    <circle id="e" class="f" cx="${center}" cy="${center}" r="${
    18 * multiplier
  }"/>
    <circle id="c" class="f1" cx="${center}" cy="${center}" r="${
    14 * multiplier
  }"/>
    <circle id="d" class="f2" cx="${center}" cy="${center}" r="${
    10 * multiplier
  }"/>
</svg>`;
};

// Note that a xml string cannot be directly embedded in a data URL
// it has to be either escaped or converted to base64.
export const svgToDataUrl = (svg: string) => {
  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svg)}`;
};

// You may need base64 encoding if the SVG contains certain special characters
export const svgToDataUrlBase64 = (svg: string) => {
  return `data:image/svg+xml;base64,${btoa(svg)}`;
};
