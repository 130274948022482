import {
  booleanAttributes,
  dateAttributes,
  numberAttributes,
  specialNumberAttributes,
  stringAttributes,
} from "../../constants/common/attributeFormat";
import { noData } from "../../constants/constants";

import { convertStringToDate } from "../charts/aggregator";
import { formatBooleanVal } from "./booleanFormatter";
import {
  formatNumber,
  formatNumberWithDecimals,
  formatSpecialNumber,
  parseNumberData,
} from "./numberFormatter";

const formatAttributeByKey = (
  attributeKey: string,
  val?: number | string | boolean | Date | null,
  showCommasForNumbers = true,
  showNoData = true,
  isForExport = false,
  overrideNumberFormatterRuleMethod?: (val: number | string) => string | number //override global standards for number format
) => {
  if (!attributeKey) return "";
  if (val === undefined || val === null || val === "")
    return showNoData ? noData : "";

  const sanitizedKey = attributeKey.toLowerCase();

  if (
    numberAttributes.includes(sanitizedKey) &&
    (typeof val === "string" || typeof val === "number")
  ) {
    if (overrideNumberFormatterRuleMethod) {
      return overrideNumberFormatterRuleMethod(val);
    }
    return formatNumber(parseNumberData(val), showCommasForNumbers);
  } else if (
    specialNumberAttributes.includes(sanitizedKey) &&
    (typeof val === "string" || typeof val === "number")
  ) {
    return formatSpecialNumber(parseNumberData(val), 10, showCommasForNumbers);
  } else if (
    stringAttributes.includes(sanitizedKey) &&
    typeof val === "string"
  ) {
    return val;
  } else if (dateAttributes.includes(sanitizedKey) && typeof val === "string") {
    return isForExport ? convertStringToDate(val) : val;
  } else if (
    booleanAttributes.includes(sanitizedKey) &&
    (typeof val === "string" || typeof val === "boolean")
  ) {
    return formatBooleanVal(val);
  } else {
    return val;
  }
};

const formatBfactor = (val: string | number) => {
  const parsedVal = parseNumberData(val);
  return formatNumberWithDecimals(parsedVal, 1, false);
};

export { formatAttributeByKey, formatBfactor };
