import { FC, MouseEvent, useEffect, useState } from "react";

import {
  CropFree as CropFreeIcon,
  FilterAltOff as FilterAltOffIcon,
  KeyboardDoubleArrowDown as KeyboardDoubleArrowDownIcon,
  KeyboardDoubleArrowUp as KeyboardDoubleArrowUpIcon,
  Minimize as MinimizeIcon,
  MoreHoriz as MoreHorizIcon,
} from "@mui/icons-material";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import ViewWeekSharpIcon from "@mui/icons-material/ViewWeekSharp";
import {
  IconButton,
  Menu,
  MenuItem,
  PopoverProps,
  Typography,
} from "@mui/material";

import { GridToolbarProps } from "../../../../types/grid";

import config from "../../../../configs/appSettings.js";

import { MAX_EXPORT } from "../../../../constants/constants";

import useExportCardStore from "../../../../store/exportCard/exportCardStore";
import useDataGridStore from "../../../../store/grid/dataGridStore";

import useR360GridExport from "../../../../customHooks/exports/useR360GridExport";

import { getNumberWithComma } from "../../../../utils/formatters/numberFormatter";

import Loading from "../../../common/Loading";
import NestedMenuItem from "../../../common/NestedMenuItem";
import { ContractTableIcon, ExpandTableIcon } from "../../../common/icons";
import SearchMagnifyingGlassIcon from "../../../common/icons/SearchMagnifyingGlassIcon";
import DynamicColumn from "../DynamicColumn";

export const GridToolBarControls: FC<GridToolbarProps> = (props) => {
  const { componentType: component, dropdown } = props;
  const [showMaximizeIcon, setShowMaximizeIcon] = useState(true);
  const [showMinimizeIcon, setShowMinimizeIcon] = useState(false);

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const filterModel = useDataGridStore((state) => state.filterModel);
  const toggleFullScreenGrid = useDataGridStore(
    (state) => state.toggleFullScreenGrid
  );
  const isFullScreenGrid = useDataGridStore((state) => state.isFullScreenGrid);
  const isExpandGridWidth = useDataGridStore(
    (state) => state.isExpandGridWidth
  );
  const toggleGridWidth = useDataGridStore((state) => state.toggleGridWidth);

  const [menuPosition, setMenuPosition] =
    useState<PopoverProps["anchorPosition"]>();
  const [columnsMenuOpen, setColumnsMenuOpen] = useState(false);
  const [exportsMenuOpen, setExportsMenuOpen] = useState(false);
  const gridFilteredCount = useDataGridStore(
    (state) => state.gridFilteredCount
  );
  const isExportToGridLoading = useDataGridStore(
    (state) => state.isExportToGridLoading
  );

  const updateOpenR360Modal = useExportCardStore(
    (state) => state.updateOpenR360modal
  );

  const { isR360Disabled } = useR360GridExport();

  const componentMinimize = () => {
    toggleFullScreenGrid(false);
  };

  const componentExpand = () => {
    toggleFullScreenGrid(true);
  };

  const componentClose = () => {
    toggleGrid(false);
    toggleFullScreenGrid(false);
  };

  const handleDropdownClick = (event: MouseEvent) => {
    if (menuPosition) {
      return;
    }
    event.preventDefault();
    setMenuPosition({
      top: event.pageY,
      left: event.pageX,
    });
  };

  const handleItemClick = (item: string) => {
    setColumnsMenuOpen(item === "columns" ? !columnsMenuOpen : false);
    setExportsMenuOpen(item === "exports" ? !exportsMenuOpen : false);
  };

  const handleCloseAllMenu = () => {
    setMenuPosition(undefined);
    setColumnsMenuOpen(false);
    setExportsMenuOpen(false);
  };

  useEffect(() => {
    if (isFullScreenGrid) {
      setShowMaximizeIcon(false);
      setShowMinimizeIcon(true);
    } else {
      setShowMaximizeIcon(true);
      setShowMinimizeIcon(false);
    }
  }, [isFullScreenGrid]);

  const exportToExcelText = () => {
    let text = "";
    if (gridFilteredCount > MAX_EXPORT) {
      text = `Export as Excel (Max ${getNumberWithComma(MAX_EXPORT)})`;
    } else {
      text = "Export as Excel";
    }
    return text;
  };

  return (
    <>
      <div className="grid-toolbar-control">
        {dropdown && (
          <>
            {!isFullScreenGrid && (
              <IconButton onClick={() => toggleGridWidth()}>
                {isExpandGridWidth ? (
                  <ContractTableIcon />
                ) : (
                  <ExpandTableIcon />
                )}
              </IconButton>
            )}
            <IconButton size="small" onClick={(e) => handleDropdownClick(e)}>
              <MoreHorizIcon className="text-white" fontSize="small" />
            </IconButton>

            <Menu
              className="grid-toolbar-menu"
              slotProps={{
                paper: {
                  className:
                    "tgsccl-system-color-palette backgrounds map-element",
                  style: { width: "210px" },
                },
              }}
              open={!!menuPosition}
              onClose={() => handleCloseAllMenu()}
              anchorReference="anchorPosition"
              anchorPosition={menuPosition}
              disableAutoFocus
              disableEnforceFocus
              anchorOrigin={{
                vertical: "center",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <NestedMenuItem
                item={
                  <>
                    <Typography component="span">
                      Show or Hide Columns
                    </Typography>
                    <ViewWeekSharpIcon />
                  </>
                }
                className="grid-toolbar-menu-item"
                parentMenuOpen={columnsMenuOpen && !!menuPosition}
                actionType="click"
                onClick={() => handleItemClick("columns")}
                nestedMenuAnchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                nestedMenutransformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <DynamicColumn />
              </NestedMenuItem>
              <MenuItem
                className="grid-toolbar-menu-item"
                onClick={dropdown.export.onClickExportExcel}
                disabled={
                  gridFilteredCount > MAX_EXPORT || isExportToGridLoading
                }
              >
                <Typography component="span">{exportToExcelText()}</Typography>
                {isExportToGridLoading && (
                  <Loading className="circular-progress" />
                )}
                <FileDownloadOutlinedIcon />
              </MenuItem>
              <MenuItem
                className="grid-toolbar-menu-item"
                onClick={dropdown.filter.onClickResetFilter}
                disabled={!filterModel?.items?.length}
              >
                <Typography component="span">Reset All Filters</Typography>
                <FilterAltOffIcon />
              </MenuItem>
              {config.r360ExportEnabled && (
                <MenuItem
                  className="grid-toolbar-menu-item"
                  disabled={isR360Disabled}
                  onClick={() => updateOpenR360Modal(true)}
                >
                  <Typography component="span">R360</Typography>
                  <SearchMagnifyingGlassIcon />
                </MenuItem>
              )}
            </Menu>
          </>
        )}

        {showMaximizeIcon && showGrid && (
          <IconButton size="small" onClick={() => componentExpand()}>
            <CropFreeIcon className="text-white" fontSize="small" />
          </IconButton>
        )}
        {showMinimizeIcon && showGrid && (
          <IconButton size="small" onClick={() => componentMinimize()}>
            <MinimizeIcon className="text-white" fontSize="small" />
          </IconButton>
        )}

        {showGrid ? (
          <IconButton size="small" onClick={() => componentClose()}>
            <KeyboardDoubleArrowDownIcon fontSize="small" />
          </IconButton>
        ) : (
          <IconButton size="small" onClick={() => toggleGrid(true)}>
            <KeyboardDoubleArrowUpIcon fontSize="small" />
          </IconButton>
        )}
      </div>
    </>
  );
};

export default GridToolBarControls;
