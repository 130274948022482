import { create } from "zustand";

import { ExportState } from "../../types/panels/wellPanel/exportModalStore";

import { exportPermitPanelSlice } from "./exportPermitPanelSlice";
import {
  exportPlotChartSlice,
  exportWellInformationSlice,
  exportWellPanelSlice,
} from "./exportWellPanelSlice";

const useExportPanelStore = create<ExportState>()((...a) => ({
  ...exportWellPanelSlice(...a),
  ...exportWellInformationSlice(...a),
  ...exportPlotChartSlice(...a),
  ...exportPermitPanelSlice(...a),
}));

export default useExportPanelStore;
