import { useMemo } from "react";

import useUnitOfMeasure from "./useUnitOfMeasure";

const useGetRequestParams = () => {
  const { isMetricOnSearch } = useUnitOfMeasure();

  const buildRequestQueryParam = useMemo(() => {
    return {
      params: {
        isMetric: isMetricOnSearch,
      },
    };
  }, [isMetricOnSearch]);

  return { buildRequestQueryParam };
};

export default useGetRequestParams;
