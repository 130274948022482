import * as attributes from "../../constants/panels/wellPanel/wellPanelAttributes";

import { UWI } from "../panels/wellPanel/wellInfo";

//Attributes only available on well panel
const wellPanelStringAttributes = [
  UWI.key.toLowerCase(),
  attributes.CASING_TYPE.key.toLowerCase(),
  attributes.CASING_PURPOSE.key.toLowerCase(),
  attributes.FORMATION_NAME.key.toLowerCase(),
  attributes.PERMIT_FORMATION.key.toLowerCase(),
  attributes.PERMIT_TYPE_CODE.key.toLowerCase(),
  attributes.WELL_ORIENTATION.key.toLowerCase(),
  attributes.TEST_TYPE.key.toLowerCase(),
];

const wellPanelNumberAttributes = [
  attributes.HOLE_SIZE.key.toLowerCase(),
  attributes.CASING_SIZE.key.toLowerCase(),
  attributes.CASING_WEIGHT.key.toLowerCase(),
  attributes.TOP_DEPTH.key.toLowerCase(),
  attributes.BOTTOM_DEPTH.key.toLowerCase(),
  attributes.CASING_GRADE.key.toLowerCase(),
  attributes.PERF_TOP.key.toLowerCase(),
  attributes.PERF_BOTTOM.key.toLowerCase(),
  attributes.OIL.key.toLowerCase(),
  attributes.GAS.key.toLowerCase(),
  attributes.WATER.key.toLowerCase(),
  attributes.TEST_DURATION.key.toLowerCase(),
];

const wellPanelDateAttributes = [
  attributes.PERF_DATE.key.toLowerCase(),
  attributes.TEST_DATE.key.toLowerCase(),
];

const wellPanelLatLong = [
  attributes.PERMIT_SURFACE_LATITUDE.key.toLowerCase(),
  attributes.PERMIT_SURFACE_LATITUDE.key.toLowerCase(),
  attributes.PERMIT_BOTTOM_HOLE_LONGITUDE.key.toLowerCase(),
  attributes.PERMIT_BOTTOM_HOLE_LAT.key.toLowerCase(),
];

export {
  wellPanelStringAttributes,
  wellPanelNumberAttributes,
  wellPanelDateAttributes,
  wellPanelLatLong,
};
