import { GunBarrelHoverEvent } from "../../types/panels/wellPanel/gunBarrelType";

import useGunBarrelInteractivityUtils from "./useGunBarrelInteractivityUtils";

const useGunBarrelEventHandler = () => {
  const {
    createOverlay,
    createTargetCrossHair,
    createFocusedPointCrossHair,
    createOnHoverAnnotations,
  } = useGunBarrelInteractivityUtils();
  const getFocusedPoint = (e: any) => {
    return {
      ...e.points[0].data,
      ...{
        x: [e.points[0].x],
        y: [e.points[0].y],
        showlegend: false,
        ...(e.points[0].text && {
          text: e.points[0].text,
          hoverinfo: "text",
        }),
      },
    };
  };

  const handleOnHover = ({
    e,
    setState,
    initialData,
    initialLayout,
  }: GunBarrelHoverEvent) => {
    if (!initialData.current) return;

    const focusedPoint = getFocusedPoint(e);
    const [xMin, xMax] = initialData.current.xRange.range;
    const [yMin, yMax] = initialData.current.yRange.range;
    const targetWell = initialData.current.originalData.targetWell;

    setState((state) => ({
      ...state,
      ...{
        data: [
          //initial traces
          ...state.data,
          //overlay
          ...createOverlay(xMin, xMax, yMin, yMax),
          //places formations on top of overlay
          ...(initialData.current ? initialData.current.formationTraces : []),
          //crosshair trace for target
          ...createTargetCrossHair(targetWell, xMin, xMax, yMin, yMax),
          //crosshair trace for focused point
          ...createFocusedPointCrossHair(focusedPoint, targetWell),
          //get trace for target well
          {
            ...(initialData.current ? initialData.current.wellTraces[0] : []),
            ...{ showlegend: false },
          },
          focusedPoint,
        ],
        layout: {
          ...state.layout,
          annotations: [
            ...(initialLayout.current?.annotations?.length
              ? initialLayout.current.annotations
              : []),
            ...createOnHoverAnnotations(focusedPoint, targetWell),
          ],
        },
        revision: state.revision + 1,
      },
    }));
  };

  const handleOnUnHover = ({
    e,
    setState,
    initialData,
    initialLayout,
  }: GunBarrelHoverEvent) => {
    setState((state) => ({
      ...state,
      ...{
        data: [
          ...(initialData.current ? initialData.current.formationTraces : []),
          ...(initialData.current ? initialData.current.wellTraces : []),
        ],
        layout: initialLayout.current,
        revision: state.revision + 1,
      },
    }));
  };

  return {
    handleOnUnHover,
    handleOnHover,
  };
};

export default useGunBarrelEventHandler;
