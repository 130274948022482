import { create } from "zustand";

import { ExportCardStore } from "../../types/exports/export-card/exportCardState";

import { exportCardSlice, exportUWIDataSlice } from "./exportCardSlice";
import exportR360Slice from "./exportR360Slice";
import { resetExportCardStateSlice } from "./resetExportCardStateSlice";

const useExportCardStore = create<ExportCardStore>()((...a) => ({
  ...exportCardSlice(...a),
  ...exportUWIDataSlice(...a),
  ...resetExportCardStateSlice(...a),
  ...exportR360Slice(...a),
}));

export default useExportCardStore;
