import { useCallback, useEffect, useMemo } from "react";

import { useOktaAuth } from "@okta/okta-react";

import jwt_decode from "jwt-decode";

import useOktaValidationStore from "../../store/okta/oktaStore";

import useReactTimer from "../common/useReactTimer";

const useOktaValidation = () => {
  const oktaValidated = useOktaValidationStore((state) => state.oktaValidated);
  const updateOktaValidated = useOktaValidationStore(
    (state) => state.updateOktaValidated
  );

  const { oktaAuth } = useOktaAuth();
  const { iteration } = useReactTimer();

  const expiry: number | undefined = useMemo(() => {
    try {
      const oktaTokenStorage: string | null =
        localStorage.getItem("okta-token-storage");

      if (oktaTokenStorage) {
        const oktaTokenStorageValue: { accessToken: { accessToken: string } } =
          JSON.parse(oktaTokenStorage);

        const accessToken = oktaTokenStorageValue?.accessToken?.accessToken;
        if (accessToken) {
          const decodedToken = jwt_decode<{ exp: number }>(accessToken);

          const expiryInMinutes = decodedToken?.exp;
          if (expiryInMinutes) {
            return expiryInMinutes * 1000;
          }
        }
      }

      return;
    } catch (error) {
      console.error("expiry could not be retrieved");
    }
  }, []);

  const checkOkta = useCallback(async () => {
    try {
      // Check Okta auth status and local storage
      if (expiry && new Date().getTime() > expiry) {
        oktaAuth.signOut();
      } else {
        updateOktaValidated(true);
      }
    } catch (error) {
      console.error("okta authentication check error", error);
    }
  }, [expiry, oktaAuth]);

  useEffect(() => {
    // Force redirect if Okta Auth State is not detected within 1 second
    // Check every 30 seconds if expired
    if (expiry && (iteration < 1 || iteration % 30 === 0)) {
      checkOkta();
    }
  }, [expiry, iteration, oktaAuth, oktaValidated, checkOkta]);

  return [oktaValidated, updateOktaValidated];
};

export default useOktaValidation;
