import {
  WellData,
  WellPanelSectionType,
} from "../../../../types/panels/wellPanel/wellPanel";

import {
  WELL_INFO_HEADER_ATTRIBUTES,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelAttributeUnits from "../../../../customHooks/panels/usePanelAttributeUnits";

import { formatAttributeByKey } from "../../../../utils/formatters/attributeFormatter";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../common/WellPanelAccordion";

interface HeaderProps {
  data: WellData;
  isExpanded: boolean;
  onChange: (type: WellPanelSectionType) => void;
}

const Header = ({ data, isExpanded, onChange }: HeaderProps) => {
  const { buildPanelAttributeVal } = usePanelAttributeUnits();
  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.HEADER_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.HEADER_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={false}
    >
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.STATE_NAME.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNTY.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.COUNTY.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.COUNTY.key] as string
            )}
          />
        </div>

        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key] as string
            )}
          />
        </div>

        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.SLANT.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.SLANT.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.SLANT.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.OPERATOR_NAME.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.TGS_OPERATOR.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.TGS_OPERATOR.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.TGS_OPERATOR.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.FIELD_NAME.key] as string
            )}
          />
        </div>

        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.BASIN_NAME.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.DISPLAY_FORMATION.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_HEADER_ATTRIBUTES.INTERPRETED_PRODUCING_FORMATION.label
            }
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.INTERPRETED_PRODUCING_FORMATION.key,
              data[
                WELL_INFO_HEADER_ATTRIBUTES.INTERPRETED_PRODUCING_FORMATION.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.key,
              Boolean(data[WELL_INFO_HEADER_ATTRIBUTES.HAS_PRODUCTION.key])
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.SPUD_DATE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.key,
              data[WELL_INFO_HEADER_ATTRIBUTES.COMPLETION_DATE.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.key,
              Boolean(data[WELL_INFO_HEADER_ATTRIBUTES.HAS_FORECAST.key])
            )}
          />
        </div>
      </div>
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.label}
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.key,
                data[WELL_INFO_HEADER_ATTRIBUTES.MEASURED_DEPTH.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.label}
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.key,
                data[
                  WELL_INFO_HEADER_ATTRIBUTES.TOTAL_VERTICAL_DEPTH.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.label}
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.key,
                data[WELL_INFO_HEADER_ATTRIBUTES.LATERAL_LENGTH.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.label}
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.key,
                data[
                  WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_TOP.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.label}
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.key,
                data[
                  WELL_INFO_HEADER_ATTRIBUTES.PERF_INTERVAL_BOTTOM.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.ELEVATION_GROUND.label}
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.ELEVATION_GROUND.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.ELEVATION_GROUND.key,
                data[WELL_INFO_HEADER_ATTRIBUTES.ELEVATION_GROUND.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE
                .label
            }
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE
                .key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE
                  .key,
                data[
                  WELL_INFO_HEADER_ATTRIBUTES
                    .CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.label
            }
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.key,
                data[
                  WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_HORIZONTAL_DISTANCE.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.label}
            value={buildPanelAttributeVal(
              WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.key,
              `${formatAttributeByKey(
                WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.key,
                data[
                  WELL_INFO_HEADER_ATTRIBUTES.CLOSEST_VERTICAL_DISTANCE.key
                ] as number
              )}`
            )}
          />
        </div>
      </div>
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.key,
              data[
                WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_PARENT_WELLS.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.key,
              data[
                WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_SIBLING_WELLS.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.label}
            value={formatAttributeByKey(
              WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.key,
              data[
                WELL_INFO_HEADER_ATTRIBUTES.COUNT_OF_CHILD_WELLS.key
              ] as number
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default Header;
