import { ChartDataFields } from "../../types/charts/chartType/chartType";

import * as attribute from "../attributes";
import { CHART_ACCUMULATION_METHOD, CHART_DATA_SOURCE } from "./charts";

export const OIL_MONTHLY: ChartDataFields = {
  name: "Oil",
  displayName: "Oil (Monthly)",
  dailyDisplayName: "Oil (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#287D3C",
  hasForecast: true,
  hasDaily: true,
  hasLateralLength: true,
  responseData: {
    name: "oil",
    origin: "mdata",
  },
};

export const OIL_CUMULATIVE: ChartDataFields = {
  name: "Oil",
  displayName: "Oil (Cumulative)",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#287D3C",
  hasForecast: true,
  hasDaily: false,
  hasLateralLength: true,
  responseData: {
    name: "oil",
    origin: "cdata",
  },
};

export const GAS_MONTHLY: ChartDataFields = {
  name: "Gas",
  displayName: "Gas (Monthly)",
  dailyDisplayName: "Gas (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#E62828",
  hasForecast: true,
  hasDaily: true,
  hasLateralLength: true,
  responseData: {
    name: "gas",
    origin: "mdata",
  },
};

export const GAS_CUMULATIVE: ChartDataFields = {
  name: "Gas",
  displayName: "Gas (Cumulative)",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#E62828",
  hasForecast: true,
  hasDaily: false,
  hasLateralLength: true,
  responseData: {
    name: "gas",
    origin: "cdata",
  },
};

export const BOE_MONTHLY: ChartDataFields = {
  name: "Boe",
  displayName: "BOE (Monthly)",
  dailyDisplayName: "BOE (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#84FFFF",
  hasForecast: true,
  hasDaily: true,
  hasLateralLength: true,
  responseData: {
    name: "bOE",
    origin: "mdata",
  },
};

export const BOE_CUMULATIVE: ChartDataFields = {
  name: "Boe",
  displayName: "BOE (Cumulative)",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#84FFFF",
  hasForecast: true,
  hasDaily: false,
  hasLateralLength: true,
  responseData: {
    name: "bOE",
    origin: "cdata",
  },
};

export const WATER_MONTHLY: ChartDataFields = {
  name: "Water",
  displayName: "Water (Monthly)",
  dailyDisplayName: "Water (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#2962FF",
  hasForecast: true,
  hasDaily: true,
  hasLateralLength: true,
  responseData: {
    name: "wtr",
    origin: "mdata",
  },
};

export const WATER_CUMULATIVE: ChartDataFields = {
  name: "Water",
  displayName: "Water (Cumulative)",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#2962FF",
  hasForecast: true,
  hasDaily: false,
  hasLateralLength: true,
  responseData: {
    name: "wtr",
    origin: "cdata",
  },
};

export const DAYS_ON_PRODUCTION: ChartDataFields = {
  name: "DaysOnProduction",
  displayName: "Days On Production",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#00AAAB",
  hasForecast: false,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "dOP",
    origin: "mdata",
  },
};

export const WATER_OIL_RATIO: ChartDataFields = {
  name: "WaterOilRatio",
  displayName: "Water-Oil Ratio",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#5500AB",
  hasForecast: true,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "wtrOR",
    origin: "mdata",
  },
};

export const WATER_CUT: ChartDataFields = {
  name: "WaterCut",
  displayName: "Water Cut",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#AA7878",
  hasForecast: true,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "wtrC",
    origin: "mdata",
  },
};

export const GAS_OIL_RATIO: ChartDataFields = {
  name: "GasOilRatio",
  displayName: "Gas-Oil Ratio",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.PRODUCTION,
  color: "#8F44EE",
  hasForecast: true,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "gasOR",
    origin: "mdata",
  },
};

export const TOTAL_INJECTED_LIQUID_MONTHLY: ChartDataFields = {
  name: "TotalLiquid",
  displayName: "Total Injected Liquid (Monthly)",
  dailyDisplayName: "Total Injected Liquid (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.INJECTION,
  color: "#C06B15",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "tLqd",
    origin: "mdata",
  },
};

export const TOTAL_INJECTED_LIQUID_CUMULATIVE: ChartDataFields = {
  name: "TotalLiquid",
  displayName: "Total Injected Liquid (Cumulative)",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
  dataSource: CHART_DATA_SOURCE.INJECTION,
  color: "#C06B15",
  hasForecast: false,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "tLqd",
    origin: "cdata",
  },
};

export const TOTAL_INJECTED_GAS_MONTHLY: ChartDataFields = {
  name: "TotalGas",
  displayName: "Total Injected Gas (Monthly)",
  dailyDisplayName: "Total Injected Gas (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.INJECTION,
  color: "#85BA85",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "tGas",
    origin: "mdata",
  },
};

export const TOTAL_INJECTED_GAS_CUMULATIVE: ChartDataFields = {
  name: "TotalGas",
  displayName: "Total Injected Gas (Cumulative)",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.CUMULATIVE,
  dataSource: CHART_DATA_SOURCE.INJECTION,
  color: "#85BA85",
  hasForecast: false,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "tGas",
    origin: "cdata",
  },
};

export const AVG_INJ_PRESSURE: ChartDataFields = {
  name: "AvInjPressure",
  displayName: "Average Injection Pressure",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.INJECTION,
  color: "#FECB78",
  hasForecast: false,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "aIP",
    origin: "mdata",
  },
};

export const MAX_INJ_PRESSURE: ChartDataFields = {
  name: "MaxInjPressure",
  displayName: "Max Injection Pressure",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.INJECTION,
  color: "#6D7085",
  hasForecast: false,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "mIP",
    origin: "mdata",
  },
};

export const DAYS_ON_INJECTION: ChartDataFields = {
  name: "DaysOnInjection",
  displayName: "Days On Injection",
  dailyDisplayName: "",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.INJECTION,
  color: "#747500",
  hasForecast: false,
  hasDaily: false,
  hasLateralLength: false,
  responseData: {
    name: "dOI",
    origin: "mdata",
  },
};

export const TOTAL_DISPOSED: ChartDataFields = {
  name: "TotalDisposed",
  displayName: "Total Disposed Volume",
  dailyDisplayName: "Total Disposed Volume (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#CE93D8",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "tD",
    origin: "mdata",
  },
};

export const VENT: ChartDataFields = {
  name: "Vent",
  displayName: "Vent",
  dailyDisplayName: "Vent (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#020075",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "vnt",
    origin: "mdata",
  },
};

export const FLARE: ChartDataFields = {
  name: "Flare",
  displayName: "Flare",
  dailyDisplayName: "Flare (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#DBFF00",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "flr",
    origin: "mdata",
  },
};

export const VENT_FLARE: ChartDataFields = {
  name: "VentFlare",
  displayName: "Vent or Flare",
  dailyDisplayName: "Vent or Flare (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#003660",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "vntFlr",
    origin: "mdata",
  },
};

export const TOTAL_USED: ChartDataFields = {
  name: "TotalUsed",
  displayName: "Total Used Volume",
  dailyDisplayName: "Total Used Volume (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#FA0272",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "tUsed",
    origin: "mdata",
  },
};

export const USED: ChartDataFields = {
  name: "Used",
  displayName: "Used",
  dailyDisplayName: "Used (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#612A00",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "used",
    origin: "mdata",
  },
};

export const INFERRED: ChartDataFields = {
  name: "Inferred",
  displayName: "Inferred",
  dailyDisplayName: "Inferred (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#F17B93",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "inf",
    origin: "mdata",
  },
};

export const OTHER: ChartDataFields = {
  name: "Other",
  displayName: "Other",
  dailyDisplayName: "Other (Daily)",
  accumMethod: CHART_ACCUMULATION_METHOD.MONTHLY,
  dataSource: CHART_DATA_SOURCE.VENTFLARE,
  color: "#F9A01B",
  hasForecast: false,
  hasDaily: true,
  hasLateralLength: false,
  responseData: {
    name: "othr",
    origin: "mdata",
  },
};

export const PRODUCTION_SOURCE_DATA_FIELDS: ChartDataFields[] = [
  OIL_MONTHLY,
  OIL_CUMULATIVE,
  GAS_MONTHLY,
  GAS_CUMULATIVE,
  BOE_MONTHLY,
  BOE_CUMULATIVE,
  WATER_MONTHLY,
  WATER_CUMULATIVE,
  DAYS_ON_PRODUCTION,
  WATER_OIL_RATIO,
  WATER_CUT,
  GAS_OIL_RATIO,
];

export const INJECTION_SOURCE_DATA_FIELDS: ChartDataFields[] = [
  TOTAL_INJECTED_LIQUID_MONTHLY,
  TOTAL_INJECTED_LIQUID_CUMULATIVE,
  TOTAL_INJECTED_GAS_MONTHLY,
  TOTAL_INJECTED_GAS_CUMULATIVE,
  MAX_INJ_PRESSURE,
  AVG_INJ_PRESSURE,
  DAYS_ON_INJECTION,
];

export const VENTFLARE_SOURCE_DATA_FIELDS: ChartDataFields[] = [
  TOTAL_DISPOSED,
  VENT,
  FLARE,
  VENT_FLARE,
  TOTAL_USED,
  USED,
  INFERRED,
];

export const PRODUCTION_DATA_FIELDS_LIST: ChartDataFields[] = [
  ...PRODUCTION_SOURCE_DATA_FIELDS,
  ...INJECTION_SOURCE_DATA_FIELDS,
  ...VENTFLARE_SOURCE_DATA_FIELDS,
];

export const PRODUCTION_GROUP_BY_ATTRIBUTES = [
  { name: attribute.COUNTY.key, displayName: attribute.COUNTY.label },
  { name: attribute.SURFACE_UWI.key, displayName: attribute.SURFACE_UWI.label },
  { name: attribute.FIELD_NAME.key, displayName: "Field" },
  { name: attribute.BASIN_NAME.key, displayName: attribute.BASIN_NAME.label },
  {
    name: attribute.DISPLAY_FORMATION.key,
    displayName: attribute.DISPLAY_FORMATION.label,
  },
  {
    name: attribute.INTERPRETED_PRODUCING_FORMATION.key,
    displayName: attribute.INTERPRETED_PRODUCING_FORMATION.label,
  },
  {
    name: attribute.OPERATOR_NAME.key,
    displayName: attribute.OPERATOR_NAME.label,
  },
  {
    name: attribute.ULTIMATE_OWNER.key,
    displayName: attribute.ULTIMATE_OWNER.label,
  },
  { name: "OriginalOperator", displayName: "Original Operator" },
  { name: attribute.LEASE_NAME.key, displayName: attribute.LEASE_NAME.label },
  { name: "LeaseID", displayName: attribute.LEASE_NAME_AND_ID.label },
  { name: attribute.WELL_STATUS.key, displayName: attribute.WELL_STATUS.label },
  { name: attribute.WELL_TYPE.key, displayName: attribute.WELL_TYPE.label },
  { name: attribute.SLANT.key, displayName: attribute.SLANT.label },
  { name: "CalculatedMajorPhase", displayName: "Major Phase" },
  {
    name: attribute.LAST_INJECTION_TYPE.key,
    displayName: attribute.LAST_INJECTION_TYPE.label,
  },
  {
    name: attribute.LAST_INJECTION_FORMATION.key,
    displayName: attribute.LAST_INJECTION_FORMATION.label,
  },
  {
    name: attribute.DISPOSITION_TYPE.key,
    displayName: attribute.DISPOSITION_TYPE.label,
  },
];

export const DEFAULT_PRODUCTION_Y_FIELDS = [
  ...PRODUCTION_DATA_FIELDS_LIST.filter(
    (dataField) =>
      (dataField.name === OIL_MONTHLY.name &&
        dataField.accumMethod === OIL_MONTHLY.accumMethod) ||
      (dataField.name === GAS_MONTHLY.name &&
        dataField.accumMethod === GAS_MONTHLY.accumMethod) ||
      (dataField.name === WATER_MONTHLY.name &&
        dataField.accumMethod === WATER_MONTHLY.accumMethod)
  ),
];
