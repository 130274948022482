import * as attributes from "../../constants/charts/productionPlotDataFields";

const productionDataAttributesInBbl = [
  attributes.OIL_MONTHLY.name.toLowerCase(),
  attributes.OIL_CUMULATIVE.name.toLowerCase(),
  attributes.WATER_MONTHLY.name.toLowerCase(),
  attributes.WATER_CUMULATIVE.name.toLowerCase(),
  attributes.TOTAL_INJECTED_LIQUID_MONTHLY.name.toLowerCase(),
  attributes.TOTAL_INJECTED_LIQUID_CUMULATIVE.name.toLowerCase(),
];

const productionDataAttributesInMcf = [
  attributes.GAS_MONTHLY.name.toLowerCase(),
  attributes.GAS_CUMULATIVE.name.toLowerCase(),
  attributes.TOTAL_INJECTED_GAS_MONTHLY.name.toLowerCase(),
  attributes.TOTAL_INJECTED_GAS_CUMULATIVE.name.toLowerCase(),
  attributes.TOTAL_DISPOSED.name.toLowerCase(),
  attributes.VENT.name.toLowerCase(),
  attributes.FLARE.name.toLowerCase(),
  attributes.VENT_FLARE.name.toLowerCase(),
  attributes.USED.name.toLowerCase(),
  attributes.INFERRED.name.toLowerCase(),
];

const productionDataAttributesInBoe = [
  attributes.BOE_MONTHLY.name.toLowerCase(),
  attributes.BOE_CUMULATIVE.name.toLowerCase(),
];

const productionDataAttributesInDays = [
  attributes.DAYS_ON_PRODUCTION.name.toLowerCase(),
  attributes.DAYS_ON_INJECTION.name.toLowerCase(),
];

const productionDataAttributesInScfPerBbl = [
  attributes.GAS_OIL_RATIO.name.toLowerCase(),
];

const productionDataAttributesInPsi = [
  attributes.MAX_INJ_PRESSURE.name.toLowerCase(),
  attributes.AVG_INJ_PRESSURE.name.toLowerCase(),
];

export {
  productionDataAttributesInBbl,
  productionDataAttributesInMcf,
  productionDataAttributesInBoe,
  productionDataAttributesInDays,
  productionDataAttributesInScfPerBbl,
  productionDataAttributesInPsi,
};
