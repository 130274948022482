import { StateCreator } from "zustand";

import { UWIR360 } from "../../types/exports/export-card/exportR360State";

const exportR360Slice: StateCreator<UWIR360> = (set, get) => ({
  uwiR360: [],
  updateUwiR360: (uwiR360) => set((state) => ({ uwiR360 })),
  openR360Modal: false,
  updateOpenR360modal: (openR360Modal) => set((state) => ({ openR360Modal })),
});

export default exportR360Slice;
