import { FC, SVGProps } from "react";

import Logo from "../../../assets/images/TGS_Logo_White_2024.png";

interface TGSLogoProp extends SVGProps<SVGAElement> {
  textFill?: string;
}

const TGSLogo: FC<TGSLogoProp> = ({}) => {
  return <img src={Logo} alt="TGS Logo" />;
};

export default TGSLogo;
