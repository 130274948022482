import { FC } from "react";

import { withRouter } from "../../utils/routes/withRouter";

import LeftNavigationPanel from "../panels/LeftNavigationPanel";
import SidebarNavPanel from "../panels/SidebarNavPanel";
import AppHeader from "./AppHeader";

const AppLayout: FC<{ children: React.ReactNode }> = (props) => {
  return (
    <>
      <AppHeader />
      <main role="main">
        <div className="app-layout">
          <LeftNavigationPanel />
          <SidebarNavPanel />
          {props.children}
        </div>
      </main>
    </>
  );
};

export default withRouter(AppLayout);
