import { getGridStringOperators } from "@mui/x-data-grid-premium";

import {
  FILTER_AFTER_OPERATOR,
  FILTER_BEFORE_OPERATOR,
  FILTER_BETWEEN_OPERATOR,
  FILTER_GREATER_THAN_OPERATOR,
  FILTER_GREATER_THAN_OR_EQUAL_TO_OPERATOR,
  FILTER_LESS_THAN_OPERATOR,
  FILTER_LESS_THAN_OR_EQUAL_TO_OPERATOR,
  FILTER_YEAR_OPERATOR,
} from "../../constants/grid";

export const getNumberFilterOperators = () => {
  //add new customized filter operators to grid
  return getGridStringOperators().concat([
    FILTER_BETWEEN_OPERATOR,
    FILTER_GREATER_THAN_OPERATOR,
    FILTER_GREATER_THAN_OR_EQUAL_TO_OPERATOR,
    FILTER_LESS_THAN_OPERATOR,
    FILTER_LESS_THAN_OR_EQUAL_TO_OPERATOR,
  ]);
};

export const getDateFilterOperators = () => {
  return getGridStringOperators().concat([
    FILTER_BETWEEN_OPERATOR,
    FILTER_AFTER_OPERATOR,
    FILTER_BEFORE_OPERATOR,
    FILTER_YEAR_OPERATOR,
  ]);
};
