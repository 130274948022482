import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";

import { CHART_TYPES } from "../../constants/charts/charts";

import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";

const useChartReference = () => {
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );

  const getIDsReference = useCallback(
    (chartType: ChartType) => {
      if (
        chartType === CHART_TYPES.PRODUCTION_PLOT ||
        chartType === CHART_TYPES.TYPE_CURVE
      ) {
        return selectedWellIdsKeys;
      } else {
        return selectedGridDataKeys;
      }
    },
    [selectedWellIdsKeys, selectedGridDataKeys]
  );

  return { getIDsReference };
};

export default useChartReference;
