import { ShareLocation as ShareLocationIcon } from "@mui/icons-material";
import { Chip, Divider, Typography } from "@mui/material";

import { PolygonData } from "../../../../../types/map/gis/common";

import { SHAPE_FILE_UPLOAD_FILE_EXTENSIONS } from "../../../../../constants/map/shapefile";

import useMapDrawStore from "../../../../../store/map/draw/mapDrawStore";
import useMapStore from "../../../../../store/map/mapStore";
import usePanelsStore from "../../../../../store/panels/panelsStore";

import useShapeFile from "../../../../../customHooks/map/shapefile/useShapeFile";
import useDrawPolygon from "../../../../../customHooks/search/useDrawPolygon";

import DrawPolygon from "../../../../charts/utils/DrawPolygon";

import ButtonUpload2 from "../../../../common/ButtonUpload2";
import Loading from "../../../../common/Loading";

import * as freeformDrawIconURL from "../../../../../assets/images/freeform_polygon.png";

export const PolygonSearch = () => {
  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const polygons = useMapDrawStore((state) => state.polygons);
  const drawLayerSource = useMapStore((state) => state.drawLayerSource);

  const {
    drawPolygonDynamically,
    removeDrawnPolygon,
    removePolygonFromSource,
  } = useDrawPolygon();

  const {
    isUploadingShapeFile,
    removeAndUploadShapeFilePolygons,
    handleUploadShapeFile,
  } = useShapeFile({
    drawPolygonDynamically,
  });

  const handleLoadShapeClick = () => {
    if (isOpenQBEditorPanel) {
      toggleIsOpenQBEditorPanel(false);
    }
  };

  const handlePolygonDelete = (polygon: PolygonData) => {
    if (polygon.isShapeFile) {
      removeAndUploadShapeFilePolygons(polygon);
    } else {
      removeDrawnPolygon(polygon);
    }
    removePolygonFromSource(polygon, drawLayerSource);
  };

  return (
    <div className="polygon-search">
      <div className="polygon-search-header">
        <ShareLocationIcon />
        <Typography>SHAPES</Typography>
        <span className="shapes-count">{polygons.length}</span>
      </div>
      <div className="polygon-search-content">
        {polygons.length ? (
          polygons.map((polygon) => (
            <Chip
              key={polygon.id}
              icon={
                <img
                  src={freeformDrawIconURL.default}
                  className="draw-controls-icon"
                  aria-label="free-form"
                  color="#8dc6f7"
                />
              }
              label={polygon.id}
              onDelete={() => handlePolygonDelete(polygon)}
              disabled={isUploadingShapeFile && polygon.isShapeFile}
              deleteIcon={
                isUploadingShapeFile && polygon.isShapeFile ? (
                  <Loading size={20} />
                ) : undefined
              }
            />
          ))
        ) : (
          <div className="polygon-search-empty">
            <Typography variant="h6">Add shapes to current search</Typography>
          </div>
        )}
      </div>
      <div className="polygon-buttons-container">
        <ButtonUpload2
          label="LOAD SHAPE"
          icon="addLocation"
          allowedFileExtension={SHAPE_FILE_UPLOAD_FILE_EXTENSIONS}
          handleFileChange={handleUploadShapeFile}
          buttonClickCallback={handleLoadShapeClick}
        />
        <Divider orientation="vertical" flexItem />
        <DrawPolygon />
      </div>
    </div>
  );
};

export default PolygonSearch;
