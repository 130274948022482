import { noData } from "../../constants/constants";

import useAttributeUnit from "../common/useAttributeUnit";

const usePanelAttributeUnits = () => {
  const { getAttributeUnit } = useAttributeUnit();

  const buildPanelAttributeVal = (
    attributeKey: string,
    attributeVal?: string
  ) => {
    if (attributeVal === noData || attributeVal === undefined)
      return attributeVal;

    return `${attributeVal} ${getAttributeUnit(attributeKey)}`;
  };
  return { buildPanelAttributeVal };
};

export default usePanelAttributeUnits;
