import { FC, useEffect, useState } from "react";

import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";
import { DatalakePressureDataResponseBody } from "../../../../types/panels/wellPanel/dataLakeType";

import { SORT_ORDER } from "../../../../constants/dataLake";
import { dataLakeSize } from "../../../../constants/panels/wellPanel/logs";
import {
  PRESSURE_DATA_FIELDS,
  PRESSURE_DATA_SEARCHTYPES,
  PRESSURE_DATA_SORT_FIELDS,
} from "../../../../constants/panels/wellPanel/pressureData";
import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useDatalake from "../../../../customHooks/panels/wellPanel/useDatalake";
import usePressureData from "../../../../customHooks/panels/wellPanel/usePressureData";

import { convertUWI } from "../../../../utils/common/wells";

import WellPanelAccordion from "../common/WellPanelAccordion";
import WellPanelSectionAccordion from "../common/WellPanelSectionAccordion";
import PressureDataSection from "../common/sectionAccordion/PressureDataSection";
import NoDataSection from "./NoDataSection";

const PressureData: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  layer,
  isExpanded,
  onChange,
}) => {
  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const updateIsPressureDataLoading = usePanelsStore(
    (state) => state.updateIsPressureDataLoading
  );

  const { dataLakeResponse, loadingRequest, successRequest, getDataLakeData } =
    useDatalake();

  const { buildPressureData } = usePressureData();

  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);

  useEffect(() => {
    if (successRequest && dataLakeResponse) {
      const mappedData = buildPressureData(
        dataLakeResponse as DatalakePressureDataResponseBody[]
      );

      updateWellCardDataByTypeAndKey(
        { type: "pressuredata", pressureData: mappedData },
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsPressureDataLoading(false);
    }
  }, [dataLakeResponse, successRequest]);

  useEffect(() => {
    if (wellData.pageNumber === activePage && !wellData.pressureData) {
      const requestBody = {
        SearchTypes: PRESSURE_DATA_SEARCHTYPES,
        Query: `Uwi:${convertUWI(wellData.wellCardData.uwi as string, -2)}`,
        IncludeFields: PRESSURE_DATA_FIELDS,
        From: 0, // from count to start at (paging)
        Size: dataLakeSize,
        Sort: {
          SortOrder: SORT_ORDER.ASC,
          SortPropertyNames: PRESSURE_DATA_SORT_FIELDS,
        },
      };
      getDataLakeData(requestBody);
      updateIsPressureDataLoading(true);
    }
  }, [wellData.pageNumber, wellData.pressureData, activePage]);

  useEffect(() => {
    setExpandedAccordions([]);
  }, [activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.PRESSURE_DATA_SECTION);
  };

  const onClick = (shouldExpandAll: boolean) => {
    const accordionList: string[] = [];

    if (shouldExpandAll) {
      wellData.pressureData?.forEach((dstData) =>
        accordionList.push(dstData.TestName)
      );
      setExpandedAccordions(accordionList);
    } else {
      setExpandedAccordions([]);
    }
  };

  const onAccordionClicked = (testName: string) => {
    if (expandedAccordions.includes(testName)) {
      setExpandedAccordions(
        expandedAccordions.filter((accordionName) => accordionName !== testName)
      );
    } else {
      setExpandedAccordions([...expandedAccordions, testName]);
    }
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.PRESSURE_DATA_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={loadingRequest}
    >
      {Boolean(wellData.pressureData?.length) ? (
        <WellPanelSectionAccordion
          onClick={onClick}
          expandedAccordions={expandedAccordions}
          totalItems={wellData?.pressureData?.length ?? 0}
        >
          <PressureDataSection
            data={wellData?.pressureData ?? []}
            expandedAccordions={expandedAccordions}
            accordionClicked={onAccordionClicked}
          />
        </WellPanelSectionAccordion>
      ) : (
        <NoDataSection
          message={
            <>
              Subscribe to DSTs to view <br /> Pressure Data
            </>
          }
        />
      )}
    </WellPanelAccordion>
  );
};

export default PressureData;
