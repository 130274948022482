import { GridColDef } from "@mui/x-data-grid-premium";

import * as category from "./wellFileCategory";

export const docsSearchType = "WellFilePage" as const;

export const docsPanelColumns: GridColDef[] = [
  {
    field: "docType",
    headerName: "Doc Type",
    width: 280,
  },
  {
    field: "pageCount",
    headerName: "Page Count",
    width: 100,
    type: "number",
  },
];

export const DOCS_WELL_FILE_CATEGORIES = [
  category.CASING_REPORTS_CATEGORY,
  category.COMPLETION_REPORTS_CATEGORY,
  category.DIRECTIONAL_SURVEYS_CATEGORY,
  category.DRILLING_NARRATIVES_CATEGORY,
  category.DRILLIN_PLAN_SUMMARY_CATEGORY,
  category.GEOLOGIC_CORE_CATEGORY,
  category.INCLINATION_REPORTS_CATEGORY,
  category.PERMITS_CATEGORY,
  category.PLATS_MAPS_CATEGORY,
  category.PLUGGING_REPORTS_CATEGORY,
  category.SCOUT_CARDS_CATEGORY,
  category.SUNDRY_REPORTS_CATEGORY,
] as const;
