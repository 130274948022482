import { FC, useEffect, useMemo, useState } from "react";

import { Box } from "@mui/material";

import { WellEconomicsResponse } from "../../../../types/panels/wellPanel/wellEconomicsType";
import { WellSystemData } from "../../../../types/panels/wellPanel/wellPanel";

import { noData } from "../../../../constants/constants";
import * as econ from "../../../../constants/panels/wellPanel/wellEconomics";

import usePanelsStore from "../../../../store/panels/panelsStore";

import { formatWellEconomicsOutputValue } from "../../../../utils/panel/wellPanel/economics";

import Loading from "../../../common/Loading";
import CrossArrowIcon from "../../../common/icons/CrossArrowIcon";
import CurrencyExchangeIcon from "../../../common/icons/CurrencyExchangeIcon";
import HourGlassIcon from "../../../common/icons/HourGlassIcon";
import TimelineIcon from "../../../common/icons/TimelineIcon";

interface WellEconomicsOutputProp {
  wellData?: WellSystemData;
}

const WellEconomicsOutput: FC<WellEconomicsOutputProp> = ({ wellData }) => {
  const [economics, setEconomics] = useState<WellEconomicsResponse>();
  const isWellEconomicsLoading = usePanelsStore(
    (state) => state.isWellEconomicsLoading
  );

  const outputValues = useMemo(() => {
    return {
      irr: economics?.Outputs?.[econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.key]
        ? `${formatWellEconomicsOutputValue(
            economics.Outputs[econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.key],
            1
          )}${econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.unit}`
        : noData,
      breakevenPrice:
        economics?.Outputs?.[econ.WELL_ECONOMICS_BREAKEVEN_PRICE.key] &&
        formatWellEconomicsOutputValue(
          economics?.Outputs?.[econ.WELL_ECONOMICS_BREAKEVEN_PRICE.key],
          1
        ) !== noData
          ? `${formatWellEconomicsOutputValue(
              economics?.Outputs?.[econ.WELL_ECONOMICS_BREAKEVEN_PRICE.key],
              1
            )} ${econ.WELL_ECONOMICS_BREAKEVEN_PRICE.unit}`
          : noData,
      npr: economics?.Outputs?.[econ.WELL_ECONOMICS_NET_PRESENT_VALUE.key]
        ? `${
            econ.WELL_ECONOMICS_NET_PRESENT_VALUE.unit
          } ${formatWellEconomicsOutputValue(
            economics?.Outputs?.[econ.WELL_ECONOMICS_NET_PRESENT_VALUE.key],
            0,
            true
          )}`
        : noData,
      payout: economics?.Outputs?.[econ.WELL_ECONOMICS_PAYOUT.key]
        ? `${formatWellEconomicsOutputValue(
            economics?.Outputs?.[econ.WELL_ECONOMICS_PAYOUT.key],
            1
          )} ${econ.WELL_ECONOMICS_PAYOUT.unit}`
        : noData,
    };
  }, [economics]);

  useEffect(() => {
    if (wellData?.wellEconomicsData) {
      if (wellData.wellEconomicsData.recalculatedValues) {
        setEconomics(wellData.wellEconomicsData.recalculatedValues);
      } else {
        setEconomics(wellData.wellEconomicsData.defaultValues);
      }
    }
  }, [wellData?.wellEconomicsData]);

  return (
    <Box
      className={`well-economics-output-container ${
        !isWellEconomicsLoading ? "disabled-container" : ""
      } ${
        wellData?.wellEconomicsData?.recalculatedValues
          ? "recalculate-highlight"
          : ""
      } `}
    >
      <div className="output-item InternalRateOfReturn">
        <TimelineIcon />
        <div className="output-text">
          <p className="econ-label">
            {econ.WELL_ECONOMICS_INTERNAL_RATE_RETURN.label}
          </p>
          <p className="econ-value">
            {isWellEconomicsLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.irr}</>
            )}
          </p>
        </div>
      </div>
      <div className="output-item BreakEvenPrice">
        <CrossArrowIcon />

        <div className="output-text">
          <p className="econ-label">
            {econ.WELL_ECONOMICS_BREAKEVEN_PRICE.label}
          </p>
          <p className="econ-value">
            {isWellEconomicsLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.breakevenPrice}</>
            )}
          </p>
        </div>
      </div>
      <div className="output-item NetPresentValue">
        <CurrencyExchangeIcon />
        <div className="output-text">
          <p className="econ-label">
            {econ.WELL_ECONOMICS_NET_PRESENT_VALUE.label}
          </p>
          <p className="econ-value">
            {isWellEconomicsLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.npr}</>
            )}
          </p>
        </div>
      </div>
      <div className="output-item Payout">
        <HourGlassIcon />
        <div className="output-text">
          <p className="econ-label">{econ.WELL_ECONOMICS_PAYOUT.label}</p>
          <p className="econ-value">
            {isWellEconomicsLoading ? (
              <Loading className="loading-spinner" size={15} />
            ) : (
              <>{outputValues.payout}</>
            )}
          </p>
        </div>
      </div>
    </Box>
  );
};

export default WellEconomicsOutput;
