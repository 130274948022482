import { useEffect } from "react";
import { useParams } from "react-router-dom";

import config from "../../configs/appSettings";

export const DocsViewer = () => {
  const { uwi10 } = useParams();
  let access_token = "";
  const oktaLocalStorage = localStorage.getItem("okta-token-storage");

  if (oktaLocalStorage) {
    const { accessToken } = JSON.parse(
      localStorage.getItem("okta-token-storage") ?? "{}"
    );

    access_token = accessToken.accessToken;
  }

  const handleMessageListener = (event: any) => {
    if (event.data === "well-docs-viewer-token") {
      event.source?.postMessage(
        {
          action: "returnData",
          key: "well-docs-viewer-token",
          value: access_token,
        },
        "*"
      );
    } else if (event.data === "well-docs-viewer-uwi") {
      event.source?.postMessage(
        {
          action: "returnData",
          key: "well-docs-viewer-uwi",
          value: uwi10,
        },
        "*"
      );
    }
  };

  useEffect(() => {
    window.addEventListener(
      "message",
      (event: MessageEvent) => {
        if (
          event.origin === config.DOCS_VIEWER_URL ||
          event.origin === window.origin
        ) {
          handleMessageListener(event);
        }
      },
      false
    );

    return () => window.removeEventListener("message", handleMessageListener);
  });

  return (
    <iframe
      title="docs-viewer"
      src={config.DOCS_VIEWER_URL}
      style={{
        width: "100%",
        height: "100%",
        position: "absolute",
        border: "none",
      }}
    ></iframe>
  );
};

export default DocsViewer;
