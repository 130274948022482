import { Accordion } from "component-library";

import {
  LAYER_NAME_TO_LABEL_MAPPER,
  MAP_SEARCH_PIPELINES,
} from "../../../constants/constants";

import LineSymbol from "./common/LineSymbol";

const items = [
  { color: "#76FF03", label: "Gathering" },
  { color: "#C62828", label: "Interstate" },
  { color: "#2979FF", label: "Intrastate" },
  { color: "#808080", label: "All Others" },
];

const MapSearchPipelinesLegend = () => {
  return (
    <Accordion
      title={LAYER_NAME_TO_LABEL_MAPPER[MAP_SEARCH_PIPELINES]}
      className="layer-info-accordion"
      isDefaultOpen
    >
      {items.map((d) => (
        <div key={d.label} className="layer-info-item">
          <div className="layer-info-symbol">
            <LineSymbol color={d.color} />
          </div>
          <div className="layer-info-label">{d.label}</div>
        </div>
      ))}
    </Accordion>
  );
};

export default MapSearchPipelinesLegend;
