import { useCallback, useEffect, useState } from "react";

import { AxiosError, AxiosResponse } from "axios";

import { APIErrorResponse, SearchRequestPayload } from "../types/common/api";
import {
  BaseSearchCriteria,
  WellCountDataPayload,
} from "../types/common/search";
import { GetWellCountDataResponse } from "../types/common/useSearchData";
import { SearchTypes } from "../types/panels/searchPanel/search";

import config from "../configs/appSettings";

import { SEARCH_TYPES } from "../constants/panels/searchPanel/search";

import useUWISearchStore from "../store/search/uwiSearch/searchUWIStore";

import { callServiceAPI } from "../action/callServiceAPI";
import useSearchRequest from "./common/useSearchRequest";
import useUwiFileUpload from "./search/useUwiFileUpload";

const useWellCountData = () => {
  const [isLoading, setIsLoading] = useState(false);
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const updateIsLoadingUwiExpectedCount = useUWISearchStore(
    (state) => state.updateIsLoadingUwiExpectedCount
  );
  const hasErrorUploadingUWI = useUWISearchStore(
    (state) => state.hasErrorUploadingUWI
  );
  const [dataPromise, setDataPromise] =
    useState<
      Promise<
        | AxiosError<APIErrorResponse, any>
        | AxiosResponse<GetWellCountDataResponse, any>
        | undefined
      >
    >();
  const [searchType, setSearchType] = useState<SearchTypes>();
  const [data, setData] = useState(0);
  const [error, setError] = useState(null);
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { buildSearchRequestByParam } = useSearchRequest();

  const updateLoadingState = useCallback(
    (searchType: SearchTypes | undefined, status: boolean) => {
      if (
        searchType === SEARCH_TYPES.UWI_SEARCH ||
        searchType === SEARCH_TYPES.UWI_FILE_SEARCH ||
        searchType === SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH
      ) {
        updateIsLoadingUwiExpectedCount(status);
      }
    },
    [updateIsLoadingUwiExpectedCount]
  );

  const awaitDataPromise = async (
    dataPromise: Promise<
      | AxiosError<APIErrorResponse, any>
      | AxiosResponse<GetWellCountDataResponse, any>
      | undefined
    >
  ) => {
    const response = (await dataPromise) as AxiosResponse<
      GetWellCountDataResponse,
      any
    >;

    if (response?.data) {
      setData(response.data.totalCount);
    } else {
      setData(0);
    }
    setIsLoading(false);
    updateLoadingState(searchType, false);
  };

  useEffect(() => {
    if (dataPromise) {
      awaitDataPromise(dataPromise);
    } else {
      setData(0);
      setIsLoading(false);
      updateLoadingState(searchType, false);
    }
  }, [dataPromise]);

  let debouncedGetExpectedWellCountTimer: NodeJS.Timeout;
  const getExpectedWellCount = async ({
    drawnPolygons = [],
    bounds = [],
    searchedUWIs = [],
    shapeId = "",
    fileId = "",
    fetchedSavedSearchTrigger = false,
    isMetric,
    searchType,
  }: WellCountDataPayload) => {
    clearTimeout(debouncedGetExpectedWellCountTimer);

    debouncedGetExpectedWellCountTimer = setTimeout(() => {
      setSearchType(searchType);
      setIsLoading(true);
      updateLoadingState(searchType, true);
      setData(0);
      setError(null);

      // Update grid total count only after loading a saved search
      if (fetchedSavedSearchTrigger) {
        setData(0);
        setIsLoading(false);
        updateLoadingState(searchType, false);
        return;
      }

      if (hasErrorUploadingUWI) return;

      const requestBodySearchCriteria: BaseSearchCriteria = {
        drawnPolygons,
        shapeId,
        currentBounds: bounds,
        fileId,
        searchedUWIs,
      };

      const searchRequestBody = buildSearchRequestByParam(
        requestBodySearchCriteria
      );

      const body: SearchRequestPayload = {
        ...searchRequestBody,
        offset: 0,
        pageLimit: 1,
        columns: ["ParentWellID", "UWI"],
        ...(config.isUoMEnabled && { isMetric }),
      };

      try {
        if (
          !drawnPolygons.length &&
          !shapeId &&
          !bounds.length &&
          !fileId &&
          !searchedUWIs.length
        ) {
          setData(0);
          setIsLoading(false);
          updateLoadingState(searchType, false);
          return;
        }
        const totalCountUrl = `${config.endpoints.wellService}api/wells/count`;

        const response = callServiceAPI<GetWellCountDataResponse>(
          totalCountUrl,
          body,
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          !!fileId
        );

        setDataPromise(response);
      } catch (e) {
        console.error(e);
      }
    }, 100);
  };

  return {
    isLoading,
    data,
    error,
    setData,
    getExpectedWellCount,
  };
};

export default useWellCountData;
