import { memo } from "react";

import { AppBar } from "@mui/material";

import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";

import useModularityStore from "../../../../store/modularity/modularityStore";

import FullScreenView from "../../../common/FullScreenView";
import AnalysisChartModularWindow from "../../../modularity/AnalysisChartModularWindow";
import AnalysisChartsPanelHeader from "../AnalysisChartsPanelHeader";

const AnalysisChartsPanelFullScreen = () => {
  const isFullScreenAnalysisPane = useModularityStore(
    (state) => state.isFullScreenAnalysisPane
  );
  const chartMode = CHART_MODE_VIEW.FULLSCREEN_ANALYSIS;

  return (
    <>
      <FullScreenView
        showCloseIcon={false}
        open={isFullScreenAnalysisPane}
        classname="fs-analysis-pane"
        header={
          <AppBar className="analysis-header-full-screen">
            <AnalysisChartsPanelHeader chartMode={chartMode} />
          </AppBar>
        }
      >
        <div className="react-grid-layout">
          <AnalysisChartModularWindow chartMode={chartMode} />
        </div>
      </FullScreenView>
    </>
  );
};

export default memo(AnalysisChartsPanelFullScreen);
