import { useEffect, useState } from "react";

const useReactTimer = () => {
  const [iteration, setIteration] = useState(0);

  useEffect(() => {
    const iterationInterval = setInterval(() => {
      setIteration((prevTime) => prevTime + 1);
    }, 1000);
    return () => clearInterval(iterationInterval);
  }, []);

  return { iteration, setIteration };
};

export default useReactTimer;
