const saveSearchStore = (set, get) => ({
  // Loaded saved search name
  savedSearchFileName: "",
  updateSavedSearchFileName: (value) =>
    set((state) => ({ savedSearchFileName: value })),

  // Loaded saved search data
  selectedSavedSearchData: [],
  updateSelectedSavedSearchData: (selectedSavedSearchData) =>
    set((state) => ({ selectedSavedSearchData })),

  // true - when loading/fetching a saved search (before API call)
  // false - after parsing of selected save search data (after parsing of api response)
  fetchedSavedSearchTrigger: false,
  updateFetchedSavedSearchTrigger: (fetchedSavedSearchTrigger) =>
    set((state) => ({ fetchedSavedSearchTrigger })),

  // open state of save search dialog
  isOpenSaveSearchDialog: false,
  toggleIsOpenSaveSearchDialog: () =>
    set((state) => ({
      isOpenSaveSearchDialog: !state.isOpenSaveSearchDialog,
    })),

  resetSelectedSavedSearch: () =>
    set((state) => ({
      savedSearchFileName: "",
      selectedSavedSearchData: [],
    })),
});

export default saveSearchStore;
