import React, {
  FC,
  HTMLAttributes,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  Paper,
  TextField,
  createFilterOptions,
} from "@mui/material";

import { WellCounts } from "../../../../../../types/common/wells";
import {
  AutocompleteProps,
  QBTypeAheadComboBoxProps,
} from "../../../../../../types/panels/searchPanel/queryBuilder/autocomplete";

import { TEXT } from "../../../../../../constants/attributes";

import Loading from "../../../../../common/Loading";
import SortToggleButton from "./SortToggleButton";

const QBTypeAheadComboBox: FC<QBTypeAheadComboBoxProps> = ({
  options,
  value,
  onSelect = () => null,
  onClick = () => null,
  isMultiple = false,
  isOptionsLoading,
  label = "",
  inputRef,
  variant = "standard",
  styles = {},
  inputValue = "",
  onBlur,
  onInputChange = () => null,
  hasOnInputEvents = false,
  clearOnBlur = false,
  blurOnSelect = false,
  selectOnFocus = false,
  reqChars = 0,
  isDebouncing = false,
  attribute,
}) => {
  const listRef = useRef<HTMLUListElement>(null);
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    limit: 500,
  });

  const resetScrollPosition = () => {
    if (listRef.current) listRef.current.scroll({ top: 0 });
    setScrollPosition(0);
  };

  const handleBlur = (e: React.FocusEvent<HTMLDivElement, Element>) => {
    resetScrollPosition();
    if (onBlur) onBlur(e);
  };

  let additionalProps: AutocompleteProps = {};
  if (hasOnInputEvents) {
    additionalProps = {
      clearOnBlur,
      inputValue,
      onInputChange,
      onBlur: handleBlur,
      blurOnSelect,
      selectOnFocus,
    };

    if (isDebouncing) {
      additionalProps.noOptionsText = "Loading...";
    } else if (reqChars > 0 && inputValue.length < reqChars) {
      let charText = "character";
      if (reqChars > 1) charText += "s";
      additionalProps.noOptionsText = `Enter ${reqChars} ${charText}`;
    }
  }

  const valueText = useMemo(() => JSON.stringify(value), [value]);

  useEffect(() => {
    if (listRef.current) {
      listRef.current.scroll({ top: scrollPosition });
    }
  }, [valueText]);

  const handleSelect = (
    event: React.SyntheticEvent<Element, Event>,
    value: unknown,
    reason?: AutocompleteChangeReason,
    details?: AutocompleteChangeDetails<unknown> | undefined
  ) => {
    let isMultipleSelecting = false;
    const evt = event as React.PointerEvent | React.KeyboardEvent;
    if (evt.ctrlKey && listRef.current) {
      setScrollPosition(listRef.current.scrollTop);
      isMultipleSelecting = true;
    }

    onSelect(event, value, isMultipleSelecting);
  };

  const DropdownContainer = (props: HTMLAttributes<HTMLElement>) => {
    return (
      <Paper onMouseDown={(event) => event.preventDefault()} {...props}>
        {attribute && attribute.key && (
          <SortToggleButton
            attribute={attribute.key}
            applyNextSort={(sort) => {
              onClick(sort);
              if (!reqChars) resetScrollPosition();
            }}
          />
        )}
        {props.children}
      </Paper>
    );
  };

  return (
    <Autocomplete
      multiple={isMultiple}
      value={value}
      onChange={handleSelect}
      id="tags-outlined"
      options={options}
      filterOptions={filterOptions}
      getOptionLabel={(option) => (option as WellCounts).attributeValue ?? ""}
      isOptionEqualToValue={(option, value) =>
        (option as WellCounts).attributeValue ===
        (value as WellCounts).attributeValue
      }
      ListboxProps={{ ref: listRef }}
      loading={isOptionsLoading}
      classes={{ root: "autocomplete-root" }}
      sx={styles}
      size="small"
      ChipProps={{ className: "qb-chip selected" }}
      renderInput={(params) => (
        <TextField
          variant={variant}
          className="autocomplete-textfield-root"
          inputRef={inputRef}
          label={label}
          {...params}
          InputProps={{
            ...params.InputProps,
            onClick: () => {
              onClick();
              if (!reqChars) resetScrollPosition();
            },
            endAdornment: (
              <React.Fragment>
                {isOptionsLoading ? <Loading size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      popupIcon={<ExpandMoreIcon />}
      renderOption={(props, option) => (
        <li {...props}>
          <div className="autocomplete-list">
            <span className="option-label">
              {(option as WellCounts).attributeValue}
            </span>
            <span className="option-count">{`(${
              (option as WellCounts).count
            })`}</span>
          </div>
        </li>
      )}
      {...(attribute?.dataType === TEXT && options.length > 0
        ? { PaperComponent: DropdownContainer }
        : {})}
      {...additionalProps}
    />
  );
};

export default QBTypeAheadComboBox;
