import { Map as OLMap } from "ol";

import { StateCreator } from "zustand";

import { Deck, Layer, Map, Zoom } from "../../types/map/layers/store";
import { MapScaleBarUnit } from "../../types/map/scaleBar";

import appSettings from "../../configs/appSettings";

import { defaultLayers } from "../../constants/defaultMapSettingsStoreData";
import { baseMapLayer } from "../../constants/map/layers";
import { MAP_SCALE_BAR_UNITS } from "../../constants/map/mapSettings";

import { clone } from "../../utils";

const mapSlice: StateCreator<Map> = (set, get) => ({
  map: undefined,
  setMap: (map: OLMap) => set((state) => ({ map })),
});

const deckSlice: StateCreator<Deck> = (set, get) => ({
  deckGl: undefined,
  setDeckGl: (deckGl) => set((state) => ({ deckGl })),
});

const zoomSlice: StateCreator<Zoom> = (set, get) => ({
  verticalPageSize: ["100%", "0", "auto"],
  updateVerticalPageSize: (verticalPageSize) =>
    set((state) => ({ verticalPageSize })),
});

const layerSlice: StateCreator<Layer> = (set, get) => ({
  layers: clone(defaultLayers),
  drawLayerSource: null,
  setDrawLayerSource: (drawLayerSource) =>
    set((state) => ({ drawLayerSource })),
  toggleLayer: (layerName, boolOverride = undefined) =>
    set({
      layers: get().layers.map((layer) => {
        if (layer.name === layerName) {
          layer.isSelected = boolOverride ?? !layer.isSelected;
        }
        return layer;
      }),
    }),
  setLayers: (layers) => set(() => ({ layers })),

  baseMapSelected: baseMapLayer[6].id,
  setBaseMapSelected: (baseMapSelected) => set(() => ({ baseMapSelected })),

  mapScaleBarUnit: MAP_SCALE_BAR_UNITS[0].key as MapScaleBarUnit,
  updateMapScaleBarUnit: (mapScaleBarUnit) => set(() => ({ mapScaleBarUnit })),

  currentZoom: 4,
  setCurrentZoom: (currentZoom: number) => set(() => ({ currentZoom })),

  currentMapCenter: undefined,
  setCurrentMapCenter: (currentMapCenter) => set(() => ({ currentMapCenter })),

  DVTQueryLoading: {},
  updateDVTQueryLoading: (DVTQueryLoading) =>
    set(() =>
      DVTQueryLoading
        ? {
            DVTQueryLoading: { ...get().DVTQueryLoading, ...DVTQueryLoading },
          }
        : {
            DVTQueryLoading: {},
          }
    ),

  DVTQuery: {},
  updateDVTQuery: (DVTQuery) =>
    set(() =>
      DVTQuery
        ? {
            DVTQuery: { ...get().DVTQuery, ...DVTQuery },
          }
        : {
            DVTQuery: {},
          }
    ),

  DVTQueryError: null,
  updateDVTQueryError: (DVTQueryError) => set(() => ({ DVTQueryError })),

  DVTUpdated: false,
  setDVTUpdated: (DVTUpdated) =>
    set(() => ({
      DVTUpdated,
    })),

  currentlySwitchedToDVT: false,
  setCurrentlySwitchedToDVT: (currentlySwitchedToDVT) =>
    set(() => ({
      currentlySwitchedToDVT,
    })),

  resetMapStates: () =>
    set(() => ({
      layers: clone(defaultLayers),
      baseMapSelected: baseMapLayer[6].id,
      currentZoom: appSettings.defaultViewSettings.zoom,
      currentMapCenter: appSettings.defaultViewSettings.center,
      DVTUpdated: false,
      // DVTQuery and DVTQueryLoading is not here
      // since they are automatically reset when search criteria changes
    })),
});

export { deckSlice, layerSlice, mapSlice, zoomSlice };
