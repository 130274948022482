import { ExportMapLayerNames } from "../../types/exports/exportMap/exportMap";

import {
  BOTTOM_WELL_SPOTS,
  SURFACE_WELL_SPOTS,
  WELL_PATHS,
} from "../constants";

export const EXPORT_MAP_LAYERS = {
  [SURFACE_WELL_SPOTS]: {
    apiKey: "WellSurfaceLocation",
    label: SURFACE_WELL_SPOTS,
  },
  [BOTTOM_WELL_SPOTS]: {
    apiKey: "WellBottomLocation",
    label: BOTTOM_WELL_SPOTS,
  },
  [WELL_PATHS]: {
    apiKey: "wellpath",
    label: WELL_PATHS,
  },
} as const;

export const EXPORT_MAP_LAYER_NAMES: ExportMapLayerNames[] = [
  EXPORT_MAP_LAYERS[BOTTOM_WELL_SPOTS].label,
  EXPORT_MAP_LAYERS[SURFACE_WELL_SPOTS].label,
  EXPORT_MAP_LAYERS[WELL_PATHS].label,
];

export const EXPORT_DATE_FORMAT = "MM/DD/YYYY hh:mm:ss A";

export const ADDITIONAL_EXPORT_SHAPEFILE_ALIAS: { [key: string]: string } = {
  FractureFluidType: "FrFluidTp",
  ProppantType: "PropType",
  FirstProductionDate: "FirstProdDT",
  OriginalOperator: "OrigOper",
  HasCompletion: "HasComp",
  HasDisposition: "HasDisp",
  HasDrillString: "HasCsng",
  HasReportedTops: "HasTops",
  ActiveInjection: "ActiveInj",
  ActiveVentFlare: "ActiveVent",
  BottomLatitude_EPSG3857: "BHLat3857",
  BottomLatitude_NAD27: "BHLatNAD27",
  BottomLatitude_NAD83: "BHLatNAD83",
  BottomLatitude_WGS84: "BHLatWGS84",
  BottomLongitude_EPSG3857: "BHLon3857",
  BottomLongitude_NAD27: "BHLonNAD27",
  BottomLongitude_NAD83: "BHLonNAD83",
  BottomLongitude_WGS84: "BHLonWGS84",
  SurfaceLatitude_EPSG3857: "SHLat3857",
  SurfaceLatitude_NAD27: "SHLatNAD27",
  SurfaceLatitude_NAD83: "SHLatNAD83",
  SurfaceLatitude_WGS84: "SHLatWGS84",
  SurfaceLongitude_EPSG3857: "SHLon3857",
  SurfaceLongitude_NAD27: "SHLonNAD27",
  SurfaceLongitude_NAD83: "SHLonNAD83",
  SurfaceLongitude_WGS84: "SHLonWGS84",
  Abstract: "Abstract",
  Block: "Block",
  DisplayLocation: "Location",
  DistanceEW: "DistanceEW",
  DistanceEWD: "EWDir",
  DistanceNS: "DistanceNS",
  DistanceNSD: "NSDir",
  QuarterQuarter: "QuartQuart",
  Survey: "Survey",
};
