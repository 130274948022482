import { FC, SVGProps } from "react";

interface TrashCanOutlineIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const TrashCanOutlineIcon: FC<TrashCanOutlineIconProps> = ({
  width = "20",
  height = "20",
  fill = "none",
  pathFill = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 17C6.0875 17 5.73437 16.8531 5.44062 16.5594C5.14687 16.2656 5 15.9125 5 15.5V5.5H4V4H8V3H12V4H16V5.5H15V15.491C15 15.9137 14.8531 16.2708 14.5594 16.5625C14.2656 16.8542 13.9125 17 13.5 17H6.5ZM13.5 5.5H6.5V15.5H13.5V5.5ZM8 14H9.5V7H8V14ZM10.5 14H12V7H10.5V14Z"
        fill={pathFill}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default TrashCanOutlineIcon;
