import { useState } from "react";

import {
  CheckBoxOutlined as CheckboxOutlinedIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Typography,
} from "@mui/material";

import classNames from "classnames";

interface AccordionProps {
  title: string;
  hasCheckbox?: boolean;
  sectionType?: string;
  isDefaultExpanded?: boolean;
  children?: React.ReactNode;
  checkedAccordion: boolean;
  onChecked: (key: boolean) => void;
}

const WellItemsAccordion: React.FC<AccordionProps> = ({
  title,
  hasCheckbox = false,
  sectionType,
  isDefaultExpanded = false,
  children,
  checkedAccordion,
  onChecked,
}) => {
  const [expandAccordion, setExpandAccordion] = useState(true);

  const selectExpandIcon = () => {
    setExpandAccordion((prev) => !prev);
  };

  return (
    <MuiAccordion
      className="well-panel-modal-accordion"
      defaultExpanded={isDefaultExpanded}
      disableGutters
      expanded={expandAccordion}
    >
      <MuiAccordionSummary
        className={classNames("well-panel-modal-accordion-summary", {
          expanded: expandAccordion,
        })}
        expandIcon={<ExpandMoreIcon onClick={selectExpandIcon} />}
      >
        {hasCheckbox ? (
          <div className="well-info-checkbox">
            <FormControlLabel
              aria-label="accordion-checkbox"
              onClick={(event) => event.stopPropagation()}
              onFocus={(event) => event.stopPropagation()}
              control={
                <Checkbox
                  disableRipple
                  checked={checkedAccordion}
                  checkedIcon={<CheckboxOutlinedIcon />}
                  onChange={(e, checked) => onChecked(checked)}
                />
              }
              label={<Typography>{title}</Typography>}
            />
          </div>
        ) : (
          <Typography>{title}</Typography>
        )}
      </MuiAccordionSummary>
      <MuiAccordionDetails
        className={classNames("well-panel-modal-accordion-details", {
          "with-section": sectionType,
        })}
      >
        {children}
      </MuiAccordionDetails>
    </MuiAccordion>
  );
};

export default WellItemsAccordion;
