import { FC, useEffect } from "react";

import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";

import { formationColumns } from "../../../../constants/panels/wellPanel/formation";
import { WELL_PANEL_SECTION } from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useWellPanelFormations from "../../../../customHooks/panels/wellPanel/useWellPanelFormations";

import PanelTable from "../common/PanelTable";
import WellPanelAccordion from "../common/WellPanelAccordion";

const Formation: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  groupedWellID,
  layer,
  isExpanded,
  onChange,
}) => {
  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const updateIsFormationsLoading = usePanelsStore(
    (state) => state.updateIsFormationsLoading
  );

  const { data, getWellFormationTops, isDataLoading } =
    useWellPanelFormations();

  useEffect(() => {
    if (data?.isDataLoaded) {
      updateWellCardDataByTypeAndKey(
        data,
        wellData.wellId,
        layer,
        groupedWellID
      );
      updateIsFormationsLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (
      wellData.pageNumber === activePage &&
      !wellData.formationData?.isDataLoaded
    ) {
      getWellFormationTops(
        wellData.wellCardData.wellId as number,
        wellData.wellCardData.uwi as string
      );
      updateIsFormationsLoading(true);
    }
  }, [wellData.pageNumber, wellData.formationData, activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.FORMATION_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.FORMATION_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isDataLoading}
    >
      {wellData.formationData?.formation.length && (
        <PanelTable
          TableData={wellData.formationData?.formation}
          TableHeader={formationColumns}
          tabContentTableSize={285}
          isLoading={isDataLoading}
        />
      )}
    </WellPanelAccordion>
  );
};

export default Formation;
