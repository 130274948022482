import { StateCreator } from "zustand";

import {
  MapSettingsStates,
  PermitColorSlice,
} from "../../../types/map/mapSettings/store";

import { PERMIT_SPOTS } from "../../../constants/constants";
import {
  COLOR_BY_ATTRIBUTE_INFO,
  STYLING_API_KEY_MAPPER,
} from "../../../constants/map/mapSettings";

import { getDefaultColorList } from "../../../components/charts/utils/mapSettingsUtils";

import {
  getCompressedDataInfo,
  transformWellStylingDataToObject,
} from "../../../utils/map/wellStyling";

import useDataGridStore from "../../grid/dataGridStore";

const sliceResetFns = new Set<() => void>();

const resetPermitColorSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialPermitColorStates = {
  compressedPermitData: [],
  permitValues: { data: {}, min: null, max: null, hasNull: false },
  permitMinMax: { min: null, max: null, hasNull: false },

  applyWellColorToPermits: false,
};

const permitColorSlice: StateCreator<
  MapSettingsStates,
  [],
  [],
  PermitColorSlice
> = (set) => {
  sliceResetFns.add(() => set(initialPermitColorStates));
  return {
    ...initialPermitColorStates,

    updatePermitLegendColors: () =>
      set((state) => {
        const { min, max, hasNull } = state.permitMinMax;
        const attributeKey = state.layerStyles[PERMIT_SPOTS].color;
        const topAttribValues = state.topAttribValues;
        const initialGridSearchMade =
          useDataGridStore.getState().initialGridSearchMade;
        const colorList = getDefaultColorList(
          topAttribValues,
          attributeKey,
          min,
          max,
          hasNull,
          initialGridSearchMade
        );
        return {
          layerLegendColors: {
            ...state.layerLegendColors,
            [PERMIT_SPOTS]: colorList,
          },
        };
      }),

    updatePermitDataInfo: (permit) =>
      set((state) => {
        const attributeKey = state.layerStyles[PERMIT_SPOTS].color;
        const permitDataInfo = getCompressedDataInfo(
          permit,
          attributeKey,
          COLOR_BY_ATTRIBUTE_INFO
        );
        const permitById = transformWellStylingDataToObject(
          permitDataInfo.data,
          [STYLING_API_KEY_MAPPER[PERMIT_SPOTS].dataKey],
          attributeKey
        );

        return {
          compressedPermitData: permit,
          permitValues: {
            ...permitDataInfo,
            data: permitById,
          },
        };
      }),

    updatePermitMinMax: () =>
      set((state) => {
        const { min, max, hasNull } = state.permitValues;
        return { permitMinMax: { min, max, hasNull } };
      }),

    toggleApplyWellColorToPermits: (status) =>
      set((state) => ({
        applyWellColorToPermits: status ?? !state.applyWellColorToPermits,
      })),
  };
};

export { permitColorSlice, resetPermitColorSlice };
