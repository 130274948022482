import { FC, useState } from "react";

import { InputAdornment, TextField, TextFieldProps } from "@mui/material";

type OutlinedInputFieldProps = TextFieldProps & {
  suffix?: string;
  prefix?: string;
  highlight?: boolean;
  handleError?: (key: boolean) => string;
  greyoutfilled?: boolean;
};

const OutlinedInputField: FC<OutlinedInputFieldProps> = (props) => {
  const [error, setError] = useState<boolean>(false);

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    props.onChange?.(event);
  };

  return (
    <TextField
      className={`outlined-textfield ${
        props.highlight ? "highlight-changed-input" : ""
      } ${props.greyoutfilled ? "greyed-out-input" : ""}`}
      variant="outlined"
      autoComplete="off"
      {...((props.suffix || props.prefix) && {
        InputProps: props.suffix
          ? {
              endAdornment: (
                <InputAdornment position="start">{props.suffix}</InputAdornment>
              ),
            }
          : {
              startAdornment: (
                <InputAdornment position="start">{props.prefix}</InputAdornment>
              ),
            },
      })}
      onChange={handleOnChange}
      InputLabelProps={{ shrink: true }}
      error={error}
      {...props}
    />
  );
};

export default OutlinedInputField;
