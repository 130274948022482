import { useEffect } from "react";

import * as mapSettings from "../../../constants/map/mapSettings";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import { shouldGetTopAttributes } from "../../../utils/map/wellStyling";

import useSearchCriteria from "../../search/useSearchCriteria";
import useProcessBottomColors from "./processColor/useProcessBottomColors";
import useProcessPermitColors from "./processColor/useProcessPermitColors";
import useProcessSurfaceColors from "./processColor/useProcessSurfaceColors";
import useLayerStylingByRecordType from "./useLayerStylingByRecordType";
import useTopAttributeValues from "./useTopAttributeValues";

const useProcessLayerColors = () => {
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const initialGridSearchMade = useDataGridStore(
    (state) => state.initialGridSearchMade
  );

  const updateIsProcessingStyles = useMapSettingsStore(
    (state) => state.updateIsProcessingStyles
  );
  const updateWellLegendColors = useMapSettingsStore(
    (state) => state.updateWellLegendColors
  );
  const updatePermitLegendColors = useMapSettingsStore(
    (state) => state.updatePermitLegendColors
  );
  const updateTopAttribValues = useMapSettingsStore(
    (state) => state.updateTopAttribValues
  );
  const updateSurfaceDataInfo = useMapSettingsStore(
    (state) => state.updateSurfaceDataInfo
  );
  const updateBottomDataInfo = useMapSettingsStore(
    (state) => state.updateBottomDataInfo
  );
  const updatePermitDataInfo = useMapSettingsStore(
    (state) => state.updatePermitDataInfo
  );
  const updatePartitionMinMax = useMapSettingsStore(
    (state) => state.updatePartitionMinMax
  );
  const updatePermitMinMax = useMapSettingsStore(
    (state) => state.updatePermitMinMax
  );

  const { hasSearchCriteria } = useSearchCriteria();
  const {
    layerStylesColorByRecordType: colorByKey,
    shouldApplyColorToPermits,
    shouldApplyColorToLayer,
    getTopAttributeSortBy,
  } = useLayerStylingByRecordType();

  const { isSurfaceSelected, isSurfaceColorsLoading, callSurfaceStylingApi } =
    useProcessSurfaceColors({
      searchCriteria,
      initialGridSearchMade,
      shouldApplyColorToLayer,
    });

  const {
    isBottomOrWellPathSelected,
    isBottomColorsLoading,
    callBottomStylingApi,
  } = useProcessBottomColors({
    searchCriteria,
    initialGridSearchMade,
    shouldApplyColorToLayer,
  });

  const { isPermitSelected, isPermitColorsLoading, callPermitStylingApi } =
    useProcessPermitColors({
      searchCriteria,
      initialGridSearchMade,
      shouldApplyColorToPermits,
      shouldApplyColorToLayer,
    });

  const {
    isLoading: isTopValuesLoading,
    data: topValues,
    getTopAttributeValues,
  } = useTopAttributeValues();

  // API Calls
  const callLayerStylingApi = (attribute: string) => {
    updateIsProcessingStyles(true);

    if (shouldGetTopAttributes(attribute)) {
      getTopAttributeValues({
        attribute,
        sortBy: getTopAttributeSortBy(attribute),
        searchCriteria,
      });
    }

    callSurfaceStylingApi(attribute);
    callBottomStylingApi(attribute);
    callPermitStylingApi(attribute);
  };

  useEffect(() => {
    if (!hasSearchCriteria) return;

    if (colorByKey === mapSettings.UNIFORM_COLOR.key) {
      updateSurfaceDataInfo([]);
      updateBottomDataInfo([]);
      updatePermitDataInfo([]);
      updateLegendInfo();
      updatePermitLegendInfo();
      return;
    }

    callLayerStylingApi(colorByKey);
  }, [searchCriteria, colorByKey]);

  useEffect(() => {
    if (isTopValuesLoading || !topValues.length || !initialGridSearchMade) {
      return;
    }

    updateTopAttribValues(topValues);
  }, [isTopValuesLoading, topValues]);

  useEffect(() => {
    if (
      !isSurfaceColorsLoading &&
      !isBottomColorsLoading &&
      !isPermitColorsLoading &&
      !isTopValuesLoading
    ) {
      updateLegendInfo();
      updatePermitLegendInfo();
      updateIsProcessingStyles(false);
    }
  }, [
    isSurfaceColorsLoading,
    isBottomColorsLoading,
    isPermitColorsLoading,
    isTopValuesLoading,
  ]);

  const updateLegendInfo = () => {
    updatePartitionMinMax();
    updateWellLegendColors();
  };

  const updatePermitLegendInfo = () => {
    updatePermitMinMax();
    updatePermitLegendColors();
  };

  useEffect(() => {
    if (!hasSearchCriteria || colorByKey === mapSettings.UNIFORM_COLOR.key)
      return;

    if (isSurfaceSelected) updateIsProcessingStyles(true);
    callSurfaceStylingApi(colorByKey);
  }, [isSurfaceSelected]);

  useEffect(() => {
    if (!hasSearchCriteria || colorByKey === mapSettings.UNIFORM_COLOR.key)
      return;

    if (isBottomOrWellPathSelected) updateIsProcessingStyles(true);
    callBottomStylingApi(colorByKey);
  }, [isBottomOrWellPathSelected]);

  useEffect(() => {
    if (!hasSearchCriteria || colorByKey === mapSettings.UNIFORM_COLOR.key)
      return;

    if (isPermitSelected) updateIsProcessingStyles(true);
    callPermitStylingApi(colorByKey);
  }, [isPermitSelected]);
};

export default useProcessLayerColors;
