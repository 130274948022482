import { Map } from "ol";

import {
  BOTTOM_WELL_SPOTS,
  BUBBLE_MAP,
  DYNAMIC_VECTOR_TILES,
  WELL_SPOTS,
} from "../../../constants/constants";
import { PERMANENT_LAYERS } from "../../../constants/map/layers";

import {
  getLayerDefinitionsKeys,
  getLayerGroupNamesByFetchType,
} from "./layerService";

export const getCurrentMapDVTLayers = (map: Map) => {
  const layerGroupNamesWithDVT = getLayerGroupNamesByFetchType(
    getLayerDefinitionsKeys(),
    DYNAMIC_VECTOR_TILES
  );

  return map
    .getLayers()
    .getArray()
    .filter((layer) => {
      const { isBaseMap, title } = layer.getProperties();
      return (
        !isBaseMap &&
        !PERMANENT_LAYERS.includes(title) &&
        layerGroupNamesWithDVT.includes(title)
      );
    });
};

export const getDVTLayerTitlesToRemove = (
  map: Map,
  layerTitlesToUpdate: string[]
) => {
  const layersToRemove: string[] = [];
  const displayedLayers = getCurrentMapDVTLayers(map);
  displayedLayers.forEach((displayedLayer) => {
    const layerTitle = displayedLayer.getProperties().title;
    if (
      !layerTitlesToUpdate.includes(layerTitle) ||
      (layerTitle === BUBBLE_MAP &&
        !layerTitlesToUpdate.includes(WELL_SPOTS) &&
        !layerTitlesToUpdate.includes(BOTTOM_WELL_SPOTS))
    ) {
      layersToRemove.push(layerTitle);
    }
  });
  return layersToRemove;
};

// SAVING FOR REFERENCE
// export const getDVTLayerTitlesToCreate = (
//   map: Map,
//   layerTitlesToUpdate: string[]
// ) => {
//   const displayedLayerTitlesAfterTheCleanUp = getCurrentMapDVTLayers(map).map(
//     (layer) => layer.getProperties().title
//   );
//   const layersToDisplay = layerTitlesToUpdate.filter(
//     (layerTitleToDisplay) =>
//       !displayedLayerTitlesAfterTheCleanUp.includes(layerTitleToDisplay)
//   );
//   return layersToDisplay;
// };

export const getCurrentMapNonDVTLayers = (map: Map) => {
  const layerGroupNamesWithDVT = getLayerGroupNamesByFetchType(
    getLayerDefinitionsKeys(),
    DYNAMIC_VECTOR_TILES
  );

  return map
    .getLayers()
    .getArray()
    .filter((layer) => {
      const { isBaseMap, title } = layer.getProperties();
      return (
        !isBaseMap &&
        !PERMANENT_LAYERS.includes(title) &&
        !layerGroupNamesWithDVT.includes(title)
      );
    });
};

export const removeUnselectedNonDVTLayersFromMap = (
  map: Map,
  layerTitlesToUpdate: string[]
) => {
  const layersToRemove: string[] = [];

  if (map) {
    const displayedLayers = getCurrentMapNonDVTLayers(map);
    displayedLayers.forEach((displayedLayer) => {
      const layerTitle = displayedLayer.getProperties().title;
      if (!layerTitlesToUpdate.includes(layerTitle)) {
        layersToRemove.push(layerTitle);
        map.removeLayer(displayedLayer);
      }
    });
  }

  return layersToRemove;
};

export const getNonDVTLayerTitlesToCreate = (
  map: Map,
  layerTitlesToUpdate: string[]
) => {
  const displayedLayerTitlesAfterTheCleanUp = getCurrentMapNonDVTLayers(
    map
  ).map((layer) => layer.getProperties().title);

  const layersToDisplay = layerTitlesToUpdate.filter(
    (layerTitleToDisplay) =>
      !displayedLayerTitlesAfterTheCleanUp.includes(layerTitleToDisplay)
  );

  return layersToDisplay;
};
