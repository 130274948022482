import { useEffect, useMemo, useState } from "react";

import { CircularProgress, Typography, styled } from "@mui/material";

import { Accordion } from "component-library";

import { LayerLegendItem } from "../../../types/map/layers/useGetTgsLayerLegendApi";

import {
  CARBON_LAYER_BASINS,
  CARBON_STORAGE_LABEL,
} from "../../../constants/map/carbonStorage";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import useGetTgsLayerLegend from "../../../customHooks/map/layers/useGetTgsLayerLegend";

const SquareIcon = styled("span")(({ color }: { color: string }) => ({
  backgroundColor: color,
  width: 12.95,
  height: 12,
  border: "1px solid #FFFFFF26",
  borderRadius: 2,
}));

const CarbonStorageLegend = () => {
  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );

  const [legendItems, setLegendItems] = useState<LayerLegendItem[]>([]);

  const { isLoading, data, error, getTGSLegend } = useGetTgsLayerLegend();

  const layerName = useMemo(
    () =>
      carbonStorageInfo.basins[CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY]
        .formationName,
    [carbonStorageInfo]
  );

  const isBasinSelected = useMemo(
    () =>
      carbonStorageInfo.basins[CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY]
        .isSelected,
    [carbonStorageInfo]
  );

  const isCarbonStorageSelected = useMemo(
    () => carbonStorageInfo.isSelected,
    [carbonStorageInfo]
  );

  useEffect(() => {
    if (!isCarbonStorageSelected || !isBasinSelected) return;
    getTGSLegend({ layerName });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCarbonStorageSelected, isBasinSelected, layerName]);

  useEffect(() => {
    if (isLoading || !data || error) return;
    setLegendItems(data);
  }, [isLoading, data, error]);

  return (
    <Accordion
      title={CARBON_STORAGE_LABEL}
      className="layer-info-accordion"
      isDefaultOpen
    >
      <Typography className="layer-info-sub-header">
        Metric Tons (Mt)
      </Typography>
      {isLoading ? (
        <div className="layer-info-loader">
          <CircularProgress />
        </div>
      ) : (
        <div className="layer-info-list multi-cols">
          {legendItems.map((item) => (
            <div key={item.label} className="layer-info-item carbon-storage">
              <div className="layer-info-symbol">
                <SquareIcon color={item.color} />
              </div>
              <div className="layer-info-label">{item.label}</div>
            </div>
          ))}
        </div>
      )}
    </Accordion>
  );
};

export default CarbonStorageLegend;
