import { SearchTypesMapper } from "../../../types/panels/searchPanel/search";

export const SEARCH_TYPES: SearchTypesMapper = {
  ATTRIBUTE_SEARCH: "Attribute Search",
  UWI_SEARCH: "UWI Search",
  UWI_FILE_SEARCH: "File Upload Search",
  UWI_CONVERTED_FILE_SEARCH: "UWI Converted File Search",
};

export const UWI_SEARCH_TYPES = [
  SEARCH_TYPES.UWI_SEARCH,
  SEARCH_TYPES.UWI_FILE_SEARCH,
  SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH,
];
