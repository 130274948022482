export const STATE = "State";
export const LAST_UPDATED_DATE = "LastUpdatedDate";
export const MOST_RECENT_PRODUCTION = "MostRecentProduction";
export const DESC = "DESC";
export const ASC = "ASC";
export const DATA_CURRENCY_TITLE = "Data Currency Log Sheet";
export const DATA_CURRENCY_MENU = "Data Currency";
export const RELEASE_NOTES_TITLE = "Release Notes";
export const STATE_LABEL = "State";
export const UPDATE_DATE_LABEL = "Update Date";
export const MOST_RECENT_PRODUCTION_LABEL = "Most Recent Production";
