import { FC, useCallback } from "react";

import { AddCircle as AddCircleIcon } from "@mui/icons-material";
import { Chip } from "@mui/material";

import classNames from "classnames";

import { Attribute } from "../../../../../../types/common/attributes";
import { AttributeWithState } from "../../../../../../types/panels/searchPanel/queryBuilder";

interface Props {
  attributes: AttributeWithState[];
  handleAddAttribute: (attribute: Attribute) => void;
  handleRemoveAttribute: (attribute: Attribute) => void;
}

const QBEditorAttributes: FC<Props> = ({
  attributes,
  handleAddAttribute,
  handleRemoveAttribute,
}) => {
  const handleAction = (attribute: AttributeWithState) => {
    if (attribute.isSelected) handleRemoveAttribute(attribute);
    else handleAddAttribute(attribute);
  };

  const getIcon = useCallback(
    (isSelected: boolean) =>
      !isSelected ? { deleteIcon: <AddCircleIcon /> } : {},
    []
  );

  return (
    <div className="qb-editor-attrib-list">
      {attributes.map((attribute) => {
        return (
          <Chip
            size="small"
            key={attribute.key}
            variant="filled"
            className={classNames("qb-chip", {
              selected: attribute.isSelected,
            })}
            label={attribute.label}
            onClick={() => handleAction(attribute)}
            onDelete={() => handleAction(attribute)}
            {...getIcon(attribute.isSelected)}
          />
        );
      })}
    </div>
  );
};

export default QBEditorAttributes;
