import { FC, useMemo } from "react";

import { Chip } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid-premium";

import classNames from "classnames";

import { COMBO_FLAG_LABELS } from "../../../../constants/panels/wellPanel/logs";

const LogPanelCell: FC<GridRenderCellParams> = ({ row }) => {
  const { type, lasTriple, lasQuad, logType } = row;
  const chipLabel = useMemo(() => {
    if (type !== "las") return "";
    if (lasQuad) return COMBO_FLAG_LABELS.QUAD;
    if (lasTriple) return COMBO_FLAG_LABELS.TRIP;
    return "";
  }, [type, lasTriple, lasQuad]);

  return (
    <div className="log-panel-cell">
      <p className="well-panel-log-type">{logType}</p>
      {chipLabel && (
        <Chip
          className={classNames("chip-container", {
            quad: chipLabel === COMBO_FLAG_LABELS.QUAD,
          })}
          label={<p className="chip-label">{chipLabel}</p>}
          size="small"
        />
      )}
    </div>
  );
};

export default LogPanelCell;
