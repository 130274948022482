import { OperationType } from "../../../../types/panels/searchPanel/queryBuilder";

export const IN: OperationType = { key: "in", label: "In" };
export const EQ: OperationType = { key: "eq", label: "Equals" };
export const GT: OperationType = { key: "gt", label: "Is greater than" };
export const GTE: OperationType = {
  key: "gte",
  label: "Is greater than or equal",
};
export const LT: OperationType = { key: "lt", label: "Is less than" };
export const LTE: OperationType = {
  key: "lte",
  label: "Is less than or equal",
};
export const BTW: OperationType = { key: "btw", label: "Is between" };

export const BEFORE: OperationType = { key: LT.key, label: "Before" };
export const AFTER: OperationType = { key: GT.key, label: "After" };
export const BETWEEN: OperationType = { key: BTW.key, label: "Between" };
export const YR: OperationType = { key: "yr", label: "Year" };

export const LK: OperationType = { key: "lk", label: "Like" };

export const NUMBER_OPTIONS = [EQ, GT, GTE, LT, LTE, BTW];
export const DATE_OPTIONS = [EQ, BEFORE, AFTER, BETWEEN, YR];
