/* eslint-disable jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */
import { useEffect, useState } from "react";

import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import {
  Collapse,
  FormControlLabel,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";

import classNames from "classnames";

import { handleKeyDown } from "../../utils";
import { CustomTooltip } from "../mapSettingsAccordions/CustomTooltip";
import CustomCheckbox from "./CustomCheckbox";
import TooltipInfoIcon from "./icons/TooltipInfoIcon";

interface AccordionSectionProps {
  title: React.ReactNode;
  isDefaultOpen?: boolean;
  isSelected?: boolean;
  isAutoOpenOnSelect?: boolean;
  hasCheckbox?: boolean;
  hasTooltip?: boolean;
  onSelect?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  expandIconStart?: boolean;
  expandIconEnd?: boolean;
  infoMessage?: string;
}

const AccordionSection: React.FC<AccordionSectionProps> = ({
  title,
  isDefaultOpen = false,
  isSelected = false,
  isAutoOpenOnSelect = true,
  hasCheckbox = false,
  hasTooltip = false,
  infoMessage,
  onSelect,
  children,
  disabled = false,
  className = "",
  expandIconStart = false,
  expandIconEnd = false,
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen);

  const toggleContent = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (!isAutoOpenOnSelect) return;
    setIsOpen(isSelected);
  }, [isSelected]);

  return (
    <div className={`custom-accordion-section ${className}`}>
      <div className="section-header">
        {expandIconStart && (
          <IconButton
            size="small"
            className={classNames("expand-icon start", { expanded: isOpen })}
            onClick={toggleContent}
          >
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
        <Tooltip title={hasTooltip ? title : ""}>
          {hasCheckbox ? (
            <FormControlLabel
              label={title}
              control={
                <CustomCheckbox onChange={onSelect} checked={isSelected} />
              }
              disabled={disabled}
            />
          ) : (
            <div
              className={`section-title ${
                !hasCheckbox ? "no-checkbox-title" : ""
              }`}
              onClick={toggleContent}
              onKeyDown={handleKeyDown}
            >
              <Typography>{title}</Typography>
            </div>
          )}
        </Tooltip>
        {infoMessage && (
          <CustomTooltip title={infoMessage} placement="left" arrow>
            <span className="tooltip-info">
              <TooltipInfoIcon />
            </span>
          </CustomTooltip>
        )}

        {expandIconEnd && (
          <IconButton
            size="small"
            className={classNames("expand-icon", { expanded: isOpen })}
            onClick={toggleContent}
          >
            {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        )}
      </div>
      <Collapse in={isOpen}>
        <div className="section-content">{children}</div>
      </Collapse>
    </div>
  );
};

export default AccordionSection;
