import { StateCreator } from "zustand";

import {
  StratModelBasinMapTypes,
  StratigraphicModelsBasins,
  StratigraphicModelsSlice,
} from "../../../types/map/mapSettings/store";

import {
  DEFAULT_FORMATION_BY_BASIN_MAP_TYPE,
  DEFAULT_STRAT_MODELS_BASINS_INFO,
} from "../../../constants/map/stratigraphicModels";

import { clone } from "../../../utils";

const sliceResetFns = new Set<() => void>();

const resetStratModelsSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialStratModelsStates = {
  stratModelsInfo: {
    isSelected: false,
    basins: clone(DEFAULT_STRAT_MODELS_BASINS_INFO),
  },
};

const stratModelsSlice: StateCreator<StratigraphicModelsSlice> = (set, get) => {
  sliceResetFns.add(() => set(initialStratModelsStates));

  return {
    ...initialStratModelsStates,
    toggleStratModelsSelected: (status) =>
      set((state) => ({
        stratModelsInfo: {
          ...state.stratModelsInfo,
          isSelected: status ?? !state.stratModelsInfo.isSelected,
        },
      })),
    updateStratModelsBasinStatus: (basinName, status) =>
      set((state) => {
        const clonedBasins = clone(
          state.stratModelsInfo.basins
        ) as StratigraphicModelsBasins;

        clonedBasins[basinName].isSelected =
          status ?? !clonedBasins[basinName].isSelected;

        return {
          stratModelsInfo: {
            ...state.stratModelsInfo,
            basins: clonedBasins,
          },
        };
      }),
    updateStratModelsBasinMapType: (basinName, mapType) =>
      set((state) => {
        const clonedBasins = clone(
          state.stratModelsInfo.basins
        ) as StratigraphicModelsBasins;
        clonedBasins[basinName].mapType = mapType;
        clonedBasins[basinName].formationName =
          DEFAULT_FORMATION_BY_BASIN_MAP_TYPE[basinName][
            mapType
          ] as StratModelBasinMapTypes;

        return {
          stratModelsInfo: {
            ...state.stratModelsInfo,
            basins: clonedBasins,
          },
        };
      }),
    updateStratModelsBasinFormation: (basinName, formation) =>
      set((state) => {
        const clonedBasins = clone(
          state.stratModelsInfo.basins
        ) as StratigraphicModelsBasins;
        clonedBasins[basinName].formationName = formation;

        return {
          stratModelsInfo: {
            ...state.stratModelsInfo,
            basins: clonedBasins,
          },
        };
      }),
    updateStratModelsBasins: (basins) =>
      set((state) => ({
        stratModelsInfo: { ...state.stratModelsInfo, basins },
      })),
    updateStratModelsInfo: (stratModelsInfo) =>
      set(() => ({ stratModelsInfo })),
  };
};

export { resetStratModelsSlice, stratModelsSlice };
