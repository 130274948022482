import {
  PERMIT_OPERATOR_CONTACT_INFO,
  PERMIT_RELATED_WELLS_INFO,
} from "../panels/permitPanel/permit";

const permitPanelNumberAttributes = [PERMIT_RELATED_WELLS_INFO.NO_WELLS.key];
const permitPanelStringAttributes = [
  PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_CONTACT.key,
  PERMIT_OPERATOR_CONTACT_INFO.PHONE_NUMBER.key,
  PERMIT_OPERATOR_CONTACT_INFO.ADDRESS.key,
  PERMIT_OPERATOR_CONTACT_INFO.CITY.key,
  PERMIT_OPERATOR_CONTACT_INFO.STATE.key,
  PERMIT_OPERATOR_CONTACT_INFO.ZIP_CODE.key,
];

export { permitPanelStringAttributes, permitPanelNumberAttributes };
