import { useCallback } from "react";

import {
  WellEconomicsInputFields,
  WellEconomicsInputsError,
} from "../../../types/panels/wellPanel/wellEconomicsType";
import { WellSystemData } from "../../../types/panels/wellPanel/wellPanel";

import * as constant from "../../../constants/panels/wellPanel/wellEconomics";
import { regexNumberAndSpecialChar } from "../../../constants/regexp";

import useAlertPopupStore from "../../../store/map/alert/alertStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import { clone } from "../../../utils";
import useWellEconomicsComputations from "./useWellEconomicsComputations";

const useWellEconomicsEvents = (wellData: WellSystemData) => {
  const wellEconomicsInputInfo = usePanelsStore(
    (state) =>
      state.wellEconomicsInputs?.[wellData.wellCardData.wellId as number]
  );
  const updateWellEconomicsInputByField = usePanelsStore(
    (state) => state.updateWellEconomicsInputByField
  );
  const updateWellEconomicsError = usePanelsStore(
    (state) => state.updateWellEconomicsError
  );

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const updateWellEconomicsGreyedState = usePanelsStore(
    (state) => state.updateWellEconomicsGreyedState
  );

  const { computeNGLFormula, getFormattedValue } =
    useWellEconomicsComputations();

  const handleInputChange = useCallback(
    (
      e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
      field: WellEconomicsInputFields
    ) => {
      if (regexNumberAndSpecialChar.test(e.currentTarget.value)) {
        const value: string | number = e.currentTarget.value;
        if (wellEconomicsInputInfo?.inputs) {
          updateWellEconomicsInputByField(
            wellData.wellCardData.wellId as number,
            field,
            value
          );
        }

        triggerErrorFields(field, value);
      }
    },
    [wellEconomicsInputInfo?.inputs, wellData.wellCardData.wellId as number]
  );

  //onBlur, add comma/s and parenthesis if negative(-) value
  //and remove trailing zeroes for non currency
  const handleOnBlur = useCallback(
    (field: WellEconomicsInputFields) => {
      if (
        wellEconomicsInputInfo?.inputs &&
        wellEconomicsInputInfo?.inputs[field] !== null
      ) {
        const fieldValue = getFormattedValue(
          field,
          wellEconomicsInputInfo.inputs[field]
        );
        if (fieldValue) {
          updateWellEconomicsInputByField(
            wellData.wellCardData.wellId as number,
            field,
            fieldValue
          );

          triggerErrorFields(field, fieldValue);
        }
      }
    },
    [wellEconomicsInputInfo?.inputs]
  );

  //Loop and revalidate all the fields before recalculating
  const validateBeforeRecalculate = () => {
    let formattingError = false;
    (
      Object.keys(wellEconomicsInputInfo.inputs) as WellEconomicsInputFields[]
    ).forEach((inputField) => {
      const valueToCheck =
        wellEconomicsInputInfo.inputs[inputField]?.toString() ?? "";
      if (
        valueToCheck !== "" &&
        !checkFieldFormatting(inputField, valueToCheck)
      ) {
        formattingError = true;
        updateWellEconomicsError(wellData.wellCardData.wellId as number, {
          [inputField]: true,
        });
      }
    });

    if (formattingError) {
      updateAlertState({
        message: "Please make sure to use proper formatting for each fields.",
        severity: "error",
      });
      return false;
    }

    return true;
  };

  //check API validations
  const checkValidTaxOrNGLField = (field: WellEconomicsInputFields) => {
    if (
      constant.WELL_ECONOMICS_TAX_GAS.key === field ||
      constant.WELL_ECONOMICS_TAX_LIQUIDS.key === field
    ) {
      updateWellEconomicsError(wellData.wellCardData.wellId as number, {
        [constant.WELL_ECONOMICS_TAX_GAS.key]: false,
        [constant.WELL_ECONOMICS_TAX_LIQUIDS.key]: false,
      });
    }

    updateWellEconomicsError(wellData.wellCardData.wellId as number, {
      [field]: false,
    });
  };

  //check the correct field formatting of a field
  const checkFieldFormatting = (
    field: WellEconomicsInputFields,
    eventValue: string
  ) => {
    if (
      constant.WELL_ECONOMICS_INPUTS_CONSTANTS[field].regex.some((regex) =>
        regex.test(eventValue)
      )
    )
      return true;
    return false;
  };

  const triggerErrorFields = (
    field: WellEconomicsInputFields,
    value: string
  ) => {
    if (checkFieldFormatting(field, value)) {
      checkValidTaxOrNGLField(field);
      if (
        field === constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key ||
        constant.WELL_ECONOMICS_NGL_PRICE.key === field
      ) {
        const currentGreyedState = clone(
          wellEconomicsInputInfo.greyedState
        ) as WellEconomicsInputsError;

        if (value !== "" && value !== null) {
          const oilPrice =
            wellEconomicsInputInfo.inputs[
              constant.WELL_ECONOMICS_OIL_PRICE.key
            ];
          if (oilPrice !== null) {
            computeNGLFormula(
              wellData.wellCardData.wellId as number,
              field,
              value,
              oilPrice,
              currentGreyedState
            );
          }
        } else {
          updateWellEconomicsGreyedState(
            wellData.wellCardData.wellId as number,
            {
              ...currentGreyedState,
              [constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key]: false,
              [constant.WELL_ECONOMICS_NGL_PRICE.key]: false,
            }
          );
        }
      }
    } else {
      updateWellEconomicsError(wellData.wellCardData.wellId as number, {
        [field]: true,
      });
    }
  };

  return {
    handleInputChange,
    handleOnBlur,
    validateBeforeRecalculate,
  };
};

export default useWellEconomicsEvents;
