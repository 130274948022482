import { StateCreator } from "zustand";

import { Modules } from "../../types/modularity/modules";
import { ModularitySlice, RGLSlice } from "../../types/modularity/store";

import { clone } from "../../utils/helper";

const rglWindowSlice: StateCreator<RGLSlice> = (set) => ({
  isChartsDraggable: "",
  updateIsChartsDraggable: (isChartsDraggable) =>
    set(() => ({ isChartsDraggable })),

  lockDrag: false,
  updateLockDrag: (lockDrag) => set(() => ({ lockDrag })),
});

const modularSlice: StateCreator<ModularitySlice> = (set, get) => ({
  modules: [],
  addModule: (moduleName, chartType, title, refetch) =>
    set((state) => ({
      modules: [
        ...state.modules,
        {
          module: moduleName,
          refetch: refetch,
          isExpanded: true,
          chartType: chartType,
          title: title,
        },
      ],
    })),
  updateModules: (modules) => set(() => ({ modules })),
  updateModuleDataByKey: (chartId, moduleData) =>
    set(() => ({
      modules: get().modules.map((currentModuleData) => {
        const newModuleData =
          currentModuleData?.module === chartId
            ? moduleData
            : currentModuleData;
        return newModuleData;
      }),
    })),
  updateModuleDataRefresh: (chartIds) =>
    set(() => ({
      modules: get().modules.map((currentModuleData) => {
        const newModuleData: Modules = clone(currentModuleData);
        if (chartIds.includes(currentModuleData?.module)) {
          newModuleData.refetch = true;
        }

        return newModuleData;
      }),
    })),

  chartFullScreenLayout: [],
  addChartFullScreenLayout: (chartId, chartType) => {
    set((state) => ({
      chartFullScreenLayout: [
        {
          type: chartType,
          i: chartId,
          x: 0,
          y: 0,
          w: 9,
          h: 2,
          minW: 4,
          maxW: 9,
          minH: 2,
        },
        ...state.chartFullScreenLayout,
      ],
    }));
  },
  updateChartFullScreenLayout: (chartFullScreenLayout) =>
    set((state) => ({ chartFullScreenLayout })),

  chartDefaultLayout: [],
  addChartDefaultLayout: (chartId, chartType) => {
    set((state) => ({
      chartDefaultLayout: [
        {
          type: chartType,
          i: chartId,
          x: 0,
          y: 0,
          w: 2.88,
          h: 2,
          minW: 2.88,
          maxW: 2.88,
          minH: 2,
        },
        ...state.chartDefaultLayout,
      ],
    }));
  },
  updateChartDefaultLayout: (chartDefaultLayout) =>
    set((state) => ({ chartDefaultLayout })),
});

export { modularSlice, rglWindowSlice };
