import { toLonLat } from "ol/proj";

export const convertExtentToTGSBBOX = (extent: number[]) => {
  const minCoordinate = [extent[0], extent[1]];
  const maxCoordinate = [extent[2], extent[3]];

  const minLonLat = toLonLat(minCoordinate);
  const maxLonLat = toLonLat(maxCoordinate);

  const minLatLong = [minLonLat[1], minLonLat[0]];
  const maxLatLong = [maxLonLat[1], maxLonLat[0]];

  return [...minLonLat, ...maxLonLat];
};

// viewport is depracated but saving for reference
export const getGeometryFromPolygonArray = (
  polygons: {
    coordinates: number[][][];
  }[],
  viewport?: any
) => {
  let geometry;
  if (polygons.length) {
    if (polygons.length > 1) {
      geometry = {
        coordinates: polygons.map((polygon) => polygon.coordinates),
        type: "MultiPolygon",
      };
    } else {
      geometry = {
        coordinates: polygons[0].coordinates,
        type: "Polygon",
      };
    }
  } else if (viewport?.coordinates?.length) {
    geometry = {
      coordinates: viewport.coordinates,
      type: "Polygon",
    };
  }

  return geometry;
};

export default { convertExtentToTGSBBOX };
