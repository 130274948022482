import { LayoutAxis, Legend } from "plotly.js";

export const defaultConfig = {
  responsive: true,
  displayModeBar: false,
};

export const generalLayout: Partial<Plotly.Layout> = {
  showlegend: true,
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  dragmode: "zoom",
  uirevision: "1",
  barmode: "stack",
};

export const legendLayout: Partial<Legend> = {
  itemclick: false,
  itemdoubleclick: false,
  y: -0.4,
  x: -0.4,
  font: {
    size: 12,
    color: "#9E9E9E",
  },
};

export const marginLayout = {
  t: 10,
  b: 60,
  l: 0,
  r: 10,
};

export const fontLayout = {
  size: 12,
  family: "Roboto, Arial",
};

export const xAxisLayout = (defaultXAxisLabel: string) => {
  const xAxis: Partial<LayoutAxis> = {
    fixedrange: true,
    autorange: true,
    title: {
      text: defaultXAxisLabel,
      font: {
        family: "Roboto, Arial",
        color: "#9E9E9E",
        size: 12,
      },
    },
    automargin: true,
    color: "rgba(255, 255, 255, 0.7)",
    linecolor: "rgba(117, 117, 117, 1)",
    griddash: "dot",
    gridcolor: "rgba(117, 117, 117, 1)",
    hoverformat: ",.2f",
    showgrid: true,
  };
  return xAxis;
};

export const yAxisLayout: Partial<LayoutAxis> = {
  fixedrange: true, // config to not allow axis dragging
  categoryorder: "total ascending",
  automargin: true,
  color: "rgba(255, 255, 255, 0.7)",
  griddash: "dot",
  gridcolor: "rgba(117, 117, 117, 1)",
  linecolor: "rgba(117, 117, 117, 1)",
  showgrid: true,
};
