import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useOktaAuth } from "@okta/okta-react";

import { Menu, MenuItem } from "@mui/material";

import jwt_decode from "jwt-decode";

import { Token } from "../../../types/okta/token";

import useStore from "../../../store/useStore";

import CustomCircularButton from "../../common/CustomCircularButton";

import { TGSUserIcon } from "../../../assets/CustomIcons";

const ProfileSettings = () => {
  const navigate = useNavigate();
  const { authState, oktaAuth } = useOktaAuth();

  const updateAuthUser = useStore((state) => state.updateAuthUser);

  const [userLoggedIn, setUserLoggedIn] = useState<string>();
  const [userAnchor, setUserAnchor] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(userAnchor);

  const logoutHandle = async () => {
    setUserAnchor(null);
    try {
      await oktaAuth.signOut();
    } catch (err) {
      console.error(err, "error logout");
      navigate("/");
    }
  };

  useEffect(() => {
    if (authState?.isAuthenticated && authState.accessToken) {
      const decodedToken = jwt_decode<Token>(authState.accessToken.accessToken);
      updateAuthUser(decodedToken);
      setUserLoggedIn(decodedToken.UserEmail);
    }
  }, [authState]);

  return (
    <>
      <CustomCircularButton
        width={36}
        height={36}
        isActive={open}
        onClick={(event) => {
          setUserAnchor(event.currentTarget);
        }}
      >
        <TGSUserIcon />
      </CustomCircularButton>

      <Menu
        id="long-menu"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        anchorEl={userAnchor}
        open={open}
        onClose={() => setUserAnchor(null)}
        className="header-menu profile-settings"
      >
        <MenuItem disabled className="user-logged-in">
          <p>{userLoggedIn}</p>
        </MenuItem>
        <MenuItem onClick={logoutHandle}>Logout</MenuItem>
      </Menu>
    </>
  );
};

export default ProfileSettings;
