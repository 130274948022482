import { useCallback, useMemo } from "react";

import { ActivePanelKeys } from "../../types/panels/sidebar";

import { SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";

import usePanelsStore from "../../store/panels/panelsStore";

const useSideBarNavPanel = () => {
  const activePanel = usePanelsStore((state) => state.activePanel);
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );

  const isMenuTextOpen = useCallback(
    (menuText: ActivePanelKeys) => {
      return Boolean(
        isOpenAppLevelNav && isOpenLeftSidePanel && menuText === activePanel
      );
    },
    [isOpenAppLevelNav, isOpenLeftSidePanel, activePanel]
  );

  const openSidebarPanel = useMemo(() => {
    return {
      dashboardPanel: isMenuTextOpen(SIDEBAR_MENU_KEYS.DEFAULT_CHARTS),
      searchPanel: isMenuTextOpen(SIDEBAR_MENU_KEYS.SEARCH),
      savedSearchPanel: isMenuTextOpen(SIDEBAR_MENU_KEYS.SAVED_SEARCH),
      wellPanel: isMenuTextOpen(SIDEBAR_MENU_KEYS.WELL_PANEL),
      exportManagerPanel: isMenuTextOpen(
        SIDEBAR_MENU_KEYS.EXPORT_PACKAGE_MANAGER
      ),
    };
  }, [activePanel, isOpenAppLevelNav, isOpenLeftSidePanel]);

  return {
    openSidebarPanel,
  };
};

export default useSideBarNavPanel;
