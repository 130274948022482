import { LayerObject } from "../../types/map/hookTemp";

import { BOTTOM_WELL_SPOTS, WELL_SPOTS } from "../../constants/constants";

export const isLayerSelected = (layerName: string, layers: LayerObject[]) => {
  return !!layers.find((layer) => layer.name === layerName)?.isSelected;
};

export const isSurfaceSelected = (layers: LayerObject[]) =>
  isLayerSelected(WELL_SPOTS, layers);
export const isBottomSelected = (layers: LayerObject[]) =>
  isLayerSelected(BOTTOM_WELL_SPOTS, layers);
