import { StateCreator } from "zustand";

import { DataGridSortStore } from "../../types/grid";

import { defaultSortModel } from "../../utils/datagrid";
import { clone } from "../../utils/helper";

const dataGridSortSlice: StateCreator<DataGridSortStore> = (set, get) => ({
  sortModel: clone(defaultSortModel),
  updateSortModel: (sortModel) => set((state) => ({ sortModel })),
  sortPayload: [],
  updateSortPayload: (sortPayload) => set((state) => ({ sortPayload })),
  sortByAnalysis: false,
  updateSortByAnalysis: (sortByAnalysis) =>
    set((state) => ({ sortByAnalysis })),
});

export { dataGridSortSlice };
