import { AttributeSortOptions } from "../../../../types/panels/searchPanel/queryBuilder/sortPerAttribute";

export const ATTRIBUTE_ALPHA_ASC = "alphaASC";
export const ATTRIBUTE_ALPHA_DESC = "alphaDESC";
export const ATTRIBUTE_WELL_COUNT_ASC = "wellcountASC";
export const ATTRIBUTE_WELL_COUNT_DESC = "wellcountDESC";

export const ATTRIBUTE_SORT_OPTIONS: AttributeSortOptions = {
  [ATTRIBUTE_ALPHA_ASC]: {
    type: "alpha",
    order: "ASC",
    text: "Sorted by Alpha (A-Z)",
    nextSort: ATTRIBUTE_WELL_COUNT_DESC,
  },
  [ATTRIBUTE_ALPHA_DESC]: {
    type: "alpha",
    order: "DESC",
    text: "Sorted by Alpha (Z-A)",
    nextSort: ATTRIBUTE_WELL_COUNT_ASC,
  },
  [ATTRIBUTE_WELL_COUNT_ASC]: {
    type: "well-count",
    order: "ASC",
    text: "Sorted by Well Count (Smallest - Largest)",
    nextSort: ATTRIBUTE_ALPHA_ASC,
  },
  [ATTRIBUTE_WELL_COUNT_DESC]: {
    type: "well-count",
    order: "DESC",
    text: "Sorted by Well Count (Largest - Smallest)",
    nextSort: ATTRIBUTE_ALPHA_DESC,
  },
};
