import { GROUP_BY_MAP } from "../../constants/charts/chartConfig";
import { CHART_GROUP_BY_TYPE } from "../../constants/charts/charts";

export const useProductionPlotExport = () => {
  const generateExportFileName = (
    chartType: string,
    chartId: string,
    groupBy?: string,
    title?: string
  ) => {
    let fileName = "";

    if (title) {
      fileName = `${title}_`;
    } else {
      fileName = `${chartType}_${chartId}_`;
    }

    if (groupBy) {
      const mappedGroupBy = GROUP_BY_MAP[groupBy];

      if (mappedGroupBy === GROUP_BY_MAP.Default) {
        fileName += "GroupByALL";
      } else if (mappedGroupBy === CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS) {
        fileName += "GroupByIndividualWells";
      } else {
        fileName += `GroupBy${mappedGroupBy}`;
      }
    }

    return fileName;
  };
  return {
    generateExportFileName,
  };
};
