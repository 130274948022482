import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import {
  Button,
  Checkbox,
  Dialog,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  IconButton,
  Typography,
} from "@mui/material";

import classNames from "classnames";

import useExportPanelStore from "../../../store/exportPanel/exportPanelStore";

import useExportPermitAction from "../../../customHooks/panels/permitsPanel/useExportPermitAction";
import useExportPermitPanel from "../../../customHooks/panels/permitsPanel/useExportPermitPanel";

import WellItemsAccordion from "../../common/WellItemsAccordion";

import { CloseIcon } from "../../../assets/CustomIcons";

const ExportPermitPanel = () => {
  const exportPermitData = useExportPanelStore(
    (state) => state.exportPermitData
  );

  const {
    isExcelExportDisabled,
    availablePermitInfoExportItemObj,
    permitInfoCheckedItems,
    isAllPermitItemsChecked,
    handleAllCheckedItem,
    handleCheckedPermitInfoItem,
    handleOnClose,
  } = useExportPermitPanel(exportPermitData?.permitPanelActiveInfo);

  const { handleExportAsExcel } = useExportPermitAction();

  return (
    <Dialog
      className="export-well-panel-modal"
      open={Boolean(exportPermitData?.isOpenExportPermitPanelDialog)}
      disablePortal
    >
      <DialogTitle>
        <div className="uwi-label">
          Export{" "}
          <div className="uwi-label">
            <Typography>
              UWI {exportPermitData?.permitPanelActiveInfo.uwi.toString() ?? ""}
            </Typography>
          </div>
        </div>
        <div className="close-button">
          <IconButton disableRipple size="small" onClick={handleOnClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              name="All"
              disableRipple
              checked={isAllPermitItemsChecked}
              onChange={handleAllCheckedItem}
              checkedIcon={<CheckBoxOutlinedIcon />}
            />
          }
          label={<Typography>All</Typography>}
        />

        <WellItemsAccordion
          title="Permit Information"
          hasCheckbox
          checkedAccordion={isAllPermitItemsChecked}
          onChecked={handleAllCheckedItem}
        >
          <FormGroup>
            {availablePermitInfoExportItemObj.map((section, key) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    onChange={(e) => handleCheckedPermitInfoItem(section.key)}
                    value={<Typography>{section.displayLabel}</Typography>}
                    checked={permitInfoCheckedItems.includes(section.key)}
                    disableRipple
                    checkedIcon={<CheckBoxOutlinedIcon />}
                  />
                }
                label={<Typography>{section.displayLabel}</Typography>}
              />
            ))}
          </FormGroup>
          <div className="export-button-groups">
            <div className="close-button">
              <Button onClick={handleOnClose} disableRipple aria-disabled>
                CLOSE
              </Button>
            </div>
            <div className="round-buttons">
              <div
                className={classNames("excel-export-button", {
                  "disabled-excel":
                    availablePermitInfoExportItemObj.length === 0,
                })}
              >
                <Button
                  disableRipple
                  disabled={isExcelExportDisabled}
                  onClick={() => {
                    if (exportPermitData?.permitPanelActiveInfo)
                      handleExportAsExcel(
                        permitInfoCheckedItems,
                        exportPermitData.permitPanelActiveInfo,
                        exportPermitData.permitPanelActiveRecordType
                      );
                  }}
                >
                  <Typography>EXPORT AS EXCEL</Typography>
                </Button>
              </div>
            </div>
          </div>
        </WellItemsAccordion>
      </FormGroup>
    </Dialog>
  );
};

export default ExportPermitPanel;
