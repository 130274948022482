export const defaultConfig = {
  responsive: true,
  displayModeBar: false,
};

export const generalLayout = {
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  uirevision: 1,
  showlegend: true,
};

export const marginLayout = {
  t: 10,
  b: 60,
  l: 30,
  r: 10,
  pad: 5,
};

export const fontLayout = {
  size: 12,
  family: "Roboto, Arial",
};
