const DownloadIcon = () => {
  return (
    <svg
      width="12"
      height="13"
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 10L2 6L3.0625 4.9375L5.25 7.125V0H6.75V7.125L8.9375 4.9375L10 6L6 10ZM1.4941 13C1.08137 13 0.729167 12.8531 0.4375 12.5594C0.145833 12.2656 0 11.9125 0 11.5V10H1.5V11.5H10.5V10H12V11.5C12 11.9125 11.853 12.2656 11.5591 12.5594C11.2652 12.8531 10.9119 13 10.4992 13H1.4941Z"
        fill="black"
        fillOpacity="0.87"
      />
    </svg>
  );
};

export default DownloadIcon;
