import { useCallback, useReducer } from "react";

import { SAVED_SEARCH_DELETE_STATE_ACTIONS } from "../../constants/panels/savedSearchPanel/component";

import { savedSearchDeleteStateReducer } from "../../reducers/savedSearch";

const useSavedSearchStateActions = () => {
  const [savedSearchDeleteState, savedSearchDeleteStateDispatch] = useReducer(
    savedSearchDeleteStateReducer,
    {}
  );

  const handleDeleteClick = useCallback((id: number) => {
    savedSearchDeleteStateDispatch({
      type: SAVED_SEARCH_DELETE_STATE_ACTIONS.DELETE_MODE,
      payload: { id, status: true },
    });
  }, []);

  const handleCancelClick = useCallback((id: number) => {
    savedSearchDeleteStateDispatch({
      type: SAVED_SEARCH_DELETE_STATE_ACTIONS.DELETE_MODE,
      payload: { id, status: false },
    });
  }, []);

  const deletingCallback = useCallback((id: number) => {
    savedSearchDeleteStateDispatch({
      type: SAVED_SEARCH_DELETE_STATE_ACTIONS.DELETING,
      payload: { id },
    });
  }, []);

  const deletedCallback = useCallback((deletedSavedId: number) => {
    savedSearchDeleteStateDispatch({
      type: SAVED_SEARCH_DELETE_STATE_ACTIONS.DELETED,
      payload: { id: deletedSavedId },
    });
  }, []);

  return {
    savedSearchDeleteState,
    savedSearchDeleteStateDispatch,
    handleDeleteClick,
    handleCancelClick,
    deletingCallback,
    deletedCallback,
  };
};

export default useSavedSearchStateActions;
