import { FC } from "react";

import { isArray } from "lodash";

import { PermitPanelSectionProp } from "../../../../types/panels/permitPanel/permitPanel";

import { PERMIT_PANEL_SECTION } from "../../../../constants/panels/permitPanel/permit";

import WellPanelAccordion from "../../wellPanel/common/WellPanelAccordion";
import AccordionSectionCard from "../common/AccordionSectionCard";
import SectionCard from "../common/SectionCards";

const RelatedPermits: FC<PermitPanelSectionProp> = ({
  isExpanded,
  onChange,
  data,
}) => {
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={PERMIT_PANEL_SECTION.RELATED_PERMITS_SECTION.displayLabel}
      onChange={() => onChange(PERMIT_PANEL_SECTION.RELATED_PERMITS_SECTION)}
      isLoading={false}
    >
      {data?.relatedPermits &&
        isArray(data.relatedPermits) &&
        data.relatedPermits.map((relatedPermits, index) => (
          <SectionCard key={index}>
            <AccordionSectionCard data={relatedPermits} />
          </SectionCard>
        ))}
    </WellPanelAccordion>
  );
};

export default RelatedPermits;
