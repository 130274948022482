import { useState } from "react";

import axios from "axios";

import { Casings } from "../../../types/panels/wellPanel/casingsType";

import config from "../../../configs/appSettings";

import { CASING_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import useGetRequestParams from "../../common/useGetRequestParams";

export const useWellPanelCasings = () => {
  const [data, setData] = useState<Casings[]>([]);
  const [success, setSuccess] = useState(true);
  const [error, setError] = useState(null);

  const { buildRequestQueryParam } = useGetRequestParams();

  const getWellCasings = async (wellId: number, uwi: string) => {
    setSuccess(false);
    try {
      const response = await axios.get<Casings[]>(
        `${config.endpoints.wellService}api/wells/${wellId}/${CASING_SECTION.urlSlug}`,
        {
          ...buildRequestQueryParam,
        }
      );
      const { data } = response;

      if (data && data.length) {
        const dataWithUWI = data.map((casing) => {
          casing.uwi = uwi;
          return {
            ...casing,
          };
        });

        setData(dataWithUWI);
        setSuccess(true);
        return dataWithUWI;
      }
    } catch (error: any) {
      console.debug("No WellCard Casings", error);
      setSuccess(false);
      setError(error);

      return {
        status: "error",
        msg: error,
      };
    }
  };

  return { getWellCasings, data, success, error };
};
export default useWellPanelCasings;
