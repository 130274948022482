import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import jwt_decode, { JwtPayload } from "jwt-decode";

import config from "../../configs/appSettings";

import useAppModeStore from "../../store/common/appModeStore";
import useExportCardStore from "../../store/exportCard/exportCardStore";
import useQueryBuilderStore from "../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../store/useStore";

import { capitalizeFirstLetterString } from "../../utils/helper2";

import useCreateUpdateSavedSearch from "../savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";
import useGetAllSavedSearches from "../savedSearch/useGetAllSavedSearches";
import useSearchPanel from "../search/useSearchPanel";
import useSaveExportSearch from "./useSaveExportSearch";

const useExportPackageManager = () => {
  const [searchCriteria, setSearchCriteria] = useState({});

  const exportPanelMenuTrigger = useExportCardStore(
    (state) => state.exportPanelMenuTrigger
  );
  const updateExportPanelMenuTrigger = useExportCardStore(
    (state) => state.updateExportPanelMenuTrigger
  );
  const updateIsExportManagerLoading = useExportCardStore(
    (state) => state.updateIsExportManagerLoading
  );

  const exportUwiList = useExportCardStore((state) => state.exportUwiList);

  const isQBUpdated = useQueryBuilderStore((state) => state.isQBUpdated);
  const updateIsQBUpdated = useQueryBuilderStore(
    (state) => state.updateIsQBUpdated
  );

  const selectedSavedSearchData = useStore(
    (state) => state.selectedSavedSearchData
  );
  const authUser = useStore((state) => state.authUser);

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);

  const exMan = useRef<Window | null>(null);

  const UoMValueOnSelection = useAppModeStore(
    (state) => state.UoMValueOnSelection
  );

  const {
    data: savedSearchListData,
    isLoading: savedSearchListDataLoading,
    success: savedSearchListSuccess,
    getAllSavedSearches,
  } = useGetAllSavedSearches();

  const {
    isLoading: updateSavedSearchLoading,
    data: updatedSavedSearchData,
    updateSavedSearch,
  } = useCreateUpdateSavedSearch();

  const { getExportSaveSearch, savedSearchDataLoading, savedSearchData } =
    useSaveExportSearch();

  const { searchTypeByActiveTab: searchType } = useSearchPanel();

  const loadedSearchName = useMemo(
    () => selectedSavedSearchData.searchName,
    [selectedSavedSearchData]
  );

  const callExportManager = () => {
    const oktaCredentials = JSON.parse(
      localStorage.getItem("okta-token-storage") || "{}"
    );

    const userCredentials = oktaCredentials.idToken;
    const userName = userCredentials.claims["name"];

    const UserEmail = jwt_decode<JwtPayload>(
      oktaCredentials.accessToken.accessToken
    ).sub;

    const exportManager = exMan.current;
    if (!exportManager) return;
    exportManager.location = config.endpoints.exportManager;

    setTimeout(() => {
      exportManager.postMessage(
        {
          accessToken: oktaCredentials.accessToken.accessToken,
          applicationName: "Well Data Analytics",
          applicationId: "WellDataAnalytics",
          userEmail: UserEmail,
          userName: userName,
          scheduleEnabled: false,
          searchCriteria: searchCriteria,
          productTypes: ["WellProductionData"],
          name: loadedSearchName !== "" ? loadedSearchName : "",
          UOM: capitalizeFirstLetterString(UoMValueOnSelection),
          id: savedSearchData
            ? savedSearchData.id
            : updatedSavedSearchData
            ? updatedSavedSearchData.id
            : 0,
        },
        "*"
      );
      updateIsExportManagerLoading(false);
    }, 2000);
    exportManager.focus();
  };

  const checkExportPackageManager = useCallback(() => {
    //trigger and ready export in new tab
    //trigger save search
    const exportManagerWindow = window.open("", "_blank");
    exportManagerWindow?.document.write("Loading Export Manager...");
    exMan.current = exportManagerWindow;

    updateIsExportManagerLoading(true);
    getAllSavedSearches();
    updateExportPanelMenuTrigger(false);
  }, []);

  useEffect(() => {
    if (exportPanelMenuTrigger) {
      checkExportPackageManager();
    }
  }, [exportPanelMenuTrigger]);

  useEffect(() => {
    if (
      savedSearchListSuccess &&
      savedSearchListData &&
      !savedSearchListDataLoading
    ) {
      if (loadedSearchName && !isQBUpdated) {
        if (isQBUpdated) {
          // this never gets called (saving for reference)
          // for generating new save search with _number
          getExportSaveSearch(savedSearchListData, loadedSearchName);
        } else {
          updateSavedSearch(searchType);
        }
      } else {
        getExportSaveSearch();
      }
    }
  }, [savedSearchListSuccess, savedSearchListData, savedSearchListDataLoading]);

  useEffect(() => {
    if (
      (savedSearchData && !savedSearchDataLoading) ||
      (updatedSavedSearchData && !updateSavedSearchLoading)
    ) {
      setTimeout(() => {
        callExportManager();
        updateIsQBUpdated(false);
      }, 3000);
    }
  }, [
    savedSearchData,
    savedSearchDataLoading,
    updatedSavedSearchData,
    updateSavedSearchLoading,
  ]);

  useEffect(() => {
    if (uploadedUWIFile && uploadedUWIFile?.name !== "") {
      setSearchCriteria({
        uwiFilePath:
          config.endpoints.fileUploadFolderPath +
          authUser.uid +
          "/" +
          uploadedUWIFile?.name,
      });
    } else {
      setSearchCriteria({ uwis: exportUwiList });
    }
  }, [JSON.stringify(uploadedUWIFile), exportUwiList]);
};

export default useExportPackageManager;
