import { FC, useEffect, useState } from "react";

import { Delete as DeleteIcon } from "@mui/icons-material";
import { Button, Divider, IconButton } from "@mui/material";

import classNames from "classnames";

import { ActionButtonsProps } from "../../../types/panels/savedSearchPanel/component";

import useDeleteSavedSearch from "../../../customHooks/savedSearch/useDeleteSavedSearch";

import Loading from "../../common/Loading";
import ShareSearchMenu from "../common/ShareSearchMenu";
import ShareSearchIcon from "../searchPanel/shareSearch/ShareSearchIcon";

const ActionButtons: FC<ActionButtonsProps> = ({
  saveSearchInfo,
  itemState,
  disabled,
  handleDeleteClick,
  handleCancelClick,
  deletingCallback,
  deletedCallback,
}) => {
  const [shareSearchAnchorEl, setShareSearchAnchorEl] =
    useState<null | HTMLElement>(null);
  const openShareSearchMenu = Boolean(shareSearchAnchorEl);

  const {
    isLoading: deleteSavedSearchLoading,
    data: deletedSavedId,
    error: deleteSavedError,
    deleteSavedSearch,
  } = useDeleteSavedSearch();

  useEffect(() => {
    if (deleteSavedSearchLoading || !deletedSavedId || deleteSavedError) return;
    deletedCallback(deletedSavedId);
  }, [
    deleteSavedSearchLoading,
    deletedSavedId,
    deleteSavedError,
    deletedCallback,
  ]);

  const handleDeleteConfirmClick = () => {
    deletingCallback(saveSearchInfo.id);
    deleteSavedSearch(saveSearchInfo.id);
  };

  return (
    <>
      <ShareSearchIcon
        classNames={classNames({
          "share-active-button": Boolean(shareSearchAnchorEl),
        })}
        handleOnClick={(e) => setShareSearchAnchorEl(e.currentTarget)}
      />
      <ShareSearchMenu
        open={openShareSearchMenu}
        anchorEl={shareSearchAnchorEl}
        handleClose={() => setShareSearchAnchorEl(null)}
        searchType={"Attribute Search"}
        isFilterIncluded={false}
        shareInfo={saveSearchInfo}
      />
      {!itemState?.onDeleteMode && !itemState?.isDeleting && (
        <>
          <IconButton
            edge="end"
            aria-label="delete"
            disabled={disabled}
            onClick={() => handleDeleteClick(saveSearchInfo.id)}
          >
            <DeleteIcon />
          </IconButton>
        </>
      )}
      {itemState?.isDeleting && <Loading size={25} />}
      <div
        className={classNames("saved-search-confirm-container", {
          hide: !itemState?.onDeleteMode,
        })}
      >
        <Button
          variant="text"
          onClick={() => handleCancelClick(saveSearchInfo.id)}
        >
          CANCEL
        </Button>
        <Divider orientation="vertical" variant="middle" />
        <Button variant="text" onClick={handleDeleteConfirmClick}>
          CONFIRM
        </Button>
      </div>
    </>
  );
};

export default ActionButtons;
