import { P } from "../../types/common/general";

import {
  BOTTOM_GEO_POINT,
  BOTTOM_WELL_SPOTS,
  SURFACE_GEO_POINT,
  SURFACE_WELL_SPOTS,
  WELL_PATHS,
  WELL_PATHS_GEO_POINT,
  WELL_STICKS,
  WELL_STICKS_GEO_POINT,
} from "../constants";

export const GEO_COLUMN_TO_LAYER_MAPPER: P = {
  [SURFACE_GEO_POINT]: SURFACE_WELL_SPOTS,
  [BOTTOM_GEO_POINT]: BOTTOM_WELL_SPOTS,
  [WELL_PATHS_GEO_POINT]: WELL_PATHS,
  [WELL_STICKS_GEO_POINT]: WELL_STICKS,
};

export const GEO_COLUMN_PERMITS = "Permits";
