import { FC } from "react";

import { Typography } from "@mui/material";

import { Attribute } from "../../../../../../types/common/attributes";

import useQueryBuilderStore from "../../../../../../store/search/queryBulder/queryBuilderStore";

import QBEditorAttributes from "./QBEditorAttributes";

const QBEditorAvailAttributes: FC = () => {
  const removeToCurrentBounds = useQueryBuilderStore(
    (state) => state.removeToCurrentBounds
  );

  const currentQBInfo = useQueryBuilderStore((state) => state.currentQBInfo);
  const availableQBAttributes = useQueryBuilderStore(
    (state) => state.availableQBAttributes
  );
  const addToCurrentQBList = useQueryBuilderStore(
    (state) => state.addToCurrentQBList
  );
  const removeToCurrentQBList = useQueryBuilderStore(
    (state) => state.removeToCurrentQBList
  );
  const resetCurrentQBInfo = useQueryBuilderStore(
    (state) => state.resetCurrentQBInfo
  );

  const handleRemoveAttribute = (attribute: Attribute) => {
    if (currentQBInfo[attribute.key].values.length) {
      resetCurrentQBInfo(attribute.key, "options");
      resetCurrentQBInfo(attribute.key, "isUpdated");
    }
    removeToCurrentQBList(attribute);
    removeToCurrentBounds(attribute.key);
  };

  return (
    <div className="qb-editor-avail-container">
      {Object.entries(availableQBAttributes).map(([group, attributes]) =>
        attributes.length ? (
          <div key={group} className="qb-editor-avail-group">
            <Typography>{group}</Typography>
            <QBEditorAttributes
              attributes={attributes}
              handleAddAttribute={addToCurrentQBList}
              handleRemoveAttribute={handleRemoveAttribute}
            />
          </div>
        ) : null
      )}
    </div>
  );
};

export default QBEditorAvailAttributes;
