import { create } from "zustand";

import { SidePanelStates } from "../../types/panels/store";

import {
  analysisPanelSlice,
  appLevelNavSlice,
  leftSidePanelSlice,
  qbEditorPanelSlice,
  searchPanelTabSlice,
  wellPanelSlice,
} from "./panelsSlice";
import { permitPanelSlice } from "./permitPanelSlice";
import { wellEconomicSlice } from "./wellEconomicsSlice";

const usePanelsStore = create<SidePanelStates>()((...a) => ({
  ...appLevelNavSlice(...a),
  ...leftSidePanelSlice(...a),
  ...searchPanelTabSlice(...a),
  ...qbEditorPanelSlice(...a),
  ...analysisPanelSlice(...a),
  ...wellPanelSlice(...a),
  ...wellEconomicSlice(...a),
  ...permitPanelSlice(...a),
}));

export default usePanelsStore;
