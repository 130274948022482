import { useEffect, useState } from "react";

import { Tooltip, Typography, styled } from "@mui/material";

import { BinningLevel } from "../../../types/common/common";

import { BUBBLE_MAP } from "../../../constants/constants";
import {
  SIZE,
  SIZE_BY_ATTRIBUTE_INFO,
} from "../../../constants/map/mapSettings";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import useStore from "../../../store/useStore";

import usePrevious from "../../../customHooks/usePrevious";

const SquareIcon = styled("span")(({ type }: { type: BinningLevel }) => ({
  backgroundColor: "rgba(144, 202, 249, 0.5)",
  border: `${type === "low" ? "0.75px" : "1px"} solid #EEEEEE`,
  width: type === "low" ? 6 : type === "high" ? 28 : 3,
  height: type === "low" ? 6 : type === "high" ? 28 : 3,
  margin:
    type === "low"
      ? "6px 30px 6px 6px"
      : type === "high"
      ? "0 12px 0 0"
      : "9px 30px 8px 7px",
  borderRadius: "100%",
}));

const BubbleSizeList = () => {
  const isBubbleSizesLoading = useStore((state) => state.isBubbleSizesLoading);
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const bubbleSizes = useMapSettingsStore((state) => state.bubbleSizes);

  const previousLoading = usePrevious(isBubbleSizesLoading);
  const [bubbleSizeLabel, setBubbleSizeLabel] = useState("Bubble Size");

  useEffect(() => {
    const attribute = layerStyles[BUBBLE_MAP][SIZE];
    if (
      !!attribute &&
      previousLoading !== isBubbleSizesLoading &&
      !isBubbleSizesLoading
    ) {
      setBubbleSizeLabel(
        `Bubble Size (By ${SIZE_BY_ATTRIBUTE_INFO[attribute].label})`
      );
    }
  }, [layerStyles, isBubbleSizesLoading]);

  return (
    <div className="column">
      <Typography>{bubbleSizeLabel}</Typography>
      <div className="list">
        {bubbleSizes.map(
          ({ label, key }): React.ReactNode => (
            <div key={key} className="bubble-size-item legend-item">
              <div className="color-swatch">
                <SquareIcon type={key as BinningLevel} />
              </div>
              <Tooltip title={label}>
                <Typography className="color-by-label">{label}</Typography>
              </Tooltip>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default BubbleSizeList;
