import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import Map_Export_Image_1 from "../../../../assets/images/release-notes/Map_Export_1.png";
import Map_Export_Image_2 from "../../../../assets/images/release-notes/Map_Export_2.png";
import UnitOfMeasurement from "../../../../assets/images/release-notes/UnitOfMeasurement.png";

const Version20241113: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20241113"}
      onChange={handleChange("Version20241113")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.11.13
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          11/13/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes shapefile export and adjustable measurement
          units.
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>• Shapefile Export</AccordionContent>
      <AccordionSubContent>
        • Users can now export their search results as a ShapeFile or FileGeoDB
        for use in other geo applications. Exported data includes:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Bottom Well Spots <br />
          ▪ Surface Well Spots <br />
          ▪ Well Paths <br />
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionSubContent>
        • All Well Spots/Paths are exported with the attributes shown in the
        search results grid.
      </AccordionSubContent>
      <AccordionSubContent>
        • To export, click on Export in the left sidebar under the Share option.
      </AccordionSubContent>
      <AccordionImage src={Map_Export_Image_1} width="100%" draggable={false} />
      <AccordionImage src={Map_Export_Image_2} width="100%" draggable={false} />

      <AccordionContent>• Unit of Measurement</AccordionContent>
      <AccordionSubContent>
        • Users working with international data can now switch between Imperial
        and Metric units as needed.
      </AccordionSubContent>
      <AccordionSubContent>
        • This setting is accessible in the top-right corner of the application
        under the settings icon.
      </AccordionSubContent>
      <AccordionImage src={UnitOfMeasurement} width="100%" draggable={false} />
    </Accordion>
  );
};

export default Version20241113;
