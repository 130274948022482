import { useEffect, useMemo, useState } from "react";

import {
  Autocomplete,
  TextField,
  autocompleteClasses,
  styled,
} from "@mui/material";

import { Attribute } from "../../../types/common/attributes";

import {
  COLOR,
  COLOR_BY_ATTRIBUTE_INFO,
  UNIFORM_COLOR,
} from "../../../constants/map/mapSettings";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

interface AttributeSelectProps {
  layerName: string;
  colorByOptions: Attribute[];
  onAttributeSelect: (attribute: string) => void;
}

const StyledAutocomplete = styled(Autocomplete)(() => ({
  [`& .${autocompleteClasses.endAdornment}`]: {
    top: "unset",
  },
}));

const AttributeSelect = ({
  layerName,
  colorByOptions,
  onAttributeSelect,
}: AttributeSelectProps) => {
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const [value, setValue] = useState<Attribute | typeof UNIFORM_COLOR | null>(
    null
  );

  const attributeKey = useMemo(() => {
    let value: string | null = layerStyles[layerName][COLOR];
    if (value === UNIFORM_COLOR.key) {
      value = null;
    }
    return value;
  }, [layerStyles, layerName]);

  // Handles loading of saved searched map configurations
  useEffect(() => {
    // Auto set value of autocomplete
    const attribute = attributeKey
      ? COLOR_BY_ATTRIBUTE_INFO[attributeKey]
      : null;
    setValue(attribute);
  }, [attributeKey]);

  // On select of autocomplete option
  const handleAutocompleteSelect = (newValue: Attribute) => {
    setValue(newValue);

    if (newValue === null) return;
    onAttributeSelect(newValue.key);
  };

  return (
    <StyledAutocomplete
      value={value}
      onChange={(_, newValue) =>
        handleAutocompleteSelect(newValue as Attribute)
      }
      isOptionEqualToValue={(option, value) =>
        (option as Attribute).key === (value as Attribute).key
      }
      options={colorByOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="Attribute"
          placeholder="-Select an option-"
        />
      )}
      disableClearable
    />
  );
};

export default AttributeSelect;
