import { useMemo } from "react";

import { PanelDrawerProps } from "../../../types/panels/common/panelDrawer";

import {
  DASHBOARD_CHART_TYPES,
  HORIZONTAL_BAR_CHART_LABELS,
} from "../../../constants/charts/dashboardCharts";

import useChartStore from "../../../store/chart/chartStore";

import FullWidthStackedBarChart from "../../charts/dashboard/FullWidthStackedBarChart";
import HorizontalBarChart from "../../charts/dashboard/HorizontalBarChart";
import StackedAreaChart from "../../charts/dashboard/StackedAreaChart";
import PanelDrawer from "../common/PanelDrawer";

const DashboardChartsPanel = ({
  open,
  handleCloseMenuClick,
}: PanelDrawerProps) => {
  const dashboardChartData = useChartStore((state) => state.dashboardChartData);

  const findChartData = useMemo(() => {
    return dashboardChartData?.find(
      (chart) => chart.chartType === DASHBOARD_CHART_TYPES.HORIZONTAL_BAR_CHART
    );
  }, [dashboardChartData]);

  const findFullWidthStackedBarChart = useMemo(() => {
    return dashboardChartData?.find(
      (chart) =>
        chart.chartType === DASHBOARD_CHART_TYPES.FULL_WIDTH_STACKED_BAR
    );
  }, [dashboardChartData]);

  const findStackedAreaChart = useMemo(() => {
    return dashboardChartData?.find(
      (chart) => chart.chartType === DASHBOARD_CHART_TYPES.STACKED_AREA
    );
  }, [dashboardChartData]);

  return (
    <>
      <PanelDrawer
        open={open}
        className="dashboard-charts-panel"
        handleCloseMenuClick={handleCloseMenuClick}
      >
        <div className="dashboard-charts-container">
          {findChartData && (
            <HorizontalBarChart
              chartData={findChartData}
              defaultXAxisLabel={HORIZONTAL_BAR_CHART_LABELS["CumBOE"].DEFAULT}
            />
          )}
          {findStackedAreaChart && (
            <StackedAreaChart
              isDefaultChart
              title={findStackedAreaChart.title}
              chartData={findStackedAreaChart}
              defaultXAxisLabel="Year"
              defaultYAxisLabel={"Monthly Production (BOE)"}
            />
          )}
          {findFullWidthStackedBarChart && (
            <FullWidthStackedBarChart
              chartData={findFullWidthStackedBarChart}
            />
          )}
        </div>
      </PanelDrawer>
    </>
  );
};

export default DashboardChartsPanel;
