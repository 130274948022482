import { FC, PropsWithChildren } from "react";

interface CustomTabPanelProps {
  hidden: boolean;
  name: string;
  id?: string | number;
}

const CustomTabPanel: FC<PropsWithChildren<CustomTabPanelProps>> = ({
  children,
  hidden,
  name,
  id = "",
}) => {
  return (
    <div
      role="tabpanel"
      hidden={hidden}
      className={`custom-tab-panel-${name}`}
      aria-labelledby={`custom-tab-panel-${name}`}
      id={`well-panel-wrapper-${id}`}
    >
      {children}
    </div>
  );
};

export default CustomTabPanel;
