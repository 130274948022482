import { FC } from "react";

import { IconButton, IconButtonProps } from "@mui/material";

import "../../assets/scss/components/_customButton.scss";

import OutlinedCloseIcon from "./icons/OutlinedCloseIcon";

const CustomCloseButton: FC<IconButtonProps> = (props) => {
  return (
    <div className="custom-cl-overriden-close-button">
      <IconButton {...props}>
        <OutlinedCloseIcon />
      </IconButton>
    </div>
  );
};

export default CustomCloseButton;
