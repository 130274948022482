import { PlotMarker } from "plotly.js";

const COMMON_MARKER_CONFIG = {
  size: 9,
  line: {
    width: 2,
  },
};

//see marker API for additional properties
const targetMarkerStyle: Partial<PlotMarker> = {
  color: "#FFF",
  size: 9,
  symbol: "star",
};

const parentsMarkerStyle: Partial<PlotMarker> = {
  color: "blue",
  ...COMMON_MARKER_CONFIG,
  line: {
    width: COMMON_MARKER_CONFIG.line.width,
    color: "blue",
  },
};

const childMarkerStyle: Partial<PlotMarker> = {
  color: "red",
  ...COMMON_MARKER_CONFIG,
  line: {
    width: COMMON_MARKER_CONFIG.line.width,
    color: "red",
  },
};

const siblingMarkerStyle: Partial<PlotMarker> = {
  color: "#FFF",
  ...COMMON_MARKER_CONFIG,
  line: {
    width: COMMON_MARKER_CONFIG.line.width,
    color: "#FFF",
  },
};

const formationStyle = {
  color: "#444",
  line: {
    width: 1,
  },
};

export {
  targetMarkerStyle,
  parentsMarkerStyle,
  childMarkerStyle,
  siblingMarkerStyle,
  formationStyle,
};
