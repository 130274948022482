import { useEffect, useState } from "react";

import axios from "axios";

import { DataCurrenciesAPIResponseType } from "../../types/information/information";

import config from "../../configs/appSettings";

import useDataCurrencyStore from "../../store/dataCurrencies/dataCurrencyStore";

const API_BASE_URL = `${config.endpoints.wellService}api/data-currencies`;

const useDataCurrencies = () => {
  const updateIsLoadingCurrencyData = useDataCurrencyStore(
    (state) => state.updateIsLoadingCurrencyData
  );
  const updateCurrencyData = useDataCurrencyStore(
    (state) => state.updateCurrencyData
  );
  const currencyData = useDataCurrencyStore((state) => state.currencyData);
  const isLoadingCurrencyData = useDataCurrencyStore(
    (state) => state.isLoadingCurrencyData
  );

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    updateIsLoadingCurrencyData(true);
    const fetchData = async () => {
      try {
        const response = await axios.get<DataCurrenciesAPIResponseType[]>(
          API_BASE_URL
        );
        updateCurrencyData(response.data);
      } catch (error) {
        setError("Failed to fetch data currencies.");
      } finally {
        updateIsLoadingCurrencyData(false);
      }
    };

    fetchData();
  }, []);

  return { currencyData, isLoadingCurrencyData, error };
};

export default useDataCurrencies;
