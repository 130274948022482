export const getHeaderClassName = (
  isOpenAppLevelNav: boolean,
  isOpenLeftSidePanel: boolean
) => {
  let className = "grid-panel grid-full-width ";

  if (isOpenAppLevelNav) className += "app-level-open ";
  if (isOpenAppLevelNav && isOpenLeftSidePanel) className += "panel-open";

  return className;
};

export const getGridClassName = (isFullScreenGrid: boolean, width: number) => {
  let className = "grid-container ";

  if (isFullScreenGrid) className += "super-grid ";
  if (width <= 820) className += "small-sized ";
  return className;
};

export const getGridContainerClassName = (isFullScreenGrid: boolean) => {
  let className = "default-super-grid-background ";
  if (isFullScreenGrid) className += "super-grid-background ";
  return className;
};
