import { useState } from "react";

import axios from "axios";

import {
  GunBarrelChildren,
  GunBarrelFormations,
  GunBarrelInfo,
  GunBarrelParents,
  GunBarrelSiblings,
} from "../../../types/panels/wellPanel/gunBarrelType";

import config from "../../../configs/appSettings";

import { GUN_BARREL_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import useGetRequestParams from "../../common/useGetRequestParams";

const useWellPanelGunBarrel = () => {
  const [gunBarrel, setGunBarrel] = useState<GunBarrelInfo>();
  const [success, setSuccess] = useState(true);
  const { buildRequestQueryParam } = useGetRequestParams();

  const checkWellsValue = (
    gunBarrelData:
      | GunBarrelParents[]
      | GunBarrelChildren[]
      | GunBarrelSiblings[],
    key: keyof GunBarrelParents &
      keyof GunBarrelChildren &
      keyof GunBarrelSiblings
  ) => {
    const test = gunBarrelData.every(
      (data) => data[key] !== null && data[key] !== undefined
    );
    return gunBarrelData.every(
      (data) => data[key] !== null && data[key] !== undefined
    );
  };

  const checkFormationsValue = (formations: GunBarrelFormations[]) => {
    return formations.every(
      (data) =>
        data.y !== null &&
        data.y !== undefined &&
        data.yoffset !== null &&
        data.yoffset !== undefined
    );
  };

  const isDataValid = (data: GunBarrelInfo | undefined) => {
    if (!data) return false;

    if (!data.targetWell || !data.targetWell.y) return false;

    if (
      data.parents &&
      data.parents.length &&
      (!checkWellsValue(data.parents, "distance") ||
        !checkWellsValue(data.parents, "x") ||
        !checkWellsValue(data.parents, "y"))
    )
      return false;

    if (
      data.children &&
      data.children.length &&
      (!checkWellsValue(data.children, "distance") ||
        !checkWellsValue(data.children, "x") ||
        !checkWellsValue(data.children, "y"))
    )
      return false;

    if (
      data.siblings &&
      data.siblings.length &&
      (!checkWellsValue(data.siblings, "distance") ||
        !checkWellsValue(data.siblings, "x") ||
        !checkWellsValue(data.siblings, "y"))
    )
      return false;

    if (
      data.formations &&
      data.formations.length &&
      !checkFormationsValue(data.formations)
    )
      return false;

    return true;
  };

  const getWellGunBarrel = async (wellId: number) => {
    setSuccess(false);
    try {
      const response = await axios.get(
        `${config.endpoints.wellService}api/wells/${wellId}/diagram/${GUN_BARREL_SECTION.urlSlug}`,
        {
          ...buildRequestQueryParam,
        }
      );
      setGunBarrel(response.data);
      setSuccess(true);
    } catch (error) {
      console.debug("No WellCard Gun Barrel Data", error);
      return {
        status: "error",
        msg: error,
      };
    }
  };

  return { gunBarrel, success, getWellGunBarrel, isDataValid };
};
export default useWellPanelGunBarrel;
