import { FC } from "react";

import { Typography } from "@mui/material";

import { ResultCountProps } from "../../../../types/grid";

const ResultCount: FC<ResultCountProps> = ({ title, value, className }) => {
  return (
    <div className="count-group">
      <Typography>{`${title}:`}</Typography>
      <Typography className={`count-value ${className}`}>{value}</Typography>
    </div>
  );
};

export default ResultCount;
