import { BrowserRouter as Router, useNavigate } from "react-router-dom";

import { AccessToken, OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";

import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { LicenseInfo } from "@mui/x-license-pro";

import axios from "axios";
import "component-library/tgs.components.default.css";
import jwt_decode from "jwt-decode";

import { Token } from "./types/okta/token";

import config from "./configs/appSettings";

import AppRoutes from "./components/routes/AppRoutes";

import useOktaValidation from "./customHooks/auth/useOktaValidation";

import "../node_modules/bootstrap/scss/bootstrap.scss";
import { darkTheme } from "./themes";

import "./assets/css/dateRangePicker.css";
import "./assets/scss/body.scss";
import "./assets/scss/main.scss";

const { issuer, clientId, scope } = config.oidc;

const OktaChecker = () => {
  useOktaValidation();

  return <></>;
};

const App = () => {
  document.title = `${config.documentTitle}`;
  const navigate = useNavigate();
  LicenseInfo.setLicenseKey(config.muiXGridPremiumLicense);

  const oktaAuth = new OktaAuth({
    issuer,
    clientId,
    scopes: scope,
    redirectUri: window.location.origin + "/implicit-callback",
    pkce: true,
  });

  // Add a request interceptor
  axios.interceptors.request.use(
    async (config: any) => {
      const { tokenManager } = oktaAuth;
      const oktaTokenStorage = (await tokenManager.get(
        "accessToken"
      )) as AccessToken;

      if (oktaTokenStorage?.accessToken) {
        const { SapCustomerId, UserEmail }: Token = jwt_decode(
          oktaTokenStorage?.accessToken
        );

        if (
          !config.url.includes("EconomicsReportByUwi") &&
          !config.url.includes("/DataLake/Search") &&
          !config.url.includes("/PackageProducts")
        ) {
          //adding these to headers will result to CORS error request
          SapCustomerId && (config.headers["Sap-Customer-Id"] = SapCustomerId);
          UserEmail && (config.headers["User-Email"] = UserEmail);
        }

        config.headers[
          "Authorization"
        ] = `Bearer ${oktaTokenStorage?.accessToken}`;
      }

      config.headers["Content-Type"] = "application/json";
      return config;
    },
    (error: any) => {
      Promise.reject(error);
    }
  );

  // Add a response interceptor
  axios.interceptors.response.use(
    (response: any) => response,
    (error: any) => {
      if (
        !!error.config?.ignoreResponseInterceptor ||
        !!error.config?.retriedOnce
      ) {
        return Promise.reject(error);
      }
      error.config.retriedOnce = true;
      return axios.request(error.config);
    }
  );
  // Add:
  // ignoreResponseInterceptor: true
  // in headers to not retry

  const restoreOriginalUri = async (
    _oktaAuth: OktaAuth,
    originalUri: string
  ) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
      replace: true,
    });
    history.go(0);
  };

  return (
    <div className="App">
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <OktaChecker />
        <AppRoutes />
      </Security>
    </div>
  );
};

const AppWithRouterAccess = () => (
  <ThemeProvider theme={darkTheme}>
    <CssBaseline />
    <Router>
      <App />
    </Router>
  </ThemeProvider>
);

export default AppWithRouterAccess;
