import { useCallback } from "react";

import axios from "axios";

import {
  ExportMapLayerColumn,
  ExportMapLayerNames,
  ExportMapTypes,
  UWIIdentifierType,
} from "../../../types/exports/exportMap/exportMap";

import config from "../../../configs/appSettings";

import { NON_ATTRIBUTE_COLUMNS } from "../../../constants/grid";

import useExportMapStore from "../../../store/exportMap/exportMapStore";
import useDataGridStore from "../../../store/grid/dataGridStore";
import useAlertPopupStore from "../../../store/map/alert/alertStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../../store/useStore";

import { changeKey } from "../../../utils/datagrid";
import { getExportMapPayload } from "../../../utils/exports/exportMap";

const useExportMap = () => {
  const authUser = useStore((state) => state.authUser);
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const apiRef = useDataGridStore((state) => state.apiRef);
  const exportMapWellUwiList = useExportMapStore(
    (state) => state.exportMapWellUwiList
  );
  const isExportMapLoading = useExportMapStore(
    (state) => state.isExportMapLoading
  );
  const updateIsExportMapLoading = useExportMapStore(
    (state) => state.updateIsExportMapLoading
  );
  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  const getColumns = useCallback(() => {
    if (!apiRef) return [];
    const columns: ExportMapLayerColumn[] = apiRef.current
      .getVisibleColumns()
      .filter((col) => !NON_ATTRIBUTE_COLUMNS.includes(col.field))
      .map((filteredCol) => {
        const changedKey = changeKey(filteredCol.field);
        return {
          name: changedKey,
          alias: filteredCol.headerName ?? changedKey,
        };
      });
    return columns;
  }, [apiRef]);

  const exportMap = async (
    exportMapType: ExportMapTypes,
    layers: ExportMapLayerNames[]
  ) => {
    updateIsExportMapLoading(true);
    const url = config.endpoints.exportMap;

    let uwiIdentifier = exportMapWellUwiList;
    let uwiIdentifierType: UWIIdentifierType = "UWI_list";

    if (uploadedUWIFile && uploadedUWIFile?.name !== "") {
      uwiIdentifier = [
        `${config.endpoints.fileUploadFolderPath}${authUser.uid}/${uploadedUWIFile?.name}`,
      ];
      uwiIdentifierType = "UWI_bucket";
    }

    const columns = getColumns();
    const payload = getExportMapPayload(
      exportMapType,
      layers,
      uwiIdentifier,
      uwiIdentifierType,
      columns
    );

    try {
      await axios.post(url, payload);
      updateAlertState({
        severity: "info",
        message:
          "Upon completion of export process, you will be notified via email and provided with link to the data file.",
      });
      updateIsExportMapLoading(false);
    } catch (e) {
      console.error("exportAsGeoDatabase error", e);
      updateIsExportMapLoading(false);
    }
  };

  return { exportMap, isExportMapLoading };
};

export default useExportMap;
