import { useCallback, useState } from "react";

import axios from "axios";

import { WellDataResponse } from "../../types/grid";
import { FetchFirstBatchProps } from "../../types/grid/useAllWellData";
import {
  GetUWIFileIdWithRetry,
  UploadedUWIFile,
} from "../../types/panels/searchPanel/search";

import config from "../../configs/appSettings";

import useDataGridStore from "../../store/grid/dataGridStore";
import useStore from "../../store/useStore";

import { getFirstBatch } from "../../services/grid/allWellData";

import { settledPromise } from "../../utils/api/request";

import useFetchExtentData from "./useFetchExtentData";
import { useGridColumn } from "./useGridColumn";

const useFetchFirstBatch: (
  a: GetUWIFileIdWithRetry,
  b: UploadedUWIFile,
  c: boolean
) => any = (getUWIFileIdWithRetry, uploadedUWIFile, isFromUploadedUWIFile) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const updateDVTProcessing = useStore((state) => state.updateDVTProcessing);
  const viewportChanged = useStore((state) => state.viewportChanged);

  const initialWellDataFetched = useDataGridStore(
    (state) => state.initialWellDataFetched
  );
  const updateInitialWellDataFetched = useDataGridStore(
    (state) => state.updateInitialWellDataFetched
  );

  const updateAllGridData = useDataGridStore(
    (state) => state.updateAllGridData
  );

  const updateAllWellGridDataLoading = useDataGridStore(
    (state) => state.updateAllWellGridDataLoading
  );
  const updateAllWellGridDataSuccess = useDataGridStore(
    (state) => state.updateAllWellGridDataSuccess
  );
  const updateAllWellGridDataError = useDataGridStore(
    (state) => state.updateAllWellGridDataError
  );
  const updateGridFilteredLoading = useDataGridStore(
    (state) => state.updateGridFilteredLoading
  );
  const updateBatchWellGridDataSuccess = useDataGridStore(
    (state) => state.updateBatchWellGridDataSuccess
  );
  const updateBatchWellGridData = useDataGridStore(
    (state) => state.updateBatchWellGridData
  );
  const updateBatchWellGridDataLoading = useDataGridStore(
    (state) => state.updateBatchWellGridDataLoading
  );
  const updateColumnsFetched = useDataGridStore(
    (state) => state.updateColumnsFetched
  );

  const { getExtentData } = useFetchExtentData();
  const { getRequiredColumns } = useGridColumn();

  const fetchFirstBatch = useCallback(
    async ({ body, pageLimit, totalIterations }: FetchFirstBatchProps) => {
      try {
        const res: WellDataResponse = await getFirstBatch(body, pageLimit);
        const first10KDataResponse = res.wells;

        // Loading States
        updateBatchWellGridDataSuccess(true);
        updateBatchWellGridDataLoading(false);
        updateBatchWellGridData(first10KDataResponse);
        updateAllGridData(first10KDataResponse);
        if (body.columns?.length) updateColumnsFetched(body.columns);

        const productionWellsUrl = `${config.endpoints.wellService}api/wells/search/`;

        if (first10KDataResponse?.length) {
          getExtentData({
            body,
            getUWIFileIdWithRetry,
            uploadedUWIFile,
            isFromUploadedUWIFile,
          });
        }

        //empty will return only the required columns, without grid columns
        const reqCols = getRequiredColumns([]);

        // call succeeding batches
        if (totalIterations > 1) {
          const responses = [...Array(totalIterations).keys()]
            .filter((num) => {
              return num !== 0;
            })
            .map((num) => {
              return axios.post(
                `${productionWellsUrl}`,
                {
                  ...body,
                  columns: reqCols,
                  offset: num * pageLimit,
                  pageLimit,
                },
                {
                  ignoreResponseInterceptor: true,
                }
              );
            });

          settledPromise(responses, (values: { data: WellDataResponse }[]) => {
            const remainingData = values.flatMap(
              (response) => response.data.wells
            );
            const allData = first10KDataResponse.concat(remainingData);
            setData(allData);
            updateAllGridData(allData);
            setIsLoading(false);
            if (!viewportChanged && initialWellDataFetched)
              updateDVTProcessing(false);
            if (!initialWellDataFetched) updateInitialWellDataFetched(true);
            updateAllWellGridDataLoading(false);
            updateAllWellGridDataSuccess(true);
            setSuccess(true);
          });
        } else {
          setData(first10KDataResponse);
          setIsLoading(false);
          if (!viewportChanged && initialWellDataFetched)
            updateDVTProcessing(false);
          if (!initialWellDataFetched) updateInitialWellDataFetched(true);
          updateAllWellGridDataLoading(false);
          updateAllWellGridDataSuccess(true);
          setSuccess(true);
        }
      } catch (error: any) {
        console.debug("getAllWellData error", error);
        setError(error);
        setIsLoading(false);
        updateAllWellGridDataLoading(false);
        updateAllWellGridDataError(error);
        updateGridFilteredLoading(false);
        updateBatchWellGridDataSuccess(false);
        updateBatchWellGridDataLoading(false);
      }
    },
    [
      getExtentData,
      getUWIFileIdWithRetry,
      uploadedUWIFile,
      isFromUploadedUWIFile,

      initialWellDataFetched,
      updateAllGridData,
      updateAllWellGridDataError,
      updateAllWellGridDataLoading,
      updateAllWellGridDataSuccess,
      updateBatchWellGridData,
      updateBatchWellGridDataLoading,
      updateBatchWellGridDataSuccess,
      updateDVTProcessing,
      updateGridFilteredLoading,
      updateInitialWellDataFetched,
      viewportChanged,
    ]
  );

  return {
    isLoading,
    success,
    data,
    error,
    fetchFirstBatch,
  };
};

export default useFetchFirstBatch;
