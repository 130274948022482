import { StateCreator } from "zustand";

import {
  ExportModalSlice,
  ExportPlotChartSlice,
  ExportWellInformationSlice,
} from "../../types/panels/wellPanel/exportModalStore";

const exportWellPanelSlice: StateCreator<ExportModalSlice> = (set) => ({
  isOpenExportWellPanelDialog: false,
  updateIsOpenExportWellPanelDialog: (isOpenExportWellPanelDialog) =>
    set(() => ({ isOpenExportWellPanelDialog })),
  exportModalProps: undefined,
  updateExportModalProps: (exportModalProps) =>
    set(() => ({ exportModalProps })),
});

const exportWellInformationSlice: StateCreator<ExportWellInformationSlice> = (
  set
) => ({
  openedPanelAvailableSections: [],
  updateOpenedPanelAvailableSections: (list) =>
    set(() => ({ openedPanelAvailableSections: list })),
});

const exportPlotChartSlice: StateCreator<ExportPlotChartSlice> = (set) => ({
  gunBarrelData: undefined,
  updateGunBarrelData: (gunBarrelData) => set(() => ({ gunBarrelData })),
});

export {
  exportWellPanelSlice,
  exportWellInformationSlice,
  exportPlotChartSlice,
};
