import { StateCreator } from "zustand";

import { WellCounts } from "../../types/common/wells";
import { DataGridFilterStore } from "../../types/grid/store";

import {
  changeAttributeKey,
  defaultColumnsFilterType,
  defaultFilterAttributesOptions,
  defaultFilterModel,
} from "../../utils/datagrid";
import { clone } from "../../utils/helper";

const dataGridFilterSlice: StateCreator<DataGridFilterStore> = (set, get) => ({
  gridFilteredLoading: false,
  updateGridFilteredLoading: (gridFilteredLoading) =>
    set((state) => ({ gridFilteredLoading })),

  filterModel: clone(defaultFilterModel),
  updateFilterModel: (filterModel) => set((state) => ({ filterModel })),

  columnsFilterType: clone(defaultColumnsFilterType),
  updateColumnsFilterType: (column, newType) =>
    set(() => ({
      columnsFilterType: {
        ...get().columnsFilterType,
        [changeAttributeKey(column)]: newType,
      },
    })),
  updateAllColumnsFilterType: (columnsFilterType) =>
    set(() => ({ columnsFilterType })),
  resetColumnsFilterType: () =>
    set((state) => ({ columnsFilterType: clone(defaultColumnsFilterType) })),

  // Column Filter Attribute Options
  allSelectedFilterAttributes: [],
  updateAllSelectedFilterAttributes: (allSelectedFilterAttributes) =>
    set((state) => ({ allSelectedFilterAttributes })),
  columnFilterAttributes: clone(defaultFilterAttributesOptions),
  updateColumnFilterAttributes: (attributeKey, newValue) =>
    set(() => ({
      columnFilterAttributes: {
        ...get().columnFilterAttributes,
        [changeAttributeKey(attributeKey)]: newValue,
      },
    })),
  resetColumnFilterAttributes: () =>
    set((state) => ({
      columnFilterAttributes: clone(defaultFilterAttributesOptions),
    })),

  resetFilterAttribute: (attributeKeyToStay) =>
    set(() => {
      const currStatus = get().columnFilterAttributes;
      const newStatus: { [key: string]: WellCounts[] } = {};
      Object.entries(currStatus).forEach(([key, value]) => {
        newStatus[key] = attributeKeyToStay === key ? value : [];
      });
      return {
        columnFilterAttributes: newStatus,
      };
    }),
  resetDefaultGridFilter: () =>
    set((state) => ({
      allSelectedFilterAttributes: [],
      columnFilterAttributes: clone(defaultFilterAttributesOptions),
      columnsFilterType: clone(defaultColumnsFilterType),
    })),
});

export { dataGridFilterSlice };
