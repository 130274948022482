import { Attribute } from "../../../types/common/attributes";

import * as attributes from "../../attributes";
import { DATE, FLOAT, NO_GROUP, TEXT } from "../../attributes";

export const CASING_TYPE: Attribute = {
  key: "casingType",
  label: "Casing Type",
  dataType: TEXT,
  group: NO_GROUP,
};

export const CASING_PURPOSE: Attribute = {
  key: "casingPurpose",
  label: "Casing Purpose",
  dataType: TEXT,
  group: NO_GROUP,
};

export const HOLE_SIZE: Attribute = {
  key: "holeSize",
  label: "Hole Size",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const CASING_SIZE: Attribute = {
  key: "casingSize",
  label: "Casing Size",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const CASING_WEIGHT: Attribute = {
  key: "casingWeight",
  label: "Casing Weight",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const TOP_DEPTH: Attribute = {
  key: "topDepth",
  label: "Top Depth",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const BOTTOM_DEPTH: Attribute = {
  key: "bottomDepth",
  label: "Bottom Depth",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const CASING_GRADE: Attribute = {
  key: "casingGrade",
  label: "Casing Grade",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const FORMATION_NAME: Attribute = {
  key: "formationName",
  label: "Formation Name",
  dataType: TEXT,
  group: NO_GROUP,
};

export const PERF_DATE: Attribute = {
  key: "perfDate",
  label: "Perf Date",
  dataType: DATE,
  group: NO_GROUP,
};

export const PERF_TOP: Attribute = {
  key: "perfTop",
  label: "Perf Top",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const PERF_BOTTOM: Attribute = {
  key: "perfBottom",
  label: "Perf Bottom",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const PERMIT_FORMATION: Attribute = {
  key: "formation",
  label: "Permit Formation",
  dataType: TEXT,
  group: NO_GROUP,
};

export const PERMIT_TYPE_CODE: Attribute = {
  key: "permitTypeCode",
  label: "Permit Type Code",
  dataType: TEXT,
  group: NO_GROUP,
};

export const WELL_ORIENTATION: Attribute = {
  key: "wellOrientation",
  label: attributes.SLANT.label,
  dataType: TEXT,
  group: NO_GROUP,
};

export const PERMIT_SURFACE_LATITUDE: Attribute = {
  key: "surfaceLat",
  label: "Permit Surface Latitude",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const PERMIT_SURFACE_LONGITUDE: Attribute = {
  key: "surfaceLong",
  label: "Permit Surface Longitude",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const PERMIT_BOTTOM_HOLE_LAT: Attribute = {
  key: "bottomHoleLat",
  label: "Permit Bottom Latitude",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const PERMIT_BOTTOM_HOLE_LONGITUDE: Attribute = {
  key: "bottomHoleLong",
  label: "Permit Bottom Longitude",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const TEST_DATE: Attribute = {
  key: "testDate",
  label: "Test Date",
  dataType: DATE,
  group: NO_GROUP,
};

export const TEST_TYPE: Attribute = {
  key: "testType",
  label: "Test Type",
  dataType: TEXT,
  group: NO_GROUP,
};

export const OIL: Attribute = {
  key: "oil",
  label: "Oil",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const GAS: Attribute = {
  key: "gas",
  label: "Gas",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const WATER: Attribute = {
  key: "water",
  label: "Water",
  dataType: FLOAT,
  group: NO_GROUP,
};

export const TEST_DURATION: Attribute = {
  key: "testDuration",
  label: "Test Duration",
  dataType: FLOAT,
  group: NO_GROUP,
};
