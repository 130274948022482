const chartConfigurationStore = (set, get) => ({
  apiChartDataExcel: [],
  addApiChartDataExcel: (apiChartDataExcel) =>
    set((state) => ({
      apiChartDataExcel: [...get().apiChartDataExcel, apiChartDataExcel],
    })),

  updateApiChartDataExcelByID: (id, apiChartDataExcel) =>
    set((state) => ({
      apiChartDataExcel: get().apiChartDataExcel.map((ChartDataDatum) => {
        if (ChartDataDatum.chartId === id) {
          ChartDataDatum.data = apiChartDataExcel.data;
        }
        return ChartDataDatum;
      }),
    })),
  removeApiChartDataExcelByID: (id) =>
    set((state) => ({
      apiChartDataExcel: get().apiChartDataExcel.filter(
        (ChartDataDatum) => ChartDataDatum.chartId !== id
      ),
    })),

  apiChartDataForecastExcel: [],
  addApiChartDataForecastExcel: (apiChartDataForecastExcel) =>
    set((state) => ({
      apiChartDataForecastExcel: [
        ...get().apiChartDataForecastExcel,
        apiChartDataForecastExcel,
      ],
    })),
  updateApiChartDataForecastExcelByID: (id, apiChartDataForecastExcel) =>
    set((state) => ({
      apiChartDataForecastExcel: get().apiChartDataForecastExcel.map(
        (ChartDataDatum) => {
          if (ChartDataDatum.chartId === id) {
            ChartDataDatum.data = apiChartDataForecastExcel.data;
          }
          return ChartDataDatum;
        }
      ),
    })),
  removeApiChartDataForecastExcelByID: (id) =>
    set((state) => ({
      apiChartDataForecastExcel: get().apiChartDataForecastExcel.filter(
        (ChartDataDatum) => ChartDataDatum.chartId !== id
      ),
    })),
});

export default chartConfigurationStore;
