import FileSaver from "file-saver";
import { isArray } from "lodash";
import XLSX from "sheetjs-style";

import { RecordType } from "../../../types/common/appMode/appMode";
import {
  PermitData,
  PermitDataExport,
  PermitInfoItemKeys,
} from "../../../types/panels/permitPanel/permitPanel";
import { WellDataExport } from "../../../types/panels/wellPanel/exportWellPanel";
import { WellData } from "../../../types/panels/wellPanel/wellPanel";

import {
  PERMIT_HEADER_SECTION,
  PERMIT_INFO_HEADER_ATTRIBUTES,
  PERMIT_OPERATOR_CONTACT_INFO,
  PERMIT_OPERATOR_CONTACT_SECTION,
  PERMIT_RELATED_PERMITS_SECTION,
  PERMIT_RELATED_WELLS_INFO,
  PERMIT_RELATED_WELLS_SECTION,
} from "../../../constants/panels/permitPanel/permit";

import { formatAttributeByKey } from "../../../utils/formatters/attributeFormatter";

import usePanelFormattingRule from "../usePanelFormattingRule";

const useExportPermitAction = () => {
  const { formatLatLongAttributes } = usePanelFormattingRule();

  const handleExportAsExcel = (
    checkedKeys: PermitInfoItemKeys[],
    permitData: Omit<PermitData, "isDataLoaded">,
    recordType: RecordType
  ) => {
    const headerData: WellDataExport[] = [];
    const operatorContact: WellDataExport[] = [];
    const relatedPermits: WellDataExport[] = [];
    const relatedWells: WellDataExport[] = [];

    if (checkedKeys.includes(PERMIT_HEADER_SECTION.key)) {
      headerData.push(formatHeaderData(permitData, recordType));
    }

    if (checkedKeys.includes(PERMIT_OPERATOR_CONTACT_SECTION.key)) {
      operatorContact.push(formatOperatorContactData(permitData));
    }

    if (
      permitData.relatedWells &&
      isArray(permitData.relatedWells) &&
      permitData.relatedWells.length &&
      checkedKeys.includes(PERMIT_RELATED_WELLS_SECTION.key)
    ) {
      permitData.relatedWells.forEach((wellData) => {
        relatedWells.push(formatRelatedWellsData(wellData));
      });
    }

    if (
      permitData.relatedPermits &&
      isArray(permitData.relatedPermits) &&
      permitData.relatedPermits.length &&
      checkedKeys.includes(PERMIT_RELATED_PERMITS_SECTION.key)
    ) {
      permitData.relatedPermits.forEach((wellData) => {
        relatedPermits.push(formatRelatedPermitsData(wellData));
      });
    }

    exportToExcelFile(
      headerData,
      operatorContact,
      relatedWells,
      relatedPermits
    );
  };

  const exportToExcelFile = (
    headerData: WellDataExport[],
    operatorContactData: WellDataExport[],
    relatedWellsData: WellDataExport[],
    relatedPermitsData: WellDataExport[]
  ) => {
    const fileType = "";
    const fileExtension = ".xlsx";
    const dateTime = new Date();
    const yyyy = dateTime.getFullYear();
    let mm = dateTime.getMonth() + 1; // Months start at 0!
    let dd = dateTime.getDate();
    const hr = dateTime.getHours();
    const min = dateTime.getMinutes();

    if (dd < 10) dd = 0 + dd;
    if (mm < 10) mm = 0 + mm;

    const formattedToday = dd.toString() + mm.toString() + yyyy + hr + min;
    const fileName = "PermitCard" + "_" + formattedToday;

    const wb = XLSX.utils.book_new();

    if (headerData.length) {
      const wsStatistics = XLSX.utils.json_to_sheet(headerData);
      XLSX.utils.book_append_sheet(
        wb,
        wsStatistics,
        PERMIT_HEADER_SECTION.displayLabel
      );
    }

    if (operatorContactData.length) {
      const wsStatistics = XLSX.utils.json_to_sheet(operatorContactData);
      XLSX.utils.book_append_sheet(
        wb,
        wsStatistics,
        PERMIT_OPERATOR_CONTACT_SECTION.displayLabel
      );
    }

    if (relatedWellsData.length) {
      const wsStatistics = XLSX.utils.json_to_sheet(relatedWellsData);
      XLSX.utils.book_append_sheet(
        wb,
        wsStatistics,
        PERMIT_RELATED_WELLS_SECTION.displayLabel
      );
    }

    if (relatedPermitsData.length) {
      const wsStatistics = XLSX.utils.json_to_sheet(relatedPermitsData);
      XLSX.utils.book_append_sheet(
        wb,
        wsStatistics,
        PERMIT_RELATED_PERMITS_SECTION.displayLabel
      );
    }

    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const dataFile = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataFile, fileName + fileExtension);
  };

  const formatExportedVal = (
    attrkey: string,
    val?: number | string | boolean | null | Date
  ) => {
    return formatAttributeByKey(attrkey, val, false);
  };

  const formatHeaderData = (
    permitData: PermitDataExport,
    recordType: RecordType
  ) => {
    return {
      [PERMIT_INFO_HEADER_ATTRIBUTES.UWI.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.UWI.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.UWI.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.RECORD_TYPE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.RECORD_TYPE.key,
        recordType
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NAME.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NAME.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NAME.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NUMBER.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NUMBER.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NUMBER.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.WELL_STATUS.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.WELL_STATUS.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_STATUS.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_TYPE.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.label]:
        formatLatLongAttributes(
          permitData[
            PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.key
          ] as number
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.label]:
        formatLatLongAttributes(
          permitData[
            PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.key
          ] as number
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key] as string
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.label]:
        formatLatLongAttributes(
          permitData[
            PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.key
          ] as number
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.label]:
        formatLatLongAttributes(
          permitData[
            PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.key
          ] as number
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key,
        permitData[PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key] as number
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.label]:
        formatExportedVal(
          PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.key,
          permitData[
            PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_HAS_H2S_GAS.key
          ] as boolean
        ),
    };
  };

  const formatOperatorContactData = (permitData: PermitDataExport) => {
    return {
      [PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_NAME.label]: formatExportedVal(
        PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_NAME.key,
        permitData[PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_NAME.key] as string
      ),
      [PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_CONTACT.label]: formatExportedVal(
        PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_CONTACT.key,
        permitData[PERMIT_OPERATOR_CONTACT_INFO.OPERATOR_CONTACT.key] as string
      ),
      [PERMIT_OPERATOR_CONTACT_INFO.PHONE_NUMBER.label]: formatExportedVal(
        PERMIT_OPERATOR_CONTACT_INFO.PHONE_NUMBER.key,
        permitData[PERMIT_OPERATOR_CONTACT_INFO.PHONE_NUMBER.key] as string
      ),
      [PERMIT_OPERATOR_CONTACT_INFO.ADDRESS.label]: formatExportedVal(
        PERMIT_OPERATOR_CONTACT_INFO.ADDRESS.key,
        permitData[PERMIT_OPERATOR_CONTACT_INFO.ADDRESS.key] as string
      ),
      [PERMIT_OPERATOR_CONTACT_INFO.CITY.label]: formatExportedVal(
        PERMIT_OPERATOR_CONTACT_INFO.CITY.key,
        permitData[PERMIT_OPERATOR_CONTACT_INFO.CITY.key] as string
      ),
      [PERMIT_OPERATOR_CONTACT_INFO.STATE.label]: formatExportedVal(
        PERMIT_OPERATOR_CONTACT_INFO.STATE.key,
        permitData[PERMIT_OPERATOR_CONTACT_INFO.STATE.key] as string
      ),
      [PERMIT_OPERATOR_CONTACT_INFO.ZIP_CODE.label]: formatExportedVal(
        PERMIT_OPERATOR_CONTACT_INFO.ZIP_CODE.key,
        permitData[PERMIT_OPERATOR_CONTACT_INFO.ZIP_CODE.key] as string
      ),
    };
  };

  const formatRelatedWellsData = (relatedWells: WellData) => {
    return {
      [PERMIT_RELATED_WELLS_INFO.UWI.label]: formatExportedVal(
        PERMIT_RELATED_WELLS_INFO.UWI.key,
        relatedWells[PERMIT_RELATED_WELLS_INFO.UWI.key]
      ),
      [PERMIT_RELATED_WELLS_INFO.WELL_NAME.label]: formatExportedVal(
        PERMIT_RELATED_WELLS_INFO.WELL_NAME.key,
        relatedWells[PERMIT_RELATED_WELLS_INFO.WELL_NAME.key]
      ),
      [PERMIT_RELATED_WELLS_INFO.WELL_NUMBER.label]: formatExportedVal(
        PERMIT_RELATED_WELLS_INFO.WELL_NUMBER.key,
        relatedWells[PERMIT_RELATED_WELLS_INFO.WELL_NUMBER.key]
      ),
      [PERMIT_RELATED_WELLS_INFO.SPUD_DATE.label]: formatExportedVal(
        PERMIT_RELATED_WELLS_INFO.SPUD_DATE.key,
        relatedWells[PERMIT_RELATED_WELLS_INFO.SPUD_DATE.key]
      ),
      [PERMIT_RELATED_WELLS_INFO.NO_WELLS.label]: formatExportedVal(
        PERMIT_RELATED_WELLS_INFO.NO_WELLS.key,
        relatedWells[PERMIT_RELATED_WELLS_INFO.NO_WELLS.key]
      ),
    };
  };

  const formatRelatedPermitsData = (relatedPermits: WellData) => {
    return {
      [PERMIT_INFO_HEADER_ATTRIBUTES.UWI.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.UWI.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.UWI.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_NUMBER.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.APPROVED_DATE.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.POSTED_DATE.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.PROPOSED_DEPTH.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.FORMATION.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_TYPE.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.SLANT.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NAME.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NAME.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NAME.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NUMBER.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NUMBER.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.WELL_NUMBER.key]
      ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.label]:
        formatLatLongAttributes(
          relatedPermits[
            PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LATITUDE.key
          ] as number
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.label]:
        formatLatLongAttributes(
          relatedPermits[
            PERMIT_INFO_HEADER_ATTRIBUTES.SURFACE_LONGTITUDE.key
          ] as number
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.label]:
        formatLatLongAttributes(
          relatedPermits[
            PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LATITUDE.key
          ] as number
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.label]:
        formatExportedVal(
          PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.key,
          relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.BOTTOM_LONGTITUDE.key]
        ),
      [PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.label]: formatExportedVal(
        PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key,
        relatedPermits[PERMIT_INFO_HEADER_ATTRIBUTES.PERMIT_PURPOSE.key]
      ),
    };
  };

  return {
    handleExportAsExcel,
  };
};

export default useExportPermitAction;
