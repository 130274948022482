import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { trim } from "lodash";

import {
  DatalakeDocsDocuments,
  DatalakeDocsResponseBody,
  DocsViewLabel,
  DocsViewLabelObjectValue,
} from "../../../../types/panels/wellPanel/dataLakeType";
import {
  DocsWellPanelData,
  LogsProps,
} from "../../../../types/panels/wellPanel/logsDocsType";

import { DOCS_WELL_FILE_CATEGORIES } from "../../../../constants/panels/wellPanel/docs";
import {
  docsPanelColumns,
  docsSearchType,
} from "../../../../constants/panels/wellPanel/docs";
import { dataLakeSize } from "../../../../constants/panels/wellPanel/logs";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useDatalake from "../../../../customHooks/panels/wellPanel/useDatalake";

import PanelTable from "../common/PanelTable";

const DocsPanel: FC<LogsProps> = ({
  layer,
  wellSystem,
  activePage,
  groupedWellID,
  surfaceUWI,
}) => {
  const [docsData, setDocsData] = useState<DocsWellPanelData[]>([]);
  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );
  const { dataLakeResponse, getDataLakeData, successRequest, loadingRequest } =
    useDatalake<DatalakeDocsResponseBody>();

  const allDocsZeroValue = useMemo(() => {
    const categoriesLabel = {} as DocsViewLabelObjectValue;
    DOCS_WELL_FILE_CATEGORIES.forEach((cat) => {
      categoriesLabel[cat.label] = 0;
    });

    return categoriesLabel;
  }, [dataLakeResponse]);

  const assembleDocsRows = useCallback(
    (data: DatalakeDocsDocuments[]) => {
      for (const { Category, DocumentType } of data) {
        DOCS_WELL_FILE_CATEGORIES.forEach((category) => {
          category.docs.forEach((doc) => {
            if (doc.category === Category) {
              if (
                doc.documentType.some((type) => type === trim(DocumentType))
              ) {
                allDocsZeroValue[category.label] =
                  allDocsZeroValue[category.label] + 1;
              }
            }
          });
        });
      }

      return (Object.keys(allDocsZeroValue) as DocsViewLabel[])
        .filter((key: DocsViewLabel) => allDocsZeroValue[key] > 0)
        .map((key: DocsViewLabel) => ({
          docType: key,
          pageCount: allDocsZeroValue[key],
        }));
    },
    [dataLakeResponse]
  );

  useEffect(() => {
    if (successRequest && dataLakeResponse?.length) {
      const rowData = assembleDocsRows(dataLakeResponse);
      setDocsData(rowData);
      updateWellCardDataByTypeAndKey(
        { type: "docs", docs: rowData },
        wellSystem.wellId,
        layer,
        groupedWellID
      );
    } else {
      setDocsData([]);
    }
  }, [dataLakeResponse, successRequest]);

  useEffect(() => {
    if (wellSystem.pageNumber === activePage && surfaceUWI) {
      //check if data was already in store, else call api
      if (wellSystem.docsData?.length) {
        setDocsData(wellSystem.docsData);
      } else {
        const requestBody = {
          SearchType: docsSearchType,
          Query: `Uwi:${surfaceUWI}`,
          From: 0, // from count to start at (paging)
          Size: dataLakeSize,
        };
        getDataLakeData(requestBody);
      }
    }
  }, [wellSystem.pageNumber, wellSystem.logData, activePage, surfaceUWI]);

  return (
    <PanelTable
      TableData={docsData}
      TableHeader={docsPanelColumns}
      tabContentTableSize={"80%"}
      isLoading={loadingRequest}
      shouldFormatValues={false}
    />
  );
};

export default DocsPanel;
