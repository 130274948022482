export const CASING_REPORTS_CATEGORY = {
  label: "Casing Reports" as const,
  docs: [
    {
      category: "Regulatory" as const,
      documentType: [
        "Cementing Reports - Surface Casing",
        "Cementing Reports - Intermediate Casing",
      ] as const,
    },
    {
      category: "Drilling" as const,
      documentType: [
        "Casing Reports",
        "Cementing Report",
        "Cementing Reports",
        "Cement Records",
      ] as const,
    },
  ],
};

export const CHANGE_OF_OPERATOR_CATEGORY = {
  label: "Change of Operator" as const,
  docs: [
    {
      category: "Regulatory" as const,
      documentType: ["Change of Operator Reports"] as const,
    },
    {
      category: "Correspondence" as const,
      documentType: ["Change of Operator Documents"] as const,
    },
  ],
};

export const COMPLETION_REPORTS_CATEGORY = {
  label: "Completion Reports" as const,
  docs: [
    {
      category: "Completion" as const,
      documentType: [
        "Reports",
        "Completion Reports",
        "Perforation Reports",
        "Daily Completion Reports",
      ] as const,
    },
  ],
};

export const DIRECTIONAL_SURVEYS_CATEGORY = {
  label: "Directional Surveys" as const,
  docs: [
    {
      category: "Survey" as const,
      documentType: ["Directional survey"] as const,
    },
  ],
};

export const DRILLING_NARRATIVES_CATEGORY = {
  label: "Drilling Narratives" as const,
  docs: [
    {
      category: "Drilling" as const,
      documentType: [
        "Drilling Narratives",
        "Daily Drilling Reports/Tour Reports",
        "Drilling Diary",
      ] as const,
    },
  ],
};

export const DRILLIN_PLAN_SUMMARY_CATEGORY = {
  label: "Drilling Plan/Drilling Summary" as const,
  docs: [
    {
      category: "Drilling" as const,
      documentType: [
        "Schematics",
        "Planned/Proposed Drilling/Workover Procedures",
      ] as const,
    },
    {
      category: "Permitting" as const,
      documentType: [
        "Drilling Plan",
        "Surface Plan",
        "Well Pad Layout Diagrams",
        "Acces Road Diagrams",
        "Cut/Fill Diagrams",
      ] as const,
    },
    {
      category: "Engineering" as const,
      documentType: [
        "Casing Designs",
        "Well Procedues/Propsals/Recommendations",
        "Tubing/Pkr/Plug designs",
        "Wellbore Diagrams",
      ] as const,
    },
  ],
};

export const GEOLOGIC_CORE_CATEGORY = {
  label: "Geologic/Core Data" as const,
  docs: [
    {
      category: "Drilling" as const,
      documentType: [
        "Geological Data",
        "Formation Cutting/Sample Reports",
        "Lithology Reports",
        "Formation Top Reports",
        "DST (Drill Stem Tests)",
        "Core Analysis",
        "Geology Reports",
      ] as const,
    },
  ],
};

export const INCLINATION_REPORTS_CATEGORY = {
  label: "Inclination Reports" as const,
  docs: [
    {
      category: "Drilling" as const,
      documentType: ["Inclination Reports", "Dipmeters"] as const,
    },
  ],
};
export const PERMITS_CATEGORY = {
  label: "Permits" as const,
  docs: [
    {
      category: "Permitting" as const,
      documentType: [
        "Permits",
        "Application for Permit to Drill/Deepen/Re-enter (APD)",
        "Verbal Request",
      ] as const,
    },
  ],
};

export const PLATS_MAPS_CATEGORY = {
  label: "Plats/Maps" as const,
  docs: [
    {
      category: "Permitting" as const,
      documentType: [
        "Maps",
        "Plats",
        "Survey Plats",
        "Topo Maps",
        "Radius Maps",
      ] as const,
    },
  ],
};

export const PLUGGING_REPORTS_CATEGORY = {
  label: "Plugging Reports" as const,
  docs: [
    {
      category: "Completion" as const,
      documentType: ["Plugging Reports (P&A Reports)"] as const,
    },
  ],
};

export const SCOUT_CARDS_CATEGORY = {
  label: "Scout Cards" as const,
  docs: [
    {
      category: "Completion" as const,
      documentType: ["Scout Tickets", "Well Records"] as const,
    },
  ],
};

export const SUNDRY_REPORTS_CATEGORY = {
  label: "Sundry Reports" as const,
  docs: [
    {
      category: "Routine Maintenance R&M" as const,
      documentType: [
        "Packer running/pulling reports",
        "Work that doesn't require prior written agency approval:",
        "Tubing running/pulling reports",
        "Pump running/pulling reports",
        "Pump changes/conversions",
        "Well cleanouts (R/T w/bit & scraper, C/O fill)",
      ] as const,
    },
    {
      category: "Regulatory" as const,
      documentType: [
        "Any type of form requesting a type of well/location work/modification/change that must be applied for and approved",
        "Any work performed on well following initial completion; ie (workover, recompletions, conversions, stimulations)",
        "Federal/State Completion Forms (Applied for & Approved)",
        "Federal/State Sundry Forms (Applied for & Approved)",
        "Well Status Reports (ie regulatory…T&A, P&A)",
        "Engineering procedures that accompany the sundries or applications ",
      ] as const,
    },
  ],
};
export const TREATMENT_REMARKS_CATEGORY = {
  label: "Sundry Reports" as const,
  docs: [
    {
      category: "Completion" as const,
      documentType: ["Stimulation/Frac Fluid Information"] as const,
    },
  ],
};
