import {
  regexNumber,
  regexParenthesis,
  regexPositiveDecimalNumber,
} from "../../../utils/regexp";

import { regexNumberNoDecimal, regexParenthesisNoDecimal } from "../../regexp";

export const FIELD_FORMAT_DEFAULT = "default-format";
export const FIELD_FORMAT_NO_DECIMAL = "no-decimal-format";

export const WELL_ECONOMICS_NGL_YIELD = {
  key: "NglYield",
  label: "NGL Yield",
  unit: "bbl/mmcf",
  isCurrency: false,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_OIL_PRICE = {
  key: "BenchmarkOilPrice",
  label: "Oil Price",
  unit: "$/bbl",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_GAS_PRICE = {
  key: "BenchmarkGasPrice",
  label: "Gas Price",
  unit: "$/mcf",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_NGL_PRICE = {
  key: "BenchmarkNglPrice",
  label: "NGL Price",
  unit: "% of oil price",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_OIL_DIFFERENCIAL = {
  key: "OilDifferential",
  label: "Oil Differential",
  unit: "$/bbl",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_GAS_DIFFERENTIAL = {
  key: "GasDifferential",
  label: "Gas Differential",
  unit: "$/mcf",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_NGL_DIFFERENTIAL = {
  key: "NglDifferential",
  label: "NGL Differential",
  unit: "$/bbl",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_LOE = {
  key: "LoeCost",
  label: "LOE",
  unit: "$/boe",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_GPT = {
  key: "GptCost",
  label: "GP&T",
  unit: "$/boe",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_WATER = {
  key: "WaterCost",
  label: "Water",
  unit: "$/bbl",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_FIXED_COST = {
  key: "FixedCost",
  label: "Fixed Cost",
  unit: "$/month",
  isCurrency: true,
  regex: [regexParenthesisNoDecimal, regexNumberNoDecimal],
} as const;

export const WELL_ECONOMICS_TAX_PER_UNIT = {
  key: "TaxPerUnit",
  label: "Tax Per Eq. Barrel",
  unit: "$/boe",
  isCurrency: true,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_TAX_GAS = {
  key: "GasTaxRate",
  label: "Tax Gas",
  unit: "%",
  isCurrency: false,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_TAX_LIQUIDS = {
  key: "LiquidTaxRate",
  label: "Tax Liquids",
  unit: "%",
  isCurrency: false,
  regex: [regexParenthesis(), regexNumber()],
} as const;

export const WELL_ECONOMICS_CAPEX = {
  key: "DrillingCompletionCapEx",
  label: "Capex",
  unit: "$mm/well",
  isCurrency: true,
  regex: [regexPositiveDecimalNumber("1,3")],
} as const;

export const WELL_ECONOMICS_INFRASTRACTURE = {
  key: "InfraCapEx",
  label: "Infrastructure",
  unit: "$mm/well",
  isCurrency: true,
  regex: [regexPositiveDecimalNumber("1,3")],
} as const;

export const WELL_ECONOMICS_WORKING_INTEREST = {
  key: "WorkingInterest",
  label: "Working Interest",
  unit: "%",
  isCurrency: false,
  regex: [regexParenthesisNoDecimal, regexNumberNoDecimal],
} as const;

export const WELL_ECONOMICS_ROYALTY = {
  key: "Royalty",
  label: "Royalty",
  unit: "%",
  isCurrency: false,
  regex: [regexParenthesisNoDecimal, regexNumberNoDecimal],
} as const;

export const WELL_ECONOMICS_DISCOUNT_RATE = {
  key: "DiscountRate",
  label: "Discount Rate",
  unit: "%",
  isCurrency: false,
  regex: [regexParenthesisNoDecimal, regexNumberNoDecimal],
} as const;

export const WELL_ECONOMICS_INTERNAL_RATE_RETURN = {
  key: "InternalRateOfReturn",
  label: "IRR",
  unit: "%",
  isCurrency: false,
} as const;

export const WELL_ECONOMICS_PAYOUT = {
  key: "Payout",
  label: "Payback",
  unit: "yr",
  isCurrency: false,
} as const;

export const WELL_ECONOMICS_NET_PRESENT_VALUE = {
  key: "NetPresentValue",
  label: "NPV",
  unit: "$",
  isCurrency: true,
} as const;

export const WELL_ECONOMICS_BREAKEVEN_PRICE = {
  key: "BreakEvenPrice",
  label: "Breakeven Price",
  unit: "$/boe",
  isCurrency: true,
} as const;

export const WELL_ECONOMICS_INPUT_FIELDS = [
  WELL_ECONOMICS_OIL_PRICE,
  WELL_ECONOMICS_OIL_DIFFERENCIAL,
  WELL_ECONOMICS_GAS_PRICE,
  WELL_ECONOMICS_GAS_DIFFERENTIAL,
  WELL_ECONOMICS_NGL_PRICE,
  WELL_ECONOMICS_NGL_DIFFERENTIAL,
  WELL_ECONOMICS_NGL_YIELD,
  WELL_ECONOMICS_LOE,
  WELL_ECONOMICS_GPT,
  WELL_ECONOMICS_WATER,
  WELL_ECONOMICS_FIXED_COST,
  WELL_ECONOMICS_TAX_PER_UNIT,
  WELL_ECONOMICS_TAX_GAS,
  WELL_ECONOMICS_TAX_LIQUIDS,
  WELL_ECONOMICS_CAPEX,
  WELL_ECONOMICS_INFRASTRACTURE,
  WELL_ECONOMICS_WORKING_INTEREST,
  WELL_ECONOMICS_ROYALTY,
  WELL_ECONOMICS_DISCOUNT_RATE,
];

export const WELL_ECONOMICS_INPUT_ARRAY_FIELDS = [
  WELL_ECONOMICS_NGL_YIELD.key,
  WELL_ECONOMICS_OIL_PRICE.key,
  WELL_ECONOMICS_GAS_PRICE.key,
  WELL_ECONOMICS_NGL_PRICE.key,
  WELL_ECONOMICS_OIL_DIFFERENCIAL.key,
  WELL_ECONOMICS_GAS_DIFFERENTIAL.key,
  WELL_ECONOMICS_NGL_DIFFERENTIAL.key,
  WELL_ECONOMICS_LOE.key,
  WELL_ECONOMICS_GPT.key,
  WELL_ECONOMICS_WATER.key,
  WELL_ECONOMICS_FIXED_COST.key,
  WELL_ECONOMICS_TAX_PER_UNIT.key,
  WELL_ECONOMICS_TAX_GAS.key,
  WELL_ECONOMICS_TAX_LIQUIDS.key,
  WELL_ECONOMICS_CAPEX.key,
  WELL_ECONOMICS_INFRASTRACTURE.key,
  WELL_ECONOMICS_WORKING_INTEREST.key,
  WELL_ECONOMICS_ROYALTY.key,
  WELL_ECONOMICS_DISCOUNT_RATE.key,
] as const;

export const WELL_ECONOMICS_OUTPUT_ARRAY_FIELDS = [
  WELL_ECONOMICS_INTERNAL_RATE_RETURN.key,
  WELL_ECONOMICS_PAYOUT.key,
  WELL_ECONOMICS_NET_PRESENT_VALUE.key,
  WELL_ECONOMICS_BREAKEVEN_PRICE.key,
] as const;

export const WELL_ECONOMICS_DEFAULT_VALUES = {
  [WELL_ECONOMICS_NGL_YIELD.key]: null,
  [WELL_ECONOMICS_OIL_PRICE.key]: null,
  [WELL_ECONOMICS_GAS_PRICE.key]: null,
  [WELL_ECONOMICS_NGL_PRICE.key]: null,
  [WELL_ECONOMICS_OIL_DIFFERENCIAL.key]: null,
  [WELL_ECONOMICS_GAS_DIFFERENTIAL.key]: null,
  [WELL_ECONOMICS_NGL_DIFFERENTIAL.key]: null,
  [WELL_ECONOMICS_LOE.key]: null,
  [WELL_ECONOMICS_GPT.key]: null,
  [WELL_ECONOMICS_WATER.key]: null,
  [WELL_ECONOMICS_FIXED_COST.key]: null,
  [WELL_ECONOMICS_TAX_PER_UNIT.key]: null,
  [WELL_ECONOMICS_TAX_GAS.key]: null,
  [WELL_ECONOMICS_TAX_LIQUIDS.key]: null,
  [WELL_ECONOMICS_CAPEX.key]: null,
  [WELL_ECONOMICS_INFRASTRACTURE.key]: null,
  [WELL_ECONOMICS_WORKING_INTEREST.key]: null,
  [WELL_ECONOMICS_ROYALTY.key]: null,
  [WELL_ECONOMICS_DISCOUNT_RATE.key]: null,
};

export const WELL_ECONOMICS_FIELD_ERROR_VALUES = {
  [WELL_ECONOMICS_NGL_YIELD.key]: false,
  [WELL_ECONOMICS_OIL_PRICE.key]: false,
  [WELL_ECONOMICS_GAS_PRICE.key]: false,
  [WELL_ECONOMICS_NGL_PRICE.key]: false,
  [WELL_ECONOMICS_OIL_DIFFERENCIAL.key]: false,
  [WELL_ECONOMICS_GAS_DIFFERENTIAL.key]: false,
  [WELL_ECONOMICS_NGL_DIFFERENTIAL.key]: false,
  [WELL_ECONOMICS_LOE.key]: false,
  [WELL_ECONOMICS_GPT.key]: false,
  [WELL_ECONOMICS_WATER.key]: false,
  [WELL_ECONOMICS_FIXED_COST.key]: false,
  [WELL_ECONOMICS_TAX_PER_UNIT.key]: false,
  [WELL_ECONOMICS_TAX_GAS.key]: false,
  [WELL_ECONOMICS_TAX_LIQUIDS.key]: false,
  [WELL_ECONOMICS_CAPEX.key]: false,
  [WELL_ECONOMICS_INFRASTRACTURE.key]: false,
  [WELL_ECONOMICS_WORKING_INTEREST.key]: false,
  [WELL_ECONOMICS_ROYALTY.key]: false,
  [WELL_ECONOMICS_DISCOUNT_RATE.key]: false,
};

export const WELL_ECONOMICS_INPUTS_CONSTANTS = {
  [WELL_ECONOMICS_NGL_YIELD.key]: WELL_ECONOMICS_NGL_YIELD,
  [WELL_ECONOMICS_OIL_PRICE.key]: WELL_ECONOMICS_OIL_PRICE,
  [WELL_ECONOMICS_GAS_PRICE.key]: WELL_ECONOMICS_GAS_PRICE,
  [WELL_ECONOMICS_NGL_PRICE.key]: WELL_ECONOMICS_NGL_PRICE,
  [WELL_ECONOMICS_OIL_DIFFERENCIAL.key]: WELL_ECONOMICS_OIL_DIFFERENCIAL,
  [WELL_ECONOMICS_GAS_DIFFERENTIAL.key]: WELL_ECONOMICS_GAS_DIFFERENTIAL,
  [WELL_ECONOMICS_NGL_DIFFERENTIAL.key]: WELL_ECONOMICS_NGL_DIFFERENTIAL,
  [WELL_ECONOMICS_LOE.key]: WELL_ECONOMICS_LOE,
  [WELL_ECONOMICS_GPT.key]: WELL_ECONOMICS_GPT,
  [WELL_ECONOMICS_WATER.key]: WELL_ECONOMICS_WATER,
  [WELL_ECONOMICS_FIXED_COST.key]: WELL_ECONOMICS_FIXED_COST,
  [WELL_ECONOMICS_TAX_PER_UNIT.key]: WELL_ECONOMICS_TAX_PER_UNIT,
  [WELL_ECONOMICS_TAX_GAS.key]: WELL_ECONOMICS_TAX_GAS,
  [WELL_ECONOMICS_TAX_LIQUIDS.key]: WELL_ECONOMICS_TAX_LIQUIDS,
  [WELL_ECONOMICS_CAPEX.key]: WELL_ECONOMICS_CAPEX,
  [WELL_ECONOMICS_INFRASTRACTURE.key]: WELL_ECONOMICS_INFRASTRACTURE,
  [WELL_ECONOMICS_WORKING_INTEREST.key]: WELL_ECONOMICS_WORKING_INTEREST,
  [WELL_ECONOMICS_ROYALTY.key]: WELL_ECONOMICS_ROYALTY,
  [WELL_ECONOMICS_DISCOUNT_RATE.key]: WELL_ECONOMICS_DISCOUNT_RATE,
};
