import { colorBins, colorCategories } from "@deck.gl/carto";

import tinygradient from "tinygradient";

import { Feature } from "../../../types/map/layers/carto";
import {
  GetColorByAttribute,
  GetColorNumericDate,
  GetColorVarchar,
} from "../../../types/map/mapSettings/layerColoring";
import { WellStylingValue } from "../../../types/map/mapSettings/useWellSpotStyling";

import {
  DATE,
  FLOAT,
  INTEGER,
  SPECIAL,
  TEXT,
} from "../../../constants/attributes";
import {
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_PERMIT_SPOTS,
  DYNAMIC_WELL_SPOTS,
  PARETO_DISTRIBUTOR_COMPENSATOR,
  WELL_PATHS,
  WELL_STICKS,
} from "../../../constants/constants";
import {
  DEFAULT_ATTRIBUTE_COLOR,
  DEFAULT_GRADIENT_COLORS,
  UNIFORM_COLOR,
} from "../../../constants/map/mapSettings";

// EQUAL BINNING IMPLEMENTATION
export const getEqualBins = (max: number, min: number) => {
  const divider = 19;
  const multiplier = (max - min) / divider;
  const domain = [];
  for (let i = 0; i <= divider; i++) {
    domain.push(min + i * multiplier);
  }
  return domain;
};

// FOR REFERENCE:
// REVERSE EXPONENTIAL BINNING
// More bins are generated as it approaches MAX value
// const getReversePareto = (max, min) => {
//   let percentages = [100];
//   let currPercent = 100;
//   for (let i = 1; i <= 19; i++) {
//     const nextPercent = currPercent / PARETO_DISTRIBUTOR_COMPENSATOR;
//     percentages.push(nextPercent);
//     currPercent = nextPercent;
//   }

//   let domain = [];
//   for (let i = 0; i <= 19; i++) {
//     const nextVal = (max - min) * (percentages[i] / 100);
//     domain.push(max - nextVal);
//   }
//   return domain;
// };

// EXPONENTIAL BINNING
// More bins are generated as it approaches MIN value
export const getPareto = (max: number, min: number) => {
  const percentages = [100];
  let currPercent = 100;
  for (let i = 1; i <= 19; i++) {
    const nextPercent = currPercent / PARETO_DISTRIBUTOR_COMPENSATOR;
    percentages.push(nextPercent);
    currPercent = nextPercent;
  }

  const domain: number[] = [];
  for (let i = 0; i <= 19; i++) {
    const nextVal = (max - min) * (percentages[i] / 100);
    domain.push(nextVal + min);
  }

  return domain.reverse();
};

export const getGradientColors = (gradientEndColors: number[][]) => {
  // Generate gradient from low and high colors
  const [low, high] = gradientEndColors;
  const [rLow, gLow, bLow] = low;
  const [rHigh, gHigh, bHigh] = high;

  const gradientEnds = tinygradient([
    { r: rLow, g: gLow, b: bLow },
    { r: 255, g: 255, b: 255 },
    { r: rHigh, g: gHigh, b: bHigh },
  ]);

  const gradients = gradientEnds
    .rgb(20)
    .map((color) => Object.values(color.toRgb()));

  return gradients;
};

const getFeatureData = (
  feature: Feature,
  wellSpotAttribValues: {
    [key: string]: WellStylingValue;
  },
  layerName: string
) => {
  const { ParentWellID, BottomWellboreID, PermitId } = feature.properties;

  let id = 0;
  if (layerName === DYNAMIC_WELL_SPOTS && ParentWellID) {
    id = ParentWellID;
  } else if (
    (layerName === DYNAMIC_BOTTOM_WELL_SPOTS ||
      layerName === WELL_PATHS ||
      layerName === WELL_STICKS) &&
    BottomWellboreID
  ) {
    id = BottomWellboreID;
  } else if (layerName === DYNAMIC_PERMIT_SPOTS && PermitId) {
    id = PermitId;
  }
  if (!wellSpotAttribValues[id] && wellSpotAttribValues[id] !== 0) return null;

  return wellSpotAttribValues[id];
};

export const getColorVarchar = ({
  featureData,
  wellColorList,
  defaultColor,
}: GetColorVarchar) => {
  const domain = wellColorList.map((colors) => colors.label).slice(0, 10);
  const colors = wellColorList.map((colors) => colors.value).slice(0, 10);

  const colorRamp = colorCategories({
    attr: "dataValue",
    domain,
    colors,
    othersColor: defaultColor,
    nullColor: defaultColor,
  });

  const dataValWithDefault = featureData ?? null;

  const newColor = colorRamp({
    properties: {
      dataValue: dataValWithDefault,
    },
  });

  return newColor;
};

export const getColorNumericDate = ({
  featureData,
  min,
  max,
  defaultColor,
  isNumeric,
}: GetColorNumericDate) => {
  if (min === null || max === null) return defaultColor;
  const colors = getGradientColors(DEFAULT_GRADIENT_COLORS).map((rgba) =>
    rgba.slice(0, 3)
  );
  // Domain should be from lowest to highest
  const domain = isNumeric ? getPareto(max, min) : getEqualBins(max, min);
  const colorRamp = colorBins({
    attr: "dataValue",
    domain,
    colors,
  });

  if (featureData || featureData === 0) {
    const dataValue = featureData;

    return colorRamp({
      properties: {
        dataValue,
      },
    });
  }

  return defaultColor;
};

export const colorBinValues = (
  domain: (string | undefined)[],
  colors: number[][],
  value: string | number
) => {
  const colorRamp = colorBins({
    attr: "dataValue",
    domain,
    colors,
  });

  const dataValue = value;
  return colorRamp({
    properties: {
      dataValue,
    },
  });
};

export const getColorByAttribute = ({
  colorByAttribute,
  compressedWellSpotData,
  compressedWellSpotInfo,
  feature,
  layerName,
  wellColorList,
  defaultColor,
}: GetColorByAttribute) => {
  const { key, dataType } = colorByAttribute;
  if (key === UNIFORM_COLOR.key || compressedWellSpotData.length === 0) {
    return defaultColor;
  }

  const { data, min, max } = compressedWellSpotInfo;
  const featureData = getFeatureData(feature, data, layerName);

  switch (dataType) {
    case FLOAT:
    case INTEGER:
      return getColorNumericDate({
        featureData,
        min,
        max,
        isNumeric: true,
        defaultColor: DEFAULT_ATTRIBUTE_COLOR,
      });
    case DATE:
      return getColorNumericDate({
        featureData,
        min,
        max,
        isNumeric: false,
        defaultColor: DEFAULT_ATTRIBUTE_COLOR,
      });
    case SPECIAL:
    case TEXT:
      return getColorVarchar({
        featureData,
        wellColorList,
        defaultColor: DEFAULT_ATTRIBUTE_COLOR,
      });
    default:
      return defaultColor;
  }
};
