import {
  BASIN_NAME,
  COUNTY,
  INTERPRETED_PRODUCING_FORMATION,
  LEASE_NAME,
  LEASE_NAME_AND_ID,
  SLANT,
  ULTIMATE_OWNER,
  WELL_STATUS,
  WELL_TYPE,
} from "../attributes";
import {
  AVG_INJ_PRESSURE,
  BOE_CUMULATIVE,
  BOE_MONTHLY,
  DAYS_ON_INJECTION,
  DAYS_ON_PRODUCTION,
  FLARE,
  GAS_CUMULATIVE,
  GAS_MONTHLY,
  GAS_OIL_RATIO,
  INFERRED,
  MAX_INJ_PRESSURE,
  OIL_CUMULATIVE,
  OIL_MONTHLY,
  OTHER,
  TOTAL_DISPOSED,
  TOTAL_INJECTED_GAS_CUMULATIVE,
  TOTAL_INJECTED_GAS_MONTHLY,
  TOTAL_INJECTED_LIQUID_CUMULATIVE,
  TOTAL_INJECTED_LIQUID_MONTHLY,
  TOTAL_USED,
  USED,
  VENT,
  VENT_FLARE,
  WATER_CUMULATIVE,
  WATER_CUT,
  WATER_MONTHLY,
  WATER_OIL_RATIO,
} from "./productionPlotDataFields";

//NOTE: CONSTANTS HERE ARE USED BY CHART EXPORT TO EXCEL
export const PRODUCTION_DATA_FIELDS = [
  {
    name: "Name",
    displayName: "Name",
    dailyDisplayName: "",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "name",
    responseDataOrigin: "name",
  },

  // for individual wells only
  {
    name: "uwi",
    displayName: "UWI",
    hasForecast: false,
    responseDataFieldName: "uwi",
    responseDataOrigin: "uwi",
  },
  {
    name: "wellName",
    displayName: "Well Name",
    hasForecast: false,
    responseDataFieldName: "wellName",
    responseDataOrigin: "wellName",
  },
  {
    name: "wellNumber",
    displayName: "Well Number",
    hasForecast: false,
    responseDataFieldName: "wellNumber",
    responseDataOrigin: "wellNumber",
    alwaysOn: true,
  },
  {
    name: "Date",
    displayName: "Date",
    hasForecast: false,
    hasDaily: false,
    responseDataFieldName: "date",
    responseDataOrigin: "date",
  },
  {
    name: "Sequence Month",
    displayName: "Sequence Month",
    hasForecast: false,
    responseDataFieldName: "month",
    responseDataOrigin: "month",
  },
  // end for individual wells only

  {
    name: OIL_MONTHLY.name,
    displayName: OIL_MONTHLY.displayName,
    dailyDisplayName: OIL_MONTHLY.dailyDisplayName,
    hasForecast: OIL_MONTHLY.hasForecast,
    hasDaily: OIL_MONTHLY.hasDaily,
    responseDataFieldName: OIL_MONTHLY.responseData.name,
    responseDataOrigin: OIL_MONTHLY.responseData.origin,
  },
  {
    name: OIL_CUMULATIVE.name,
    displayName: OIL_CUMULATIVE.displayName,
    dailyDisplayName: OIL_CUMULATIVE.dailyDisplayName,
    hasForecast: OIL_CUMULATIVE.hasForecast,
    hasDaily: OIL_CUMULATIVE.hasDaily,
    responseDataFieldName: OIL_CUMULATIVE.responseData.name,
    responseDataOrigin: OIL_CUMULATIVE.responseData.origin,
  },
  {
    name: GAS_MONTHLY.name,
    displayName: GAS_MONTHLY.displayName,
    dailyDisplayName: GAS_MONTHLY.dailyDisplayName,
    hasForecast: GAS_MONTHLY.hasForecast,
    hasDaily: GAS_MONTHLY.hasDaily,
    responseDataFieldName: GAS_MONTHLY.responseData.name,
    responseDataOrigin: GAS_MONTHLY.responseData.origin,
  },
  {
    name: GAS_CUMULATIVE.name,
    displayName: GAS_CUMULATIVE.displayName,
    dailyDisplayName: GAS_CUMULATIVE.dailyDisplayName,
    hasForecast: GAS_CUMULATIVE.hasForecast,
    hasDaily: GAS_CUMULATIVE.hasDaily,
    responseDataFieldName: GAS_CUMULATIVE.responseData.name,
    responseDataOrigin: GAS_CUMULATIVE.responseData.origin,
  },
  {
    name: BOE_MONTHLY.name,
    displayName: BOE_MONTHLY.displayName,
    dailyDisplayName: BOE_MONTHLY.dailyDisplayName,
    hasForecast: BOE_MONTHLY.hasForecast,
    hasDaily: BOE_MONTHLY.hasDaily,
    responseDataFieldName: BOE_MONTHLY.responseData.name,
    responseDataOrigin: BOE_MONTHLY.responseData.origin,
  },
  {
    name: BOE_CUMULATIVE.name,
    displayName: BOE_CUMULATIVE.displayName,
    dailyDisplayName: BOE_CUMULATIVE.dailyDisplayName,
    hasForecast: BOE_CUMULATIVE.hasForecast,
    hasDaily: BOE_CUMULATIVE.hasDaily,
    responseDataFieldName: BOE_CUMULATIVE.responseData.name,
    responseDataOrigin: BOE_CUMULATIVE.responseData.origin,
  },
  {
    name: WATER_MONTHLY.name,
    displayName: WATER_MONTHLY.displayName,
    dailyDisplayName: WATER_MONTHLY.dailyDisplayName,
    hasForecast: WATER_MONTHLY.hasForecast,
    hasDaily: WATER_MONTHLY.hasDaily,
    responseDataFieldName: WATER_MONTHLY.responseData.name,
    responseDataOrigin: WATER_MONTHLY.responseData.origin,
  },
  {
    name: WATER_CUMULATIVE.name,
    displayName: WATER_CUMULATIVE.displayName,
    dailyDisplayName: WATER_CUMULATIVE.dailyDisplayName,
    hasForecast: WATER_CUMULATIVE.hasForecast,
    hasDaily: WATER_CUMULATIVE.hasDaily,
    responseDataFieldName: WATER_CUMULATIVE.responseData.name,
    responseDataOrigin: WATER_CUMULATIVE.responseData.origin,
  },
  {
    name: DAYS_ON_PRODUCTION.name,
    displayName: DAYS_ON_PRODUCTION.displayName,
    dailyDisplayName: DAYS_ON_PRODUCTION.dailyDisplayName,
    hasForecast: DAYS_ON_PRODUCTION.hasForecast,
    hasDaily: DAYS_ON_PRODUCTION.hasDaily,
    responseDataFieldName: DAYS_ON_PRODUCTION.responseData.name,
    responseDataOrigin: DAYS_ON_PRODUCTION.responseData.origin,
  },
  {
    name: WATER_OIL_RATIO.name,
    displayName: WATER_OIL_RATIO.displayName,
    dailyDisplayName: WATER_OIL_RATIO.dailyDisplayName,
    hasForecast: WATER_OIL_RATIO.hasForecast,
    hasDaily: WATER_OIL_RATIO.hasDaily,
    responseDataFieldName: WATER_OIL_RATIO.responseData.name,
    responseDataOrigin: WATER_OIL_RATIO.responseData.origin,
  },
  {
    name: WATER_CUT.name,
    displayName: WATER_CUT.displayName,
    dailyDisplayName: WATER_CUT.dailyDisplayName,
    hasForecast: WATER_CUT.hasForecast,
    hasDaily: WATER_CUT.hasDaily,
    responseDataFieldName: WATER_CUT.responseData.name,
    responseDataOrigin: WATER_CUT.responseData.origin,
  },
  {
    name: GAS_OIL_RATIO.name,
    displayName: GAS_OIL_RATIO.displayName,
    dailyDisplayName: GAS_OIL_RATIO.dailyDisplayName,
    hasForecast: GAS_OIL_RATIO.hasForecast,
    hasDaily: GAS_OIL_RATIO.hasDaily,
    responseDataFieldName: GAS_OIL_RATIO.responseData.name,
    responseDataOrigin: GAS_OIL_RATIO.responseData.origin,
  },
  {
    name: TOTAL_INJECTED_LIQUID_MONTHLY.name,
    displayName: TOTAL_INJECTED_LIQUID_MONTHLY.displayName,
    dailyDisplayName: TOTAL_INJECTED_LIQUID_MONTHLY.dailyDisplayName,
    hasForecast: TOTAL_INJECTED_LIQUID_MONTHLY.hasForecast,
    hasDaily: TOTAL_INJECTED_LIQUID_MONTHLY.hasDaily,
    responseDataFieldName: TOTAL_INJECTED_LIQUID_MONTHLY.responseData.name,
    responseDataOrigin: TOTAL_INJECTED_LIQUID_MONTHLY.responseData.origin,
  },
  {
    name: TOTAL_INJECTED_LIQUID_CUMULATIVE.name,
    displayName: TOTAL_INJECTED_LIQUID_CUMULATIVE.displayName,
    dailyDisplayName: TOTAL_INJECTED_LIQUID_CUMULATIVE.dailyDisplayName,
    hasForecast: TOTAL_INJECTED_LIQUID_CUMULATIVE.hasForecast,
    hasDaily: TOTAL_INJECTED_LIQUID_CUMULATIVE.hasDaily,
    responseDataFieldName: TOTAL_INJECTED_LIQUID_CUMULATIVE.responseData.name,
    responseDataOrigin: TOTAL_INJECTED_LIQUID_CUMULATIVE.responseData.origin,
  },
  {
    name: TOTAL_INJECTED_GAS_MONTHLY.name,
    displayName: TOTAL_INJECTED_GAS_MONTHLY.displayName,
    dailyDisplayName: TOTAL_INJECTED_GAS_MONTHLY.dailyDisplayName,
    hasForecast: TOTAL_INJECTED_GAS_MONTHLY.hasForecast,
    hasDaily: TOTAL_INJECTED_GAS_MONTHLY.hasDaily,
    responseDataFieldName: TOTAL_INJECTED_GAS_MONTHLY.responseData.name,
    responseDataOrigin: TOTAL_INJECTED_GAS_MONTHLY.responseData.origin,
  },
  {
    name: TOTAL_INJECTED_GAS_CUMULATIVE.name,
    displayName: TOTAL_INJECTED_GAS_CUMULATIVE.displayName,
    dailyDisplayName: TOTAL_INJECTED_GAS_CUMULATIVE.dailyDisplayName,
    hasForecast: TOTAL_INJECTED_GAS_CUMULATIVE.hasForecast,
    hasDaily: TOTAL_INJECTED_GAS_CUMULATIVE.hasDaily,
    responseDataFieldName: TOTAL_INJECTED_GAS_CUMULATIVE.responseData.name,
    responseDataOrigin: TOTAL_INJECTED_GAS_CUMULATIVE.responseData.origin,
  },
  {
    name: MAX_INJ_PRESSURE.name,
    displayName: MAX_INJ_PRESSURE.displayName,
    dailyDisplayName: MAX_INJ_PRESSURE.dailyDisplayName,
    hasForecast: MAX_INJ_PRESSURE.hasForecast,
    hasDaily: MAX_INJ_PRESSURE.hasDaily,
    responseDataFieldName: MAX_INJ_PRESSURE.responseData.name,
  },
  {
    name: AVG_INJ_PRESSURE.name,
    displayName: AVG_INJ_PRESSURE.displayName,
    dailyDisplayName: AVG_INJ_PRESSURE.dailyDisplayName,
    hasForecast: AVG_INJ_PRESSURE.hasForecast,
    hasDaily: AVG_INJ_PRESSURE.hasDaily,
    responseDataFieldName: AVG_INJ_PRESSURE.responseData.name,
  },
  {
    name: DAYS_ON_INJECTION.name,
    displayName: DAYS_ON_INJECTION.displayName,
    dailyDisplayName: DAYS_ON_INJECTION.dailyDisplayName,
    hasForecast: DAYS_ON_INJECTION.hasForecast,
    hasDaily: DAYS_ON_INJECTION.hasDaily,
    responseDataFieldName: DAYS_ON_INJECTION.responseData.name,
  },
  {
    name: TOTAL_DISPOSED.name,
    displayName: TOTAL_DISPOSED.displayName,
    dailyDisplayName: TOTAL_DISPOSED.dailyDisplayName,
    hasForecast: TOTAL_DISPOSED.hasForecast,
    hasDaily: TOTAL_DISPOSED.hasDaily,
    responseDataFieldName: TOTAL_DISPOSED.responseData.name,
  },
  {
    name: VENT.name,
    displayName: VENT.displayName,
    dailyDisplayName: VENT.dailyDisplayName,
    hasForecast: VENT.hasForecast,
    hasDaily: VENT.hasDaily,
    responseDataFieldName: VENT.responseData.name,
  },
  {
    name: FLARE.name,
    displayName: FLARE.displayName,
    dailyDisplayName: FLARE.dailyDisplayName,
    hasForecast: FLARE.hasForecast,
    hasDaily: FLARE.hasDaily,
    responseDataFieldName: FLARE.responseData.name,
  },
  {
    name: VENT_FLARE.name,
    displayName: VENT_FLARE.displayName,
    dailyDisplayName: VENT_FLARE.dailyDisplayName,
    hasForecast: VENT_FLARE.hasForecast,
    hasDaily: VENT_FLARE.hasDaily,
    responseDataFieldName: VENT_FLARE.responseData.name,
  },
  {
    name: TOTAL_USED.name,
    displayName: TOTAL_USED.displayName,
    dailyDisplayName: TOTAL_USED.dailyDisplayName,
    hasForecast: TOTAL_USED.hasForecast,
    hasDaily: TOTAL_USED.hasDaily,
    responseDataFieldName: TOTAL_USED.responseData.name,
  },
  {
    name: USED.name,
    displayName: USED.displayName,
    dailyDisplayName: USED.dailyDisplayName,
    hasForecast: USED.hasForecast,
    hasDaily: USED.hasDaily,
    responseDataFieldName: USED.responseData.name,
  },
  {
    name: INFERRED.name,
    displayName: INFERRED.displayName,
    dailyDisplayName: INFERRED.dailyDisplayName,
    hasForecast: INFERRED.hasForecast,
    hasDaily: INFERRED.hasDaily,
    responseDataFieldName: INFERRED.responseData.name,
  },
  {
    name: OTHER.name,
    displayName: OTHER.displayName,
    dailyDisplayName: OTHER.dailyDisplayName,
    hasForecast: OTHER.hasForecast,
    hasDaily: OTHER.hasDaily,
    responseDataFieldName: OTHER.responseData.name,
  },

  // for excel forecast column arrangement
  {
    name: OIL_MONTHLY.name,
    displayName: `${OIL_MONTHLY.displayName} Forecast`,
    dailyDisplayName: `${OIL_MONTHLY.dailyDisplayName} Forecast`,
    hasDaily: OIL_MONTHLY.hasDaily,
  },

  {
    name: OIL_CUMULATIVE.name,
    displayName: `${OIL_CUMULATIVE.displayName} Forecast`,
    dailyDisplayName: "",
    hasDaily: OIL_CUMULATIVE.hasDaily,
  },

  {
    name: GAS_MONTHLY.name,
    displayName: `${GAS_MONTHLY.displayName} Forecast`,
    dailyDisplayName: `${GAS_MONTHLY.dailyDisplayName} Forecast`,
    hasDaily: GAS_MONTHLY.hasDaily,
  },

  {
    name: GAS_CUMULATIVE.name,
    displayName: `${GAS_CUMULATIVE.displayName} Forecast`,
    dailyDisplayName: "",
    hasDaily: GAS_CUMULATIVE.hasDaily,
  },
  {
    name: BOE_MONTHLY.name,
    displayName: `${BOE_MONTHLY.displayName} Forecast`,
    dailyDisplayName: `${BOE_MONTHLY.dailyDisplayName} Forecast`,
    hasDaily: BOE_MONTHLY.hasDaily,
  },
  {
    name: BOE_CUMULATIVE.name,
    displayName: `${BOE_CUMULATIVE.displayName} Forecast`,
    dailyDisplayName: "",
    hasDaily: BOE_CUMULATIVE.hasDaily,
  },
  {
    name: WATER_MONTHLY.name,
    displayName: `${WATER_MONTHLY.displayName} Forecast`,
    dailyDisplayName: `${WATER_MONTHLY.dailyDisplayName} Forecast`,
    hasDaily: WATER_MONTHLY.hasDaily,
  },

  {
    name: WATER_CUMULATIVE.name,
    displayName: `${WATER_CUMULATIVE.displayName} Forecast`,
    dailyDisplayName: "",
    hasDaily: WATER_CUMULATIVE.hasDaily,
  },
  {
    name: WATER_OIL_RATIO.name,
    displayName: `${WATER_OIL_RATIO.displayName} Forecast`,
    dailyDisplayName: "",
    hasDaily: WATER_OIL_RATIO.hasDaily,
  },
  {
    name: WATER_CUT.name,
    displayName: `${WATER_CUT.displayName} Forecast`,
    dailyDisplayName: "",
    hasDaily: WATER_CUT.hasDaily,
  },
  {
    name: GAS_OIL_RATIO.name,
    displayName: `${GAS_OIL_RATIO.displayName} Forecast`,
    dailyDisplayName: "",
    hasDaily: GAS_OIL_RATIO.hasDaily,
  },
];

export const GROUP_BY_MAP: { [key: string]: string } = {
  DisplayFormation: "ProducingFormation",
  InterpretedProducingFormation: INTERPRETED_PRODUCING_FORMATION.label,
  OperatorName: "CurrentOperator",
  UltimateOwner: ULTIMATE_OWNER.key,
  OriginalOperator: "OriginalOperator",
  LeaseName: LEASE_NAME.key,
  LeaseID: LEASE_NAME_AND_ID.key,
  WellStatus: WELL_STATUS.key,
  WellType: WELL_TYPE.key,
  CalculatedMajorPhase: "MajorPhase",
  LastInjectionType: "InjectionType",
  LastInjectionFormation: "InjectionFormation",
  DispositionType: "GasDispositionType",
  BasinName: BASIN_NAME.label,
  FieldName: "Field",
  SurfaceUWI: "SurfaceUWI",
  County: COUNTY.key,
  Slant: SLANT.key,
  Default: "ALL",
  NONE: "IndividualWells",
};
