export const WELL_PANEL_HOVER_ACTION = "HOVER";
export const WELL_PANEL_RIGHT_CLICK_ACTION = "WELL-SPOT-RIGHT-CLICK";
export const PERMIT_PANEL_RIGHT_CLICK_ACTION = "PERMIT-SPOT-RIGHT-CLICK";
export const WELL_PANEL_DISTINCTION_MENU_ACTION = "DISTINCTION-MENU";

// Well Panel Grid States
export const OPENED_ACTIVE_WELL_OR_PERMIT = "OPENED-ACTIVE-WELL-OR-PERMIT";
export const OPENED_ACTIVE_WELL_SYSTEM_OR_PERMIT =
  "OPENED-ACTIVE-WELL-SYSTEM-OR-PERMIT";
export const OPENED_INACTIVE_WELL_SYSTEM_OR_PERMIT =
  "OPENED-INACTIVE-WELL-SYSTEM-OR-PERMIT";
export const NOT_OPENED_WELL_SYSTEM_OR_PERMIT =
  "NOT-OPENED-WELL-SYSTEM-OR-PERMIT";
