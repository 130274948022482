import { StateCreator } from "zustand";

import {
  Basins,
  CarbonStorageSlice,
} from "../../../types/map/mapSettings/store";

import { DEFAULT_CARBON_STORAGE_BASINS_INFO } from "../../../constants/map/carbonStorage";

import { clone } from "../../../utils";

const sliceResetFns = new Set<() => void>();

const resetCarbonStorageSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialCarbonStorageStates = {
  carbonStorageInfo: {
    isSelected: false,
    basins: clone(DEFAULT_CARBON_STORAGE_BASINS_INFO),
  },
};

const carbonStorageSlice: StateCreator<CarbonStorageSlice> = (set, get) => {
  sliceResetFns.add(() => set(initialCarbonStorageStates));

  return {
    ...initialCarbonStorageStates,
    toggleCarbonStorageSelected: (status) =>
      set((state) => ({
        carbonStorageInfo: {
          ...state.carbonStorageInfo,
          isSelected: status ?? !state.carbonStorageInfo.isSelected,
        },
      })),
    updateCarbonStorageBasinStatus: (basinName, status) =>
      set((state) => {
        const clonedBasins = clone(state.carbonStorageInfo.basins) as Basins;

        clonedBasins[basinName].isSelected =
          status ?? !clonedBasins[basinName].isSelected;

        return {
          carbonStorageInfo: {
            ...state.carbonStorageInfo,
            basins: clonedBasins,
          },
        };
      }),
    updateCarbonStorageBasinFormation: (basinName, formation) =>
      set((state) => {
        const clonedBasins = clone(state.carbonStorageInfo.basins) as Basins;
        clonedBasins[basinName].formationName = formation;

        return {
          carbonStorageInfo: {
            ...state.carbonStorageInfo,
            basins: clonedBasins,
          },
        };
      }),
    updateCarbonStorageBasins: (basins) =>
      set((state) => ({
        carbonStorageInfo: { ...state.carbonStorageInfo, basins },
      })),
    updateCarbonStorageInfo: (carbonStorageInfo) =>
      set(() => ({ carbonStorageInfo })),
  };
};

export { carbonStorageSlice, resetCarbonStorageSlice };
