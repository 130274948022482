import { FC, PropsWithChildren } from "react";

interface Props {
  hidden: boolean;
  name: string;
}

const TabPanel: FC<PropsWithChildren<Props>> = ({ hidden, children, name }) => (
  <div
    role="tabpanel"
    aria-labelledby="sidebar-tabpanel"
    className={`sidebar-tabpanel ${name}`}
    hidden={hidden}
  >
    {children}
  </div>
);

export default TabPanel;
