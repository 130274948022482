import { FC } from "react";

import { isArray } from "lodash";

import { PermitSystemData } from "../../../types/panels/permitPanel/permitPanel";
import { WellPermitPanelSection } from "../../../types/panels/wellPanel/wellPanel";

import usePanelsStore from "../../../store/panels/panelsStore";

import Header from "./sections/Header";
import OperatorContact from "./sections/OperatorContact";
import RelatedPermits from "./sections/RelatedPermits";
import RelatedWells from "./sections/RelatedWells";

interface PermitContentProps {
  wellHeaderPermitKey: string;
  permitSystem?: PermitSystemData;
}

const PermitPanelContent: FC<PermitContentProps> = ({
  wellHeaderPermitKey,
  permitSystem,
}) => {
  const updatePermitPanelActiveSectionByKey = usePanelsStore(
    (state) => state.updatePermitPanelActiveSectionByKey
  );

  const handleOnChange = (section: WellPermitPanelSection) => {
    updatePermitPanelActiveSectionByKey(wellHeaderPermitKey, section);
  };

  return (
    <div className="permit-content-container">
      {permitSystem && (
        <>
          <Header
            data={permitSystem.permitData}
            isExpanded={permitSystem.permitSectionStatus.isHeaderSectionOpen}
            onChange={handleOnChange}
          />
          <OperatorContact
            data={permitSystem.permitData}
            isExpanded={
              permitSystem.permitSectionStatus.isOperatorContactSectionOpen
            }
            onChange={handleOnChange}
          />
          {permitSystem.permitData.relatedWells &&
            isArray(permitSystem.permitData.relatedWells) &&
            permitSystem.permitData.relatedWells.length && (
              <RelatedWells
                data={permitSystem.permitData}
                isExpanded={
                  permitSystem.permitSectionStatus.isRelatedWellsSectionOpen
                }
                onChange={handleOnChange}
              />
            )}
          {permitSystem.permitData.relatedPermits &&
            isArray(permitSystem.permitData.relatedPermits) &&
            permitSystem.permitData.relatedPermits.length && (
              <RelatedPermits
                data={permitSystem.permitData}
                isExpanded={
                  permitSystem.permitSectionStatus.isRelatedPermitsSectionOpen
                }
                onChange={handleOnChange}
              />
            )}
        </>
      )}
    </div>
  );
};

export default PermitPanelContent;
