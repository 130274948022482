import { Polygon, PolygonData } from "../../../types/map/gis/common";
import {
  SavedAttributeSearch,
  SavedAttributeSort,
  SavedUWIFileUpload,
  SavedUWISearch,
} from "../../../types/panels/savedSearchPanel/savedSearchData";

import { RECORD_TYPE } from "../../../constants/attributes";
import { UWI_TAB } from "../../../constants/constants";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useQueryBuilderStore from "../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";

import { createRecordTypeBounds } from "../../../utils/common/boundsData";
import { getPolygonType } from "../../../utils/map/draw/polygon";

import useDrawPolygon from "../../search/useDrawPolygon";
import useUwiFileUpload from "../../search/useUwiFileUpload";

const useLoadSearches = () => {
  const updateCurrentBounds = useQueryBuilderStore(
    (state) => state.updateCurrentBounds
  );

  const updatePolygons = useMapDrawStore((state) => state.updatePolygons);
  const updateShapeId = useMapDrawStore((state) => state.updateShapeId);

  const updateActiveSearchPanelTab = usePanelsStore(
    (state) => state.updateActiveSearchPanelTab
  );

  const toggleGrid = useDataGridStore((state) => state.toggleGrid);
  const toggleGridHeader = useDataGridStore((state) => state.toggleGridHeader);
  const updateInitialGridSearchMade = useDataGridStore(
    (state) => state.updateInitialGridSearchMade
  );

  const loadQBInfo = useQueryBuilderStore((state) => state.loadQBInfo);

  const updateIsLoadingSavedSearchFileUpload = useUWISearchStore(
    (state) => state.updateIsLoadingSavedSearchFileUpload
  );
  const updateUploadedUWIFile = useUWISearchStore(
    (state) => state.updateUploadedUWIFile
  );
  const updateUploadedUwiFileId = useUWISearchStore(
    (state) => state.updateUploadedUwiFileId
  );
  const updateIsFromUploadedUWI = useUWISearchStore(
    (state) => state.updateIsFromUploadedUWI
  );
  const loadSavedUWIs = useUWISearchStore((state) => state.loadSavedUWIs);

  const setIsValidUwiFile = useUWISearchStore(
    (state) => state.setIsValidUwiFile
  );

  const { drawPolygonDynamically } = useDrawPolygon();
  const { getUWIFileIdWithRetry } = useUwiFileUpload();

  const loadAttributeSearch = (
    attributeSearch?: SavedAttributeSearch,
    sortList?: SavedAttributeSort
  ) => {
    const bounds = attributeSearch?.bounds ?? [];

    const recordTypeExists = bounds?.find(
      (attribute) => attribute.bound === RECORD_TYPE.key
    );

    if (!Boolean(recordTypeExists)) {
      const recordType = createRecordTypeBounds(RECORD_TYPES.WELL);
      bounds.push(recordType);
    }

    if (bounds?.length) {
      loadQBInfo(bounds, sortList);
      updateCurrentBounds(bounds);
    }

    // Drawn Polygons
    const geometry = attributeSearch?.geometry;
    const coordinates = geometry?.coordinates;
    const polygons: Polygon = [];
    if (coordinates?.length) {
      for (const geometryData of coordinates) {
        const { id, isSelected, isShapeFile, coordinates } = geometryData;
        const polygon: PolygonData = {
          id,
          isSelected,
          isShapeFile: !!isShapeFile,
          coordinates: [
            coordinates.map((value) => [value.coordinateX, value.coordinateY]),
          ],
        };
        polygons.push(polygon);
        drawPolygonDynamically(polygon);
      }
    }
    updatePolygons(polygons);

    const shapeId = attributeSearch?.shapeId ?? "";
    updateShapeId(shapeId);

    const drawnPolygons = polygons.filter((p) => !p.isShapeFile);
    updateIsFromUploadedUWI(false);
    return {
      currentBounds: bounds,
      drawnPolygons,
      polygonType: getPolygonType(drawnPolygons),
      shapeId,
    };
  };

  const loadUWISearch = (uwiSearch?: SavedUWISearch) => {
    const searchUWIState = { loadedUWIs: {}, isMoreThanLimit: false };
    if (!uwiSearch || (uwiSearch && !uwiSearch.searchUWIs.length)) {
      return searchUWIState;
    }
    const uwis = uwiSearch.searchUWIs;

    loadSavedUWIs(uwis);

    updateActiveSearchPanelTab(UWI_TAB);
    updateIsFromUploadedUWI(false);
    searchUWIState.loadedUWIs = { searchedUWIs: uwis };
    return searchUWIState;
  };

  const loadUWIFileUpload = async (
    uwiFileUpload?: SavedUWIFileUpload,
    uwiSearch?: SavedUWISearch
  ) => {
    if (!uwiFileUpload) return {};
    if (uwiSearch) loadSavedUWIs(uwiSearch.searchUWIs, true);
    updateIsLoadingSavedSearchFileUpload(true);
    updateInitialGridSearchMade(true);
    toggleGrid(true);
    toggleGridHeader(true);
    try {
      const fileId = await getUWIFileIdWithRetry(uwiFileUpload?.fileName);
      updateIsFromUploadedUWI(true);
      updateUploadedUWIFile({ name: uwiFileUpload?.fileName });
      updateUploadedUwiFileId(fileId);
      updateIsLoadingSavedSearchFileUpload(false);
      setIsValidUwiFile(true);
      return { fileId };
    } catch (error) {
      console.error(error);
      return {};
    }
  };

  return {
    loadAttributeSearch,
    loadUWISearch,
    loadUWIFileUpload,
  };
};

export default useLoadSearches;
