import {
  CloseOutlined as CloseOutlinedIcon,
  CropFree as CropFreeIcon,
  Minimize as MinimizeIcon,
  MoreHoriz as MoreHorizIcon,
  RestartAlt as RestartAltIcon,
} from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";

import { DefaultViewChartControlsProps } from "../../../../../types/charts/chartType/chartTypeHeader";

const DefaultViewChartControls = ({
  onReset,
  onClick,
  onMinimize,
  onFullScreen,
  onDelete,
}: DefaultViewChartControlsProps) => {
  return (
    <>
      <IconButton size="small" onClick={() => onReset()}>
        <RestartAltIcon fontSize="small" />
      </IconButton>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        className="toolbar-divider"
      />
      <IconButton
        size="small"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          onClick(e)
        }
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={() => onMinimize()}>
        <MinimizeIcon fontSize="small" />
      </IconButton>
      <IconButton size="small" onClick={() => onFullScreen()}>
        <CropFreeIcon fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          onDelete();
        }}
      >
        <CloseOutlinedIcon fontSize="small" />
      </IconButton>
    </>
  );
};

export default DefaultViewChartControls;
