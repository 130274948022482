import { WellPermitCardProps } from "../../types/map/wellSpot/mapHover";

import * as attributes from "../../constants/attributes";

import useWellCardState from "../../customHooks/map/useWellCardState";
import useWellPanelViewAction from "../../customHooks/panels/wellPanel/useWellPanelViewAction";

import { formatAttributeByKey } from "../../utils/formatters/attributeFormatter";

import WellInfoText from "../common/WellInfoText";
import {
  CumBoeIcon,
  FirstProdMonthIcon,
  ProducingFormationIcon,
  TGSLandingZoneIcon,
  TGSOperatorIcon,
  TotalVerticalDepth,
} from "../common/icons/wellLabel";
import HoverCard from "./hoverCard/HoverCard";
import HoverCardFooter from "./hoverCard/HoverCardFooter";
import HoverCardHeader from "./hoverCard/HoverCardHeader";

const WellCard = ({
  open,
  anchorEl,
  wellData,
  isArrowEnabled = true,
  placement,
  offset,
  className = "",
  handleOnHoverAway,
}: WellPermitCardProps) => {
  const { shoudDisableOpenWellPanel } = useWellCardState();
  const { handleOpenWellCard } = useWellPanelViewAction();

  return (
    <HoverCard
      open={open}
      anchorEl={anchorEl}
      placement={placement}
      offset={offset}
      isArrowEnabled={isArrowEnabled}
      handleOnHoverAway={handleOnHoverAway}
      className={className}
    >
      <HoverCardHeader
        UWI={wellData.UWI}
        WellStatus={wellData.WellStatus}
        WellName={wellData.WellName}
        WellNumber={wellData.WellNumber}
      />
      <div className="well-label-other-info-container">
        <div className="well-label-other-info">
          <div className="well-label-col">
            <WellInfoText
              label={attributes.ULTIMATE_OWNER.label}
              value={formatAttributeByKey(
                attributes.ULTIMATE_OWNER.key,
                wellData.UltimateOwner
              )}
              icon={<TGSOperatorIcon />}
            />
            <WellInfoText
              label={attributes.DISPLAY_FORMATION.label}
              value={formatAttributeByKey(
                attributes.DISPLAY_FORMATION.key,
                wellData.ProducingFormation
              )}
              icon={<ProducingFormationIcon />}
            />
            <WellInfoText
              label={attributes.FIRST_MONTH.label}
              value={formatAttributeByKey(
                attributes.FIRST_MONTH.key,
                wellData.FirstMonth
              )}
              icon={<FirstProdMonthIcon />}
            />
          </div>
          <div className="well-label-col">
            <WellInfoText
              label={attributes.TOTAL_VERTICAL_DEPTH.label}
              value={formatAttributeByKey(
                attributes.TOTAL_VERTICAL_DEPTH.key,
                wellData.TotalVerticalDepth
              )}
              icon={<TotalVerticalDepth />}
            />
            <WellInfoText
              label={attributes.INTERPRETED_PRODUCING_FORMATION.label}
              value={formatAttributeByKey(
                attributes.INTERPRETED_PRODUCING_FORMATION.key,
                wellData.LandingZone
              )}
              icon={<TGSLandingZoneIcon />}
            />
            <WellInfoText
              label={attributes.CUM_BOE.label}
              value={formatAttributeByKey(
                attributes.CUM_BOE.key,
                wellData.CumBOE
              )}
              icon={<CumBoeIcon />}
            />
          </div>
        </div>
        <HoverCardFooter
          isDisabled={shoudDisableOpenWellPanel}
          onClickMoreInfo={handleOpenWellCard}
        />
      </div>
    </HoverCard>
  );
};

export default WellCard;
