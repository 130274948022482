import { useMemo } from "react";

import { useOktaAuth } from "@okta/okta-react";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import usePanelsStore from "../../store/panels/panelsStore";

import { getToken } from "../../utils/common/getToken";

const useCartoClickProps = () => {
  const { authState } = useOktaAuth();

  const allGridData = useDataGridStore((state) => state.allGridData);
  const updateWellSelectTrigger = useDataGridStore(
    (state) => state.updateWellSelectTrigger
  );
  const dataGridSelector = useDataGridStore((state) => state.allWellSelectors);

  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const deckGl = useMapStore((state) => state.deckGl);

  const selectedWellCardPWIDs = usePanelsStore(
    (state) => state.selectedWellCardPWIDs
  );
  const addSelectedWellCardPWID = usePanelsStore(
    (state) => state.addSelectedWellCardPWID
  );
  const selectedWellCardBWIDs = usePanelsStore(
    (state) => state.selectedWellCardBWIDs
  );
  const addSelectedWellCardBWID = usePanelsStore(
    (state) => state.addSelectedWellCardBWID
  );
  const selectedCardPermitIDs = usePanelsStore(
    (state) => state.selectedCardPermitIDs
  );
  const addSelectedCardPermitID = usePanelsStore(
    (state) => state.addSelectedCardPermitID
  );

  const addSelectedMapParentWellID = useMapSelectionStore(
    (state) => state.addSelectedMapParentWellID
  );
  const addMapSelectedPermitIDs = useMapSelectionStore(
    (state) => state.addMapSelectedPermitIDs
  );
  const addSelectedMapParentWellIDs = useMapSelectionStore(
    (state) => state.addSelectedMapParentWellIDs
  );
  const addSelectedBottomWellboreID = useMapSelectionStore(
    (state) => state.addSelectedBottomWellboreID
  );

  const updateMapHoverData = useMapHoverStore(
    (state) => state.updateMapHoverData
  );

  const cartoClickProps = useMemo(
    () => ({
      token: getToken(authState),
      allGridData,
      updateWellSelectTrigger,

      selectedWellCardPWIDs,
      addSelectedWellCardPWID,
      selectedWellCardBWIDs,
      addSelectedWellCardBWID,
      selectedCardPermitIDs,
      addSelectedCardPermitID,

      // Map Selection
      addSelectedMapParentWellID,
      addSelectedMapParentWellIDs,
      addSelectedBottomWellboreID,
      addMapSelectedPermitIDs,

      // MISC
      toggleLayer,

      // right click well panel
      dataGridSelector,
      updateMapHoverData,
      deckGl,
    }),
    [
      authState,
      allGridData,
      updateWellSelectTrigger,

      selectedWellCardPWIDs,
      addSelectedWellCardPWID,
      selectedWellCardBWIDs,
      addSelectedWellCardBWID,
      selectedCardPermitIDs,
      addSelectedCardPermitID,

      addSelectedMapParentWellID,
      addSelectedMapParentWellIDs,
      addSelectedBottomWellboreID,
      addMapSelectedPermitIDs,

      // MISC
      toggleLayer,

      //right click well panel
      dataGridSelector,
      updateMapHoverData,
      deckGl,
    ]
  );

  return {
    cartoClickProps,
  };
};

export default useCartoClickProps;
