import { StateCreator } from "zustand";

import {
  CommonLayerStylingSlice,
  MapSettingsStates,
} from "../../../types/map/mapSettings/store";

import {
  BOTTOM_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../../constants/constants";
import {
  defaultLayerColors,
  defaultLayerColorsLoading,
  defaultLayerStyles,
} from "../../../constants/defaultMapSettingsStoreData";
import { UNIFORM_COLOR } from "../../../constants/map/mapSettings";

import { clone } from "../../../utils";
import { resetPermitColorSlice } from "./permitColorSlice";
import { resetWellBubbleMapSlice } from "./wellBubbleMapSlice";
import { resetWellColorSlice } from "./wellColorSlice";

const sliceResetFns = new Set<() => void>();

const resetLayerStylingSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialCommonLayerStylingStates = {
  isProcessingStyles: false, // including wells, permits and bubble map styling
  isHighlightSelectedSpots: true,
  layerColorsLoading: clone(defaultLayerColorsLoading),
  layerStyles: clone(defaultLayerStyles),
  layerLegendColors: clone(defaultLayerColors),
  topAttribValues: [],
};

const commonLayerStylingSlice: StateCreator<
  MapSettingsStates,
  [],
  [],
  CommonLayerStylingSlice
> = (set, get) => {
  sliceResetFns.add(() => set(initialCommonLayerStylingStates));

  return {
    ...initialCommonLayerStylingStates,
    updateIsProcessingStyles: (isProcessingStyles) =>
      set(() => ({ isProcessingStyles })),
    updateLayerColorsLoading: (layerName, status) =>
      set((state) => ({
        layerColorsLoading: {
          ...state.layerColorsLoading,
          [layerName]: status,
        },
      })),

    toggleIsHighlightSelectedSpots: (status) =>
      set((state) => ({
        isHighlightSelectedSpots: status ?? !state.isHighlightSelectedSpots,
      })),

    updateLayerStyles: (layerKey, styleKey, value) =>
      set((state) => ({
        layerStyles: {
          ...state.layerStyles,
          [layerKey]: {
            ...state.layerStyles[layerKey],
            [styleKey]: value,
          },
        },
      })),

    updateWellStyleColors: (value) =>
      set((state) => {
        return {
          layerStyles: {
            ...state.layerStyles,
            [WELL_SPOTS]: {
              ...state.layerStyles[WELL_SPOTS],
              color: value,
            },
            [BOTTOM_WELL_SPOTS]: {
              ...state.layerStyles[BOTTOM_WELL_SPOTS],
              color: value,
            },
            [WELL_PATH_AND_STICKS]: {
              ...state.layerStyles[WELL_PATH_AND_STICKS],
              color: value,
            },
          },
          ...(value === UNIFORM_COLOR.key && {
            layerLegendColors: {
              ...state.layerLegendColors,
              [WELL_SPOTS]: clone(defaultLayerColors[WELL_SPOTS]),
            },
          }),
        };
      }),

    updatePermitStyleColors: (value) =>
      set((state) => ({
        layerStyles: {
          ...state.layerStyles,
          [PERMIT_SPOTS]: {
            ...state.layerStyles[PERMIT_SPOTS],
            color: value,
          },
        },
        ...(value === UNIFORM_COLOR.key && {
          layerLegendColors: {
            ...state.layerLegendColors,
            [PERMIT_SPOTS]: clone(defaultLayerColors[PERMIT_SPOTS]),
          },
        }),
      })),

    updateAllLayerStyleColors: (value) => {
      get().updateWellStyleColors(value);
      get().updatePermitStyleColors(value);
    },

    loadWellStyles: (wellStyles) =>
      set(() => ({
        layerStyles: {
          ...defaultLayerStyles,
          ...wellStyles,
        },
      })),

    loadLayerStyles: (layerStyles) =>
      set((state) => ({
        layerStyles: {
          ...state.layerStyles,
          ...layerStyles,
        },
      })),

    updateTopAttribValues: (topAttribValues) =>
      set(() => ({ topAttribValues })),

    resetLayerStylingStates: () => {
      resetLayerStylingSlice();
      resetWellColorSlice();
      resetPermitColorSlice();
      resetWellBubbleMapSlice();
    },
  };
};

export { commonLayerStylingSlice, resetLayerStylingSlice };
