import { create } from "zustand";

import { MapSettingsStates } from "../../../types/map/mapSettings/store";

import { carbonStorageSlice } from "./carbonStorageSlice";
import { commonLayerStylingSlice } from "./layerStylingSlice";
import { mapOverlaySlice } from "./mapOverlaySlice";
import { permitColorSlice } from "./permitColorSlice";
import { stratModelsSlice } from "./stratigraphicModelsSlice";
import { wellBubbleMapSlice } from "./wellBubbleMapSlice";
import { wellColorSlice } from "./wellColorSlice";

const useMapSettingsStore = create<MapSettingsStates>()((...a) => ({
  ...mapOverlaySlice(...a),
  ...commonLayerStylingSlice(...a),
  ...wellColorSlice(...a),
  ...permitColorSlice(...a),
  ...wellBubbleMapSlice(...a),
  ...carbonStorageSlice(...a),
  ...stratModelsSlice(...a),
}));

export default useMapSettingsStore;
