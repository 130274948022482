import { StateCreator } from "zustand";

import { ExportPermitModalSlice } from "../../types/panels/permitPanel/exportPermitModalStore";

const exportPermitPanelSlice: StateCreator<ExportPermitModalSlice> = (set) => ({
  exportPermitData: undefined,
  updateExportPermitData: (exportPermitData) =>
    set(() => ({ exportPermitData })),
});

export { exportPermitPanelSlice };
