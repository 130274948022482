const TimelineIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16C1 15.45 1.19583 14.9792 1.5875 14.5875C1.97917 14.1958 2.45 14 3 14H3.2625C3.3375 14 3.41667 14.0167 3.5 14.05L8.05 9.5C8.01667 9.41667 8 9.3375 8 9.2625V9C8 8.45 8.19583 7.97917 8.5875 7.5875C8.97917 7.19583 9.45 7 10 7C10.55 7 11.0208 7.19583 11.4125 7.5875C11.8042 7.97917 12 8.45 12 9C12 9.03333 11.9833 9.2 11.95 9.5L14.5 12.05C14.5833 12.0167 14.6625 12 14.7375 12H15.2625C15.3375 12 15.4167 12.0167 15.5 12.05L19.05 8.5C19.0167 8.41667 19 8.3375 19 8.2625V8C19 7.45 19.1958 6.97917 19.5875 6.5875C19.9792 6.19583 20.45 6 21 6C21.55 6 22.0208 6.19583 22.4125 6.5875C22.8042 6.97917 23 7.45 23 8C23 8.55 22.8042 9.02083 22.4125 9.4125C22.0208 9.80417 21.55 10 21 10H20.7375C20.6625 10 20.5833 9.98333 20.5 9.95L16.95 13.5C16.9833 13.5833 17 13.6625 17 13.7375V14C17 14.55 16.8042 15.0208 16.4125 15.4125C16.0208 15.8042 15.55 16 15 16C14.45 16 13.9792 15.8042 13.5875 15.4125C13.1958 15.0208 13 14.55 13 14V13.7375C13 13.6625 13.0167 13.5833 13.05 13.5L10.5 10.95C10.4167 10.9833 10.3375 11 10.2625 11H10C9.96667 11 9.8 10.9833 9.5 10.95L4.95 15.5C4.98333 15.5833 5 15.6625 5 15.7375V16C5 16.55 4.80417 17.0208 4.4125 17.4125C4.02083 17.8042 3.55 18 3 18Z"
        fill="#BBBBBB"
      />
    </svg>
  );
};

export default TimelineIcon;
