import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";

import { XAxisDataPickerProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

const XAxisDataPicker = ({
  dataList,
  selected,
  onChange,
}: XAxisDataPickerProps) => {
  return (
    <>
      <Grid item className="x-axis-data-picker-container">
        <FormControl>
          <InputLabel
            id="x-axis-data-field-input-label"
            variant="outlined"
            shrink
          >
            Data Field
          </InputLabel>
          <Select
            label="Data Field"
            className="x-axis-data-field-select"
            value={selected}
            onChange={onChange}
            renderValue={(selected) => {
              const attribute = dataList.find((attr) => attr.name === selected);
              return attribute?.displayName ?? "";
            }}
            displayEmpty
          >
            {dataList.map((attribute, index) => {
              return (
                <MenuItem key={index} value={attribute.name}>
                  {attribute.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </>
  );
};

export default XAxisDataPicker;
