import { useCallback, useMemo } from "react";

import {
  SavedAttributeSearch,
  SavedGeometryData,
  SearchState,
} from "../../../types/panels/savedSearchPanel/savedSearchData";
import { SearchTypes } from "../../../types/panels/searchPanel/search";

import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";
import { FILE_UPLOAD_FOLDER } from "../../../constants/uwiSearchUpload";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useQueryBuilderStore from "../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../../store/useStore";

import { getSortPerAttribute } from "../../../utils/savedSearch/savedData";

const useSearchData = () => {
  const searchUWIText = useUWISearchStore((state) => state.searchUWIText);
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const updateIsFromUploadedUWI = useUWISearchStore(
    (state) => state.updateIsFromUploadedUWI
  );
  const updateFileTimeStamp = useUWISearchStore(
    (state) => state.updateFileTimeStamp
  );

  const authUser = useStore((state) => state.authUser);
  const currentBounds = useQueryBuilderStore((state) => state.currentBounds);

  const polygons = useMapDrawStore((state) => state.polygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  const currentQBInfo = useQueryBuilderStore((state) => state.currentQBInfo);

  const sortPerAttribute = useMemo(
    () => getSortPerAttribute(currentQBInfo),
    [currentQBInfo]
  );

  const getGeometryData = useCallback(() => {
    let geometry: SavedAttributeSearch["geometry"] = {
      coordinates: [],
    };
    if (polygons?.length) {
      const geometryCoordinates: SavedGeometryData[] = [];
      for (const polygonData of polygons) {
        const { id, isSelected, isShapeFile, coordinates } = polygonData;
        const polygon: SavedGeometryData = {
          id,
          isSelected,
          isShapeFile,
          coordinates: [],
        };
        coordinates[0].forEach((element: number[], index: number) => {
          const coordinate = {
            i: index,
            coordinateX: element[0],
            coordinateY: element[1],
          };
          polygon.coordinates.push(coordinate);
        });
        geometryCoordinates.push(polygon);
      }
      geometry = { coordinates: geometryCoordinates };
    }
    return geometry;
  }, [polygons]);

  const searchData = useCallback(
    (searchType: SearchTypes) => {
      let searchState: SearchState = {};
      if (searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
        updateIsFromUploadedUWI(false);
        searchState = {
          attributeSearch: {
            bounds: currentBounds,
            geometry: getGeometryData(),
            shapeId,
          },
          ...(Object.keys(sortPerAttribute).length ? { sortPerAttribute } : {}),
        };
      } else {
        if (
          searchType === SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH ||
          searchType === SEARCH_TYPES.UWI_SEARCH
        ) {
          const uwiList = searchUWIText.split("\n");
          searchState = {
            ...searchState,
            uwiSearch: { searchUWIs: uwiList },
          };
        }
        if (
          searchType === SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH ||
          searchType === SEARCH_TYPES.UWI_FILE_SEARCH
        ) {
          searchState = {
            ...searchState,
            fileUpload: {
              fileName: uploadedUWIFile?.name ?? "",
              folderName: `${FILE_UPLOAD_FOLDER}/${authUser.uid}`,
            },
          };
        }
        updateFileTimeStamp(null);
      }
      return {
        searchState,
        searchTypeToBeSaved: searchType,
      };
    },
    [
      authUser,
      currentBounds,
      shapeId,
      updateIsFromUploadedUWI,
      uploadedUWIFile,
      searchUWIText,
      sortPerAttribute,
      getGeometryData,
      updateFileTimeStamp,
    ]
  );

  return { searchData };
};

export default useSearchData;
