import * as attributes from "../../constants/attributes";

import {
  productionDataAttributesInBbl,
  productionDataAttributesInBoe,
  productionDataAttributesInDays,
  productionDataAttributesInMcf,
  productionDataAttributesInPsi,
  productionDataAttributesInScfPerBbl,
} from "./productionDataAttributeUnits";

//Converting to lowercase to remove case sensitivity on keys
//Attributes are grouped based on their Imperial Units
const attributesInBbl = [
  attributes.FRAC_FLUID_AMOUNT.key.toLowerCase(),
  attributes.CUM_INJECTION_LIQUID.key.toLowerCase(),
  attributes.CUM_OIL.key.toLowerCase(),
  attributes.CUM_WATER.key.toLowerCase(),
  attributes.MAX_OIL.key.toLowerCase(),
  attributes.MAX_OIL_PLUS_2.key.toLowerCase(),
  attributes.MAX_WATER.key.toLowerCase(),
  attributes.MAX_WATER_PLUS_2.key.toLowerCase(),
  attributes.WELL_FORECAST_OIL_REMAINING.key.toLowerCase(),
  attributes.WELL_FORECAST_OIL_ULTIMATE.key.toLowerCase(),
  attributes.WELL_FORECAST_WATER_REMAINING.key.toLowerCase(),
  attributes.WELL_FORECAST_WATER_ULTIMATE.key.toLowerCase(),
  attributes.OIL_INITIAL_RATE.key.toLowerCase(),
  attributes.WATER_INITIAL_RATE.key.toLowerCase(),

  //Production Plot and Type Curve Chart Attributes
  ...productionDataAttributesInBbl,
];

const attributesInMcf = [
  attributes.CUM_GAS.key.toLowerCase(),
  attributes.CUM_INJECTION_GAS.key.toLowerCase(),
  attributes.CUM_VENT_FLARE_VOL.key.toLowerCase(),
  attributes.MAX_GAS.key.toLowerCase(),
  attributes.MAX_GAS_PLUS_2.key.toLowerCase(),
  attributes.WELL_FORECAST_GAS_REMAINING.key.toLowerCase(),
  attributes.WELL_FORECAST_GAS_ULTIMATE.key.toLowerCase(),
  attributes.GAS_INITIAL_RATE.key.toLowerCase(),

  //Production Plot and Type Curve Chart Attributes
  ...productionDataAttributesInMcf,
];

const attributesInFt = [
  attributes.LATERAL_LENGTH.key.toLowerCase(),
  attributes.MEASURED_DEPTH.key.toLowerCase(),
  attributes.PERF_INTERVAL_BOTTOM.key.toLowerCase(),
  attributes.PERF_INTERVAL_TOP.key.toLowerCase(),
  attributes.TOTAL_VERTICAL_DEPTH.key.toLowerCase(),
  attributes.PERMIT_TOTAL_DEPTH.key.toLowerCase(),
  attributes.PERMIT_FORMATION_DEPTH.key.toLowerCase(),

  //Well Panel Attributes
  attributes.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.key.toLowerCase(),
  attributes.CLOSEST_HORIZONTAL_DISTANCE.key.toLowerCase(),
  attributes.CLOSEST_VERTICAL_DISTANCE.key.toLowerCase(),
  attributes.ELEVATION_GROUND.key.toLowerCase(),
];

const attributesInBblPerFt = [
  attributes.FRAC_FLUID_AMOUNT_PER_FT.key.toLowerCase(),
  attributes.EUR_PER_FT.key.toLowerCase(),
  attributes.OIL_CUM_PER_FT.key.toLowerCase(),
  attributes.OIL_EUR_PER_FT.key.toLowerCase(),
  attributes.WATER_CUM_PER_FT.key.toLowerCase(),
];

const attributesInBoe = [
  attributes.BOE_IP90.key.toLowerCase(),
  attributes.CUM_BOE.key.toLowerCase(),
  attributes.MAX_BOE.key.toLowerCase(),
  attributes.WELL_FORECAST_BOE_REMAINING.key.toLowerCase(),
  attributes.WELL_FORECAST_BOE_ULTIMATE.key.toLowerCase(),

  //Production Plot and Type Curve Chart Attributes
  ...productionDataAttributesInBoe,
];

const attributesInBoePerFt = [
  attributes.BOE_CUM_PER_FT.key.toLowerCase(),
  attributes.BOE_MAX_PER_FT.key.toLowerCase(),
];

const attributesInCfPerBbl = [
  attributes.CUM_GOR.key.toLowerCase(),
  attributes.WELL_FORECAST_GOR_ULTIMATE.key.toLowerCase(),
];

const attributesInBblPerMmcf = [
  attributes.CUM_YIELD.key.toLowerCase(),
  attributes.WELL_FORECAST_YIELD_ULTIMATE.key.toLowerCase(),
];

const attributesInMcfPerFt = [
  attributes.GAS_CUM_PER_FT.key.toLowerCase(),
  attributes.GAS_EUR_PER_FT.key.toLowerCase(),
];

const attributesInGallon = [attributes.ACID_AMOUNT.key.toLowerCase()];

const attributesInGramsPerCubicCentimeter = [
  attributes.DENSITY.key.toLowerCase(),
];

const attributesInPounds = [attributes.PROPPANT_AMOUNT.key.toLowerCase()];

const attributesInPoundsPerFt = [
  attributes.PROPPANT_AMOUNT_PER_FT.key.toLowerCase(),
];

const attributesInOhmm = [attributes.RESISTIVITY.key.toLowerCase()];

const attributesInUsPerFt = [attributes.SONIC.key.toLowerCase()];

const attributesInDays = [...productionDataAttributesInDays];

const attributesInScfPerBbl = [...productionDataAttributesInScfPerBbl];

const attributesInPsi = [...productionDataAttributesInPsi];

export {
  attributesInGallon,
  attributesInGramsPerCubicCentimeter,
  attributesInBbl,
  attributesInBblPerFt,
  attributesInBblPerMmcf,
  attributesInMcf,
  attributesInMcfPerFt,
  attributesInCfPerBbl,
  attributesInFt,
  attributesInPounds,
  attributesInPoundsPerFt,
  attributesInOhmm,
  attributesInUsPerFt,
  attributesInBoePerFt,
  attributesInBoe,
  attributesInDays,
  attributesInScfPerBbl,
  attributesInPsi,
};
