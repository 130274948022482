import { FC, useEffect, useMemo, useState } from "react";
import Plot from "react-plotly.js";

import { ChartRange } from "../../../../types/charts/chartType/chartType";
import { WellPanelSectionsProps } from "../../../../types/panels/wellPanel/common";

import { CHART_SCALING_TYPE } from "../../../../constants/charts/charts";
import { PRODUCTION_PLOT } from "../../../../constants/constants";
import {
  PRODUCTION_PLOT_SECTION,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";
import useStore from "../../../../store/useStore";

import useWellPanelProduction from "../../../../customHooks/panels/wellPanel/useWellPanelProduction";

import { differenceInMonths } from "../../../../utils/charts/dateHelper";
import {
  config,
  fontLayout,
  generalLayout,
  legendLayout,
  xAxisLayout,
  yAxisLayout,
} from "../../../../utils/charts/layouts/ProductionPlotLayout";

import Loading from "../../../common/Loading";
import { TGSLogo } from "../../../common/icons";
import WellPanelAccordion from "../common/WellPanelAccordion";

const ProductionPlot: FC<WellPanelSectionsProps> = ({
  wellData,
  activePage,
  layer,
  groupedWellID,
  isExpanded,
  onChange,
  isChartExport = false,
}) => {
  const { getProductionData, isProductionDataLoading, productionDataTrace } =
    useWellPanelProduction();
  const [plotlyLayout, setPlotlyLayout] = useState<any>();
  const [range, setRange] = useState<ChartRange | undefined>(undefined);
  const exportChart = useStore((state) => state.exportChart);

  const updateWellCardDataByTypeAndKey = usePanelsStore(
    (state) => state.updateWellCardDataByTypeAndKey
  );

  useEffect(() => {
    if (productionDataTrace?.isDataLoaded) {
      updateWellCardDataByTypeAndKey(
        { type: "productionplot", ...productionDataTrace },
        wellData.wellId,
        layer,
        groupedWellID
      );
    }
  }, [productionDataTrace]);

  useEffect(() => {
    if (
      wellData.pageNumber === activePage &&
      !wellData.productionData?.isDataLoaded
    ) {
      getProductionData({ wellId: wellData.wellCardData.wellId as number });
    }
  }, [wellData.pageNumber, wellData.productionData, activePage]);

  const handleResetChart = () => {
    const range: ChartRange = {
      xLegend: -0.1,
      yLegend: -0.7,
      autorange: true,
      yRange: [undefined, undefined],
      xRange: [undefined, undefined],
    };
    setRange(range);
  };

  useEffect(() => {
    handleResetChart();
  }, [activePage]);

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.PRODUCTION_PLOT_SECTION);
  };

  const onChartRelayout = (figure: any) => {
    if (figure?.autosize) {
      //on initialization, first value of the callBack is just {autosize: true} so we should negate the call
      return;
    }
    setPlotlyLayout(figure);
  };

  useEffect(() => {
    //Triggers when reLayout throws a callback [NOTE: Even on new generation, it triggers]
    const layout = plotlyLayout;
    let layoutCount = 0;
    //Check if layout contains the right axis values so we count the length, length should be more than 1. [Expected: 4, 2 items per axis]
    if (layout) {
      layoutCount = Object.keys(layout).length;
    }
    let xRangeVal: undefined[] | number[] = [undefined, undefined];
    let yRangeVal: undefined[] | number[] = [undefined, undefined];

    if (layoutCount > 1) {
      const xAxisLower = layout?.["xaxis.range[0]"];
      const xAxisUpper = layout?.["xaxis.range[1]"];
      const yAxisLower = layout?.["yaxis.range[0]"];
      const yAxisUpper = layout?.["yaxis.range[1]"];
      let xLegend = layout?.["legend.x"];
      let yLegend = layout?.["legend.y"];

      if (xAxisLower && xAxisUpper) {
        xRangeVal = [xAxisLower, xAxisUpper];
      } else {
        if (range?.xRange) {
          xRangeVal = range.xRange;
        }
      }

      if (yAxisLower && yAxisUpper) {
        yRangeVal = [yAxisLower, yAxisUpper];
      } else {
        if (range?.yRange) {
          yRangeVal = range.yRange;
        }
      }

      if (!xLegend && range?.xLegend) {
        xLegend = range.xLegend;
      }
      if (!yLegend && range?.yLegend) {
        yLegend = range?.yLegend;
      }

      setRange({
        xLegend: xLegend,
        yLegend: yLegend,
        xRange: xRangeVal,
        yRange: yRangeVal,
        autorange: true,
      });
    }
  }, [plotlyLayout]);

  const shouldFormatXTicks: boolean = useMemo(() => {
    try {
      const minmaxValue: { [key: string]: number } = {};
      wellData.productionData?.trace.forEach((data) => {
        if (data.name && data.type === "scatter" && data.x?.length) {
          minmaxValue[data.name] = differenceInMonths(
            new Date(data.x[0] as string),
            new Date(data.x[data.x.length - 1] as string)
          );
        }
      });
      return Object.values(minmaxValue).filter((val) => val > 5).length > 0;
    } catch (error) {
      console.debug(error);
      return false;
    }
  }, [wellData.productionData?.trace]);

  const chartExportTitle = useMemo(() => {
    if (!isChartExport) return <></>;

    const getProdPlot = exportChart.find(
      (chart: any) => chart.chartType === PRODUCTION_PLOT_SECTION.key
    );

    return (
      <div className="chart-name">
        <div className="chart-type">{`${PRODUCTION_PLOT}_${getProdPlot.chartId}`}</div>
      </div>
    );
  }, [wellData, exportChart]);

  const chartExportLogo = useMemo(() => {
    if (!isChartExport) return <></>;

    return (
      <div className="tgs-logo-watermark-production-data">
        <TGSLogo />
      </div>
    );
  }, [exportChart]);

  return (
    <WellPanelAccordion
      expanded={isExpanded && !isProductionDataLoading}
      header={WELL_PANEL_SECTION.PRODUCTION_PLOT_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={isProductionDataLoading}
      className="chart"
      isChartExport={isChartExport}
    >
      <div className="well-panel-chart-container">
        {!isProductionDataLoading ? (
          <>
            {chartExportTitle}
            <Plot
              onDoubleClick={() => handleResetChart()}
              className="plot-chart"
              useResizeHandler={true}
              data={wellData.productionData?.trace ?? []}
              config={config}
              layout={{
                ...generalLayout(isChartExport),
                ...(isChartExport && {
                  width: 1850,
                  height: 1060,
                }),
                margin: {
                  t: isChartExport ? 50 : 10,
                  r: isChartExport ? 0 : 20,
                  b: 0,
                  l: isChartExport ? 70 : 50,
                },
                font: fontLayout,
                legend: { ...legendLayout(range, isChartExport) },
                xaxis: {
                  ...xAxisLayout(range, !shouldFormatXTicks, isChartExport),
                  gridcolor: "rgba(117, 117, 117, 0.4)",
                  tickangle: 0,
                },
                yaxis: {
                  ...yAxisLayout(
                    CHART_SCALING_TYPE.LINEAR,
                    range,
                    wellData.productionData?.customTicks ?? [],
                    true,
                    isChartExport
                  ),
                  gridcolor: "rgba(117, 117, 117, 0.4)",
                },
              }}
              onRelayout={(figure) => {
                onChartRelayout(figure);
              }}
            />
            {chartExportLogo}
          </>
        ) : (
          <div className="flexContainer-Spinner">
            <Loading />
          </div>
        )}
      </div>
    </WellPanelAccordion>
  );
};

export default ProductionPlot;
