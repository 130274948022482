import { useCallback } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import {
  DashboardChartState,
  DashboardChartType,
} from "../../types/charts/dashboardChartType/dashboardChartType";
import {
  BaseSearchRequestNoPaginationPayload,
  ChartsSearchRequestPayload,
} from "../../types/common/api";

import { CHART_DATA_STATE, PERMIT_CHARTS } from "../../constants/charts/charts";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import useRecordType from "../common/useRecordType";
import useSearchRequest from "../common/useSearchRequest";
import { useGridSelectedData } from "../grid/useGridSelectedData";

const useChartSearchRequest = () => {
  const allGridData = useDataGridStore((state) => state.allGridData);

  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );

  const selectedPermitIdsKeys = useMapSelectionStore(
    (state) => state.selectedPermitIdsKeys
  );

  const deselectedWellIdsKeys = useMapSelectionStore(
    (state) => state.deselectedWellIdsKeys
  );
  const deselectedPermitIdsKeys = useMapSelectionStore(
    (state) => state.deselectedPermitIdsKeys
  );

  const { isSelectedAllGrid, isDeselectedIds } = useGridSelectedData();

  const { searchedRecordType } = useRecordType();
  const { buildSearchRequest } = useSearchRequest();

  const isDashboardChartEmpty = useCallback(
    (chartDataState: DashboardChartState) =>
      searchedRecordType === RECORD_TYPES.PERMIT ||
      (!selectedWellIdsKeys.length &&
        chartDataState === CHART_DATA_STATE.POST_SEARCH),
    [selectedWellIdsKeys, searchedRecordType]
  );

  const buildChartPostRequest = useCallback(
    (chartType: DashboardChartType | ChartType) => {
      if (isSelectedAllGrid) {
        return buildSearchRequest(chartType);
      } else {
        if (isDeselectedIds) {
          let deSelectedSearchRequest = buildSearchRequest(chartType);

          if (deselectedWellIdsKeys.length) {
            deSelectedSearchRequest = {
              ...deSelectedSearchRequest,
              wellIds: deselectedWellIdsKeys,
              isDeselected: true,
            };
          }

          if (
            deselectedPermitIdsKeys.length &&
            PERMIT_CHARTS.includes(chartType)
          ) {
            deSelectedSearchRequest = {
              ...deSelectedSearchRequest,
              permitIds: deselectedPermitIdsKeys,
              isDeselected: true,
            };
          }
          return deSelectedSearchRequest;
        } else {
          const selectedSearchRequest: ChartsSearchRequestPayload<BaseSearchRequestNoPaginationPayload> =
            {};
          if (selectedWellIdsKeys.length) {
            selectedSearchRequest.wellIds = selectedWellIdsKeys;
          }

          if (
            selectedPermitIdsKeys.length &&
            PERMIT_CHARTS.includes(chartType)
          ) {
            selectedSearchRequest.permitIds = selectedPermitIdsKeys;
          }
          return selectedSearchRequest;
        }
      }
    },
    [
      isSelectedAllGrid,
      searchCriteria,
      selectedWellIdsKeys,
      selectedPermitIdsKeys,
      isFromUploadedUWIFile,
      deselectedWellIdsKeys,
      deselectedPermitIdsKeys,
      allGridData,
      isDeselectedIds,
    ]
  );

  return {
    buildChartPostRequest,
    isDashboardChartEmpty,
  };
};

export default useChartSearchRequest;
