import { FC, SVGProps } from "react";

interface CumBoeIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const CumBoeIcon: FC<CumBoeIconProps> = ({
  width = "11",
  height = "10",
  fill = "none",
  pathFill = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 10"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75343 0C2.99543 0 0.751953 2.24377 0.751953 5.00164C0.751953 7.7595 2.99543 10.0029 5.75343 10.0029C8.51129 10.0029 10.7549 7.7595 10.7549 5.00164C10.7549 2.24377 8.51132 0 5.75343 0ZM5.75343 9.21402C3.43055 9.21402 1.54085 7.32435 1.54085 5.00161C1.54085 2.67859 3.43055 0.788895 5.75343 0.788895C8.0763 0.788895 9.966 2.67859 9.966 5.00161C9.966 7.32435 8.0763 9.21402 5.75343 9.21402Z"
        fill={pathFill}
      />
      <path
        d="M4.96309 4.02186C4.96309 3.70544 5.42589 3.65141 5.82732 3.65141C6.20568 3.65141 6.72266 3.82873 7.15494 4.05284L7.23965 3.18071C7.02347 3.05712 6.52196 2.91045 6.01255 2.87979L6.136 1.99219H5.3102L5.43382 2.87979C4.36886 2.97998 4.02148 3.59735 4.02148 4.11447C4.02148 5.41869 6.45229 5.14115 6.45229 5.94344C6.45229 6.24469 6.16698 6.35248 5.6652 6.35248C4.9862 6.35248 4.47665 6.11349 4.21445 5.86633L4.08312 6.84655C4.3303 6.99322 4.83165 7.12439 5.43382 7.15537L5.3102 8.01202H6.136L6.01255 7.1475C7.26276 7.03938 7.48654 6.37558 7.48654 5.93587C7.48657 4.38474 4.96309 4.77039 4.96309 4.02186Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default CumBoeIcon;
