import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import CanadaWells from "../../../../assets/images/release-notes/CanadaWells.png";
import PressureData from "../../../../assets/images/release-notes/PressureData.png";

const Version20240603: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240603"}
      onChange={handleChange("Version20240603")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.06.03
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          06/03/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes Adding Canada well data, Pressure Data
          visualization in well panel.
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>• Canada Data </AccordionContent>
      <AccordionSubContent>
        • WDA users now have access to Canada wells including production data.
        TGS customers that license Canada data will be able to use all the WDA
        features and able to package and receive exports via the packaging
        manager.
      </AccordionSubContent>
      <AccordionSubContent>
        • Users can choose in Well Data Analytics whether they want to see data
        in Imperial or Metric Units (This would apply to Canada and US wells)
      </AccordionSubContent>
      <AccordionSubContent>
        • Users can only search for wells that they are entitled to. IE: Well
        attributes from Canada wells do not show up in search dropdown if users
        are not subscribed to Canada data.
      </AccordionSubContent>
      <AccordionImage src={CanadaWells} width="100%" draggable={false} />
      <AccordionContent>
        • Visualization of Pressure Data including DSTs and RFTs in Well Panel{" "}
      </AccordionContent>
      <AccordionSubContent>
        • Users can view and export all available Pressure Data for each well
        under well panel by clicking on MORE INFO icon on each well’s mini well
        card.
      </AccordionSubContent>
      <AccordionSubContent>• Available info for each test:</AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ DST Number & Reported Formation <br />
          ▪ TGS Landing Zone <br />
          ▪ Temperature <br />
          ▪ Top <br />
          ▪ Bottom <br />
          ▪ 1st Flow <br />
          ▪ 1st Shut-in <br />
          ▪ 2nd Flow <br />
          ▪ 2nd Shut-in <br />
          ▪ Recovery Remarks <br />
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage src={PressureData} width="50%" draggable={false} />
    </Accordion>
  );
};

export default Version20240603;
