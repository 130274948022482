import { Button } from "component-library";

type HoverCardFooterProps = {
  isDisabled: boolean;
  onClickMoreInfo: () => void;
};

const HoverCardFooter = ({
  isDisabled,
  onClickMoreInfo,
}: HoverCardFooterProps) => {
  return (
    <div className="hover-card-footer-container">
      <Button
        type="primary"
        text={<p className="more-info-text">MORE INFO</p>}
        onClick={onClickMoreInfo}
        state={isDisabled ? "disabled" : "enabled"}
      />
    </div>
  );
};

export default HoverCardFooter;
