import { ImageTile, Tile } from "ol";
import ImageWrapper from "ol/Image";
import BaseLayer from "ol/layer/Base";
import TileLayer from "ol/layer/Tile";
import { XYZ } from "ol/source";

import { DVT, Layer, MVT, WMS, WMTS } from "../../../types/map";

const tileLoadFetcher = (
  tile: Tile | ImageWrapper,
  src: string,
  token: string
) => {
  const imageTile = tile as ImageTile;

  const client = new XMLHttpRequest();

  client.open("GET", src);
  client.responseType = "arraybuffer";
  client.setRequestHeader("Authorization", "Bearer " + token);

  client.onload = function () {
    const arrayBufferView = new Uint8Array(this.response);
    const blob = new Blob([arrayBufferView], { type: "image/png" });
    const urlCreator = window.URL;
    const imageUrl = urlCreator.createObjectURL(blob);
    const htmlImageElement = imageTile.getImage() as HTMLImageElement;
    htmlImageElement.src = imageUrl;
  };
  client.send();
};

const returnAsBaseLayer = (layer: TileLayer<XYZ>) => {
  return layer as BaseLayer;
};

const returnAsWMTS = (layer: Layer) => {
  return layer as WMTS;
};

const returnAsDVT = (layer: Layer) => {
  return layer as DVT;
};

const returnAsWMS = (layer: Layer) => {
  return layer as WMS;
};

const returnAsMVT = (layer: Layer) => {
  return layer as MVT;
};

export {
  returnAsDVT,
  returnAsMVT,
  returnAsWMS,
  returnAsWMTS,
  returnAsBaseLayer,
  tileLoadFetcher,
};
