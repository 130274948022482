import { StateCreator } from "zustand";

import {
  MapSettingsStates,
  WellColorSlice,
} from "../../../types/map/mapSettings/store";

import { BOTTOM_WELL_SPOTS, WELL_SPOTS } from "../../../constants/constants";
import {
  COLOR_BY_ATTRIBUTE_INFO,
  STYLING_API_KEY_MAPPER,
} from "../../../constants/map/mapSettings";

import { getDefaultColorList } from "../../../components/charts/utils/mapSettingsUtils";

import {
  getCompressedDataInfo,
  transformWellStylingDataToObject,
} from "../../../utils/map/wellStyling";

import useDataGridStore from "../../grid/dataGridStore";
import useMapStore from "../mapStore";

const sliceResetFns = new Set<() => void>();

const resetWellColorSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialWellColorStates = {
  compressedSurfaceData: [],
  surfaceWellSpotValues: { data: {}, min: null, max: null, hasNull: false },
  compressedBottomData: [],
  bottomWellSpotValues: { data: {}, min: null, max: null, hasNull: false },

  partitionMinMax: { min: null, max: null, hasNull: false },
};

const wellColorSlice: StateCreator<
  MapSettingsStates,
  [],
  [],
  WellColorSlice
> = (set) => {
  sliceResetFns.add(() => set(initialWellColorStates));
  return {
    ...initialWellColorStates,
    updateWellLegendColors: () =>
      set((state) => {
        const { min, max, hasNull } = state.partitionMinMax;
        const attributeKey = state.layerStyles[WELL_SPOTS].color;
        const topAttribValues = state.topAttribValues;
        const initialGridSearchMade =
          useDataGridStore.getState().initialGridSearchMade;
        const defaultColorList = getDefaultColorList(
          topAttribValues,
          attributeKey,
          min,
          max,
          hasNull,
          initialGridSearchMade
        );
        return {
          layerLegendColors: {
            ...state.layerLegendColors,
            [WELL_SPOTS]: defaultColorList,
          },
        };
      }),

    updateSurfaceDataInfo: (surface) =>
      set((state) => {
        const attributeKey = state.layerStyles[WELL_SPOTS].color;
        const surfaceDataInfo = getCompressedDataInfo(
          surface,
          attributeKey,
          COLOR_BY_ATTRIBUTE_INFO
        );
        const surfaceByParentWellID = transformWellStylingDataToObject(
          surfaceDataInfo.data,
          [STYLING_API_KEY_MAPPER[WELL_SPOTS].dataKey],
          attributeKey
        );

        return {
          compressedSurfaceData: surface,
          surfaceWellSpotValues: {
            ...surfaceDataInfo,
            data: surfaceByParentWellID,
          },
        };
      }),

    updateBottomDataInfo: (bottom) =>
      set((state) => {
        const attributeKey = state.layerStyles[WELL_SPOTS].color;
        const bottomDataInfo = getCompressedDataInfo(
          bottom,
          attributeKey,
          COLOR_BY_ATTRIBUTE_INFO
        );
        const bottomByBottomWellboreID = transformWellStylingDataToObject(
          bottomDataInfo.data,
          [STYLING_API_KEY_MAPPER[BOTTOM_WELL_SPOTS].dataKey],
          attributeKey
        );

        return {
          compressedBottomData: bottom,
          bottomWellSpotValues: {
            ...bottomDataInfo,
            data: bottomByBottomWellboreID,
          },
        };
      }),

    updatePartitionMinMax: () =>
      set((state) => {
        const isSurfaceSelected = useMapStore.getState().layers[0].isSelected;
        const isBottomOrWellPathSelected =
          useMapStore.getState().layers[1].isSelected ||
          useMapStore.getState().layers[2].isSelected;

        const {
          min: minSurface,
          max: maxSurface,
          hasNull: hasNullSurface,
        } = state.surfaceWellSpotValues;
        const {
          min: minBottom,
          max: maxBottom,
          hasNull: hasNullBottom,
        } = state.bottomWellSpotValues;

        let min = null;
        let max = null;
        let hasNull = false;
        if (isSurfaceSelected && isBottomOrWellPathSelected) {
          min = minBottom;
          max = maxSurface;
          hasNull = hasNullSurface || hasNullBottom;
        } else if (isSurfaceSelected) {
          min = minSurface;
          max = maxSurface;
          hasNull = hasNullSurface;
        } else if (isBottomOrWellPathSelected) {
          min = minBottom;
          max = maxBottom;
          hasNull = hasNullBottom;
        }

        return {
          partitionMinMax: { min, max, hasNull },
        };
      }),
  };
};

export { resetWellColorSlice, wellColorSlice };
