import { FC, SVGProps } from "react";

interface FirstProdMonthIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const FirstProdMonthIcon: FC<FirstProdMonthIconProps> = ({
  width = "12",
  height = "11",
  fill = "none",
  pathFill = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 11"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.4395 1.3125H9.37695V0H8.62695V1.3125H4.12695V0H3.37695V1.3125H1.31445C1.16532 1.31267 1.02235 1.37199 0.916897 1.47744C0.811446 1.5829 0.752127 1.72587 0.751953 1.875V9.75C0.752127 9.89913 0.811446 10.0421 0.916897 10.1476C1.02235 10.253 1.16532 10.3123 1.31445 10.3125H11.4395C11.5886 10.3123 11.7316 10.253 11.837 10.1476C11.9425 10.0421 12.0018 9.89913 12.002 9.75V1.875C12.0018 1.72587 11.9425 1.5829 11.837 1.47744C11.7316 1.37199 11.5886 1.31267 11.4395 1.3125ZM11.252 9.5625H1.50195V2.0625H3.37695V3H4.12695V2.0625H8.62695V3H9.37695V2.0625H11.252V9.5625Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default FirstProdMonthIcon;
