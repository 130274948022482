import { create } from "zustand";

import { UWISearchStore } from "../../../types/panels/searchPanel/uwiSearch/commonUwiSearchSlice";

// import { ExportCardStore } from "../../types/exports/export-card/exportCardState";
import {
  commonUwiSlice,
  fileUwiSearch,
  pastedUwiSearch,
  resetUwis,
} from "./searchUWISlice";

const useUWISearchStore = create<UWISearchStore>()((...a) => ({
  ...commonUwiSlice(...a),
  ...fileUwiSearch(...a),
  ...pastedUwiSearch(...a),
  ...resetUwis(...a),
}));

export default useUWISearchStore;
