import { FC, useEffect, useState } from "react";

import {
  CheckCircleOutline as CheckCircleOutlineIcon,
  ChevronLeft as ChevronLeftIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";

import { Button as ClButton } from "component-library";

import { WellData } from "../../types/common/wells";

import { COUNTRY } from "../../constants/constants";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useStore from "../../store/useStore.js";

import R360Form from "../form/R360Form";

const R360: FC = () => {
  const [uwis, setUwis] = useState<string[]>([]);

  const openR360Modal = useExportCardStore((state) => state.openR360Modal);
  const updateOpenR360modal = useExportCardStore(
    (state) => state.updateOpenR360modal
  );

  const { byWellHeaderPermitKey } = useDataGridStore(
    (state) => state.allWellSelectors
  );

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const allGridData = useDataGridStore((state) => state.allGridData);

  const updateToastMessage = useStore((state) => state.updateToastMessage);
  const updateToastProps = useStore((state) => state.updateToastProps);

  const openR360Page = () => {
    const form = document.getElementById("searchFormPost") as HTMLFormElement;
    form.submit();

    updateToastProps({
      vertical: "top",
      horizontal: "right",
      closeButton: true,
      severity: "success",
    });

    updateToastMessage(
      <span>
        <CheckCircleOutlineIcon
          fontSize="small"
          style={{ marginRight: "10px" }}
        />
        {`Successfully added the selected (${uwis.length}) Wells to R360 search.`}
      </span>
    );

    updateOpenR360modal(false);
  };

  const getUWIByCountry = (well: WellData) => {
    if (well && well.UWI) {
      if (well.Country === COUNTRY.US) {
        return well.UWI.substring(0, 10);
      }
      return well.UWI;
    }
  };

  useEffect(() => {
    if (selectedGridDataKeys.length) {
      const newUWIs: string[] = [];

      selectedGridDataKeys.forEach((wellHeaderKey) => {
        const gridData = byWellHeaderPermitKey[wellHeaderKey];
        const uwi = getUWIByCountry(gridData);
        if (gridData && uwi) newUWIs.push(uwi);
      });

      setUwis(newUWIs);
    } else if (allGridData.length) {
      const newUWIs: string[] = [];
      allGridData.forEach((data) => {
        const uwi = getUWIByCountry(data);
        if (uwi) newUWIs.push(uwi);
      });

      setUwis(newUWIs);
    }
  }, [selectedGridDataKeys, allGridData]);

  return (
    <>
      <Dialog
        open={openR360Modal}
        onClose={() => updateOpenR360modal(false)}
        PaperProps={{
          className:
            "r360-modal-container tgsccl-system-color-palette backgrounds primary",
        }}
      >
        <DialogTitle className="r360-modal-header">
          <Typography component={"p"} className="modal-header">
            Export to R360
          </Typography>
          <IconButton onClick={() => updateOpenR360modal(false)}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Typography component={"p"} className="modal-content-text">
            {`Search for these (${uwis.length} Wells) in R360?`}
          </Typography>
          <R360Form uwis={uwis} />
        </DialogContent>
        <DialogActions>
          <Button
            variant="text"
            className="cancel-button"
            onClick={() => updateOpenR360modal(false)}
          >
            <ChevronLeftIcon /> Cancel
          </Button>
          <ClButton
            type={"primary"}
            text={"OPEN IN NEW WINDOW"}
            state={"enabled"}
            onClick={openR360Page}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default R360;
