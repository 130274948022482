import { useEffect, useMemo, useState } from "react";

import {
  ChartModeView,
  ChartType,
} from "../../types/charts/chartType/chartType";

import { CHART_MODE_VIEW } from "../../constants/charts/chartModeView";

import useChartStore from "../../store/chart/chartStore";
import useModularityStore from "../../store/modularity/modularityStore";

interface Props {
  chartMode: ChartModeView;
  chartId: string;
  chartType: ChartType;
}
const useAnalysisChartRefetch = ({ chartMode, chartId, chartType }: Props) => {
  const modules = useModularityStore((state) => state.modules);
  const getDataFlag = useChartStore((state) => state.chartDataRefetchFlag);
  const isFullScreenAnalysisPane = useModularityStore(
    (state) => state.isFullScreenAnalysisPane
  );
  const isFullScreenIndividualChart = useModularityStore(
    (state) => state.isFullScreenIndividualChart
  );
  const [refetch, setRefetch] = useState<boolean>(false);

  const shouldPreventRefetch = useMemo(() => {
    return (
      (chartMode === CHART_MODE_VIEW.DEFAULT &&
        (isFullScreenAnalysisPane || isFullScreenIndividualChart)) ||
      (chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS &&
        isFullScreenIndividualChart)
    );
  }, [chartMode, isFullScreenIndividualChart, isFullScreenAnalysisPane]);

  useEffect(() => {
    if (shouldPreventRefetch) {
      return;
    }

    const findModule = modules.find((module) => module.module === chartId);

    if (findModule) {
      setRefetch(findModule.refetch);
    }
  }, [modules, shouldPreventRefetch]);

  useEffect(() => {
    if (shouldPreventRefetch) {
      return;
    }

    if (getDataFlag.chartId === chartId && getDataFlag.refetch) {
      setRefetch(true);
    }
  }, [getDataFlag, shouldPreventRefetch]);

  const updateRefetch = (flag: boolean) => {
    setRefetch(flag);
  };

  return { refetch, updateRefetch };
};

export default useAnalysisChartRefetch;
