import { useState } from "react";

import { SearchRequestPayload } from "../types/common/api";
import { BaseSearchCriteria, SearchCriteria } from "../types/common/search";
import { WellData } from "../types/common/wells";
import { VisibleColumns } from "../types/grid/export";
import { GetGridDataResponse } from "../types/grid/useGridData";

import config from "../configs/appSettings";

import * as attribute from "../constants/attributes";
import { EXPORT_TYPE } from "../constants/constants";

import useDataGridStore from "../store/grid/dataGridStore";
import useUWISearchStore from "../store/search/uwiSearch/searchUWIStore";

import { convertStringToDate } from "../utils/charts/aggregator";
import { changeKey } from "../utils/datagrid/columns";

import { callServiceAPI } from "../action/callServiceAPI";
import useSearchRequest from "./common/useSearchRequest";
import useUnitOfMeasure from "./common/useUnitOfMeasure";
import useExportToExcel from "./exports/useExportToExcel";
import useUwiFileUpload from "./search/useUwiFileUpload";

const searchUrl = `${config.endpoints.wellService}api/wells/search/`;

const useAllWellDataForExport = () => {
  const pageLimit = config.searchPageLimit;
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const { buildSearchRequestByParam } = useSearchRequest();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const updateIsExportToGridLoading = useDataGridStore(
    (state) => state.updateIsExportToGridLoading
  );
  const gridFilteredCount = useDataGridStore(
    (state) => state.gridFilteredCount
  );
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const sortPayload = useDataGridStore((state) => state.sortPayload);
  const { exportToExcel } = useExportToExcel();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const getAllWellDataForExport = async (
    searchCriteria: SearchCriteria,
    columns: string[]
  ) => {
    const allWellData: WellData[] = [];

    const totalIterations = searchCriteria.gridTotalCount
      ? Math.ceil(searchCriteria.gridTotalCount / pageLimit)
      : 0;

    const requestBodySearchCriteria: BaseSearchCriteria = {
      drawnPolygons: searchCriteria.drawnPolygons,
      shapeId: searchCriteria.shapeId,
      currentBounds: searchCriteria.currentBounds,
      fileId: searchCriteria.fileId,
      searchedUWIs: searchCriteria.searchedUWIs,
      filters: searchCriteria.filters,
    };

    const searchRequestBody = buildSearchRequestByParam(
      requestBodySearchCriteria
    );

    const body: SearchRequestPayload = {
      ...searchRequestBody,
      offset: 0,
      pageLimit: pageLimit,
      ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
    };

    if (sortPayload.length) {
      body.sort = sortPayload;
    }

    if (columns.length) {
      const searchColumn = columns.map((col) => {
        return changeKey(col);
      });
      body.columns = searchColumn;
    }

    try {
      for (let batchCount = 0; batchCount < totalIterations; batchCount++) {
        body.offset = batchCount * pageLimit;

        const response = await callServiceAPI<GetGridDataResponse>(
          searchUrl,
          body,
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          isFromUploadedUWIFile
        );

        if (response && "data" in response) {
          allWellData.push(...response.data.wells);
        }
      }

      return { data: allWellData, isSuccess: true };
    } catch (error) {
      return { data: [], isSuccess: false };
    }
  };

  const exportDataToExcel = (data: WellData[], headers: VisibleColumns[]) => {
    const mappedData = data.map((well: any) => {
      const wellObj: { [key: string]: any } = {};
      headers.forEach((header) => {
        if (attribute.DATE_TYPE_FIELDS.includes(header.field)) {
          wellObj[header.field] = convertStringToDate(well[header.field]);
        } else {
          wellObj[header.field] = well[header.field];
        }
      });
      return wellObj;
    });

    const extractedExcelHeader = headers.map((col) => col.header) as string[];

    exportToExcel(
      mappedData,
      "TGS_Grid",
      extractedExcelHeader,
      EXPORT_TYPE.DATA_GRID
    );
  };

  const exportAllWellDataToExcel = (
    searchCriteria: SearchCriteria,
    customHeaders: VisibleColumns[]
  ) => {
    try {
      setIsLoading(true);
      updateIsExportToGridLoading(true);
      const columns = customHeaders.map((col) => col.field);
      getAllWellDataForExport(searchCriteria, columns).then((data) => {
        if (data.isSuccess) {
          exportDataToExcel(data.data, customHeaders);
        } else {
          setIsError(true);
        }
        setIsLoading(false);
        updateIsExportToGridLoading(false);
      });
    } catch (error) {}
  };

  const exportDataAsExcelCallback = (
    allWells: WellData[],
    visibleColumns: VisibleColumns[]
  ) => {
    if (gridFilteredCount <= pageLimit) {
      //if search result is less than 10k, then use allWells data for export
      const gridDataForExport = Object.assign([], allWells);
      exportDataToExcel(gridDataForExport, visibleColumns);
    } else {
      //do batch data call if filtered result is greater than 10k
      if (searchCriteria) {
        const allWellsDataForExportReq: SearchCriteria = Object.assign(
          {},
          searchCriteria
        );
        allWellsDataForExportReq.gridTotalCount = gridFilteredCount;

        exportAllWellDataToExcel(allWellsDataForExportReq, visibleColumns);
      }
    }
  };

  return {
    isLoading,
    isError,
    exportDataAsExcelCallback,
  };
};

export default useAllWellDataForExport;
