import { LayerObject } from "../../../types/map/hookTemp";
import { SavedMapConfiguration } from "../../../types/panels/savedSearchPanel/savedSearchData";

import {
  LAYER_OLD_TO_NEW_NAME_MAPPER,
  MAP_SEARCH_PIPELINES,
} from "../../../constants/constants";
import {
  defaultLayerStyles,
  defaultLayers,
} from "../../../constants/defaultMapSettingsStoreData";
import {
  CARBON_STORAGE_LABEL,
  DEFAULT_CARBON_STORAGE_BASINS_INFO,
} from "../../../constants/map/carbonStorage";
import { DARK_GRAY } from "../../../constants/map/layers";
import { DEFAULT_STRAT_MODELS_BASINS_INFO } from "../../../constants/map/stratigraphicModels";

import useMapStore from "../../../store/map/mapStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import { clone } from "../../../utils";
import useSagaMap from "../../map/useSagaMap";

const useLoadMapConfigs = () => {
  const layers = useMapStore((state) => state.layers);
  const setLayers = useMapStore((state) => state.setLayers);
  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const baseMapSelected = useMapStore((state) => state.baseMapSelected);
  const setBaseMapSelected = useMapStore((state) => state.setBaseMapSelected);

  const loadWellStyles = useMapSettingsStore((state) => state.loadWellStyles);
  const loadLayerStyles = useMapSettingsStore((state) => state.loadLayerStyles);
  const toggleApplyWellColorToPermits = useMapSettingsStore(
    (state) => state.toggleApplyWellColorToPermits
  );
  const resetLayerStylingStates = useMapSettingsStore(
    (state) => state.resetLayerStylingStates
  );
  const updateMapOverlayOpened = useMapSettingsStore(
    (state) => state.updateMapOverlayOpened
  );
  const updateLayerLegends = useMapSettingsStore(
    (state) => state.updateLayerLegends
  );
  const carbonStorageInfo = useMapSettingsStore(
    (state) => state.carbonStorageInfo
  );
  const updateCarbonStorageInfo = useMapSettingsStore(
    (state) => state.updateCarbonStorageInfo
  );
  const stratModelsInfo = useMapSettingsStore((state) => state.stratModelsInfo);
  const updateStratModelsInfo = useMapSettingsStore(
    (state) => state.updateStratModelsInfo
  );

  const { updateBaseMap } = useSagaMap();

  const loadMapConfigs = (mapConfig?: SavedMapConfiguration) => {
    if (!mapConfig) {
      if (baseMapSelected !== DARK_GRAY.id) {
        setBaseMapSelected(DARK_GRAY.id);
        updateBaseMap(DARK_GRAY.id);
      }
      // update to default if no saved mapsettings
      setLayers(clone(defaultLayers));
      loadLayerStyles(clone(defaultLayerStyles));
      updateMapOverlayOpened([]);
      updateCarbonStorageInfo({
        isSelected: false,
        basins: DEFAULT_CARBON_STORAGE_BASINS_INFO,
      });
      updateStratModelsInfo({
        isSelected: false,
        basins: DEFAULT_STRAT_MODELS_BASINS_INFO,
      });
      return;
    }

    resetLayerStylingStates();
    const {
      layers: savedLayers,
      baseMap,
      wellStyles,
      layerStyles,
      applyWellColorToPermits,
      mapOverlayOpened,
      layerLegendsOpened: savedLayerLegendsOpened,
      carbonStorageInfo: savedCarbonStorageInfo,
      stratModelsInfo: savedStratModelsInfo,
    } = mapConfig;
    if (baseMap && baseMap !== baseMapSelected) {
      setBaseMapSelected(baseMap);
      updateBaseMap(baseMap);
    } else if (!baseMap && baseMapSelected !== DARK_GRAY.id) {
      // if a base map is not saved and
      // saved base map != currently selected basemap
      setBaseMapSelected(DARK_GRAY.id);
      updateBaseMap(DARK_GRAY.id);
    }

    // remove all currently toggled on formations/layers
    // before loading the formations/layers
    // for carbon storage and stratigraphic models
    Object.values(carbonStorageInfo.basins).map((basin) => {
      if (basin.isSelected) toggleLayer(basin.formationName, false);
    });
    Object.values(stratModelsInfo.basins).map((basin) => {
      if (basin.isSelected) toggleLayer(basin.formationName, false);
    });

    const layerLegendsOpened: string[] = [];
    let newCarbonStorageInfo = {
      isSelected: false,
      basins: DEFAULT_CARBON_STORAGE_BASINS_INFO,
    };
    if (savedCarbonStorageInfo) {
      newCarbonStorageInfo = {
        isSelected: savedCarbonStorageInfo.isSelected,
        basins: {
          ...carbonStorageInfo.basins,
          ...savedCarbonStorageInfo.basins,
        },
      };
    }
    const hasToggledOn = Object.values(newCarbonStorageInfo.basins).some(
      (basinInfo) => basinInfo.isSelected
    );
    if (newCarbonStorageInfo.isSelected && hasToggledOn) {
      layerLegendsOpened.push(CARBON_STORAGE_LABEL);
    }
    updateCarbonStorageInfo(newCarbonStorageInfo);

    if (savedStratModelsInfo) {
      updateStratModelsInfo({
        isSelected: savedStratModelsInfo.isSelected,
        basins: {
          ...stratModelsInfo.basins,
          ...savedStratModelsInfo.basins,
        },
      });
    } else {
      updateStratModelsInfo({
        isSelected: false,
        basins: DEFAULT_STRAT_MODELS_BASINS_INFO,
      });
    }

    if (layers) {
      // Only update the isSelected status of each layers
      // based on the loaded saved search data
      const modifiedLayers: LayerObject[] = clone(layers);
      savedLayers.forEach((sLayer) => {
        const layerIndex = modifiedLayers.findIndex(
          (mLayer) =>
            mLayer.name === sLayer.name ||
            mLayer.name === LAYER_OLD_TO_NEW_NAME_MAPPER[sLayer.name]
        );
        if (layerIndex > -1) {
          modifiedLayers[layerIndex].isSelected = sLayer.isSelected;
        }

        if (sLayer.name === MAP_SEARCH_PIPELINES && sLayer.isSelected) {
          layerLegendsOpened.push(MAP_SEARCH_PIPELINES);
        }
      });
      setLayers(modifiedLayers);
    } else {
      setLayers(clone(defaultLayers));
    }

    // update to default if no saved data on map settings
    if (wellStyles) {
      loadWellStyles(wellStyles);
    } else {
      loadLayerStyles(layerStyles || clone(defaultLayerStyles));
    }

    if (applyWellColorToPermits) {
      toggleApplyWellColorToPermits(applyWellColorToPermits);
    }
    updateMapOverlayOpened(mapOverlayOpened ?? []);
    updateLayerLegends(savedLayerLegendsOpened ?? layerLegendsOpened);
  };

  return {
    loadMapConfigs,
  };
};

export default useLoadMapConfigs;
