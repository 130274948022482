import { useMemo } from "react";

import { UnitOfMeasure } from "../../types/common/appMode/appMode";

import config from "../../configs/appSettings";

import { UNIT_OF_MEASURE } from "../../constants/appHeader/unitOfMeasure";

import useAppModeStore from "../../store/common/appModeStore";

const useUnitOfMeasure = () => {
  const UoMValueOnSelection = useAppModeStore(
    (state) => state.UoMValueOnSelection
  );
  const UoMValueOnSearch = useAppModeStore((state) => state.UoMValueOnSearch);

  const isMetricOnSelection = useMemo(
    () =>
      UoMValueOnSelection === UNIT_OF_MEASURE.METRIC.key && config.isUoMEnabled,
    [UoMValueOnSelection]
  );

  const isMetricOnSearch = useMemo(
    () =>
      UoMValueOnSearch === UNIT_OF_MEASURE.METRIC.key && config.isUoMEnabled,
    [UoMValueOnSearch]
  );

  const getUoMLabel = (unit: UnitOfMeasure) => {
    return unit === UNIT_OF_MEASURE.IMPERIAL.key
      ? UNIT_OF_MEASURE.IMPERIAL.label
      : UNIT_OF_MEASURE.METRIC.label;
  };

  return { isMetricOnSelection, isMetricOnSearch, getUoMLabel };
};

export default useUnitOfMeasure;
