import { FormControlLabel, Switch, Typography } from "@mui/material";

import { NormalizedByLateralLengthCheckboxProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

const NormalizedByLateralLengthCheckbox = ({
  chartShowByNormalizedLateralLength,
  handleChange,
}: NormalizedByLateralLengthCheckboxProps) => {
  return (
    <FormControlLabel
      control={
        <Switch
          checked={chartShowByNormalizedLateralLength}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            handleChange(e);
          }}
        />
      }
      label={
        <Typography sx={{ fontSize: "14px", color: "white" }}>
          Normalize by Lateral Length
        </Typography>
      }
    />
  );
};

export default NormalizedByLateralLengthCheckbox;
