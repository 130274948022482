import { Projection } from "ol/proj";

const GRID_SET_NAME = "EPSG:900913";

const projection = new Projection({
  code: GRID_SET_NAME,
  units: "m",
  axisOrientation: "neu",
});

const params = (layerName: string, format: string) => {
  return {
    REQUEST: "GetTile",
    SERVICE: "WMTS",
    VERSION: "1.0.0",
    LAYER: layerName,
    TILEMATRIX: GRID_SET_NAME + ":{z}",
    TILEMATRIXSET: GRID_SET_NAME,
    FORMAT: format,
    TILECOL: "{x}",
    TILEROW: "{y}",
  };
};

export { projection, params };
