import { Typography, styled } from "@mui/material";

export const AccordionHeadingText = styled(Typography)({
  fontSize: 14,
  fontWeight: 400,
  color: "#fff",
});

export const AccordionContent = styled(Typography)({
  fontSize: 14,
  fontStyle: "normal",
  fontWeight: 400,
  color: "rgba(255, 255, 255, 0.70)",
  marginBottom: 10,
});

export const AccordionSubContent = styled(Typography)<{ isItalic?: boolean }>(
  ({ isItalic }) => ({
    marginLeft: 16,
    marginBottom: 10,
    fontSize: 14,
    fontStyle: isItalic ? "italic" : "normal",
    fontWeight: 400,
    color: "rgba(255, 255, 255, 0.70)",
  })
);

export const SpanText = styled("span")({
  fontStyle: "normal",
});

export const AccordionImageTitle = styled(Typography)({
  fontSize: 13,
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "22px",
  textTransform: "uppercase",
  color: "#fff",
  marginTop: 25,
});

export const AccordionImage = styled("img")<{ width?: number | string }>(
  ({ width = 400 }) => ({
    height: "auto",
    width,
    marginTop: 10,
    marginBottom: 40,
  })
);

export const FlexAccordionImage = styled("img")({
  height: "auto",
  width: "49%",
  marginTop: 10,
  marginBottom: 40,
});

export const CustomTypography = styled(Typography)({
  color: "#fff",
  fontSize: 14,
  fontWeight: 400,
  fontStyle: "normal",
  marginBottom: 10,
});

export const FlexContainer = styled("div")({
  display: "flex",
  justifyContent: "space-evenly",
});
