import { Layout, Legend } from "plotly.js";

import { GunBarrelData } from "../../../types/panels/wellPanel/gunBarrelType";

export const buildGunBarrelLayout = (
  gunBarrelWidth: number,
  gunBarrelHeight: number,
  gunBarrelFontSize: number,
  gunBarrelData: GunBarrelData,
  isChartExport: boolean,
  axisUnit: string
) => {
  const layout: Partial<Layout> = {
    autosize: true,
    margin: {
      t: isChartExport ? 60 : 20,
      ...(!isChartExport && { r: 20 }),
    },
    // zeroline: "false",
    datarevision: 0,
    showlegend: true,
    legend: getFormattedLegend(gunBarrelFontSize),
    width: gunBarrelWidth,
    height: gunBarrelHeight,
    paper_bgcolor: "rgba(0,0,0,0)",
    plot_bgcolor: "#272727",
    font: {
      family: "Roboto, Arial",
      size: gunBarrelFontSize,
      color: "rgba(255, 255, 255, 0.7)",
    },
    xaxis: {
      fixedrange: true,
      showgrid: true,
      gridwidth: 3,
      ticks: "outside",
      tickwidth: 1.5,
      title: {
        text: `Horizontal Distance From Target Well (${axisUnit})`,
        standoff: 10,
        font: {
          size: 12,
          color: "rgba(117, 117, 117, 1)",
        },
      },
      showticklabels: true,
      range: gunBarrelData.xRange.range,
      showline: true,
      linewidth: 1,
      mirror: true,
      tickformat: ",.0f",
      zerolinecolor: "2a2a2a",
      zerolinewidth: 3,
      ticklen: 15,
    },
    yaxis: {
      zeroline: false,
      fixedrange: true,
      automargin: true,
      gridwidth: 3,
      title: {
        text: `TVD (${axisUnit})`,
        font: {
          color: "rgba(117, 117, 117, 1)",
          size: 12,
        },
        standoff: isChartExport ? 20 : 12,
      },
      showgrid: true,
      range: [gunBarrelData.yRange.range[1], gunBarrelData.yRange.range[0]],
      showline: true,
      linewidth: 1,
      mirror: true,
      ticks: "outside",
      tickwidth: 1.5,
      tickformat: ",.0f",
    },
    annotations: gunBarrelData.annotations,
  };

  return layout;
};

const getFormattedLegend = (gunBarrelFontSize: number) => {
  const fn = (gunBarrelFontSize: number) => {
    switch (gunBarrelFontSize) {
      case 16:
        return {
          y: -0.8,
          yanchor: "right",
          entrywidth: 98,
          font: {
            size: 16,
            color: "#838383",
          },
        };
      case 14:
        return {
          y: -1.5,
          entrywidth: 73,
          font: {
            size: 14,
            color: "#838383",
          },
        };
      case 12:
      default:
        return {
          y: -1,
          font: {
            size: 12,
            color: "#838383",
          },
        };
    }
  };

  return {
    orientation: "v",
    x: -0.15,
    itemclick: false,
    itemdoubleclick: false,
    ...fn(gunBarrelFontSize),
  } as Partial<Legend>;
};
