import { GridFilterModel } from "@mui/x-data-grid-premium";

import { cloneDeep } from "lodash";

import {
  ChartAxisDataFields,
  ChartDataFields,
  IChartTypeInfo,
} from "../../../types/charts/chartType/chartType";
import { UnitOfMeasure } from "../../../types/common/appMode/appMode";
import { DefaultLayout } from "../../../types/modularity/layout";
import {
  SavedChartAxisDataFields,
  SavedDashboardChartConfiguration,
  SavedGridConfiguration,
  SavedModulesConfiguration,
} from "../../../types/panels/savedSearchPanel/savedSearchData";

import { UNIT_OF_MEASURE } from "../../../constants/appHeader/unitOfMeasure";
import {
  CHART_AXIS,
  CHART_GROUP_BY_TYPE,
  CHART_TYPES,
} from "../../../constants/charts/charts";
import { PRODUCTION_DATA_FIELDS_LIST } from "../../../constants/charts/productionPlotDataFields";
import {
  SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_PERMIT_Y_AXIS_ATTRIBUTES,
} from "../../../constants/charts/scatterPlotDataFields";
import { PRODUCTION_PLOT, TYPE_CURVE } from "../../../constants/constants";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useChartStore from "../../../store/chart/chartStore";
import useAppModeStore from "../../../store/common/appModeStore";
import useDataGridStore from "../../../store/grid/dataGridStore";
import useModularityStore from "../../../store/modularity/modularityStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import { getTopDataField } from "../../../utils/charts/sorting";
import { fetchColumnsList } from "../../../utils/common/fetchColumnsList";
import {
  changeColumnFilterAccordingToType,
  defaultColumnsOrder,
  defaultFilterModel,
  defaultSortModel,
  filterRequestFormat,
  getColumnKeyNames,
  sortRequestFormat,
} from "../../../utils/datagrid";

import useScatterPlotChartReset from "../../charts/useScatterPlotChartReset";
import useLoadMapConfigs from "./useLoadMapConfigs";
import useSavedSearchDataReMapping from "./useSavedSearchDataReMapping";

const useLoadConfigs = () => {
  const columnsFilterType = useDataGridStore(
    (state) => state.columnsFilterType
  );
  const columnVisibilityModel = useDataGridStore(
    (state) => state.columnVisibilityModel
  );
  const updateFilterModel = useDataGridStore(
    (state) => state.updateFilterModel
  );
  const updateSortModel = useDataGridStore((state) => state.updateSortModel);
  const updateColumnsOrder = useDataGridStore(
    (state) => state.updateColumnsOrder
  );
  const updateAllColumnsFilterType = useDataGridStore(
    (state) => state.updateAllColumnsFilterType
  );
  const updateColumnVisibilityModel = useDataGridStore(
    (state) => state.updateColumnVisibilityModel
  );
  const updateColumnsPayload = useDataGridStore(
    (state) => state.updateColumnsPayload
  );

  const updateChartData = useChartStore((state) => state.updateChartData);
  const loadSavedSearchDashboardData = useChartStore(
    (state) => state.loadSavedSearchDashboardData
  );
  const revertDashboardDataAttributeDefaultValue = useChartStore(
    (state) => state.revertDashboardDataAttributeDefaultValue
  );

  const toggleAnalysisPanel = usePanelsStore(
    (state) => state.toggleAnalysisPanel
  );

  const updateModules = useModularityStore((state) => state.updateModules);
  const updateChartDefaultLayout = useModularityStore(
    (state) => state.updateChartDefaultLayout
  );
  const updateChartFullScreenLayout = useModularityStore(
    (state) => state.updateChartFullScreenLayout
  );

  //Unit of Measure
  const UoMValueOnSearch = useAppModeStore((state) => state.UoMValueOnSearch);
  const updateUoMValueOnSelection = useAppModeStore(
    (state) => state.updateUoMValueOnSelection
  );
  const updateUoMValueOnSearch = useAppModeStore(
    (state) => state.updateUoMValueOnSearch
  );
  const updateUoMAlertState = useAppModeStore(
    (state) => state.updateUoMAlertState
  );

  const { getScatterPlotDefault } = useScatterPlotChartReset({});
  const { loadMapConfigs } = useLoadMapConfigs();
  const { mapSavedGridConfig, mapSavedFilterModel } =
    useSavedSearchDataReMapping();

  const loadGridConfigs = (gridConfig?: SavedGridConfiguration) => {
    if (!gridConfig) return {};

    const { filterModel, sortModel, columnsModel, columnsOrder } =
      mapSavedGridConfig(gridConfig);

    let newFilterModel = filterModel?.items?.length
      ? filterModel
      : defaultFilterModel;

    //check if filterModel contains object types from the old MUI Datagrid Version (v5)
    //if true, change the outdated keys ... (refer: https://mui.com/x/migration/migration-data-grid-v5/#filtering)
    newFilterModel.items = newFilterModel.items.map((val) => {
      if ("columnField" in val && "operatorValue" in val) {
        return {
          id: val.id,
          field: val.columnField,
          operator: val.operatorValue,
          value: val.value,
        };
      } else {
        return val;
      }
    });

    // TODO: Remove "as GridFilterModel"
    newFilterModel = mapSavedFilterModel(newFilterModel as GridFilterModel);
    updateFilterModel(newFilterModel);

    //update columns filter type
    const newColumnsFilterType = changeColumnFilterAccordingToType(
      filterModel as GridFilterModel,
      columnsFilterType
    );
    updateAllColumnsFilterType(newColumnsFilterType);

    const newColumnsOrder = columnsOrder?.length
      ? columnsOrder
      : defaultColumnsOrder;

    const missingColumns = defaultColumnsOrder.filter(
      (e) => !newColumnsOrder.includes(e)
    );

    if (missingColumns.length) {
      const mergedColumns = newColumnsOrder.concat(missingColumns);
      updateColumnsOrder(mergedColumns);
    } else {
      updateColumnsOrder(newColumnsOrder);
    }

    const newSortModel = sortModel?.length ? sortModel : defaultSortModel;
    updateSortModel(newSortModel);

    const newColumnModel = columnsModel ?? columnVisibilityModel;
    const columnsList = fetchColumnsList(columnVisibilityModel, newColumnModel);

    updateColumnVisibilityModel(columnsList);
    updateColumnsPayload(getColumnKeyNames(newColumnModel));

    return {
      filters: filterRequestFormat(newFilterModel as GridFilterModel),
      sort: sortRequestFormat(newSortModel),
      columns: getColumnKeyNames(newColumnModel),
    };
  };

  const loadModulesConfigs = (modulesConfig?: SavedModulesConfiguration) => {
    if (!modulesConfig) {
      updateChartDefaultLayout([]);
      updateChartFullScreenLayout([]);
      updateModules([]);
      updateChartData([]);
      toggleAnalysisPanel(false);
      return;
    }

    const { layout, modules, chartData } = modulesConfig;
    let toggleAnalysisPane = false;

    if ("default" in layout && "fullScreen" in layout) {
      toggleAnalysisPane = Boolean(layout.default.length);
      updateChartDefaultLayout(layout.default || []);
      updateChartFullScreenLayout(layout.fullScreen || []);
    } else {
      //For old saved searches
      toggleAnalysisPane = Boolean(layout?.length);

      const defaultLayout: DefaultLayout[] = [];

      const fullScreenLayout: DefaultLayout[] = [];

      layout.forEach((chartLayout) => {
        const fullScreenLayoutObj: DefaultLayout = {
          type: chartLayout.type,
          i: chartLayout.i,
          x: 0,
          y: fullScreenLayout.length * 2,
          w: 9,
          h: 2,
          minW: 4,
          maxW: 9,
          minH: 2,
        };

        fullScreenLayout.push(fullScreenLayoutObj);

        const defaultLayoutObj: DefaultLayout = {
          type: chartLayout.type,
          i: chartLayout.i,
          x: 0,
          y: fullScreenLayout.length * 2,
          w: 2.88,
          h: 2,
          minW: 2.88,
          maxW: 2.88,
          minH: 2,
        };
        defaultLayout.push(defaultLayoutObj);
      });

      updateChartDefaultLayout(defaultLayout);
      updateChartFullScreenLayout(fullScreenLayout);
    }

    toggleAnalysisPanel(toggleAnalysisPane);
    updateModules(modules || []);
    const copiedChartData = cloneDeep(chartData);
    const mappedData = copiedChartData.map((data) => {
      switch (data.chartType) {
        case CHART_TYPES.PRODUCTION_PLOT:
          data.objectType = CHART_TYPES.PRODUCTION_PLOT;
          break;
        case CHART_TYPES.TYPE_CURVE:
          data.objectType = CHART_TYPES.TYPE_CURVE;
          break;
        case CHART_TYPES.SCATTER_PLOT:
          data.objectType = CHART_TYPES.SCATTER_PLOT;
          break;
      }

      if (
        data.objectType === CHART_TYPES.PRODUCTION_PLOT ||
        data.objectType === CHART_TYPES.TYPE_CURVE
      ) {
        const savedDataFieldList =
          data.groupBy === CHART_GROUP_BY_TYPE.DEFAULT
            ? data.chartDisplayedDataFields
            : getTopDataField(
                data.chartDisplayedDataFields,
                PRODUCTION_DATA_FIELDS_LIST
              );

        data.chartDisplayedDataFields = (
          savedDataFieldList as ChartDataFields[]
        ).reduce((result: ChartDataFields[], field: ChartDataFields) => {
          if (
            data.chartType === PRODUCTION_PLOT ||
            data.chartType === TYPE_CURVE
          ) {
            const fieldInfo = PRODUCTION_DATA_FIELDS_LIST.find(
              (info) =>
                info.name === field.name &&
                info.accumMethod === field.accumMethod
            );
            if (fieldInfo) result.push(fieldInfo);
          }
          return result;
        }, []);
      } else if (data.objectType === CHART_TYPES.SCATTER_PLOT) {
        data.chartXAxisDataFields =
          SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES.find(
            (dataField) => dataField.name === data.chartXAxisDataFields?.name
          );
        data.chartYAxisDataFields = data.chartYAxisDataFields.reduce(
          (result: ChartAxisDataFields[], field: SavedChartAxisDataFields) => {
            const fieldInfo = SCATTER_PLOT_WELL_PERMIT_Y_AXIS_ATTRIBUTES.find(
              (info) => info.name === field?.name
            );

            if (fieldInfo) {
              fieldInfo.axis = field?.axis ?? CHART_AXIS.LEFT; //backward compatiblity for old save searches to default the selected attributes on left axis
              result.push(fieldInfo);
            }

            return result;
          },
          []
        );

        if (!data.chartAxis) {
          const defaultAxis = getScatterPlotDefault(RECORD_TYPES.WELL)?.axis;
          if (defaultAxis) data.chartAxis = defaultAxis; //backward compatiblity for old save searches to have a default y axis
        }

        if (data.chartColorBy) {
          const dataType = SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES.find(
            (attr) => attr.key === data.chartColorBy?.attributeKey
          )?.dataType;
          data.chartColorBy = {
            ...data.chartColorBy,
            attibuteDataType: dataType,
          };
        }
      }

      return data;
    }) as IChartTypeInfo[];

    updateChartData(mappedData || []);
  };

  const loadDashboardChartConfigs = (
    dashboardConfig?: SavedDashboardChartConfiguration
  ) => {
    if (!dashboardConfig?.dashboardChartData?.length) {
      revertDashboardDataAttributeDefaultValue();
      return;
    }
    loadSavedSearchDashboardData(dashboardConfig.dashboardChartData);
  };

  const loadUnitOfMeasure = (setting: UnitOfMeasure) => {
    const val = setting ?? UNIT_OF_MEASURE.IMPERIAL.key;
    if (UoMValueOnSearch !== val)
      updateUoMAlertState({
        message: "The saved search changed your session's unit of measurement",
        severity: "info",
      });
    updateUoMValueOnSearch(val);
    updateUoMValueOnSelection(val);
  };

  return {
    loadGridConfigs,
    loadModulesConfigs,
    loadMapConfigs,
    loadDashboardChartConfigs,
    loadUnitOfMeasure,
  };
};

export default useLoadConfigs;
