import { FC } from "react";

import { Typography } from "@mui/material";

import { CircularButton } from "component-library";

import { SortableTableLabelPropsType } from "../../../types/information/information";

import { ASC } from "../../../constants/dataCurrency";

const SortableTableLabel: FC<SortableTableLabelPropsType> = ({
  direction,
  label,
  onClick,
}) => {
  return (
    <Typography variant="body1" component="div">
      <div className="label-with-sort">
        {label}
        <CircularButton
          type="small"
          icon={direction === ASC ? "expandLess" : "expandMore"}
          state="enabled"
          className="sort-btn"
          onClick={onClick}
        />
      </div>
    </Typography>
  );
};

export default SortableTableLabel;
