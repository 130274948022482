import { FC, SVGProps } from "react";

interface CLCheckedBoxOutlineIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const CLCheckedBoxOutlineIcon: FC<CLCheckedBoxOutlineIconProps> = ({
  width = "20",
  height = "20",
  fill = "none",
  pathFill = "#000",
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill={fill}
    >
      <path
        fill={pathFill}
        d="M4.5 17C4.0875 17 3.73437 16.8531 3.44062 16.5594C3.14687 16.2656 3 15.9125 3 15.5V4.5C3 4.0875 3.14687 3.73438 3.44062 3.44063C3.73437 3.14688 4.0875 3 4.5 3H15.5C15.9125 3 16.2656 3.14688 16.5594 3.44063C16.8531 3.73438 17 4.0875 17 4.5V15.5C17 15.9125 16.8531 16.2656 16.5594 16.5594C16.2656 16.8531 15.9125 17 15.5 17H4.5ZM4.5 15.5H15.5V4.5H4.5V15.5Z"
      ></path>
    </svg>
  );
};

export default CLCheckedBoxOutlineIcon;
