import { useMemo } from "react";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useRecordType from "../../../customHooks/common/useRecordType";

import { LEGENDS_SYMBOLS } from "./LegendSymbol";

const SymbolList = () => {
  const { searchedRecordType } = useRecordType();

  const symbols = useMemo(() => {
    switch (searchedRecordType) {
      case RECORD_TYPES.WELL:
        return [LEGENDS_SYMBOLS.WellSpotSymbol];
      case RECORD_TYPES.PERMIT:
        return [LEGENDS_SYMBOLS.PermitsSymbol];
      case RECORD_TYPES.WELLS_AND_PERMIT:
        return [LEGENDS_SYMBOLS.WellSpotSymbol, LEGENDS_SYMBOLS.PermitsSymbol];
      default:
        return [LEGENDS_SYMBOLS.WellSpotSymbol];
    }
  }, [searchedRecordType]);

  return (
    <div className="list">
      {symbols.map((symbol, index) => (
        <div key={`symbol-${index}`} className="symbols-container">
          <div>{symbol.symbol}</div>
          <div className="symbols-label">{symbol.text}</div>
        </div>
      ))}
    </div>
  );
};

export default SymbolList;
