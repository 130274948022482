import { StateCreator } from "zustand";

import { ChartsWindowSlice } from "../../types/modularity/store";

const chartWindowSlice: StateCreator<ChartsWindowSlice> = (set, get) => ({
  isFullScreenAnalysisPane: false,
  toggleFullScreenAnalysisPane: (status) =>
    set((state) => ({ isFullScreenAnalysisPane: status })),
  isFullScreenIndividualChart: false,
  toggleFullScreenIndividualChart: (status) =>
    set((state) => ({ isFullScreenIndividualChart: status })),
  fullScreenChartId: undefined,
  updateFullScreenChartId: (chartId) =>
    set(() => ({ fullScreenChartId: chartId })),
});
export { chartWindowSlice };
