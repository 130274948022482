import { StateCreator } from "zustand";

import { MapDrawStates, MapPolygons } from "../../../../types/map/draw/store";

import { resetMapDrawPolygonSlice } from "./mapDrawPolygonSlice";
import { resetMapShapeFileSlice } from "./mapShapeFileSlice";

const sliceResetFns = new Set<() => void>();

const resetMapPolygonsSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialMapPolygonsStates = {
  polygons: [],
};

const mapPolygonSlice: StateCreator<MapDrawStates, [], [], MapPolygons> = (
  set
) => {
  sliceResetFns.add(() => set(initialMapPolygonsStates));

  return {
    ...initialMapPolygonsStates,
    updatePolygons: (polygons) =>
      set(() => ({
        polygons,
        shapeFilePolygons: polygons.filter((p) => p.isShapeFile),
        drawnPolygons: polygons.filter((p) => !p.isShapeFile),
      })),
    resetAllPolygonStates: () => {
      resetMapPolygonsSlice();
      resetMapDrawPolygonSlice();
      resetMapShapeFileSlice();
    },
  };
};

export { mapPolygonSlice, resetMapPolygonsSlice };
