import * as attributes from "../attributes";

export const COLUMN_TYPE_STRING = "string";
export const COLUMN_TYPE_INTEGER = "integer";
export const COLUMN_TYPE_NUMERIC = "numeric";
export const COLUMN_TYPE_DATE = "date";
export const COLUMN_TYPE_BOOLEAN = "boolean";
export const COLUMN_TYPE_BIG_NUMERIC = "bignumeric";
export const COLUMN_TYPE_FLOAT = "float";

export const COLUMN_ACTION_ADD = "add";
export const COLUMN_ACTION_REMOVE = "remove";
export const COLUMN_ACTION_STORED = "stored";
export const COLUMN_ACTION_LIMIT_EXCEEDED = "limit-exceeded";

export const COLUMN_GRID_MAXIMUM = 25;

// soon to remove UWI as required column for well spot
// then add it as required column for well hover
export const REQUIRED_WELL_SPOT_COLUMNS = [
  "UWI",
  "ParentWellID",
  "WellID",
  "PermitID",
  "BottomWellboreID",
  "WellHeaderPermitKey",
];

//only add columns that are required in well hover
export const REQUIRED_WELL_HOVER_COLUMNS = [
  attributes.WELL_NAME.key,
  attributes.WELL_NUMBER.key,
  attributes.WELL_STATUS.key,
  attributes.DISPLAY_FORMATION.key,
  attributes.FIRST_MONTH.key,
  attributes.TOTAL_VERTICAL_DEPTH.key,
  attributes.ULTIMATE_OWNER.key,
  attributes.INTERPRETED_PRODUCING_FORMATION.key,
  attributes.CUM_BOE.key,
  attributes.SURFACE_UWI.key,
  attributes.RECORD_TYPE.key,
  attributes.COUNTRY.key,
];

export const REQUIRED_PERMIT_HOVER_COLUMNS = [
  // Note: permit uses the same column for display formation
  attributes.OPERATOR_NAME.key,
  attributes.PERMIT_NUMBER.key,
  attributes.PERMIT_TOTAL_DEPTH.key,
  attributes.PERMIT_TYPE.key,
  attributes.PERMIT_APPROVAL_DATE.key,
  attributes.PERMIT_POSTED_DATE.key,
];

export const REQUIRED_COLUMNS = [
  ...REQUIRED_WELL_SPOT_COLUMNS,
  ...REQUIRED_WELL_HOVER_COLUMNS,
  ...REQUIRED_PERMIT_HOVER_COLUMNS,
];

export const GRID_COLUMNS = [
  "UWI",
  ...REQUIRED_WELL_HOVER_COLUMNS,
  ...REQUIRED_PERMIT_HOVER_COLUMNS,
];

export const NON_ATTRIBUTE_COLUMNS = ["__check__", "isOpenWellPanel"];

export const FORMATTING_STANDARDS_FILTER_ATTRIBUTES = [
  attributes.LATERAL_LENGTH.key,
  attributes.MEASURED_DEPTH.key,
  attributes.PERF_INTERVAL_TOP.key,
  attributes.PERF_INTERVAL_BOTTOM.key,
  attributes.TOTAL_VERTICAL_DEPTH.key,
  attributes.ELEVATION_GROUND.key,
  attributes.ELEVATION_KELLY_BUSHING.key,
  attributes.PERMIT_TOTAL_DEPTH.key,
];

export const ALL_GRID_PERMIT_COLUMNS = [
  attributes.PERMIT_NUMBER.key,
  attributes.PERMIT_APPROVAL_DATE.key,
  attributes.PERMIT_POSTED_DATE.key,
  attributes.PERMIT_TYPE.key,
  attributes.PERMIT_TOTAL_DEPTH.key,
  attributes.PERMIT_HAS_H2S.key,
  attributes.OPERATOR_CONTACT.key,
  attributes.OPERATOR_PHONE_NUMBER.key,
  attributes.OPERATOR_ADDRESS.key,
  attributes.OPERATOR_CITY.key,
  attributes.OPERATOR_STATE.key,
  attributes.OPERATOR_ZIP_CODE.key,
  attributes.PERMIT_FORMATION_DEPTH.key,
];
