import { FC, MouseEvent, useCallback } from "react";

import { MenuItem } from "@mui/material";
import {
  GridPinnedPosition,
  useGridApiContext,
} from "@mui/x-data-grid-premium";

import { ColumnItemProps } from "../../../../types/grid";

const PinColumnItem: FC<ColumnItemProps> = ({ colDef, hideMenu }) => {
  const apiRef = useGridApiContext();
  const side = apiRef.current.isColumnPinned(colDef.field);
  const pinToLeft = "Pin to left";
  const pinToRight = "Pin to right";

  const pinColumn = useCallback(
    (event: MouseEvent<HTMLLIElement>, side: GridPinnedPosition) => {
      apiRef.current.pinColumn(colDef.field, side);
      hideMenu(event);
    },
    [apiRef, colDef.field, hideMenu]
  );

  const unpinColumn = (event: MouseEvent<HTMLLIElement>) => {
    apiRef.current.unpinColumn(colDef.field);
    hideMenu(event);
  };

  const PinToLeftMenuItem = () => {
    return (
      <MenuItem onClick={(e) => pinColumn(e, GridPinnedPosition.left)}>
        {pinToLeft}
      </MenuItem>
    );
  };

  const PinToRightMenuItem = () => {
    return (
      <MenuItem onClick={(e) => pinColumn(e, GridPinnedPosition.right)}>
        {pinToRight}
      </MenuItem>
    );
  };

  if (!colDef) {
    return null;
  }

  if (side) {
    const otherSide =
      side === GridPinnedPosition.right
        ? GridPinnedPosition.left
        : GridPinnedPosition.right;
    const label =
      otherSide === GridPinnedPosition.right ? pinToRight : pinToLeft;

    return (
      <>
        <MenuItem onClick={(e) => pinColumn(e, otherSide)}>{label}</MenuItem>
        <MenuItem onClick={unpinColumn}>Unpin</MenuItem>
      </>
    );
  }

  return (
    <>
      <PinToLeftMenuItem />
      <PinToRightMenuItem />
    </>
  );
};

export default PinColumnItem;
