import { useCallback, useEffect, useMemo, useState } from "react";

import { isArray, isEqual, sortBy } from "lodash";

import {
  PermitData,
  PermitInfoItemKeys,
} from "../../../types/panels/permitPanel/permitPanel";

import {
  PERMIT_HEADER_SECTION,
  PERMIT_OPERATOR_CONTACT_SECTION,
  PERMIT_PANEL_SECTION,
  PERMIT_PANEL_SECTION_KEYS,
  PERMIT_RELATED_PERMITS_SECTION,
  PERMIT_RELATED_WELLS_SECTION,
} from "../../../constants/panels/permitPanel/permit";

import useExportPanelStore from "../../../store/exportPanel/exportPanelStore";

const useExportPermitPanel = (permitInfoPanel?: PermitData) => {
  const [permitInfoCheckedItems, setPermitInfoCheckedItems] = useState<
    PermitInfoItemKeys[]
  >([]);

  const updateExportPermitData = useExportPanelStore(
    (state) => state.updateExportPermitData
  );

  const isExistingRelatedPermits = useMemo(
    () =>
      Boolean(
        permitInfoPanel?.relatedPermits &&
          isArray(permitInfoPanel.relatedPermits) &&
          permitInfoPanel.relatedPermits.length
      ),
    [
      permitInfoPanel,
      permitInfoPanel?.permitData,
      permitInfoPanel?.permitSystemData,
    ]
  );

  const isExistingRelatedWells = useMemo(
    () =>
      Boolean(
        permitInfoPanel?.relatedWells &&
          isArray(permitInfoPanel.relatedWells) &&
          permitInfoPanel.relatedWells.length
      ),
    [
      permitInfoPanel,
      permitInfoPanel?.permitData,
      permitInfoPanel?.permitSystemData,
    ]
  );

  const availablePermitInfoExportItemKeys = useMemo<PermitInfoItemKeys[]>(
    () => [
      PERMIT_HEADER_SECTION.key,
      PERMIT_OPERATOR_CONTACT_SECTION.key,
      ...(isExistingRelatedPermits ? [PERMIT_RELATED_PERMITS_SECTION.key] : []),
      ...(isExistingRelatedWells ? [PERMIT_RELATED_WELLS_SECTION.key] : []),
    ],
    [isExistingRelatedPermits, isExistingRelatedWells]
  );

  const availablePermitInfoExportItemObj = useMemo(
    () =>
      Object.values(PERMIT_PANEL_SECTION)
        .filter((section) =>
          availablePermitInfoExportItemKeys.includes(section.key)
        )
        .map((section) => section),
    [availablePermitInfoExportItemKeys]
  );

  const isAllPermitItemsChecked = useMemo(
    () =>
      isEqual(
        sortBy(permitInfoCheckedItems),
        sortBy(PERMIT_PANEL_SECTION_KEYS)
      ),
    [permitInfoCheckedItems]
  );

  const isExcelExportDisabled = useMemo(
    () => !Boolean(permitInfoCheckedItems.length),
    [permitInfoCheckedItems]
  );

  const handleAllCheckedItem = useCallback(() => {
    if (
      permitInfoCheckedItems?.length !==
        availablePermitInfoExportItemKeys.length ||
      !Boolean(permitInfoCheckedItems.length)
    ) {
      setPermitInfoCheckedItems(availablePermitInfoExportItemKeys);
    } else if (
      permitInfoCheckedItems.length === availablePermitInfoExportItemKeys.length
    ) {
      setPermitInfoCheckedItems([]);
    }
  }, [permitInfoCheckedItems]);

  const handleCheckedPermitInfoItem = useCallback(
    (item: PermitInfoItemKeys) => {
      if (permitInfoCheckedItems.includes(item)) {
        setPermitInfoCheckedItems(
          permitInfoCheckedItems.filter((checkedItem) => checkedItem !== item)
        );
      } else {
        setPermitInfoCheckedItems((permitInfoKey) => [
          ...(permitInfoKey ?? []),
          item,
        ]);
      }
    },
    [permitInfoCheckedItems]
  );

  const handleOnClose = () => {
    updateExportPermitData(undefined);
  };

  useEffect(() => {
    setPermitInfoCheckedItems(availablePermitInfoExportItemKeys);
  }, [availablePermitInfoExportItemKeys]);

  return {
    isExcelExportDisabled,
    availablePermitInfoExportItemObj,
    availablePermitInfoExportItemKeys,
    permitInfoCheckedItems,
    isAllPermitItemsChecked,
    handleCheckedPermitInfoItem,
    handleAllCheckedItem,
    handleOnClose,
  };
};

export default useExportPermitPanel;
