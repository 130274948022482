import { FC } from "react";

import { Square } from "@mui/icons-material";
import { TextField } from "@mui/material";

import { ColorByRangeProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

import { DEFAULT_ATTRIBUTE_COLOR } from "../../../../constants/map/mapSettings";

import { formatColorsToRGB } from "../../../../utils/charts/formatter";

const ColorByRange: FC<ColorByRangeProps> = ({
  minValue,
  minColor,
  maxColor,
  maxValue,
  fieldToggle,
}) => {
  return (
    <div className="color-by-range-container">
      {fieldToggle?.showMin && (
        <TextField
          disabled
          label="Min Range"
          value={minValue}
          size="small"
          className="field-range"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
            endAdornment: <Square sx={{ color: minColor }} />,
          }}
        />
      )}
      {fieldToggle?.showMax && (
        <TextField
          disabled
          label="Max Range"
          value={maxValue}
          size="small"
          className="field-range"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
            endAdornment: <Square sx={{ color: maxColor }} />,
          }}
        />
      )}
      {fieldToggle?.showAllOthers && (
        <TextField
          disabled
          size="small"
          label="All Others"
          defaultValue=""
          className="field-range"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Square
                sx={{ color: formatColorsToRGB(DEFAULT_ATTRIBUTE_COLOR) }}
              />
            ),
          }}
        />
      )}
    </div>
  );
};

export default ColorByRange;
