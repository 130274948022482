import { FC } from "react";

import { GridSlotsComponentsProps } from "@mui/x-data-grid-premium";

declare module "@mui/x-data-grid" {
  interface NoResultsOverlayPropsOverrides {
    message?: string;
  }
}

declare module "@mui/x-data-grid" {
  interface NoRowsOverlayPropsOverrides {
    message?: string;
  }
}

const NoResultDisplay: FC = (
  param:
    | GridSlotsComponentsProps["noResultsOverlay"]
    | GridSlotsComponentsProps["noRowsOverlay"]
) => {
  return (
    <div className="full-overlay-without-bg">
      <p>{param?.message}</p>
    </div>
  );
};

export default NoResultDisplay;
