import { PropsWithChildren, RefObject, useMemo, useState } from "react";

import { Popper, PopperProps } from "@mui/material";

import classNames from "classnames";

type HoverCardProps = {
  open: boolean;
  anchorEl: PopperProps["anchorEl"] | null;
  popperRef?: RefObject<HTMLDivElement> | null;
  placement?: PopperProps["placement"];
  offset?: number;
  handleOnHoverAway: () => void;
  className?: string;
  isArrowEnabled?: boolean;
};

type PopperModifierOffsetProps = {
  placement: PopperProps["placement"];
  reference: DOMRect;
  popper: DOMRect;
};

const HoverCard = ({
  open,
  anchorEl,
  popperRef,
  placement = "bottom-start",
  offset = 0,
  handleOnHoverAway,
  className = "",
  isArrowEnabled = true,
  children,
}: PropsWithChildren<HoverCardProps>) => {
  const [arrowRef, setArrowRef] = useState<HTMLSpanElement | null>(null);

  const flipFallbackPlacements = useMemo(() => {
    switch (className) {
      case "distinction-menu":
      case "hover-card-via-distinction-menu":
        return ["left-start", "right-start"];
      default:
        return ["top-start", "left", "right"];
    }
  }, [className]);

  return (
    <Popper
      ref={popperRef}
      open={open}
      placement={placement}
      anchorEl={anchorEl}
      className={classNames("hover-card-container", {
        [className]: !!className,
      })}
      onMouseLeave={handleOnHoverAway}
      modifiers={[
        {
          name: "offset",
          options: {
            offset: ({ placement }: PopperModifierOffsetProps) => {
              switch (placement) {
                case "bottom-start":
                  return [-20, 62];
                case "top-start":
                  return [-20, -48];
                case "left":
                  return [50, 5];
                case "right-start":
                  return [40, 13];
                case "left-start":
                  if (className === "distinction-menu") return [40, 9];
                  if (className === "hover-card-via-distinction-menu" && offset)
                    return [40, offset - 13];
                  return [40, 295];
                default:
                  return [];
              }
            },
          },
        },
        {
          name: "arrow",
          enabled: true,
          options: {
            element: arrowRef,
            padding: 14, //adjust padding; sometimes arrow placement is incorrect
          },
        },
        {
          name: "flip",
          enabled: true,
          options: {
            altBoundary: true,
            rootBoundary: "viewport",
            padding: 8,
            fallbackPlacements: flipFallbackPlacements,
          },
        },
        {
          name: "preventOverflow",
          enabled: true,
          options: {
            altAxis: true,
            altBoundary: true,
            tether: true,
            rootBoundary: "viewport",
            padding: 8,
          },
        },
      ]}
    >
      {isArrowEnabled && (
        <span
          ref={setArrowRef}
          className={classNames("hover-card-arrow", {
            [className]: !!className,
          })}
        />
      )}
      {children}
    </Popper>
  );
};

export default HoverCard;
