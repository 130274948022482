import { CircularProgress } from "@mui/material";

interface LoadingProps {
  className?: string;
  size?: number;
}
const Loading: React.FC<LoadingProps> = ({ className, size }) => {
  return (
    <CircularProgress
      className={`circular-loader ${className ?? ""}`}
      size={size}
    />
  );
};

export default Loading;
