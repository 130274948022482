import { useEffect, useState } from "react";

import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  ThemeProvider,
  Typography,
} from "@mui/material";

import { Button } from "component-library";

import {
  DRAW_MODE_NONE,
  DRAW_POLYGON_MODE,
} from "../../../constants/map/mapSettings";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useMapStore from "../../../store/map/mapStore";
import usePanelsStore from "../../../store/panels/panelsStore";
import useStore from "../../../store/useStore";

import useDrawPolygon from "../../../customHooks/search/useDrawPolygon";
import useEventListener from "../../../customHooks/useEventListener";

import { darkTheme } from "../../../themes";

import * as freeformDrawIconURL from "../../../assets/images/freeform_polygon.png";

export const DrawPolygon = () => {
  const [drawOptions, setDrawOptions] = useState(null);

  const [showMenu, setShowMenu] = useState(false);

  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );
  const drawLayerSource = useMapStore((state) => state.drawLayerSource);

  const drawPolygonMode = useMapDrawStore((state) => state.drawPolygonMode);
  const updateDrawPolygonMode = useMapDrawStore(
    (state) => state.updateDrawPolygonMode
  );
  const drawPolygonModeListener = useMapDrawStore(
    (state) => state.drawPolygonModeListener
  );
  const updateDrawPolygonModeListener = useMapDrawStore(
    (state) => state.updateDrawPolygonModeListener
  );

  const drawToSelectModeListener = useStore(
    (state) => state.drawToSelectModeListener
  );

  const ESCAPE_KEYS = ["27", "Escape"];

  const _handleKeyDown = ({ key }) => {
    if (ESCAPE_KEYS.includes(String(key))) {
      updateDrawPolygonMode(DRAW_MODE_NONE);
    }
  };

  useEventListener("keydown", _handleKeyDown);

  const { updateDrawMode, drawPolygonCallback } = useDrawPolygon();

  const showDrawMenu = (e) => {
    if (isOpenQBEditorPanel) {
      toggleIsOpenQBEditorPanel(false);
    }
    if (drawPolygonMode !== DRAW_MODE_NONE) {
      return updateDrawPolygonMode(DRAW_MODE_NONE);
    }
    setDrawOptions(e.currentTarget);
    setShowMenu(!showMenu);
  };

  const closeDrawMenu = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    if (drawPolygonMode) {
      updateDrawMode({
        type: DRAW_POLYGON_MODE,
        mode: drawPolygonMode,
        listener: drawPolygonModeListener,
        externalListener: drawToSelectModeListener,
        updateListener: updateDrawPolygonModeListener,
        source: drawLayerSource,
        callback: drawPolygonCallback,
      });
    }
  }, [drawPolygonMode]);

  const selectDrawOption = (e) => {
    const drawMode = e.currentTarget.getAttribute("value");
    if (drawPolygonMode !== drawMode) {
      updateDrawPolygonMode(drawMode);
    } else {
      updateDrawPolygonMode(DRAW_MODE_NONE);
    }
    closeDrawMenu();
  };

  return (
    <>
      <Button
        iconLeft="editLocationAlt"
        iconRight="chevronRight"
        text="DRAW"
        type="tertiary"
        onClick={showDrawMenu}
      />
      <Paper>
        <ThemeProvider theme={darkTheme}>
          <Menu
            classes={{ list: "draw-menu" }}
            anchorEl={drawOptions}
            open={showMenu}
            onClose={closeDrawMenu}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            onChange={selectDrawOption}
          >
            <MenuItem value="Polygon" onClick={selectDrawOption}>
              <ListItemIcon>
                <img
                  src={freeformDrawIconURL.default}
                  className="draw-controls-icon"
                  aria-label="free-form"
                  color="#8dc6f7"
                />
              </ListItemIcon>
              <ListItemText>
                <Typography variant="body2">Polygon</Typography>
              </ListItemText>
            </MenuItem>
          </Menu>
        </ThemeProvider>
      </Paper>
    </>
  );
};

export default DrawPolygon;
