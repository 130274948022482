const CrossArrowIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.33333 13.3333V11.6667H10.5L7.85417 9.02083L9.04167 7.83333L11.6667 10.4583V8.33333H13.3333V13.3333H8.33333ZM1.16667 13.3333L0 12.1667L10.5 1.66667H8.33333V0H13.3333V5H11.6667V2.83333L1.16667 13.3333ZM4.3125 5.47917L0 1.16667L1.16667 0L5.47917 4.3125L4.3125 5.47917Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};

export default CrossArrowIcon;
