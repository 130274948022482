import { StateCreator } from "zustand";

import { WellEconomicsSlice } from "../../types/panels/wellPanel/wellEconomicsStore";
import { WellEconomicsInputsPerWellPanel } from "../../types/panels/wellPanel/wellEconomicsType";

import { WELL_ECONOMICS_FIELD_ERROR_VALUES } from "../../constants/panels/wellPanel/wellEconomics";

import { clone } from "../../utils/helper";

const wellEconomicSlice: StateCreator<WellEconomicsSlice> = (set, get) => ({
  isWellEconomicsLoading: false,
  updateIsWellEconomicsLoading: (isWellEconomicsLoading) =>
    set(() => ({ isWellEconomicsLoading })),
  wellEconomicsInputs: {},
  addWellEconomicsInput: (data) =>
    set(() => ({
      wellEconomicsInputs: get().wellEconomicsInputs
        ? { ...get().wellEconomicsInputs, ...data }
        : { ...data },
    })),
  updateWellEconomicsInputByField: (wellId, field, value) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [wellId]: {
          ...get().wellEconomicsInputs?.[wellId],
          inputs: {
            ...get().wellEconomicsInputs?.[wellId].inputs,
            [field]: value,
          },
        },
      },
    })),
  updateWellEconomicsError: (wellId, errorValues) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [wellId]: {
          ...get().wellEconomicsInputs[wellId],
          errorState: {
            ...get().wellEconomicsInputs[wellId].errorState,
            ...errorValues,
          },
        },
      },
    })),
  updateWellEconomicChangedStateByField: (wellId, field, value) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [wellId]: {
          ...get().wellEconomicsInputs[wellId],
          changedState: {
            ...get().wellEconomicsInputs[wellId].changedState,
            [field]: value,
          },
        },
      },
    })),

  updateWellEconomicChangedState: (wellId, changedValues) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [wellId]: {
          ...get().wellEconomicsInputs[wellId],
          changedState: {
            ...get().wellEconomicsInputs[wellId].changedState,
            ...changedValues,
          },
        },
      },
    })),

  updateWellEconomicsGreyedState: (wellId, values) =>
    set(() => ({
      wellEconomicsInputs: {
        ...get().wellEconomicsInputs,
        [wellId]: {
          ...get().wellEconomicsInputs[wellId],
          greyedState: {
            ...get().wellEconomicsInputs[wellId].greyedState,
            ...values,
          },
        },
      },
    })),

  resetWellEconomicsStateValues: (wellId, states) =>
    set(() => {
      const newStates = new Map();

      states.forEach((state) => {
        switch (state) {
          case "error":
            newStates.set("errorState", WELL_ECONOMICS_FIELD_ERROR_VALUES);
            break;
          case "changed":
            newStates.set("errorState", WELL_ECONOMICS_FIELD_ERROR_VALUES);
            break;
        }
      });

      return {
        wellEconomicsInputs: {
          ...get().wellEconomicsInputs,
          [wellId]: {
            ...get().wellEconomicsInputs[wellId],
            ...newStates,
          },
        },
      };
    }),
  removeWellEconomicsByWellIds: (wellIds) =>
    set(() => {
      let newWellEconomicsList = get().wellEconomicsInputs;

      if (wellIds.length) {
        newWellEconomicsList = clone(
          get().wellEconomicsInputs
        ) as WellEconomicsInputsPerWellPanel;

        wellIds.forEach((wellId) => {
          if (get().wellEconomicsInputs[wellId]) {
            delete newWellEconomicsList[wellId];
          }
        });
      }

      return {
        wellEconomicsInputs: newWellEconomicsList,
      };
    }),
});

export { wellEconomicSlice };
