import { FC, SVGProps } from "react";

interface ExpandTableIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const ExpandTableIcon: FC<ExpandTableIconProps> = ({
  width = "20",
  height = "13",
  fill = "none",
  pathFill = "#FFFFFFCC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 13"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.0732422 0.863281L1.57324 0.863281L1.57324 12.8633L0.0732417 12.8633L0.0732422 0.863281ZM17.5732 0.863281L19.0732 0.863281L19.0732 12.8633L17.5732 12.8633L17.5732 0.863281Z"
        fill={pathFill}
      />
      <path
        d="M7.85596 9.58735L4.94718 6.86335L7.85596 4.13934L7.0274 3.36342L3.29006 6.86335L7.0274 10.3633L7.85596 9.58735Z"
        fill={pathFill}
      />
      <path
        d="M12.1191 10.3633L15.8564 6.86335L12.1191 3.36342L11.2905 4.13934L14.1993 6.86335L11.2905 9.58735L12.1191 10.3633Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default ExpandTableIcon;
