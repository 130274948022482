import { Polygon } from "../../../types/map/gis/common";

export const getPolygonType = (polygons: Polygon) => {
  if (polygons.length === 0) {
    return "";
  } else if (polygons.length === 1) {
    return "Polygon";
  } else {
    return "MultiPolygon";
  }
};
