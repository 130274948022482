import { WellSystemData } from "../../../types/panels/wellPanel/wellPanel";

import { WELL_PANEL_FLAGS } from "../../../constants/panels/wellPanel/wellInfo";

export const wellPanelSectionFlags = (wellSystem: WellSystemData) => {
  return {
    hasStatistics: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_INJECTION.key] ||
        wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_PRODUCTION.key]
    ),
    hasPerforation: Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.PERF.key]),
    hasTesting: Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.TEST.key]),
    hasCompletion: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_COMPLETION.key]
    ),
    hasProduction: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_PRODUCTION.key]
    ),
    hasCasing: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_CASING.key]
    ),
    hasWellEconomics: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_WELL_ECONOMICS.key]
    ),
    hasFormation: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_REPORTED_TOPS.key]
    ),
    hasPermit: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_PERMIT.key]
    ),
    hasGunBarrel: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_GUN_BARREL.key]
    ),
    hasForecast: Boolean(
      wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_FORECAST.key]
    ),
    hasDST: Boolean(wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_DST.key]),
  };
};
