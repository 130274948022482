import { StateCreator } from "zustand";

import { ExportMapStates } from "../../types/exports/exportMap/exportMapStore";

import { clone } from "../../utils";

const sliceResetFns = new Set<() => void>();

const resetExportMapSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialExportMapSlice = {
  exportMapModalOpened: null,
  exportMapWellUwiList: [],
  isExportMapLoading: false,
};

const exportMapSlice: StateCreator<ExportMapStates> = (set, get) => {
  sliceResetFns.add(() => set(clone(initialExportMapSlice)));
  return {
    ...initialExportMapSlice,
    updateExportMapModalOpened: (exportMapModalOpened) =>
      set(() => ({ exportMapModalOpened })),
    updateExportMapWellUwiList: (exportMapWellUwiList) =>
      set(() => ({ exportMapWellUwiList })),
    updateIsExportMapLoading: (isExportMapLoading) =>
      set(() => ({ isExportMapLoading })),
  };
};

export { exportMapSlice, resetExportMapSlice };
