import { ColumnVisibilityModel } from "../../types/grid";

export const fetchColumnsList = (
  columnVisibilityModel: ColumnVisibilityModel,
  newColumnModel: ColumnVisibilityModel
) => {
  return Object.keys(columnVisibilityModel).reduce(
    (acc: ColumnVisibilityModel, v) => {
      acc[v] = newColumnModel[v] ?? false;
      return acc;
    },
    {}
  );
};
