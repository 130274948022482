import { useMemo } from "react";

import useDataGridStore from "../../store/grid/dataGridStore";

const useSearchCriteria = () => {
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);

  const hasSearchCriteria = useMemo(
    () =>
      searchCriteria.currentBounds.length > 0 ||
      searchCriteria.drawnPolygons.length > 0 ||
      searchCriteria.searchedUWIs.length > 0 ||
      !!searchCriteria.fileId ||
      !!searchCriteria.shapeId,
    [searchCriteria]
  );

  return { hasSearchCriteria };
};

export default useSearchCriteria;
