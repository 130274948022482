import { DRAW_MODE_NONE } from "../constants/map/mapSettings";

const mapSettingsStore = (set, get) => ({
  // DRAW TO SELECT
  drawToSelectMode: DRAW_MODE_NONE,
  updateDrawToSelectMode: (drawToSelectMode) =>
    set(() => ({ drawToSelectMode })),
  drawToSelectModeListener: DRAW_MODE_NONE,
  updateDrawToSelectModeListener: (drawToSelectModeListener) =>
    set(() => ({ drawToSelectModeListener })),
  isDrawToSelectLoading: false,
  updateIsDrawToSelectLoading: (isDrawToSelectLoading) =>
    set(() => ({ isDrawToSelectLoading })),
  // DRAW TO SELECT END

  isBubbleSizesLoading: false,
  updateIsBubbleSizesLoading: (isBubbleSizesLoading) =>
    set(() => ({ isBubbleSizesLoading })),

  DVTProcessing: false,
  updateDVTProcessing: (DVTProcessing) => set(() => ({ DVTProcessing })),

  viewportChanged: false,
  updateViewportChanged: (viewportChanged) => set(() => ({ viewportChanged })),
});

export default mapSettingsStore;
