import { noData } from "../../constants/constants";

import {
  formatAttributeByKey,
  formatBfactor,
} from "../../utils/formatters/attributeFormatter";
import { truncateStringDecimal } from "../../utils/formatters/numberFormatter";

const usePanelFormattingRule = () => {
  const formatRateAttributes = (
    attrKey: string,
    val: string | number,
    showCommas = true
  ) => {
    return formatAttributeByKey(attrKey, val, showCommas);
  };

  const formatBFactorAttributes = (
    attrKey: string,
    val: string | number,
    showCommas = true
  ) => {
    return formatAttributeByKey(
      attrKey,
      val,
      showCommas,
      true,
      false,
      formatBfactor
    );
  };

  const formatLatLongAttributes = (val: string | number) => {
    if (val === undefined || val === null || val === "") return noData;

    return truncateStringDecimal(val.toString(), 6);
  };

  return {
    formatRateAttributes,
    formatBFactorAttributes,
    formatLatLongAttributes,
  };
};

export default usePanelFormattingRule;
