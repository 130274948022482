import { useCallback, useState } from "react";

import { ChartType } from "../../types/charts/chartType/chartType";
import { GetTopAttributesResponse } from "../../types/map/mapSettings/useTopAttributeValues";

import config from "../../configs/appSettings";

import { CUM_BOE } from "../../constants/attributes";

import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { callServiceAPI } from "../../action/callServiceAPI";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import { useGridSelectedData } from "../grid/useGridSelectedData";
import useUwiFileUpload from "../search/useUwiFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

export const useChartTopAttribute = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [data, setData] = useState<GetTopAttributesResponse | undefined>(
    undefined
  );

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const { getUWIFileIdWithRetry } = useUwiFileUpload();

  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );
  const { isSelectedAllGrid } = useGridSelectedData();

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const { buildChartPostRequest } = useChartSearchRequest();
  const { isMetricOnSearch } = useUnitOfMeasure();

  const getChartTopAttributes = useCallback(
    async (attribute: string, chartType: ChartType) => {
      setIsLoading(true);

      try {
        setIsError(false);
        const body = {
          ...buildChartPostRequest(chartType),
          ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
        };
        const sortBy = CUM_BOE.key;
        const topAttributesUtl = `${config.endpoints.wellService}api/wells/top/${attribute}/by/${sortBy}`;

        const response = await callServiceAPI<GetTopAttributesResponse>(
          topAttributesUtl,
          body ?? {},
          getUWIFileIdWithRetry,
          uploadedUWIFile,
          isFromUploadedUWIFile
        );

        if (!response || !("data" in response)) return;

        setData(response.data);
      } catch (e) {
        setIsError(true);
        setData([]);
        console.log("getChartData error", e);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [
      isSelectedAllGrid,
      selectedGridDataKeys,
      isFromUploadedUWIFile,
      uploadedUWIFile,
      getUWIFileIdWithRetry,
    ]
  );
  return {
    isLoading,
    data,
    isError,
    getChartTopAttributes,
  };
};
