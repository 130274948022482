import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";

import classNames from "classnames";

import { SidebarNavigationMenu } from "../../../types/panels/sidebar";

import Loading from "../../common/Loading";

interface NavMenuProps {
  menuInfo: SidebarNavigationMenu;
  isActive?: boolean;
  isDisabled?: boolean;
  isLoading?: boolean;
  handleMenuClick?: (
    event: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>
  ) => void;
  className?: string;
}

const NavMenu = ({
  menuInfo,
  isActive = false,
  isDisabled = false,
  isLoading = false,
  handleMenuClick = () => null,
  className = "",
}: NavMenuProps) => {
  return (
    <div
      className={classNames("menu-container", {
        [className]: !!className,
      })}
      onClick={handleMenuClick}
      role="presentation"
    >
      <IconButton
        sx={{
          ...menuInfo.style,
        }}
        className={classNames("menu-icon-box", {
          "active-menu": isActive,
        })}
        disabled={isDisabled}
      >
        {isLoading ? <Loading size={20} /> : menuInfo.icon}
      </IconButton>

      <div
        className={classNames("side-bar-menu-text", {
          "disabled-menu-text": isDisabled,
          "active-menu-text": isActive,
        })}
      >
        {menuInfo.text}
      </div>
      {menuInfo.type === "accordion" &&
        (isActive ? (
          <ExpandLessIcon className="accordion-icon" />
        ) : (
          <ExpandMoreIcon className="accordion-icon" />
        ))}
    </div>
  );
};

export default NavMenu;
