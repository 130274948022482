import { ColumnVisibilityAction } from "../../types/grid";

import {
  COLUMN_GRID_MAXIMUM,
  NON_ATTRIBUTE_COLUMNS,
} from "../../constants/grid";

/**
 * @params newColumnsModel(object) list of all (hidden + shown) columns {UWI: true, ...}
 * @params columnsModel(array) list of displayed columns
 * @params columnsLoaded(array of strings) list of last fetched columns
 *
 * @return object { action: `add` | `remove` | `stored` | `limit-exceeded`, array of columns }
 */
export const getColumnVisibilityAction = (
  newColumnsModel: { [key: string]: boolean },
  columnsModel: { [key: string]: boolean },
  columnsLoaded: string[]
) => {
  let action: ColumnVisibilityAction | "" = "";

  //get all columns with value = true
  const newColumnsToShow = getColumnKeyNames(newColumnsModel);
  const columnsModelValueTrue = getColumnKeyNames(columnsModel);

  //limit shown columns to 25
  if (newColumnsToShow.length <= COLUMN_GRID_MAXIMUM) {
    //check if new columns is added
    if (newColumnsToShow.length > columnsModelValueTrue.length) {
      const newColumn = newColumnsToShow.filter(
        (x) => !columnsModelValueTrue.includes(x)
      );

      if (newColumn && newColumn[0] !== "") {
        const isStored = columnsLoaded.includes(newColumn[0]);

        if (isStored) {
          action = "stored";
        } else {
          action = "add";
        }
      }
    }

    //check if a column is removed
    else if (newColumnsToShow.length < columnsModelValueTrue.length) {
      action = "remove";
    }
  } else {
    action = "limit-exceeded";
  }

  return { action: action, columnsArray: newColumnsToShow };
};

/**
 * Get all columns with value = true
 *
 * @params allColumns (object)
 * @return columns with value = true (array of strings)
 */
export const getColumnKeyNames = (
  columns: { [key: string]: boolean } = {},
  forPayload = true
) => {
  if (Object.keys(columns).length > 0) {
    //convert object to array
    const columnsArray = Object.entries(columns);

    //get all columns with value = true
    //exclude checkbox column key
    const filteredColumns = columnsArray.filter(
      ([key, value]) => value === true && !NON_ATTRIBUTE_COLUMNS.includes(key)
    );

    //convert array back to object
    // `{UWI: true, StateName: true .... }`
    const columnsAsObject = Object.fromEntries(filteredColumns);

    //get column keys only
    const newColumns = Object.keys(columnsAsObject);

    if (forPayload) {
      changeRequestKeys(newColumns);
    }

    return newColumns;
  }

  return [];
};

/**
 * Get the number of shown columns
 *
 * @params newModel (object)
 * @return number
 */
export const shownColumnsCount = (newModel: { [key: string]: boolean }) => {
  return Object.values(newModel).reduce((a, item) => a + (item ? 1 : 0), 0);
};

export const changeRequestKeys = (columns: string[]) => {
  //if ProducingFormation is included get its index
  const indexProducingFormation = columns.findIndex(
    (el) => el === "ProducingFormation"
  );

  if (indexProducingFormation >= 0) {
    //Replace ProducingFormation with DisplayFormation
    columns[indexProducingFormation] = "DisplayFormation";
  }

  //if ProducingFormation is included get its index
  const indexLandingZone = columns.findIndex((el) => el === "LandingZone");

  if (indexLandingZone >= 0) {
    //Replace LandingZone with InterpretedProducingFormation
    columns[indexLandingZone] = "InterpretedProducingFormation";
  }

  //if BOEIP30 is included get its index
  const indexBOEIP30 = columns.findIndex((el) => el === "BOEIP30");

  if (indexBOEIP30 >= 0) {
    //Replace BOEIP30 with MaxBOE
    columns[indexBOEIP30] = "MaxBOE";
  }

  //Surface and Bottom Attributes
  const indexSurfaceLatitudeNAD27 = columns.findIndex(
    (el) => el === "SurfaceLatitudeNAD27"
  );
  const SurfaceLongitudeNAD27 = columns.findIndex(
    (el) => el === "SurfaceLongitudeNAD27"
  );
  const BottomLatitudeNAD27 = columns.findIndex(
    (el) => el === "BottomLatitudeNAD27"
  );
  const BottomLongitudeNAD27 = columns.findIndex(
    (el) => el === "BottomLongitudeNAD27"
  );
  const SurfaceLatitudeNAD83 = columns.findIndex(
    (el) => el === "SurfaceLatitudeNAD83"
  );
  const SurfaceLongitudeNAD83 = columns.findIndex(
    (el) => el === "SurfaceLongitudeNAD83"
  );
  const BottomLatitudeNAD83 = columns.findIndex(
    (el) => el === "BottomLatitudeNAD83"
  );
  const BottomLongitudeNAD83 = columns.findIndex(
    (el) => el === "BottomLongitudeNAD83"
  );
  const SurfaceLatitudeWGS84 = columns.findIndex(
    (el) => el === "SurfaceLatitudeWGS84"
  );
  const SurfaceLongitudeWGS84 = columns.findIndex(
    (el) => el === "SurfaceLongitudeWGS84"
  );
  const BottomLatitudeWGS84 = columns.findIndex(
    (el) => el === "BottomLatitudeWGS84"
  );
  const BottomLongitudeWGS84 = columns.findIndex(
    (el) => el === "BottomLongitudeWGS84"
  );
  const SurfaceLatitudeEPSG3857 = columns.findIndex(
    (el) => el === "SurfaceLatitudeEPSG3857"
  );
  const SurfaceLongitudeEPSG3857 = columns.findIndex(
    (el) => el === "SurfaceLongitudeEPSG3857"
  );
  const BottomLatitudeEPSG3857 = columns.findIndex(
    (el) => el === "BottomLatitudeEPSG3857"
  );
  const BottomLongitudeEPSG3857 = columns.findIndex(
    (el) => el === "BottomLongitudeEPSG3857"
  );

  if (indexSurfaceLatitudeNAD27 >= 0)
    columns[indexSurfaceLatitudeNAD27] = "SurfaceLatitude_NAD27";
  if (SurfaceLongitudeNAD27 >= 0)
    columns[SurfaceLongitudeNAD27] = "SurfaceLongitude_NAD27";
  if (BottomLatitudeNAD27 >= 0)
    columns[BottomLatitudeNAD27] = "BottomLatitude_NAD27";
  if (BottomLongitudeNAD27 >= 0)
    columns[BottomLongitudeNAD27] = "BottomLongitude_NAD27";
  if (SurfaceLatitudeNAD83 >= 0)
    columns[SurfaceLatitudeNAD83] = "SurfaceLatitude_NAD83";
  if (SurfaceLongitudeNAD83 >= 0)
    columns[SurfaceLongitudeNAD83] = "SurfaceLongitude_NAD83";
  if (BottomLatitudeNAD83 >= 0)
    columns[BottomLatitudeNAD83] = "BottomLatitude_NAD83";
  if (BottomLongitudeNAD83 >= 0)
    columns[BottomLongitudeNAD83] = "BottomLongitude_NAD83";
  if (SurfaceLatitudeWGS84 >= 0)
    columns[SurfaceLatitudeWGS84] = "SurfaceLatitude_WGS84";
  if (SurfaceLongitudeWGS84 >= 0)
    columns[SurfaceLongitudeWGS84] = "SurfaceLongitude_WGS84";
  if (BottomLatitudeWGS84 >= 0)
    columns[BottomLatitudeWGS84] = "BottomLatitude_WGS84";
  if (BottomLongitudeWGS84 >= 0)
    columns[BottomLongitudeWGS84] = "BottomLongitude_WGS84";
  if (SurfaceLatitudeEPSG3857 >= 0)
    columns[SurfaceLatitudeEPSG3857] = "SurfaceLatitude_EPSG3857";
  if (SurfaceLongitudeEPSG3857 >= 0)
    columns[SurfaceLongitudeEPSG3857] = "SurfaceLongitude_EPSG3857";
  if (BottomLatitudeEPSG3857 >= 0)
    columns[BottomLatitudeEPSG3857] = "BottomLatitude_EPSG3857";
  if (BottomLongitudeEPSG3857 >= 0)
    columns[BottomLongitudeEPSG3857] = "BottomLongitude_EPSG3857";
};
