import { RecordType } from "../../../types/common/appMode/appMode";
import { MapControlsWellStyling } from "../../../types/map/mapSettings/controls";

import * as mapSettings from "../../../constants/map/mapSettings";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

export const getWellStylingControls = (type: RecordType | null) => {
  if (!type) return null;
  return {
    [mapSettings.WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL.key]:
      wellStylingControlByRecordType(
        type,
        mapSettings.WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_WELL_PERMIT_CARD_CONTROL.key]:
      wellStylingControlByRecordType(
        type,
        mapSettings.WELL_STYLING_WELL_PERMIT_CARD_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL.key]:
      wellStylingControlByRecordType(
        type,
        mapSettings.WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL.key]:
      wellStylingControlByRecordType(
        type,
        mapSettings.WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_WELL_PATHS_CONTROL.key]:
      wellStylingControlByRecordType(
        type,
        mapSettings.WELL_STYLING_WELL_PATHS_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_PERMIT_SPOTS_CONTROL.key]:
      wellStylingControlByRecordType(
        type,
        mapSettings.WELL_STYLING_PERMIT_SPOTS_CONTROL.key
      ),
    [mapSettings.WELL_STYLING_APPLY_COLOR_TO_PERMITS_CONTROL.key]:
      wellStylingControlByRecordType(
        type,
        mapSettings.WELL_STYLING_APPLY_COLOR_TO_PERMITS_CONTROL.key
      ),
  };
};

export const wellStylingControlByRecordType = (
  type: RecordType,
  setting: MapControlsWellStyling
) => {
  switch (type) {
    case RECORD_TYPES.WELL:
      return mapSettings.WELL_STYLING_WELLS_ONLY.map<MapControlsWellStyling>(
        (list) => list.key
      ).includes(setting);
    case RECORD_TYPES.PERMIT:
      return mapSettings.WELL_STYLING_PERMITS_ONLY.map<MapControlsWellStyling>(
        (list) => list.key
      ).includes(setting);
    case RECORD_TYPES.WELLS_AND_PERMIT:
      return mapSettings.WELL_STYLING_WELLS_AND_PERMITS.map<MapControlsWellStyling>(
        (list) => list.key
      ).includes(setting);
    default:
      return false;
  }
};
