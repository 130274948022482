import { create } from "zustand";

import { ExtentInfoState } from "../../../../types/map/gis/store";

import { extentInfoSlice } from "./extentSlice";

const useExtentInfoStore = create<ExtentInfoState>()((...a) => ({
  ...extentInfoSlice(...a),
}));

export default useExtentInfoStore;
