export const GRID_RENDER_TRIGGERS = [
  "searchPanel",
  "savedSearch",
  "filter",
  "sort",
  "pagination",
  "dynamicColumns",
  "sortBySelected",
];

export const RESET_GRID_TRIGGERS = [
  "searchPanel",
  "savedSearch",
  "filter",
  "sort",
  "dynamicColumns",
  "sortBySelected",
];

export const WELL_SELECT_MAP_TRIGGER = "map";
export const WELL_SELECT_GRID_TRIGGER = "grid";
export const WELL_SELECT_DRAW_TRIGGER = "draw";
