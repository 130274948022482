import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../types/common/api";
import { GetAllSavedSearchesResponse } from "../../types/panels/savedSearchPanel/savedSearchData";

import config from "../../configs/appSettings";

import useSavedSearchHelper from "./useSavedSearchHelper";

const useGetAllSavedSearches = () => {
  const { error, setError, catchError } = useSavedSearchHelper();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<GetAllSavedSearchesResponse | null>(null);
  const [success, setSuccess] = useState(false);

  const getAllSavedSearches = async () => {
    setIsLoading(true);
    setSuccess(false);
    setData(null);
    setError(null);

    const url = `${config.endpoints.wellService}api/searches`;
    try {
      const response = await axios.get(url);
      setData(response.data);
      setSuccess(true);
    } catch (error) {
      const err = error as AxiosError<APIErrorResponse>;
      catchError(err);
      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    success,
    getAllSavedSearches,
  };
};

export default useGetAllSavedSearches;
