import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "./../StyledAccordions";

import BubbleMapEnhancements from "../../../../assets/images/release-notes/BubbleMapEnhancements.png";
import ScatterPlot from "../../../../assets/images/release-notes/ScatterPlot.png";
import ScatterPlotSelectionToggle from "../../../../assets/images/release-notes/ScatterPlotSelectionToggle.png";
import ScatterPlotSettings from "../../../../assets/images/release-notes/ScatterPlotSettings.png";
import SearchToggle from "../../../../assets/images/release-notes/SearchToggle.png";
import TypeCurveForecast1 from "../../../../assets/images/release-notes/TypeCurveForecast1.png";
import TypeCurveForecast2 from "../../../../assets/images/release-notes/TypeCurveForecast2.png";
import TypeCurveForecast3 from "../../../../assets/images/release-notes/TypeCurveForecast3.png";
import TypeCurveForecast4 from "../../../../assets/images/release-notes/TypeCurveForecast4.png";

const Version20231107: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20231107"}
      onChange={handleChange("Version20231107")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2023.11.07
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          11/07/2023
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes an enhancement to query builder and bubble map,
          added scatter plot option and more attributes to each well.
        </CustomTypography>
      </AccordionDetails>

      <AccordionContent>
        • Added production forecast to Type Curve charts
      </AccordionContent>
      <AccordionSubContent>
        • We added a “Forecast” toggle to Type Curve Setting window and user can
        see the forecast type curve for economic life of the selected wells.
      </AccordionSubContent>
      <AccordionImage src={TypeCurveForecast1} width="100%" draggable={false} />
      <AccordionImage src={TypeCurveForecast2} width="100%" draggable={false} />
      <AccordionImage src={TypeCurveForecast3} width="100%" draggable={false} />
      <AccordionImage src={TypeCurveForecast4} width="100%" draggable={false} />

      <AccordionContent>
        • Added Scatter Plot to Analysis Charts
      </AccordionContent>
      <AccordionSubContent>
        • Well Data Analytics users can open a scatter plot chart and analyze
        search results data relationship
      </AccordionSubContent>
      <AccordionSubContent>
        • Designed default Scatter Plot is Completion Data vs BOE Total Cum but
        other attributes can be selected for X and Y axis under Setting dropdown
        window
      </AccordionSubContent>
      <AccordionImage
        src={ScatterPlotSelectionToggle}
        width="100%"
        draggable={false}
      />
      <AccordionImage src={ScatterPlot} width="100%" draggable={false} />
      <AccordionImage
        src={ScatterPlotSettings}
        width="100%"
        draggable={false}
      />

      <AccordionContent>
        • Used Forecast Decline Parameters added to search grid results
      </AccordionContent>
      <AccordionSubContent>
        ▪ Oil Algorithm <br />
        ▪ Oil Initial Rate <br />
        ▪ Oil Decline Rate
        <br />
        ▪ Oil B-Factor <br />
        ▪ Oil Start
        <br />
        ▪ Gas Algorithm
        <br />
        ▪ Gas Initial Rate <br />
        ▪ Gas Decline Rate
        <br />
        ▪ Gas B-Factor
        <br />
        ▪ Gas Start
        <br />
        ▪ Water Algorithm
        <br />
        ▪ Water Initial Rate
        <br />
        ▪ Water Decline Rate
        <br />
        ▪ Water B-Factor
        <br />▪ Water Start
      </AccordionSubContent>
      <AccordionContent>
        • Added more production statistic attributes to search grid results, map
        color by and bubble map
      </AccordionContent>
      <AccordionSubContent>
        ▪ Oil EUR Per Ft
        <br />
        ▪ Gas EUR Per Ft
        <br />
        ▪ Oil Cum Per Ft
        <br />
        ▪ Gas Cum Per Ft
        <br />
        ▪ Water Cum Per Ft
        <br />
        ▪ BOE Cum Per Ft
        <br />
        ▪ BOE IP30
        <br />▪ BOE IP90
      </AccordionSubContent>

      <AccordionContent>• Bubble Map Enhancements</AccordionContent>
      <AccordionSubContent>
        • Changing binning algorithm to allow users to better identify
        differences in bubble size
      </AccordionSubContent>
      <AccordionImage
        src={BubbleMapEnhancements}
        draggable={false}
        width={"100%"}
      />
      <AccordionContent>• Search Attribute Enhancement</AccordionContent>
      <AccordionSubContent>
        • Added a toggle option to change attribute list sort order from Alpha A
        to Z to Well Count Largest to Smallest or Smallest to Largest to quickly
        identify the options with highest/lowest number of wells
      </AccordionSubContent>
      <AccordionImage
        src={SearchToggle}
        draggable={false}
        width={"auto"}
        height={"500px"}
      />
    </Accordion>
  );
};

export default Version20231107;
