import { ChangeEvent, FC, useCallback, useEffect, useState } from "react";

import { FormControl, Grid, IconButton, TextField } from "@mui/material";

import { Button, ButtonIcon } from "component-library";

import {
  ChartAxisType,
  YAxisScaleType,
} from "../../../../types/charts/chartType/chartType";
import {
  AxisLabelError,
  ChartAxis,
  MultipleAxisConfigProps,
} from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

import {
  CHART_AXIS,
  CHART_SCALING_TYPE,
} from "../../../../constants/charts/charts";
import { axisList } from "../../../../constants/charts/multipleAxisConfig";

import { hasEmoji } from "../../../../utils/charts/validator";

import { clone } from "../../../../utils";
import ScaleRadioButtons from "./ScaleRadioButtons";

const MultipleAxisConfig: FC<MultipleAxisConfigProps> = ({
  chartAxisList,
  setChange,
  setIsError,
  isColorByOn,
}) => {
  const [axisLabelFieldState, setAxisLabelFieldState] =
    useState<AxisLabelError>([]);
  const getAxisScaleValue = useCallback(
    (axisName: string) => {
      return (
        chartAxisList.find((axis) => axis.name === axisName)?.scale ??
        CHART_SCALING_TYPE.LINEAR
      );
    },
    [chartAxisList]
  );

  useEffect(() => {
    setAxisLabelFieldState((prevState) => {
      const newAxisLabelState: AxisLabelError = [];
      chartAxisList.forEach((axis) => {
        newAxisLabelState.push({ name: axis.name, isError: false });
      });
      return newAxisLabelState;
    });
  }, []);

  const handleScaleChange = (
    e: ChangeEvent<HTMLInputElement>,
    axisName: ChartAxisType
  ) => {
    const changedAxis = clone(chartAxisList).map((axis: ChartAxis) => {
      if (axis.name === axisName) {
        axis.scale = e.target.value as YAxisScaleType;
      }
      return axis;
    });

    setChange(changedAxis);
  };

  const handleAddAxis = () => {
    setChange((prevState) => {
      return [
        ...prevState,
        { name: CHART_AXIS.RIGHT, scale: CHART_SCALING_TYPE.LINEAR, title: "" },
      ];
    });

    setAxisLabelFieldState((prevState) => {
      return [...prevState, { name: CHART_AXIS.RIGHT, isError: false }];
    });
  };

  const handleDeleteAxis = () => {
    setChange((prevState) =>
      prevState.filter((prevItem) => prevItem.name !== CHART_AXIS.RIGHT)
    );

    setAxisLabelFieldState((prevState) =>
      prevState.filter((prevItem) => prevItem.name !== CHART_AXIS.RIGHT)
    );
  };

  const handleChangeAxisLabel = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    idx: number,
    name: ChartAxisType
  ) => {
    const fieldState = axisLabelFieldState.find((axis) => axis.name === name);

    if (fieldState?.isError) {
      //reset state
      setAxisLabelFieldState((prevState) => {
        const newState = (clone(prevState) as AxisLabelError).map((axis) => {
          if (axis.name === name) {
            axis.isError = false;
          }
          return axis;
        });
        return newState;
      });
    }

    setChange((prevState) => {
      const newAxisList = clone(prevState) as ChartAxis[];
      newAxisList[idx].title = e.target.value;
      return newAxisList;
    });
  };

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>,
    name: ChartAxisType
  ) => {
    if (e.target.value && hasEmoji(e.target.value)) {
      setAxisLabelFieldState((prevState) => {
        const newState = (clone(prevState) as AxisLabelError).map((axis) => {
          if (axis.name === name) {
            axis.isError = true;
          }
          return axis;
        });
        return newState;
      });
    }
  };

  useEffect(() => {
    if (!axisLabelFieldState.length) {
      return;
    }

    if (axisLabelFieldState.every((axis) => !Boolean(axis.isError))) {
      setIsError(false);
    } else {
      setIsError(true);
    }
  }, [axisLabelFieldState]);

  const isAxisError = useCallback(
    (name: ChartAxisType) => {
      return axisLabelFieldState.find((axis) => axis.name === name)?.isError;
    },
    [axisLabelFieldState]
  );

  const disableForms = useCallback(
    (axis: ChartAxisType) => {
      return axis === CHART_AXIS.RIGHT && isColorByOn;
    },
    [isColorByOn]
  );

  return (
    <Grid container item className="multiple-axis-container">
      {chartAxisList.map((axis, idx) => (
        <div key={axis.name} className="axis-container">
          <div className="axis-text-field">
            <FormControl disabled={disableForms(axis.name)}>
              <TextField
                className="axis-title"
                size="small"
                placeholder={`${
                  axisList.find((axisName) => axisName.name === axis.name)
                    ?.displayName
                } Axis Label`}
                value={axis.title}
                onChange={(e) => handleChangeAxisLabel(e, idx, axis.name)}
                disabled={disableForms(axis.name)}
                onBlur={(e) => handleBlur(e, axis.name)}
                error={isAxisError(axis.name)}
                inputProps={{ maxLength: 30 }}
                helperText={
                  <div className="text-field-helper-text">
                    <span className="error-message">
                      {isAxisError(axis.name) && <>Invalid Axis Label</>}
                    </span>
                    <span className="counter">{`${
                      (axis?.title as string)?.length ?? 0
                    } / 30`}</span>
                  </div>
                }
              />
            </FormControl>
          </div>
          <div>
            <ScaleRadioButtons
              value={getAxisScaleValue(axis.name)}
              handleScaleChange={(e) => {
                handleScaleChange(e, axis.name);
              }}
              disabled={disableForms(axis.name)}
            />
          </div>
          {axis.name === CHART_AXIS.RIGHT && (
            <IconButton
              size="small"
              onClick={handleDeleteAxis}
              disabled={disableForms(axis.name)}
            >
              <ButtonIcon icon="delete" fill="#fff" />
            </IconButton>
          )}
          {axis.name === CHART_AXIS.LEFT && (
            <div className="add-axis-button">
              <Button
                type="secondary"
                iconLeft="add"
                text="Add Y Axis"
                disabled={chartAxisList.length === 2 || isColorByOn}
                onClick={handleAddAxis}
              />
            </div>
          )}
        </div>
      ))}
    </Grid>
  );
};

export default MultipleAxisConfig;
