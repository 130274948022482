import { useMemo } from "react";

import { ScatterPlotFormList } from "../../../types/charts/chartHooks/scatterPlotData";

import {
  SCATTER_PLOT_PERMIT_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES,
  SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_PERMIT_Y_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES,
  SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES,
} from "../../../constants/charts/scatterPlotDataFields";
import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useRecordType from "../../common/useRecordType";

export const useScatterPlotConfig = () => {
  const { searchedRecordType } = useRecordType();

  const formDropdownList = useMemo(() => {
    const dropdownList: ScatterPlotFormList = {
      colorByAttributes: [],
      xAttributes: [],
      yAttributes: [],
    };
    if (searchedRecordType === RECORD_TYPES.WELLS_AND_PERMIT) {
      dropdownList.colorByAttributes =
        SCATTER_PLOT_WELL_PERMIT_COLOR_BY_ATTRIBUTES;
      dropdownList.xAttributes = SCATTER_PLOT_WELL_PERMIT_X_AXIS_ATTRIBUTES;
      dropdownList.yAttributes = SCATTER_PLOT_WELL_PERMIT_Y_AXIS_ATTRIBUTES;
    } else if (searchedRecordType === RECORD_TYPES.PERMIT) {
      dropdownList.colorByAttributes = SCATTER_PLOT_PERMIT_COLOR_BY_ATTRIBUTES;
      dropdownList.xAttributes = SCATTER_PLOT_PERMIT_X_AXIS_ATTRIBUTES;
      dropdownList.yAttributes = SCATTER_PLOT_PERMIT_Y_AXIS_ATTRIBUTES;
    } else {
      dropdownList.colorByAttributes = SCATTER_PLOT_WELL_COLOR_BY_ATTRIBUTES;
      dropdownList.xAttributes = SCATTER_PLOT_WELL_X_AXIS_ATTRIBUTES;
      dropdownList.yAttributes = SCATTER_PLOT_WELL_Y_AXIS_ATTRIBUTES;
    }

    return dropdownList;
  }, [searchedRecordType]);

  return { formDropdownList };
};
