import { Tooltip } from "@mui/material";

import { getTruncatedWellStatus } from "../../../utils/helper2";

import { getTrimmedWellNamePlusNumber } from "../../../utils";

type HoverCardHeaderProps = {
  RecordType?: string;
  UWI?: string;
  WellStatus?: string;
  WellName?: string;
  WellNumber?: string;
};

const HoverCardHeader = ({
  RecordType = "Well",
  UWI,
  WellStatus,
  WellName,
  WellNumber,
}: HoverCardHeaderProps) => {
  return (
    <div className="hover-card-header-container">
      <div>
        <div className="hover-card-uwi-status">
          <div>
            <span>{UWI ?? "N/A"}</span>
          </div>
          <div className="hover-card-pill-container">
            <Tooltip title={WellStatus ?? "N/A"}>
              <span className="hover-card-pill">
                {getTruncatedWellStatus(WellStatus ?? "N/A")}
              </span>
            </Tooltip>
            <span className="hover-card-pill with-icon">
              {/* Saving this for putting the symbol of the record type*/}
              {/* {RecordType === RECORD_TYPES.WELL ? (
                <WellSpot className="hover-card-pill-icon" />
              ) : (
                <Permit className="hover-card-pill-icon" />
              )} */}
              <span className="hover-card-pill-text">{RecordType}</span>
            </span>
          </div>
        </div>
      </div>
      <div className="hover-card-well-name">
        {getTrimmedWellNamePlusNumber(WellName, WellNumber) ?? "N/A"}
      </div>
    </div>
  );
};

export default HoverCardHeader;
