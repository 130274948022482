import {
  DataTitle,
  Font,
  HoverLabel,
  Layout,
  LayoutAxis,
  Legend,
  Margin,
} from "plotly.js";

export const defaultConfig = {
  responsive: true,
  displayModeBar: false,
};

export const generalLayout: Partial<Layout> = {
  hovermode: "x unified",
  showlegend: true,
  autosize: true,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  dragmode: "zoom",
  uirevision: 1,
};

export const legendLayout: Partial<Legend> = {
  orientation: "h",
  y: -0.25,
  x: -0.25,
  font: {
    size: 12,
    color: "#9E9E9E",
  },
  traceorder: "normal",
};

export const hoverLabelLayout: Partial<HoverLabel> = {
  bgcolor: "rgba(0,0,0,0.8)",
  bordercolor: "rgba(0,0,0,0.8)",
  font: {
    size: 12,
    color: "rgba(255,255,255)",
  },
};

export const marginLayout: Partial<Margin> = {
  t: 10,
  b: 60,
  l: 30,
  r: 10,
  pad: 5,
};

export const fontLayout: Partial<Font> = {
  size: 12,
  family: "Roboto, Arial",
};

export const xAxisLayout: Partial<LayoutAxis> = {
  autorange: true,
  automargin: true,
  color: "rgba(255, 255, 255, 0.7)",
  linecolor: "rgba(117, 117, 117, 1)",
  griddash: "dot",
  gridcolor: "rgba(117, 117, 117, 1)",
  showgrid: true,
};

export const xAxisFont: Partial<Font> = {
  family: "Roboto, Arial",
  color: "#9E9E9E",
  size: 12,
};

export const yAxisLayout: Partial<LayoutAxis> = {
  tickfont: {
    size: 12,
  },
  hoverformat: ",.0f",
  automargin: true,
  color: "rgba(255, 255, 255, 0.7)",
  griddash: "dot",
  gridcolor: "rgba(117, 117, 117, 1)",
  linecolor: "rgba(117, 117, 117, 1)",
  showgrid: true,
};

export const yAxisTitle: Partial<DataTitle> = {
  standoff: 5,
  font: {
    family: "Roboto, Arial",
    color: "#9E9E9E",
    size: 12,
  },
};
