import {
  WellData,
  WellPanelSectionType,
} from "../../../../types/panels/wellPanel/wellPanel";

import {
  WELL_INFO_DECLINE_CURVE_ATTRIBUTES,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelAttributeUnits from "../../../../customHooks/panels/usePanelAttributeUnits";
import usePanelFormattingRule from "../../../../customHooks/panels/usePanelFormattingRule";

import { formatAttributeByKey } from "../../../../utils/formatters/attributeFormatter";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../common/WellPanelAccordion";

interface DeclineCurveParametersProps {
  data: WellData;
  isExpanded: boolean;
  onChange: (type: WellPanelSectionType) => void;
}

const DeclineCurveParameters = ({
  data,
  isExpanded,
  onChange,
}: DeclineCurveParametersProps) => {
  const { formatRateAttributes, formatBFactorAttributes } =
    usePanelFormattingRule();
  const { buildPanelAttributeVal } = usePanelAttributeUnits();
  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.DECLINE_CURVE_PARAMETERS_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={false}
    >
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.label}
            value={formatAttributeByKey(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_ALGORITHM.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.label}
            value={buildPanelAttributeVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.key,
              `${formatRateAttributes(
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.key,
                data[
                  WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_INITIAL_RATE.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.label}
            value={formatRateAttributes(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_DECLINE_RATE.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.label}
            value={formatBFactorAttributes(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_B_FACTOR.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.label}
            value={formatAttributeByKey(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.key,
              data[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.OIL_START.key] as string
            )}
          />
        </div>
      </div>
      <div className="well-info-container with-bottom-border">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.label}
            value={formatAttributeByKey(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_ALGORITHM.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.label}
            value={buildPanelAttributeVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.key,
              `${formatRateAttributes(
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.key,
                data[
                  WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_INITIAL_RATE.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.label}
            value={formatRateAttributes(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_DECLINE_RATE.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.label}
            value={formatBFactorAttributes(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_B_FACTOR.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.label}
            value={formatAttributeByKey(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.key,
              data[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.GAS_START.key] as string
            )}
          />
        </div>
      </div>
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.label}
            value={formatAttributeByKey(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_ALGORITHM.key
              ] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.label}
            value={buildPanelAttributeVal(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.key,
              `${formatRateAttributes(
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.key,
                data[
                  WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_INITIAL_RATE.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.label}
            value={formatRateAttributes(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_DECLINE_RATE.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.label}
            value={formatBFactorAttributes(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.key,
              data[
                WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_B_FACTOR.key
              ] as number
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.label}
            value={formatAttributeByKey(
              WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.key,
              data[WELL_INFO_DECLINE_CURVE_ATTRIBUTES.WATER_START.key] as string
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default DeclineCurveParameters;
