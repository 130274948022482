import { FC, ReactNode } from "react";

import { styled } from "@mui/material";

const CustomTabPanel = styled("div")({
  padding: "16px 24px",
});

const TabPanel: FC<{
  activePanel: number;
  value: number;
  children: ReactNode;
}> = ({ activePanel, value, children }) => (
  <CustomTabPanel hidden={value !== activePanel}>
    {value === activePanel && <div>{children}</div>}
  </CustomTabPanel>
);

export default TabPanel;
