import { StateCreator } from "zustand";

import { ExtentInfoSlice } from "../../../../types/map/gis/store";

const extentInfoSlice: StateCreator<ExtentInfoSlice> = (set, get) => ({
  extentInfo: { isLoading: false, data: [], error: "" },
  updateExtentInfo: (extentInfo) =>
    set(() => ({
      extentInfo: {
        ...get().extentInfo,
        ...extentInfo,
      },
    })),
});

export { extentInfoSlice };
