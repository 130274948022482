import useExportCardStore from "../../../store/exportCard/exportCardStore";
import useQueryBuilderStore from "../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";

import useUwiChangeHandler from "./useUwiChangeHandler";

const useUwiFileUploadHandler = () => {
  const updateExportUwiList = useExportCardStore(
    (state) => state.updateExportUwiList
  );

  const updateUploadedUWIFile = useUWISearchStore(
    (state) => state.updateUploadedUWIFile
  );
  const isLoadingUwiFileUpload = useUWISearchStore(
    (state) => state.isLoadingUwiFileUpload
  );
  const setIsLoadingUWIFileUpload = useUWISearchStore(
    (state) => state.setIsLoadingUWIFileUpload
  );
  const setIfFileUploadFormatNotAllowed = useUWISearchStore(
    (state) => state.setIfFileUploadFormatNotAllowed
  );

  const setIsValidUwiFile = useUWISearchStore(
    (state) => state.setIsValidUwiFile
  );

  const resetUWIsToSearch = useUWISearchStore(
    (state) => state.resetUWIsToSearch
  );
  const resetUWIStates = useUWISearchStore((state) => state.resetUWIStates);

  // UWI Attrib Check for Export
  const updateIsQBUpdated = useQueryBuilderStore(
    (state) => state.updateIsQBUpdated
  );

  const { handleUwiFileOnChange } = useUwiChangeHandler();

  const handleUpload = async (file: File) => {
    setIfFileUploadFormatNotAllowed(false);
    updateUploadedUWIFile(null);
    resetUWIStates();
    setIsLoadingUWIFileUpload(true);

    const spinnerDisplayedPromise: Promise<void> = new Promise((resolve) => {
      // Resolve the promise when the spinner is displayed
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    spinnerDisplayedPromise.then(() => {
      const reader = new FileReader();
      reader.onloadend = async (e: ProgressEvent<FileReader>) => {
        if (file.type === "text/plain" || file.name.endsWith(".csv")) {
          handleUwiFileOnChange(e.target?.result as string, file);
          setIfFileUploadFormatNotAllowed(false);
        } else {
          setIfFileUploadFormatNotAllowed(true);
          setIsValidUwiFile(false);
          setIsLoadingUWIFileUpload(false);
        }
      };
      reader.readAsText(file);
    });

    updateIsQBUpdated(true);
    updateUploadedUWIFile(file);
  };

  const handleRemoveUpload = () => {
    if (isLoadingUwiFileUpload) return;
    setIfFileUploadFormatNotAllowed(false);
    setIsValidUwiFile(false);
    updateExportUwiList([]);
    resetUWIsToSearch();
    resetUWIStates();
  };

  return {
    handleRemoveUpload,
    handleUpload,
  };
};

export default useUwiFileUploadHandler;
