import { useEffect, useState } from "react";

import { FormControlLabel } from "@mui/material";

import classNames from "classnames";
import { Accordion as CLAccordion } from "component-library";

import {
  BOTTOM_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../constants/constants";
import * as mapSettings from "../../constants/map/mapSettings";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import useMapStore from "../../store/map/mapStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import useRecordType from "../../customHooks/common/useRecordType";
import useWellStyleAccordion from "../../customHooks/map/mapSettings/useWellStyleAccordion";
import usePrevious from "../../customHooks/usePrevious";

import { isLayerSelected } from "../../utils/map/mapSettings";
import { getWellStylingControls } from "../../utils/map/settings/settings";

import AccordionSection from "../common/AccordionSection";
import CustomCheckbox from "../common/CustomCheckbox";
import Select from "../common/Select";
import TooltipInfo from "../common/TooltipInfo";
import TooltipInfoIcon from "../common/icons/TooltipInfoIcon";
import { CustomTooltip } from "./CustomTooltip";
import ColorByAccordion from "./colorByAccordion/ColorByAccordion";

const WellStyleAccordion = () => {
  const [isPermitsChecked, setIsPermitsChecked] = useState(true);

  const layers = useMapStore((state) => state.layers);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const initialGridSearchMade = useDataGridStore(
    (state) => state.initialGridSearchMade
  );

  const isHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.isHighlightSelectedSpots
  );
  const toggleIsHighlightSelectedSpots = useMapSettingsStore(
    (state) => state.toggleIsHighlightSelectedSpots
  );

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const updateMapHoverEnabled = useMapHoverStore(
    (state) => state.updateMapHoverEnabled
  );
  const mapHoverEnabled = useMapHoverStore((state) => state.mapHoverEnabled);
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);

  const { wellPermitCardDisabled, handleLayerStyleChange, handleToggleLayer } =
    useWellStyleAccordion();

  const { searchedRecordType } = useRecordType();

  const prevSearchRecordType = usePrevious(searchedRecordType);

  useEffect(() => {
    if (
      searchedRecordType !== prevSearchRecordType ||
      isLayerSelected(PERMIT_SPOTS, layers)
    ) {
      setIsPermitsChecked(true);
    } else {
      setIsPermitsChecked(false);
    }
  }, [isPermitsChecked, searchedRecordType, prevSearchRecordType, layers]);

  return (
    <CLAccordion
      isDefaultOpen
      title="Well & Permit Styling"
      className="cl-custom-accordion"
    >
      {getWellStylingControls(searchedRecordType)?.HighlightSelectedWell && (
        <FormControlLabel
          className="mapsetting-label"
          control={
            <CustomCheckbox
              checked={isHighlightSelectedSpots}
              disabled={!selectedGridDataKeys.length}
              onChange={() => toggleIsHighlightSelectedSpots()}
            />
          }
          label={
            <>
              <span
                className={`${
                  !selectedGridDataKeys.length ? "disabled-option" : ""
                }`}
              >
                {mapSettings.WELL_STYLING_HIGHLIGHT_SELECTED_WELL_CONTROL.label}
              </span>
              {!selectedGridDataKeys.length && (
                <CustomTooltip
                  title={"Search required and select well/s"}
                  placement="left"
                  arrow
                >
                  <span className="tooltip-info">
                    <TooltipInfoIcon />
                  </span>
                </CustomTooltip>
              )}
            </>
          }
        />
      )}

      {getWellStylingControls(searchedRecordType)?.WellPermitCard && (
        <FormControlLabel
          className="mapsetting-label"
          control={
            <CustomCheckbox
              checked={mapHoverEnabled}
              disabled={wellPermitCardDisabled}
              onChange={() => updateMapHoverEnabled(!mapHoverEnabled)}
            />
          }
          label={
            <>
              <span
                className={classNames({
                  "disabled-option": wellPermitCardDisabled,
                })}
              >
                {mapSettings.WELL_STYLING_WELL_PERMIT_CARD_CONTROL.label}
              </span>
              {wellPermitCardDisabled && (
                <CustomTooltip
                  title={
                    "Search required, and turn on Surface Hole Location or Bottom Hole Location or Well Path "
                  }
                  placement="left"
                  arrow
                >
                  <span className="tooltip-info">
                    <TooltipInfoIcon />
                  </span>
                </CustomTooltip>
              )}
            </>
          }
        />
      )}

      {getWellStylingControls(searchedRecordType)?.WellPaths && (
        <FormControlLabel
          className="mapsetting-label"
          control={
            <CustomCheckbox
              checked={layers[2].isSelected}
              disabled={currentZoom < 12}
              onChange={() => handleToggleLayer(WELL_PATH_AND_STICKS)}
            />
          }
          label={
            <>
              <span className={`${currentZoom < 12 ? "disabled-option" : ""}`}>
                {mapSettings.WELL_STYLING_WELL_PATHS_CONTROL.label}
              </span>
              {currentZoom < 12 && (
                <TooltipInfo text={"Min zoom 12"} position="left" />
              )}
            </>
          }
        />
      )}

      {getWellStylingControls(searchedRecordType)?.PermitSpots && (
        <FormControlLabel
          className="mapsetting-label"
          control={
            <CustomCheckbox
              checked={isPermitsChecked}
              onChange={() => {
                setIsPermitsChecked(!isPermitsChecked);
                if (initialGridSearchMade) {
                  handleToggleLayer(PERMIT_SPOTS);
                }
              }}
            />
          }
          label={
            <span>{mapSettings.WELL_STYLING_PERMIT_SPOTS_CONTROL.label}</span>
          }
        />
      )}

      {getWellStylingControls(searchedRecordType)?.SurfaceHoleLocation && (
        <AccordionSection
          hasCheckbox
          title={mapSettings.WELL_STYLING_SURFACE_HOLE_LOCATION_CONTROL.label}
          isSelected={layers[0].isSelected}
          onSelect={() => handleToggleLayer(WELL_SPOTS)}
        >
          <Select
            label="Size"
            labelId="surface-size"
            value={layerStyles[WELL_SPOTS].size}
            options={mapSettings.WELL_SPOT_SIZES}
            onChange={(e) =>
              handleLayerStyleChange(e, WELL_SPOTS, mapSettings.SIZE)
            }
            className="custom-select"
          />
        </AccordionSection>
      )}

      {getWellStylingControls(searchedRecordType)?.BottomHoleLocation && (
        <AccordionSection
          hasCheckbox
          title={mapSettings.WELL_STYLING_BOTTOM_HOLE_LOCATION_CONTROL.label}
          infoMessage={currentZoom < 12 ? "Min zoom 12" : undefined}
          disabled={currentZoom < 12}
          isSelected={layers[1].isSelected}
          onSelect={() => handleToggleLayer(BOTTOM_WELL_SPOTS)}
        >
          <Select
            label="Size"
            labelId="bottom-size"
            disabled={currentZoom < 12}
            value={layerStyles[BOTTOM_WELL_SPOTS].size}
            options={mapSettings.WELL_SPOT_SIZES}
            onChange={(e) =>
              handleLayerStyleChange(e, BOTTOM_WELL_SPOTS, mapSettings.SIZE)
            }
            className="custom-select"
          />
        </AccordionSection>
      )}
      <ColorByAccordion />
    </CLAccordion>
  );
};

export default WellStyleAccordion;
