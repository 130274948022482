import moment from "moment";

import { PRODUCTION_PLOT } from "../../constants/constants";

export const extractGData = (collection) => {
  const extractedGDataList = [];

  if (!collection || collection.length < 1) return [];

  collection.forEach((entry) => {
    entry.groups.forEach((child) => {
      let name;
      if (child.name !== "ALL") {
        name = child.name;
      }
      child.gdata.forEach((d) => {
        if (name) {
          extractedGDataList.push({
            ...d,
            name,
            ...(child.wellNumber && { wellNumber: child.wellNumber }),
            ...(child.wellName && { wellName: child.wellName }),
            ...(child.uwi && { uwi: child.uwi }),
          });
        } else {
          extractedGDataList.push(d);
        }
      });
    });
  });

  return extractedGDataList;
};

export const groupCollectionByFields = (collection, groupByFields) => {
  const groupedByValues = {};

  collection.forEach((entry) => {
    let composedKey = "";
    groupByFields.forEach((groupByField) => {
      composedKey =
        composedKey === ""
          ? entry[groupByField]
          : composedKey + "," + entry[groupByField];
    });

    const entryToPush = {
      ...entry.pdata.mdata,
      production: entry.production,
    };

    if (groupedByValues[composedKey]) {
      groupedByValues[composedKey].push(entryToPush);
    } else {
      groupedByValues[composedKey] = [entryToPush];
    }
  });

  return groupedByValues;
};

export const groupDataByFields = (collection, groupByFields) => {
  const groupedByValues = {};

  collection.forEach((entry) => {
    let composedKey = "";
    groupByFields.forEach((groupByField) => {
      composedKey = entry.wellName
        ? `${entry[groupByField]} - ${entry.wellName}`
        : entry[groupByField];
    });

    const entryToPush = {
      ...entry.pdata,
      production: entry.production,
      ...(entry.uwi && { uwi: entry.uwi }),
      ...(entry.wellNumber && { wellNumber: entry.wellNumber }),
      ...(entry.wellName && { wellName: entry.wellName }),
    };

    if (groupedByValues[composedKey]) {
      groupedByValues[composedKey].push(entryToPush);
    } else {
      groupedByValues[composedKey] = [entryToPush];
    }
  });

  return groupedByValues;
};

export const aggregateValues = (collection, type) => {
  switch (type) {
    case "average":
      return collection.reduce((a, b, _, { length }) => a + b / length, 0);
    default:
      return collection.reduce((a, b) => a + b, 0);
  }
};

export const getFormattedXByChart = (chartToShow, value) => {
  if (chartToShow === PRODUCTION_PLOT) {
    const tempDate = moment(value).format("YYYY-MM-DD") + "T00:00:00.000+00:00";
    const newDate = convertTZ(new Date(tempDate), "UTC");

    return newDate;
  }
  return value;
};

export const convertTZ = (date, tzString) => {
  if (!date) {
    return;
  }
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
};

export const convertStringToDate = (strDate) => {
  if (!strDate) return "";

  const date = moment(strDate).toISOString();
  return new Date(date);
};
