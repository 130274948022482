import { FC, useEffect, useState } from "react";

import { TextField } from "@mui/material";
import { GridFilterItem, useGridApiContext } from "@mui/x-data-grid-premium";

import {
  ExpressionBuilderProps,
  VarcharFilterItem,
} from "../../../../../../../types/grid/filter/filter";

import {
  FILTER_EQUALS_OPERATOR,
  FILTER_TYPE_EXPRESSION_BUILDER,
  FILTER_VARCHAR_EXPRESSION_BUILDER_OPERATORS,
  IS_ANY_OF,
  IS_EMPTY,
  IS_NOT_EMPTY,
} from "../../../../../../../constants/grid";

import useDataGridStore from "../../../../../../../store/grid/dataGridStore";

import usePrevious from "../../../../../../../customHooks/usePrevious";

import { generate5DigitUniqueRandomNumber } from "../../../../../../../utils";
import FilterFooter from "../../FilterFooter";
import FilterOperatorField from "./FilterOperatorField";

const VarcharFilter: FC<ExpressionBuilderProps> = ({ field, cancelPanel }) => {
  const apiRef = useGridApiContext();
  const [disableFilterButton, setDisableFilterButton] = useState(true);
  const filterItemDefault = {
    operator: FILTER_EQUALS_OPERATOR.key,
    payload: FILTER_EQUALS_OPERATOR.payload,
    value: "",
    field: field,
  };

  const [filterItem, setFilterItem] =
    useState<VarcharFilterItem>(filterItemDefault);
  const prevFilterItem = usePrevious(filterItem);

  const filterModel = useDataGridStore((state) => state.filterModel);
  const updateColumnsFilterType = useDataGridStore(
    (state) => state.updateColumnsFilterType
  );
  const columnsFilterType = useDataGridStore(
    (state) => state.columnsFilterType
  );

  const handleInputValueChange = (value: string) => {
    setFilterItem({
      ...filterItem,
      value,
    });
  };

  const handleFilterOperatorChange = (operator: string) => {
    const filterOperator = FILTER_VARCHAR_EXPRESSION_BUILDER_OPERATORS.find(
      (item) => item.key === operator
    );

    if (filterOperator) {
      setFilterItem((prevItem) => ({
        ...prevItem,
        operator,
        payload: filterOperator.payload,
        value: prevItem.value,
      }));
    }
  };

  const handleCancel = () => {
    cancelPanel();
  };

  const applyFilter = () => {
    if (filterItem.value !== "") {
      const newFilterItem: GridFilterItem = {
        field: "",
        operator: "",
      };
      if (filterItem.id) {
        newFilterItem.id = filterItem.id;
        newFilterItem.field = field;
        newFilterItem.operator = filterItem.operator;
        newFilterItem.value = filterItem.value;
      } else {
        const filterIds = filterModel.items.map((value) => value.id);
        const newFilterId = generate5DigitUniqueRandomNumber(filterIds);
        newFilterItem.id = newFilterId;
        newFilterItem.field = field;
        newFilterItem.operator = filterItem.operator;
        newFilterItem.value = filterItem.value;

        setFilterItem({
          ...filterItem,
          id: newFilterId,
        });
      }

      //this only updates the filter model in grid, will not trigger api call
      //datagrid will call the api once changes are detected
      if (columnsFilterType[field] !== FILTER_TYPE_EXPRESSION_BUILDER) {
        const newFilterItems = filterModel.items.filter(
          (item) => item.field !== field
        );
        newFilterItems.push(newFilterItem);

        const newFilterModel = {
          ...filterModel,
          items: newFilterItems,
        };

        apiRef.current.setFilterModel(newFilterModel);
      } else {
        apiRef.current.upsertFilterItem(newFilterItem);
      }
    } else {
      if (filterItem.id) {
        apiRef.current.deleteFilterItem(filterItem);
        setFilterItem(filterItemDefault);
      }
    }

    updateColumnsFilterType(field, FILTER_TYPE_EXPRESSION_BUILDER);
    handleCancel();
    apiRef.current.hideColumnMenu();
  };

  useEffect(() => {
    if (filterItem.value !== prevFilterItem?.["value"]) {
      if (prevFilterItem?.["value"] !== "" && filterItem.value === "") {
        setDisableFilterButton(true);
      } else {
        setDisableFilterButton(false);
      }
    } else {
      if (
        filterItem.operator !== prevFilterItem?.["operator"] &&
        filterItem.value !== ""
      ) {
        setDisableFilterButton(false);
      } else {
        setDisableFilterButton(true);
      }
    }
  }, [filterItem]);

  useEffect(() => {
    if (filterModel?.items.length) {
      const item = filterModel.items.find(
        (item) =>
          item.field === field &&
          item.operator !== IS_ANY_OF &&
          item.operator !== IS_EMPTY
      );

      if (item) {
        const operatorValue = FILTER_VARCHAR_EXPRESSION_BUILDER_OPERATORS.find(
          (op) => op.key === item.operator
        );

        if (operatorValue) {
          setFilterItem((prevItem) => ({
            ...prevItem,
            id: item.id,
            payload: operatorValue.payload,
            value: item.value,
            operator: operatorValue.key,
          }));
        }
      }
    }
    setDisableFilterButton(true);
  }, []);

  return (
    <div
      className="grid-filter-number-panel-container"
      key={`${field}-exp-builder-box`}
    >
      <FilterOperatorField
        operators={FILTER_VARCHAR_EXPRESSION_BUILDER_OPERATORS}
        value={filterItem.operator}
        onChange={(val: string) => handleFilterOperatorChange(val)}
      />
      {filterItem.operator !== IS_EMPTY &&
        filterItem.operator !== IS_NOT_EMPTY && (
          <TextField
            fullWidth
            className={"filter-number-value-input"}
            variant="standard"
            label={"Value"}
            value={filterItem.value}
            onChange={(event) => {
              handleInputValueChange(event.target.value);
            }}
            onKeyDown={(e) => {
              // prevent the input keyboard events from bubbling up
              // when the input is inside a menuItem component
              e.stopPropagation();
            }}
          />
        )}

      <FilterFooter
        buttons={[
          {
            text: "Cancel",
            disabled: false,
            variant: "text",
            onClick: handleCancel,
          },
          {
            text: "Apply Filter",
            disabled: disableFilterButton,
            variant: "outlined",
            onClick: applyFilter,
          },
        ]}
        paddingTop="20px"
      />
    </div>
  );
};

export default VarcharFilter;
