import useOktaValidationStore from "../../store/okta/oktaStore";

import MapArea from "../MapArea";
import AppLayout from "../layout/AppLayout";

/* eslint-disable */
const Home = () => {
  const oktaValidated = useOktaValidationStore((state) => state.oktaValidated);

  return <AppLayout>{oktaValidated && <MapArea />}</AppLayout>;
};

export default Home;
