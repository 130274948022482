import { useEffect, useMemo, useState } from "react";

import { SnackbarCloseReason } from "@mui/base";
import { Close as CloseIcon } from "@mui/icons-material";
import { IconButton, Snackbar } from "@mui/material";

import classNames from "classnames";

import { MessageBarProps } from "../../types/common/messageBar";

import usePanelsStore from "../../store/panels/panelsStore";
import useStore from "../../store/useStore";

const MessageBar: React.FC<MessageBarProps> = ({
  closeButton = false,
  vertical = "bottom",
  horizontal = "center",
  autoHideDuration = 3000,
  severity = "default",
}) => {
  const toastMessage = useStore((state) => state.toastMessage);
  const updateToastMessage = useStore((state) => state.updateToastMessage);
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const updateToastProps = useStore((state) => state.updateToastProps);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const [isOpen, setIsOpen] = useState(false);

  const onClose = (
    _event?: Event | React.SyntheticEvent<any, Event>,
    reason?: SnackbarCloseReason
  ) => {
    if (reason === "clickaway") return;
    setIsOpen(false);
    updateToastMessage(null);
    updateToastProps({
      vertical: "bottom",
      horizontal: "center",
      closeButton: false,
      severity: "default",
    });
  };

  useEffect(() => {
    setIsOpen(Boolean(toastMessage));
  }, [toastMessage]);

  const toasterLeft = useMemo(() => {
    if (isOpenAppLevelNav && isOpenLeftSidePanel) {
      return {
        left: "calc(50% + 200px)",
      };
    } else if (isOpenAppLevelNav) {
      return {
        left: "calc(50% + 100px)",
      };
    }
    return {
      left: "50%",
    };
  }, [isOpenAppLevelNav, isOpenLeftSidePanel]);

  return (
    <Snackbar
      className={classNames({
        "tgs-snackbar": typeof toastMessage === "string",
        "tgs-snackbar-custom-node": typeof toastMessage !== "string",
      })}
      ContentProps={{
        className: classNames({
          "tgsccl-system-color-palette status success custom-alert":
            severity === "success",
          "tgsccl-system-color-palette status info custom-alert":
            severity === "info",
          "tgsccl-system-color-palette status error custom-alert":
            severity === "error",
          "tgsccl-system-color-palette status warning custom-alert":
            severity === "warning",
        }),
      }}
      anchorOrigin={{ vertical, horizontal }}
      open={isOpen}
      onClose={onClose}
      autoHideDuration={autoHideDuration}
      message={toastMessage}
      style={toasterLeft}
      {...(closeButton && {
        action: (
          <IconButton size="small" color="inherit" onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        ),
      })}
    />
  );
};

export default MessageBar;
