import * as filter_constants from "./filter";

/**
 * for additional filter operator @params refer to https://v5.mui.com/x/api/data-grid/grid-filter-operator/
 * important to follow the GridFilterOperator required objects
 *
 * NOTE: for getApplyFilterFn()
 * return null for now since filtering is done in the server side
 * returning null will skip the filtering callback in the client or datagrid side
 */

export const FILTER_IS_ANY_OPERATOR = {
  key: filter_constants.IS_ANY_OF,
  label: "Is Any Of",
  payload: "any",
};

export const FILTER_IS_EMPTY_OPERATOR = {
  key: filter_constants.IS_EMPTY,
  label: "NULLS",
  payload: "emp",
};

export const FILTER_EQUALS_OPERATOR = {
  key: filter_constants.EQUALS,
  label: "Equals",
  payload: "equal",
};

export const FILTER_GREATER_THAN_OPERATOR = {
  key: filter_constants.GREATER_THAN,
  label: "Greater Than",
  payload: "gt",
  value: filter_constants.GREATER_THAN, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_GREATER_THAN_OR_EQUAL_TO_OPERATOR = {
  key: filter_constants.GREATER_THAN_OR_EQUAL_TO,
  label: "Greater Than Or Equal To",
  payload: "gte",
  value: filter_constants.GREATER_THAN_OR_EQUAL_TO, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_LESS_THAN_OPERATOR = {
  key: filter_constants.LESS_THAN,
  label: "Less Than",
  payload: "lt",
  value: filter_constants.LESS_THAN, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_LESS_THAN_OR_EQUAL_TO_OPERATOR = {
  key: filter_constants.LESS_THAN_OR_EQUAL_TO,
  label: "Less Than Or Equal To",
  payload: "lte",
  value: filter_constants.LESS_THAN_OR_EQUAL_TO, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_BEFORE_OPERATOR = {
  key: filter_constants.BEFORE,
  label: "Before",
  payload: "lt",
  value: filter_constants.BEFORE, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_AFTER_OPERATOR = {
  key: filter_constants.AFTER,
  label: "After",
  payload: "gt",
  value: filter_constants.AFTER, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_YEAR_OPERATOR = {
  key: filter_constants.YEAR,
  label: "Year",
  payload: "yr",
  value: filter_constants.YEAR, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_BETWEEN_OPERATOR = {
  key: filter_constants.BETWEEN,
  label: "Between",
  payload: "btw",
  value: filter_constants.BETWEEN, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_STARTS_WITH_OPERATOR = {
  key: filter_constants.STARTS_WITH,
  label: "Begins With",
  payload: "starts",
  value: filter_constants.STARTS_WITH, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_ENDS_WITH_OPERATOR = {
  key: filter_constants.ENDS_WITH,
  label: "Ends With",
  payload: "ends",
  value: filter_constants.ENDS_WITH, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_CONTAINS_OPERATOR = {
  key: filter_constants.CONTAINS,
  label: "Contains",
  payload: "contains",
  value: filter_constants.CONTAINS, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};

export const FILTER_IS_NOT_EMPTY_OPERATOR = {
  key: filter_constants.IS_NOT_EMPTY,
  label: "Is Not Empty",
  payload: "nemp",
  value: filter_constants.IS_NOT_EMPTY, //grid operatorValue equivalent
  getApplyFilterFn: () => {
    return null;
  },
};
