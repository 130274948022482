import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../types/common/api";
import { DeleteSavedSearch } from "../../types/panels/savedSearchPanel/hooks";

import config from "../../configs/appSettings";

import useSavedSearchHelper from "./useSavedSearchHelper";

const useDeleteSavedSearch = () => {
  const { error, setError, catchError } = useSavedSearchHelper();

  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<number | null>(null);

  const deleteSavedSearch: DeleteSavedSearch = async (id) => {
    setIsLoading(true);
    setData(null);
    setError(null);

    try {
      await axios.post(
        `${config.endpoints.wellService}api/searches/delete/${id}`
      );
      setData(id);
    } catch (error) {
      const err = error as AxiosError<APIErrorResponse>;
      catchError(err);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    deleteSavedSearch,
  };
};

export default useDeleteSavedSearch;
