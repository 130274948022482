const AppFooter = () => {
  const currentyear = new Date().getFullYear();

  return (
    <div className="arlas-cs-footer">
      <nav>
        <ul>
          <li>&copy;{currentyear} TGS</li>
          <li>
            <a
              href="https://www.tgs.com/products-services/well-data"
              rel="noopener noreferrer"
              target="_blank"
            >
              Well Data Analytics
            </a>
          </li>
          <li>
            <a
              href="https://www.tgs.com/trademark"
              rel="noopener noreferrer"
              target="_blank"
            >
              Trademark
            </a>
          </li>
          <li>
            <a
              href="https://www.tgs.com/privacy-policy"
              rel="noopener noreferrer"
              target="_blank"
            >
              Privacy Policy
            </a>
          </li>
          <li>
            <a
              href="https://www.tgs.com/terms-and-conditions"
              rel="noopener noreferrer"
              target="_blank"
            >
              Terms & Conditions
            </a>
          </li>
          <li className="contact-us">
            <a
              href="https://www.tgs.com/well-data-analytics-customer-support"
              rel="noopener noreferrer"
              target="_blank"
            >
              Contact Us
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default AppFooter;
