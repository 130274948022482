import { useCallback, useMemo } from "react";

import classNames from "classnames";

import {
  MAP_SCALE_BAR,
  MAP_SEARCH_PIPELINES,
} from "../../../constants/constants";
import { CARBON_STORAGE_LABEL } from "../../../constants/map/carbonStorage";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapStore from "../../../store/map/mapStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";
import usePanelsStore from "../../../store/panels/panelsStore";

import CarbonStorageLegend from "./CarbonStorageLegend";
import MapSearchPipelinesLegend from "./MapSearchPipelinesLegend";

const LayerInfoOverlay = () => {
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const gridHeight = useDataGridStore((state) => state.gridHeight);
  const layers = useMapStore((state) => state.layers);

  const layerLegends = useMapSettingsStore((state) => state.layerLegends);
  const currentZoom = useMapStore((state) => state.currentZoom);

  const isLayerOn = useCallback(
    (layerName: string) => {
      return !!layers.find((layer) => layer.name === layerName)?.isSelected;
    },
    [layers]
  );

  const isMapScaleBarOn = useMemo(() => isLayerOn(MAP_SCALE_BAR), [isLayerOn]);

  const getLeftPosition = useMemo(() => {
    let value = 10;
    if (isOpenAppLevelNav && isOpenLeftSidePanel) {
      value = 474;
    } else if (isOpenAppLevelNav) {
      value = 74;
    }

    return value;
  }, [isOpenAppLevelNav, isOpenLeftSidePanel]);

  const getBottomPosition = useMemo(() => {
    const mapScaleBarDistance = isMapScaleBarOn ? 40 : 0;
    let bottom = `calc(${gridHeight} + ${mapScaleBarDistance}px + 30px)`;

    if (!showGrid && !showGridHeader) {
      bottom = `calc(${mapScaleBarDistance}px + 30px)`;
    } else if (!showGrid && showGridHeader) {
      bottom = `calc(${mapScaleBarDistance}px + 85px)`;
    }

    return bottom;
  }, [gridHeight, showGrid, showGridHeader, isMapScaleBarOn]);

  const getLegendComponent = useCallback(
    (layerName: string) => {
      if (layerName === MAP_SEARCH_PIPELINES && currentZoom >= 12) {
        return <MapSearchPipelinesLegend key={layerName} />;
      } else if (layerName === CARBON_STORAGE_LABEL) {
        return <CarbonStorageLegend key={layerName} />;
      } else {
        return null;
      }
    },
    [currentZoom]
  );

  return (
    <div
      className={classNames("layer-info-overlay", {
        hide: !layerLegends.length,
      })}
      style={{ left: getLeftPosition, bottom: getBottomPosition }}
    >
      {layerLegends.map((layerName) => getLegendComponent(layerName))}
    </div>
  );
};

export default LayerInfoOverlay;
