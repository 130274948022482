import { FC } from "react";

import { CustomTooltip } from "../../../mapSettingsAccordions/CustomTooltip";

interface UoMButtonProps {
  disabled: boolean;
  children: React.ReactNode;
}

const UoMButton: FC<UoMButtonProps> = ({ disabled, children }) => {
  return (
    <>
      {disabled ? (
        <CustomTooltip title="Please execute a search to change UOM" arrow>
          <span>{children}</span>
        </CustomTooltip>
      ) : (
        children
      )}
    </>
  );
};

export default UoMButton;
