import Loading from "./Loading";

interface LoadingBackDropProps {
  isOpen: boolean;
  className: string;
}

const LoadingBackDrop = ({ isOpen, className = "" }: LoadingBackDropProps) => {
  if (isOpen) {
    return (
      <div className={`loading-back-drop ${className}`}>
        <Loading />
      </div>
    );
  } else {
    return <></>;
  }
};

export default LoadingBackDrop;
