import ImageLayer from "ol/layer/Image";
import TileLayer from "ol/layer/Tile";
import ImageWMS from "ol/source/ImageWMS";
import TileWMS from "ol/source/TileWMS";

import { WMS } from "../../../types/map";

import { tileLoadFetcher } from "./tileUtils";

const getWMSLayer = (layer: WMS, definitionKey: string, token: string) => {
  if (layer.tileSupport === undefined) layer["tileSupport"] = true;

  if (layer.tileSupport) {
    const layerAttributes = {
      title: definitionKey,
      opacity: layer.layerOpacity ?? 1.0,
      source: getTileWMS(layer, token),
      ...(layer.minZoom && {
        minZoom: layer.minZoom,
      }),
      ...(layer.zIndex && { zIndex: layer.zIndex }),
    };
    return new TileLayer(layerAttributes);
  } else {
    const layerAttributes = {
      title: definitionKey,
      opacity: layer.layerOpacity ?? 1.0,
      source: getImageWMS(layer, token),
      ...(layer.minZoom && {
        minZoom: layer.minZoom,
      }),
      ...(layer.zIndex && { zIndex: layer.zIndex }),
    };
    return new ImageLayer(layerAttributes);
  }
};

const getTileWMS = (layer: WMS, token: string) => {
  return new TileWMS({
    url: layer.url,
    ...(layer.params && { params: layer.params }),
    tileLoadFunction: (tile, src) => {
      tileLoadFetcher(tile, src, token);
    },
    crossOrigin: "anonymous",
    serverType: "geoserver",
  });
};

const getImageWMS = (layer: WMS, token: string) => {
  return new ImageWMS({
    url: layer.url,
    ...(layer.params && { params: layer.params }),
    imageLoadFunction: (image, src) => {
      tileLoadFetcher(image, src, token);
    },
    crossOrigin: "anonymous",
    serverType: "geoserver",
  });
};

export { getWMSLayer };
