import {
  SavedSearchDeleteAction,
  SavedSearchDeleteState,
} from "../types/panels/savedSearchPanel/component";

import { SAVED_SEARCH_DELETE_STATE_ACTIONS } from "../constants/panels/savedSearchPanel/component";

export const savedSearchDeleteStateReducer = (
  state: SavedSearchDeleteState,
  action: SavedSearchDeleteAction
) => {
  const { type } = action;
  switch (type) {
    case SAVED_SEARCH_DELETE_STATE_ACTIONS.SET:
      return action.payload.savedSearchDeleteState;
    case SAVED_SEARCH_DELETE_STATE_ACTIONS.DELETE_MODE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          onDeleteMode: action.payload.status,
        },
      };
    case SAVED_SEARCH_DELETE_STATE_ACTIONS.DELETING:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          onDeleteMode: false,
          isDeleting: true,
        },
      };
    case SAVED_SEARCH_DELETE_STATE_ACTIONS.DELETED:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          onDeleteMode: false,
          isDeleting: false,
        },
      };
    case SAVED_SEARCH_DELETE_STATE_ACTIONS.CLEAR:
      return {};
    default:
      throw Error("Unknown action: " + type);
  }
};
