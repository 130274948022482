import { create } from "zustand";

import authSettingsStore from "./authSettingsStore";
import chartConfigurationStore from "./chartConfigurationStore";
import mapSettingsStore from "./mapSettingsStore";
import modularityStore from "./modularityStore";
import saveSearchStore from "./saveSearchStore";
import wellCardSettingsStore from "./wellCardSettingsStore";

const useStore = create((set, get) => ({
  // Zoom to Fit
  enableZoomFit: false,
  toggleEnableZoomFit: (status) => set((state) => ({ enableZoomFit: status })),

  // Toast
  toastMessage: "",
  toastProps: {
    vertical: "bottom",
    horizontal: "center",
    closeButton: false,
    severity: "default",
  },
  updateToastProps: (toastProps) => set((state) => ({ toastProps })),
  updateToastMessage: (toastMessage) => set((state) => ({ toastMessage })),

  // Search States
  lastSearchedBy: "",
  updateLastSearchedBy: (lastSearchedBy) =>
    set((state) => ({ lastSearchedBy })),

  // SAVE FOR FUTURE USE
  // lastUWISearchTime: null,
  // lastAttributeSearchTime: null,
  // updateLastSearchTime: (searchType) =>
  //   set((state) => {
  //     if (searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
  //       return { lastAttributeSearchTime: moment().format() };
  //     } else {
  //       return { lastUWISearchTime: moment().format() };
  //     }
  //   }),

  ...authSettingsStore(set, get),
  ...mapSettingsStore(set, get),
  ...wellCardSettingsStore(set, get),
  ...chartConfigurationStore(set, get),
  ...modularityStore(set, get),
  ...saveSearchStore(set, get),
}));

export default useStore;
