import { StateCreator } from "zustand";

import { MapHoverSlice } from "../../../types/map/wellSpot/store";

const mapHoverSlice: StateCreator<MapHoverSlice> = (set, get) => ({
  mapHoverEnabled: true,
  updateMapHoverEnabled: (mapHoverEnabled) =>
    set((state) => ({ mapHoverEnabled })),

  mapHoveredFeatures: [],
  updateMapHoveredFeatures: (mapHoveredFeatures) =>
    set(() => ({ mapHoveredFeatures })),

  mapHoverData: undefined,
  updateMapHoverData: (mapHoverData) => set((state) => ({ mapHoverData })),
});

export { mapHoverSlice };
