import { StateCreator } from "zustand";

import { ChartWellSelectionStates } from "../../../types/map/selection/store";

import { clone } from "../../../utils";

const sliceResetFns = new Set<() => void>();

const resetChartWellSelectionSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialChartWellSelectionStates = {
  selectedWellUwisKeys: [],
  selectedWellUwis: {},
};

const chartWellSelectionSlice: StateCreator<ChartWellSelectionStates> = (
  set
) => {
  sliceResetFns.add(() => set(clone(initialChartWellSelectionStates)));
  return {
    ...initialChartWellSelectionStates,
    updateSelectedWellUwisKeys: (selectedWellUwisKeys) =>
      set(() => ({ selectedWellUwisKeys })),
    updateSelectedWellUwis: (selectedWellUwis) =>
      set(() => ({ selectedWellUwis })),
  };
};

export { chartWellSelectionSlice, resetChartWellSelectionSlice };
