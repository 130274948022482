import {
  WellData,
  WellPanelSectionType,
} from "../../../../types/panels/wellPanel/wellPanel";

import {
  WELL_INFO_STATISTICS_ATTRIBUTES,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelAttributeUnits from "../../../../customHooks/panels/usePanelAttributeUnits";

import { formatAttributeByKey } from "../../../../utils/formatters/attributeFormatter";

import WellInfoText from "../../../common/WellInfoText";
import WellPanelAccordion from "../common/WellPanelAccordion";

interface StatisticsProps {
  data: WellData;
  isExpanded: boolean;
  onChange: (type: WellPanelSectionType) => void;
}
const Statistics = ({ data, isExpanded, onChange }: StatisticsProps) => {
  const { buildPanelAttributeVal } = usePanelAttributeUnits();

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.STATISTICS_SECTION);
  };
  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.STATISTICS_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={false}
    >
      <div className="well-info-container">
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.FIRST_MONTH.label}
            value={formatAttributeByKey(
              WELL_INFO_STATISTICS_ATTRIBUTES.FIRST_MONTH.key,
              data[WELL_INFO_STATISTICS_ATTRIBUTES.FIRST_MONTH.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.LAST_MONTH.label}
            value={formatAttributeByKey(
              WELL_INFO_STATISTICS_ATTRIBUTES.LAST_MONTH.key,
              data[WELL_INFO_STATISTICS_ATTRIBUTES.LAST_MONTH.key] as string
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.CUM_OIL.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.CUM_OIL.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.CUM_OIL.key,
                data[WELL_INFO_STATISTICS_ATTRIBUTES.CUM_OIL.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL.key,
                data[WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL_PLUS_2.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL_PLUS_2.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL_PLUS_2.key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.MAX_OIL_PLUS_2.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_OIL_ULTIMATE.label
            }
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_OIL_ULTIMATE.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_OIL_ULTIMATE.key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_OIL_ULTIMATE.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.CUM_GAS.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.CUM_GAS.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.CUM_GAS.key,
                data[WELL_INFO_STATISTICS_ATTRIBUTES.CUM_GAS.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS.key,
                data[WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS_PLUS_2.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS_PLUS_2.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS_PLUS_2.key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.MAX_GAS_PLUS_2.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_GAS_ULTIMATE.label
            }
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_GAS_ULTIMATE.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_GAS_ULTIMATE.key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_GAS_ULTIMATE.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.CUM_WATER.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.CUM_WATER.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.CUM_WATER.key,
                data[WELL_INFO_STATISTICS_ATTRIBUTES.CUM_WATER.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER.key,
                data[WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER.key] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER_PLUS_2.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER_PLUS_2.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER_PLUS_2.key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.MAX_WATER_PLUS_2.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_WATER_ULTIMATE.label
            }
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_WATER_ULTIMATE.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_WATER_ULTIMATE
                  .key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.WELL_FORECAST_WATER_ULTIMATE
                    .key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_LIQUID.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_LIQUID.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_LIQUID.key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_LIQUID.key
                ] as number
              )}`
            )}
          />
        </div>
        <div className="well-info-item">
          <WellInfoText
            label={WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_GAS.label}
            value={buildPanelAttributeVal(
              WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_GAS.key,
              `${formatAttributeByKey(
                WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_GAS.key,
                data[
                  WELL_INFO_STATISTICS_ATTRIBUTES.CUM_INJECTION_GAS.key
                ] as number
              )}`
            )}
          />
        </div>
      </div>
    </WellPanelAccordion>
  );
};

export default Statistics;
