import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { PopperProps } from "@mui/material";

import { WellData } from "../../types/common/wells";
import { MapHoverDataViaHoverFeature } from "../../types/map/wellSpot/store";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";
import {
  PERMIT_PANEL_RIGHT_CLICK_ACTION,
  WELL_PANEL_DISTINCTION_MENU_ACTION,
  WELL_PANEL_HOVER_ACTION,
  WELL_PANEL_RIGHT_CLICK_ACTION,
} from "../../constants/panels/wellPanel/wellPanel";

import useMapHoverStore from "../../store/map/hover/mapHoverStore";

import usePrevious from "../../customHooks/usePrevious";

import PermitCard from "./PermitCard";
import WellCard from "./WellCard";

const MapHoverCardManager: FC = () => {
  const [open, setOpen] = useState(false);
  const [wellData, setWellData] = useState<WellData>();
  const [anchorEl, setAnchorEl] = useState<PopperProps["anchorEl"] | null>(
    null
  );
  const prevWellData = usePrevious(wellData);

  const mapHoverData = useMapHoverStore((state) => state.mapHoverData);
  const updateMapHoverData = useMapHoverStore(
    (state) => state.updateMapHoverData
  );

  const isMenuPlacementLeftStart = !!document.querySelector(
    `div[data-popper-placement="left-start"].distinction-menu`
  );

  const isTriggerByMenu = useMemo(
    () =>
      mapHoverData?.trigger === WELL_PANEL_DISTINCTION_MENU_ACTION ||
      mapHoverData?.trigger === WELL_PANEL_RIGHT_CLICK_ACTION ||
      mapHoverData?.trigger === PERMIT_PANEL_RIGHT_CLICK_ACTION,
    [mapHoverData?.trigger]
  );

  const placement = useMemo(() => {
    if (isTriggerByMenu) {
      if (isMenuPlacementLeftStart) {
        return "left-start";
      }
      return "right-start";
    }

    return undefined;
  }, [isTriggerByMenu, isMenuPlacementLeftStart]);

  const generateGetBoundingClientRect = (x = 0, y = 0) => {
    return {
      getBoundingClientRect: () => {
        return new DOMRect(x, y, 0, 0);
      },
    };
  };

  const handleOnHoverAway = useCallback(() => {
    if (mapHoverData) {
      updateMapHoverData(undefined);
    }
  }, [mapHoverData, updateMapHoverData]);

  useEffect(() => {
    if (
      mapHoverData &&
      mapHoverData.info !== prevWellData &&
      (mapHoverData.trigger === WELL_PANEL_HOVER_ACTION ||
        mapHoverData.trigger === WELL_PANEL_DISTINCTION_MENU_ACTION)
    ) {
      let xOffset = 0;
      if (placement === "left-start") {
        xOffset -= mapHoverData.offset + 22;
      }
      const targetEl = generateGetBoundingClientRect(
        mapHoverData.anchor.x + xOffset,
        mapHoverData.anchor.y
      );
      setAnchorEl(targetEl);
      setWellData(mapHoverData.info);
      setOpen(true);
    } else {
      if (wellData === undefined && prevWellData !== undefined) {
        setOpen(false);
        setAnchorEl(null);
        setWellData(undefined);
      }
    }
  }, [mapHoverData, placement]);

  return (
    wellData && (
      <>
        {wellData.RecordType === RECORD_TYPES.WELL ? (
          <WellCard
            open={open}
            anchorEl={anchorEl}
            wellData={wellData}
            isArrowEnabled={!isTriggerByMenu}
            placement={placement}
            offset={(mapHoverData as MapHoverDataViaHoverFeature).offset}
            className={isTriggerByMenu ? "hover-card-via-distinction-menu" : ""}
            handleOnHoverAway={handleOnHoverAway}
          />
        ) : (
          <PermitCard
            open={open}
            anchorEl={anchorEl}
            wellData={wellData}
            isArrowEnabled={!isTriggerByMenu}
            placement={placement}
            offset={(mapHoverData as MapHoverDataViaHoverFeature).offset}
            className={isTriggerByMenu ? "hover-card-via-distinction-menu" : ""}
            handleOnHoverAway={handleOnHoverAway}
          />
        )}
      </>
    )
  );
};

export default MapHoverCardManager;
