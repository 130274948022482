import { FC } from "react";

import { Box, Button } from "@mui/material";

import { FilterButtonsProps } from "../../../../../types/grid";

const FilterFooter: FC<FilterButtonsProps> = ({ buttons, ...boxProps }) => {
  return (
    <Box className="grid-filter-footer" {...boxProps}>
      {buttons.length > 0 &&
        buttons.map((button, index) => {
          return (
            <Button
              variant={button.variant ?? "contained"}
              disabled={button.disabled ?? false}
              sx={button.sx ?? {}}
              onClick={button.onClick}
              key={`filterFooterButton${index}`}
            >
              {button.text}
            </Button>
          );
        })}
    </Box>
  );
};

export default FilterFooter;
