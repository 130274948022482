import { roundNumber } from "../../utils/helper";

const useUnitValueConversion = () => {
  const convertFtValueToMeter = (val: number) => {
    return roundNumber(val * 0.3048, 14);
  };

  const convertMeterValueToFt = (val: number) => {
    return roundNumber(val * 3.280399, 14);
  };

  const convertKilometerValueToMeter = (val: number) => {
    return roundNumber(val * 1000, 14);
  };

  const convertKilometerValueToFt = (val: number) => {
    return roundNumber(val * 3280.8399, 14);
  };

  return {
    convertFtValueToMeter,
    convertMeterValueToFt,
    convertKilometerValueToMeter,
    convertKilometerValueToFt,
  };
};

export default useUnitValueConversion;
