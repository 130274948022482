import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import GVerseGeographix from "../../../../assets/images/release-notes/GVerseGeographix.png";
import MapColorByEconomicAttributes from "../../../../assets/images/release-notes/MapColorByEconomicAttributes.png";
import PipelineByMapSearch from "../../../../assets/images/release-notes/PipelineByMapSearch.png";

const Version20240502: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240502"}
      onChange={handleChange("Version20240502")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.05.02
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          05/02/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes Adding Pipelines by MAPSearch™, updated Land
          Grids and Offshore Blocks, Delaware Basin Well Economics, Well Styling
          by Economic Attributes and some enhancements to Data Export
        </CustomTypography>
      </AccordionDetails>

      <AccordionContent>• Pipelines Provided by MAPSearch™ </AccordionContent>
      <AccordionSubContent>
        • Users can view pipelines (Gathering, Interstate, and Intrastate) by
        toggling the Pipelines by MAPSearch™ through Culture Layers in MAP
        SETTINGS.
      </AccordionSubContent>
      <AccordionSubContent>
        • NOTE: The map must be at zoom level 12 and above to see Pipelines.
      </AccordionSubContent>

      <AccordionImage
        src={PipelineByMapSearch}
        width="100%"
        draggable={false}
      />

      <AccordionContent>
        • Land Grid & Offshore Blocks Provided by GVERSE GeoGraphix®
      </AccordionContent>
      <AccordionSubContent>
        • Users can view Land Grid by toggling the Land Grid by GVERSE
        GeoGraphix® through Culture Layers in MAP SETTINGS, included layers:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Townships <br />
          ▪ Sections <br />
          ▪ Blocks <br />
          ▪ Surveys <br />
          ▪ Sub surveys <br />
          ▪ Abstracts <br />
          ▪ Subdivisions <br />
          ▪ Lots <br />▪ Tracts and …
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionSubContent>
        • Users can view Offshore Blocks by toggling the Offshore Blocks by
        GVERSE GeoGraphix® through Culture Layers in MAP SETTINGS, included
        info:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Protractions <br />
          ▪ Districts <br />
          ▪ Blocks <br />
          ▪ Fairways <br />
          ▪ Leases <br />
          ▪ Units <br />
          ▪ CSB <br />
          ▪ Pipelines <br />▪ Platforms and …
        </AccordionSubContent>
      </AccordionSubContent>

      <AccordionImage src={GVerseGeographix} width="100%" draggable={false} />

      <AccordionContent>
        • Added Well Economics for Delaware Basin Wells
      </AccordionContent>
      <AccordionSubContent>
        • After Midland Basin well economics data release in Dec 2023, now users
        can access the new TGS Delaware Basin well economics dataset via the
        Well Data Analytics software.
      </AccordionSubContent>
      <AccordionSubContent>
        • Default cost input and economic output values for all Delaware Basin
        wells
      </AccordionSubContent>
      <AccordionSubContent>• Exportable economic model</AccordionSubContent>

      <AccordionContent>
        • Well Economics Improvements – Map Color by Economic Attributes
      </AccordionContent>
      <AccordionSubContent>
        • For a better map visualizations and data evaluation, after running a
        search users have the option to change the color of well spots and well
        path by Well Economic attributes such as:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ IRR <br />
          ▪ Breakeven Price <br />
          ▪ NPV <br />
          ▪ Payback <br />
          ▪ LOE <br />▪ And more ...
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage
        src={MapColorByEconomicAttributes}
        width="100%"
        draggable={false}
      />
    </Accordion>
  );
};

export default Version20240502;
