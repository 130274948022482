import { KeyboardEvent } from "react";

// For integer only inputs on text field
// Used on onChange (to catch when user paste decimal values)
export const removeDecimal = (value: string) => {
  return value.replace(".", "");
};

// For integer only inputs on text field
// Used on onKeyDown
export const integersOnlyKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
  if (e.key === ".") e.preventDefault();
};
