import { StateCreator } from "zustand";

import { OktaValidationSlice } from "../../types/okta/store";

const oktaValidationSlice: StateCreator<OktaValidationSlice> = (set, get) => ({
  oktaValidated: false,
  updateOktaValidated: (oktaValidated) =>
    set(() => ({
      oktaValidated,
    })),
});

export { oktaValidationSlice };
