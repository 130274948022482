import {
  Feature,
  PermitSelectedForPermitPanel,
} from "../../../../types/map/layers/carto";
import {
  GetIconPoinTypeConfig,
  GetIconPointTypeUpdateTriggers,
  GetIconSizeTriggers,
} from "../../../../types/map/layers/iconPointTypeConfig";

import { DYNAMIC_PERMIT_SPOTS } from "../../../../constants/constants";
import { SELECTED_FOR_WELLCARD_MULTIPLIER } from "../../../../constants/map/cartoStyles";

import { getColorByAttribute } from "../../../../data/map/utils/colorAggregator";

import { createPermitIcon, svgToDataUrl } from "./generatePermitIcon";

export const getIconPoinTypeConfig: GetIconPoinTypeConfig = ({
  layerName,
  zoom,
  size,
  selectedPermitIds,
  colorByAttribute,
  layerColors,
  compressedWellSpotData,
  compressedWellSpotInfo,
  defaultColor,
  selectedCardPermitIDs,
  isHighlightSelectedSpots,
}) => {
  if (layerName === DYNAMIC_PERMIT_SPOTS) {
    return {
      pointType: "icon",
      iconSizeUnits: "pixels",
      iconSizeScale: 2.5,
      // To enable hovering on the inside of permit
      // Has issues when hovering over both permit and well
      // iconAlphaCutoff: 0,
      getIconSize: (f: Feature) =>
        (zoom + 1) *
        (size / 300) *
        getPermitCardSelectionMultiplier({ f, selectedCardPermitIDs }),
      getIcon: (f: Feature) => {
        const { PermitId } = f.properties;
        let isSelected = false;
        if (selectedPermitIds?.["P" + PermitId] && isHighlightSelectedSpots) {
          isSelected = true;
        }

        const innerColor = getColorByAttribute({
          colorByAttribute,
          compressedWellSpotData,
          compressedWellSpotInfo,
          feature: f,
          layerName,
          wellColorList: layerColors,
          defaultColor,
        }) as number[];

        const spotSizeMultiplier = getPermitCardSelectionMultiplier({
          f,
          selectedCardPermitIDs,
        });

        return {
          url: svgToDataUrl(
            createPermitIcon(isSelected, innerColor, spotSizeMultiplier)
          ),
          height: 52 * spotSizeMultiplier,
          width: 52 * spotSizeMultiplier,
        };
      },
    };
  } else {
    return {};
  }
};

export const getIconPointTypeUpdateTriggers = ({
  layerName,
  selectedPermitIds,
  compressedWellSpotData,
  legendColors,
  color,
  isHighlightSelectedSpots,
  selectedCardPermitIDs,
}: GetIconPointTypeUpdateTriggers) => {
  if (layerName === DYNAMIC_PERMIT_SPOTS) {
    return [
      selectedPermitIds,
      compressedWellSpotData,
      legendColors,
      color,
      isHighlightSelectedSpots,
      selectedCardPermitIDs,
    ];
  } else {
    return [];
  }
};

export const getIconSizeTriggers = ({
  layerName,
  selectedCardPermitIDs,
  zoom,
}: GetIconSizeTriggers) => {
  if (layerName === DYNAMIC_PERMIT_SPOTS) {
    return [selectedCardPermitIDs, zoom];
  }
  return [];
};

const isPermitSelectedForPermitPanel = ({
  f,
  selectedCardPermitIDs,
}: PermitSelectedForPermitPanel) => {
  const { PermitId } = f.properties;

  if (PermitId && selectedCardPermitIDs?.includes(PermitId)) {
    return true;
  }

  return false;
};

const getPermitCardSelectionMultiplier = ({
  f,
  selectedCardPermitIDs,
}: PermitSelectedForPermitPanel) => {
  const isSelected = isPermitSelectedForPermitPanel({
    f,
    selectedCardPermitIDs,
  });

  if (isSelected) return SELECTED_FOR_WELLCARD_MULTIPLIER;
  return 1;
};
