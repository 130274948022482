import { useMemo } from "react";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Button, IconButton } from "@mui/material";

import classNames from "classnames";

interface WellPanelPagination {
  currentPage: number;
  totalPages: number;
  onPageChange: (page: number) => void;
}

const WellPanelPagination = ({
  currentPage,
  totalPages,
  onPageChange,
}: WellPanelPagination) => {
  const paginationItems = useMemo(() => {
    const items = [];

    if (totalPages <= 3) {
      const pages = Array.from(Array(totalPages), (_, i) => i + 1);
      items.push(...pages);
    } else {
      if (totalPages - currentPage <= 1) {
        items.push("...");
        items.push(totalPages - 1);
        items.push(totalPages);
      } else {
        if (currentPage === 1) {
          items.push(currentPage);
          items.push(currentPage + 1);
        } else {
          items.push(currentPage - 1);
          items.push(currentPage);
        }
        items.push("...");
        items.push(totalPages);
      }
    }

    return items;
  }, [currentPage, totalPages]);

  const handlePageChange = (page: number) => {
    onPageChange(page);
  };

  return (
    <div className="custom-pagination-well-card">
      <span className="left-pagination-icon">
        <IconButton
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          data-testid="previous-page-btn"
        >
          <ChevronLeftIcon />
        </IconButton>
      </span>

      {paginationItems.map((item, index) =>
        typeof item === "string" ? (
          <span className="dotPaggination" key={`dot-${index}`}>
            {item}
          </span>
        ) : (
          <span className="pagination-numbers" key={`btn-${item}`}>
            <Button
              variant="text"
              onClick={() => handlePageChange(item)}
              className={classNames("pagination-numbers", {
                active: item === currentPage,
              })}
            >
              {item}
            </Button>
          </span>
        )
      )}
      <span className="right-pagination-icon">
        <IconButton
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          data-testid="next-page-btn"
        >
          <ChevronRightIcon />
        </IconButton>
      </span>
    </div>
  );
};

export default WellPanelPagination;
