import { BUBBLE_MAP, PERMIT_SPOTS, WELL_PATH_AND_STICKS } from "../constants";

export const SELECTED_FOR_WELLCARD_MULTIPLIER = 1.75;

export const LINE_WIDTH_PIXELS = {
  [WELL_PATH_AND_STICKS]: 6,
  [BUBBLE_MAP]: 1,
  [PERMIT_SPOTS]: 1.5,
  DEFAULT: 3,
};
