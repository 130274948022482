import { WellData } from "../../types/common/wells";
import { DataGridSelector } from "../../types/grid";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";

import { clone } from "../helper";

// used as initial or place holder value only. must be cloned deep if you need to reuse
export const INITIAL_DATA_GRID_SELECTOR: DataGridSelector = {
  byWellHeaderPermitKey: {},
  byPermitID: {},
  byParentIDandUWI: {},
  byWellID: {},
  groupedByUWID10: {},
  groupedByUWID12: {},
  groupedByParentWellID: {},
  groupedByBottomWellboreID: {},
  bottomWellboreIDByPWID: {},
};

export const getAllGridDataSelectors = (wellData: WellData[]) => {
  const selectors: DataGridSelector = clone(INITIAL_DATA_GRID_SELECTOR);

  return wellData.reduce((selectorObj: DataGridSelector, curr: WellData) => {
    selectorObj.byWellHeaderPermitKey[curr.WellHeaderPermitKey] = curr;

    switch (curr.RecordType) {
      case RECORD_TYPES.WELL:
        const parentWellIID = curr.ParentWellID;
        const bottomWellboreID = curr.BottomWellboreID ?? "";

        const wellID = curr.WellID;
        // soon to be removed
        const uwi = curr.UWI ?? "";
        const uwi10 = parentWellIID + (curr.UWI?.slice(0, 10) ?? "");
        const uwi12 = parentWellIID + (curr.UWI?.slice(0, 12) ?? "");
        if (wellID) selectorObj.byWellID[wellID] = curr;
        selectorObj.byParentIDandUWI[curr.ParentWellID + uwi] = curr;
        selectorObj.bottomWellboreIDByPWID[parentWellIID] = [
          ...(selectorObj.bottomWellboreIDByPWID[parentWellIID] ?? []),
          curr.BottomWellboreID,
        ];

        // soon to be removed
        const maxUWI10Data = selectorObj.groupedByUWID10?.[uwi10];
        const maxUWI12Data = selectorObj.groupedByUWID12?.[uwi12];
        if (parseInt(maxUWI10Data?.UWI ?? "0") < parseInt(curr?.UWI ?? "0"))
          selectorObj.groupedByUWID10[uwi10] = curr;

        if (parseInt(maxUWI12Data?.UWI ?? "0") < parseInt(curr?.UWI ?? "0"))
          selectorObj.groupedByUWID12[uwi12] = curr;

        const maxParentWellIDDataUWI =
          selectorObj.groupedByParentWellID?.[parentWellIID]?.UWI ?? "0";
        const maxBottomWellboreIDDataUWI =
          selectorObj.groupedByBottomWellboreID?.[bottomWellboreID]?.UWI ?? "0";

        if (maxParentWellIDDataUWI.localeCompare(curr?.UWI ?? "0") < 0) {
          selectorObj.groupedByParentWellID[parentWellIID] = curr;
        }

        if (maxBottomWellboreIDDataUWI.localeCompare(curr?.UWI ?? "0") < 0) {
          selectorObj.groupedByBottomWellboreID[bottomWellboreID] = curr;
        }
        break;

      case RECORD_TYPES.PERMIT:
        const permitID = curr.PermitID;
        if (permitID) selectorObj.byPermitID[permitID] = curr;
        break;
    }

    return selectorObj;
  }, selectors);
};
