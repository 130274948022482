import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from "@mui/material";

import { PRODUCTION_GROUP_BY_ATTRIBUTES } from "../../../../constants/charts/productionPlotDataFields";

const groupByAttributesList = PRODUCTION_GROUP_BY_ATTRIBUTES;
const GroupByAttributesSelection = (props) => {
  return (
    <Grid className="group-wells-grid" spacing={2} container>
      <Grid item>
        <Typography>Group Wells By Attribute</Typography>
      </Grid>
      <Grid item>
        <FormControl fullWidth>
          <InputLabel id="group-by-select-label" variant="outlined" shrink>
            Group By
          </InputLabel>
          <Select
            id="groupby-select"
            label="Group By"
            value={props.selected}
            onOpen={props.onOpen}
            onChange={props.onChange}
            renderValue={(selected) => {
              if (!selected) {
                return "--- Select an option ---";
              }

              let attribute = groupByAttributesList.find(
                (attr) => attr.name === props.selected
              );
              return attribute.displayName;
            }}
            displayEmpty
          >
            {groupByAttributesList.map((attribute, index) => {
              return (
                <MenuItem key={index} value={attribute.name}>
                  {attribute.displayName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const AggregatePicker = (props) => {
  return (
    <FormControl error={props.isInputAggregateError} variant="standard">
      <Grid container spacing={3}>
        <Grid item>
          <FormLabel id="aggregate-label">Display by</FormLabel>
        </Grid>
        <Grid item>
          <RadioGroup
            name="radio-buttons-group"
            value={props.aggregate}
            onChange={props.handleOnAggregateTypeChange}
          >
            {props.aggregatesSelection.map((selection) => (
              <FormControlLabel
                key={selection.id}
                className="display-by-label-group"
                value={selection.name}
                control={<Radio size="small" />}
                label={
                  selection.id === 3 ? (
                    <GroupByAttributesSelection
                      selection={selection}
                      onChange={props.handleOnGroupByAttributeChange}
                      onOpen={props.handleOnGroupBySelectorOpen}
                      selected={props.selectedAttr}
                    ></GroupByAttributesSelection>
                  ) : (
                    <Typography>{selection.displayName}</Typography>
                  )
                }
              />
            ))}
          </RadioGroup>
          <FormHelperText>
            {props.inputAggregateValidationMessage}
          </FormHelperText>
        </Grid>
      </Grid>
    </FormControl>
  );
};

export default AggregatePicker;
