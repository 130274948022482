import { useCallback } from "react";

import { Curves } from "../../../types/panels/wellPanel/dataLakeType";

import { logTypeValues } from "../../../constants/panels/wellPanel/logs";

import { formatNumber } from "../../../utils/formatters/numberFormatter";

import useUnitOfMeasure from "../../common/useUnitOfMeasure";
import useUnitValueConversion from "../../common/useUnitValueConversion";

const useLogsData = () => {
  const { isMetricOnSearch } = useUnitOfMeasure();
  const {
    convertFtValueToMeter,
    convertMeterValueToFt,
    convertKilometerValueToFt,
    convertKilometerValueToMeter,
  } = useUnitValueConversion();

  const getLogTypeDisplayName = (logType: string) => {
    let mappedLogType = "";
    const convertedLogType = logType?.toLowerCase();

    switch (convertedLogType) {
      case logTypeValues.STANDARD.key:
        mappedLogType = logTypeValues.STANDARD.displayName;
        break;
      case logTypeValues.WORK_STATION_READY.key:
        mappedLogType = logTypeValues.WORK_STATION_READY.displayName;
        break;
      case logTypeValues.RASTER.key:
        mappedLogType = logTypeValues.RASTER.displayName;
        break;
      case logTypeValues.MUD.key:
        mappedLogType = logTypeValues.MUD.displayName;
        break;
      case logTypeValues.LITHOLOGY.key:
        mappedLogType = logTypeValues.LITHOLOGY.displayName;
        break;
      case logTypeValues.ANALYTIC_READY_LAS.key:
        mappedLogType = logTypeValues.ANALYTIC_READY_LAS.displayName;
        break;
      case logTypeValues.PETROPHYSICAL_LOGS.key:
        mappedLogType = logTypeValues.PETROPHYSICAL_LOGS.displayName;
        break;
      default:
        break;
    }

    return mappedLogType;
  };

  const getLASDescription = (curvesList: Curves[]) => {
    let description = "";
    if (curvesList) {
      const curveClassCdList = curvesList
        .map((curve) => curve.CurveClassCd)
        .filter((curveClass) => curveClass);
      const uniqueList = curveClassCdList.filter((curveClass, index, arr) => {
        return arr.indexOf(curveClass) === index;
      });

      description = uniqueList.join(", ");
    }

    return description;
  };

  const getLogDepthConversionValue = useCallback(
    (val: number, uom?: string) => {
      if (!uom) return formatNumber(val);

      if (isMetricOnSearch) {
        switch (uom.toUpperCase()) {
          case "KM":
            return formatNumber(convertKilometerValueToMeter(val));
          case "F":
          case "FT":
            return formatNumber(convertFtValueToMeter(val));
          default:
            return formatNumber(val);
        }
      } else {
        switch (uom.toUpperCase()) {
          case "KM":
            return formatNumber(convertKilometerValueToFt(val));
          case "M":
          case "MT":
            return formatNumber(convertMeterValueToFt(val));
          default:
            return formatNumber(val);
        }
      }
    },
    [isMetricOnSearch]
  );

  return {
    getLogTypeDisplayName,
    getLASDescription,
    getLogDepthConversionValue,
  };
};

export default useLogsData;
