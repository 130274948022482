import { StateCreator } from "zustand";

import { AlertMessageSlice } from "../../../types/map/alertStore";

const alertMessageSlice: StateCreator<AlertMessageSlice> = (set, get) => ({
  alertState: undefined,
  updateAlertState: (alertState) => set(() => ({ alertState })),
});

export { alertMessageSlice };
