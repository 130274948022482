import * as attributes from "../wellPanel/wellPanelAttributes";

export const perforationsColumns = [
  {
    field: attributes.PERF_DATE.key,
    headerName: attributes.PERF_DATE.label,
    width: 100,
    sortable: false,
  },
  {
    field: attributes.PERF_TOP.key,
    headerName: attributes.PERF_TOP.label,
    width: 100,
    sortable: false,
    type: "number",
  },
  {
    field: attributes.PERF_BOTTOM.key,
    headerName: attributes.PERF_BOTTOM.label,
    width: 100,
    sortable: false,
    type: "number",
  },
];
