import { StateCreator } from "zustand";

import {
  MapDrawStates,
  MapShapeFileSlice,
} from "../../../../types/map/draw/store";

const sliceResetFns = new Set<() => void>();

const resetMapShapeFileSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialMapShapeFileStates = {
  shapeId: "",
  shapeFilePolygons: [],
  isUploadingShapeFile: false,
};

const mapShapeFileSlice: StateCreator<
  MapDrawStates,
  [],
  [],
  MapShapeFileSlice
> = (set) => {
  sliceResetFns.add(() => set(initialMapShapeFileStates));

  return {
    ...initialMapShapeFileStates,
    updateShapeId: (shapeId) => set(() => ({ shapeId })),

    addShapeFilePolygon: (polygon) =>
      set((state) => ({
        shapeFilePolygons: [...state.shapeFilePolygons, polygon],
        polygons: [...state.polygons, polygon],
      })),
    removeShapeFilePolygon: (polygonToRemove) =>
      set((state) => ({
        shapeFilePolygons: state.shapeFilePolygons.filter(
          (polygon) => polygon.id !== polygonToRemove.id
        ),
        polygons: state.polygons.filter(
          (polygon) => polygon.id !== polygonToRemove.id
        ),
      })),
    updateShapeFilePolygons: (shapeFilePolygons) =>
      set(() => ({ shapeFilePolygons })),

    updateIsUploadingShapeFile: (status) =>
      set(() => ({ isUploadingShapeFile: status })),
  };
};

export { mapShapeFileSlice, resetMapShapeFileSlice };
