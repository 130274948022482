import { ChangeEvent, FC, useCallback } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  FormControl,
  IconButton,
  InputAdornment,
  OutlinedInput,
} from "@mui/material";

import classNames from "classnames";
import PropTypes from "prop-types";

import { SearchFieldProps } from "../../../../../../types/grid";

const SearchField: FC<SearchFieldProps> = (props) => {
  const { searchItem, onSearchItemChange, disabled, onResetSearch } = props;

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onSearchItemChange(event);
    },
    [onSearchItemChange]
  );

  return (
    <FormControl
      className={classNames("grid-filter-form", {
        "hidden-label": !searchItem,
      })}
      fullWidth
    >
      <OutlinedInput
        type="text"
        label="Search"
        placeholder="Search"
        disabled={disabled}
        fullWidth
        onChange={handleInputChange}
        onKeyDown={(e) => {
          // prevent the input keyboard events from bubbling up
          // when the input is inside a menuItem component
          e.stopPropagation();
        }}
        value={searchItem}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="reset search text"
              onClick={onResetSearch}
              edge="end"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

SearchField.defaultProps = {
  searchItem: "",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onSearchItemChange: () => {},
  disabled: false,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onResetSearch: () => {},
};

SearchField.propTypes = {
  searchItem: PropTypes.string.isRequired,
  onSearchItemChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  onResetSearch: PropTypes.func,
};

export default SearchField;
