import { create } from "zustand";

import { QueryBuilderState } from "../../../types/panels/searchPanel/queryBuilder";

import {
  currentBoundsSlice,
  queryBuilderEditorSlice,
  queryBuilderInfoSlice,
  queryBuilderUtilitySlice,
} from "./queryBuilderSlice";

const useQueryBuilderStore = create<QueryBuilderState>()((...a) => ({
  ...queryBuilderInfoSlice(...a),
  ...queryBuilderEditorSlice(...a),
  ...queryBuilderUtilitySlice(...a),
  ...currentBoundsSlice(...a),
}));

export default useQueryBuilderStore;
