import { FC } from "react";

import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import classNames from "classnames";

import { PressureDataWellPanel } from "../../../../../types/panels/wellPanel/pressureDataType";

import { PRESSURE_DATA_FIELDS_DISPLAY } from "../../../../../constants/panels/wellPanel/pressureData";

import WellInfoText from "../../../../common/WellInfoText";

interface PressureDataSectionProps {
  data: PressureDataWellPanel[];
  expandedAccordions: string[];
  accordionClicked: (accordionName: string) => void;
}

const PressureDataSection: FC<PressureDataSectionProps> = ({
  data,
  expandedAccordions,
  accordionClicked,
}) => {
  return (
    <>
      {data.map((pressureData) => (
        <Accordion
          className={classNames("accordion-content", {
            expanded: expandedAccordions.includes(pressureData.TestName),
          })}
          key={pressureData.TestName}
          expanded={expandedAccordions.includes(pressureData.TestName)}
          onChange={() => accordionClicked(pressureData.TestName)}
        >
          <AccordionSummary
            expandIcon={<ExpandMore />}
            className="accordion-summary"
          >
            <div className="title">{pressureData.TestName}</div>
            <div className="formation">{pressureData.Formation}</div>
          </AccordionSummary>
          <AccordionDetails className="accordion-details">
            <div className="well-info-container">
              {PRESSURE_DATA_FIELDS_DISPLAY.map((field, index) => (
                <div
                  className={classNames("well-info-item", {
                    "full-width":
                      index === PRESSURE_DATA_FIELDS_DISPLAY.length - 1,
                  })}
                  key={field.propertyKey}
                >
                  <WellInfoText
                    label={field.label}
                    value={pressureData[field.propertyKey]}
                  />
                </div>
              ))}
            </div>
          </AccordionDetails>
        </Accordion>
      ))}
    </>
  );
};

export default PressureDataSection;
