import { useEffect, useState } from "react";

import { useOktaAuth } from "@okta/okta-react";

import OverviewMap from "ol/control/OverviewMap";
import { Tile as TileLayer } from "ol/layer";
import VectorTileLayer from "ol/layer/VectorTile";
import { XYZ } from "ol/source";
import { Fill, Stroke, Style } from "ol/style";

import config from "../../configs/appSettings";

import { params, projection } from "../../constants";
import { constructSource } from "../../utils";

const useMiniMap = () => {
  const { authState } = useOktaAuth();
  const [miniMap, setMiniMap] = useState<OverviewMap>();
  const { layerName, format } = config.endpoints.tgsGWCWMTS;

  const args = {
    authState,
    config,
    params: params(layerName, format),
    projection,
  };

  useEffect(() => {
    const vector = new VectorTileLayer({
      properties: {
        title: "overviewVector",
      },
      declutter: true,
      source: constructSource(args),
      style: new Style({
        stroke: new Stroke({
          color: "rgba(255, 255, 255, 0.2)",
          width: 2,
        }),
        fill: new Fill({
          color: "rgba(0,0,0,0)",
        }),
      }),
    });

    const overviewMapControl = new OverviewMap({
      // see in overviewmap-custom.html to see the custom CSS used
      className: "mini-map",
      layers: [
        new TileLayer({
          source: new XYZ({
            url: config.endpoints.darkGrayCanvasBaseMap,
          }),
        }),
        vector,
      ],
      collapseLabel: "\u00AB",
      label: "\u00BB",
      collapsed: false,
    });

    setMiniMap(overviewMapControl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    miniMap,
  };
};

export default useMiniMap;
