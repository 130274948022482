import { FC } from "react";

interface SectionCardProps {
  children?: React.ReactNode;
}

const SectionCard: FC<SectionCardProps> = ({ children }) => {
  return <div className="section-card-container">{children}</div>;
};

export default SectionCard;
