import * as wellinfo from "./wellInfo";

export const WELL_INFORMATION_EXPORT_CATEGORY = "well-information" as const;
export const WELL_CHARTS_EXPORT_CATEGORY = "well-charts" as const;
export const WELL_EXPORT_ALL = "all";

export const WELL_PANEL_INFORMATION_EXPORT_CATEGORY = {
  key: WELL_INFORMATION_EXPORT_CATEGORY,
  label: "Well Information",
};

export const WELL_PANEL_CHARTS_EXPORT_CATEGORY = {
  key: WELL_CHARTS_EXPORT_CATEGORY,
  label: "Charts",
};

export const WELL_INFORMATION_BY_KEY = {
  [wellinfo.HEADER_SECTION.key]: wellinfo.HEADER_SECTION,
  [wellinfo.STATISTICS_SECTION.key]: wellinfo.STATISTICS_SECTION,
  [wellinfo.DECLINE_CURVE_PARAMETERS_SECTION.key]:
    wellinfo.DECLINE_CURVE_PARAMETERS_SECTION,
  [wellinfo.PERFORATION_SECTION.key]: wellinfo.PERFORATION_SECTION,
  [wellinfo.TEST_SECTION.key]: wellinfo.TEST_SECTION,
  [wellinfo.PRESSURE_DATA_SECTION.key]: wellinfo.PRESSURE_DATA_SECTION,
  [wellinfo.COMPLETION_SECTION.key]: wellinfo.COMPLETION_SECTION,
  [wellinfo.CASING_SECTION.key]: wellinfo.CASING_SECTION,
  [wellinfo.FORMATION_SECTION.key]: wellinfo.FORMATION_SECTION,
  [wellinfo.PERMIT_SECTION.key]: wellinfo.PERMIT_SECTION,
};

export const PLOTS_AND_CHARTS_BY_KEY = {
  [wellinfo.GUN_BARREL_SECTION.key]: wellinfo.GUN_BARREL_SECTION,
  [wellinfo.PRODUCTION_PLOT_SECTION.key]: wellinfo.PRODUCTION_PLOT_SECTION,
};

export const WELL_INFORMATION_ARRAY_LIST = Object.keys(
  WELL_INFORMATION_BY_KEY
).map((key) => key);

export const PLOTS_AND_CHARTS_ARRAY_LIST = Object.keys(
  PLOTS_AND_CHARTS_BY_KEY
).map((key) => key);
