import { FC } from "react";

import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

interface FilterOperatorFieldProps {
  operators: {
    key: string;
    label: string;
    payload: string;
  }[];
  value: string;
  onChange: (val: string) => void;
}

const FilterOperatorField: FC<FilterOperatorFieldProps> = (props) => {
  const { operators, value, onChange } = props;

  return (
    <FormControl
      variant="standard"
      fullWidth
      className="grid-filter-operator-field"
    >
      <InputLabel className={"filter-operator-input-label"}>
        Filter Operator
      </InputLabel>
      <Select
        id="filter-operator-input-field"
        value={value}
        onChange={(event) => {
          onChange(event.target.value);
        }}
        label="Filter Operator"
        onBlur={(e) => {
          e.stopPropagation();
        }}
      >
        {operators.map((operator) => (
          <MenuItem
            value={operator.key}
            key={`${operator.key}-exp-operator-item`}
          >
            {operator.label === "NULLS" ? "Is Empty" : operator.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default FilterOperatorField;
