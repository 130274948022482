import { useEffect, useState } from "react";

import {
  BOTTOM_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_SPOTS,
} from "../../../constants/constants";

import useMapStore from "../../../store/map/mapStore";

import useLayerStylingByRecordType from "../../../customHooks/map/mapSettings/useLayerStylingByRecordType";
import useProcessLayerColors from "../../../customHooks/map/mapSettings/useProcessLayerColors";

import { isLayerSelected } from "../../../utils/map/mapSettings";

import AccordionSection from "../../common/AccordionSection";
import ColorBySelect from "./ColorBySelect";

const ColorByAccordion = () => {
  const layers = useMapStore((state) => state.layers);

  const {
    attributesForColoringByRecordType,
    layerStyleKeyByRecordType,
    updateLayerStyleColors,
  } = useLayerStylingByRecordType();

  useProcessLayerColors();

  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  useEffect(() => {
    let isAutoOpenAccordion = false;
    if (layerStyleKeyByRecordType === PERMIT_SPOTS) {
      isAutoOpenAccordion = isLayerSelected(PERMIT_SPOTS, layers);
    } else {
      isAutoOpenAccordion =
        isLayerSelected(WELL_SPOTS, layers) ||
        isLayerSelected(BOTTOM_WELL_SPOTS, layers);
    }

    setIsAccordionOpen(isAutoOpenAccordion);
  }, [layerStyleKeyByRecordType, layers]);

  return (
    <AccordionSection
      isDefaultOpen
      isSelected={isAccordionOpen}
      title="Surface & Bottom"
      className="custom-child-accordion"
      onSelect={() => setIsAccordionOpen(!isAccordionOpen)}
      hasCheckbox
    >
      <ColorBySelect
        layerName={layerStyleKeyByRecordType}
        colorByOptions={attributesForColoringByRecordType}
        updateLayerStyleColors={updateLayerStyleColors}
      />
    </AccordionSection>
  );
};

export default ColorByAccordion;
