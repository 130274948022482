import { CustomMenuSelectionData } from "../../types/common/customMenuSelection";

export const UNIT_OF_MEASURE = {
  IMPERIAL: { key: "imperial", label: "Imperial" },
  METRIC: { key: "metric", label: "Metric" },
} as const;

export const UNIT_OF_MEASURE_MENU_OPTION: CustomMenuSelectionData[] = [
  { label: UNIT_OF_MEASURE.IMPERIAL.label, key: UNIT_OF_MEASURE.IMPERIAL.key },
  { label: UNIT_OF_MEASURE.METRIC.label, key: UNIT_OF_MEASURE.METRIC.key },
];
