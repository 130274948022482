import { useEffect, useState } from "react";

import axios from "axios";

import { DataCurrenciesAPIResponseType } from "../../types/information/information";

import config from "../../configs/appSettings";

import useDataCurrencyStore from "../../store/dataCurrencies/dataCurrencyStore";

const API_BASE_URL = `${config.endpoints.wellService}api/data-currencies/sort-by`;

const useSortedDataCurrencies = (sortAttribute: string, sortOrder: string) => {
  const updateIsLoadingCurrencyData = useDataCurrencyStore(
    (state) => state.updateIsLoadingCurrencyData
  );
  const updateCurrencyData = useDataCurrencyStore(
    (state) => state.updateCurrencyData
  );
  const sortedCurrencyData = useDataCurrencyStore(
    (state) => state.currencyData
  );
  const isLoadingCurrencyData = useDataCurrencyStore(
    (state) => state.isLoadingCurrencyData
  );

  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const url = `${API_BASE_URL}/${sortAttribute}/${sortOrder}`;
        const response = await axios.get<DataCurrenciesAPIResponseType[]>(url);
        updateCurrencyData(response.data);
      } catch (error) {
        setError("Failed to fetch sorted data currencies.");
      } finally {
        updateIsLoadingCurrencyData(false);
      }
    };

    fetchData();
  }, [sortAttribute, sortOrder]);

  return { sortedCurrencyData, isLoadingCurrencyData, error };
};

export default useSortedDataCurrencies;
