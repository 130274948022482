import { useEffect, useState } from "react";

import { MAX_EXPORT } from "../../constants/constants";

import useExportCardStore from "../../store/exportCard/exportCardStore";
import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

const useR360GridExport = () => {
  const [isR360Disabled, setIsR360Disabled] = useState(true);

  const allWellGridDataLoading = useDataGridStore(
    (state) => state.allWellGridDataLoading
  );
  const gridTotalCount = useDataGridStore((state) => state.gridTotalCount);

  const isInvalidUWIText = useUWISearchStore((state) => state.isInvalidUWIText);

  const isSearchCriteriaChange = useExportCardStore(
    (state) => state.isSearchCriteriaChange
  );

  const allGridData = useDataGridStore((state) => state.allGridData);

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  useEffect(() => {
    if (
      allGridData.length === 0 ||
      (allGridData.length > 0 && allGridData.length > MAX_EXPORT) ||
      isInvalidUWIText ||
      allWellGridDataLoading ||
      gridTotalCount === 0 ||
      isSearchCriteriaChange
    ) {
      if (
        selectedGridDataKeys.length > 0 &&
        selectedGridDataKeys.length <= MAX_EXPORT
      ) {
        setIsR360Disabled(false);
      } else {
        setIsR360Disabled(true);
      }
    } else {
      setIsR360Disabled(false);
    }
  }, [
    allGridData,
    isInvalidUWIText,
    allWellGridDataLoading,
    gridTotalCount,
    selectedGridDataKeys,
  ]);

  return {
    isR360Disabled,
  };
};

export default useR360GridExport;
