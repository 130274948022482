const wellCardSettingsStore = (set, get) => ({
  closeMenuFlag: false,
  updateCloseMenuFlag: (closeMenuFlag) =>
    set((state) => ({
      closeMenuFlag,
    })),

  wellCardData: [],
  addWellCardData: (wellCardData) =>
    set((state) => ({
      wellCardData: [...get().wellCardData, wellCardData],
    })),
  updateWellCardDataByID: (id, wellCardData) =>
    set((state) => ({
      wellCardData: get().wellCardData.map((wellCardDatum) => {
        if (wellCardDatum.componentID === id) {
          return wellCardData;
        }
        return wellCardDatum;
      }),
    })),

  removeWellCardDataByID: (id) =>
    set((state) => ({
      wellCardData: get().wellCardData.filter(
        (wellCardDatum) => wellCardDatum.componentID !== id
      ),
    })),

  removeWellCardDataByUwiParentID: (uwi, parentID) =>
    set((state) => ({
      wellCardData: get().wellCardData.filter(
        (wellCardDatum) =>
          !(
            wellCardDatum.UWI === uwi && wellCardDatum.ParentWellID === parentID
          )
      ),
    })),

  setSelectedWellCardsOrdering: (wellCardData) =>
    set(() => ({
      wellCardData: [...wellCardData],
    })),

  // selectedWellCardPWIDs: [],
  // addSelectedWellCardPWID: (selectedWellCardPWIDs) =>
  //   set((state) => ({
  //     selectedWellCardPWIDs: [
  //       ...get().selectedWellCardPWIDs,
  //       selectedWellCardPWIDs,
  //     ],
  //   })),
  // removeSelectedWellCardByPWID: (value) =>
  //   set((state) => ({
  //     selectedWellCardPWIDs: get().selectedWellCardPWIDs.filter(
  //       (PWID) => PWID !== value
  //     ),
  //   })),

  // selectedWellCardUWIs: [],
  // addSelectedWellCardUWI: (selectedWellCardUWIs) =>
  //   set((state) => ({
  //     selectedWellCardUWIs: [
  //       ...get().selectedWellCardUWIs,
  //       selectedWellCardUWIs,
  //     ],
  //   })),
  // removeSelectedWellCardByUWI: (uwiValue, pwidValue) =>
  //   set((state) => {
  //     const childUWICount = get().wellCardData.filter(
  //       (data) => data.ParentWellID === pwidValue
  //     ).length;

  //     return {
  //       selectedWellCardUWIs: get().selectedWellCardUWIs.filter(
  //         (uwi) => uwi !== uwiValue
  //       ),
  //       ...(childUWICount === 1 && {
  //         selectedWellCardPWIDs: get().selectedWellCardPWIDs.filter(
  //           (PWID) => PWID !== pwidValue
  //         ),
  //       }),
  //     };
  //   }),

  resetWellCard: () =>
    set((state) => ({
      wellCardData: [],
      selectedWellCardPWIDs: [],
      selectedWellCardUWIs: [],
    })),
});

export default wellCardSettingsStore;
