import { FC, SVGProps } from "react";

interface TooltipInfoIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const TooltipInfoIcon: FC<TooltipInfoIconProps> = ({
  width = "17",
  height = "18",
  fill = "none",
  pathFill = "#979797",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 18"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.83203 4.83073H9.4987V6.4974H7.83203V4.83073ZM7.83203 8.16406H9.4987V13.1641H7.83203V8.16406ZM8.66537 0.664062C4.06536 0.664062 0.332031 4.3974 0.332031 8.9974C0.332031 13.5974 4.06536 17.3307 8.66537 17.3307C13.2654 17.3307 16.9987 13.5974 16.9987 8.9974C16.9987 4.3974 13.2654 0.664062 8.66537 0.664062ZM8.66537 15.6641C4.99037 15.6641 1.9987 12.6724 1.9987 8.9974C1.9987 5.3224 4.99037 2.33073 8.66537 2.33073C12.3404 2.33073 15.332 5.3224 15.332 8.9974C15.332 12.6724 12.3404 15.6641 8.66537 15.6641Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default TooltipInfoIcon;
