import { StateCreator } from "zustand";

import {
  GridWellSelectionStates,
  SelectedKeys,
  SelectedKeysWithData,
} from "../../../types/map/selection/store";

import { clone } from "../../../utils";

const sliceResetFns = new Set<() => void>();

const resetGridWellSelectionSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialGridWellSelectionStates = {
  //list of selected WellHeaderPermitKey referenced on grid
  selectedGridDataKeys: [],

  //NON-FILTERED: list of selected WellHeaderPermitKey
  allSelectedGridData: {},

  //FILTERED: list of selected WELLS only
  selectedWellIds: {},
  selectedWellData: {}, //selected wells map data
  selectedWellIdsKeys: [],
  deselectedWellIdsKeys: [],

  //FILTERED: list of selected PERMITS only
  selectedPermitIds: {},
  selectedPermitData: {}, //selected permits map data
  selectedPermitIdsKeys: [],
  deselectedPermitIdsKeys: [],
};

const gridWellSelectionSlice: StateCreator<GridWellSelectionStates> = (
  set,
  get
) => {
  sliceResetFns.add(() => set(clone(initialGridWellSelectionStates)));
  return {
    ...initialGridWellSelectionStates,
    updateAllSelectedGridData: (allSelectedGridData) =>
      set(() => ({ allSelectedGridData })),

    updateSelectedGridDataKeys: (selectedGridDataKeys) =>
      set(() => ({ selectedGridDataKeys })),
    updateDeselectedWellIds: (deselectedWellIdsKeys) =>
      set(() => ({ deselectedWellIdsKeys })),
    updateSelectedWellIds: (
      selectedWellIds,
      selectedWellData,
      selectedWellIdsKeys
    ) =>
      set(() => ({
        selectedWellIds,
        selectedWellData,
        selectedWellIdsKeys,
      })),
    addSelectedWellIds: (
      selectedWellIds,
      selectedWellData,
      selectedWellIdsKeys
    ) =>
      set((state) => ({
        selectedWellIds: { ...state.selectedWellIds, ...selectedWellIds },
        selectedWellData: { ...state.selectedWellData, ...selectedWellData },
        selectedWellIdsKeys: [
          ...state.selectedWellIdsKeys,
          ...selectedWellIdsKeys,
        ],
        allSelectedGridData: {
          ...state.allSelectedGridData,
          ...selectedWellIds,
        },
      })),
    removeSelectedIds: (keysToRemove) =>
      set((state) => {
        const updateObj = {
          selectedWellIds: state.selectedWellIds,
          selectedWellData: state.selectedWellData,
          selectedPermitIds: state.selectedPermitIds,
          selectedPermitData: state.selectedPermitData,
          allSelectedGridData: state.allSelectedGridData,
          selectedWellIdsKeys: state.selectedWellIdsKeys,
          selectedPermitIdsKeys: state.selectedPermitIdsKeys,
        };

        // Convert Object into Map
        const allSelectedGridDataMap = new Map(
          Object.entries(updateObj.allSelectedGridData)
        );

        // Remove key on the Map
        keysToRemove.forEach((key) => {
          allSelectedGridDataMap.delete(key);
        });

        // Convert Map back to Object
        updateObj.allSelectedGridData = Object.fromEntries(
          allSelectedGridDataMap
        );

        // For Optimization
        // Manual creation of object based on the allSelectedGridDataMap
        // This is faster than doing the convertion to Map three times.
        const newSelectedWellIds: SelectedKeys = {};
        const newSelectedWellData: SelectedKeysWithData = {};
        const newSelectedPermitData: SelectedKeysWithData = {};
        const newSelectedPermitIds: SelectedKeys = {};
        const newSelectedWellIdsKeys: number[] = [];
        const newSelectedPermitIdsKeys: number[] = [];
        for (const key of allSelectedGridDataMap.keys()) {
          if (updateObj.selectedWellIds[key]) {
            // if exists, key is a well
            newSelectedWellIds[key] = updateObj.selectedWellIds[key];
            newSelectedWellData[key] = updateObj.selectedWellData[key];
            newSelectedWellIdsKeys.push(updateObj.selectedWellIds[key]);
          } else if (updateObj.selectedPermitIds[key]) {
            //if exists, key is a permit
            newSelectedPermitIds[key] = updateObj.selectedPermitIds[key];
            newSelectedPermitData[key] = updateObj.selectedPermitData[key];
            newSelectedPermitIdsKeys.push(updateObj.selectedPermitIds[key]);
          }
        }

        updateObj.selectedWellIds = newSelectedWellIds;
        updateObj.selectedWellData = newSelectedWellData;
        updateObj.selectedPermitData = newSelectedPermitData;
        updateObj.selectedPermitIds = newSelectedPermitIds;
        updateObj.selectedPermitIdsKeys = newSelectedPermitIdsKeys;
        updateObj.selectedWellIdsKeys = newSelectedWellIdsKeys;

        return updateObj;
      }),
    updateDeselectedPermitIds: (deselectedPermitIdsKeys) =>
      set(() => ({ deselectedPermitIdsKeys })),
    addSelectedPermitIds: (
      selectedPermitIds,
      selectedPermitData,
      selectedPermitIdsKeys
    ) =>
      set((state) => ({
        selectedPermitIds: { ...state.selectedPermitIds, ...selectedPermitIds },
        selectedPermitData: {
          ...state.selectedPermitData,
          ...selectedPermitData,
        },
        selectedPermitIdsKeys: [
          ...state.selectedPermitIdsKeys,
          ...selectedPermitIdsKeys,
        ],

        allSelectedGridData: {
          ...state.allSelectedGridData,
          ...selectedPermitIds,
        },
      })),
    updateSelectedPermitIds: (
      selectedPermitIds,
      selectedPermitData,
      selectedPermitIdsKeys
    ) =>
      set(() => ({
        selectedPermitIds,
        selectedPermitData,
        selectedPermitIdsKeys,
      })),

    removeAllCurrentlySelectedIds: () => {
      const ids = [
        ...Object.keys(get().selectedWellIds),
        ...Object.keys(get().selectedPermitIds),
      ];

      get().removeSelectedIds(ids);
    },
  };
};

export { gridWellSelectionSlice, resetGridWellSelectionSlice };
