import { FC, SVGProps } from "react";

interface PermintNumberIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const PermintNumberIcon: FC<PermintNumberIconProps> = ({
  width = "8",
  height = "10",
  fill = "none",
  pathFill = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 10"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 8H6V7H2V8ZM2 6H6V5H2V6ZM1 10C0.725 10 0.489583 9.90208 0.29375 9.70625C0.0979167 9.51042 0 9.275 0 9V1C0 0.725 0.0979167 0.489583 0.29375 0.29375C0.489583 0.0979167 0.725 0 1 0H5L8 3V9C8 9.275 7.90208 9.51042 7.70625 9.70625C7.51042 9.90208 7.275 10 7 10H1ZM4.5 3.5V1H1V9H7V3.5H4.5Z"
        fill={pathFill}
        fillOpacity="0.35"
      />
    </svg>
  );
};

export default PermintNumberIcon;
