import { FC, MouseEvent, useCallback, useMemo } from "react";

import { MenuItem } from "@mui/material";
import { GridSortDirection, useGridApiContext } from "@mui/x-data-grid-premium";

import { ColumnItemProps } from "../../../../types/grid";

import useDataGridStore from "../../../../store/grid/dataGridStore";

const SortColumnItem: FC<ColumnItemProps> = ({ colDef, hideMenu }) => {
  const apiRef = useGridApiContext();
  const sortModel = useDataGridStore((state) => state.sortModel);

  const sortDirection: GridSortDirection = useMemo(() => {
    if (!colDef) {
      return null;
    }

    const sortItem = sortModel.find((item) => item.field === colDef.field);
    return sortItem == null ? void 0 : sortItem.sort;
  }, [colDef, sortModel]);

  const onSortMenuItemClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      hideMenu(event);

      const direction =
        event.currentTarget.getAttribute("data-value") ||
        (null as GridSortDirection);

      switch (direction) {
        case "asc":
          apiRef.current.sortColumn(colDef, "asc");
          break;
        case "desc":
          apiRef.current.sortColumn(colDef, "desc");
          break;
        default:
          apiRef.current.sortColumn(colDef, null);
          break;
      }
    },
    [apiRef, colDef, hideMenu]
  );
  return (
    <>
      <MenuItem
        disabled={sortDirection === "asc"}
        onClick={onSortMenuItemClick}
        data-value="asc"
      >
        Sort A to Z
      </MenuItem>
      <MenuItem
        disabled={sortDirection === "desc"}
        onClick={onSortMenuItemClick}
        data-value="desc"
      >
        Sort Z to A
      </MenuItem>
    </>
  );
};

export default SortColumnItem;
