import * as attributes from "../../constants/attributes";

import {
  permitPanelNumberAttributes,
  permitPanelStringAttributes,
} from "./permitPanelAttributeFormat";
import {
  wellPanelDateAttributes,
  wellPanelNumberAttributes,
  wellPanelStringAttributes,
} from "./wellPanelAttributeFormat";

//Converting to lowercase to remove case sensitivity on keys
const numberAttributes = [
  attributes.MEASURED_DEPTH.key.toLowerCase(),
  attributes.TOTAL_VERTICAL_DEPTH.key.toLowerCase(),
  attributes.LATERAL_LENGTH.key.toLowerCase(),
  attributes.ELEVATION_GROUND.key.toLowerCase(),
  attributes.PERF_INTERVAL_TOP.key.toLowerCase(),
  attributes.PERF_INTERVAL_BOTTOM.key.toLowerCase(),
  attributes.CUM_OIL.key.toLowerCase(),
  attributes.MAX_OIL.key.toLowerCase(),
  attributes.MAX_OIL_PLUS_2.key.toLowerCase(),
  attributes.WELL_FORECAST_OIL_ULTIMATE.key.toLowerCase(),
  attributes.CUM_GAS.key.toLowerCase(),
  attributes.MAX_GAS.key.toLowerCase(),
  attributes.MAX_GAS_PLUS_2.key.toLowerCase(),
  attributes.WELL_FORECAST_GAS_ULTIMATE.key.toLowerCase(),
  attributes.CUM_WATER.key.toLowerCase(),
  attributes.MAX_WATER.key.toLowerCase(),
  attributes.MAX_WATER_PLUS_2.key.toLowerCase(),
  attributes.WELL_FORECAST_WATER_ULTIMATE.key.toLowerCase(),
  attributes.CUM_INJECTION_LIQUID.key.toLowerCase(),
  attributes.CUM_INJECTION_GAS.key.toLowerCase(),
  attributes.OIL_INITIAL_RATE.key.toLowerCase(),
  attributes.OIL_DECLINE_RATE.key.toLowerCase(),
  attributes.OIL_B_FACTOR.key.toLowerCase(),
  attributes.GAS_INITIAL_RATE.key.toLowerCase(),
  attributes.GAS_DECLINE_RATE.key.toLowerCase(),
  attributes.GAS_B_FACTOR.key.toLowerCase(),
  attributes.WATER_INITIAL_RATE.key.toLowerCase(),
  attributes.WATER_DECLINE_RATE.key.toLowerCase(),
  attributes.WATER_B_FACTOR.key.toLowerCase(),
  attributes.MAX_TREATMENT_RATE.key.toLowerCase(),
  attributes.MAX_TREATMENT_PRESSURE.key.toLowerCase(),
  attributes.PROPPANT_AMOUNT.key.toLowerCase(),
  attributes.FRAC_FLUID_AMOUNT.key.toLowerCase(),
  attributes.ACID_AMOUNT.key.toLowerCase(),
  attributes.CUM_BOE.key.toLowerCase(),

  attributes.BOE_CUM_PER_FT.key.toLowerCase(),
  attributes.BOE_IP90.key.toLowerCase(),
  attributes.CUM_GOR.key.toLowerCase(),
  attributes.CUM_VENT_FLARE_VOL.key.toLowerCase(),
  attributes.DENSITY.key.toLowerCase(),
  attributes.ELEVATION_KELLY_BUSHING.key.toLowerCase(),
  attributes.EUR_PER_FT.key.toLowerCase(),
  attributes.FRAC_FLUID_AMOUNT_PER_FT.key.toLowerCase(),
  attributes.GAS_CUM_PER_FT.key.toLowerCase(),
  attributes.BOE_IP30.key.toLowerCase(),
  attributes.NEUTRON_POROSITY.key.toLowerCase(),
  attributes.OIL_CUM_PER_FT.key.toLowerCase(),
  attributes.OIL_EUR_PER_FT.key.toLowerCase(),
  attributes.PROPPANT_AMOUNT_PER_FT.key.toLowerCase(),
  attributes.RECENT_1MO_GAS.key.toLowerCase(),
  attributes.RECENT_1MO_OIL.key.toLowerCase(),
  attributes.RECENT_1MO_WATER.key.toLowerCase(),
  attributes.RECENT_1YR_GAS.key.toLowerCase(),
  attributes.RECENT_1YR_OIL.key.toLowerCase(),
  attributes.RECENT_1YR_WATER.key.toLowerCase(),
  attributes.RECENT_6MO_GAS.key.toLowerCase(),
  attributes.RECENT_6MO_OIL.key.toLowerCase(),
  attributes.RECENT_6MO_WATER.key.toLowerCase(),
  attributes.RESISTIVITY.key.toLowerCase(),
  attributes.SONIC.key.toLowerCase(),
  attributes.VSHALE.key.toLowerCase(),
  attributes.WATER_CUM_PER_FT.key.toLowerCase(),
  attributes.WATER_CUT_TOTAL_CUM.key.toLowerCase(),
  attributes.WATER_OIL_RATIO_TOTAL_CUM.key.toLowerCase(),
  attributes.WELL_FORECAST_BOE_REMAINING.key.toLowerCase(),
  attributes.WELL_FORECAST_BOE_ULTIMATE.key.toLowerCase(),
  attributes.WELL_FORECAST_GAS_REMAINING.key.toLowerCase(),
  attributes.WELL_FORECAST_GOR_ULTIMATE.key.toLowerCase(),
  attributes.WELL_FORECAST_OIL_REMAINING.key.toLowerCase(),
  attributes.WELL_FORECAST_WATER_CUT_ULTIMATE.key.toLowerCase(),
  attributes.WELL_FORECAST_WATER_REMAINING.key.toLowerCase(),
  attributes.BREAK_EVEN_PRICE.key.toLowerCase(),
  attributes.DRILLING_COMPLETION_CAP_EX.key.toLowerCase(),
  attributes.FIXED_COST.key.toLowerCase(),
  attributes.GPT_COST.key.toLowerCase(),
  attributes.INFRA_CAP_EX.key.toLowerCase(),
  attributes.INTERNAL_RATE_OF_RETURN.key.toLowerCase(),
  attributes.LOE_COST.key.toLowerCase(),
  attributes.NET_PRESENT_VALUE.key.toLowerCase(),
  attributes.PAYOUT.key.toLowerCase(),
  attributes.ROYALTY.key.toLowerCase(),
  attributes.WATER_COST.key.toLowerCase(),
  attributes.WORKING_INTEREST.key.toLowerCase(),
  attributes.PERMIT_FORMATION_DEPTH.key.toLowerCase(),
  attributes.PERMIT_TOTAL_DEPTH.key.toLowerCase(),

  //well panel specific attribute keys
  ...wellPanelNumberAttributes,

  //permit panel specific attribute keys
  ...permitPanelNumberAttributes,
];

//Well Spacing Distances
const specialNumberAttributes = [
  attributes.CLOSEST_HORIZONTAL_DISTANCE_IN_ZONE.key.toLowerCase(),
  attributes.CLOSEST_HORIZONTAL_DISTANCE.key.toLowerCase(),
  attributes.CLOSEST_VERTICAL_DISTANCE.key.toLowerCase(),
];

const dateAttributes = [
  attributes.SPUD_DATE.key.toLowerCase(),
  attributes.COMPLETION_DATE.key.toLowerCase(),
  attributes.FIRST_MONTH.key.toLowerCase(),
  attributes.LAST_MONTH.key.toLowerCase(),
  attributes.OIL_START.key.toLowerCase(),
  attributes.GAS_START.key.toLowerCase(),
  attributes.WATER_START.key.toLowerCase(),

  attributes.PERMIT_APPROVAL_DATE.key.toLowerCase(),
  attributes.PERMIT_POSTED_DATE.key.toLowerCase(),

  //well panel specific attribute keys
  ...wellPanelDateAttributes,
];

const stringAttributes = [
  attributes.STATE_NAME.key.toLowerCase(),
  attributes.COUNTY.key.toLowerCase(),
  attributes.WELL_TYPE.key.toLowerCase(),
  attributes.SLANT.key.toLowerCase(),
  attributes.OPERATOR_NAME.key.toLowerCase(),
  attributes.ULTIMATE_OWNER.key.toLowerCase(),
  attributes.FIELD_NAME.key.toLowerCase(),
  attributes.BASIN_NAME.key.toLowerCase(),
  attributes.DISPLAY_FORMATION.key.toLowerCase(),
  attributes.INTERPRETED_PRODUCING_FORMATION.key.toLowerCase(),
  attributes.WELL_NAME.key.toLowerCase(),
  attributes.WELL_NUMBER.key.toLowerCase(),
  attributes.WELL_STATUS.key.toLowerCase(),
  attributes.ULTIMATE_OWNER.key.toLowerCase(),
  attributes.OIL_ALGORITHM.key.toLowerCase(),
  attributes.GAS_ALGORITHM.key.toLowerCase(),
  attributes.WATER_ALGORITHM.key.toLowerCase(),
  attributes.TREATMENT_REMARKS.key.toLowerCase(),

  attributes.PERMIT_PURPOSE.key.toLowerCase(),
  attributes.PERMIT_NUMBER.key.toLowerCase(),
  attributes.PERMIT_TYPE.key.toLowerCase(),
  attributes.OPERATOR_NAME.key.toLowerCase(),

  attributes.RECORD_TYPE.key.toLowerCase(),

  //well panel specific attribute keys
  ...wellPanelStringAttributes,

  //permit panel specific attribute keys
  ...permitPanelStringAttributes,
];

const booleanAttributes = [
  attributes.HAS_PRODUCTION.key.toLowerCase(),
  attributes.HAS_FORECAST.key.toLowerCase(),
  attributes.PERMIT_HAS_H2S.key.toLowerCase(),
];

export {
  numberAttributes,
  specialNumberAttributes,
  dateAttributes,
  stringAttributes,
  booleanAttributes,
};
