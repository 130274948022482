export const baseMapURLs = {
  worldBoundaries:
    "https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}",
  OceansBaseMapLabel:
    "https://services.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Reference/MapServer/tile/{z}/{y}/{x}",
  OceanBaseMap:
    "https://server.arcgisonline.com/arcgis/rest/services/Ocean/World_Ocean_Base/MapServer/tile/{z}/{y}/{x}",
  darkGrayCanvasBase:
    "https://server.arcgisonline.com/arcgis/rest/services/Canvas/World_Dark_Gray_Base/MapServer",
  // darkGraythumbnailImage:
  //   "https://js.arcgis.com/4.16/esri/images/basemap/gray-vector.jpg",
  lightGrayCanvasBase:
    "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer",
  // lightGrayCanvasRef:
  //   "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer",
  // lightGraythumbnailImage:
  //   "https://js.arcgis.com/4.16/esri/images/basemap/gray-vector.jpg",
  nationalGeographicBase:
    "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/",
  // nationalGeographicBasethumbNailImage:
  //   "https://js.arcgis.com/4.16/esri/images/basemap/national-geographic.jpg",
  streetsCanvasBase:
    "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/Streets/MapServer",
  // streetsCanvasRef:
  //   "https://basemaps.arcgis.com/arcgis/rest/services/World_Basemap_v2/VectorTileServer",
  topographicBase:
    "https://services.arcgisonline.com/arcgis/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
  // topographicRef:
  //   "https://services.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Reference/MapServer",
  // topographicBasethumbNailImage:
  //   "https://js.arcgis.com/4.16/esri/images/basemap/topo-vector.jpg",
  OpenLayerBaseMap:
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  lightGrayCanvasBaseMap:
    "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}",
  darkGrayCanvasBaseMap:
    "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Dark_Gray_Base/MapServer/tile/{z}/{y}/{x}",
  streetsCanvasBaseMap:
    "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
  nationalGeographicBaseMap:
    "https://services.arcgisonline.com/ArcGIS/rest/services/NatGeo_World_Map/MapServer/tile/{z}/{y}/{x}",
  hybridBaseMap:
    "https://services.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  worldImageryBaseMap:
    "https://server.arcgisonline.com/arcgis/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
  // worldImageryLabelsRef:
  //   "https://services.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer",
};
