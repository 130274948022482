import { useMemo } from "react";

import { DYNAMIC_WELL_SPOTS, WELL_SPOTS } from "../../constants/constants";
import { MAP_LAYER } from "../../constants/panels/wellPanel/wellInfo";

import useMapHoverStore from "../../store/map/hover/mapHoverStore";
import usePanelsStore from "../../store/panels/panelsStore";

import {
  checkWellIDByLayerName,
  getWellIDByLayerName,
} from "../../utils/common/wells";

const useWellCardState = () => {
  const mapHoverData = useMapHoverStore((state) => state.mapHoverData);
  const wellInfoList = usePanelsStore((state) => state.wellList);

  const getGroupedWellID = useMemo(() => {
    if (!mapHoverData) {
      return undefined;
    }

    return getWellIDByLayerName(mapHoverData.layer.id, mapHoverData.info);
  }, [mapHoverData]);

  const isActivelyViewingWell = useMemo(() => {
    const wellInfo = wellInfoList.find(
      (data) =>
        getGroupedWellID &&
        mapHoverData &&
        data.groupedWellID === getGroupedWellID &&
        data.wellType === mapHoverData.layer.id
    );

    if (wellInfo) {
      const wellSystemData = wellInfo.wellSystemData?.find(
        (data) => data.wellId === mapHoverData?.info?.WellID
      );
      if (wellSystemData) {
        return {
          isActive:
            wellInfo.isActive &&
            wellInfo.activePage === wellSystemData.pageNumber,
          wellPage: wellSystemData.pageNumber,
        };
      } else {
        return { isActive: false, wellPage: 0 };
      }
    } else {
      return { isActive: false, wellPage: 0 };
    }
  }, [mapHoverData, wellInfoList]);

  const isWellSystemExisting = useMemo(() => {
    return Boolean(
      wellInfoList.find(
        (data) =>
          getGroupedWellID &&
          mapHoverData &&
          checkWellIDByLayerName(data.wellType, getGroupedWellID, {
            ParentWellID: data.parentWellId,
            BottomWellboreID: data.bottomWellBoreId,
          }) &&
          data.wellType === mapHoverData.layer.id
      )
    );
  }, [wellInfoList, mapHoverData, getGroupedWellID]);

  const isSurfaceWellSpotPanelExistingForBottomWellSPot = useMemo(() => {
    if (
      mapHoverData?.layer.id === MAP_LAYER.BOTTOM_WELL_SPOTS ||
      mapHoverData?.layer.id === MAP_LAYER.DYNAMIC_BOTTOM_WELL_SPOTS
    ) {
      return Boolean(
        wellInfoList.find((data) => {
          return (
            getGroupedWellID &&
            checkWellIDByLayerName(data.wellType, getGroupedWellID, {
              ParentWellID: data.parentWellId,
              BottomWellboreID: data.bottomWellBoreId,
            }) &&
            data.wellId === mapHoverData.info?.WellID &&
            data.wellSystemData !== undefined &&
            data.wellSystemData.length <= 1
          );
        })
      );
    } else {
      return false;
    }
  }, [mapHoverData, wellInfoList, getGroupedWellID]);

  const isActivelyViewingSurfaceWellSpotPanel = useMemo(() => {
    if (
      mapHoverData?.layer.id === MAP_LAYER.BOTTOM_WELL_SPOTS ||
      mapHoverData?.layer.id === MAP_LAYER.DYNAMIC_BOTTOM_WELL_SPOTS
    ) {
      const wellInfo = wellInfoList.find(
        (data) =>
          getGroupedWellID &&
          checkWellIDByLayerName(data.wellType, getGroupedWellID, {
            ParentWellID: data.parentWellId,
            BottomWellboreID: data.bottomWellBoreId,
          }) &&
          data.wellId === mapHoverData.info?.WellID &&
          data.wellSystemData !== undefined &&
          data.wellSystemData.length <= 1
      );

      if (wellInfo) {
        const wellSystemData = wellInfo.wellSystemData?.find(
          (data) => data.wellId === mapHoverData.info?.WellID
        );
        if (wellSystemData) {
          return {
            isActive:
              wellInfo.isActive &&
              wellInfo.activePage === wellSystemData.pageNumber,
            wellPage: wellSystemData.pageNumber,
          };
        } else {
          return { isActive: false, wellPage: 0 };
        }
      } else {
        return { isActive: false, wellPage: 0 };
      }
    } else {
      return { isActive: false, wellPage: 0 };
    }
  }, [mapHoverData, wellInfoList, getGroupedWellID]);

  const isBottomWellSpotPanelExistingForSurfaceWellSpot = useMemo(() => {
    if (
      mapHoverData?.layer.id === MAP_LAYER.WELL_SPOTS ||
      mapHoverData?.layer.id === MAP_LAYER.DYNAMIC_WELL_SPOTS
    ) {
      //check if bottom uwi exists
      return Boolean(
        wellInfoList.find(
          (data) =>
            getGroupedWellID &&
            checkWellIDByLayerName(data.wellType, getGroupedWellID, {
              ParentWellID: data.parentWellId,
              BottomWellboreID: data.bottomWellBoreId,
            }) &&
            data.wellId === mapHoverData.info?.WellID &&
            data.isSurfaceAndBottomEqual
        )
      );
    } else {
      return false;
    }
  }, [mapHoverData, wellInfoList, getGroupedWellID]);

  const isSurfaceEqualWithBottomWellPanel = useMemo(() => {
    if (!mapHoverData) return false;

    return Boolean(
      wellInfoList.find(
        (data) =>
          getGroupedWellID &&
          checkWellIDByLayerName(data.wellType, getGroupedWellID, {
            ParentWellID: data.parentWellId,
            BottomWellboreID: data.bottomWellBoreId,
          }) &&
          data.wellId === mapHoverData.info?.WellID &&
          [DYNAMIC_WELL_SPOTS, WELL_SPOTS].includes(data.wellType) &&
          data.isSurfaceAndBottomEqual
      )
    );
  }, [mapHoverData, wellInfoList, getGroupedWellID]);

  const isActiveViewingBottomWellSpotPanel = useMemo(() => {
    if (
      mapHoverData?.layer.id === MAP_LAYER.WELL_SPOTS ||
      mapHoverData?.layer.id === MAP_LAYER.DYNAMIC_WELL_SPOTS
    ) {
      const wellInfo = wellInfoList.find(
        (data) =>
          getGroupedWellID &&
          checkWellIDByLayerName(data.wellType, getGroupedWellID, {
            ParentWellID: data.parentWellId,
            BottomWellboreID: data.bottomWellBoreId,
          }) &&
          data.wellId === mapHoverData.info?.WellID &&
          data.isSurfaceAndBottomEqual
      );

      if (wellInfo) {
        const wellSystemData = wellInfo.wellSystemData?.find(
          (data) => data.wellId === mapHoverData.info?.WellID
        );
        if (wellSystemData) {
          return {
            isActive:
              wellInfo.isActive &&
              wellInfo.activePage === wellSystemData.pageNumber,
            wellPage: wellSystemData.pageNumber,
          };
        } else {
          return { isActive: false, wellPage: 0 };
        }
      } else {
        return { isActive: false, wellPage: 0 };
      }
    } else {
      return { isActive: false, wellPage: 0 };
    }
  }, [mapHoverData, wellInfoList, getGroupedWellID]);

  const shoudDisableOpenWellPanel: boolean = useMemo(() => {
    return (
      (isWellSystemExisting && isActivelyViewingWell.isActive) ||
      (isBottomWellSpotPanelExistingForSurfaceWellSpot &&
        isActiveViewingBottomWellSpotPanel.isActive) ||
      (isSurfaceWellSpotPanelExistingForBottomWellSPot &&
        isActivelyViewingSurfaceWellSpotPanel.isActive)
    );
  }, [
    isWellSystemExisting,
    isActivelyViewingWell,
    isActivelyViewingSurfaceWellSpotPanel,
    isSurfaceWellSpotPanelExistingForBottomWellSPot,
    isBottomWellSpotPanelExistingForSurfaceWellSpot,
    isActiveViewingBottomWellSpotPanel,
  ]);

  return {
    getGroupedWellID,
    isWellSystemExisting,
    isActivelyViewingWell,
    isActivelyViewingSurfaceWellSpotPanel,
    isSurfaceWellSpotPanelExistingForBottomWellSPot,
    isBottomWellSpotPanelExistingForSurfaceWellSpot,
    isActiveViewingBottomWellSpotPanel,
    shoudDisableOpenWellPanel,
    isSurfaceEqualWithBottomWellPanel,
  };
};

export default useWellCardState;
