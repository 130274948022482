import {
  ThemeProvider as MuiThemeProvider,
  Paper,
  createTheme,
} from "@mui/material";

import { ThemeProviderProps } from "../../types/information/information";

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: "#2E2E2E",
          position: "absolute",
          right: 5,
          top: 56,
          zIndex: 1301,
          width: 600,
          height: "calc(100vh - 80px)",
          padding: "19px 0",
          overflowY: "auto",
          borderRadius: 5,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          width: "100%",
          height: "auto",
          padding: 0,
          boxShadow: "none",
          borderBottom: "1px solid #4a4a4a",
          top: 24,
          "&:last-child": {
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          "&.Mui-expanded": {
            minHeight: 48,
            height: 48,
            borderBottom: "1px solid #42A5F5",
            background: "#4d4d4d",
          },
        },
        expandIconWrapper: {
          color: "#fff",
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
          paddingTop: 24,
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: "inherit",
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          "&:hover": {
            color: "#fff",
          },
        },
        icon: {
          display: "inline",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: "#ffffff",
          borderBottom: "none",
          "&:not(:first-of-type)::before": {
            content: '""',
            position: "absolute",
            top: 12,
            bottom: 0,
            left: -10,
            width: 1,
            background: "#524e4e",
            opacity: 0.7,
            height: 23,
          },
          minWidth: 188,
        },
        root: {
          color: "#b8b8b8",
          padding: "8px 0",
          borderBottom: "1px solid rgba(255, 255, 255, 0.10)",
          position: "relative",
          fontSize: 14,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 14,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        scroller: {
          display: "flex",
        },
        indicator: {
          height: 1,
        },
      },
    },
  },
});

const StyleOverrideThemeProvider: React.FC<ThemeProviderProps> = ({
  children,
}) => {
  return (
    <MuiThemeProvider theme={theme}>
      <Paper>{children}</Paper>
    </MuiThemeProvider>
  );
};

export default StyleOverrideThemeProvider;
