import { FC } from "react";

import { Box, InputLabel } from "@mui/material";

import {
  WellData,
  WellPanelSectionType,
} from "../../../../types/panels/wellPanel/wellPanel";

import {
  WELL_INFO_COMPLETION_DATA_CONTENT_ATTRIBUTES,
  WELL_INFO_COMPLETION_DATA_HEADER_ATTRIBUTES,
  WELL_PANEL_SECTION,
} from "../../../../constants/panels/wellPanel/wellInfo";

import { formatAttributeByKey } from "../../../../utils/formatters/attributeFormatter";

import DotFiller from "../common/DotFiller";
import WellPanelAccordion from "../common/WellPanelAccordion";

const CompletionData: FC<{
  wellData: WellData;
  isExpanded: boolean;
  onChange: (type: WellPanelSectionType) => void;
}> = ({ wellData, isExpanded, onChange }) => {
  const getDisplayValue = (field: string) => {
    return formatAttributeByKey(field, wellData[field]);
  };

  const handleOnChange = () => {
    onChange(WELL_PANEL_SECTION.COMPLETION_SECTION);
  };

  return (
    <WellPanelAccordion
      expanded={isExpanded}
      header={WELL_PANEL_SECTION.COMPLETION_SECTION.displayLabel}
      onChange={handleOnChange}
      isLoading={false}
    >
      <Box className="w-100 completion-data-container">
        <div style={{ display: "flex", flexDirection: "row", gap: 8 }}>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {WELL_INFO_COMPLETION_DATA_HEADER_ATTRIBUTES.map((data) => (
              <InputLabel
                key={data}
                className="input-well-card"
                style={{ overflowX: "hidden", width: 250 }}
              >
                {data} <DotFiller />
              </InputLabel>
            ))}
          </div>
          <div style={{ display: "flex", flexDirection: "column" }}>
            {WELL_INFO_COMPLETION_DATA_CONTENT_ATTRIBUTES.map((field) => (
              <InputLabel key={field} className="input-well-card">
                {getDisplayValue(field)?.toString()}
              </InputLabel>
            ))}
          </div>
        </div>
      </Box>
    </WellPanelAccordion>
  );
};

export default CompletionData;
