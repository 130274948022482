import { createTheme } from "@mui/material/styles";

export const darkTheme = createTheme({
  palette: {
    mode: "dark",
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: "light",
  },
});

export const stackedBarChartPalletes = [
  "#00B0FF",
  "#651FFF",
  "#FF4081",
  "#00E676",
  "#AA00FF",
  "#FF3D00",
];
