import { FC } from "react";

import { Typography } from "@mui/material";

import FilterCheckbox from "./FilterCheckboxPanel/FilterCheckbox";
import FilterExpressionBuilder from "./FilterExpressionBuilderPanel/FilterExpressBuilder";

const CustomFilter: FC = () => {
  return (
    <>
      <div className="grid-menu-header">
        <Typography variant={"body1"}>FILTER</Typography>
      </div>
      <FilterExpressionBuilder />
      <FilterCheckbox />
    </>
  );
};

export default CustomFilter;
