import * as filter_const from "./filter";
import * as filter_op_const from "./filterOperator";

export const FILTER_NUMBER_EXPRESSION_BUILDER_OPERATORS = [
  filter_op_const.FILTER_EQUALS_OPERATOR,
  filter_op_const.FILTER_GREATER_THAN_OPERATOR,
  filter_op_const.FILTER_GREATER_THAN_OR_EQUAL_TO_OPERATOR,
  filter_op_const.FILTER_LESS_THAN_OPERATOR,
  filter_op_const.FILTER_LESS_THAN_OR_EQUAL_TO_OPERATOR,
  filter_op_const.FILTER_BETWEEN_OPERATOR,
];

export const FILTER_DATE_EXPRESSION_BUILDER_OPERATORS = [
  filter_op_const.FILTER_EQUALS_OPERATOR,
  filter_op_const.FILTER_BEFORE_OPERATOR,
  filter_op_const.FILTER_AFTER_OPERATOR,
  filter_op_const.FILTER_BETWEEN_OPERATOR,
  filter_op_const.FILTER_YEAR_OPERATOR,
];

export const FILTER_VARCHAR_EXPRESSION_BUILDER_OPERATORS = [
  filter_op_const.FILTER_EQUALS_OPERATOR,
  filter_op_const.FILTER_STARTS_WITH_OPERATOR,
  filter_op_const.FILTER_CONTAINS_OPERATOR,
];

export const ALL_FILTER_OPERATORS_OBJ = {
  [filter_const.IS_ANY_OF]: filter_op_const.FILTER_IS_ANY_OPERATOR,
  [filter_const.EQUALS]: filter_op_const.FILTER_EQUALS_OPERATOR,
  [filter_const.GREATER_THAN]: filter_op_const.FILTER_GREATER_THAN_OPERATOR,
  [filter_const.GREATER_THAN_OR_EQUAL_TO]:
    filter_op_const.FILTER_GREATER_THAN_OR_EQUAL_TO_OPERATOR,
  [filter_const.LESS_THAN]: filter_op_const.FILTER_LESS_THAN_OPERATOR,
  [filter_const.LESS_THAN_OR_EQUAL_TO]:
    filter_op_const.FILTER_LESS_THAN_OR_EQUAL_TO_OPERATOR,
  [filter_const.BETWEEN]: filter_op_const.FILTER_BETWEEN_OPERATOR,
  [filter_const.IS_EMPTY]: filter_op_const.FILTER_IS_EMPTY_OPERATOR,
  [filter_const.BEFORE]: filter_op_const.FILTER_BEFORE_OPERATOR,
  [filter_const.AFTER]: filter_op_const.FILTER_AFTER_OPERATOR,
  [filter_const.YEAR]: filter_op_const.FILTER_YEAR_OPERATOR,
  [filter_const.CONTAINS]: filter_op_const.FILTER_CONTAINS_OPERATOR,
  [filter_const.IS_NOT_EMPTY]: filter_op_const.FILTER_IS_NOT_EMPTY_OPERATOR,
  [filter_const.STARTS_WITH]: filter_op_const.FILTER_STARTS_WITH_OPERATOR,
  [filter_const.ENDS_WITH]: filter_op_const.FILTER_ENDS_WITH_OPERATOR,
};
