import { RecordType } from "../../types/common/appMode/appMode";
import { Attribute, Bounds } from "../../types/common/attributes";
import { OperationTypeKeys } from "../../types/panels/searchPanel/queryBuilder/opTypes";

import { BOOLEAN, RECORD_TYPE } from "../../constants/attributes";
import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { IN } from "../../constants/panels/searchPanel/queryBuilder/opTypes";

import { stringifyBooleanValues } from "../helper";
import { getAttributeBounds } from "../search/formatter";

const createRecordTypeBounds = (type: RecordType) => {
  const recordType =
    type === RECORD_TYPES.WELLS_AND_PERMIT
      ? [RECORD_TYPES.WELL, RECORD_TYPES.PERMIT]
      : [type];

  return getAttributeBounds(RECORD_TYPE, recordType, IN.key);
};

const parseBoundValueByDataType = (
  attribute: Attribute,
  value: string | number | boolean
) => {
  switch (attribute.dataType) {
    case BOOLEAN:
      return stringifyBooleanValues(value);
    default:
      return value;
  }
};

const parseBoundValuesByKey = (
  attribute: Attribute,
  values: string[] | number[]
) => {
  switch (attribute.key) {
    case RECORD_TYPE.key:
      let recordTypeVals: RecordType[] = values as RecordType[];
      if (
        recordTypeVals.includes(RECORD_TYPES.WELL) &&
        recordTypeVals.includes(RECORD_TYPES.PERMIT)
      ) {
        recordTypeVals = [RECORD_TYPES.WELLS_AND_PERMIT];
      }
      return recordTypeVals;

    default:
      return values;
  }
};

const createBoundsByKey = (
  attribute: Attribute,
  values: string[] | number[],
  operationType: OperationTypeKeys
) => {
  switch (attribute.key) {
    case RECORD_TYPE.key:
      return createRecordTypeBounds(values[0] as RecordType);

    default:
      return getAttributeBounds(attribute, values, operationType);
  }
};

const getRecordTypeOnBound = (bounds: Bounds) => {
  const recordTypeBound = bounds.find(
    (bound) => bound.bound === RECORD_TYPE.key
  );

  let recordType: RecordType | null = null;
  if (!!recordTypeBound) {
    const recordTypeBoundValues =
      recordTypeBound.operations[0].values.join(", ");

    if (recordTypeBoundValues === "Well, Permit") {
      recordType = RECORD_TYPES.WELLS_AND_PERMIT;
    } else {
      recordType = recordTypeBoundValues as RecordType;
    }
  }
  return recordType;
};

export {
  createBoundsByKey,
  createRecordTypeBounds,
  getRecordTypeOnBound,
  parseBoundValueByDataType,
  parseBoundValuesByKey,
};
