import { GridFilterModel } from "@mui/x-data-grid-premium";

import { SavedGridConfiguration } from "../../../types/panels/savedSearchPanel/savedSearchData";

import { listOfDepracatedAttributes } from "../../../constants/common/depracatedAttributes";

import { clone } from "../../../utils";

//to handle backward compatiblity when there are changes on the data structure
const useSavedSearchDataReMapping = () => {
  const mapSavedGridConfig = (gridConfig: SavedGridConfiguration) => {
    const copiedGridConfig = clone(gridConfig) as SavedGridConfiguration;

    listOfDepracatedAttributes.forEach((attr) => {
      //Columns Model
      if (
        copiedGridConfig.columnsModel &&
        copiedGridConfig.columnsModel[attr.oldKey]
      ) {
        copiedGridConfig.columnsModel[attr.newKey] =
          copiedGridConfig.columnsModel[attr.oldKey];
        delete copiedGridConfig.columnsModel[attr.oldKey];
      }

      //Columns Order
      if (
        copiedGridConfig.columnsOrder &&
        copiedGridConfig.columnsOrder.length
      ) {
        const columnAttrIndex = copiedGridConfig.columnsOrder.findIndex(
          (col) => col === attr.oldKey
        );
        if (columnAttrIndex > -1) {
          copiedGridConfig.columnsOrder[columnAttrIndex] = attr.newKey;
        }
      }

      //Sort Model
      if (copiedGridConfig.sortModel && copiedGridConfig.sortModel.length) {
        const sortAttrIndex = copiedGridConfig.sortModel.findIndex(
          (sortAttr) => sortAttr.field === attr.oldKey
        );
        if (sortAttrIndex > -1) {
          copiedGridConfig.sortModel[sortAttrIndex].field = attr.newKey;
        }
      }
    });

    return copiedGridConfig;
  };

  const mapSavedFilterModel = (newFilterModel: GridFilterModel) => {
    const copiedFilterModel = clone(newFilterModel) as GridFilterModel;

    listOfDepracatedAttributes.forEach((attr) => {
      if (copiedFilterModel && copiedFilterModel.items.length) {
        const filterAttrIndex = copiedFilterModel.items.findIndex(
          (filterAttr) => filterAttr.field === attr.oldKey
        );
        if (filterAttrIndex > -1) {
          copiedFilterModel.items[filterAttrIndex].field = attr.newKey;
        }
      }
    });

    return copiedFilterModel;
  };

  return { mapSavedGridConfig, mapSavedFilterModel };
};

export default useSavedSearchDataReMapping;
