import { useEffect, useState } from "react";

import { DataGridPremium } from "@mui/x-data-grid-premium";

import { wellPanelLatLong } from "../../../../constants/common/wellPanelAttributeFormat";

import usePanelFormattingRule from "../../../../customHooks/panels/usePanelFormattingRule";

import { formatAttributeByKey } from "../../../../utils/formatters/attributeFormatter";

import { clone } from "../../../../utils";
import NoResultDisplay from "../../../DataGrid/CustomGridComponents/NoResultDisplay";

const PanelTable = ({
  TableData,
  TableHeader,
  tabContentTableSize,
  isLoading,
  shouldFormatValues = true,
}) => {
  const [rows, setRows] = useState([]);

  const { formatLatLongAttributes } = usePanelFormattingRule();

  useEffect(() => {
    if (TableData) {
      let formattedRows = [];
      TableData.forEach((a, i) => {
        let clonedData = clone(a);

        //only format values if flag is set to true
        if (shouldFormatValues) {
          for (const [key, value] of Object.entries(clonedData)) {
            if (wellPanelLatLong.includes(key.toLowerCase())) {
              clonedData[key] = formatLatLongAttributes(value);
            } else {
              clonedData[key] = formatAttributeByKey(key, value);
            }
          }
        }

        formattedRows.push({ ...clonedData, id: i });
      });

      setRows(formattedRows);
    }
  }, [TableData]);

  return (
    <div style={{ height: tabContentTableSize, width: "100%" }}>
      <DataGridPremium
        className="dataTableWellCard"
        rows={isLoading ? [] : rows}
        columns={TableHeader}
        pageSize={5}
        rowHeight={26}
        rowsPerPageOptions={[TableData ? TableData.length : 0]}
        disableColumnMenu
        disableColumnFilter
        disableColumnSelector
        hideFooterPagination={true}
        hideFooterSelectedRowCount={true}
        slots={{ noRowsOverlay: NoResultDisplay }}
        loading={isLoading}
        columnHeaderHeight={35}
      />
    </div>
  );
};

export default PanelTable;
