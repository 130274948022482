import { FC } from "react";

import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import { IconButton } from "@mui/material";

interface ShareSearchIconProps {
  disable?: boolean;
  handleOnClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  classNames?: string;
}

const ShareSearchIcon: FC<ShareSearchIconProps> = ({
  disable = false,
  handleOnClick,
  classNames,
}) => {
  return (
    <IconButton
      disabled={disable}
      className={classNames}
      onClick={(e) => handleOnClick(e)}
    >
      <ShareOutlinedIcon />
    </IconButton>
  );
};

export default ShareSearchIcon;
