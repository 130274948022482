import { useCallback } from "react";

import { GunBarrelTargetWell } from "../../types/panels/wellPanel/gunBarrelType";

import {
  horizontalFocusedPointCrossHairConfig,
  horizontalTargetCrossHairConfig,
  overlayConfig,
  verticalFocusedPointCrossHairConfig,
  verticalTargetCrossHairConfig,
  wellHorizontalDistanceHoverAnConfig,
  wellVerticalDistanceHoverAnConfig,
} from "../../constants/charts/gunBarrelInteractivityConfigs";

import useAttributeUnit from "../common/useAttributeUnit";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useGunBarrelCalculations from "./useGunBarrelCalculations";
import useGunBarrelTraceUtils from "./useGunBarrelTraceUtils";

const useGunBarrelInteractivityUtils = () => {
  const { createAnnotation, createTrace } = useGunBarrelTraceUtils();
  const { getWellDistanceFromTargetWell, calculateMidPoint } =
    useGunBarrelCalculations();

  const { isMetricOnSearch } = useUnitOfMeasure();
  const { convertFtToMeter } = useAttributeUnit();
  const createOverlay = (
    xMin: number,
    xMax: number,
    yMin: number,
    yMax: number
  ) => {
    return [
      createTrace(
        {
          x: [xMin, xMin, xMax, xMax],
          y: [yMin, yMax, yMax, yMin],
        },
        overlayConfig
      ),
    ];
  };

  const createTargetCrossHair = (
    targetWell: GunBarrelTargetWell,
    xMin: number,
    xMax: number,
    yMin: number,
    yMax: number
  ) => {
    return [
      createTrace(
        {
          x: [xMin, xMax],
          y: [targetWell.y, targetWell.y],
        },
        horizontalTargetCrossHairConfig
      ),
      createTrace(
        {
          x: [0, 0],
          y: [yMax, yMin],
        },
        verticalTargetCrossHairConfig
      ),
    ];
  };

  const createFocusedPointCrossHair = (
    focusedPoint: any,
    targetWell: GunBarrelTargetWell
  ) => {
    const x = focusedPoint.x[0];
    const y = focusedPoint.y[0];
    return [
      createTrace(
        {
          x: [0, x],
          y: [y, y],
        },
        horizontalFocusedPointCrossHairConfig
      ),
      createTrace(
        {
          x: [x, x],
          y: [y, targetWell.y],
        },
        verticalFocusedPointCrossHairConfig
      ),
    ];
  };

  const createOnHoverAnnotations = useCallback(
    (focusedPoint: any, targetWell: GunBarrelTargetWell) => {
      const x = focusedPoint.x[0];
      const y = focusedPoint.y[0];

      const annotations = [
        // createAnnotation(
        //   focusedPoint.text,
        //   {
        //     x,
        //     y,
        //   },
        //   {
        //     ...wellHoverAnnotationConfig,
        //     yshift: y > targetWell.y ? -30 : 30,
        //   }
        // ),
        createAnnotation(
          `${getWellDistanceFromTargetWell(x)} ${convertFtToMeter(
            isMetricOnSearch
          )}`,
          {
            x: calculateMidPoint(x),
            y: y,
          },
          {
            ...wellHorizontalDistanceHoverAnConfig,
            yshift: y > targetWell.y ? -20 : 20,
          }
        ),
        createAnnotation(
          `${getWellDistanceFromTargetWell(y, targetWell.y)} ${convertFtToMeter(
            isMetricOnSearch
          )}`,
          {
            x,
            y: calculateMidPoint(y, targetWell.y),
          },
          {
            ...wellVerticalDistanceHoverAnConfig,
            textangle: x < 0 ? "270" : "90",
            xshift: x < 0 ? -15 : 15,
          }
        ),
      ];

      //when user hovers over targetwell only
      if (x === 0 && y === targetWell.y) {
        return [{ ...annotations[0], text: "" }];
      }
      //when well is is directly left or right to the target
      else if (y === targetWell.y) {
        return [annotations[0], annotations[1]];
      }
      //when well is above or below target
      else if (x === 0) {
        return [annotations[0], annotations[2]];
      }
      return annotations;
    },
    [isMetricOnSearch]
  );

  return {
    createOverlay,
    createTargetCrossHair,
    createFocusedPointCrossHair,
    createOnHoverAnnotations,
  };
};

export default useGunBarrelInteractivityUtils;
