import { STORAGE_UNITS_LAYERS } from "../constants";

export const CARBON_STORAGE_LABEL = "TGS CO₂ Storage Assessment";

export const CARBON_LAYER_BASINS = {
  AR_MS_AL_STORAGE_CAPACITY: "AR-MS-AL Storage Capacity",
};

export const ORDERED_CARBON_LAYERS = [
  {
    key: CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY,
    label: "East Gulf Coast Basin",
  },
];

export const CARBON_STORAGE_FORMATIONS_BY_BASINS = {
  [CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY]: [
    STORAGE_UNITS_LAYERS.VICKSBURG,
    STORAGE_UNITS_LAYERS.CLAIBORNE,
    STORAGE_UNITS_LAYERS.UP_WILCOX,
    STORAGE_UNITS_LAYERS.LR_WILCOX,
    STORAGE_UNITS_LAYERS.TAYLOR,
    STORAGE_UNITS_LAYERS.AUSTIN,
    STORAGE_UNITS_LAYERS.LR_TUSCA,
    STORAGE_UNITS_LAYERS.LR_CRETA,
    STORAGE_UNITS_LAYERS.PALUXY,
    STORAGE_UNITS_LAYERS.TRINITY,
    STORAGE_UNITS_LAYERS.RODESSA,
    STORAGE_UNITS_LAYERS.SLIGO,
    STORAGE_UNITS_LAYERS.PETTET,
    STORAGE_UNITS_LAYERS.HOSSTON_TRAVIS_PEAK,
    STORAGE_UNITS_LAYERS.COTTONVALLEY,
    STORAGE_UNITS_LAYERS.LRCOTTONVALLEY,
    STORAGE_UNITS_LAYERS.BOSSIER,
    STORAGE_UNITS_LAYERS.HAYNESVILLE,
    STORAGE_UNITS_LAYERS.SMACKOVER,
    STORAGE_UNITS_LAYERS.LRSMACKOVER,
    STORAGE_UNITS_LAYERS.NORPHLET,
    STORAGE_UNITS_LAYERS.EAGLEMILLS,
  ],
};

export const DEFAULT_CARBON_STORAGE_BASINS_INFO = {
  [CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY]: {
    isSelected: false,
    formationName:
      CARBON_STORAGE_FORMATIONS_BY_BASINS[
        CARBON_LAYER_BASINS.AR_MS_AL_STORAGE_CAPACITY
      ][0],
  },
};

export const CARBON_STORAGE_LEGEND_ITEMS = [
  {
    label: "Null",
    color: "#D9D9D9",
  },
  {
    label: "0 - 0.1",
    color: "#D31F23",
  },
  {
    label: "0.1 - 1",
    color: "#F2A25F",
  },
  {
    label: "1 - 5",
    color: "#FACD81",
  },
  {
    label: "5 - 10",
    color: "#F9EEA6",
  },
  {
    label: "10 - 15",
    color: "#EAF6AB",
  },
  {
    label: "15 - 20",
    color: "#C7E484",
  },
  {
    label: "20 - 25",
    color: "#96D366",
  },
  {
    label: "25 - 30",
    color: "#5FAE5C",
  },
  {
    label: ">90",
    color: "#1E9445",
  },
];
