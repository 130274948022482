import { styled } from "@mui/material";

export const Permit = styled("span")(() => ({
  backgroundColor: `transparent`,
  width: 12,
  height: 12,
  border: "2px solid rgba(0, 184, 212, 1)",
  borderRadius: "50%",
  display: "flex",
  marginTop: 5,
}));

export const WellSpot = styled("span")(() => ({
  backgroundColor: `rgba(36, 86, 156, 1)`,
  width: 12,
  height: 12,
  borderRadius: "50%",
  display: "flex",
  marginTop: 5,
}));

export const LEGENDS_SYMBOLS = {
  ["WellSpotSymbol"]: {
    symbol: <WellSpot />,
    text: "Well Spots",
  },
  ["PermitsSymbol"]: {
    symbol: <Permit />,
    text: "Permits",
  },
};
