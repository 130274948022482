import * as attributes from "../wellPanel/wellPanelAttributes";

export const casingColumns = [
  {
    field: attributes.CASING_TYPE.key,
    headerName: attributes.CASING_TYPE.label,
    width: 100,
    sortable: false,
  },
  {
    field: attributes.CASING_PURPOSE.key,
    headerName: attributes.CASING_PURPOSE.key,
    width: 120,
    sortable: false,
  },
  {
    field: attributes.HOLE_SIZE.key,
    headerName: attributes.HOLE_SIZE.label,
    width: 80,
    sortable: false,
    type: "number",
  },
  {
    field: attributes.CASING_SIZE.key,
    headerName: attributes.CASING_SIZE.label,
    width: 100,
    sortable: false,
    type: "number",
  },
  {
    field: attributes.CASING_WEIGHT.key,
    headerName: attributes.CASING_WEIGHT.label,
    width: 120,
    sortable: false,
    type: "number",
  },
  {
    field: attributes.TOP_DEPTH.key,
    headerName: attributes.TOP_DEPTH.label,
    width: 100,
    sortable: false,
    type: "number",
  },
  {
    field: attributes.BOTTOM_DEPTH.key,
    headerName: attributes.BOTTOM_DEPTH.label,
    width: 110,
    sortable: false,
    type: "number",
  },
  {
    field: attributes.CASING_GRADE.key,
    headerName: attributes.CASING_GRADE.label,
    width: 110,
    sortable: false,
  },
];
