import { useEffect, useMemo, useState } from "react";
import { Layout } from "react-grid-layout";

import classNames from "classnames";
import { ResizeCallback } from "re-resizable";

import { DefaultLayout } from "../../types/modularity/layout";

import { CHART_MODE_VIEW } from "../../constants/charts/chartModeView";

import useDataGridStore from "../../store/grid/dataGridStore";
import useModularityStore from "../../store/modularity/modularityStore";
import usePanelsStore from "../../store/panels/panelsStore";

export const useModularity = () => {
  const chartDefaultLayout = useModularityStore(
    (state) => state.chartDefaultLayout
  );
  const updateChartDefaultLayout = useModularityStore(
    (state) => state.updateChartDefaultLayout
  );
  const chartFullScreenLayout = useModularityStore(
    (state) => state.chartFullScreenLayout
  );
  const updateChartFullScreenLayout = useModularityStore(
    (state) => state.updateChartFullScreenLayout
  );
  const showGridHeader = useDataGridStore((state) => state.showGridHeader);
  const showGrid = useDataGridStore((state) => state.showGrid);
  const setGridHeight = useDataGridStore((state) => state.setGridHeight);
  const gridHeight = useDataGridStore((state) => state.gridHeight);
  const isFullScreenGrid = useDataGridStore((state) => state.isFullScreenGrid);
  const isExpandGridWidth = useDataGridStore(
    (state) => state.isExpandGridWidth
  );

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );
  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );

  const [fullHeightGrid, setFullHeightGrid] = useState(false);

  const getGridClassName = useMemo(
    () =>
      classNames("grid-modular-window", {
        "full-screen": isFullScreenGrid,
        "grid-header-height": !showGrid && showGridHeader,
        "hide-view": isOpenQBEditorPanel,
        "app-level-open": !isExpandGridWidth && isOpenAppLevelNav,
        "left-panel-open":
          !isExpandGridWidth && isOpenAppLevelNav && isOpenLeftSidePanel,
        "analysis-panel-open": !isExpandGridWidth && isOpenAnalysisPanel,
        "expanded-view": isExpandGridWidth,
      }),
    [
      isFullScreenGrid,
      isExpandGridWidth,
      isOpenQBEditorPanel,
      isOpenAppLevelNav,
      isOpenLeftSidePanel,
      isOpenAnalysisPanel,
      showGridHeader,
      showGrid,
    ]
  );

  const getGridSubContainerClassName = () => {
    return classNames("grid-plot-content", {
      "full-screen-mode-grid": fullHeightGrid,
      "hide-grid": (gridHeight === "50px" && !fullHeightGrid) || !showGrid,
    });
  };

  const onResize: ResizeCallback = (e, d, el, delta) => {
    setGridHeight(el.style.height);
  };

  const handleLayoutChange = (layout: Layout[], chartMode: string) => {
    const sortedLayoutList = layout
      // sort layout items in the new order
      .sort((a, b) => {
        // same row, compare X
        if (a.y === b.y) {
          return a.x - b.x;
        }

        // different row, compare Y
        return a.y - b.y;
      });
    const modifiedFullScreenLayoutList: DefaultLayout[] = [];
    const modifiedDefaultLayoutList: DefaultLayout[] = [];
    sortedLayoutList.forEach((currentlayout: any, index: number) => {
      const findfullScreenChart = chartFullScreenLayout.find(
        (layout: DefaultLayout) => layout.i === currentlayout.i
      );

      if (findfullScreenChart) {
        const fullScreenLayoutObj: DefaultLayout = {
          ...findfullScreenChart,
          ...(chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS
            ? {
                x: currentlayout.x,
                w: currentlayout.w,
                h: currentlayout.h,
              }
            : {
                x: index % 2 === 0 ? 0 : currentlayout.x + currentlayout.x + 4,
              }),
          y: currentlayout.y,
        };

        modifiedFullScreenLayoutList.push(fullScreenLayoutObj);
      }

      const findDefaultChart = chartDefaultLayout.find(
        (layout: DefaultLayout) => layout.i === currentlayout.i
      );

      if (findDefaultChart) {
        const defaultLayoutObj: DefaultLayout = {
          ...findDefaultChart,
          y: currentlayout.y % 2 === 0 ? currentlayout.y : currentlayout.y + 1,
        };
        modifiedDefaultLayoutList.push(defaultLayoutObj);
      }
    });

    updateChartDefaultLayout(modifiedDefaultLayoutList);
    updateChartFullScreenLayout(modifiedFullScreenLayoutList);
  };

  useEffect(() => {
    setFullHeightGrid(isFullScreenGrid);
  }, [isFullScreenGrid]);

  return {
    getGridClassName,
    getGridSubContainerClassName,
    onResize,
    handleLayoutChange,
  };
};
