// REREQUEST FUNCTION
// Allows the following benefits:
// (1) Retain Successful requests from sychronous Promise.all requests
// (2) Request a second time for failed requests
import axios, { AxiosResponse } from "axios";

// Process of rerequest:
// (1) All initial successful requests are preserved
// (2) Failed requests are tracked
// (3) Failed requests get ONE rerequest chance (Gives time for cloud run scaling)
// (4) Successful requests get appended to initial successful requests
// (5) Failed requests are lost (more unlikely)

export const settledPromise = <T extends object>(
  responses: Promise<AxiosResponse<T>>[],
  callback: (values: AxiosResponse<T>[]) => void
) => {
  Promise.allSettled(responses).then((results) => {
    const values = results
      .filter(
        (result): result is PromiseFulfilledResult<AxiosResponse<T>> =>
          result.status === "fulfilled"
      )
      .map((result) => result.value);

    const rejectedResults = results.filter(
      (result): result is PromiseRejectedResult => result.status === "rejected"
    );

    if (rejectedResults?.length > 0) {
      const rerequests = rejectedResults.map((request) => {
        const url = request.reason.config.url;
        const data = request.reason.config.data;
        const headers = request.reason.config.headers;
        return axios.post(url, JSON.parse(data), headers);
      });

      Promise.allSettled(rerequests).then((rerequestResults) => {
        const rerequestValues = rerequestResults
          .filter(
            (
              rerequestResult
            ): rerequestResult is PromiseFulfilledResult<AxiosResponse<T>> =>
              rerequestResult.status === "fulfilled"
          )
          .map((rerequestResult) => rerequestResult.value);

        const allConfirmedValues = [...values, ...rerequestValues];
        callback(allConfirmedValues);
      });
    } else {
      callback(values);
    }
  });
};
