import { StateCreator } from "zustand";

import { AppUoMSettingSlice } from "../../types/common/appMode/appMode";

import { UNIT_OF_MEASURE } from "../../constants/appHeader/unitOfMeasure";

const UoMSettingSlice: StateCreator<AppUoMSettingSlice> = (set, get) => ({
  //Unit of Measure value on form selection
  UoMValueOnSelection: UNIT_OF_MEASURE.IMPERIAL.key,
  updateUoMValueOnSelection: (val) => set(() => ({ UoMValueOnSelection: val })),

  //Unit of Measure value on Search
  UoMValueOnSearch: UNIT_OF_MEASURE.IMPERIAL.key,
  updateUoMValueOnSearch: (setting) =>
    set(() => ({ UoMValueOnSearch: setting })),

  //Unit of Measure alert box
  UoMAlertState: undefined,
  updateUoMAlertState: (alertState) =>
    set(() => ({ UoMAlertState: alertState })),
});

export { UoMSettingSlice };
