import { create } from "zustand";

import { MapHoverStoreState } from "../../../types/map/wellSpot/store";

import { mapHoverSlice } from "./mapHoverSlice";

const useMapHoverStore = create<MapHoverStoreState>()((...a) => ({
  ...mapHoverSlice(...a),
}));

export default useMapHoverStore;
