import { useEffect } from "react";

import shpjs, { FeatureCollectionWithFilename } from "shpjs";

import { PolygonData } from "../../../types/map/gis/common";

import config from "../../../configs/appSettings";

import { SHAPE_FILE_UPLOAD_FILE_EXTENSIONS } from "../../../constants/map/shapefile";

import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useStore from "../../../store/useStore";

import useUploadShapeFile from "./useUploadShapeFile";

interface ShapeFileHookProps {
  drawPolygonDynamically: (polygon: PolygonData) => void;
}

const useShapeFile = ({ drawPolygonDynamically }: ShapeFileHookProps) => {
  const { data, isLoading, error, uploadShapeFile } = useUploadShapeFile();

  const shapeFilePolygons = useMapDrawStore((state) => state.shapeFilePolygons);
  const isUploadingShapeFile = useMapDrawStore(
    (state) => state.isUploadingShapeFile
  );
  const updateIsUploadingShapeFile = useMapDrawStore(
    (state) => state.updateIsUploadingShapeFile
  );
  const updateShapeId = useMapDrawStore((state) => state.updateShapeId);
  const addShapeFilePolygon = useMapDrawStore(
    (state) => state.addShapeFilePolygon
  );
  const removeShapeFilePolygon = useMapDrawStore(
    (state) => state.removeShapeFilePolygon
  );

  const updateToastMessage = useStore((state) => state.updateToastMessage);

  useEffect(() => {
    updateIsUploadingShapeFile(isLoading);
  }, [isLoading, updateIsUploadingShapeFile]);

  useEffect(() => {
    if (!data || error || isLoading) return;
    updateShapeId(data);
  }, [data, error, isLoading, updateShapeId]);

  const addAndUploadShapeFilePolygons = (polygonToAdd: PolygonData) => {
    addShapeFilePolygon(polygonToAdd);

    const newShapeFilePolygons = [...shapeFilePolygons, polygonToAdd];
    uploadShapeFile(newShapeFilePolygons);
  };

  const removeAndUploadShapeFilePolygons = (polygonToRemove: PolygonData) => {
    removeShapeFilePolygon(polygonToRemove);

    const newShapeFilePolygons = shapeFilePolygons.filter(
      (polygon) => polygon.id !== polygonToRemove.id
    );

    if (!newShapeFilePolygons.length) {
      updateShapeId("");
      return;
    }

    uploadShapeFile(newShapeFilePolygons);
  };

  const handleUploadShapeFile = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    updateToastMessage(null);
    try {
      const targetFiles = e.target.files;
      const allowedFile = SHAPE_FILE_UPLOAD_FILE_EXTENSIONS.substring(1);
      if (targetFiles?.length) {
        const filename = targetFiles[0]?.name?.split(".");
        const importedName = filename?.shift();

        // prevent same file names
        if (
          shapeFilePolygons?.filter((polygon) => polygon.id === importedName)
            ?.length
        ) {
          return;
        }

        if (filename?.pop() !== allowedFile) {
          return updateToastMessage(`File should be in ${allowedFile} format.`);
        }

        const buffer = await targetFiles[0].arrayBuffer();
        const { features } = (await shpjs(
          buffer
        )) as FeatureCollectionWithFilename;
        const featuresCount = features?.length ?? 0;
        if (!featuresCount) {
          return updateToastMessage("File can't be imported. Error occurred.");
        }

        if (featuresCount > config.maximumPolygonsToImport) {
          return updateToastMessage(
            `Maximum number of polygons to import (${config.maximumPolygonsToImport}) exceeded.`
          );
        }

        features.forEach(({ geometry }) => {
          if (geometry.type !== "Polygon") {
            return updateToastMessage(
              "File can't be imported. Invalid polygons found in the shapefile."
            );
          }

          const polygon: PolygonData = {
            id: importedName ?? "",
            coordinates: geometry.coordinates,
            isSelected: true,
            isShapeFile: true,
          };

          drawPolygonDynamically(polygon);
          addAndUploadShapeFilePolygons(polygon);
        });
      }
    } catch (err) {
      console.log(err);
      updateToastMessage("File can't be imported. Error occurred.");
    }
  };

  return {
    isUploadingShapeFile,
    shapeFilePolygons,
    removeAndUploadShapeFilePolygons,
    handleUploadShapeFile,
  };
};

export default useShapeFile;
