import { FC, SVGProps } from "react";

interface ContractTableIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const ContractTableIcon: FC<ContractTableIconProps> = ({
  width = "20",
  height = "12",
  fill = "none",
  pathFill = "#FFFFFFCC",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 12"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.503906 -6.99382e-07L2.00391 -6.33815e-07L2.00391 12L0.503906 12L0.503906 -6.99382e-07ZM18.0039 -6.55671e-08L19.5039 0L19.5039 12L18.0039 12L18.0039 -6.55671e-08Z"
        fill={pathFill}
      />
      <path
        d="M15.3917 2.37402L11.7656 6.0001L15.3917 9.62617L16.1956 8.82228L13.3734 6.0001L16.1956 3.17792L15.3917 2.37402Z"
        fill={pathFill}
      />
      <path
        d="M3.8125 3.17792L6.63468 6.0001L3.8125 8.82228L4.61639 9.62617L8.24247 6.0001L4.61639 2.37402L3.8125 3.17792Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default ContractTableIcon;
