import React, { useRef } from "react";

import { Button } from "component-library";

import { ButtonUploadProps } from "../../types/common/common";

// Version 2 of ButtonUpload Component
// Replace the old version by this when this component
// is updated for UWI Upload
const ButtonUpload2: React.FC<ButtonUploadProps> = ({
  label,
  icon,
  allowedFileExtension = null,
  handleFileChange,
  buttonClickCallback = null,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (buttonClickCallback) buttonClickCallback();
    fileInputRef.current?.click();
  };

  return (
    <>
      <input
        id="upload-file"
        type="file"
        accept={allowedFileExtension || undefined}
        ref={fileInputRef}
        onChange={handleFileChange}
        onClick={(e) => ((e.target as HTMLInputElement).value = "")}
        hidden
      />
      <Button
        type="tertiary"
        iconLeft={icon}
        text={label}
        className="load-shape-btn"
        onClick={handleButtonClick}
      />
    </>
  );
};

export default ButtonUpload2;
