import { useCallback } from "react";

import {
  SavedFileUploadRequest,
  SavedSearchCriteria,
} from "../../../types/panels/savedSearchPanel/savedSearchData";
import { SearchTypes } from "../../../types/panels/searchPanel/search";

import { SEARCH_TYPES } from "../../../constants/panels/searchPanel/search";
import { FILE_UPLOAD_FOLDER } from "../../../constants/uwiSearchUpload";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapDrawStore from "../../../store/map/draw/mapDrawStore";
import useQueryBuilderStore from "../../../store/search/queryBulder/queryBuilderStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../../store/useStore";

import { filterRequestFormat } from "../../../utils/datagrid";

import { getGeometryFromPolygonArray } from "../../../data/map/mapUtils";

const useSavedSearchExportData = () => {
  const authUser = useStore((state) => state.authUser);
  const currentBounds = useQueryBuilderStore((state) => state.currentBounds);

  const uwisToSearch = useUWISearchStore((state) => state.uwisToSearch);
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);

  const drawnPolygons = useMapDrawStore((state) => state.drawnPolygons);
  const shapeId = useMapDrawStore((state) => state.shapeId);

  const filterModel = useDataGridStore((state) => state.filterModel);

  const savedSearchExportData = useCallback(
    (searchType: SearchTypes) => {
      const searchExportData: {
        searchCriteria?: SavedSearchCriteria;
        fileUploadRequest?: SavedFileUploadRequest;
      } = {};

      if (searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH) {
        searchExportData.searchCriteria = {};

        if (currentBounds.length) {
          searchExportData.searchCriteria.bounds = currentBounds;
        }

        const geometry = getGeometryFromPolygonArray(drawnPolygons);
        if (geometry) {
          searchExportData.searchCriteria.geometry = geometry;
        }
        if (shapeId) {
          searchExportData.searchCriteria.shapeId = shapeId;
        }

        const filters = filterRequestFormat(filterModel);
        if (filters.length) {
          searchExportData.searchCriteria.filters = filters;
        }
      } else {
        if (searchType === SEARCH_TYPES.UWI_SEARCH) {
          searchExportData.searchCriteria = {};

          if (uwisToSearch.length) {
            searchExportData.searchCriteria.uwis = uwisToSearch;
          }

          const filters = filterRequestFormat(filterModel);
          if (filters.length) {
            searchExportData.searchCriteria.filters = filters;
          }
        } else if (
          searchType === SEARCH_TYPES.UWI_FILE_SEARCH ||
          searchType === SEARCH_TYPES.UWI_CONVERTED_FILE_SEARCH
        ) {
          searchExportData.fileUploadRequest = {
            fileName: uploadedUWIFile?.name ?? "",
            folderName: `${FILE_UPLOAD_FOLDER}/${authUser.uid}`,
          };
        }
      }

      return searchExportData;
    },
    [
      currentBounds,
      drawnPolygons,
      shapeId,
      uwisToSearch,
      uploadedUWIFile,
      authUser,
      filterModel,
    ]
  );

  return { savedSearchExportData };
};

export default useSavedSearchExportData;
