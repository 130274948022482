import {
  WellEconomicsInputFields,
  WellEconomicsInputsError,
} from "../../../types/panels/wellPanel/wellEconomicsType";

import * as constant from "../../../constants/panels/wellPanel/wellEconomics";
import { REGEX_UP_TO_THREE_DECIMAL } from "../../../constants/regexp";

import usePanelsStore from "../../../store/panels/panelsStore";

import { getNumberWithComma } from "../../../utils/formatters/numberFormatter";
import { regexNumber, regexParenthesis } from "../../../utils/regexp";

import { removeTrailingZeros } from "../../../utils";

const useWellEconomicsComputations = () => {
  const updateWellEconomicsInputByField = usePanelsStore(
    (state) => state.updateWellEconomicsInputByField
  );
  const updateWellEconomicsGreyedState = usePanelsStore(
    (state) => state.updateWellEconomicsGreyedState
  );

  const formatParenthesisToNegativeNumber = (value: string) => {
    if (
      typeof value === "string" &&
      (regexParenthesis().test(value) ||
        regexParenthesis(REGEX_UP_TO_THREE_DECIMAL).test(value))
    ) {
      const numberValue = `-${value.replace(/[()]/g, "")}`; //remove parenthesis
      return parseFloat(numberValue);
    }
    return parseFloat(value);
  };

  const parseStringToNumber = (value: string | number) => {
    if (typeof value === "string") {
      if (
        regexParenthesis().test(value) ||
        regexParenthesis(REGEX_UP_TO_THREE_DECIMAL).test(value)
      ) {
        return formatParenthesisToNegativeNumber(value);
      } else {
        return parseFloat(value.replace(/[(),]/g, ""));
      }
    } else {
      return value;
    }
  };

  const computeNGLFormula = (
    wellId: number,
    field: WellEconomicsInputFields,
    value: string,
    oilPrice: number | string,
    greyedState: WellEconomicsInputsError
  ) => {
    if (field === constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key) {
      const computedNGLPrice = getNGLPriceValue(value, oilPrice).toFixed(2);

      updateWellEconomicsInputByField(
        wellId,
        constant.WELL_ECONOMICS_NGL_PRICE.key,
        getFormattedValue(field, computedNGLPrice) ?? null
      );

      updateWellEconomicsGreyedState(wellId, {
        ...greyedState,
        [field]: false,
        [constant.WELL_ECONOMICS_NGL_PRICE.key]: true,
      });
    } else if (field === constant.WELL_ECONOMICS_NGL_PRICE.key) {
      const computedNGLDiff = getNGLDifferentialValue(value, oilPrice).toFixed(
        2
      );

      updateWellEconomicsInputByField(
        wellId,
        constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key,
        getFormattedValue(field, computedNGLDiff) ?? null
      );

      updateWellEconomicsGreyedState(wellId, {
        ...greyedState,
        [field]: false,
        [constant.WELL_ECONOMICS_NGL_DIFFERENTIAL.key]: true,
      });
    }
  };

  const getNGLPriceValue = (value: string, oilPrice: number | string) => {
    const oilPriceParsed = parseStringToNumber(oilPrice);
    const NGLDIffParsed = parseStringToNumber(value);

    return ((NGLDIffParsed + oilPriceParsed) / oilPriceParsed) * 100;
  };

  const getNGLDifferentialValue = (
    value: string,
    oilPrice: number | string
  ) => {
    const oilPriceParsed = parseStringToNumber(oilPrice);
    const NGLPriceParsed = parseStringToNumber(value);

    return oilPriceParsed * (NGLPriceParsed / 100) - oilPriceParsed;
  };

  const getFormattedValue = (
    field: WellEconomicsInputFields,
    fieldValue: string | number | null
  ) => {
    if (!fieldValue) {
      return null;
    } else {
      if (typeof fieldValue === "string") {
        const trimmedValue = constant.WELL_ECONOMICS_INPUTS_CONSTANTS[field]
          .isCurrency
          ? fieldValue
          : removeTrailingZeros(fieldValue);

        if (
          regexNumber().test(trimmedValue) ||
          regexNumber(REGEX_UP_TO_THREE_DECIMAL).test(trimmedValue)
        ) {
          //remove comma and convert to float number
          const numberValue = parseFloat(trimmedValue.replace(/,/g, ""));
          if (numberValue < 0) {
            const absolutevalue = Math.abs(numberValue);
            const parenthesisValue =
              field === constant.WELL_ECONOMICS_NGL_PRICE.key
                ? `(${absolutevalue})`
                : `(${getNumberWithComma(absolutevalue)})`;
            return parenthesisValue;
          } else {
            return field === constant.WELL_ECONOMICS_NGL_PRICE.key
              ? numberValue.toString()
              : getNumberWithComma(numberValue);
          }
        } else if (
          regexParenthesis().test(fieldValue) ||
          regexParenthesis(REGEX_UP_TO_THREE_DECIMAL).test(fieldValue)
        ) {
          //remove comma and parenthesis and convert to float number
          const numberValue = trimmedValue.replace(/([(,)])/g, "");
          const parsedVal = parseFloat(numberValue);
          return field === constant.WELL_ECONOMICS_NGL_PRICE.key
            ? `(${numberValue})`
            : `(${getNumberWithComma(parsedVal)})`;
        }
      } else {
        return field === constant.WELL_ECONOMICS_NGL_PRICE.key
          ? fieldValue.toString()
          : getNumberWithComma(fieldValue);
      }
    }
  };

  return {
    parseStringToNumber,
    computeNGLFormula,
    getFormattedValue,
  };
};

export default useWellEconomicsComputations;
