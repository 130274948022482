import { QBAttributeStates } from "../../types/panels/searchPanel/queryBuilder";
import { SearchTypes } from "../../types/panels/searchPanel/search";

import { ATTRIBUTE_ALPHA_ASC } from "../../constants/panels/searchPanel/queryBuilder/sortAttributes";

export const getSortPerAttribute = (currentQBInfo: QBAttributeStates) => {
  return Object.fromEntries(
    Object.entries(currentQBInfo)
      .filter((val) => val[1].sortKey !== undefined)
      .map(([att, info]) => [att, info.sortKey ?? ATTRIBUTE_ALPHA_ASC])
  );
};

export const formatToNonAutoSearchType = (searchType: SearchTypes) => {
  return searchType.replace("-Auto", "") as SearchTypes;
};

export const getIsAutoSaveSearchType = (searchType: SearchTypes) => {
  return searchType.indexOf("-Auto") > -1;
};
