import { ChartAxisType } from "../../types/charts/chartType/chartType";

import { CHART_AXIS, CHART_SCALING_TYPE } from "./charts";

export const scalingList = [
  { name: CHART_SCALING_TYPE.LINEAR, displayName: "Linear" },
  { name: CHART_SCALING_TYPE.LOGARITHMIC, displayName: "Logarithmic" },
];

export const axisList: { name: ChartAxisType; displayName: string }[] = [
  { name: CHART_AXIS.LEFT, displayName: "Left" },
  { name: CHART_AXIS.RIGHT, displayName: "Right" },
];
