import { AttributeKey } from "../../../types/common/attributes";

import { LEGEND } from "../../../constants/map/mapSettings";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import AttributeSelect from "./AttributeSelect";

interface SizeBySelectProps {
  disabled?: boolean;
}

const SizeBySelect = ({ disabled = false }: SizeBySelectProps) => {
  const appendMapOverlayOpened = useMapSettingsStore(
    (state) => state.appendMapOverlayOpened
  );
  const updateWellStyleBubbleSize = useMapSettingsStore(
    (state) => state.updateWellStyleBubbleSize
  );

  // triggers when an attribute is selected
  const handleAttributeSelect = (attribute: AttributeKey) => {
    updateWellStyleBubbleSize(attribute);
    appendMapOverlayOpened(LEGEND.key);
  };

  return (
    <div className="well-size-container">
      <AttributeSelect
        onAttributeSelect={handleAttributeSelect}
        disabled={disabled}
      />
    </div>
  );
};

export default SizeBySelect;
