import { useState } from "react";

import { Drawer } from "@mui/material";

import { SidebarNavigationMenu } from "../../types/panels/sidebar";

import { SIDEBAR_MENU, SIDEBAR_MENU_KEYS } from "../../constants/panels/menu";

import usePanelsStore from "../../store/panels/panelsStore";

import useLeftNavPanel from "../../customHooks/panels/useLeftNavPanel";

import SidebarWellPanelNav from "./SidebarWellPanelNav";
import PanelControls from "./common/PanelControl";
import ExportNavAccordion from "./leftNavPanel/ExportNavAccordion";
import NavMenu from "./leftNavPanel/NavMenu";

const LeftNavigationPanel = () => {
  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null);

  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const activePanel = usePanelsStore((state) => state.activePanel);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const toggleLeftSidePanel = usePanelsStore(
    (state) => state.toggleLeftSidePanel
  );

  const {
    wellsAndPermitPanel,
    isNavbarExpanded,
    handleMenuClick,
    handleOnMouseOut,
    handleOnMouseOver,
    handleOnMouseEnter,
    getIsDisabledMenu,
  } = useLeftNavPanel();

  const getAccordionNavMenu = (
    menuInfo: SidebarNavigationMenu,
    isDisabled: boolean
  ) => {
    if (menuInfo.key === SIDEBAR_MENU_KEYS.EXPORT_PACKAGE_MANAGER) {
      return (
        <ExportNavAccordion
          key={menuInfo.key}
          menuInfo={menuInfo}
          isDisabled={isDisabled}
          isNavbarExpanded={isNavbarExpanded}
        />
      );
    }

    return null;
  };

  return (
    <>
      <Drawer
        ref={setPanelRef}
        anchor="left"
        open={isOpenAppLevelNav}
        className="sidebar-drawer-style"
        disableEnforceFocus
        id="app-level-navigation-drawer"
        hideBackdrop
        onMouseEnter={handleOnMouseEnter}
        onMouseOver={handleOnMouseOver}
        onMouseOut={handleOnMouseOut}
        PaperProps={{
          className: "sidebar-drawer-style-paper default-width",
        }}
      >
        <div className="sidebar-menu">
          {SIDEBAR_MENU.map((menu) => {
            return menu.type === "accordion" ? (
              getAccordionNavMenu(menu, getIsDisabledMenu(menu.key))
            ) : (
              <NavMenu
                key={menu.key}
                menuInfo={menu}
                isActive={activePanel === menu.key}
                isDisabled={getIsDisabledMenu(menu.key)}
                handleMenuClick={(e) => handleMenuClick(menu.key, e)}
              />
            );
          })}
          {wellsAndPermitPanel.length !== 0 && <SidebarWellPanelNav />}
        </div>
        {!isOpenLeftSidePanel && (
          <PanelControls
            anchorEl={panelRef}
            open={isOpenLeftSidePanel}
            clickCallback={() => {
              toggleLeftSidePanel();
            }}
          />
        )}
      </Drawer>
    </>
  );
};

export default LeftNavigationPanel;
