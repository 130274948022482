import { useCallback, useEffect, useState } from "react";

import { DatalakePressureDataResponseBody } from "../../../../types/panels/wellPanel/dataLakeType";
import { MapLayer } from "../../../../types/panels/wellPanel/wellPanel";

import { SORT_ORDER } from "../../../../constants/dataLake";
import { dataLakeSize } from "../../../../constants/panels/wellPanel/logs";
import {
  PRESSURE_DATA_FIELDS,
  PRESSURE_DATA_SEARCHTYPES,
  PRESSURE_DATA_SORT_FIELDS,
} from "../../../../constants/panels/wellPanel/pressureData";

import usePanelsStore from "../../../../store/panels/panelsStore";

import useDatalake from "../useDatalake";
import usePressureData from "../usePressureData";

const useExportWellPanelPressureData = () => {
  const [loadingDataLakeData, setLoadingDataLakeData] = useState(false);
  const [panelInfoWellType, setPanelInfoWellType] = useState<
    MapLayer | undefined
  >(undefined);
  const [panelInfoGroupedWellId, setPanelInfoGroupedWellId] = useState<
    number | undefined
  >(undefined);
  const { getDataLakeData, dataLakeResponse, successRequest, errorRequest } =
    useDatalake();
  const { buildPressureData } = usePressureData();

  const updateWellCardDataByUWI = usePanelsStore(
    (state) => state.updateWellCardDataByUWI
  );
  const parseUWIQuery = (uwiList: string[]) => {
    let query = "";

    uwiList.forEach((uwi, index) => {
      query = query.concat(
        `Uwi:${uwi}`,
        `${index + 1 !== uwiList.length ? " OR " : ""}`
      );
    });

    return query;
  };

  const groupedData = useCallback(
    (response: DatalakePressureDataResponseBody[]) => {
      const groupings: { [key: string]: DatalakePressureDataResponseBody[] } =
        {};

      response.forEach((pressureData) => {
        if (groupings[pressureData.Uwi]) {
          groupings[pressureData.Uwi].push(pressureData);
        } else {
          groupings[pressureData.Uwi] = [pressureData];
        }
      });

      return groupings;
    },
    [dataLakeResponse, successRequest]
  );

  useEffect(() => {
    if (!panelInfoWellType || !panelInfoGroupedWellId) {
      return;
    }

    if (successRequest && dataLakeResponse) {
      const data = groupedData(
        dataLakeResponse as DatalakePressureDataResponseBody[]
      );

      Object.entries(data).forEach(([key, value]) => {
        const mappedData = buildPressureData(value);
        updateWellCardDataByUWI(
          { type: "pressuredata", pressureData: mappedData },
          key,
          panelInfoWellType,
          panelInfoGroupedWellId
        );
      });

      setLoadingDataLakeData(false);
      setPanelInfoGroupedWellId(undefined);
      setPanelInfoWellType(undefined);
    }
  }, [
    dataLakeResponse,
    successRequest,
    panelInfoWellType,
    panelInfoGroupedWellId,
  ]);

  const getBulkPressureData = (
    uwiList: string[],
    wellType: MapLayer,
    groupedWellID: number
  ) => {
    if (Boolean(!uwiList.length)) {
      return;
    }

    setLoadingDataLakeData(true);
    setPanelInfoGroupedWellId(groupedWellID);
    setPanelInfoWellType(wellType);

    const uwiQuery = parseUWIQuery(uwiList);
    const requestBody = {
      SearchTypes: PRESSURE_DATA_SEARCHTYPES,
      Query: uwiQuery,
      IncludeFields: PRESSURE_DATA_FIELDS,
      From: 0, // from count to start at (paging)
      Size: dataLakeSize,
      Sort: {
        SortOrder: SORT_ORDER.ASC,
        SortPropertyNames: PRESSURE_DATA_SORT_FIELDS,
      },
    };
    getDataLakeData(requestBody);
  };

  return { getBulkPressureData, loadingDataLakeData };
};

export default useExportWellPanelPressureData;
