import { useState } from "react";

import { orderBy } from "lodash";

import {
  ChartDataBody,
  FullWidthStackedBarFormattedData,
  FullWidthStackedBarResponseData,
} from "../../types/charts/chartHooks/chartData";
import {
  DashboardChartState,
  DashboardChartType,
} from "../../types/charts/dashboardChartType/dashboardChartType";

import config from "../../configs/appSettings";

import { CHART_DATA_STATE } from "../../constants/charts/charts";
import { DASHBOARD_CHARTS_FIELDS_MAPPER } from "../../constants/charts/dashboardCharts";

import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";

import { callServiceAPI } from "../../action/callServiceAPI";
import useSearchRequest from "../common/useSearchRequest";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useUwiFileUpload from "../search/useUwiFileUpload";
import useChartSearchRequest from "./useChartSearchRequest";

//formula: {value}/{totalValue} * 100
//round by up to 3 decimal places
export const formatStackedBarData = (
  rawData: FullWidthStackedBarResponseData[]
) => {
  if (rawData?.length && rawData[0]?.fields?.length) {
    const data: FullWidthStackedBarFormattedData[] = [];

    rawData[0].fields.forEach((value) => {
      if (value) {
        const computedPercentage =
          (value.wellCount / rawData[0].totalWellCount) * 100;
        data.push({
          label: value.val,
          value: value.wellCount,
          percentageValue: parseFloat(computedPercentage.toFixed(3)),
        });
      }
    });
    return orderBy(data, ["value"], "desc");
  }
  return [];
};

export const useStackedBarChartData = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FullWidthStackedBarFormattedData[] | null>(
    []
  );
  const [error, setError] = useState<[] | null>([]);

  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const { getUWIFileIdWithRetry } = useUwiFileUpload();
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );

  const { buildChartPostRequest, isDashboardChartEmpty } =
    useChartSearchRequest();
  const { buildSearchRequest } = useSearchRequest();

  const { isMetricOnSearch } = useUnitOfMeasure();

  const getStackedBarChartData = async (
    chartDataState: DashboardChartState,
    chartType: DashboardChartType,
    groupBy: string[]
  ) => {
    setLoading(true);
    setData(null);
    setError(null);

    try {
      if (isDashboardChartEmpty(chartDataState)) {
        setData([]);
        setLoading(false);
        return;
      }
      const body: ChartDataBody = {
        chartType: DASHBOARD_CHARTS_FIELDS_MAPPER[chartType],
        groupByFields: groupBy,
      };

      switch (chartDataState) {
        case CHART_DATA_STATE.PRE_SEARCH:
          body.dataForDefaultDashboard = true;

          if (config.isUoMEnabled && isMetricOnSearch)
            body.searchRequest = {
              isMetric: isMetricOnSearch,
            };
          break;
        case CHART_DATA_STATE.INTER_SEARCH:
          body.searchRequest = {
            ...buildSearchRequest(chartType),
            ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
          };
          break;
        case CHART_DATA_STATE.POST_SEARCH:
          body.searchRequest = {
            ...buildChartPostRequest(chartType),
            ...(config.isUoMEnabled && { isMetric: isMetricOnSearch }),
          };
          break;
        default:
          break;
      }

      const response = await callServiceAPI<FullWidthStackedBarResponseData[]>(
        `${config.endpoints.wellService}api/wells/chart`,
        body,
        getUWIFileIdWithRetry,
        uploadedUWIFile,
        isFromUploadedUWIFile
      );

      if (!response || !("data" in response)) {
        setData([]);
        return;
      }
      const responseData: FullWidthStackedBarResponseData[] = response.data;

      const formattedChartData = formatStackedBarData(responseData);
      setData(formattedChartData);
    } catch (e: any) {
      setData([]);
      setError(e);
    }

    setLoading(false);
  };

  return {
    loading,
    error,
    data,
    getStackedBarChartData,
  };
};
