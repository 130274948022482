import { useEffect, useState } from "react";

import { SelectChangeEvent } from "@mui/material";

import { LayerStyles } from "../../../types/map/mapSettings/store";

import {
  BOTTOM_WELL_SPOTS,
  PERMIT_SPOTS,
  WELL_PATH_AND_STICKS,
  WELL_SPOTS,
} from "../../../constants/constants";

import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapStore from "../../../store/map/mapStore";
import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import { isLayerSelected } from "../../../utils/map/mapSettings";

import useRecordType from "../../common/useRecordType";
import usePrevious from "../../usePrevious";

const useWellStyleAccordion = () => {
  const [wellPermitCardDisabled, setwellPermitCardDisabled] = useState(true);

  const layers = useMapStore((state) => state.layers);
  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const currentZoom = useMapStore((state) => state.currentZoom);

  const allGridData = useDataGridStore((state) => state.allGridData);

  const updateLayerStyles = useMapSettingsStore(
    (state) => state.updateLayerStyles
  );

  const { searchedRecordType } = useRecordType();

  const prevRecordType = usePrevious(searchedRecordType);

  const handleToggleLayer = (layerKey: string) => {
    toggleLayer(layerKey);
  };

  const handleLayerStyleChange = (
    e: SelectChangeEvent<string | number>,
    layerKey: string,
    styleKey: keyof LayerStyles
  ) => {
    updateLayerStyles(layerKey, styleKey, e.target.value);
  };

  useEffect(() => {
    if (
      !(
        isLayerSelected(WELL_SPOTS, layers) ||
        isLayerSelected(BOTTOM_WELL_SPOTS, layers) ||
        isLayerSelected(WELL_PATH_AND_STICKS, layers) ||
        isLayerSelected(PERMIT_SPOTS, layers)
      ) ||
      !allGridData?.length
    ) {
      setwellPermitCardDisabled(true);
    } else {
      setwellPermitCardDisabled(false);
    }
  }, [layers, allGridData, currentZoom]);

  return {
    wellPermitCardDisabled,
    handleToggleLayer,
    handleLayerStyleChange,
  };
};

export default useWellStyleAccordion;
