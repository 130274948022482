import { useMemo } from "react";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";

export const useGridSelectedData = () => {
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const allGridData = useDataGridStore((state) => state.allGridData);
  const gridFilteredCount = useDataGridStore(
    (state) => state.gridFilteredCount
  );

  const isSelectedAllGrid = useMemo(() => {
    return Boolean(
      selectedGridDataKeys.length === allGridData.length &&
        selectedGridDataKeys.length &&
        allGridData.length
    );
  }, [selectedGridDataKeys, allGridData]);

  const isDeselectedIds = useMemo(() => {
    if (
      selectedGridDataKeys.length &&
      selectedGridDataKeys.length < gridFilteredCount
    ) {
      const midpointCount = gridFilteredCount / 2;
      if (selectedGridDataKeys.length > midpointCount) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [selectedGridDataKeys, gridFilteredCount]);

  return {
    isSelectedAllGrid,
    isDeselectedIds,
  };
};
