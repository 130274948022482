import {
  DashboardChartAttributes,
  DashboardChartDataField,
} from "../../types/charts/dashboardChartType/dashboardChartType";

import {
  BASIN_NAME,
  COUNTY,
  DISPLAY_FORMATION,
  FIELD_NAME,
  INTERPRETED_PRODUCING_FORMATION,
  SLANT,
  STATE_NAME,
  ULTIMATE_OWNER,
  WELL_STATUS,
  WELL_TYPE,
} from "../attributes";
import { BAR_CHART_DATA_FIELDS } from "./barChartDataFields";

export const DASHBOARD_CHART_TYPES = {
  HORIZONTAL_BAR_CHART: "Horizontal Bar Chart",
  FULL_WIDTH_STACKED_BAR: "Full Width Stacked Bar Chart",
  STACKED_AREA: "Stacked Area",
} as const;

export const DASHBOARD_PANE_CHART_LIST = [
  DASHBOARD_CHART_TYPES.HORIZONTAL_BAR_CHART,
  DASHBOARD_CHART_TYPES.FULL_WIDTH_STACKED_BAR,
  DASHBOARD_CHART_TYPES.STACKED_AREA,
];

export const DASHBOARD_CHARTS_FIELDS_MAPPER: { [key: string]: string } = {
  [DASHBOARD_CHART_TYPES.HORIZONTAL_BAR_CHART]: "sideways-bar",
  [DASHBOARD_CHART_TYPES.FULL_WIDTH_STACKED_BAR]: "stacked-bar",
  [DASHBOARD_CHART_TYPES.STACKED_AREA]: "stacked-area",
};

export const CHART_AGGREGATE_TYPE = {
  AVERAGE_AGGREGATE: { name: "AVG", displayName: "Average" },
  SUM_AGGREGATE: { name: "SUM", displayName: "Sum" },
  COUNT_AGGREGATE: { name: "COUNT", displayName: "Count" },
} as const;

export const DEFAULT_CHARTS_DEFAULT_VALUE: {
  [key: string]: {
    TITLE: string;
    GROUP_BY: string[];
    DATA_FIELDS_TO_DISPLAY: DashboardChartDataField[];
  };
} = {
  [DASHBOARD_CHART_TYPES.HORIZONTAL_BAR_CHART]: {
    TITLE: ULTIMATE_OWNER.label,
    GROUP_BY: [ULTIMATE_OWNER.key],
    DATA_FIELDS_TO_DISPLAY: [
      {
        fieldInfo: BAR_CHART_DATA_FIELDS.find(
          (field) => field.name === "CumOil"
        ),
        aggregate: CHART_AGGREGATE_TYPE.SUM_AGGREGATE.name,
      },
      {
        fieldInfo: BAR_CHART_DATA_FIELDS.find(
          (field) => field.name === "CumGas"
        ),
        aggregate: CHART_AGGREGATE_TYPE.SUM_AGGREGATE.name,
      },
    ],
  },
  [DASHBOARD_CHART_TYPES.FULL_WIDTH_STACKED_BAR]: {
    TITLE: SLANT.label,
    GROUP_BY: [SLANT.key],
    DATA_FIELDS_TO_DISPLAY: [],
  },
  [DASHBOARD_CHART_TYPES.STACKED_AREA]: {
    TITLE: "MONTHLY PRODUCTION BY VINTAGE",
    GROUP_BY: [],
    DATA_FIELDS_TO_DISPLAY: [],
  },
};

export const STACKED_BAR_ATTRIBUTES: DashboardChartAttributes[] = [
  {
    attribute: SLANT.key,
    title: SLANT.label,
  },
  {
    attribute: WELL_TYPE.key,
    title: WELL_TYPE.label,
  },
  {
    attribute: WELL_STATUS.key,
    title: WELL_STATUS.label,
  },
];

export const HORIZONTAL_BAR_ATTRIBUTES: DashboardChartAttributes[] = [
  {
    attribute: ULTIMATE_OWNER.key,
    title: ULTIMATE_OWNER.label,
  },
  {
    attribute: FIELD_NAME.key,
    title: FIELD_NAME.label,
  },
  {
    attribute: BASIN_NAME.key,
    title: BASIN_NAME.label,
  },
  {
    attribute: DISPLAY_FORMATION.key,
    title: DISPLAY_FORMATION.label,
  },
  {
    attribute: INTERPRETED_PRODUCING_FORMATION.key,
    title: INTERPRETED_PRODUCING_FORMATION.label,
  },
  {
    attribute: COUNTY.key,
    title: COUNTY.label,
  },
  {
    attribute: STATE_NAME.key,
    title: STATE_NAME.label,
  },
  {
    attribute: SLANT.key,
    title: SLANT.label,
  },
];

export const HORIZONTAL_BAR_CHART_LABELS = {
  CumBOE: { DEFAULT: "BOE" },
};

export const HORIZONTAL_BAR_CHART_DISPLAY_MAPPER: { [key: string]: string } = {
  [ULTIMATE_OWNER.label]: `${ULTIMATE_OWNER.label}s`,
  [FIELD_NAME.label]: `${FIELD_NAME.label}s`,
  [BASIN_NAME.label]: `${BASIN_NAME.label}s`,
  [DISPLAY_FORMATION.label]: `${DISPLAY_FORMATION.label}s`,
  [INTERPRETED_PRODUCING_FORMATION.label]: `${INTERPRETED_PRODUCING_FORMATION.label}s`,
  [COUNTY.label]: "Counties",
  [STATE_NAME.label]: `${STATE_NAME.label}s`,
  [SLANT.label]: `${SLANT.label}s`,
};
