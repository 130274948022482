import { FC, useCallback, useState } from "react";

import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";

import { CircularButton } from "component-library";

import { SortDirection } from "../../../types/information/information";

import {
  ASC,
  DATA_CURRENCY_TITLE,
  DESC,
  LAST_UPDATED_DATE,
  MOST_RECENT_PRODUCTION,
  MOST_RECENT_PRODUCTION_LABEL,
  STATE,
  STATE_LABEL,
  UPDATE_DATE_LABEL,
} from "../../../constants/dataCurrency";

import useDataCurrencyStore from "../../../store/dataCurrencies/dataCurrencyStore";

import useDataCurrencies from "../../../customHooks/dataCurrency/useDataCurrencies";
import useSortedDataCurrencies from "../../../customHooks/dataCurrency/useSortedDataCurrencies";

import StyleOverrideThemeProvider from "../styleOverridesTheme";
import SortableTableLabel from "./SortTableLabel";

const Title = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginBottom: 23,
});

const LoadingSpinner = styled("div")({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 600,
});

const CustomCircularProgress = styled(CircularProgress)({
  color: "#434343",
});

const Container = styled("div")({
  padding: "0 19px",
});

const DataCurrencyTable: FC = () => {
  const [sortAttribute, setSortAttribute] = useState<string>(STATE);
  const [sortOrder, setSortOrder] = useState<SortDirection>(ASC);
  useDataCurrencies();
  useSortedDataCurrencies(sortAttribute, sortOrder);
  const [orderState, setOrderState] = useState<SortDirection>(ASC);
  const [orderLastUpdate, setOrderLastUpdate] = useState<SortDirection>(ASC);
  const [orderMostRecent, setOrderMostRecent] = useState<SortDirection>(ASC);
  const updateIsOpenDataCurrencyTable = useDataCurrencyStore(
    (state) => state.updateIsOpenDataCurrencyTable
  );

  const currencyData = useDataCurrencyStore((state) => state.currencyData);
  const isLoadingCurrencyData = useDataCurrencyStore(
    (state) => state.isLoadingCurrencyData
  );

  const capitalizeText = useCallback((state: string) => {
    const text = state.toLowerCase() || "";
    const words = text.split(" ") || [];
    const capitalizedWords = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }, []);

  const handleSort = (property: string) => {
    if (property === STATE) {
      const isAscOrderState = orderState === ASC;
      setOrderState(isAscOrderState ? DESC : ASC);
      setSortAttribute(property);
      setSortOrder(isAscOrderState ? DESC : ASC);
    } else if (property === LAST_UPDATED_DATE) {
      const isAscOrderLastUpdate = orderLastUpdate === ASC;
      setOrderLastUpdate(isAscOrderLastUpdate ? DESC : ASC);
      setSortAttribute(property);
      setSortOrder(isAscOrderLastUpdate ? DESC : ASC);
    } else if (property === MOST_RECENT_PRODUCTION) {
      const isAscOrderMostRecent = orderMostRecent === ASC;
      setOrderMostRecent(isAscOrderMostRecent ? DESC : ASC);
      setSortAttribute(property);
      setSortOrder(isAscOrderMostRecent ? DESC : ASC);
    }
  };

  return (
    <StyleOverrideThemeProvider>
      <Container className="data-currency-container">
        <Title>
          <Typography fontSize={16} color="#fff">
            {DATA_CURRENCY_TITLE}
          </Typography>
          <CircularButton
            icon="close"
            className="data-currency-close-btn"
            onClick={() => updateIsOpenDataCurrencyTable(false)}
          />
        </Title>

        {isLoadingCurrencyData ? (
          <LoadingSpinner>
            <CustomCircularProgress />
          </LoadingSpinner>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <SortableTableLabel
                    direction={orderState}
                    label={STATE_LABEL}
                    onClick={() => handleSort(STATE)}
                  />
                </TableCell>
                <TableCell>
                  <SortableTableLabel
                    direction={orderLastUpdate}
                    label={UPDATE_DATE_LABEL}
                    onClick={() => handleSort(LAST_UPDATED_DATE)}
                  />
                </TableCell>
                <TableCell>
                  <SortableTableLabel
                    direction={orderMostRecent}
                    label={MOST_RECENT_PRODUCTION_LABEL}
                    onClick={() => handleSort(MOST_RECENT_PRODUCTION)}
                  />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {currencyData?.map((row, currencyKey) => (
                <TableRow key={currencyKey}>
                  <TableCell>{capitalizeText(row.state)}</TableCell>
                  <TableCell>{row.lastUpdatedDate}</TableCell>
                  <TableCell>{row.mostRecentProduction}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </Container>
    </StyleOverrideThemeProvider>
  );
};

export default DataCurrencyTable;
