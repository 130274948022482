import { Map } from "ol";
import { applyTransform } from "ol/extent";
import { getTransform } from "ol/proj";

import {
  Extent,
  ExtentFromResponse,
  ExtentPadding,
} from "../../types/map/gis/extent";

import {
  MAX_LATITUDE,
  MAX_LONGITUDE,
  MIN_LATITUDE,
  MIN_LONGITUDE,
} from "../../constants/constants";

export const applyZoomToFit = (
  map: Map,
  extentData: Extent,
  { top, right, bottom, left }: ExtentPadding
) => {
  let extent = applyTransform(
    extentData,
    getTransform("EPSG:4326", "EPSG:3857")
  );

  map.getLayers().forEach((layerGroup: any) => {
    if (layerGroup.values_.title === "Draw Layer") {
      layerGroup.getLayers().forEach((layer: any) => {
        const drawLayerSource = layer.getSource();
        if (drawLayerSource.getFeatures().length) {
          // Zoom to fit adjustment - for polygon search
          extent = drawLayerSource.getExtent();
        }
      });
      map.getView().fit(extent, {
        size: map.getSize(),
        maxZoom: 16,
        padding: [top, right, bottom, left],
      });
    }
  });
};

export const cleanExtentData: ExtentFromResponse = (exData) => {
  if (!exData) return;
  const { longitude, latitude } = exData;
  if (!longitude || !latitude) return;
  const { min: longMin, max: longMax } = longitude;
  const { min: latMin, max: latMax } = latitude;
  if (!longMin || !longMax || !latMin || !latMax) return;
  return [
    Math.max(longMin, MIN_LONGITUDE),
    Math.max(latMin, MIN_LATITUDE),
    Math.min(longMax, MAX_LONGITUDE),
    Math.min(latMax, MAX_LATITUDE),
  ];
};
