import { StateCreator } from "zustand";

import { SearchCriteriaStore } from "../../types/grid";

//we can move this into a separate folder or under search folder
const searchCriteriaSlice: StateCreator<SearchCriteriaStore> = (set, get) => ({
  searchCriteria: {
    drawnPolygons: [],
    polygonType: "",
    shapeId: "",
    fileId: "",
    currentBounds: [],
    searchedUWIs: [],
  },
  //update single object from searchCriteria
  updateSearchCriteria: (searchCriteria) =>
    set((state) => ({
      searchCriteria: {
        ...get().searchCriteria,
        ...searchCriteria,
      },
    })),
  //override searchCriteria
  updateNewSearchCriteria: (searchCriteria) =>
    set((state) => ({ searchCriteria })),
  initialGridSearchMade: false,
  updateInitialGridSearchMade: (initialGridSearchMade) =>
    set((state) => ({
      initialGridSearchMade,
    })),
});

export { searchCriteriaSlice };
