import { useMemo } from "react";

import {
  FormControlLabel,
  FormGroup,
  Tooltip,
  Typography,
} from "@mui/material";

import { LAYER_NAME_TO_LABEL_MAPPER } from "../../constants/constants";
import { GEO_EDGES_LAYERS } from "../../constants/map/mapSettings";

import useMapStore from "../../store/map/mapStore";

import AccordionSection from "../common/AccordionSection";
import CustomCheckbox from "../common/CustomCheckbox";

const GeoEdgeLayerAccordian = () => {
  const layers = useMapStore((state) => state.layers);
  const currentZoom = useMapStore((state) => state.currentZoom);
  const toggleLayer = useMapStore((state) => state.toggleLayer);

  const geoedgesLayers = useMemo(
    () => layers.filter((layer) => GEO_EDGES_LAYERS.includes(layer.name)),
    [layers]
  );

  const handleChange = (layerName: string) => {
    toggleLayer(layerName);
  };

  return (
    <AccordionSection
      title="GeoEdges "
      className="geoedgelayer-cls nested-section-content"
      expandIconStart
    >
      <AccordionSection title="Woodford" expandIconStart>
        <FormGroup>
          {geoedgesLayers.map((layer, index) => (
            <FormControlLabel
              disabled={Boolean(
                layer.isDisabled &&
                  layer?.minZoom &&
                  currentZoom < layer?.minZoom
              )}
              key={layer.name}
              control={
                <CustomCheckbox
                  checked={Boolean(layer.isSelected)}
                  onChange={() => handleChange(layer.name)}
                />
              }
              label={
                <Tooltip title={LAYER_NAME_TO_LABEL_MAPPER[layer.name]}>
                  <Typography>
                    {LAYER_NAME_TO_LABEL_MAPPER[layer.name]}
                  </Typography>
                </Tooltip>
              }
            />
          ))}
        </FormGroup>
      </AccordionSection>
    </AccordionSection>
  );
};

export default GeoEdgeLayerAccordian;
