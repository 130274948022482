import { FC } from "react";

import { Button, Dialog, DialogContent, DialogTitle } from "@mui/material";

import { ShareLinkAlertsProps } from "../../../types/shareLink/shareLink";

const ShareLinkAlerts: FC<ShareLinkAlertsProps> = ({
  message,
  title,
  actions,
  ...props
}) => {
  return (
    <Dialog {...props} className="share-link-alert-container">
      <DialogTitle>{title ?? "Alert"}</DialogTitle>
      <DialogContent>{message ?? ""}</DialogContent>
      {actions && (
        <div className="alert-action">
          {actions.map((button, index) => (
            <Button key={index} onClick={() => button.onClick()}>
              {button.text}
            </Button>
          ))}
        </div>
      )}
    </Dialog>
  );
};

export default ShareLinkAlerts;
