import { useState } from "react";

import axios, { AxiosError } from "axios";

import { APIErrorResponse } from "../../../types/common/api";
import { PermitData } from "../../../types/panels/wellPanel/wellPanel";

import config from "../../../configs/appSettings";

import { PERMIT_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import useGetRequestParams from "../../common/useGetRequestParams";

const useWellPanelPermits = () => {
  const [data, setData] = useState<PermitData | undefined>(undefined);
  const [isDataLoading, setIsDataLoading] = useState<boolean>(false);
  const [dataError, setDataError] = useState<string | undefined>(undefined);
  const { buildRequestQueryParam } = useGetRequestParams();

  const getWellPermits = async (wellId: number, uwi: string) => {
    const permitData: PermitData = {
      type: "permit",
      permit: [],
      isDataLoaded: false,
    };
    try {
      setIsDataLoading(true);
      const response = await axios.get(
        `${config.endpoints.wellService}api/wells/${wellId}/${PERMIT_SECTION.urlSlug}`,
        {
          ...buildRequestQueryParam,
        }
      );

      if (!response || !("data" in response)) {
        permitData.isDataLoaded = true;
        setData(permitData);
        return;
      }

      permitData.isDataLoaded = true;
      permitData.permit = response.data;
      setData(permitData);

      //to remove if old well card is removed
      return response.data;
    } catch (errorResponse) {
      const err = errorResponse as AxiosError<APIErrorResponse>;
      setDataError(err.message);
      //to remove if old well card is removed
      return {
        status: "error",
        msg: err.message,
      };
    } finally {
      setIsDataLoading(false);
    }
  };

  return { getWellPermits, data, isDataLoading, dataError };
};

export default useWellPanelPermits;
