import { create } from "zustand";

import {
  DataCurrencyActionType,
  DataCurrencyStoreType,
} from "../../types/information/information";

import dataCurrencySlice from "./dataCurrencySlice";

const useDataCurrencyStore = create<
  DataCurrencyActionType & DataCurrencyStoreType
>()((...a) => ({
  ...dataCurrencySlice(...a),
}));

export default useDataCurrencyStore;
