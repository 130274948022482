// UWI SEARCH
export const UWI_SEARCH_FIELD_ERROR =
  "UWI’s must be between 10-14 or 16 characters and cannot include any of the following characters: `!@#$%^&*()_+-=[]{};':\"\\|.<>/?~";
export const UWI_SEARCH_FILE_FORMAT_ERROR =
  "File should be either in .CSV or .TXT format. Please upload another file.";
export const UWI_STATUS_ERROR_FOR_RETRY =
  "File ID does not exist in staging table";
export const PASTED_UWIS_MORE_THAN_60K =
  "Only accepts up to 60,000 UWIs. For bigger UWI searches, use the file upload.";
export const PASTED_UWI_LIMIT = 60000;
export const FILE_ID = "fileId";
export const SEARCH_REQUEST = "searchRequest";
export const SEARCH_TYPE = "searchType";
export const CHART_TYPE = "chartType";
export const SEARCH_BOUNDS = "bounds";
export const ATTRIBUTE_SEARCH = "attribute";
export const GEOMETRY_SERACH = "geometry";
export const SHAPE_FILE_ID = "shapeId";
