import { FC, SVGProps } from "react";

interface TGSOperatorIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const TGSOperatorIcon: FC<TGSOperatorIconProps> = ({
  width = "9",
  height = "7",
  fill = "none",
  pathFill = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 9 7"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.95195 1.55556H6.35195V0.777778L5.55195 0H3.95195L3.15195 0.777778V1.55556H1.55195C1.11195 1.55556 0.751953 1.90556 0.751953 2.33333V4.27778C0.751953 4.56944 0.911953 4.81444 1.15195 4.95056V6.22222C1.15195 6.65389 1.50795 7 1.95195 7H7.55195C7.99595 7 8.35195 6.65389 8.35195 6.22222V4.94667C8.58795 4.81056 8.75195 4.56167 8.75195 4.27778V2.33333C8.75195 1.90556 8.39195 1.55556 7.95195 1.55556ZM3.95195 0.777778H5.55195V1.55556H3.95195V0.777778ZM1.55195 2.33333H7.95195V4.27778H5.95195V3.11111H3.55195V4.27778H1.55195V2.33333ZM5.15195 4.66667H4.35195V3.88889H5.15195V4.66667ZM7.55195 6.22222H1.95195V5.05556H3.55195V5.44444H5.95195V5.05556H7.55195V6.22222Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default TGSOperatorIcon;
