import { useEffect, useState } from "react";

import classNames from "classnames";

import { SidebarNavigationMenu } from "../../../types/panels/sidebar";

import NavMenu from "./NavMenu";

interface AccordionNavMenuProps {
  menuInfo: SidebarNavigationMenu;
  isDisabled: boolean;
  isLoading?: boolean;
  isNavbarExpanded: boolean;
  isModalOpen?: boolean;
  className?: string;
  listItems: {
    key: string;
    label: string;
    subLabel: string;
    isDisabled: boolean;
    onClick: () => void;
  }[];
}

const AccordionNavMenu = ({
  menuInfo,
  isDisabled,
  isLoading = false,
  isNavbarExpanded,
  isModalOpen = false,
  className,
  listItems,
}: AccordionNavMenuProps) => {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState("");

  useEffect(() => {
    if (!isNavbarExpanded) {
      setIsAccordionOpen(false);
      setSelectedItem("");
    }
  }, [isNavbarExpanded]);

  return (
    <>
      <NavMenu
        menuInfo={menuInfo}
        isActive={isAccordionOpen || isModalOpen}
        isDisabled={isDisabled}
        isLoading={isLoading}
        handleMenuClick={() => {
          setIsAccordionOpen(!isAccordionOpen);
        }}
        className={className}
      />
      {isAccordionOpen && !isDisabled && (
        <div className="accordion-content-container">
          {listItems.map((item) => (
            <div
              key={item.label}
              className={classNames("accordion-content-list-item", {
                selected: selectedItem === item.key,
                disabled: item.isDisabled,
              })}
              onClick={() => {
                setSelectedItem(item.key);
                item.onClick();
                setIsAccordionOpen(false);
              }}
              role="presentation"
            >
              <span className="accordion-list-item-label">{item.label}</span>
              {item.isDisabled && (
                <span className="accordion-list-item-sublabel">
                  {item.subLabel}
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default AccordionNavMenu;
