import { create } from "zustand";

import { ChartStoreState } from "../../types/charts/chartStore/chartStore";

import {
  chartDataSlice,
  chartExportSlice,
  chartSettingsSlice,
} from "./chartSlice";
import { dashboardChartDataSlice } from "./dashboardChartSlice";

const useChartStore = create<ChartStoreState>()((...a) => ({
  ...chartDataSlice(...a),
  ...chartSettingsSlice(...a),
  ...chartExportSlice(...a),
  ...dashboardChartDataSlice(...a),
}));

export default useChartStore;
