import { FC, useCallback } from "react";

import {
  FormControl,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

import { ChartAxisType } from "../../../../types/charts/chartType/chartType";
import { AxisDropdownProps } from "../../../../types/charts/chartTypeSettings/chartTypeSettings";

import { axisList } from "../../../../constants/charts/multipleAxisConfig";

const AxisDropdown: FC<AxisDropdownProps> = ({
  handleOnSelectAxisChange,
  selectedAxis,
  chartAxisList,
  isAttributeSelected,
  selectedList,
}) => {
  const getAxisLabel = useCallback(
    (axisName: string) => {
      return axisList.find((axis) => axis.name === axisName)?.displayName ?? "";
    },
    [chartAxisList]
  );

  return (
    <FormControl>
      <Select
        className="axis-select"
        value={selectedAxis}
        onChange={(e: SelectChangeEvent<ChartAxisType>) => {
          handleOnSelectAxisChange(e);
        }}
        disabled={!isAttributeSelected || selectedList.length <= 1}
        renderValue={(selected) => {
          if (!isAttributeSelected) {
            return "- Select -";
          }

          return getAxisLabel(selectedAxis);
        }}
        displayEmpty
      >
        {chartAxisList.map((axis, index) => {
          return (
            <MenuItem key={index} value={axis.name}>
              {getAxisLabel(axis.name)}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default AxisDropdown;
