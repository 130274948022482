import { useCallback, useState } from "react";

import axios from "axios";

import { WMS } from "../../../types/map";
import {
  GetTGSLayerLegendResponse,
  LayerLegendItem,
} from "../../../types/map/layers/useGetTgsLayerLegendApi";

import config from "../../../configs/appSettings";

const useGetTgsLayerLegend = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<LayerLegendItem[] | null>(null);
  const [error, setError] = useState<unknown>(null);

  const getLegendItems = useCallback((resp: GetTGSLayerLegendResponse) => {
    const legendItems: LayerLegendItem[] = [];
    if (!resp.Legend.length) return [];

    const rules = resp.Legend[0].rules;
    rules.forEach(({ title, symbolizers }) => {
      if (symbolizers.length) {
        const symbol = symbolizers[0];
        legendItems.push({
          label: title,
          color: symbol.Polygon.fill,
        });
      }
    });

    return legendItems;
  }, []);

  const getTGSLegend = async ({ layerName }: { layerName: string }) => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const layerDefinition = config.layerDefinitions[layerName].layers[0] as WMS;
    const { LAYERS, CQL_FILTER, STYLES } = layerDefinition.params;

    try {
      const url = config.endpoints.tgsLegend;
      const response = await axios.get<GetTGSLayerLegendResponse>(url, {
        params: {
          layer: LAYERS,
          cqlFilter: CQL_FILTER,
          style: STYLES,
        },
      });
      const legendItems = getLegendItems(response.data);
      setData(legendItems);
      setIsLoading(false);
    } catch (error) {
      console.error("getTGSLegend error", error);
      setError(error);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    getTGSLegend,
  };
};

export default useGetTgsLayerLegend;
