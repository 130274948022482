import React, { useMemo, useState } from "react";

import {
  Backdrop,
  Button as MuiButton,
  Popover,
  Typography,
} from "@mui/material";

import { Button } from "component-library";

import { PillButtonOptions } from "component-library/components/buttons/pill/button";

interface ButtonWithPromptProps {
  description: string;
  showPrompt?: boolean;
  handleConfirmClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCancelClick: (event?: React.MouseEvent<HTMLButtonElement>) => void;
  buttonProps: PillButtonOptions;
}

const ButtonWithPrompt: React.FC<ButtonWithPromptProps> = ({
  description,
  showPrompt = false,
  handleConfirmClick,
  handleCancelClick,
  buttonProps,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleConfirm = (event: React.MouseEvent<HTMLButtonElement>) => {
    handleClose();
    handleConfirmClick(event);
  };

  const handleCancel = () => {
    handleClose();
    if (handleCancelClick) handleCancelClick();
  };

  const isShowPopover = useMemo(() => showPrompt && open, [showPrompt, open]);
  const handleButtonClick = useMemo(
    () => (showPrompt ? handleClick : handleConfirmClick),
    [showPrompt, handleConfirmClick]
  );

  return (
    <>
      <Button
        {...buttonProps}
        onClick={(e) =>
          handleButtonClick(e as React.MouseEvent<HTMLButtonElement>)
        }
      />
      <Backdrop className="text-white" open={isShowPopover}>
        <Popover
          open={isShowPopover}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          classes={{ root: "button-popover-confirmation" }}
        >
          <Typography>{description}</Typography>
          <MuiButton variant="text" onClick={handleConfirm}>
            YES
          </MuiButton>
          <Typography>/</Typography>
          <MuiButton variant="text" onClick={handleCancel}>
            NO
          </MuiButton>
        </Popover>
      </Backdrop>
    </>
  );
};

export default ButtonWithPrompt;
