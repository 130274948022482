const HourGlassIcon = () => {
  return (
    <svg
      width="14"
      height="18"
      viewBox="0 0 14 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.66671 15.666H10.3334V13.166C10.3334 12.2493 10.007 11.4646 9.35421 10.8118C8.70143 10.1591 7.91671 9.83268 7.00004 9.83268C6.08337 9.83268 5.29865 10.1591 4.64587 10.8118C3.9931 11.4646 3.66671 12.2493 3.66671 13.166V15.666ZM0.333374 17.3327V15.666H2.00004V13.166C2.00004 12.3188 2.19796 11.5237 2.59379 10.7806C2.98962 10.0375 3.54171 9.44379 4.25004 8.99935C3.54171 8.5549 2.98962 7.96115 2.59379 7.2181C2.19796 6.47504 2.00004 5.6799 2.00004 4.83268V2.33268H0.333374V0.666016H13.6667V2.33268H12V4.83268C12 5.6799 11.8021 6.47504 11.4063 7.2181C11.0105 7.96115 10.4584 8.5549 9.75004 8.99935C10.4584 9.44379 11.0105 10.0375 11.4063 10.7806C11.8021 11.5237 12 12.3188 12 13.166V15.666H13.6667V17.3327H0.333374Z"
        fill="#B5B5B5"
      />
    </svg>
  );
};

export default HourGlassIcon;
