import { useMemo } from "react";

import { RecordType } from "../../types/common/appMode/appMode";

import { RECORD_TYPES } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";

import useDataGridStore from "../../store/grid/dataGridStore";
import useQueryBuilderStore from "../../store/search/queryBulder/queryBuilderStore";

import { getRecordTypeOnBound } from "../../utils/common/boundsData";

const useRecordType = () => {
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const currentBounds = useQueryBuilderStore((state) => state.currentBounds);

  const searchedRecordType = useMemo(
    () =>
      getRecordTypeOnBound(searchCriteria.currentBounds) ?? RECORD_TYPES.WELL,
    [searchCriteria.currentBounds]
  );

  const queryBuilderRecordType = useMemo(
    () => getRecordTypeOnBound(currentBounds),
    [currentBounds]
  );

  const isCurrentBoundRecordTypeOnly = useMemo(() => {
    return currentBounds.length === 1;
  }, [currentBounds]);

  const isWellRecord = (type: RecordType) => {
    return type === RECORD_TYPES.WELL;
  };

  const isPermitRecord = (type: RecordType) => {
    return type === RECORD_TYPES.PERMIT;
  };

  const getRecordTypeLabel = (type: string) => {
    let label = "";
    switch (type) {
      case RECORD_TYPES.WELL:
        label = "Wells";
        break;
      case RECORD_TYPES.PERMIT:
        label = "Permits";
        break;
      case RECORD_TYPES.WELLS_AND_PERMIT:
        label = RECORD_TYPES.WELLS_AND_PERMIT;
        break;
      default:
        label = type;
        break;
    }

    return label;
  };

  return {
    searchedRecordType,
    queryBuilderRecordType,
    isCurrentBoundRecordTypeOnly,
    isPermitRecord,
    isWellRecord,
    getRecordTypeLabel,
  };
};

export default useRecordType;
