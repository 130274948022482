import { Annotations, PlotData } from "plotly.js";

import {
  GunBarrelChildren,
  GunBarrelFormations,
  GunBarrelParents,
  GunBarrelSiblings,
  GunBarrelTargetWell,
} from "../../types/panels/wellPanel/gunBarrelType";

import { getFormattedWellNameAndNumber } from "../../utils/helper";

const useGunBarrelTraceUtils = () => {
  const createTrace = (
    { x = [0], y = [], text = [] }: Partial<PlotData>,
    obj: Partial<PlotData>
  ) => {
    const trace: Partial<PlotData> = {
      x,
      y,
      text,
      hoverinfo: "none",
      ...obj,
    };
    return trace;
  };

  const createAnnotation = (
    text: string,
    { x, y }: { x: number; y: number },
    annotationConfig: Partial<Annotations>
  ) => {
    const annotations: Partial<Annotations> = {
      x,
      y,
      text,
      ...annotationConfig,
    };
    return annotations;
  };

  /**
   * Creates Text Array for a Trace
   * Well.wellNumber may be undefined for targetWell
   */
  const createHoverTemplate = (
    wells:
      | GunBarrelChildren[]
      | GunBarrelFormations[]
      | GunBarrelParents[]
      | GunBarrelSiblings[]
      | GunBarrelTargetWell[]
  ) => {
    return wells.map((well: any) =>
      getFormattedWellNameAndNumber(well.wellName, well.wellNumber)
    );
  };
  return {
    createTrace,
    createAnnotation,
    createHoverTemplate,
  };
};

export default useGunBarrelTraceUtils;
