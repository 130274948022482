import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "./../StyledAccordions";

import AndarkoBasinStructure from "../../../../assets/images/release-notes/AndarkoBasinStructure.png";
import QBEnhancement from "../../../../assets/images/release-notes/QBEnhancement.png";
import R360Menu from "../../../../assets/images/release-notes/R360Menu.png";
import R360Modal from "../../../../assets/images/release-notes/R360Modal.png";
import R360Site from "../../../../assets/images/release-notes/R360Site.png";
import SortGridBySelected from "../../../../assets/images/release-notes/SortGridBySelected.png";

const Version20231004: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20231004"}
      onChange={handleChange("Version20231004")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2023.10.04
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          10/04/2023
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes an enhancement to query builder, R360 direct
          access and Anadarko Basin structural maps.
        </CustomTypography>
      </AccordionDetails>

      <AccordionContent>• Query Builder Enhancements</AccordionContent>
      <AccordionSubContent>
        • Quality of life UI enhancements to the query builder which result in
        better search attribute organization and easier attribute selection.
      </AccordionSubContent>
      <AccordionImage src={QBEnhancement} width="100%" draggable={false} />

      <AccordionContent>
        • R360 direct access through Well Data Analytics
      </AccordionContent>
      <AccordionSubContent>
        • Well Data Analytics users can directly access to available well logs
        in R360 through Well Data Analytics by selecting wells from searched map
        or grid well results.
      </AccordionSubContent>
      <AccordionImage src={R360Menu} draggable={false} width={"100%"} />
      <AccordionImage src={R360Modal} draggable={false} width={"100%"} />
      <AccordionImage src={R360Site} draggable={false} width={"100%"} />

      <AccordionContent>• Sort Grid by Selected Wells</AccordionContent>
      <AccordionSubContent>
        • A new button on the grid header that will pull all selected wells to
        the top of the grid for quicker analysis.
      </AccordionSubContent>
      <AccordionImage
        src={SortGridBySelected}
        draggable={false}
        width={"100%"}
      />

      <AccordionContent>• Anadarko Basin Structure Map Layers</AccordionContent>
      <AccordionSubContent>
        • The recently updated Anadarko stratigraphic model layers are now
        available in Well Data Analytics.
      </AccordionSubContent>
      <AccordionImage
        src={AndarkoBasinStructure}
        draggable={false}
        width={"100%"}
      />
    </Accordion>
  );
};

export default Version20231004;
