import { useCallback } from "react";

import useAttributeUnit from "../common/useAttributeUnit";
import useUnitOfMeasure from "../common/useUnitOfMeasure";

const useChartFormatter = () => {
  const { isMetricOnSearch } = useUnitOfMeasure();

  const { convertFtToMeter } = useAttributeUnit();

  const formatLateralLengthUnit = useCallback(
    (
      fieldHasLateralLength: boolean,
      chartSettingShowLateralLength: boolean,
      fieldUnit: string
    ) => {
      let formattedLateralLengthUnit = fieldUnit;

      if (fieldHasLateralLength && chartSettingShowLateralLength) {
        formattedLateralLengthUnit = `${fieldUnit} per ${convertFtToMeter(
          isMetricOnSearch
        )}`;
      }

      return formattedLateralLengthUnit;
    },
    [isMetricOnSearch]
  );

  const formatDailyUnit = (
    fieldHasDaily: boolean,
    chartSettingShowDaily: boolean,
    fieldUnit: string
  ) => {
    let formattedDailyUnit = fieldUnit;
    if (fieldHasDaily && chartSettingShowDaily) {
      formattedDailyUnit = `${fieldUnit}/d`;
    }
    return formattedDailyUnit;
  };

  const formatUnit = (
    fieldHasDaily: boolean,
    chartSettingShowDaily: boolean,
    fieldHasLateralLength: boolean,
    chartSettingShowLateralLength: boolean,
    fieldUnit: string
  ) => {
    let formattedUnit = fieldUnit;

    if (formattedUnit) {
      const formattedDailyUnit = formatDailyUnit(
        fieldHasDaily,
        chartSettingShowDaily,
        fieldUnit
      );
      const formattedLateralLengthUnit = formatLateralLengthUnit(
        fieldHasLateralLength,
        chartSettingShowLateralLength,
        formattedDailyUnit
      );

      formattedUnit = formattedLateralLengthUnit;
    }

    return formattedUnit;
  };

  return { formatUnit };
};

export default useChartFormatter;
