import { FC, SVGProps } from "react";

interface TGSLandingZoneIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const TGSLandingZoneIcon: FC<TGSLandingZoneIconProps> = ({
  width = "10",
  height = "9",
  fill = "none",
  pathFill = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 9"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.75195 2C6.75195 1.72396 6.6543 1.48828 6.45898 1.29297C6.26367 1.09766 6.02799 1 5.75195 1C5.47591 1 5.24023 1.09766 5.04492 1.29297C4.84961 1.48828 4.75195 1.72396 4.75195 2C4.75195 2.27604 4.84961 2.51172 5.04492 2.70703C5.24023 2.90234 5.47591 3 5.75195 3C6.02799 3 6.26367 2.90234 6.45898 2.70703C6.6543 2.51172 6.75195 2.27604 6.75195 2ZM7.75195 2C7.75195 2.28385 7.70898 2.51693 7.62305 2.69922L6.20117 5.72266C6.15951 5.80859 6.09766 5.8763 6.01562 5.92578C5.93359 5.97526 5.8457 6 5.75195 6C5.6582 6 5.57031 5.97526 5.48828 5.92578C5.40625 5.8763 5.3457 5.80859 5.30664 5.72266L3.88086 2.69922C3.79492 2.51693 3.75195 2.28385 3.75195 2C3.75195 1.44792 3.94727 0.976562 4.33789 0.585938C4.72852 0.195312 5.19987 0 5.75195 0C6.30404 0 6.77539 0.195312 7.16602 0.585938C7.55664 0.976562 7.75195 1.44792 7.75195 2Z"
        fill={pathFill}
      />
      <path
        d="M1.34558 8.63756C1.45093 8.65374 1.55719 8.66353 1.66377 8.66687C1.81034 8.66687 1.84027 8.65431 1.84027 8.45215V6.33313H1.56972C1.34558 6.33313 1.12633 6.33313 1.12633 6.69498C1.1273 6.7797 1.13444 6.86424 1.1477 6.94797H0.751953V6C1.10068 6.01256 1.56972 6.02931 2.1719 6.02931C2.77407 6.02931 3.24311 6.01256 3.59184 6V6.94797H3.19486C3.20838 6.86426 3.21573 6.77971 3.21685 6.69498C3.21685 6.33313 2.99699 6.33313 2.77346 6.33313H2.50291V8.45215C2.50291 8.65431 2.53345 8.66687 2.67941 8.66687C2.78599 8.66353 2.89225 8.65374 2.9976 8.63756V9C2.72644 8.98744 2.46016 8.97069 2.1719 8.97069C1.88363 8.97069 1.61674 8.98744 1.34558 9V8.63756Z"
        fill={pathFill}
      />
      <path
        d="M6.79287 7.82643C6.69541 7.81457 6.6141 7.79876 6.55297 7.79876C6.49184 7.79876 6.46358 7.81457 6.46358 7.92583V8.74134C6.09392 8.87688 5.76059 9 5.27731 9C4.23349 9 3.75195 8.30761 3.75195 7.51977C3.75195 6.64834 4.35748 6 5.43418 6C5.76066 6.01241 6.08416 6.06594 6.39668 6.15926V6.97873H6.00857C6.02157 6.89441 6.02832 6.80929 6.02875 6.72402C6.02875 6.50546 5.93533 6.2869 5.47224 6.2869C4.49704 6.2869 4.49185 7.22948 4.49185 7.58584C4.49185 7.70501 4.51665 8.71536 5.36151 8.71536C5.53292 8.71234 5.70121 8.66998 5.85286 8.59168V7.92583C5.85286 7.83038 5.80038 7.79876 5.70695 7.79876C5.61353 7.79876 5.47628 7.81852 5.37766 7.82643V7.5C5.6216 7.51186 5.86901 7.52767 6.11295 7.52767C6.34363 7.52767 6.56796 7.51186 6.79576 7.5L6.79287 7.82643Z"
        fill={pathFill}
      />
      <path
        d="M6.75195 8.01467H7.14208C7.1277 8.12136 7.11943 8.22875 7.1173 8.33634C7.1173 8.5553 7.34089 8.71445 7.77136 8.71445C8.20183 8.71445 8.40525 8.47573 8.40525 8.24887C8.40525 7.97461 8.15746 7.89165 7.99899 7.83578L7.48035 7.65688C7.05852 7.50959 6.75772 7.31828 6.75772 6.86682C6.75772 6.24605 7.40774 6 7.94309 6C8.24812 6.00427 8.55018 6.05943 8.83629 6.16309V6.886H8.44674C8.45481 6.81038 8.47094 6.69526 8.47094 6.63939C8.47094 6.38939 8.22373 6.30079 7.92695 6.30079C7.59848 6.30079 7.33455 6.44357 7.33455 6.69018C7.33455 6.93679 7.48438 7.00395 7.75638 7.09594L8.19895 7.24266C8.54009 7.35553 9.01494 7.50903 9.01494 8.06151C9.01494 8.51919 8.6784 9 7.72814 9C7.39506 9 6.979 8.90068 6.75368 8.8307L6.75195 8.01467Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default TGSLandingZoneIcon;
