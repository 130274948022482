import { useCallback } from "react";

import { GridSortModel } from "@mui/x-data-grid-premium";

import { orderBy } from "lodash";

import { WellData } from "../../types/common/wells";

import config from "../../configs/appSettings";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapSelectionStore from "../../store/map/selection/mapSelectionStore";

import { useGridColumn } from "./useGridColumn";
import { useGridData } from "./useGridData";

export const useGridSortBySelected = () => {
  const pageLimit = config.searchPageLimit;

  const sortPayload = useDataGridStore((state) => state.sortPayload);
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const updateGridWellDataTrigger = useDataGridStore(
    (state) => state.updateGridWellDataTrigger
  );

  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );

  const { searchGridData } = useGridData();
  const { getColumnsPayloadData } = useGridColumn();

  const sortGridBySelected = useCallback(
    (gridData: WellData[], sortModel: GridSortModel) => {
      const selectedData: WellData[] = [];
      const nonSelectedData: WellData[] = [];

      //separate selected by non-selected grid data
      gridData.forEach((data) => {
        if (selectedGridDataKeys.includes(data.WellHeaderPermitKey)) {
          selectedData.push(data);
        } else {
          nonSelectedData.push(data);
        }
      });

      if (sortModel && sortModel.length && sortModel[0].sort) {
        return orderBy(
          selectedData,
          [sortModel[0].field],
          [sortModel[0].sort]
        ).concat(nonSelectedData);
      }

      return selectedData.concat(nonSelectedData);
    },
    [selectedGridDataKeys]
  );

  const getGridDataForSortBySelected = useCallback(() => {
    updateGridWellDataTrigger("sortBySelected");

    const columns = getColumnsPayloadData();

    searchGridData({
      ...searchCriteria,
      offset: 0,
      pageLimit: pageLimit,
      columns: columns,
      sort: sortPayload,
      optimizeColumns: true,
    });
  }, [searchCriteria, sortPayload, getColumnsPayloadData]);

  return { sortGridBySelected, getGridDataForSortBySelected };
};
