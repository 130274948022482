import { FC, useEffect } from "react";

import { Close as CloseIcon } from "@mui/icons-material";
import { Drawer, IconButton, Typography } from "@mui/material";

import usePanelsStore from "../../../../../../store/panels/panelsStore";

import QBEditorAvailAttributes from "./QBEditorAvailAttributes";

const QBEditorPanel: FC = () => {
  const isOpenQBEditorPanel = usePanelsStore(
    (state) => state.isOpenQBEditorPanel
  );
  const toggleIsOpenQBEditorPanel = usePanelsStore(
    (state) => state.toggleIsOpenQBEditorPanel
  );

  const activePanel = usePanelsStore((state) => state.activePanel);

  useEffect(() => {
    if (activePanel !== "search") {
      toggleIsOpenQBEditorPanel(false);
    }
  }, [activePanel]);

  return (
    <Drawer
      open={isOpenQBEditorPanel}
      onClose={() => toggleIsOpenQBEditorPanel()}
      className="custom-drawer qb-editor-panel"
      ModalProps={{ disableEnforceFocus: true }}
    >
      <div className="qb-editor-container">
        <div className="qb-editor-header">
          <div className="qb-editor-header-content">
            <Typography variant="h5">Add/ Remove Attributes</Typography>
            <IconButton
              aria-label="close-button"
              size="small"
              onClick={() => toggleIsOpenQBEditorPanel()}
            >
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </div>
        </div>
        <div className="qb-editor-content">
          <QBEditorAvailAttributes />
        </div>
      </div>
    </Drawer>
  );
};

export default QBEditorPanel;
