import * as wellPanelAttributes from "../wellPanel/wellPanelAttributes";

export const testColumns = [
  {
    field: wellPanelAttributes.TEST_DATE.key,
    headerName: wellPanelAttributes.TEST_DATE.label,
    width: 90,
    sortable: false,
  },
  {
    field: wellPanelAttributes.TEST_TYPE.key,
    headerName: wellPanelAttributes.TEST_TYPE.label,
    width: 90,
    sortable: false,
  },
  {
    field: wellPanelAttributes.OIL.key,
    headerName: wellPanelAttributes.OIL.label,
    width: 10,
    sortable: false,
    type: "number",
  },
  {
    field: wellPanelAttributes.GAS.key,
    headerName: wellPanelAttributes.GAS.label,
    width: 10,
    sortable: false,
    type: "number",
  },
  {
    field: wellPanelAttributes.WATER.key,
    headerName: wellPanelAttributes.WATER.label,
    width: 60,
    sortable: false,
    type: "number",
  },
  {
    field: wellPanelAttributes.TEST_DURATION.key,
    headerName: wellPanelAttributes.TEST_DURATION.label,
    width: 110,
    sortable: false,
    type: "number",
  },
];
