import {
  FullscreenExit as FullscreenExitIcon,
  MoreHoriz as MoreHorizIcon,
  RestartAlt as RestartAltIcon,
} from "@mui/icons-material";
import { Divider, IconButton } from "@mui/material";

import { FullScreenViewChartControlsProps } from "../../../../../types/charts/chartType/chartTypeHeader";

const FullScreenViewChartControls = ({
  onReset,
  onClick,
  onClose,
}: FullScreenViewChartControlsProps) => {
  return (
    <>
      <IconButton size="small" onClick={() => onReset()}>
        <RestartAltIcon fontSize="small" />
      </IconButton>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        className="toolbar-divider"
      />
      <IconButton
        size="small"
        onClick={(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
          onClick(e)
        }
      >
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <IconButton
        size="small"
        onClick={() => {
          onClose();
        }}
      >
        <FullscreenExitIcon fontSize="small" />
      </IconButton>
    </>
  );
};

export default FullScreenViewChartControls;
