import { Fab, styled } from "@mui/material";

interface WellPanelBubbleIconProps {
  filled: boolean;
}

const CustomFab = styled(Fab)(() => ({
  "&:hover": {
    backgroundColor: "unset",
  },
  backgroundColor: "unset",
  boxShadow: "none",
  width: "20px",
  height: "21px",
  minWidth: "20px",
  minHeight: "21px",
  zIndex: "0",
  border: "none",
}));
const WellPanelBubbleIcon = ({ filled }: WellPanelBubbleIconProps) => {
  return (
    <div className="panel-bubble-container">
      <CustomFab>
        <svg
          width="20"
          height="21"
          viewBox="0 0 20 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          {filled ? (
            <circle
              cx="9.86886"
              cy="10.6384"
              r="9.66183"
              fill="rgba(25, 118, 210, 1)"
            />
          ) : (
            <circle
              cx="9.86886"
              cy="10.4743"
              r="9.16183"
              fill="#343434"
              stroke="#0091EA"
            />
          )}
        </svg>
      </CustomFab>
    </div>
  );
};

export default WellPanelBubbleIcon;
