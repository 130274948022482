import { IconButton } from "@mui/material";

import classNames from "classnames";

interface CLCircularButtonProps {
  isActive: boolean;
  width?: number;
  height?: number;
  children?: React.ReactNode;
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onBlur?: () => void;
  disabled?: boolean;
}

const CustomCircularButton: React.FC<CLCircularButtonProps> = ({
  width = "34",
  height = "34",
  children,
  isActive = false,
  onClick,
  onBlur,
  disabled = false,
}) => {
  return (
    <IconButton
      className={classNames({
        "button-active": isActive,
      })}
      sx={{
        width: { width },
        height: { height },
        backgroundColor: "rgba(46, 46, 46, 1)",
        "&.button-active, &.button-active:hover": {
          backgroundColor: "rgba(25, 118, 210, 1)",
        },
        "&:hover": {
          backgroundColor: "rgba(2, 136, 209, .2)",
        },
      }}
      onClick={(e) => {
        onClick(e);
      }}
      onBlur={() => {
        if (onBlur) onBlur();
      }}
      disabled={disabled}
    >
      {children}
    </IconButton>
  );
};

export default CustomCircularButton;
