import { StateCreator } from "zustand";

import { DashboardChartData } from "../../types/charts/chartStore/chartStore";
import {
  DashboardChartAttributes,
  DashboardChartDataConfig,
  IDashboardChartData,
} from "../../types/charts/dashboardChartType/dashboardChartType";

import {
  CHART_DATA_STATE,
  CHART_SCALING_TYPE,
} from "../../constants/charts/charts";
import {
  DASHBOARD_CHART_TYPES,
  DASHBOARD_PANE_CHART_LIST,
  DEFAULT_CHARTS_DEFAULT_VALUE,
  HORIZONTAL_BAR_ATTRIBUTES,
  STACKED_BAR_ATTRIBUTES,
} from "../../constants/charts/dashboardCharts";

import { generateId } from "../../utils/common/generateId";

const generateDefaultCharts = () => {
  const defaultChartsList: IDashboardChartData[] = [];

  DASHBOARD_PANE_CHART_LIST.forEach((chartType) => {
    const chartToAdd = {
      title: DEFAULT_CHARTS_DEFAULT_VALUE[chartType].TITLE,
      chartType: chartType,
      chartId: generateId(5),
      chartYAxisScaling: CHART_SCALING_TYPE.LINEAR,
      chartShowForecast: false,
      chartData: [],
      groupBy: DEFAULT_CHARTS_DEFAULT_VALUE[chartType].GROUP_BY,
      chartDisplayedDataFields:
        DEFAULT_CHARTS_DEFAULT_VALUE[chartType].DATA_FIELDS_TO_DISPLAY,
      chartRange: undefined,
    };

    defaultChartsList.push(chartToAdd);
  });

  return defaultChartsList;
};

const mapDashboardChartSavedSearchData = (
  config: DashboardChartDataConfig[]
) => {
  const defaultChartsList: IDashboardChartData[] = [];

  DASHBOARD_PANE_CHART_LIST.forEach((chartType) => {
    const dashboardChartConfiguration = config.find(
      (data) => chartType === data.chartType
    );
    if (dashboardChartConfiguration) {
      let chartTitle = "";

      switch (chartType) {
        case DASHBOARD_CHART_TYPES.FULL_WIDTH_STACKED_BAR:
          chartTitle =
            STACKED_BAR_ATTRIBUTES.find(
              (attr) =>
                attr.attribute === dashboardChartConfiguration.groupBy[0]
            )?.title ?? "";
          break;
        case DASHBOARD_CHART_TYPES.HORIZONTAL_BAR_CHART:
          chartTitle =
            HORIZONTAL_BAR_ATTRIBUTES.find(
              (attr) =>
                attr.attribute === dashboardChartConfiguration.groupBy[0]
            )?.title ?? "";
          break;
        default:
          chartTitle = DEFAULT_CHARTS_DEFAULT_VALUE[chartType].TITLE;
          break;
      }
      const chartToAdd = {
        title: chartTitle,
        chartType: chartType,
        chartId: dashboardChartConfiguration.chartId,
        chartYAxisScaling: CHART_SCALING_TYPE.LINEAR,
        chartShowForecast: false,
        chartData: [],
        groupBy: dashboardChartConfiguration.groupBy,
        chartDisplayedDataFields:
          DEFAULT_CHARTS_DEFAULT_VALUE[chartType].DATA_FIELDS_TO_DISPLAY,
        chartRange: undefined,
      };

      defaultChartsList.push(chartToAdd);
    }
  });

  return defaultChartsList;
};

const dashboardChartDataSlice: StateCreator<DashboardChartData> = (
  set,
  get
) => ({
  dashboardChartData: generateDefaultCharts(),
  addDashboardChartData: (dashboardChartData) =>
    set((state) => ({
      dashboardChartData: [...state.dashboardChartData, dashboardChartData],
    })),
  updateDashboardChartData: (dashboardChartData) =>
    set((state) => ({ dashboardChartData })),
  updateDashboardChartAttribute: (chartId, chartType, attribute) =>
    set(() => ({
      dashboardChartData: get().dashboardChartData.map((currentChartData) => {
        let attributeList: DashboardChartAttributes[] = [];
        switch (chartType) {
          case DASHBOARD_CHART_TYPES.FULL_WIDTH_STACKED_BAR:
            attributeList = STACKED_BAR_ATTRIBUTES;
            break;
          case DASHBOARD_CHART_TYPES.HORIZONTAL_BAR_CHART:
            attributeList = HORIZONTAL_BAR_ATTRIBUTES;
            break;
        }

        if (currentChartData.chartId === chartId && attributeList.length) {
          currentChartData.title =
            attributeList.find((attr) => attr.attribute === attribute)?.title ??
            "";
          currentChartData.groupBy = [attribute];
        }
        return currentChartData;
      }),
    })),
  loadSavedSearchDashboardData: (
    dashboardChartConfig: DashboardChartDataConfig[]
  ) =>
    set((state) => ({
      dashboardChartData:
        mapDashboardChartSavedSearchData(dashboardChartConfig),
    })),
  revertDashboardDataAttributeDefaultValue: () =>
    set(() => ({
      dashboardChartData: get().dashboardChartData.map((currentChartData) => {
        currentChartData.title =
          DEFAULT_CHARTS_DEFAULT_VALUE[currentChartData.chartType].TITLE;
        currentChartData.groupBy =
          DEFAULT_CHARTS_DEFAULT_VALUE[currentChartData.chartType].GROUP_BY;

        return currentChartData;
      }),
    })),
});

export { dashboardChartDataSlice };
