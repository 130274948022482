import { StateCreator } from "zustand";

import {
  ReleaseNotesActionType,
  ReleaseNotesStoreType,
} from "../../types/information/information";

const releaseNotes: StateCreator<
  ReleaseNotesActionType & ReleaseNotesStoreType
> = (set) => ({
  isOpenReleaseNotes: false,
  isLoadingReleaseNotes: false,
  updateIsLoadingReleaseNotes: (isLoadingReleaseNotes) =>
    set({ isLoadingReleaseNotes }),
  updateIsOpenReleaseNotes: (isOpenReleaseNotes) => set({ isOpenReleaseNotes }),
});

export default releaseNotes;
