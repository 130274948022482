import { Typography } from "@mui/material";

import { AnalysisChartsPanelCountProps } from "../../../types/panels/analysisChartsPanel/analysisChartsPanel";

const AnalysisChartsPanelCount = ({
  title,
  value,
  className,
}: AnalysisChartsPanelCountProps) => {
  return (
    <>
      <div className="count-group">
        <Typography>{`${title}:`}</Typography>
        <Typography className={`count-value ${className}`}>{value}</Typography>
      </div>
    </>
  );
};

export default AnalysisChartsPanelCount;
