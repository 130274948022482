export const BAR_CHART_DATA_FIELDS = [
  {
    id: 1,
    name: "AcidAmount",
    displayName: "AcidAmount",
    color: "",
  },
  {
    id: 2,
    name: "BOEMaxPer1000Ft",
    displayName: "BOEMaxPer1000Ft",
    color: "",
  },
  {
    id: 3,
    name: "CumBOE",
    displayName: "CumBOE",
    color: "",
  },
  {
    id: 4,
    name: "CumGas",
    displayName: "Cum Gas",
    color: "#E62828",
    unit: "mcf",
  },
  {
    id: 5,
    name: "CumGOR",
    displayName: "CumGOR",
    color: "",
  },
  {
    id: 6,
    name: "CumInjectionGas",
    displayName: "CumInjectionGas",
    color: "",
  },
  {
    id: 7,
    name: "CumInjectionGas",
    displayName: "CumInjectionGas",
    color: "",
  },
  {
    id: 8,
    name: "CumInjectionLiquid",
    displayName: "CumInjectionLiquid",
    color: "",
  },
  {
    id: 9,
    name: "CumOil",
    displayName: "Cum Oil",
    color: "#287D3C",
    unit: "bbl",
  },
  {
    id: 10,
    name: "CumVentFlareVol",
    displayName: "CumVentFlareVol",
    color: "",
  },
  {
    id: 11,
    name: "CumYield",
    displayName: "CumWater",
    color: "",
  },
  {
    id: 12,
    name: "CumYield",
    displayName: "CumYield",
    color: "",
  },
  {
    id: 13,
    name: "DisplayElevation",
    displayName: "DisplayElevation",
    color: "",
  },
  {
    id: 14,
    name: "EURPer1000Ft",
    displayName: "EURPer1000Ft",
    color: "",
  },
  {
    id: 15,
    name: "FractureFluidAmount",
    displayName: "FractureFluidAmount",
    color: "",
  },
  {
    id: 16,
    name: "FractureFluidAmountPerFt",
    displayName: "FractureFluidAmountPerFt",
    color: "",
  },
  {
    id: 17,
    name: "LateralLength",
    displayName: "LateralLength",
    color: "",
  },
  {
    id: 18,
    name: "MaxBOE",
    displayName: "MaxBOE",
    color: "",
  },
  {
    id: 19,
    name: "MaxGas",
    displayName: "MaxGas",
    color: "",
  },
  {
    id: 20,
    name: "MaxGasPlus2",
    displayName: "MaxGasPlus2",
    color: "",
  },
  {
    id: 21,
    name: "MaxOil",
    displayName: "MaxOil",
    color: "",
  },
  {
    id: 22,
    name: "MaxOilPlus2",
    displayName: "MaxOilPlus2",
    color: "",
  },
  {
    id: 23,
    name: "MaxWater",
    displayName: "MaxWater",
    color: "",
  },
  {
    id: 24,
    name: "MaxWaterPlus2",
    displayName: "MaxWaterPlus2",
    color: "",
  },
  {
    id: 25,
    name: "MeasuredDepth",
    displayName: "MeasuredDepth",
    color: "",
  },
  {
    id: 26,
    name: "PerfIntervalBottom",
    displayName: "PerfIntervalBottom",
    color: "",
  },
  {
    id: 27,
    name: "PerfIntervalTop",
    displayName: "PerfIntervalTop",
    color: "",
  },
  {
    id: 28,
    name: "ProppantAmount",
    displayName: "ProppantAmount",
    color: "",
  },
  {
    id: 29,
    name: "ProppantAmountPerFt",
    displayName: "ProppantAmountPerFt",
    color: "",
  },
  {
    id: 30,
    name: "TotalVerticalDepth",
    displayName: "TotalVerticalDepth",
    color: "",
  },
  {
    id: 31,
    name: "WellForecastBOERemaining",
    displayName: "WellForecastBOERemaining",
    color: "",
  },
  {
    id: 32,
    name: "WellForecastBOEUltimate",
    displayName: "WellForecastBOEUltimate",
    color: "",
  },
  {
    id: 33,
    name: "WellForecastGasRemaining",
    displayName: "WellForecastGasRemaining",
    color: "",
  },
  {
    id: 34,
    name: "WellForecastGasUltimate",
    displayName: "WellForecastGasUltimate",
    color: "",
  },
  {
    id: 35,
    name: "WellForecastGORUltimate",
    displayName: "WellForecastGORUltimate",
    color: "",
  },
  {
    id: 36,
    name: "WellForecastOilRemaining",
    displayName: "WellForecastOilRemaining",
    color: "",
  },
  {
    id: 37,
    name: "WellForecastOilUltimate",
    displayName: "WellForecastOilUltimate",
    color: "",
  },
  {
    id: 38,
    name: "WellForecastWaterCutUltimate",
    displayName: "WellForecastWaterCutUltimate",
    color: "",
  },
  {
    id: 39,
    name: "WellForecastWaterRemaining",
    displayName: "WellForecastWaterRemaining",
    color: "",
  },
  {
    id: 40,
    name: "WellForecastWaterUltimate",
    displayName: "WellForecastWaterUltimate",
    color: "",
  },
  {
    id: 41,
    name: "WellForecastYieldUltimate",
    displayName: "WellForecastYieldUltimate",
    color: "",
  },
];
