import { useEffect, useMemo, useState } from "react";

import {
  Autocomplete,
  TextField,
  autocompleteClasses,
  styled,
} from "@mui/material";

import { Attribute, AttributeKey } from "../../../types/common/attributes";

import { BUBBLE_MAP } from "../../../constants/constants";
import {
  SIZE,
  SIZE_BY_ATTRIBUTE_INFO,
  SIZE_BY_ATTRIBUTE_LIST,
} from "../../../constants/map/mapSettings";

import useMapSettingsStore from "../../../store/map/settings/mapSettingsStore";

import { clone } from "../../../utils";

interface AttributeSelectProps {
  onAttributeSelect: (key: AttributeKey) => void;
  disabled?: boolean;
}

// REFERENCE FOR CATEGORIZED TREE
// const StyledTreeItem = styled(TreeItem)(() => ({
//   [`& .${treeItemClasses.content}`]: {
//     minHeight: 36,
//     [`& .${treeItemClasses.label}`]: {
//       fontSize: 14,
//     },
//   },
// }));

const StyledAutocomplete = styled(Autocomplete)(() => ({
  [`& .MuiInputLabel-formControl`]: {
    top: "0 !important",
  },

  [`& .${autocompleteClasses.endAdornment}`]: {
    top: "unset",
  },
}));

// REFERENCE FOR CATEGORIZED TREE
// const RenderTree = ({ node, onAttributeSelect }) => {
//   const isGroup = Array.isArray(node.attributes);
//   const attributeProps = {};
//   if (!isGroup) {
//     attributeProps.onClick = () => onAttributeSelect(node.key);
//   }
//   return (
//     <StyledTreeItem
//       key={node.key}
//       nodeId={node.key}
//       label={node.label}
//       {...attributeProps}
//     >
//       {isGroup
//         ? node.attributes.map((node) => (
//             <RenderTree
//               key={node.key}
//               node={node}
//               onAttributeSelect={onAttributeSelect}
//             />
//           ))
//         : null}
//     </StyledTreeItem>
//   );
// };

const AttributeSelect: React.FC<AttributeSelectProps> = ({
  onAttributeSelect,
  disabled = false,
}) => {
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const [value, setValue] = useState<Attribute | null>(null);
  // REFERENCE FOR CATEGORIZED TREE
  // const [expandedItems, setExpandedItems] = useState([]);

  const attributeOptions = clone(SIZE_BY_ATTRIBUTE_LIST) as Attribute[];
  attributeOptions.sort((a, b) => {
    //sort labels ascending
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });

  const bubbleMapAttributeKey: AttributeKey = useMemo(
    () => layerStyles[BUBBLE_MAP][SIZE] as string,
    [layerStyles]
  );

  // Handles loading of saved searched map configurations
  useEffect(() => {
    // Auto set value of autocomplete
    const attribute: Attribute = SIZE_BY_ATTRIBUTE_INFO[bubbleMapAttributeKey];
    setValue(attribute ?? null);

    // REFERENCE FOR CATEGORIZED TREE
    // Auto expands tree items on size by attribute update
    // setExpandedItems(attribute ? [attribute.group] : []);
  }, [bubbleMapAttributeKey]);

  // On select of autocomplete option
  const handleAutocompleteSelect = (newValue: Attribute) => {
    setValue(newValue);
    // REFERENCE FOR CATEGORIZED TREE
    // setExpandedItems([newValue.group]);

    if (newValue === null) return;
    onAttributeSelect(newValue.key);
  };

  // REFERENCE FOR CATEGORIZED TREE
  // On expand/collapse of tree item
  // const handleToggle = (_, nodeIds) => {
  //   setExpandedItems(nodeIds);
  // };

  return (
    <>
      <StyledAutocomplete
        value={value}
        onChange={(_e, value): void =>
          handleAutocompleteSelect(value as Attribute)
        }
        isOptionEqualToValue={(option, value): boolean =>
          (option as Attribute).key === (value as Attribute).key
        }
        options={attributeOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            label="Attribute"
            placeholder="-Select an option-"
          />
        )}
        disabled={disabled}
        disableClearable
      />
      {/* REFERENCE FOR CATEGORIZED TREE */}
      {/* <div className="tree-container">
        <TreeView
          selected={attributeKey}
          expanded={expandedItems}
          onNodeToggle={handleToggle}
          defaultCollapseIcon={<RemoveIcon fontSize="small" />}
          defaultExpandIcon={<AddIcon fontSize="small" />}
        >
          {CATEGORIZED_ATTRIBUTE_TREE.map((group) => (
            <RenderTree
              key={group.key}
              node={group}
              onAttributeSelect={onAttributeSelect}
            />
          ))}
        </TreeView>
      </div> */}
    </>
  );
};

export default AttributeSelect;
