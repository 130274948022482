import { Attribute } from "../../types/common/attributes";
import {
  WellStylingData,
  WellStylingObject,
} from "../../types/map/mapSettings/useWellSpotStyling";

import {
  DATE,
  FLOAT,
  INTEGER,
  SPECIAL,
  TEXT,
} from "../../constants/attributes";
import {
  COLOR_BY_ATTRIBUTE_INFO,
  UNIFORM_COLOR,
} from "../../constants/map/mapSettings";

import { convertToMilliseconds } from "../helper";

export const shouldGetTopAttributes = (attribute: string) => {
  const attributeInfo = COLOR_BY_ATTRIBUTE_INFO[attribute];

  if (attributeInfo.dataType === TEXT || attributeInfo.dataType === SPECIAL) {
    return true;
  }

  return false;
};

export const transformWellStylingDataToObject = (
  list: WellStylingObject[],
  keys: (keyof WellStylingObject)[],
  dataKeyValue: keyof WellStylingObject
) => {
  const object: { [key: string]: WellStylingObject[keyof WellStylingObject] } =
    {};

  list.forEach((data) => {
    const valueKey = keys.map((key) => data[key] ?? " ").join("-");
    object[valueKey] = data[dataKeyValue];
  });

  return object;
};

export const getCompressedDataInfo = (
  compressedData: WellStylingData,
  byKey: string,
  attributeInfo: { [x: string]: Attribute | typeof UNIFORM_COLOR }
) => {
  let data = compressedData;

  const attribute = attributeInfo[byKey];
  const { dataType } = attribute;
  // convert the dates to milliseconds and numeric to numbers
  if (dataType === DATE || dataType === FLOAT || dataType === INTEGER) {
    data = compressedData.map((cData) => {
      const attributeValue = cData[byKey];
      if (attributeValue || attributeValue === 0) {
        return {
          ...cData,
          [byKey]:
            dataType === DATE
              ? convertToMilliseconds(attributeValue)
              : parseFloat(attributeValue as string),
        };
      }
      return cData;
    });
  }

  const { min, max, hasNull } = getPartitionMinMax(data, attribute);

  return { data, min, max, hasNull };
};

// Get Min and Max of attribute value based on partition object
// Only applicable to numeric and date attributes
const getPartitionMinMax = (
  compressedData: WellStylingData | undefined,
  attribute: Attribute | typeof UNIFORM_COLOR
) => {
  let min = null;
  let max = null;
  let hasNull = false;

  const { key, dataType } = attribute;
  if (dataType === FLOAT || dataType === INTEGER || dataType === DATE) {
    const {
      min: minVal,
      max: maxVal,
      hasNull: hasNullVal,
    } = getMinMaxValueByAttribute(compressedData, key);

    min = minVal;
    max = maxVal;
    hasNull = hasNullVal;
  }
  return { min, max, hasNull };
};

// Filter null values and get the list of values
const getValidValues = (
  data: WellStylingData | undefined,
  attributeKey: string
) => {
  return data
    ?.filter((data) => data[attributeKey] ?? data[attributeKey] === 0)
    .map((data) => data[attributeKey]);
};

// Check if there is a null value on the data
const getHasNullAttributeValue = (
  data: WellStylingData | undefined,
  attributeKey: string
) => {
  if (!data) return true;

  let hasNull = false;
  for (let i = 0; i < data.length; i++) {
    if (!data[i][attributeKey] && data[i][attributeKey] !== 0) {
      hasNull = true;
      break;
    }
  }
  return hasNull;
};

// Need to create utility for max and min
// Math.max/min can't handle large data set
export const getMaxValue = (data: (string | number | undefined)[] = []) => {
  let max = -Infinity;
  data.forEach((value) => {
    if (typeof value === "number") max = max < value ? value : max;
  });
  return max;
};

export const getMinValue = (data: (string | number | undefined)[] = []) => {
  let min = Infinity;
  data.forEach((value) => {
    if (typeof value === "number") min = min > value ? value : min;
  });
  return min;
};

const getMinMaxValueByAttribute = (
  data: WellStylingData | undefined,
  attributeKey: string
) => {
  const values = getValidValues(data, attributeKey);
  const max = getMaxValue(values);
  const min = getMinValue(values);
  const hasNull = getHasNullAttributeValue(data, attributeKey);
  return {
    max: isFinite(max) ? max : null,
    min: isFinite(min) ? min : null,
    hasNull,
  };
};
