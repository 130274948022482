export const WellPanelOpenedActiveIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 14.0208C8.15278 12.5347 9.39931 11.1667 10.2396 9.91667C11.0799 8.66667 11.5 7.54861 11.5 6.5625C11.5 5.10417 11.0243 3.89583 10.0729 2.9375C9.12153 1.97917 7.93056 1.5 6.5 1.5C5.06944 1.5 3.87847 1.97917 2.92708 2.9375C1.97569 3.89583 1.5 5.10417 1.5 6.5625C1.5 7.54861 1.92014 8.66667 2.76042 9.91667C3.60069 11.1667 4.84722 12.5347 6.5 14.0208ZM6.5 16C4.31986 14.179 2.69146 12.4877 1.61479 10.926C0.538264 9.36424 0 7.90972 0 6.5625C0 4.70139 0.618056 3.14236 1.85417 1.88542C3.09028 0.628472 4.63542 0 6.48958 0C8.34375 0 9.89236 0.628472 11.1354 1.88542C12.3785 3.14236 13 4.70139 13 6.5625C13 7.90972 12.4653 9.36111 11.3958 10.9167C10.3264 12.4722 8.69444 14.1667 6.5 16Z"
        fill="white"
      />
      <circle cx="6.5" cy="6" r="3" fill="white" />
      <circle cx="6.5" cy="6" r="2.5" stroke="white" strokeOpacity="0.7" />
    </svg>
  );
};

export const WellPanelOpenedInactiveIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.5 14.0208C8.15278 12.5347 9.39931 11.1667 10.2396 9.91667C11.0799 8.66667 11.5 7.54861 11.5 6.5625C11.5 5.10417 11.0243 3.89583 10.0729 2.9375C9.12153 1.97917 7.93056 1.5 6.5 1.5C5.06944 1.5 3.87847 1.97917 2.92708 2.9375C1.97569 3.89583 1.5 5.10417 1.5 6.5625C1.5 7.54861 1.92014 8.66667 2.76042 9.91667C3.60069 11.1667 4.84722 12.5347 6.5 14.0208ZM6.5 16C4.31986 14.179 2.69146 12.4877 1.61479 10.926C0.538264 9.36424 0 7.90972 0 6.5625C0 4.70139 0.618056 3.14236 1.85417 1.88542C3.09028 0.628472 4.63542 0 6.48958 0C8.34375 0 9.89236 0.628472 11.1354 1.88542C12.3785 3.14236 13 4.70139 13 6.5625C13 7.90972 12.4653 9.36111 11.3958 10.9167C10.3264 12.4722 8.69444 14.1667 6.5 16Z"
        fill="#42A5F5"
      />
      <circle cx="6.5" cy="6" r="2.375" stroke="#42A5F5" strokeWidth="1.25" />
    </svg>
  );
};

export const WellPanelNotOpenedIcon = () => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 14.0208C8.65278 12.5347 9.89931 11.1667 10.7396 9.91667C11.5799 8.66667 12 7.54861 12 6.5625C12 5.10417 11.5243 3.89583 10.5729 2.9375C9.62153 1.97917 8.43056 1.5 7 1.5C5.56944 1.5 4.37847 1.97917 3.42708 2.9375C2.47569 3.89583 2 5.10417 2 6.5625C2 7.54861 2.42014 8.66667 3.26042 9.91667C4.10069 11.1667 5.34722 12.5347 7 14.0208ZM7 16C4.81986 14.179 3.19146 12.4877 2.11479 10.926C1.03826 9.36424 0.5 7.90972 0.5 6.5625C0.5 4.70139 1.11806 3.14236 2.35417 1.88542C3.59028 0.628472 5.13542 0 6.98958 0C8.84375 0 10.3924 0.628472 11.6354 1.88542C12.8785 3.14236 13.5 4.70139 13.5 6.5625C13.5 7.90972 12.9653 9.36111 11.8958 10.9167C10.8264 12.4722 9.19444 14.1667 7 16Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
};
