import { create } from "zustand";

import {
  ReleaseNotesActionType,
  ReleaseNotesStoreType,
} from "../../types/information/information";

import releaseNotesSlice from "./releaseNotesSlice";

const useReleaseNotesStore = create<
  ReleaseNotesActionType & ReleaseNotesStoreType
>()((...a) => ({
  ...releaseNotesSlice(...a),
}));

export default useReleaseNotesStore;
