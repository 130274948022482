import { isEqual } from "lodash";

import { WellData, WellDataObjectKeys } from "../../types/common/wells";

import {
  GRID_COLUMNS,
  REQUIRED_COLUMNS,
  REQUIRED_WELL_SPOT_COLUMNS,
} from "../../constants/grid";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapHoverStore from "../../store/map/hover/mapHoverStore";

import {
  changeAttributeKey,
  columnVisibilityList,
  defaultColumnsModel,
} from "../../utils/datagrid";
import { clone } from "../../utils/helper";

export const useGridColumn = () => {
  const columnsPayload = useDataGridStore((state) => state.columnsPayload);
  const columnVisibilityModel = useDataGridStore(
    (state) => state.columnVisibilityModel
  );
  const mapHoverEnabled = useMapHoverStore((state) => state.mapHoverEnabled);
  const dataGridSelector = useDataGridStore((state) => state.allWellSelectors);
  const reqCols: string[] = mapHoverEnabled
    ? clone(REQUIRED_COLUMNS)
    : clone(REQUIRED_WELL_SPOT_COLUMNS);

  const getRequiredColumns = (columns: string[], optimizeColumns = false) => {
    if (columns.length) {
      const columnsFiltered = getColumnsFiltered(columns);

      if (optimizeColumns) {
        return ["WellHeaderPermitKey", ...columnsFiltered];
      }

      return [...reqCols, ...columnsFiltered];
    }

    if (optimizeColumns) {
      const columnsFiltered = getColumnsFiltered(defaultColumnsModel);
      return ["WellHeaderPermitKey", ...columnsFiltered];
    }

    return reqCols;
  };

  const getColumnsFiltered = (columns: string[] = []) => {
    return columns.filter((col) => !reqCols.includes(col));
  };

  const getColumnsPayloadData = () => {
    return isEqual(columnVisibilityModel, columnVisibilityList)
      ? []
      : columnsPayload;
  };

  //find missing column data from allWellDataObj and merge to existing grid row data
  const setGridRowData = (rowData: WellData[] = []) => {
    const allWellGridDatabyWellHeaderPermitKey =
      dataGridSelector.byWellHeaderPermitKey;

    const newRowData = rowData.map((row) => {
      const mapObj =
        allWellGridDatabyWellHeaderPermitKey[row.WellHeaderPermitKey];

      return {
        ...getColumnsFromAllWellData(mapObj, reqCols),
        ...row,
      };
    });

    return newRowData;
  };

  const getColumnsFromAllWellData = (
    wellData: WellData,
    gridColumns: string[] = []
  ) => {
    const requiredMiniCardColumns = REQUIRED_COLUMNS as WellDataObjectKeys[];
    const rowDataFromAllWellDataObj:
      | WellData
      | Record<string, string | number | number[] | undefined> = {};

    if (gridColumns.length) {
      requiredMiniCardColumns.forEach((col) => {
        const wellDataCol = changeAttributeKey(col) as keyof WellData;
        if (gridColumns.includes(col) && wellData[wellDataCol]) {
          rowDataFromAllWellDataObj[wellDataCol] = wellData[wellDataCol];
        }
      });
    } else {
      requiredMiniCardColumns.forEach((col) => {
        const wellDataCol = changeAttributeKey(col) as keyof WellData;
        if (wellData[wellDataCol])
          rowDataFromAllWellDataObj[wellDataCol] = wellData[wellDataCol];
      });
    }

    return rowDataFromAllWellDataObj;
  };

  return {
    getRequiredColumns,
    getColumnsPayloadData,
    setGridRowData,
  };
};
