import { FC, SVGProps } from "react";

interface CopyIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const CopyIcon: FC<CopyIconProps> = ({
  width = "14",
  height = "16",
  fill = "none",
  pathFill = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 14 16"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 13C4.0875 13 3.73438 12.8531 3.44062 12.5594C3.14687 12.2656 3 11.9125 3 11.5V1.5C3 1.0875 3.14687 0.734376 3.44062 0.440626C3.73438 0.146876 4.0875 0 4.5 0H12.5C12.9125 0 13.2656 0.146876 13.5594 0.440626C13.8531 0.734376 14 1.0875 14 1.5V11.5C14 11.9125 13.8531 12.2656 13.5594 12.5594C13.2656 12.8531 12.9125 13 12.5 13H4.5ZM4.5 11.5H12.5V1.5H4.5V11.5ZM1.5 16C1.0875 16 0.734375 15.8531 0.440625 15.5594C0.146875 15.2656 0 14.9125 0 14.5V3H1.5V14.5H11V16H1.5Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default CopyIcon;
