import { memo, useCallback, useEffect, useMemo, useState } from "react";

import { Divider, Typography } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

import classNames from "classnames";

import { IChartTypeInfo } from "../../../../types/charts/chartType/chartType";
import { ChartTypeHeaderProps } from "../../../../types/charts/chartType/chartTypeHeader";

import { CHART_MODE_VIEW } from "../../../../constants/charts/chartModeView";
import {
  CHART_GROUP_BY_TYPE,
  CHART_TYPES,
  INDIVIDUAL_WELLS_BATCH_LABEL,
  MAX_INDIVIDUAL_WELLS_COUNT,
} from "../../../../constants/charts/charts";

import useChartStore from "../../../../store/chart/chartStore";
import useDataGridStore from "../../../../store/grid/dataGridStore";
import useMapSelectionStore from "../../../../store/map/selection/mapSelectionStore";
import useModularityStore from "../../../../store/modularity/modularityStore";

import { useGridSelectedData } from "../../../../customHooks/grid/useGridSelectedData";

import { darkTheme } from "../../../../themes";
import ChartTypeHeaderControls from "./ChartTypeHeaderControls";

const ChartTypeHeader = ({
  chartId,
  isChartExport,
  chartMode,
  onChartFullScreenClose,
  chartRendered,
  chartRef,
  onChartExportData,
}: ChartTypeHeaderProps) => {
  const gridTotalCount = useDataGridStore((state) => state.gridTotalCount);
  const selectedWellIdsKeys = useMapSelectionStore(
    (state) => state.selectedWellIdsKeys
  );
  const chartData = useChartStore((state) => state.chartData);
  const isChartsDraggable = useModularityStore(
    (state) => state.isChartsDraggable
  );
  const updateIsChartsDraggable = useModularityStore(
    (state) => state.updateIsChartsDraggable
  );
  const isFullScreenIndividualChart = useModularityStore(
    (state) => state.isFullScreenIndividualChart
  );
  const fullScreenChartId = useModularityStore(
    (state) => state.fullScreenChartId
  );
  const lockDrag = useModularityStore((state) => state.lockDrag);
  const updateLockDrag = useModularityStore((state) => state.updateLockDrag);

  const { isSelectedAllGrid } = useGridSelectedData();

  const [currentChartData, setCurrentChartData] = useState<
    IChartTypeInfo | undefined
  >(undefined);

  const handleHeaderTextEnter = useCallback(() => {
    updateIsChartsDraggable(chartId);
  }, [chartId, updateIsChartsDraggable]);

  const handleHeaderTextLeave = useCallback(() => {
    if (isChartsDraggable === chartId && !lockDrag) updateIsChartsDraggable("");
  }, [chartId, updateIsChartsDraggable, isChartsDraggable, lockDrag]);

  const handleHeaderTextMouseDown = useCallback(() => {
    if (isChartsDraggable === chartId) updateLockDrag(true);
  }, [chartId, isChartsDraggable, updateLockDrag]);

  const handleHeaderTextMouseUp = useCallback(() => {
    if (isChartsDraggable === chartId && lockDrag) {
      updateLockDrag(true);
      updateIsChartsDraggable("");
    }
  }, [
    chartId,
    updateIsChartsDraggable,
    isChartsDraggable,
    updateLockDrag,
    lockDrag,
  ]);

  useEffect(() => {
    if (!chartData.length && !chartId) return;

    const filteredChartData: IChartTypeInfo | undefined = chartData.find(
      (chart: IChartTypeInfo) => chart.chartId === chartId
    );

    if (filteredChartData) {
      setCurrentChartData(filteredChartData);
    }
  }, [chartId, chartData]);

  const chartHeader: JSX.Element = useMemo(() => {
    if (!currentChartData) return <></>;
    let chartTitleDisplay: string =
      currentChartData.title === ""
        ? currentChartData.chartType + "_" + currentChartData.chartId
        : currentChartData.title;
    let label = "";
    const max = 21;
    const maxWithLabel = 13;

    if (
      currentChartData?.objectType === CHART_TYPES.PRODUCTION_PLOT ||
      currentChartData?.objectType === CHART_TYPES.TYPE_CURVE
    ) {
      if (currentChartData.groupBy === CHART_GROUP_BY_TYPE.INDIVIDUAL_WELLS) {
        if (
          (isSelectedAllGrid && gridTotalCount > MAX_INDIVIDUAL_WELLS_COUNT) ||
          selectedWellIdsKeys.length > MAX_INDIVIDUAL_WELLS_COUNT
        ) {
          label = INDIVIDUAL_WELLS_BATCH_LABEL;
        }
      }
    }

    if (chartMode === CHART_MODE_VIEW.DEFAULT) {
      if (label) {
        chartTitleDisplay =
          chartTitleDisplay.length > maxWithLabel
            ? chartTitleDisplay.substring(0, maxWithLabel) + "…"
            : chartTitleDisplay;
      } else {
        chartTitleDisplay =
          chartTitleDisplay.length > max
            ? chartTitleDisplay.substring(0, max) + "…"
            : chartTitleDisplay;
      }
    }

    return (
      <>
        {chartTitleDisplay}
        {label}
      </>
    );
  }, [currentChartData, isSelectedAllGrid, selectedWellIdsKeys]);

  const isFullScreenView: boolean = useMemo(() => {
    return (
      fullScreenChartId === chartId &&
      isFullScreenIndividualChart &&
      chartMode === CHART_MODE_VIEW.FULLSCREEN_INDIVIDUAL_CHART &&
      !isChartExport
    ); // Allow export chart to have uniform size
  }, [
    fullScreenChartId,
    isFullScreenIndividualChart,
    chartMode,
    isChartExport,
  ]);

  return (
    <ThemeProvider theme={darkTheme}>
      <div
        className={classNames("plot-chart-header", {
          "full-screen-chart": isFullScreenView,
        })}
      >
        <div
          className="chart-header-text"
          role="presentation"
          onMouseEnter={handleHeaderTextEnter}
          onMouseLeave={handleHeaderTextLeave}
          onMouseDown={handleHeaderTextMouseDown}
          onMouseUp={handleHeaderTextMouseUp}
        >
          <Typography>{chartHeader}</Typography>
          <Divider
            orientation="vertical"
            variant="middle"
            flexItem
            className="title-divider"
          />
          <Typography>{currentChartData?.chartType.toUpperCase()}</Typography>
        </div>
        <ChartTypeHeaderControls
          chartMode={chartMode}
          chartId={chartId}
          chartType={currentChartData?.chartType}
          chartTitle={currentChartData?.title}
          onChartFullScreenClose={onChartFullScreenClose}
          chartRendered={chartRendered}
          chartRef={isChartExport ? chartRef : null}
          onChartExportData={onChartExportData}
        />
      </div>
    </ThemeProvider>
  );
};

export default memo(ChartTypeHeader);
