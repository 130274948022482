import { FC } from "react";

interface WellInfoTextProps {
  label: string;
  value?: string | boolean | number | Date;
  icon?: React.ReactNode;
}

const WellInfoText: FC<WellInfoTextProps> = ({ label, value, icon }) => {
  return (
    <div className="well-label-grouped-info">
      {icon && <div className="well-info-icon">{icon}</div>}
      <div>
        <div className="well-info-label">{label}</div>
        <div className="well-info-value tgs-operator">
          {value?.toString() ?? ""}
        </div>
      </div>
    </div>
  );
};

export default WellInfoText;
