import { create } from "zustand";

import { ModularityState } from "../../types/modularity/store";

import { chartWindowSlice } from "./chartWindowSlice";
import { modularSlice, rglWindowSlice } from "./modularitySlice";

const useModularityStore = create<ModularityState>()((...a) => ({
  ...rglWindowSlice(...a),
  ...modularSlice(...a),
  ...chartWindowSlice(...a),
}));

export default useModularityStore;
