import { Route, Routes } from "react-router-dom";

import { LoginCallback } from "@okta/okta-react";

import AccessDenied from "../pages/AccessDenied";
import DocsViewer from "../pages/DocsViewer";
import Home from "../pages/Home";
import { RequiredAuth } from "./SecureRoute";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={"/implicit-callback"} element={<LoginCallback />} />
      <Route path="/" element={<RequiredAuth />}>
        <Route path="/" element={<Home />} />
        <Route path="/:shareSearchId" element={<Home />} />

        <Route path="/docs-viewer/:uwi10" element={<DocsViewer />} />
      </Route>
      <Route path="/access-denied" element={<AccessDenied />} />
    </Routes>
  );
};

export default AppRoutes;
