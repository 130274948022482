import { FC, PropsWithChildren, useState } from "react";

import { Drawer, styled } from "@mui/material";

import classNames from "classnames";

import {
  PanelDrawerProps,
  StyledPanelDrawerProps,
} from "../../../types/panels/common/panelDrawer";

import usePanelsLayout from "../../../customHooks/panels/usePanelsLayout";

import PanelControl from "./PanelControl";

const StyledPanelDrawer = styled(Drawer)(
  ({ height }: StyledPanelDrawerProps) => ({ height })
);

const PanelDrawer: FC<PropsWithChildren<PanelDrawerProps>> = ({
  open,
  handleCloseMenuClick,
  children,
  className = "",
  hidePanelControl = false,
}) => {
  const { getPanelHeight } = usePanelsLayout();
  const [panelRef, setPanelRef] = useState<HTMLDivElement | null>(null);

  return (
    <StyledPanelDrawer
      ref={setPanelRef}
      open={open}
      className={classNames("floating-scrollbar-left custom-drawer", className)}
      disableEnforceFocus
      hideBackdrop
      height={getPanelHeight}
      ModalProps={{
        keepMounted: true,
      }}
    >
      {children}
      {!hidePanelControl && (
        <PanelControl
          anchorEl={panelRef}
          open={open}
          clickCallback={() => {
            handleCloseMenuClick();
          }}
        />
      )}
    </StyledPanelDrawer>
  );
};

export default PanelDrawer;
