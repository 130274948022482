import { FC } from "react";

import { ClickAwayListener } from "@mui/base";
import { Paper, Popper, styled } from "@mui/material";

import { ExpressBuilderPanelProps } from "../../../../../../types/grid";

import MenuHeader from "../../MenuHeader";

const GridMenuExpressionBuilderContainer = styled(Popper, {
  name: "MuiDataGrid",
  slot: "Menu",
  overridesResolver: (props, styles) => styles.menu,
})(({ theme }) => ({
  zIndex: theme.zIndex.modal,
  margin: "0 20px !important",
  backgroundColor: theme.palette.background.paper,
  "& .expression-builder-paper-container": {
    minWidth: "280px",
    overflow: "hidden",
  },
}));

const FilterExpressionBuilderContainer: FC<ExpressBuilderPanelProps> = (
  props
) => {
  const { menuAnchor, onClickAway, children } = props;
  const openPanel = Boolean(menuAnchor);

  return (
    <GridMenuExpressionBuilderContainer
      open={openPanel}
      anchorEl={menuAnchor}
      placement="right-start"
    >
      <ClickAwayListener onClickAway={onClickAway} mouseEvent="onMouseDown">
        <Paper className="expression-builder-paper-container">
          <MenuHeader />
          {children}
        </Paper>
      </ClickAwayListener>
    </GridMenuExpressionBuilderContainer>
  );
};

export default FilterExpressionBuilderContainer;
