import axios, { AxiosError } from "axios";

import { APICallService, APIErrorResponse } from "../types/common/api";

import {
  ATTRIBUTE_SEARCH,
  CHART_TYPE,
  FILE_ID,
  GEOMETRY_SERACH,
  SEARCH_BOUNDS,
  SEARCH_REQUEST,
  SEARCH_TYPE,
  SHAPE_FILE_ID,
  UWI_STATUS_ERROR_FOR_RETRY,
} from "../constants/validation";

export const callServiceAPI: APICallService = async (
  url,
  body,
  getUWIFileIdWithRetry,
  uploadedUWIFile,
  isFromUploadedUWIFile,
  isBasedOnUWIFileUpload = true
) => {
  const isFileIdNotExistingInBody = !(FILE_ID in body);
  const isFileIdNotExistingInSearchRequest =
    SEARCH_REQUEST in body &&
    !(body[SEARCH_REQUEST] && FILE_ID in body[SEARCH_REQUEST]);
  const isSearchTypeExisting = SEARCH_TYPE in body;
  const isChartTypeExisting = CHART_TYPE in body;
  const isBoundsExisting = SEARCH_BOUNDS in body;
  const isAttributesExisting = ATTRIBUTE_SEARCH in body;
  const isGeometryExisting = GEOMETRY_SERACH in body;
  const isShapeFileExisting = SHAPE_FILE_ID in body;

  if (
    isFromUploadedUWIFile &&
    isBasedOnUWIFileUpload &&
    !isSearchTypeExisting &&
    !isChartTypeExisting &&
    !isBoundsExisting &&
    !isAttributesExisting &&
    !isGeometryExisting &&
    !isShapeFileExisting &&
    isFileIdNotExistingInBody &&
    isFileIdNotExistingInSearchRequest
  )
    return;

  try {
    const response = await axios.post(url, body);
    return response;
  } catch (err) {
    const error = err as AxiosError<APIErrorResponse>;
    if (
      isFromUploadedUWIFile &&
      error.response?.data?.error.includes(UWI_STATUS_ERROR_FOR_RETRY)
    ) {
      await getUWIFileIdWithRetry(uploadedUWIFile?.name as string);
      // Retry the API call after getting the UWI file ID
      callServiceAPI(
        url,
        body,
        getUWIFileIdWithRetry,
        uploadedUWIFile,
        isFromUploadedUWIFile
      );
    } else {
      return error;
    }
  }
};
