import { Annotations, PlotData } from "plotly.js";

const WELLNAME_MAX_LENGTH = 10;

const horizontalTargetCrossHairConfig: Partial<PlotData> = {
  type: "scatter",
  mode: "lines",
  hoverinfo: "none",
  showlegend: false,
  line: {
    dash: "dot",
    width: 1,
    color: "rgba(255,255,255, 0.5)",
  },
};

const verticalTargetCrossHairConfig: Partial<PlotData> = {
  type: "scatter",
  mode: "lines",
  hoverinfo: "none",
  showlegend: false,
  line: {
    dash: "dot",
    width: 1,
    color: "rgba(255,255,255, 0.5)",
  },
};

const horizontalFocusedPointCrossHairConfig: Partial<PlotData> = {
  type: "scatter",
  mode: "lines",
  showlegend: false,
  hoverinfo: "none",
  line: {
    dash: "dot",
    width: 2,
    color: "white",
  },
};

const verticalFocusedPointCrossHairConfig: Partial<PlotData> = {
  type: "scatter",
  mode: "lines",
  hoverinfo: "none",
  showlegend: false,
  line: {
    dash: "dot",
    width: 2,
    color: "white",
  },
};

const overlayConfig: Partial<PlotData> = {
  fill: "toself",
  fillcolor: "#000",
  type: "scatter",
  mode: "none",
  hoverinfo: "skip",
  opacity: 0.5,
  showlegend: false,
};

const wellHoverAnnotationConfig: Partial<Annotations> = {
  opacity: 0.7,
  font: {
    size: 10,
    color: "#000",
  },
  borderwidth: 1,
  borderpad: 2,
  bgcolor: "#fff",
  showarrow: false,
};

const wellHorizontalDistanceHoverAnConfig: Partial<Annotations> = {
  font: {
    size: 9,
    color: "#fff",
  },
  showarrow: false,
};

const wellVerticalDistanceHoverAnConfig: Partial<Annotations> = {
  font: {
    size: 9,
    color: "#fff",
  },
  showarrow: false,
};

export {
  horizontalTargetCrossHairConfig,
  verticalTargetCrossHairConfig,
  horizontalFocusedPointCrossHairConfig,
  verticalFocusedPointCrossHairConfig,
  wellHoverAnnotationConfig,
  wellHorizontalDistanceHoverAnConfig,
  wellVerticalDistanceHoverAnConfig,
  overlayConfig,
  WELLNAME_MAX_LENGTH,
};
