import { CircularButton } from "component-library";

interface WellPanelContractIconProps {
  state: "disabled" | "enabled" | "pressed" | undefined;
  onClickFn?: () => void;
}

const WellPanelContractIcon = ({
  state,
  onClickFn,
}: WellPanelContractIconProps) => {
  return (
    <CircularButton
      icon="addLocation"
      type="default"
      state={state}
      onClick={onClickFn}
    />
  );
};

export default WellPanelContractIcon;
