import { create } from "zustand";

import { MapDrawStates } from "../../../types/map/draw/store";

import { mapDrawPolygonSlice } from "./polygon/mapDrawPolygonSlice";
import { mapPolygonSlice } from "./polygon/mapPolygonSlice";
import { mapShapeFileSlice } from "./polygon/mapShapeFileSlice";

const useMapDrawStore = create<MapDrawStates>()((...a) => ({
  ...mapPolygonSlice(...a),
  ...mapDrawPolygonSlice(...a),
  ...mapShapeFileSlice(...a),
}));

export default useMapDrawStore;
