import { FC } from "react";

import { FileDownloadOutlined as FileDownloadOutlinedIcon } from "@mui/icons-material";
import { Button } from "@mui/material";

import classNames from "classnames";
import { CircularButton } from "component-library";

import { PermitPanelInfo } from "../../../types/panels/permitPanel/permitPanel";

import {
  WELL_INFO_WELL_NAME,
  WELL_INFO_WELL_STATUS,
} from "../../../constants/panels/wellPanel/wellInfo";

import useExportPanelStore from "../../../store/exportPanel/exportPanelStore";

import usePermitPanelHeader from "../../../customHooks/panels/permitsPanel/usePermitPanelHeader";

import { formatAttributeByKey } from "../../../utils/formatters/attributeFormatter";
import { getTruncatedWellStatus } from "../../../utils/helper2";

interface PermitHeaderPops {
  permitInfo: PermitPanelInfo;
  wellHeaderPermitKey: string;
}

const PermitPanelHeader: FC<PermitHeaderPops> = ({
  permitInfo,
  wellHeaderPermitKey,
}) => {
  const exportPermitData = useExportPanelStore(
    (state) => state.exportPermitData
  );

  const { handleClosePermitPanel, handleOnExportPermitClick } =
    usePermitPanelHeader();

  return (
    <div className="well-panel-header">
      <div className="well-panel-header-wrapper">
        <span className="well-uwi">{permitInfo.uwi}</span>{" "}
        <div className="well-panel-pill-container">
          <span className="well-panel-pill">
            {getTruncatedWellStatus(
              (formatAttributeByKey(
                WELL_INFO_WELL_STATUS.key,
                permitInfo.permitSystemData?.permitData?.wellStatus as string
              ) as string) ?? ""
            )}
          </span>
          <span className="well-panel-pill">
            <span className="well-panel-pill-text">
              {permitInfo.recordType}
            </span>
          </span>
        </div>
        <span className="well-panel-header-button-container">
          <div className="well-panel-buttons">
            <Button
              className={classNames("export-well-panel-button", {
                isModalOpen: Boolean(
                  exportPermitData?.isOpenExportPermitPanelDialog
                ),
              })}
              onClick={() =>
                handleOnExportPermitClick(permitInfo, wellHeaderPermitKey)
              }
              disableRipple
              endIcon={<FileDownloadOutlinedIcon />}
            />
            <CircularButton
              icon={"close"}
              className="well-panel-close-button"
              onClick={() => handleClosePermitPanel(permitInfo)}
            />
          </div>
        </span>
      </div>
      <div className="well-name-number permit-header-name-number">
        {formatAttributeByKey(
          WELL_INFO_WELL_NAME.key,
          permitInfo.permitSystemData?.permitData[
            WELL_INFO_WELL_NAME.key
          ] as string
        )?.toString()}
        , {permitInfo.wellNumber}
      </div>
    </div>
  );
};

export default PermitPanelHeader;
