import { StateCreator } from "zustand";

import {
  PermitPanelSlice,
  PermitsPanelState,
} from "../../types/panels/permitPanel/permitsPanelStore";

import { PERMIT_PANEL_SECTION } from "../../constants/panels/permitPanel/permit";

const sliceResetFns = new Set<() => void>();

const resetPermitPanelSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const permitPanelSlice: StateCreator<
  PermitsPanelState,
  [],
  [],
  PermitPanelSlice
> = (set, get) => {
  return {
    permitsList: [],
    updatePermitsList: (permitsList) =>
      set(() => ({
        permitsList: [
          ...get().permitsList.map((permit) => {
            permit.isActive = false;
            return permit;
          }),
          permitsList,
        ],
      })),

    updateActivePermitPanel: (status, permitInfo) =>
      set(() => ({
        permitsList: get().permitsList.map((permit) => {
          if (!permitInfo) {
            permit.isActive = status;
            return permit;
          }

          if (permit.wellHeaderPermitKey === permitInfo.wellHeaderPermitKey) {
            permit.isActive = status;
          } else {
            permit.isActive = false;
          }
          return permit;
        }),
      })),
    updatePermitDataByKey: (permitData, wellHeaderPermitKey) =>
      set(() => ({
        permitsList: get().permitsList.map((permit) => {
          if (permit.wellHeaderPermitKey === wellHeaderPermitKey) {
            permit.permitSystemData = permitData;
          }

          return permit;
        }),
      })),

    updatePermitPanelActiveSectionByKey: (wellHeaderPermitKey, section) =>
      set(() => ({
        permitsList: get().permitsList.map((permit) => {
          if (
            permit.wellHeaderPermitKey === wellHeaderPermitKey &&
            permit.permitSystemData
          ) {
            switch (section.key) {
              case PERMIT_PANEL_SECTION.HEADER_SECTION.key:
                return {
                  ...permit,
                  permitSystemData: {
                    ...permit.permitSystemData,
                    permitSectionStatus: {
                      ...permit.permitSystemData.permitSectionStatus,
                      isHeaderSectionOpen:
                        !permit.permitSystemData.permitSectionStatus
                          .isHeaderSectionOpen,
                    },
                  },
                };
              case PERMIT_PANEL_SECTION.OPERATOR_CONTACT_SECTION.key:
                return {
                  ...permit,
                  permitSystemData: {
                    ...permit.permitSystemData,
                    permitSectionStatus: {
                      ...permit.permitSystemData.permitSectionStatus,
                      isOperatorContactSectionOpen:
                        !permit.permitSystemData.permitSectionStatus
                          .isOperatorContactSectionOpen,
                    },
                  },
                };
              case PERMIT_PANEL_SECTION.RELATED_WELLS_SECTION.key:
                return {
                  ...permit,
                  permitSystemData: {
                    ...permit.permitSystemData,
                    permitSectionStatus: {
                      ...permit.permitSystemData.permitSectionStatus,
                      isRelatedWellsSectionOpen:
                        !permit.permitSystemData.permitSectionStatus
                          .isRelatedWellsSectionOpen,
                    },
                  },
                };
              case PERMIT_PANEL_SECTION.RELATED_PERMITS_SECTION.key:
                return {
                  ...permit,
                  permitSystemData: {
                    ...permit.permitSystemData,
                    permitSectionStatus: {
                      ...permit.permitSystemData.permitSectionStatus,
                      isRelatedPermitsSectionOpen:
                        !permit.permitSystemData.permitSectionStatus
                          .isRelatedPermitsSectionOpen,
                    },
                  },
                };
            }
          }

          return permit;
        }),
      })),

    removePermitPanelByKey: (wellHeaderPermitKey) =>
      set(() => {
        const newPermitList = get().permitsList.filter(
          (permitData) =>
            !(permitData.wellHeaderPermitKey === wellHeaderPermitKey)
        );
        return {
          permitsList: newPermitList,
        };
      }),
    selectedCardPermitIDs: [],
    addSelectedCardPermitID: (selectedCardPermitIDs: number) =>
      set((state) => ({
        selectedCardPermitIDs: [
          ...get().selectedCardPermitIDs,
          selectedCardPermitIDs,
        ],
      })),
    removeSelectedPermitCardByID: (id) =>
      set((state) => ({
        selectedCardPermitIDs: get().selectedCardPermitIDs.filter(
          (data) => data !== id
        ),
      })),
    resetPermitList: () =>
      set(() => ({
        permitsList: [],
        selectedCardPermitIDs: [],
      })),
  };
};

export { permitPanelSlice, resetPermitPanelSlice };
