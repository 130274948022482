import { FC, useCallback, useEffect, useState } from "react";

import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";

import classNames from "classnames";

import { WellCounts } from "../../../../../../types/common/wells";
import { VarcharBooleanProps } from "../../../../../../types/panels/searchPanel/queryBuilder";

import { TEXT_SELECT } from "../../../../../../constants/attributes";
import { initialOperationType } from "../../../../../../constants/panels/searchPanel/queryBuilder/store";

import useQueryBuilderStore from "../../../../../../store/search/queryBulder/queryBuilderStore";

import useAttributeOptionsPostProcess from "../../../../../../customHooks/common/useAttributeOptionsPostProcess";

import Loading from "../../../../../common/Loading";

const QBTextSelectAttribute: FC<VarcharBooleanProps> = ({
  attribute,
  attributeQBInfo,
  isOptionsLoading,
  getOptionsWithWellCounts,
  modifyAttributeBounds,
}) => {
  const updateCurrentQBInfo = useQueryBuilderStore(
    (state) => state.updateCurrentQBInfo
  );
  const [options, setOptions] = useState<WellCounts[]>([]);

  const { getOptionLabelByAttribute } = useAttributeOptionsPostProcess();

  const onAttributeChange = (newValue: string | null) => {
    const attribValue = newValue;
    const value = attribValue ? [attribValue] : [];
    updateCurrentQBInfo(attribute.key, "values", value);
    modifyAttributeBounds(value, initialOperationType[TEXT_SELECT]);
  };

  useEffect(() => {
    if (isOptionsLoading) {
      setOptions([{ attributeValue: "Loading...", count: -1 }]);
    } else {
      if (attributeQBInfo.options.length) {
        setOptions(attributeQBInfo.options);
      } else {
        setOptions([{ attributeValue: "No options", count: -1 }]);
      }
    }
  }, [attributeQBInfo, isOptionsLoading]);

  const isOptionInvalid = useCallback(
    (option: WellCounts) => {
      return option.count === -1;
    },
    [options]
  );

  const valueLabel = useCallback(
    (val: string) => {
      return getOptionLabelByAttribute(attribute.key, val);
    },
    [attribute.key, attributeQBInfo.values]
  );

  return (
    <div className="relative">
      <FormControl fullWidth className="select-root">
        <InputLabel className="select-form-label">{attribute.label}</InputLabel>
        <Select
          className="autocomplete-textfield-root"
          variant="standard"
          label={attribute.label}
          value={
            attributeQBInfo?.values.length
              ? attributeQBInfo?.values[0].toString()
              : null
          }
          renderValue={(selected) => {
            return (
              <div className="select-value-box">
                {valueLabel(selected ?? "") ?? ""}
                {isOptionsLoading && <Loading size={20} />}
              </div>
            );
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                bgcolor: "black",
              },
            },
          }}
          IconComponent={ExpandMoreIcon}
          onChange={(e) => onAttributeChange(e.target.value)}
          onOpen={() => getOptionsWithWellCounts()}
        >
          {options.map((option, index) => (
            <MenuItem
              key={index}
              value={!isOptionInvalid(option) ? option.attributeValue : ""}
              className={classNames("select-list", {
                disabled: isOptionInvalid(option),
              })}
              disabled={isOptionInvalid(option)}
            >
              <span className="option-label">
                {option.label ?? option.attributeValue}
              </span>
              {!isOptionInvalid(option) && (
                <span className="option-count">{`(${option.count})`}</span>
              )}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default QBTextSelectAttribute;
