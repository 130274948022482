import { FC, SVGProps } from "react";

interface LassoSelectIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const LassoSelectIcon: FC<LassoSelectIconProps> = ({
  width = "20",
  height = "20",
  fill = "none",
  pathFill = "white",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.68742 8.75L2.20825 8.47917C2.31936 7.92361 2.47214 7.39236 2.66659 6.88542C2.86103 6.37847 3.11797 5.88889 3.43742 5.41667L4.68742 6.25C4.43742 6.625 4.22909 7.02431 4.06242 7.44792C3.89575 7.87153 3.77075 8.30556 3.68742 8.75ZM3.66659 14.7292C3.33325 14.2847 3.04853 13.8125 2.81242 13.3125C2.57631 12.8125 2.39575 12.2917 2.27075 11.75L3.74992 11.4167C3.86103 11.8611 4.00339 12.2847 4.177 12.6875C4.35061 13.0903 4.57631 13.4722 4.85409 13.8333L3.66659 14.7292ZM6.60409 4.41667L5.79159 3.14583C6.26381 2.84028 6.76034 2.59375 7.28117 2.40625C7.802 2.21875 8.3402 2.08333 8.89575 2L9.10409 3.47917C8.65964 3.54861 8.22561 3.65972 7.802 3.8125C7.37839 3.96528 6.97909 4.16667 6.60409 4.41667ZM9.99992 17.9167C9.29159 17.9167 8.6145 17.8264 7.96867 17.6458C7.32284 17.4653 6.70825 17.2153 6.12492 16.8958L6.87492 15.6042C7.34714 15.8681 7.8402 16.0694 8.35409 16.2083C8.86797 16.3472 9.41659 16.4167 9.99992 16.4167V17.9167ZM14.1041 4.91667C13.7569 4.625 13.3888 4.375 12.9999 4.16667C12.611 3.95833 12.1944 3.79167 11.7499 3.66667L12.1666 2.22917C12.6944 2.38194 13.2013 2.58681 13.6874 2.84375C14.1735 3.10069 14.6319 3.40972 15.0624 3.77083L14.1041 4.91667ZM16.4791 17.4583L13.4999 14.4792V16.9167H11.9999V11.9167H16.9999V13.4167H14.5624L17.5208 16.3958L16.4791 17.4583ZM16.4374 9.91667C16.4374 9.38889 16.3784 8.88542 16.2603 8.40625C16.1423 7.92708 15.9721 7.46528 15.7499 7.02083L17.0833 6.35417C17.3471 6.88194 17.5555 7.44792 17.7083 8.05208C17.861 8.65625 17.9374 9.27778 17.9374 9.91667H16.4374Z"
        fill={pathFill}
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default LassoSelectIcon;
