import { FC, memo } from "react";

import { WellPermitPanelState } from "../../../types/panels/sidebar";

import { RECORD_TYPES } from "../../../constants/panels/searchPanel/queryBuilder/attributeValues";

import usePanelsStore from "../../../store/panels/panelsStore";

import PermitsPanel from "../permitsPanel/PermitsPanel";
import WellPanel from "./WellPanel";

interface WellPanelDrawerProps {
  isOpenWellPanel: boolean;
  handleCloseMenuClick: () => void;
}

const WellPanelDrawer: FC<WellPanelDrawerProps> = ({
  isOpenWellPanel,
  handleCloseMenuClick,
}) => {
  const wellInfoList = usePanelsStore((state) => state.wellList);
  const permitsInfoList = usePanelsStore((state) => state.permitsList);

  const panelList: WellPermitPanelState[] = [
    ...wellInfoList,
    ...permitsInfoList,
  ];

  return panelList.map((panelInfo, index) => {
    if (panelInfo.recordType === RECORD_TYPES.WELL) {
      return (
        <WellPanel
          key={panelInfo.wellId + "_" + index}
          open={isOpenWellPanel}
          wellId={panelInfo.wellId}
          handleCloseMenuClick={handleCloseMenuClick}
          parentWellId={panelInfo.parentWellId}
          bottomWellBoreId={panelInfo.bottomWellBoreId}
          layer={panelInfo.wellType}
          uwi={panelInfo.uwi}
          groupedWellID={panelInfo.groupedWellID}
        />
      );
    } else if (panelInfo.recordType === RECORD_TYPES.PERMIT) {
      return (
        <PermitsPanel
          key={panelInfo.wellHeaderPermitKey + "_" + index}
          wellHeaderPermitKey={panelInfo.wellHeaderPermitKey}
          open={isOpenWellPanel}
          handleCloseMenuClick={handleCloseMenuClick}
        />
      );
    }
  });
};

export default memo(WellPanelDrawer);
