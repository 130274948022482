import { Attribute } from "../../types/common/attributes";
import { QBAttributeInfo } from "../../types/panels/searchPanel/queryBuilder";

import { initialAttributeValues } from "../../constants/panels/searchPanel/queryBuilder/attributeValues";
import { initialOperationType } from "../../constants/panels/searchPanel/queryBuilder/store";

export const getDefaultAttributeInfo = (
  attribute: Attribute
): QBAttributeInfo => ({
  options: [],
  operationType: initialOperationType[attribute.dataType],
  values: initialAttributeValues[attribute.key] ?? [],
  input: "",
  hasError: false,
  isUpdated: false,
});
