import { create } from "zustand";

import { MapStoreState } from "../../types/map/scaleBar";

import { deckSlice, layerSlice, mapSlice, zoomSlice } from "./mapSlice";

const useMapStore = create<MapStoreState>()((...a) => ({
  ...mapSlice(...a),
  ...deckSlice(...a),
  ...layerSlice(...a),
  ...zoomSlice(...a),
}));

export default useMapStore;
