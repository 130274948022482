import { FC, MouseEvent, useEffect, useMemo } from "react";

import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  SaveAs as SaveAsIcon,
  Save as SaveIcon,
  SaveOutlined as SaveOutlinedIcon,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Fade, MenuItem, MenuProps } from "@mui/material";
import { styled } from "@mui/material/styles";

import HoverMenu from "material-ui-popup-state/HoverMenu";
import {
  bindHover,
  bindMenu,
  usePopupState,
} from "material-ui-popup-state/hooks";

import { SaveSearchButtonProps } from "../../../../types/panels/common/saveSearchButton";

import { ATTRIBUTES_TAB, UWI_TAB } from "../../../../constants/constants";
import { SAVED_SEARCH_VISIBILITY_PRIVATE } from "../../../../constants/panels/savedSearchPanel/hooks";
import { SEARCH_TYPES } from "../../../../constants/panels/searchPanel/search";

import usePanelsStore from "../../../../store/panels/panelsStore";
import useQueryBuilderStore from "../../../../store/search/queryBulder/queryBuilderStore";
import useStore from "../../../../store/useStore";

import useSavedSearchData from "../../../../customHooks/savedSearch/createUpdateSavedSearch/useCreateUpdateSavedSearch";

import ButtonWithPrompt from "../../../common/ButtonWithPrompt";
import { useSearchPanelStyles } from "../../styles";

const StyledMenu = styled((props: MenuProps) => (
  <HoverMenu
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    TransitionProps={{
      timeout: 350,
    }}
    TransitionComponent={Fade}
    {...props}
  />
))(({ theme }) => useSearchPanelStyles(theme));

const SaveSearchButton: FC<SaveSearchButtonProps> = ({
  searchType,
  hasLoadedSavedSearch,
  disabled,
  onClickSaveCallback,
  closeAttributesPanel,
}) => {
  const popupState = usePopupState({
    variant: "popover",
    popupId: "save-menu",
  });

  const toggleIsOpenSaveSearchDialog = useStore(
    (state) => state.toggleIsOpenSaveSearchDialog
  );
  const selectedSavedSearchData = useStore(
    (state) => state.selectedSavedSearchData
  );
  const updateSavedSearchFileName = useStore(
    (state) => state.updateSavedSearchFileName
  );
  const updateLastSearchedBy = useStore((state) => state.updateLastSearchedBy);
  const updateToastMessage = useStore((state) => state.updateToastMessage);

  const updateActiveSearchPanelTab = usePanelsStore(
    (state) => state.updateActiveSearchPanelTab
  );
  const updateIsQBUpdated = useQueryBuilderStore(
    (state) => state.updateIsQBUpdated
  );

  const isShowPrompt = useMemo(
    () => !hasLoadedSavedSearch && selectedSavedSearchData.searchName,
    [hasLoadedSavedSearch, selectedSavedSearchData.searchName]
  );

  const showSaveDropdown = useMemo(
    () =>
      hasLoadedSavedSearch &&
      selectedSavedSearchData.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE,
    [hasLoadedSavedSearch, selectedSavedSearchData]
  );

  const {
    isLoading: updateSavedSearchLoading,
    data: updateSavedSearchData,
    error: updateSavedSearchError,
    updateSavedSearch,
  } = useSavedSearchData();

  const handleSaveClick = (
    e: MouseEvent<HTMLLIElement> | MouseEvent<HTMLButtonElement>
  ) => {
    e.stopPropagation();
    closeAttributesPanel();
    updateSavedSearch(searchType);
    updateLastSearchedBy(searchType);
    onClickSaveCallback();
    updateIsQBUpdated(false);
    popupState.close();
  };

  const handleSearchSaveDialog = (
    e: MouseEvent<HTMLLIElement> | MouseEvent<HTMLButtonElement>
  ) => {
    e?.stopPropagation();
    closeAttributesPanel();

    if (
      hasLoadedSavedSearch &&
      selectedSavedSearchData.visibility === SAVED_SEARCH_VISIBILITY_PRIVATE
    ) {
      updateSavedSearchFileName(selectedSavedSearchData.searchName);
      handleSaveClick(e);
    } else {
      toggleIsOpenSaveSearchDialog();
    }
  };

  const handleSearchSaveAsDialog = () => {
    closeAttributesPanel();
    toggleIsOpenSaveSearchDialog();
    popupState.close();
  };

  useEffect(() => {
    if (updateSavedSearchError || !updateSavedSearchData) return;
    updateToastMessage("Saved successfully");
  }, [updateSavedSearchData]);

  return (
    <>
      {showSaveDropdown ? (
        <>
          <LoadingButton
            {...bindHover(popupState)}
            disabled={updateSavedSearchLoading || disabled}
            startIcon={<SaveOutlinedIcon />}
            endIcon={<KeyboardArrowDownIcon />}
            className="save-loading-btn"
          >
            Save
          </LoadingButton>
          <StyledMenu {...bindMenu(popupState)}>
            <MenuItem onClick={handleSaveClick} disableRipple>
              <SaveIcon />
              Save
            </MenuItem>
            <MenuItem onClick={handleSearchSaveAsDialog} disableRipple>
              <SaveAsIcon />
              Save As
            </MenuItem>
          </StyledMenu>
        </>
      ) : (
        <ButtonWithPrompt
          description="Do you want to save a new search?"
          showPrompt={isShowPrompt}
          handleConfirmClick={handleSearchSaveDialog}
          handleCancelClick={() => {
            updateActiveSearchPanelTab(
              searchType === SEARCH_TYPES.ATTRIBUTE_SEARCH
                ? UWI_TAB
                : ATTRIBUTES_TAB
            );
          }}
          buttonProps={{
            text: "Save",
            iconLeft: "save",
            state: disabled ? "disabled" : "enabled",
            type: "tertiary",
          }}
        />
      )}
    </>
  );
};

export default SaveSearchButton;
