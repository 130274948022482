import { useEffect, useState } from "react";

import {
  Box,
  Checkbox,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import { CHART_GROUP_BY_TYPE } from "../../../../constants/charts/charts";
import { PRODUCTION_DATA_FIELDS_LIST } from "../../../../constants/charts/productionPlotDataFields";

import useChartStore from "../../../../store/chart/chartStore";

import usePrevious from "../../../../customHooks/usePrevious";

import { getTopDataField } from "../../../../utils/charts/sorting";

import colorPalleteIcon from "../../../../assets/images/color_pallete.png";

const DataFieldsPicker = (props) => {
  const dataFieldsList = useChartStore(
    (state) => state.chartSettingsDataFieldsList
  );

  const [selectedList, setSelectedList] = useState(props?.selectedDataFields);
  const previousAggregate = usePrevious(props.aggregate);

  const isDataSelected = (data) => {
    let dataIndex = selectedList?.findIndex(
      (dataField) =>
        dataField.name === data.name &&
        dataField.accumMethod === data.accumMethod
    );
    return dataIndex > -1;
  };

  const handleOnDataSelect = (data) => {
    let newSelected = [...selectedList];
    const selectedIndex = newSelected.findIndex(
      (dataField) =>
        dataField.name === data.name &&
        dataField.accumMethod === data.accumMethod
    );

    if (selectedIndex === -1) {
      //if data field is not yet selected
      newSelected.push(data);
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedList(newSelected);
  };

  const handleRadioButtonSelection = (data) => {
    setSelectedList([data]);
  };

  const handleOnSelectAllClick = (e) => {
    if (e.target.checked) {
      setSelectedList(dataFieldsList);
    } else {
      setSelectedList([]);
    }
  };

  useEffect(() => {
    props.onDataFieldsSelection(selectedList);
  }, [selectedList]);

  useEffect(() => {
    if (
      !props.aggregate ||
      props.aggregate === CHART_GROUP_BY_TYPE.DEFAULT ||
      !selectedList.length
    ) {
      return;
    }

    if (previousAggregate == CHART_GROUP_BY_TYPE.DEFAULT) {
      const newSelectedList = getTopDataField(
        selectedList,
        PRODUCTION_DATA_FIELDS_LIST
      );
      setSelectedList(newSelectedList);
    }
  }, [props.aggregate, previousAggregate]);

  return (
    <div className="data-fields-wrapper">
      <Typography className="data-fields-title">
        Data Fields to Display
      </Typography>
      <TableContainer className="data-fields-table-head">
        <Table className="data-fields-table-head">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                {props.aggregate === CHART_GROUP_BY_TYPE.DEFAULT && (
                  <Checkbox
                    color="primary"
                    indeterminate={
                      selectedList?.length > 0 &&
                      selectedList?.length < dataFieldsList.length
                    }
                    checked={
                      dataFieldsList.length > 0 &&
                      selectedList?.length === dataFieldsList.length
                    }
                    onChange={handleOnSelectAllClick}
                  />
                )}
              </TableCell>
              <TableCell>
                <Typography>Data</Typography>
              </TableCell>
              <TableCell>
                <Typography>Color</Typography>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <TableContainer className="data-fields-table-body">
        <Table>
          <TableBody>
            {dataFieldsList.map((data) => (
              <TableRow key={`${data.name}-${data.accumMethod}`}>
                <TableCell padding="checkbox">
                  {props.aggregate === CHART_GROUP_BY_TYPE.DEFAULT ? (
                    <Checkbox
                      size="small"
                      color="primary"
                      checked={isDataSelected(data)}
                      onChange={(e) => {
                        handleOnDataSelect(data);
                      }}
                    />
                  ) : (
                    <Radio
                      size="small"
                      checked={
                        selectedList[0]?.name === data.name &&
                        selectedList[0]?.accumMethod === data.accumMethod
                      }
                      value={`${data.name}-${data.accumMethod}`}
                      onChange={(e) => {
                        handleRadioButtonSelection(data);
                      }}
                    />
                  )}
                </TableCell>
                <TableCell>
                  <Typography>{data.displayName}</Typography>
                </TableCell>
                <TableCell>
                  {props.aggregate === CHART_GROUP_BY_TYPE.DEFAULT ? (
                    <Box
                      sx={{
                        width: 15,
                        height: 15,
                        backgroundColor: data.color,
                      }}
                    />
                  ) : (
                    <div>
                      <img src={colorPalleteIcon} alt="color pallete" />
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataFieldsPicker;
