import { Feature, Tile, VectorTile } from "ol";
import { FeatureLike } from "ol/Feature";
import { MVT as olMVT } from "ol/format";
import VectorTileLayer from "ol/layer/VectorTile";
import RenderFeature from "ol/render/Feature";
import VectorTileSource from "ol/source/VectorTile";
import { Fill, Stroke, Style, Text } from "ol/style";
import { StyleFunction } from "ol/style/Style";

import { MVT } from "../../../types/map";

const getMVTLayer = (layer: MVT, definitionKey: string, token: string) => {
  let layerstyle = new Style({
    stroke: new Stroke({
      color: "red",
      width: 1,
    }),
    fill: new Fill({
      color: "green",
    }),
  });
  const customStyles = layer.layerStyle;
  if (customStyles) {
    layerstyle = new Style({
      stroke: new Stroke({
        color: layer.layerStyle.stroke.color,
        width: layer.layerStyle.stroke.width,
        lineJoin: layer.layerStyle.stroke.lineJoin
          ? layer.layerStyle.stroke.lineJoin
          : undefined,
        lineCap: layer.layerStyle.stroke.lineCap
          ? layer.layerStyle.stroke.lineCap
          : undefined,
      }),
      fill: new Fill({
        color: layer.layerStyle.fill.color,
        //   opacity: 0.5,
      }),
      text: new Text({
        font: "0.8rem Roboto, Arial",
        overflow: true,
        fill: new Fill({
          color: "#fff",
        }),
        // stroke: new Stroke({
        //   color: "#fff",
        //   width: 1,
        // }),
      }),
    });
  }
  const layerAttributes = {
    title: definitionKey,
    declutter: true,
    source: new VectorTileSource({
      url:
        layer.url +
        "?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=&TILEMATRIX=" +
        layer.projection +
        ":{z}&TILEMATRIXSET=" +
        layer.projection +
        "&TILECOL={x}&TILEROW={y}&FORMAT=" +
        layer.format +
        "&LAYER=" +
        layer.params.LAYERS,
      format: new olMVT(),
      tileLoadFunction: (tile, src) => {
        tileWMTSFetcher(tile, src, token);
      },
      wrapX: true,
    }),
    ...(layer.minZoom && {
      minZoom: layer.minZoom,
    }),
    ...(layer.zIndex && { zIndex: layer.zIndex }),
    style: getMVTStyle(layerstyle),
  };
  return new VectorTileLayer(layerAttributes);
};

const getMVTStyle = (layerStyle: Style): StyleFunction => {
  return (feature: FeatureLike) => {
    const label = feature.get("name").split(" ").join("\n");
    layerStyle.getText().setText(label);
    return layerStyle;
  };
};

const tileWMTSFetcher = (tile: Tile, src: string, token: string) => {
  const vectorTile = tile as VectorTile;

  const client = new XMLHttpRequest();
  vectorTile.setLoader((extent, resolution, projection) => {
    client.open("GET", src);
    client.responseType = "arraybuffer";
    client.setRequestHeader("Authorization", "Bearer " + token);
    client.onload = function () {
      const data = new Uint8Array(this.response);
      if (data !== undefined) {
        const format = new olMVT({
          featureClass: RenderFeature,
        });
        const features = format.readFeatures(data, {
          extent: extent,
          featureProjection: projection,
        }) as Feature[];
        vectorTile.setFeatures(features);
      }
    };
    client.send();
  });
};

export { getMVTLayer, tileWMTSFetcher };
