import * as attributes from "../wellPanel/wellPanelAttributes";

export const formationColumns = [
  {
    field: attributes.FORMATION_NAME.key,
    headerName: attributes.FORMATION_NAME.label,
    width: 120,
    sortable: false,
  },
  {
    field: attributes.TOP_DEPTH.key,
    headerName: attributes.TOP_DEPTH.label,
    width: 80,
    sortable: false,
  },
];
