import { FC, SVGProps } from "react";

interface ProducingFormationIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const ProducingFormationIcon: FC<ProducingFormationIconProps> = ({
  width = "11",
  height = "11",
  fill = "none",
  pathFill = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 11 11"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.75195 1C5.35413 1 4.9726 1.15804 4.69129 1.43934C4.40999 1.72064 4.25195 2.10218 4.25195 2.5C4.25195 3.1185 4.64295 3.749 5.12095 4.272C5.34895 4.521 5.57795 4.726 5.75195 4.8695C5.97574 4.68504 6.18657 4.4854 6.38295 4.272C6.86095 3.749 7.25195 3.119 7.25195 2.5C7.25195 2.10218 7.09392 1.72064 6.81261 1.43934C6.53131 1.15804 6.14978 1 5.75195 1ZM5.75195 6.107L5.46845 5.912L5.46745 5.911L5.46545 5.91L5.45945 5.9055L5.43895 5.8905C5.33083 5.81206 5.22512 5.73035 5.12195 5.6455C4.92145 5.4805 4.65295 5.242 4.38295 4.9465C3.86095 4.3755 3.25195 3.5065 3.25195 2.5C3.25195 1.83696 3.51535 1.20107 3.98419 0.732233C4.45303 0.263392 5.08891 0 5.75195 0C6.41499 0 7.05088 0.263392 7.51972 0.732233C7.98856 1.20107 8.25195 1.83696 8.25195 2.5C8.25195 3.506 7.64295 4.376 7.12095 4.9465C6.80046 5.29488 6.44679 5.61121 6.06495 5.891L6.04495 5.9055L6.03845 5.91L6.03645 5.911L6.03595 5.9115L5.75195 6.107ZM5.75195 2.625C5.78511 2.625 5.8169 2.61183 5.84034 2.58839C5.86378 2.56495 5.87695 2.53315 5.87695 2.5C5.87695 2.46685 5.86378 2.43505 5.84034 2.41161C5.8169 2.38817 5.78511 2.375 5.75195 2.375C5.7188 2.375 5.68701 2.38817 5.66356 2.41161C5.64012 2.43505 5.62695 2.46685 5.62695 2.5C5.62695 2.53315 5.64012 2.56495 5.66356 2.58839C5.68701 2.61183 5.7188 2.625 5.75195 2.625ZM4.87695 2.5C4.87695 2.26794 4.96914 2.04538 5.13323 1.88128C5.29733 1.71719 5.51989 1.625 5.75195 1.625C5.98402 1.625 6.20658 1.71719 6.37067 1.88128C6.53477 2.04538 6.62695 2.26794 6.62695 2.5C6.62695 2.73206 6.53477 2.95462 6.37067 3.11872C6.20658 3.28281 5.98402 3.375 5.75195 3.375C5.51989 3.375 5.29733 3.28281 5.13323 3.11872C4.96914 2.95462 4.87695 2.73206 4.87695 2.5ZM0.751953 4H3.25195V5H1.75195V8.2485L4.28945 7.1905L7.25195 8.921L9.75195 7.463V5H8.25195V4H10.752V8.037L7.25195 10.079L4.21495 8.305L0.751953 9.749V4Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default ProducingFormationIcon;
