import { useEffect, useMemo } from "react";

import { Accordion as CLAccordion } from "component-library";

import {
  BOTTOM_WELL_SPOTS,
  BUBBLE_MAP,
  WELL_SPOTS,
} from "../../constants/constants";
import { STYLING_API_KEY_MAPPER } from "../../constants/map/mapSettings";

import useDataGridStore from "../../store/grid/dataGridStore";
import useMapStore from "../../store/map/mapStore";
import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";
import useUWISearchStore from "../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../store/useStore";

import useWellSpotStyling from "../../customHooks/map/mapSettings/useWellSpotStyling";
import useSearchCriteria from "../../customHooks/search/useSearchCriteria";

import {
  isBottomSelected,
  isSurfaceSelected,
} from "../../utils/map/mapSettings";

import AccordionSection from "../common/AccordionSection";
import SizeBySelect from "./bubbleSizeAccordion/SizeBySelect";

const BubbleMapAccordion = () => {
  const layers = useMapStore((state) => state.layers);
  const toggleLayer = useMapStore((state) => state.toggleLayer);
  const currentZoom = useMapStore((state) => state.currentZoom);

  // Search Criteria
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);
  const initialGridSearchMade = useDataGridStore(
    (state) => state.initialGridSearchMade
  );

  // Well Spot Styling
  const isProcessingStyles = useMapSettingsStore(
    (state) => state.isProcessingStyles
  );
  const updateIsProcessingStyles = useMapSettingsStore(
    (state) => state.updateIsProcessingStyles
  );
  const layerStyles = useMapSettingsStore((state) => state.layerStyles);
  const updateBubbleSizes = useMapSettingsStore(
    (state) => state.updateBubbleSizes
  );
  const compressedSurfaceBubbleMapData = useMapSettingsStore(
    (state) => state.compressedSurfaceBubbleMapData
  );
  const compressedBottomBubbleMapData = useMapSettingsStore(
    (state) => state.compressedBottomBubbleMapData
  );
  const updateSurfaceBubbleMapDataInfo = useMapSettingsStore(
    (state) => state.updateSurfaceBubbleMapDataInfo
  );
  const updateBottomBubbleMapDataInfo = useMapSettingsStore(
    (state) => state.updateBottomBubbleMapDataInfo
  );
  const updateBubbleMapPartitionMinMax = useMapSettingsStore(
    (state) => state.updateBubbleMapPartitionMinMax
  );

  const updateIsBubbleSizesLoading = useStore(
    (state) => state.updateIsBubbleSizesLoading
  );

  // Loading state for DVT
  const DVTProcessing = useStore((state) => state.DVTProcessing);

  const isLoadingDrawToSelect = useStore(
    (state) => state.isDrawToSelectLoading
  );
  const isFromUploadedUWIFile = useUWISearchStore(
    (state) => state.isFromUploadedUWIFile
  );

  // Custom Hooks
  const {
    isLoading: isSurfaceValuesLoading,
    data: surfaceValues,
    getWellSpotAttribValues: getSurfaceValues,
  } = useWellSpotStyling();

  const {
    isLoading: isBottomValuesLoading,
    data: bottomValues,
    getWellSpotAttribValues: getBottomValues,
  } = useWellSpotStyling();

  const { hasSearchCriteria } = useSearchCriteria();

  // Memoization
  const sizeByKey = useMemo(
    () => layerStyles[BUBBLE_MAP].size as string,
    [layerStyles]
  );

  const isSurfaceLayerSelected = useMemo(
    () => isSurfaceSelected(layers),
    [layers]
  );
  const isBottomLayerSelected = useMemo(
    () => isBottomSelected(layers),
    [layers]
  );
  const isBubbleMapLayerSelected = useMemo(
    () => !!layers.find((layer) => layer.name === BUBBLE_MAP)?.isSelected,
    [layers]
  );

  const isBubbleMapDisabled =
    (!isSurfaceLayerSelected && !isBottomLayerSelected) ||
    currentZoom < 12 ||
    !hasSearchCriteria ||
    DVTProcessing ||
    isLoadingDrawToSelect ||
    isProcessingStyles;

  // API Calls
  const callWellSpotStylingApi = (attribute: string) => {
    (async () => {
      if (isBottomLayerSelected) {
        updateIsBubbleSizesLoading(true);
        updateIsProcessingStyles(true);
        await getBottomValues({
          attribute,
          searchCriteria,
          layer: STYLING_API_KEY_MAPPER[BOTTOM_WELL_SPOTS].request,
        });
      } else if (isSurfaceLayerSelected) {
        if (
          (!isFromUploadedUWIFile && searchCriteria.fileId) ||
          (isFromUploadedUWIFile && !searchCriteria.fileId)
        )
          return;

        updateIsBubbleSizesLoading(true);
        updateIsProcessingStyles(true);
        await getSurfaceValues({
          attribute,
          searchCriteria,
          layer: STYLING_API_KEY_MAPPER[WELL_SPOTS].request,
        });
      }
    })();
  };

  useEffect(() => {
    if (!hasSearchCriteria) return;

    if (
      !isBubbleMapLayerSelected ||
      (!isSurfaceLayerSelected && !isBottomLayerSelected) ||
      !sizeByKey
    ) {
      updateSurfaceBubbleMapDataInfo([]);
      updateBottomBubbleMapDataInfo([]);
      updateLegendInfo();
      return;
    }

    callWellSpotStylingApi(sizeByKey);
  }, [searchCriteria, sizeByKey]);

  useEffect(() => {
    if (isSurfaceValuesLoading || !initialGridSearchMade) return;

    updateSurfaceBubbleMapDataInfo(surfaceValues);
  }, [isSurfaceValuesLoading, surfaceValues]);

  useEffect(() => {
    if (isBottomValuesLoading || !initialGridSearchMade) return;

    updateBottomBubbleMapDataInfo(bottomValues);
  }, [isBottomValuesLoading, bottomValues]);

  useEffect(() => {
    if (!isSurfaceValuesLoading && !isBottomValuesLoading) {
      updateLegendInfo();
    }
  }, [isSurfaceValuesLoading, isBottomValuesLoading]);

  const updateLegendInfo = () => {
    updateBubbleMapPartitionMinMax();
    updateBubbleSizes();
    updateIsBubbleSizesLoading(false);
    updateIsProcessingStyles(false);
  };

  // updates legend list of colors and values
  useEffect(() => {
    updateLegendInfo();
  }, [compressedSurfaceBubbleMapData, compressedBottomBubbleMapData]);

  useEffect(() => {
    if (!isBubbleMapLayerSelected || !hasSearchCriteria || !sizeByKey) return;

    (async () => {
      if (isBottomLayerSelected) {
        updateIsBubbleSizesLoading(true);
        updateIsProcessingStyles(true);
        await getBottomValues({
          attribute: sizeByKey,
          searchCriteria,
          layer: STYLING_API_KEY_MAPPER[BOTTOM_WELL_SPOTS].request,
        });
      } else if (isSurfaceLayerSelected) {
        updateIsBubbleSizesLoading(true);
        updateIsProcessingStyles(true);
        await getSurfaceValues({
          attribute: sizeByKey,
          searchCriteria,
          layer: STYLING_API_KEY_MAPPER[WELL_SPOTS].request,
        });
      } else {
        updateBottomBubbleMapDataInfo([]);
        updateSurfaceBubbleMapDataInfo([]);
        updateLegendInfo();
      }
    })();
  }, [isSurfaceLayerSelected, isBottomLayerSelected, isBubbleMapLayerSelected]);

  return (
    <CLAccordion title="Bubble Map" className="cl-custom-accordion">
      <AccordionSection
        hasCheckbox
        title="Show Bubble Map"
        disabled={isBubbleMapDisabled}
        infoMessage={
          isBubbleMapDisabled &&
          "Search required, min zoom 12, and turn on Surface Hole Location or Bottom Hole Location"
        }
        isSelected={isBubbleMapLayerSelected}
        onSelect={() => toggleLayer(BUBBLE_MAP)}
      >
        <SizeBySelect disabled={isBubbleMapDisabled} />
      </AccordionSection>
    </CLAccordion>
  );
};

export default BubbleMapAccordion;
