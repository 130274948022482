import { FC } from "react";

import LegendsOverlay from "../mapOverlays/LegendsOverlay";
import MapSettingsOverlay from "../mapOverlays/MapSettingsOverlay";
import LayerInfoOverlay from "../mapOverlays/layerInfoOverlays/LayerInfoOverlay";
import DrawToSelectControls from "./controls/DrawToSelectControls";
import MapControls from "./controls/MapControls";
import ZoomLevel from "./controls/ZoomLevel/ZoomLevel";

const MapControlsActions: FC = () => {
  return (
    <>
      <MapSettingsOverlay />
      <LegendsOverlay />
      <MapControls />
      <DrawToSelectControls />
      <ZoomLevel />
      <LayerInfoOverlay />
    </>
  );
};

export default MapControlsActions;
