import { StateCreator } from "zustand";

import {
  DrawPolygonMode,
  MapDrawPolygonSlice,
  MapDrawStates,
} from "../../../../types/map/draw/store";
import { Polygon } from "../../../../types/map/gis/common";

import { DRAW_MODE_NONE } from "../../../../constants/map/mapSettings";

const sliceResetFns = new Set<() => void>();

const resetMapDrawPolygonSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialMapDrawPolygonStates = {
  drawPolygonMode: DRAW_MODE_NONE as DrawPolygonMode,
  drawPolygonModeListener: null,
  drawnPolygons: [] as Polygon,
};

const mapDrawPolygonSlice: StateCreator<
  MapDrawStates,
  [],
  [],
  MapDrawPolygonSlice
> = (set) => {
  sliceResetFns.add(() => set(initialMapDrawPolygonStates));

  return {
    ...initialMapDrawPolygonStates,
    updateDrawPolygonMode: (drawPolygonMode) =>
      set(() => ({ drawPolygonMode })),
    updateDrawPolygonModeListener: (drawPolygonModeListener) =>
      set(() => ({ drawPolygonModeListener })),

    addDrawnPolygon: (drawnPolygon) =>
      set((state) => ({
        drawnPolygons: [...state.drawnPolygons, drawnPolygon],
        polygons: [...state.polygons, drawnPolygon],
      })),
    removeDrawnPolygon: (polygonToRemove) =>
      set((state) => ({
        drawnPolygons: state.drawnPolygons.filter(
          (polygon) => polygon.id !== polygonToRemove.id
        ),
        polygons: state.polygons.filter(
          (polygon) => polygon.id !== polygonToRemove.id
        ),
      })),
    updateDrawnPolygons: (drawnPolygons) => set(() => ({ drawnPolygons })),
  };
};

export { mapDrawPolygonSlice, resetMapDrawPolygonSlice };
