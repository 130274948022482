const modularityStore = (set, get) => ({
  exportChart: {
    chartType: "",
    chartId: "",
    chartMode: "",
  },
  updateExportChart: (exportChart) => set((state) => ({ exportChart })),
});

export default modularityStore;
