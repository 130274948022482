import { FC } from "react";

import { Checkbox, CheckboxProps } from "@mui/material";

import { CLCheckedBoxIcon, CLCheckedBoxOutlineIcon } from "./icons";

const CustomCheckbox: FC<CheckboxProps> = (props) => {
  return (
    <Checkbox
      {...props}
      className="ds-custom-checkbox"
      icon={
        <CLCheckedBoxOutlineIcon
          pathFill={props.disabled ? "rgba(255,255,255,.35)" : "#fff"}
        />
      }
      checkedIcon={
        <CLCheckedBoxIcon
          pathFill={props.disabled ? "rgba(255,255,255,.35)" : "#42a5f5"}
        />
      }
    />
  );
};

export default CustomCheckbox;
