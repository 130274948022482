import { forwardRef, useMemo, useState } from "react";
import { Layout, Responsive, WidthProvider } from "react-grid-layout";

import { ChevronLeft, ChevronRight, ExpandMore } from "@mui/icons-material";

import classNames from "classnames";

import { DefaultLayout } from "../../types/modularity/layout";
import {
  AnalysisChartModularWindowProps,
  ResizeHandleProps,
} from "../../types/panels/analysisChartsPanel/analysisChartsPanel";

import { CHART_MODE_VIEW } from "../../constants/charts/chartModeView";

import useModularityStore from "../../store/modularity/modularityStore";

import { useModularity } from "../../customHooks/modularity/useModularity";
import usePrevious from "../../customHooks/usePrevious";

import ChartType from "../charts/ChartType";

const ResizeHandle = (
  props: ResizeHandleProps,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const { handleAxis, ...restProps } = props;
  return (
    <div
      {...restProps}
      ref={ref}
      className={`custom-rgl-handle react-resizable-handle react-resizable-handle-${handleAxis}`}
    >
      {(handleAxis === "e" || handleAxis === "se") && (
        <ChevronRight className="rgl-handle-icon" />
      )}
      {(handleAxis === "w" || handleAxis === "sw") && (
        <ChevronLeft className="rgl-handle-icon" />
      )}
      {handleAxis === "s" && <ExpandMore className="rgl-handle-icon" />}
    </div>
  );
};

const CustomHandles = forwardRef<HTMLDivElement, { handleAxis?: string }>(
  ResizeHandle
);

const AnalysisChartModularWindow = ({
  chartMode,
}: AnalysisChartModularWindowProps) => {
  const chartFullScreenLayout = useModularityStore(
    (state) => state.chartFullScreenLayout
  );

  const chartDefaultLayout = useModularityStore(
    (state) => state.chartDefaultLayout
  );

  const isFullScreenAnalysisPane = useModularityStore(
    (state) => state.isFullScreenAnalysisPane
  );
  const isChartsDraggable = useModularityStore(
    (state) => state.isChartsDraggable
  );
  const updateIsChartsDraggable = useModularityStore(
    (state) => state.updateIsChartsDraggable
  );

  const updateLockDrag = useModularityStore((state) => state.updateLockDrag);

  const [currentItemResized, setCurrentItemResized] = useState<Layout | null>(
    null
  );
  const [currentItemDragged, setCurrentItemDragged] = useState<Layout | null>(
    null
  );

  const { handleLayoutChange } = useModularity();

  const ReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const chartLayout = useMemo(() => {
    if (chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS) {
      return chartFullScreenLayout;
    } else {
      return chartDefaultLayout;
    }
  }, [chartMode, chartDefaultLayout, chartFullScreenLayout]);

  const enableResizeChart = useMemo(() => {
    return Boolean(
      isFullScreenAnalysisPane &&
        chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS
    );
  }, [isFullScreenAnalysisPane]);

  const previousChartFullScreenLayout = usePrevious(chartFullScreenLayout);
  const previousChartDefaultLayout = usePrevious(chartDefaultLayout);

  const handleDynamicLayoutAdjustment = (currentLayout: Layout[]) => {
    if (previousChartDefaultLayout && previousChartFullScreenLayout) {
      if (
        currentLayout.length <
          (previousChartDefaultLayout as DefaultLayout[]).length &&
        currentLayout.length <
          (previousChartFullScreenLayout as DefaultLayout[]).length
      ) {
        handleLayoutChange(currentLayout, chartMode);
      }
    }
  };

  return (
    <ReactGridLayout
      onLayoutChange={(currentLayout) =>
        handleDynamicLayoutAdjustment(currentLayout)
      }
      verticalCompact
      layouts={{ lg: chartLayout }}
      breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
      preventCollision={false}
      cols={{ lg: 8, md: 8, sm: 4, xs: 2, xxs: 2 }}
      autoSize
      resizeHandles={["se", "e", "w", "s", "sw"]}
      {...(enableResizeChart && { resizeHandle: <CustomHandles /> })}
      isBounded
      measureBeforeMount={
        isFullScreenAnalysisPane &&
        chartMode === CHART_MODE_VIEW.FULLSCREEN_ANALYSIS
      }
      isDraggable={!!isChartsDraggable}
      isResizable={enableResizeChart}
      onDrag={(layout, oldItem, newItem, placeholder, event, element) => {
        setCurrentItemDragged(oldItem);
      }}
      onDragStop={(layout, oldItem, newItem, placeholder, event, element) => {
        updateLockDrag(false);
        updateIsChartsDraggable("");
        setCurrentItemDragged(null);
        handleLayoutChange(layout, chartMode);
      }}
      onResizeStart={(
        layout,
        oldItem,
        newItem,
        placeholder,
        event,
        element
      ) => {
        setCurrentItemResized(oldItem);
      }}
      onResizeStop={(layout, oldItem, newItem, placeholder, event, element) => {
        setCurrentItemResized(null);
        handleLayoutChange(layout, chartMode);
      }}
    >
      {chartLayout.map((chart: DefaultLayout) => {
        return (
          <div
            className={classNames("resizable react-grid-item", {
              resizing: currentItemResized?.i === chart?.i,
              dragging: currentItemDragged?.i === chart?.i,
            })}
            key={chart?.i}
            data-grid={{
              x: chart?.x,
              y: chart?.y,
              w: chart?.w,
              h: chart?.h,
              minW: chart?.minW,
              maxW: chart?.maxW,
              minH: chart?.minH,
            }}
          >
            <ChartType
              chartType={chart?.type}
              chartId={chart?.i}
              chartMode={chartMode}
            />
          </div>
        );
      })}
    </ReactGridLayout>
  );
};

export default AnalysisChartModularWindow;
