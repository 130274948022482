import { FC, RefObject, useRef } from "react";

import {
  Button,
  ClickAwayListener,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";

import { ZOOM_LEVELS } from "../../../../constants/map/zoomLevels";

interface ZoomLevelsPopperProps {
  open: boolean;
  zoomNum: number;
  zoomSingleItem: (level: number) => void;
  anchorRef: RefObject<HTMLButtonElement>;
  handleClose: () => void;
}

const ZoomLevelsPopper: FC<ZoomLevelsPopperProps> = ({
  open,
  zoomNum,
  zoomSingleItem,
  handleClose,
  anchorRef,
}) => {
  return (
    <Popper
      open={open}
      anchorEl={anchorRef.current}
      className="zoom-level-menu-container"
      placement="top"
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList>
            {ZOOM_LEVELS.map((level) => (
              <MenuItem
                key={`zoom-${level}`}
                onClick={(event) => {
                  const { innerText } = event.target as HTMLElement;
                  zoomSingleItem(parseInt(innerText));
                }}
                selected={Boolean(level === zoomNum)}
              >
                {level}
              </MenuItem>
            ))}
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popper>
  );
};

export default ZoomLevelsPopper;
