import { StateCreator } from "zustand";

import {
  DataCurrencyActionType,
  DataCurrencyStoreType,
} from "../../types/information/information";

const dataCurrencySlice: StateCreator<
  DataCurrencyStoreType & DataCurrencyActionType
> = (set) => ({
  isOpenDataCurrencyTable: false,
  isLoadingCurrencyData: false,
  currencyData: [],
  updateCurrencyData: (currencyData) => set({ currencyData }),
  updateIsLoadingCurrencyData: (isLoadingCurrencyData) =>
    set({ isLoadingCurrencyData }),
  updateIsOpenDataCurrencyTable: (isOpenDataCurrencyTable) =>
    set({ isOpenDataCurrencyTable }),
});

export default dataCurrencySlice;
