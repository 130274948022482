import { FC } from "react";

import {
  Checkbox,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

import { CheckboxSelectAllProps } from "../../../../../../types/grid";

const CheckboxSelectAll: FC<CheckboxSelectAllProps> = (props) => {
  const { onSelectAll, selectedAll } = props;

  return (
    <div className="checkbox-list-container">
      <ListItem key={"select-all-grid-filtering-001"} disablePadding>
        <ListItemButton
          role={undefined}
          onClick={() =>
            onSelectAll(selectedAll ? "deselect-all" : "select-all")
          }
          dense
        >
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selectedAll}
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": "checkbox-list-label-select-all",
              }}
            />
          </ListItemIcon>
          <ListItemText
            className="grid-filter-text-list"
            id={"checkbox-list-label-select-all"}
            primary={"Select All"}
          />
        </ListItemButton>
      </ListItem>
    </div>
  );
};

export default CheckboxSelectAll;
