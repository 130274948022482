import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import WellEconomicsGrid from "../../../../assets/images/release-notes/WellEconomicsGrid.png";
import WellEconomicsQB from "../../../../assets/images/release-notes/WellEconomicsQB.png";

const Version20240703: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240703"}
      onChange={handleChange("Version20240703")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.07.03
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          07/03/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes enhancements to Well Economics Data.
        </CustomTypography>
      </AccordionDetails>
      <AccordionContent>
        • Well Economics Improvements – Search Query and Grid Results{" "}
      </AccordionContent>
      <AccordionSubContent>
        • WDA users now have access to Well Economics Data through search
        queries, allowing them to search by any of the well economics
        attributes.
      </AccordionSubContent>
      <AccordionImage src={WellEconomicsQB} width="100%" draggable={false} />
      <AccordionSubContent>
        • Well economic attributes are now available in the grid results table,
        allowing users to filter the data by their economic values.
      </AccordionSubContent>
      <AccordionSubContent>
        • Available economic attributes in the grid table include:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Capex <br />
          ▪ Infrastructure <br />
          ▪ Working Interest <br />
          ▪ Royalty <br />
          ▪ IRR <br />
          • Breakeven Price <br />
          ▪ NPV <br />
          • Payback <br />
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionImage src={WellEconomicsGrid} width="100%" draggable={false} />
    </Accordion>
  );
};

export default Version20240703;
