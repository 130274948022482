import AppsIcon from "@mui/icons-material/Apps";
import { Divider } from "@mui/material/";

import config from "../../configs/appSettings";

import usePanelsStore from "../../store/panels/panelsStore";

import { withRouter } from "../../utils/routes/withRouter";

import Informations from "../Informations";
import { TGSLogo } from "../common/icons";
import ProfileSettings from "./appHeader/ProfileSettings";
import UoMSetting from "./appHeader/UoMSetting";

const AppHeader = () => {
  const toggleIsOpenAppLevelNav = usePanelsStore(
    (state) => state.toggleIsOpenAppLevelNav
  );

  return (
    <header className={`arlas-cs-header-section`}>
      <div className="d-flex justify-content-between">
        <div className="left-header d-flex align-items-center">
          <AppsIcon onClick={() => toggleIsOpenAppLevelNav()} />
          <a
            className="tgs-logo ml-5"
            href="https://www.tgs.com/products-services/well-data"
            target="_blank"
            rel="noreferrer"
          >
            <TGSLogo />
          </a>
          <Divider variant="middle" orientation="vertical" />
          <p className="arlas-cs-title">Well Data Analytics</p>
        </div>

        <div className="right-header d-flex">
          {config.isUoMEnabled && <UoMSetting />}

          <Informations />
          <ProfileSettings />
        </div>
      </div>
    </header>
  );
};

export default withRouter(AppHeader);
