import { useCallback, useMemo } from "react";

import { Annotations, PlotData } from "plotly.js";

import {
  AnnotationsAlignment,
  AnnotationsXAnchor,
  AnnotationsYAnchor,
  GunBarrelData,
  GunBarrelFormations,
  GunBarrelInfo,
  GunBarrelRange,
  GunBarrelTargetWell,
} from "../../types/panels/wellPanel/gunBarrelType";

import {
  MAX_TARGET_FORMATION_DISTANCE,
  X_AXIS,
  Y_AXIS_PADDING,
} from "../../constants/charts/gunBarrelAxis";
import {
  childMarkerConfig,
  formationAnnotationConfig,
  formationConfig,
  parentMarkerConfig,
  siblingMarkerConfig,
  targetMarkerConfig,
} from "../../constants/charts/gunBarrelTraceConfigs";

import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useUnitValueConversion from "../common/useUnitValueConversion";
import useGunBarrelCalculations from "./useGunBarrelCalculations";
import useGunBarrelTraceUtils from "./useGunBarrelTraceUtils";

const useGunBarrelUtils = () => {
  const { isMetricOnSearch } = useUnitOfMeasure();
  const { convertFtValueToMeter } = useUnitValueConversion();
  const { compare, getCoordinates, getFormationDistanceFromTargetWell } =
    useGunBarrelCalculations();
  const { createAnnotation, createHoverTemplate, createTrace } =
    useGunBarrelTraceUtils();

  const computedMaxFormationDistance = useMemo(() => {
    return isMetricOnSearch
      ? convertFtValueToMeter(MAX_TARGET_FORMATION_DISTANCE)
      : MAX_TARGET_FORMATION_DISTANCE;
  }, [isMetricOnSearch]);

  const computedYAxisPadding = useMemo(() => {
    return isMetricOnSearch
      ? convertFtValueToMeter(Y_AXIS_PADDING)
      : Y_AXIS_PADDING;
  }, [isMetricOnSearch]);

  const computedOffSetVal = useCallback(
    (val: number) => {
      return isMetricOnSearch ? convertFtValueToMeter(val) : val;
    },
    [isMetricOnSearch]
  );

  const computedXAxis: GunBarrelRange = useMemo(() => {
    return isMetricOnSearch
      ? {
          range: X_AXIS.range.map((val) => convertFtValueToMeter(val)),
        }
      : X_AXIS;
  }, [isMetricOnSearch]);

  const getFormationY = useCallback(
    (targetWell: GunBarrelTargetWell, f: GunBarrelFormations, yMin: number) => {
      if (targetWell === undefined) {
        return f.y;
      }
      return getFormationDistanceFromTargetWell(f.y, targetWell.y) <
        computedMaxFormationDistance
        ? f.y
        : yMin;
    },
    [isMetricOnSearch]
  );

  const getFormationAnnotations = (
    formations: GunBarrelFormations[],
    targetWell: GunBarrelTargetWell,
    xMax: number,
    yMin: number
  ) => {
    return [
      ...formations.map((f) =>
        createAnnotation(
          f.name,
          {
            x: xMax,
            y: getFormationY(targetWell, f, yMin),
          },
          formationAnnotationConfig
        )
      ),
    ];
  };
  const createFormationsTraces = (
    formations: GunBarrelFormations[],
    xMin: number,
    xMax: number
  ) => {
    return formations.map((f) =>
      createTrace(
        {
          x: [xMin, xMax],
          y: [f.y, f.y],
        },
        formationConfig
      )
    );
  };
  const getFormationTraces = (
    data: GunBarrelInfo,
    xMin: number,
    xMax: number
  ) => {
    const formations = data.formations;

    //handle removing last formation if < -500ft
    if (formations.length !== 0) {
      const length = formations.length - 1;
      const offset = formations[length].yoffset;

      if (offset <= -computedMaxFormationDistance) {
        return createFormationsTraces(formations.slice(0, length), xMin, xMax);
      }
    }
    return createFormationsTraces(formations, xMin, xMax);
  };

  const getWellTraces = (data: GunBarrelInfo) => {
    const targetWell = [
      createTrace(
        {
          y: [data.targetWell.y],
          text: createHoverTemplate([data.targetWell]),
        },
        targetMarkerConfig
      ),
    ];

    const children = [
      createTrace(
        {
          x: getCoordinates(data.children),
          y: getCoordinates(data.children, "y"),
          text: createHoverTemplate(data.children),
        },
        childMarkerConfig
      ),
    ];

    const siblings = [
      createTrace(
        {
          x: getCoordinates(data.siblings),
          y: getCoordinates(data.siblings, "y"),
          text: createHoverTemplate(data.siblings),
        },
        siblingMarkerConfig
      ),
    ];

    const parents = [
      createTrace(
        {
          x: getCoordinates(data.parents),
          y: getCoordinates(data.parents, "y"),
          text: createHoverTemplate(data.parents),
        },
        parentMarkerConfig
      ),
    ];

    return [...targetWell, ...siblings, ...children, ...parents];
  };

  const sortFormations = (data: GunBarrelInfo) => {
    let sortedFormations: GunBarrelFormations[] = [];

    //sort
    sortedFormations = data.formations.sort(compare);

    sortedFormations = sortedFormations.filter(
      (f) => f.yoffset < computedMaxFormationDistance
    );
    //slice
    //get all formations and first formation that is > 500 ft from target well
    const length = sortedFormations.length;
    let i = 0;

    while (i < length) {
      const offset = sortedFormations[i].yoffset;

      if (
        (offset <= computedOffSetVal(-410) &&
          offset >= computedOffSetVal(-500)) ||
        offset < -computedMaxFormationDistance
      ) {
        break;
      }

      i++;
    }

    return sortedFormations.slice(0, ++i);
  };

  const gunBarrelBuilder = (data: GunBarrelInfo) => {
    const formattedData: GunBarrelInfo = {
      children: data.children?.length ? data.children : [],
      formations: data.formations?.length ? data.formations : [],
      parents: data.parents?.length ? data.parents : [],
      siblings: data.siblings?.length ? data.siblings : [],
      targetWell: data.targetWell,
    };

    //consider case of multiple formations with > 500 ft from target well
    formattedData.formations = sortFormations(formattedData);

    return formattedData;
  };

  const createFormattedData = (
    formattedData: GunBarrelInfo,
    xAxis: GunBarrelRange,
    yAxis: GunBarrelRange,
    wellTraces: Partial<PlotData>[] = []
  ) => {
    const [xMin, xMax] = xAxis.range;
    const [yMin] = yAxis.range;

    const data: GunBarrelData = {
      originalData: formattedData,
      formationTraces: getFormationTraces(formattedData, xMin, xMax),
      wellTraces,
      annotations: getFormationAnnotations(
        formattedData.formations,
        formattedData.targetWell,
        xMax,
        yMin
      ),
      xRange: xAxis,
      yRange: yAxis,
    };

    return data;
  };

  const getCorrectFormationData = (formattedData: GunBarrelInfo) => {
    const targetWell = formattedData.targetWell;

    return createFormattedData(
      formattedData,
      computedXAxis,
      {
        range: [
          targetWell.y - computedYAxisPadding,
          targetWell.y + computedYAxisPadding,
        ],
      },
      getWellTraces(formattedData)
    );
  };

  //for initilization
  const initGunBarrel = (rawData: GunBarrelInfo) => {
    const formattedData = gunBarrelBuilder(rawData);

    return getCorrectFormationData(formattedData);
  };

  const updateMarkerSize = (traces: Partial<PlotData>[]) => {
    return traces.map((t: Partial<PlotData>) => ({
      ...t,
      marker: {
        ...t.marker,
        size: 7,
      },
    }));
  };

  const updateAnnotationFontSize = (annotations: Partial<Annotations>[]) => {
    const align: AnnotationsAlignment = "right";
    const xanchor: AnnotationsXAnchor = "right";
    const yanchor: AnnotationsYAnchor = "middle";

    return annotations.map((a: Partial<Annotations>) => ({
      ...a,
      font: {
        ...a.font,
        // size: "medium",
      },
      yshift: -9,
      borderpad: 0,
      align,
      xanchor,
      yanchor,
    }));
  };

  const updateGunBarrelStyles = (data: GunBarrelData) => {
    const { wellTraces } = data;

    const updatedStyle: GunBarrelData = {
      ...data,
      annotations: updateAnnotationFontSize(data.annotations),
      wellTraces: wellTraces.length
        ? [
            {
              ...wellTraces[0],
              marker: {
                ...wellTraces[0].marker,
                size: 12,
              },
            },
            ...updateMarkerSize(wellTraces.slice(1)),
          ]
        : [],
    };
    return updatedStyle;
  };

  return {
    initGunBarrel,

    //resizing
    updateGunBarrelStyles,
  };
};

export default useGunBarrelUtils;
