import {
  LayerByBasinMapType,
  StratigraphicModelsInfo,
} from "../../types/map/mapSettings/store";

import * as LAYERS from "../constants";

export const STRATIGRAPHIC_MODELS_BASINS = {
  PERMIAN_BASIN: "Permian Basin",
  EAGLE_FORD_BASIN: "Eagle Ford Basin",
  ANADARKO_BASIN: "Anadarko Basin",
} as const;

export const STRATIGRAPHIC_MODELS_MAP_TYPES = {
  STRUCTURE_MAP: "Structure Map",
  ISOPACH_MAP: "Isopach Map",
} as const;

export const PERMIAN_BASIN_STRUCTURE_MAP_FORMATIONS = [
  LAYERS.GPB_01_RUSTLER,
  LAYERS.GPB_02_SALADO,
  LAYERS.GPB_03A_CHERRY_CANYON_QUEEN,
  LAYERS.GPB_03_BELL_CANYON_TANSIL,
  LAYERS.GPB_04A_GLORIETA,
  LAYERS.GPB_04_BRUSHY_CANYON_SAN_ANDRES,
  LAYERS.GPB_05_TOP_BS_CLEARFORK,
  LAYERS.GPB_06A1_MIDDLE_SPRABERRY,
  LAYERS.GPB_06A2_2ND_BONE_SPRING_LIME,
  LAYERS.GPB_06B1_LOWER_SPRABERRY,
  LAYERS.GPB_06B2_2ND_BONE_SPRING_SAND,
  LAYERS.GPB_06C0_LOWER_SPRABERRY_SHALE,
  LAYERS.GPB_06C1_3RD_BONE_SPRING_LIME,
  LAYERS.GPB_06C2_3RD_BONE_SPRING_LIME_SANDSTONE_TOP,
  LAYERS.GPB_06C3_3RD_BONE_SPRING_LIME_SANDSTONE_BASE,
  LAYERS.GPB_06D_3RD_BONE_SPRING_SAND,
  LAYERS.GPB_06D_DEAN_SAND,
  LAYERS.GPB_06_1ST_BS_SAND_WICHITA_SPRABERRY,
  LAYERS.GPB_07A1_WOLFCAMP_X,
  LAYERS.GPB_07A2_WOLFCAMP_Y,
  LAYERS.GPB_07A3_WOLFCAMP_SHALE,
  LAYERS.GPB_07B1_MID_WOLFCAMP_B,
  LAYERS.GPB_07B2_DEL_WOLFCAMP_B,
  LAYERS.GPB_07C_INTRA_WOLFCAMP_C,
  LAYERS.GPB_07_TOP_WOLFCAMP,
  LAYERS.GPB_08_CISCO_WOLFCAMP_D,
  LAYERS.GPB_09_STRAWN,
  LAYERS.GPB_10_BARNETT,
  LAYERS.GPB_11_MISSISSIPPIAN_LIMESTONE,
  LAYERS.GPB_12_WOODFORD_SHALE,
  LAYERS.GPB_13_DEVONIAN_CARBONATE,
  LAYERS.GPB_14_SILURIAN_SHALE,
  LAYERS.GPB_15_FUSSLEMAN,
  LAYERS.GPB_16_SIMPSON,
  LAYERS.GPB_17_ELLENBURGER,
  LAYERS.GPB_18_BASEMENT,
  LAYERS.MID_15A_MONTOYA,
];

export const PERMIAN_BASIN_ISOPACH_MAP_FORMATIONS = [
  LAYERS.FIRST_ST_BONE_SPRINGS_ISOPACH,
  LAYERS.SECOND_BONE_SPRINGS_ISOPACH,
  LAYERS.THIRD_BONE_SPRINGS_ISOPACH,
  LAYERS.WOLFCAMPX_ISOPACH,
  LAYERS.UPPERSPRABERRY_ISOPACH,
  LAYERS.MIDDLESPRABERRY_ISOPACH,
  LAYERS.LOWERSPRABERRY_ISOPACH,
  LAYERS.SANANDRES_ISOPACH,
  LAYERS.WOLFCAMPY_ISOPACH,
];

export const EAGLE_FORD_STRUCTURE_MAP_FORMATIONS = [
  LAYERS.EAGLE_FORD_02_VICKSBURG_GROUP_GRID,
  LAYERS.EAGLE_FORD_03_CLAIBORNE_GROUP_GRID,
  LAYERS.EAGLE_FORD_03B_LOWER_CLAIBORNE_GRID,
  LAYERS.EAGLE_FORD_04_UPPER_WILCOX_GRID,
  LAYERS.EAGLE_FORD_04A_MIDDLE_WILCOX_GRID,
  LAYERS.EAGLE_FORD_04B_LOWER_WILCOX_GRID,
  LAYERS.EAGLE_FORD_05_MIDWAY_GROUP_GRID,
  LAYERS.EAGLE_FORD_06_NAVARRO_GROUP_GRID,
  LAYERS.EAGLE_FORD_07_TAYLOR_GROUP_GRID,
  LAYERS.EAGLE_FORD_07A_ANACACHO_GRID,
  LAYERS.EAGLE_FORD_08_AUSTIN_CHALK_GRID,
  LAYERS.EAGLE_FORD_09_UPPER_EAGLE_FORD_GRID,
  LAYERS.EAGLE_FORD_10_LOWER_EAGLE_FORD_GRID,
  LAYERS.EAGLE_FORD_10A_WOODBINE_GRID,
  LAYERS.EAGLE_FORD_10B_MANESS_GRID,
  LAYERS.EAGLE_FORD_11_BUDA_GRID,
  LAYERS.EAGLE_FORD_11A_DEL_RIO_GRID,
  LAYERS.EAGLE_FORD_11B_GEORGETOWN_GRID,
  LAYERS.EAGLE_FORD_11C_EDWARDS_GRID,
  LAYERS.EAGLE_FORD_12_GLEN_ROSE_GRID,
  LAYERS.EAGLE_FORD_13_PEARSAL_GRID,
  LAYERS.EAGLE_FORD_14_SLIGO_PETTET_GRID,
];

export const ANADARKO_STRUCTURE_MAP_FORMATIONS = [
  LAYERS.ANADARKO_01_STONE_CORRAL_STRUCTURE,
  LAYERS.ANADARKO_02_TOP_HUTCHINSON_STRUCTURE,
  LAYERS.ANADARKO_03_BASE_HUTCHINSON_STRUCTURE,
  LAYERS.ANADARKO_04_CHESTER_STRUCTURE,
  LAYERS.ANADARKO_05_WABAUNSEE_STRUCTURE,
  LAYERS.ANADARKO_06_HEEBNER_SHALE_STRUCTURE,
  LAYERS.ANADARKO_07_LANSING_STRUCTURE,
  LAYERS.ANADARKO_08_HOXBAR_KANSAS_CITY_STRUCTURE,
  LAYERS.ANADARKO_09_MARMATON_STRUCTURE,
  LAYERS.ANADARKO_10_CHEROKEE_STRUCTURE,
  LAYERS.ANADARKO_11_ATOKA_STRUCTURE,
  LAYERS.ANADARKO_12_TOP_EROSION_STRUCTURE,
  LAYERS.ANADARKO_13_SPRINGER_STRUCTURE,
  LAYERS.ANADARKO_14_CHESTER_LIME_STRUCTURE,
  LAYERS.ANADARKO_15_MISSISSIPPI_LIME_STRUCTURE,
  LAYERS.ANADARKO_16_WOODFORD_STRUCTURE,
  LAYERS.ANADARKO_17_HOSSTON_STRUCTURE,
  LAYERS.ANADARKO_18_SYLVAN_SHALE_STRUCTURE,
  LAYERS.ANADARKO_19_VIOLA_STRUCTURE,
  LAYERS.ANADARKO_20_SIMPSON_STRUCTURE,
  LAYERS.ANADARKO_21_ARBUCKLE_STRUCTURE,
];

// The constants below should be updated together
// when adding new basin or map types
export const DEFAULT_FORMATION_BY_BASIN_MAP_TYPE: LayerByBasinMapType = {
  [STRATIGRAPHIC_MODELS_BASINS.PERMIAN_BASIN]: {
    [STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP]:
      PERMIAN_BASIN_STRUCTURE_MAP_FORMATIONS[0],
    [STRATIGRAPHIC_MODELS_MAP_TYPES.ISOPACH_MAP]:
      PERMIAN_BASIN_ISOPACH_MAP_FORMATIONS[0],
  },
  [STRATIGRAPHIC_MODELS_BASINS.EAGLE_FORD_BASIN]: {
    [STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP]:
      EAGLE_FORD_STRUCTURE_MAP_FORMATIONS[0],
  },
  [STRATIGRAPHIC_MODELS_BASINS.ANADARKO_BASIN]: {
    [STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP]:
      ANADARKO_STRUCTURE_MAP_FORMATIONS[0],
  },
};

export const DEFAULT_STRAT_MODELS_BASINS_INFO: StratigraphicModelsInfo["basins"] =
  {
    [STRATIGRAPHIC_MODELS_BASINS.PERMIAN_BASIN]: {
      isSelected: false,
      mapType: STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP,
      formationName: PERMIAN_BASIN_STRUCTURE_MAP_FORMATIONS[0],
    },
    [STRATIGRAPHIC_MODELS_BASINS.EAGLE_FORD_BASIN]: {
      isSelected: false,
      mapType: STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP,
      formationName: EAGLE_FORD_STRUCTURE_MAP_FORMATIONS[0],
    },
    [STRATIGRAPHIC_MODELS_BASINS.ANADARKO_BASIN]: {
      isSelected: false,
      mapType: STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP,
      formationName: ANADARKO_STRUCTURE_MAP_FORMATIONS[0],
    },
  };

export const STRATIGRAPHIC_MODELS = [
  {
    basinName: STRATIGRAPHIC_MODELS_BASINS.PERMIAN_BASIN,
    mapTypesInfo: [
      {
        mapType: STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP,
        formations: PERMIAN_BASIN_STRUCTURE_MAP_FORMATIONS,
      },
      {
        mapType: STRATIGRAPHIC_MODELS_MAP_TYPES.ISOPACH_MAP,
        formations: PERMIAN_BASIN_ISOPACH_MAP_FORMATIONS,
      },
    ],
  },
  {
    basinName: STRATIGRAPHIC_MODELS_BASINS.EAGLE_FORD_BASIN,
    mapTypesInfo: [
      {
        mapType: STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP,
        formations: EAGLE_FORD_STRUCTURE_MAP_FORMATIONS,
      },
    ],
  },
  {
    basinName: STRATIGRAPHIC_MODELS_BASINS.ANADARKO_BASIN,
    mapTypesInfo: [
      {
        mapType: STRATIGRAPHIC_MODELS_MAP_TYPES.STRUCTURE_MAP,
        formations: ANADARKO_STRUCTURE_MAP_FORMATIONS,
      },
    ],
  },
];
