import { Dispatch, SetStateAction } from "react";

import {
  GunBarrelInfo,
  GunBarrelLayout,
  GunBarrelOriginalData,
} from "../../types/panels/wellPanel/gunBarrelType";

import { buildGunBarrelLayout } from "../../utils/charts/layouts/GunBarrelLayout";

import useAttributeUnit from "../common/useAttributeUnit";
import useUnitOfMeasure from "../common/useUnitOfMeasure";
import useGunBarrelUtils from "./useGunBarrelUtils";

export const useLoadGunBarrel = () => {
  const { initGunBarrel, updateGunBarrelStyles } = useGunBarrelUtils();
  const { isMetricOnSearch } = useUnitOfMeasure();
  const { convertFtToMeter } = useAttributeUnit();

  const updateGunbarrelChart = (
    apiContent: GunBarrelInfo | undefined,
    setState: Dispatch<SetStateAction<GunBarrelLayout>>,
    originalData: GunBarrelOriginalData,
    isChartExport: boolean
  ) => {
    if (apiContent && Object.keys(apiContent).length > 0) {
      const gunBarrelData = updateGunBarrelStyles(initGunBarrel(apiContent));

      //For Gun Barrel Export Props
      const gunBarrelLayoutProps = isChartExport
        ? [700, 450, 16]
        : [400, 280, 12];

      const gunBarreLayout = buildGunBarrelLayout(
        gunBarrelLayoutProps[0],
        gunBarrelLayoutProps[1],
        gunBarrelLayoutProps[2],
        gunBarrelData,
        isChartExport,
        convertFtToMeter(isMetricOnSearch)
      );

      setState((state) => ({
        ...state,
        ...{
          data: [...gunBarrelData.formationTraces, ...gunBarrelData.wellTraces],
          layout: gunBarreLayout,
          revision: state.revision + 1,
        },
      }));
      originalData.data.current = gunBarrelData;
      originalData.layout.current = gunBarreLayout;
    }
  };

  return {
    updateGunbarrelChart,
  };
};
