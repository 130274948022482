import { useMemo } from "react";

import {
  MapLayer,
  WellPermitPanelSection,
  WellSystemData,
} from "../../../../types/panels/wellPanel/wellPanel";

import {
  WELL_PANEL_FLAGS,
  WELL_PANEL_TAB,
} from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import CustomTabPanel from "../../../common/CustomTabPanel";
import GunBarrel from "../sections/GunBarrel";
import ProductionPlot from "../sections/ProductionPlot";

interface ChartsAndPlotsTabProps {
  hidden: boolean;
  wellSystem: WellSystemData;
  activePage: number;
  groupedWellID: number;
  layer: MapLayer;
  uwi: string;
  productionExport: boolean;
}
const ChartsAndPlotsTab = ({
  hidden,
  wellSystem,
  activePage,
  groupedWellID,
  layer,
  uwi,
}: ChartsAndPlotsTabProps) => {
  const updateWellPanelActiveSectionByKey = usePanelsStore(
    (state) => state.updateWellPanelActiveSectionByKey
  );

  const handleOnChange = (section: WellPermitPanelSection) => {
    updateWellPanelActiveSectionByKey(
      layer,
      groupedWellID,
      wellSystem.wellId,
      section
    );
  };

  const section = useMemo(() => {
    return {
      hasProduction: Boolean(
        wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_PRODUCTION.key]
      ),
      hasGunBarrel: Boolean(
        wellSystem.wellCardData[WELL_PANEL_FLAGS.HAS_GUN_BARREL.key]
      ),
    };
  }, [wellSystem]);

  return (
    <CustomTabPanel hidden={hidden} name={WELL_PANEL_TAB.PLOTS_CHARTS_TAB}>
      {section.hasProduction && (
        <ProductionPlot
          wellData={wellSystem}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          isExpanded={wellSystem.pageSectionStatus.isProductionPlotSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
      {section.hasGunBarrel && (
        <GunBarrel
          uwi={uwi}
          chartExport={false}
          wellSystem={wellSystem}
          activePage={activePage}
          layer={layer}
          groupedWellID={groupedWellID}
          isExpanded={wellSystem.pageSectionStatus.isGunBarrelSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
    </CustomTabPanel>
  );
};

export default ChartsAndPlotsTab;
