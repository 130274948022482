import { Annotations, PlotData, PlotType } from "plotly.js";

import {
  childMarkerStyle,
  formationStyle,
  parentsMarkerStyle,
  siblingMarkerStyle,
  targetMarkerStyle,
} from "./gunBarrelTraceStyles";

const showlegend = true;
const COMMON_MARKER_CONFIG = {
  type: "scatter" as PlotType,
};

const targetMarkerConfig: Partial<PlotData> = {
  marker: targetMarkerStyle,
  name: "Target Well",
  mode: "markers",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const parentMarkerConfig: Partial<PlotData> = {
  marker: parentsMarkerStyle,
  mode: "markers",
  name: "Parent Wells",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const childMarkerConfig: Partial<PlotData> = {
  marker: childMarkerStyle,
  mode: "markers",
  name: "Child Wells",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const siblingMarkerConfig: Partial<PlotData> = {
  marker: siblingMarkerStyle,
  mode: "markers",
  name: "Sibling Wells",
  showlegend,
  ...COMMON_MARKER_CONFIG,
};

const formationConfig: Partial<PlotData> = {
  marker: formationStyle,
  type: "lines" as PlotType,
  mode: "lines",
  showlegend: !showlegend,
  hoverinfo: "skip",
};

const formationAnnotationConfig: Partial<Annotations> = {
  font: {
    color: "rgba(255, 255, 255, 0.7)",
    size: 12, //will be updated in hook
  },
  showarrow: false,
  xanchor: "right",
  yshift: -10, //will be updated in hook
};

export {
  targetMarkerConfig,
  parentMarkerConfig,
  childMarkerConfig,
  siblingMarkerConfig,
  formationConfig,
  formationAnnotationConfig,
};
