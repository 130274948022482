const OutlinedCloseIcon = () => {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.06238 10.5L-0.00012207 9.4375L3.93738 5.5L-0.00012207 1.5625L1.06238 0.5L4.99988 4.4375L8.93738 0.5L9.99988 1.5625L6.06238 5.5L9.99988 9.4375L8.93738 10.5L4.99988 6.5625L1.06238 10.5Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  );
};

export default OutlinedCloseIcon;
