import { useMemo } from "react";

import useMapStore from "../../store/map/mapStore";

const useMap = () => {
  const map = useMapStore((state) => state.map);

  const hasMap = useMemo(() => !!map, [map]);

  return {
    hasMap,
  };
};

export default useMap;
