import { useEffect, useMemo } from "react";

import { ProcessColors } from "../../../../types/map/mapSettings/layerColoring";

import {
  BOTTOM_WELL_SPOTS,
  WELL_PATH_AND_STICKS,
} from "../../../../constants/constants";
import * as mapSettings from "../../../../constants/map/mapSettings";

import useMapStore from "../../../../store/map/mapStore";
import useMapSettingsStore from "../../../../store/map/settings/mapSettingsStore";

import { isLayerSelected } from "../../../../utils/map/mapSettings";

import useWellSpotStyling from "../useWellSpotStyling";

const useProcessBottomColors = ({
  searchCriteria,
  initialGridSearchMade,
  shouldApplyColorToLayer,
}: ProcessColors) => {
  const layers = useMapStore((state) => state.layers);
  const compressedBottomData = useMapSettingsStore(
    (state) => state.compressedBottomData
  );
  const updateBottomDataInfo = useMapSettingsStore(
    (state) => state.updateBottomDataInfo
  );
  const layerColorsLoading = useMapSettingsStore(
    (state) => state.layerColorsLoading
  );
  const updateLayerColorsLoading = useMapSettingsStore(
    (state) => state.updateLayerColorsLoading
  );

  const {
    isLoading: isBottomValuesLoading,
    data: bottomValues,
    getWellSpotAttribValues: getBottomValues,
  } = useWellSpotStyling();

  const isBottomOrWellPathSelected = useMemo(
    () =>
      isLayerSelected(BOTTOM_WELL_SPOTS, layers) ||
      isLayerSelected(WELL_PATH_AND_STICKS, layers),
    [layers]
  );

  useEffect(() => {
    if (isBottomValuesLoading || !initialGridSearchMade) return;

    updateBottomDataInfo(bottomValues);
    updateLayerColorsLoading(BOTTOM_WELL_SPOTS, false);
  }, [isBottomValuesLoading, bottomValues]);

  const callBottomStylingApi = (attributeKey: string) => {
    if (
      isBottomOrWellPathSelected &&
      shouldApplyColorToLayer(BOTTOM_WELL_SPOTS, attributeKey)
    ) {
      updateLayerColorsLoading(BOTTOM_WELL_SPOTS, true);
      getBottomValues({
        attribute: attributeKey,
        searchCriteria,
        layer: mapSettings.STYLING_API_KEY_MAPPER[BOTTOM_WELL_SPOTS].request,
      });
    } else {
      updateBottomDataInfo([]);
    }
  };

  return {
    isBottomOrWellPathSelected,
    isBottomColorsLoading: layerColorsLoading[BOTTOM_WELL_SPOTS],
    compressedBottomData,
    callBottomStylingApi,
  };
};

export default useProcessBottomColors;
