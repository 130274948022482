import { FC } from "react";

import { TextField, TextFieldProps } from "@mui/material";

interface MultipleTextFieldProps {
  textFields: TextFieldProps[];
}

const MultipleTextFields: FC<MultipleTextFieldProps> = (props) => {
  const { textFields } = props;

  return (
    <>
      {textFields?.length > 0 &&
        textFields.map(({ onChange, label, value, ...rest }) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <TextField
              fullWidth
              className={"filter-number-value-input"}
              variant="standard"
              label={label ?? "Value"}
              value={value ?? ""}
              onChange={(event) => {
                onChange?.(event);
              }}
              {...rest}
            />
          );
        })}
    </>
  );
};

export default MultipleTextFields;
