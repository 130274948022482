import { ChangeEvent, FC, useState } from "react";

import { Tab, Tabs, styled } from "@mui/material";

import { CircularButton } from "component-library";

import useReleaseNotesStore from "../../store/releaseNotes/releaseNotesStore";

import ReleaseNotesAccordion from "./ReleaseNotes/ReleaseNotesAccordion";
import TabPanel from "./ReleaseNotes/ReleaseNotesTabPanel";
import WhatsNext from "./WhatsNext/WhatsNext";
import StyleOverrideThemeProvider from "./styleOverridesTheme";

const CloseIconContainer = styled("div")({
  position: "absolute",
  right: 16,
  zIndex: 9,
});

const LineSeperator = styled("div")({
  height: 1,
  width: "100%",
  background: "#fff",
  marginTop: -1,
});

const CustomTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    backgroundColor: "#42A5F5",
  },
});

const CustomTab = styled(Tab)({
  color: "#fff",
  fontSize: "13px !important",
  fontWeight: "500 !important",
  width: "162px !important",

  "&.Mui-selected": {
    color: "#42A5F5",
  },
});

const InformationTabs: FC = () => {
  const updateIsOpenReleaseNotes = useReleaseNotesStore(
    (state) => state.updateIsOpenReleaseNotes
  );

  const [activeTab, setActiveTab] = useState<number>(0);

  const handleTabChange = (_: ChangeEvent<object>, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <StyleOverrideThemeProvider>
      <CloseIconContainer>
        <CircularButton
          icon="close"
          className="release-notes-close-btn"
          onClick={() => updateIsOpenReleaseNotes(false)}
        />
      </CloseIconContainer>
      <CustomTabs value={activeTab} onChange={handleTabChange}>
        <CustomTab label="RELEASE NOTES" />
        <CustomTab label="WHAT'S NEXT" />
      </CustomTabs>
      <LineSeperator />
      <TabPanel activePanel={0} value={activeTab}>
        <ReleaseNotesAccordion />
      </TabPanel>
      <TabPanel activePanel={1} value={activeTab}>
        <WhatsNext />
      </TabPanel>
    </StyleOverrideThemeProvider>
  );
};

export default InformationTabs;
