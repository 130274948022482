import { FC } from "react";

import { WellSystemData } from "../../../types/panels/wellPanel/wellPanel";

import config from "../../../configs/appSettings";

import { WELL_PANEL_FLAGS } from "../../../constants/panels/wellPanel/wellInfo";

import WellPanelPagination from "./WellPanelPagination";
import WellEconomicsOutput from "./footer/WellEconomicsOutput";

interface WellPanelFooterProps {
  currentPage: number;
  totalPages: number;
  currentViewedWellPanel: WellSystemData;
  onPageChange: (page: number) => void;
}

const WellPanelFooter: FC<WellPanelFooterProps> = ({
  totalPages,
  currentPage,
  currentViewedWellPanel,
  onPageChange,
}) => {
  return (
    <div className="well-panel-footer">
      {config.isWellEconomicsEnabled &&
        currentViewedWellPanel.wellCardData[
          WELL_PANEL_FLAGS.HAS_WELL_ECONOMICS.key
        ] && <WellEconomicsOutput wellData={currentViewedWellPanel} />}
      <WellPanelPagination
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={(page: number) => {
          onPageChange(page);
        }}
      />
    </div>
  );
};

export default WellPanelFooter;
