import jwt_decode, { JwtPayload } from "jwt-decode";
import moment from "moment";

import {
  ExportMapLayerColumn,
  ExportMapLayerDetails,
  ExportMapLayerNames,
  ExportMapPayload,
  ExportMapTypes,
  UWIIdentifierType,
} from "../../types/exports/exportMap/exportMap";

import config from "../../configs/appSettings";

import { ATTRIBUTE_INFO_BY_KEY, RECORD_TYPE } from "../../constants/attributes";
import {
  BOTTOM_WELL_SPOTS,
  SURFACE_WELL_SPOTS,
  WELL_PATHS,
} from "../../constants/constants";
import {
  ADDITIONAL_EXPORT_SHAPEFILE_ALIAS,
  EXPORT_DATE_FORMAT,
  EXPORT_MAP_LAYERS,
} from "../../constants/export/exportMap";
import {
  EXPORT_FILE_GEO_DB,
  EXPORT_SHAPE_FILE,
} from "../../constants/export/exportNavbar";
import { ALL_GRID_PERMIT_COLUMNS } from "../../constants/grid";

import { formatCurrentDateTime } from "../helper";
import { convertJSONToStringWithSpaces } from "../helper2";

const getAliasDefaultFormat = (alias: string, maxChar?: number) => {
  let formattedAlias = alias.replace(
    /[\s`~!@#$%^&*()|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
    "_"
  );
  if (maxChar) {
    formattedAlias = formattedAlias.slice(0, maxChar);
  }
  return formattedAlias;
};

const getLayerColumnsForGeoDB = (
  columns: ExportMapLayerColumn[]
): ExportMapLayerColumn[] => {
  const formattedColumns = columns.map((c) => ({
    name: c.name,
    alias: getAliasDefaultFormat(c.alias),
  }));

  return formattedColumns;
};

const getLayerColumnsForShapefile = (
  columns: ExportMapLayerColumn[]
): ExportMapLayerColumn[] => {
  const formattedColumns = columns.map((c) => {
    const alias =
      ATTRIBUTE_INFO_BY_KEY[c.name]?.exportAlias ??
      ADDITIONAL_EXPORT_SHAPEFILE_ALIAS[c.name] ??
      c.alias;
    return {
      name: c.name,
      alias: getAliasDefaultFormat(alias, 10),
    };
  });

  return formattedColumns;
};

const getWellColumns = (columns: ExportMapLayerColumn[]) => {
  const exportWellColumns = [];
  // filter permit columns, record type and uwi
  const wellColumns = columns.filter(
    (c) =>
      ![...ALL_GRID_PERMIT_COLUMNS, RECORD_TYPE.key, "UWI"].includes(c.name)
  );

  // add uwi and geopoint
  exportWellColumns.push({ name: "UWI", alias: "UWI" });

  // Saving for reference:
  // GeoPoints are automatically included even if not included
  // exportWellColumns.push({
  //   name:
  //     layerName === SURFACE_WELL_SPOTS ? SURFACE_GEO_POINT : BOTTOM_GEO_POINT,
  //   alias: "GeoPoint",
  // });
  exportWellColumns.push(...wellColumns);
  return exportWellColumns;
};

const getLayerColumns = (
  exportMapType: ExportMapTypes,
  layerName: ExportMapLayerNames,
  columns: ExportMapLayerColumn[]
) => {
  let layerColumns: { name: string; alias: string }[] = [];
  if (layerName === WELL_PATHS) {
    layerColumns = [{ name: "UWI", alias: "UWI" }];
  } else if (
    layerName === SURFACE_WELL_SPOTS ||
    layerName === BOTTOM_WELL_SPOTS
  ) {
    const wellColumns = getWellColumns(columns);

    if (exportMapType === EXPORT_FILE_GEO_DB) {
      layerColumns = getLayerColumnsForGeoDB(wellColumns);
    } else if (exportMapType === EXPORT_SHAPE_FILE) {
      layerColumns = getLayerColumnsForShapefile(wellColumns);
    }
  }
  return layerColumns;
};

const getLayerIdentifiers = (
  exportMapType: ExportMapTypes,
  layers: ExportMapLayerNames[],
  columns: ExportMapLayerColumn[]
) => {
  const layerIdentifiers: ExportMapLayerDetails = {
    layerDetails: [],
  };

  layers.forEach((layerName) => {
    layerIdentifiers.layerDetails.push({
      searchQuery: null,
      columns: getLayerColumns(exportMapType, layerName, columns),
      layerName: EXPORT_MAP_LAYERS[layerName].apiKey,
    });
  });

  layerIdentifiers.layerDetails.push({
    env: config.exportMapEnv,
  });

  return convertJSONToStringWithSpaces(layerIdentifiers);
};

const getUserCredentials = () => {
  const oktaCredentials = JSON.parse(
    localStorage.getItem("okta-token-storage") || "{}"
  );
  const userCredentials = oktaCredentials.idToken;
  const userName = userCredentials.claims["name"];
  const userEmail =
    jwt_decode<JwtPayload>(oktaCredentials.accessToken.accessToken).sub ?? "";
  return { userName, userEmail };
};

const getCSTCurrentDate = () => {
  const cstDateStr = new Date().toLocaleString("en-US", {
    timeZone: "Canada/Central",
  });
  const cstDate = moment(new Date(cstDateStr));
  const formattedDate = cstDate.format(EXPORT_DATE_FORMAT);
  return `${formattedDate} CST`;
};

export const getExportMapPayload = (
  exportMapType: ExportMapTypes,
  layers: ExportMapLayerNames[],
  uwiIdentifier: string[],
  uwiIdentifierType: UWIIdentifierType,
  columns: ExportMapLayerColumn[]
): ExportMapPayload => {
  const { userName, userEmail } = getUserCredentials();

  return {
    CustomerName: userName,
    SourceSystemName: "WellDataAnalytics",
    IncrementalDateUtc: "",
    UserEmail: userEmail,
    PackageDescription: `TGS Well Data Download ${getCSTCurrentDate()}`,
    Deliveries: [
      {
        DeliveryFormatType: "Single",
        DeliveryType: "SignedUrl",
        PackagingSystem: "WDP_SAGA",
        PackagingSystemDescription: "TGS Well Data",
        DeliveryName: `TGS MapExport ${formatCurrentDateTime()}`,
        DeliveryDescription: null,
        Products: [
          {
            ...(exportMapType === EXPORT_FILE_GEO_DB
              ? ({ ProductType: "GeoDatabase", FileFormat: "gdb" } as const)
              : ({ ProductType: "GeoShapefile", FileFormat: "shp" } as const)),
            IdentifierType: "Json",
            Identifiers: [getLayerIdentifiers(exportMapType, layers, columns)],
          },
          {
            ProductType: "UWI",
            FileFormat: "UWI",
            IdentifierType: uwiIdentifierType,
            Identifiers: uwiIdentifier,
          },
        ],
      },
    ],
    NotificationEmails: [userEmail],
  };
};
