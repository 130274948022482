import { useEffect, useState } from "react";
import Plot from "react-plotly.js";

import { ExpandMore } from "@mui/icons-material";
import { Box, IconButton, Menu, MenuItem, Typography } from "@mui/material";

import { Data } from "plotly.js";

import { DashboardChartState } from "../../../types/charts/dashboardChartType/dashboardChartType";
import { IFullWidthStackedBarChartProps } from "../../../types/charts/dashboardChartType/fullWidthStackedBarChart";

import { CHART_DATA_STATE } from "../../../constants/charts/charts";
import { STACKED_BAR_ATTRIBUTES } from "../../../constants/charts/dashboardCharts";

import useChartStore from "../../../store/chart/chartStore";
import useDataGridStore from "../../../store/grid/dataGridStore";
import useMapSelectionStore from "../../../store/map/selection/mapSelectionStore";
import useUWISearchStore from "../../../store/search/uwiSearch/searchUWIStore";
import useStore from "../../../store/useStore";

import { useStackedBarChartData } from "../../../customHooks/charts/useStackedBarChartData";
import useSearchCriteria from "../../../customHooks/search/useSearchCriteria";

import { legendNameFormatter } from "../../../utils/charts/formatter";
import {
  defaultConfig,
  fontLayout,
  generalLayout,
  marginLayout,
} from "../../../utils/charts/layouts/FullWidthStackedBarLayout";
import { getNumberWithComma } from "../../../utils/formatters/numberFormatter";

import { stackedBarChartPalletes } from "../../../themes";
import LoadingBackDrop from "../../common/LoadingBackDrop";

const FullWidthStackedBarChart = ({
  chartData,
}: IFullWidthStackedBarChartProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openAttributeSelection = Boolean(anchorEl);
  const [data, setData] = useState<Data[]>([]);
  const {
    getStackedBarChartData,
    data: rawData,
    loading,
  } = useStackedBarChartData();
  const fetchedSavedSearchTrigger = useStore(
    (state) => state.fetchedSavedSearchTrigger
  );
  const selectedGridDataKeys = useMapSelectionStore(
    (state) => state.selectedGridDataKeys
  );
  const { hasSearchCriteria } = useSearchCriteria();

  const isLoadingSavedSearchFileUpload = useUWISearchStore(
    (state) => state.isLoadingSavedSearchFileUpload
  );

  const updateDashboardChartAttribute = useChartStore(
    (state) => state.updateDashboardChartAttribute
  );
  const searchCriteria = useDataGridStore((state) => state.searchCriteria);

  const fetchChartData = async (state: DashboardChartState) => {
    await getStackedBarChartData(state, chartData.chartType, chartData.groupBy);
  };

  useEffect(() => {
    if (!chartData || fetchedSavedSearchTrigger) {
      return;
    }

    if (selectedGridDataKeys.length) {
      fetchChartData(CHART_DATA_STATE.POST_SEARCH);
    } else {
      if (hasSearchCriteria) {
        fetchChartData(CHART_DATA_STATE.INTER_SEARCH);
      } else {
        fetchChartData(CHART_DATA_STATE.PRE_SEARCH);
      }
    }
  }, [
    chartData,
    chartData.groupBy,
    JSON.stringify(selectedGridDataKeys),
    searchCriteria,
    hasSearchCriteria,
    fetchedSavedSearchTrigger,
  ]);

  useEffect(() => {
    if (rawData?.length) {
      const stackedBars: Data[] = [];

      if (rawData.length > 6) {
        let othersValue = 0;
        let othersPercentage = 0;
        rawData.forEach((stack, index) => {
          if (stack.label && stack.value) {
            if (rawData.length > 6 && index > 4) {
              const rawDataLastIndex = rawData.length - 1;
              if (index === rawDataLastIndex) {
                othersValue += stack.value;
                othersPercentage += stack.percentageValue;

                const othersBarData: Data = {
                  x: [othersPercentage],
                  y: ["Well Count"],
                  name: "All Others",
                  type: "bar",
                  orientation: "h",
                  width: [0.6],
                  marker: {
                    color: stackedBarChartPalletes[5],
                    width: 1,
                  },
                  hovertemplate: `${getNumberWithComma(othersValue)}`,
                };
                stackedBars.push(othersBarData);
              } else {
                othersValue += stack.value;
                othersPercentage += stack.percentageValue;
              }
            } else {
              const bar: Data = {
                x: [stack.percentageValue],
                y: ["Well Count"],
                name: legendNameFormatter(stack.label, 12),
                type: "bar",
                orientation: "h",
                width: [0.6],
                marker: {
                  color: stackedBarChartPalletes[index],
                  width: 1,
                },
                hovertemplate:
                  `${getNumberWithComma(stack.value)}` +
                  `<extra>${stack.label}</extra>`,
              };

              stackedBars.push(bar);
            }
          }
        });
      } else {
        rawData.forEach((stack, index) => {
          if (stack.label && stack.value) {
            if (stack.label === "OTHERS") {
              //API returns a grouping for "OTHERS" to contain the rest of the values; this applies if chart state is at pre-search
              const othersBarData: Data = {
                x: [stack.percentageValue],
                y: ["Well Count"],
                name: "All Others",
                type: "bar",
                orientation: "h",
                width: [0.6],
                marker: {
                  color: stackedBarChartPalletes[index],
                  width: 1,
                },
                hovertemplate: `${getNumberWithComma(stack.value)}`,
              };
              stackedBars.push(othersBarData);
            } else {
              legendNameFormatter(stack.label, 15);
              const bar: Data = {
                x: [stack.percentageValue],
                y: ["Well Count"],
                name: legendNameFormatter(stack.label, 12),
                type: "bar",
                orientation: "h",
                width: [0.6],
                marker: {
                  color: stackedBarChartPalletes[index],
                  width: 1,
                },

                hovertemplate:
                  `${getNumberWithComma(stack.value)}` +
                  `<extra>${stack.label}</extra>`,
              };

              stackedBars.push(bar);
            }
          }
        });
      }

      setData(stackedBars);
    } else {
      setData([]);
    }
  }, [rawData]);

  const toggleAttributeList = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };

  const handleAttributeListClose = () => {
    setAnchorEl(null);
  };

  const onAttributeSelection = (attribute: string) => {
    updateDashboardChartAttribute(
      chartData.chartId,
      chartData.chartType,
      attribute
    );
    handleAttributeListClose();
  };

  return (
    <Box className="dashboard-chart-main">
      <div className="chart-header stacked-bar">
        <IconButton
          size="small"
          onClick={(e) => toggleAttributeList(e)}
          className="attr-list-button"
        >
          <ExpandMore fontSize="small" />
        </IconButton>
        <Typography fontSize="12px" className="chart-title">
          {chartData.title.toUpperCase()}
        </Typography>
        <Menu
          anchorEl={anchorEl}
          open={openAttributeSelection}
          onClose={() => handleAttributeListClose()}
          anchorOrigin={{ vertical: 40, horizontal: "left" }}
        >
          {STACKED_BAR_ATTRIBUTES.filter(
            (attr) => attr.attribute !== chartData.groupBy[0]
          ).map((attr, index) => (
            <MenuItem
              key={index}
              onClick={() => onAttributeSelection(attr.attribute)}
            >
              {attr.title}
            </MenuItem>
          ))}
        </Menu>
      </div>
      <div className="chart-container stacked-bar-chart">
        {!loading &&
        chartData !== undefined &&
        !isLoadingSavedSearchFileUpload ? (
          <Plot
            className="plot-chart"
            config={defaultConfig}
            data={data}
            layout={{
              dragmode: "zoom",
              barmode: "stack",
              ...generalLayout,
              margin: {
                ...marginLayout,
              },
              font: {
                ...fontLayout,
              },
              xaxis: {
                ticksuffix: "%",
                color: "#9E9E9E",
                dtick: 20,
                griddash: "dot",
                gridcolor: "#757575",
                ...(data.length === 0 && { range: [0, 100] }),
              },
              yaxis: {
                tickangle: 270,
                ticklen: 3,
                color: "#9E9E9E",
                showline: false,
                ...(data.length === 0 && { range: [0, 4] }),
              },
              legend: {
                orientation: "h",
                font: {
                  size: 12,
                  color: "#9E9E9E",
                },
                traceorder: "normal",
              },
            }}
            useResizeHandler
          />
        ) : (
          <LoadingBackDrop className={"chart-container loader"} isOpen />
        )}
      </div>
    </Box>
  );
};

export default FullWidthStackedBarChart;
