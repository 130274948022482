import { FC, SyntheticEvent } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";

import {
  ReleaseVersionsProps,
  ReleaseVersionsTypes,
} from "../../../../types/information/information";

import {
  AccordionContent,
  AccordionHeadingText,
  AccordionImage,
  AccordionSubContent,
  CustomTypography,
} from "../StyledAccordions";

import ScatterPlotAnalysisView from "../../../../assets/images/release-notes/ScatterPlotAnalysisView.png";
import ScatterPlotSettingsImprovements from "../../../../assets/images/release-notes/ScatterPlotSettingsImprovements.png";
import WellDocViewerLink from "../../../../assets/images/release-notes/WellDocViewerLink.png";
import WellStyling from "../../../../assets/images/release-notes/WellStyling.png";

const Version20240304: FC<ReleaseVersionsProps> = ({ expanded, onChange }) => {
  const handleChange =
    (panel: ReleaseVersionsTypes) =>
    (_: SyntheticEvent, isExpanded: boolean) => {
      onChange(isExpanded ? panel : "");
    };

  return (
    <Accordion
      expanded={expanded === "Version20240304"}
      onChange={handleChange("Version20240304")}
    >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <AccordionHeadingText sx={{ width: "80%", flexShrink: 0 }}>
          Version 2024.03.04
        </AccordionHeadingText>
        <AccordionHeadingText sx={{ color: "rgba(255, 255, 255, 0.70)" }}>
          03/04/2024
        </AccordionHeadingText>
      </AccordionSummary>
      <AccordionDetails>
        <CustomTypography>
          This release includes Adding Well Document Viewer, and some
          enhancements to well styling and scatter plots.
        </CustomTypography>
      </AccordionDetails>

      <AccordionContent>• Well Document Viewer Link </AccordionContent>
      <AccordionSubContent>
        • Users can now easily view available well document inventory for any
        selected well through the Well Panel feature. The integration of the
        Well Data Analytics and TGS R360 applications allows for streamlined
        workflows, making well file discovery fast and easy for the user.
      </AccordionSubContent>

      <AccordionImage src={WellDocViewerLink} width="100%" draggable={false} />
      <AccordionSubContent>
        • After selecting a well and navigating to the LOGS & DOCS tab within
        the well panel, the user can view well documents by clicking the DOC
        VIEWER icon. Doing this will re-direct the user to a separate browser
        window. The integration with R360 now makes it easy to review and access
        well document files while working in Well Data Analytics.
      </AccordionSubContent>

      <AccordionContent>• Scatter Plot Enhancements</AccordionContent>
      <AccordionSubContent>
        • Users can select up to five Y axis and edit the display name for each
        axis:
      </AccordionSubContent>
      <AccordionSubContent>
        <AccordionSubContent>
          ▪ Left Y Axis
          <br />▪ Right Y Axis
        </AccordionSubContent>
      </AccordionSubContent>
      <AccordionSubContent>
        • The Y-axis can be chosen between linear and logarithmic scales.
      </AccordionSubContent>

      <AccordionImage
        src={ScatterPlotSettingsImprovements}
        width="100%"
        draggable={false}
      />
      <AccordionSubContent>
        • Users can also do multivariate analysis by selecting{" "}
        <strong>Color by</strong> Attribute in addition to the Y-axis and X-axis
        attributes in the Scatter Plot setting window.
      </AccordionSubContent>

      <AccordionImage
        src={ScatterPlotAnalysisView}
        width="100%"
        draggable={false}
      />

      <AccordionContent>• Well Styling Improvements</AccordionContent>
      <AccordionSubContent>
        • After running a search, users have the option to change the color of
        well spots and well path from default color (Blue) to different colors
        using the list of attributes provided in the application for better map
        visualizations.
      </AccordionSubContent>
      <AccordionSubContent>
        • NOTE: The map must be at zoom level 12 to see bottom hole well spots
        and well paths.
      </AccordionSubContent>

      <AccordionImage src={WellStyling} width="100%" draggable={false} />
    </Accordion>
  );
};

export default Version20240304;
