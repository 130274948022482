import {
  SavedSearchRounded as SavedSearchRoundedIcon,
  Search as SearchIcon,
  ShareOutlined as ShareOutlinedIcon,
} from "@mui/icons-material";

import { SidebarNavigationMenu } from "../../types/panels/sidebar";

import { DashboardChartsIcon } from "../../components/common/icons";
import ExportPackageManagerIcon from "../../components/common/icons/ExportPackageManagerIcon";

export const SIDEBAR_MENU_KEYS = {
  DEFAULT_CHARTS: "default_charts",
  SEARCH: "search",
  SAVED_SEARCH: "savedsearch",
  EXPORT_PACKAGE_MANAGER: "export_package_manager",
  SHARE_SEARCH: "sharesearch",
  WELL_PANEL: "wellpanel",
} as const;

export const SIDEBAR_MENU: SidebarNavigationMenu[] = [
  {
    key: SIDEBAR_MENU_KEYS.DEFAULT_CHARTS,
    text: "DASHBOARD",
    icon: <DashboardChartsIcon />,
    style: { padding: "10px" },
  },
  {
    key: SIDEBAR_MENU_KEYS.SEARCH,
    text: "SEARCH",
    icon: <SearchIcon />,
    style: { padding: "15px" },
  },
  {
    key: SIDEBAR_MENU_KEYS.SAVED_SEARCH,
    text: "SAVED SEARCH",
    icon: <SavedSearchRoundedIcon />,
    style: { padding: "15px" },
  },
  {
    key: SIDEBAR_MENU_KEYS.EXPORT_PACKAGE_MANAGER,
    text: "EXPORT",
    icon: <ExportPackageManagerIcon />,
    style: { padding: "5px" },
    type: "accordion",
  },
  {
    key: SIDEBAR_MENU_KEYS.SHARE_SEARCH,
    text: "SHARE (COPY LINK)",
    icon: <ShareOutlinedIcon />,
    style: { padding: "12px" },
  },
];
