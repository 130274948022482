import { FC, useCallback, useEffect, useMemo, useState } from "react";

import { Button } from "@mui/material";

import {
  SortInfoState,
  SortToggleButtonProps,
} from "../../../../../../types/panels/searchPanel/queryBuilder/sortPerAttribute";

import {
  ATTRIBUTE_ALPHA_ASC,
  ATTRIBUTE_SORT_OPTIONS,
} from "../../../../../../constants/panels/searchPanel/queryBuilder/sortAttributes";

import useQueryBuilderStore from "../../../../../../store/search/queryBulder/queryBuilderStore";

const SortToggleButton: FC<SortToggleButtonProps> = ({
  attribute,
  applyNextSort,
}) => {
  const { text: defaultText, nextSort: defaultNextSort } =
    ATTRIBUTE_SORT_OPTIONS[ATTRIBUTE_ALPHA_ASC];

  const [sortInfo, setSortInfo] = useState<
    SortInfoState | Record<string, never>
  >({});

  const currentQBInfo = useQueryBuilderStore((state) => state.currentQBInfo);

  const onClick = useCallback(() => {
    const forPayload = {
      type: ATTRIBUTE_SORT_OPTIONS[sortInfo.nextSort].type,
      order: ATTRIBUTE_SORT_OPTIONS[sortInfo.nextSort].order,
    };
    applyNextSort(forPayload);
  }, [sortInfo, applyNextSort]);

  const applyDefaultState = () => {
    setSortInfo({ text: defaultText, nextSort: defaultNextSort });
  };

  const attributeSort = useMemo(
    () => currentQBInfo[attribute],
    [currentQBInfo, attribute]
  );

  useEffect(() => {
    if (attributeSort && attributeSort.sortKey) {
      const { text, nextSort } = ATTRIBUTE_SORT_OPTIONS[attributeSort.sortKey];

      setSortInfo({ text, nextSort });
      return;
    }

    applyDefaultState();
  }, [attributeSort]);

  return (
    <Button variant="text" className="toggle-sort-button" onClick={onClick}>
      {sortInfo.text ? sortInfo.text : ""}
    </Button>
  );
};

export default SortToggleButton;
