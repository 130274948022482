import { useEffect, useState } from "react";

import { Tooltip, Typography, styled } from "@mui/material";

import {
  COLOR_BY_ATTRIBUTE_INFO,
  UNIFORM_COLOR,
} from "../../../constants/map/mapSettings";

import useStore from "../../../store/useStore";

import useLayerStylingByRecordType from "../../../customHooks/map/mapSettings/useLayerStylingByRecordType";
import usePrevious from "../../../customHooks/usePrevious";

import SymbolList from "./SymbolList";

const SquareIcon = styled("span")(({ rgbArray }: { rgbArray: number[] }) => ({
  backgroundColor: `rgb(${rgbArray ? rgbArray.join(", ") : "0, 88, 161"})`,
  width: 20,
  height: 20,
  marginRight: 8,
  border: "1px solid #FFFFFF26",
  borderRadius: 4,
}));

const WellColorList = () => {
  const isWellColorsLoading = useStore((state) => state.isWellColorsLoading);
  const previousLoading = usePrevious(isWellColorsLoading);
  const [wellColorLabel, setWellColorLabel] = useState("Well Color");

  const { legendColorsByRecordType, layerStylesColorByRecordType } =
    useLayerStylingByRecordType();

  useEffect(() => {
    const attribute = layerStylesColorByRecordType;
    const defaultLabel = "Color";

    if (attribute === UNIFORM_COLOR.key) {
      setWellColorLabel(defaultLabel);
    } else {
      setWellColorLabel(
        `${defaultLabel} (By ${COLOR_BY_ATTRIBUTE_INFO[attribute].label})`
      );
    }
  }, [layerStylesColorByRecordType, isWellColorsLoading]);

  return (
    <div className="column">
      <Typography>Symbols</Typography>
      <SymbolList />
      {!!legendColorsByRecordType.length && (
        <>
          <Typography>{wellColorLabel}</Typography>
          <div className="list">
            {legendColorsByRecordType.map(({ value, label, key }) => (
              <div key={key} className="well-color-item legend-item">
                <div className="color-swatch">
                  <SquareIcon rgbArray={value} />
                </div>
                <Tooltip title={label}>
                  <Typography className="color-by-label">{label}</Typography>
                </Tooltip>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default WellColorList;
