import React, { useRef } from "react";

import { Upload as UploadIcon } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";

import { ButtonUploadProps } from "../../types/common/common";

const ButtonUpload: React.FC<ButtonUploadProps> = ({
  label,
  disabled = false,
  buttonIcon = null,
  allowedFileExtension = null,
  handleFileChange,
  buttonClickCallback = null,
  buttonClasses = null,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleButtonClick = () => {
    if (buttonClickCallback) buttonClickCallback();
    fileInputRef.current?.click();
  };

  return (
    <>
      <input
        id="upload-file"
        type="file"
        accept={allowedFileExtension || undefined} // Use undefined if allowedFileExtension is null}
        ref={fileInputRef}
        onChange={handleFileChange}
        onClick={(e) => ((e.target as HTMLInputElement).value = "")}
        hidden
      />
      <Button
        disabled={disabled}
        classes={buttonClasses || { root: "upload-button" }}
        startIcon={buttonIcon || <UploadIcon />}
        onClick={handleButtonClick}
        fullWidth
      >
        <Typography variant="h6">{label}</Typography>
      </Button>
    </>
  );
};

export default ButtonUpload;
