import { useMemo } from "react";

import { FormControlLabel } from "@mui/material";

import classNames from "classnames";

import * as mapSettings from "../../constants/map/mapSettings";
import { UNIFORM_COLOR } from "../../constants/map/mapSettings";

import useMapSettingsStore from "../../store/map/settings/mapSettingsStore";

import useLayerStylingByRecordType from "../../customHooks/map/mapSettings/useLayerStylingByRecordType";

import CustomCheckbox from "../common/CustomCheckbox";
import TooltipInfoIcon from "../common/icons/TooltipInfoIcon";
import { CustomTooltip } from "./CustomTooltip";

const ApplyWellColorToPermits = () => {
  const applyWellColorToPermits = useMapSettingsStore(
    (state) => state.applyWellColorToPermits
  );
  const toggleApplyWellColorToPermits = useMapSettingsStore(
    (state) => state.toggleApplyWellColorToPermits
  );

  const { layerStylesColorByRecordType, isColorBySharedAttribute } =
    useLayerStylingByRecordType();

  const isDisabled = useMemo(
    () =>
      layerStylesColorByRecordType === UNIFORM_COLOR.key ||
      !isColorBySharedAttribute(layerStylesColorByRecordType),
    [layerStylesColorByRecordType, isColorBySharedAttribute]
  );

  return (
    <FormControlLabel
      className="mapsetting-label apply-well-color-to-permits"
      control={
        <CustomCheckbox
          checked={applyWellColorToPermits}
          disabled={isDisabled}
          onChange={() => toggleApplyWellColorToPermits()}
        />
      }
      label={
        <div className="apply-well-color-to-permits-label">
          <span className={classNames({ "disabled-option": isDisabled })}>
            {mapSettings.WELL_STYLING_APPLY_COLOR_TO_PERMITS_CONTROL.label}
          </span>
          {isDisabled && (
            <CustomTooltip
              title="If turned off, the default permit style will be applied"
              placement="left"
              arrow
            >
              <span className="tooltip-info">
                <TooltipInfoIcon />
              </span>
            </CustomTooltip>
          )}
        </div>
      }
    />
  );
};

export default ApplyWellColorToPermits;
