import { create } from "zustand";

import { AppModeStates } from "../../types/common/appMode/appMode";

import { UoMSettingSlice } from "./UoMSettingSlice";

const useAppModeStore = create<AppModeStates>()((...a) => ({
  ...UoMSettingSlice(...a),
}));

export default useAppModeStore;
