import { useState } from "react";

import axios from "axios";

import { Test } from "../../../types/panels/wellPanel/testType";

import config from "../../../configs/appSettings";

import { TEST_SECTION } from "../../../constants/panels/wellPanel/wellInfo";

import useGetRequestParams from "../../common/useGetRequestParams";

const useWellPanelTest = () => {
  const [data, setData] = useState<Test[]>([]);
  const [success, setSuccess] = useState(true);
  const [error, setError] = useState(null);
  const { buildRequestQueryParam } = useGetRequestParams();

  const getWellTest = async (wellId: number, uwi: string) => {
    setSuccess(false);
    try {
      const response = await axios.get(
        `${config.endpoints.wellService}api/${TEST_SECTION.urlSlug}/${wellId}`,
        {
          ...buildRequestQueryParam,
        }
      );
      const { data } = response;

      if (data && data.length) {
        const dataWithUWI = data.map((test: any) => {
          test.uwi = uwi;
          return {
            ...test,
          };
        });

        setData(dataWithUWI);
        setSuccess(true);

        return dataWithUWI;
      }
    } catch (error: any) {
      console.debug("No WellCard Tests", error);
      setData([]);
      setSuccess(false);
      setError(error);

      return {
        status: "error",
        msg: error,
      };
    }
  };

  return { getWellTest, data, success, error };
};

export default useWellPanelTest;
