/**
 * @param {array} array of exisiting numbers, will be used for comparison
 * @returns {number} 5 digit unique number
 */
export const generate5DigitUniqueRandomNumber = (numbers = []) => {
  let newNum = Math.floor(Math.random() * 90000) + 10000;

  do {
    newNum = Math.floor(Math.random() * 90000) + 10000;
  } while (numbers.includes(newNum));

  return newNum;
};
