export const DRAW_LAYER = "Draw Layer";

export const TOPOGRAPHIC = {
  id: "Topographic",
  title: "Topographic",
  imgSrc: "https://js.arcgis.com/4.16/esri/images/basemap/topo-vector.jpg",
};

export const OCEANS = {
  id: "Oceans",
  title: "Oceans",
  imgSrc:
    "https://www.arcgis.com/sharing/rest/content/items/799805972b604b2fb6f77de892682008/info/thumbnail/india_oceans.jpg",
};

export const OSM = {
  id: "OSM",
  title: "OSM",
  imgSrc: "https://js.arcgis.com/4.16/esri/images/basemap/osm.jpg",
};

export const STREETS = {
  id: "Streets",
  title: "Streets",
  imgSrc: "https://js.arcgis.com/4.16/esri/images/basemap/streets.jpg",
};

export const LIGHT_GREY_CANVAS = {
  id: "LightGreyCanvas",
  title: "Light Grey Canvas",
  imgSrc: "https://js.arcgis.com/4.16/esri/images/basemap/gray-vector.jpg",
};

export const IMAGERY_WITH_LABELS = {
  id: "ImageryLabel",
  title: "Imagery with Labels",
  imgSrc: "https://js.arcgis.com/4.16/esri/images/basemap/hybrid.jpg",
};

export const HYBRID = {
  id: "Hybrid",
  title: "Hybrid",
  imgSrc: "https://js.arcgis.com/4.16/esri/images/basemap/hybrid.jpg",
};

export const DARK_GRAY = {
  id: "DarkGray",
  title: "DarkGray",
  imgSrc: "https://js.arcgis.com/4.16/esri/images/basemap/dark-gray.jpg",
};

export const NAT_GEO = {
  id: "NatGeo",
  title: "NatGeo",
  imgSrc:
    "https://js.arcgis.com/4.16/esri/images/basemap/national-geographic.jpg",
};

export const baseMapLayer = [
  TOPOGRAPHIC,
  OCEANS,
  // OSM,
  STREETS,
  LIGHT_GREY_CANVAS,
  IMAGERY_WITH_LABELS,
  HYBRID,
  DARK_GRAY,
  NAT_GEO,
];

export const PERMANENT_LAYERS = [DRAW_LAYER];
