import { Attribute } from "../../types/common/attributes";

import * as constants from "../attributes";

export const COLOR_BY_PERMIT_ATTRIBUTES: Attribute[] = [
  constants.PERMIT_APPROVAL_DATE,
  constants.PERMIT_POSTED_DATE,
  constants.PERMIT_PURPOSE,
  constants.PERMIT_TYPE,
  constants.PERMIT_TOTAL_DEPTH,
  constants.PERMIT_FORMATION_DEPTH,
];

export const COLOR_BY_WELLS_AND_PERMITS_ATTRIBUTES: Attribute[] = [
  constants.LEASE_NAME,
  constants.FIELD_NAME,
  constants.SLANT,
  constants.WELL_STATUS,
  constants.WELL_TYPE,
  constants.OPERATOR_NAME,
  constants.DISPLAY_FORMATION,
];
