import { StateCreator } from "zustand";

import {
  MapSettingsStates,
  WellBubbleMapSizeSlice,
  WellBubbleSize,
} from "../../../types/map/mapSettings/store";

import {
  BOTTOM_WELL_SPOTS,
  BUBBLE_MAP,
  WELL_SPOTS,
} from "../../../constants/constants";
import { defaultBubbleSizes } from "../../../constants/defaultMapSettingsStoreData";
import {
  COLOR_BY_ATTRIBUTE_INFO,
  SIZE,
  STYLING_API_KEY_MAPPER,
} from "../../../constants/map/mapSettings";

import { getDefaultSizeList } from "../../../components/charts/utils/mapSettingsUtils";

import {
  getCompressedDataInfo,
  transformWellStylingDataToObject,
} from "../../../utils/map/wellStyling";

import { clone } from "../../../utils";
import useDataGridStore from "../../grid/dataGridStore";
import useMapStore from "../mapStore";

const sliceResetFns = new Set<() => void>();

const resetWellBubbleMapSlice = () => {
  sliceResetFns.forEach((resetFn) => {
    resetFn();
  });
};

const initialWellBubbleMapStates = {
  bubbleSizes: clone(defaultBubbleSizes) as WellBubbleSize[],

  compressedSurfaceBubbleMapData: [],
  surfaceBubbleMapValues: { data: {}, min: null, max: null, hasNull: false },

  compressedBottomBubbleMapData: [],
  bottomBubbleMapValues: { data: {}, min: null, max: null, hasNull: false },

  bubbleMapPartitionMinMax: { min: null, max: null },
};

const wellBubbleMapSlice: StateCreator<
  MapSettingsStates,
  [],
  [],
  WellBubbleMapSizeSlice
> = (set) => {
  sliceResetFns.add(() => set(initialWellBubbleMapStates));
  return {
    ...initialWellBubbleMapStates,

    updateBubbleSizes: () =>
      set((state) => {
        const { min, max } = state.bubbleMapPartitionMinMax;
        const initialGridSearchMade =
          useDataGridStore.getState().initialGridSearchMade;
        const bubbleSizes = getDefaultSizeList(min, max, initialGridSearchMade);
        return {
          bubbleSizes,
        };
      }),
    updateWellStyleBubbleSize: (value) =>
      set((state) => ({
        layerStyles: {
          ...state.layerStyles,
          [BUBBLE_MAP]: {
            ...state.layerStyles[BUBBLE_MAP],
            [SIZE]: value,
          },
        },
      })),

    updateSurfaceBubbleMapDataInfo: (surface) =>
      set((state) => {
        const attributeKey = state.layerStyles[BUBBLE_MAP][SIZE] as string;
        const surfaceDataInfo = getCompressedDataInfo(
          surface,
          attributeKey,
          COLOR_BY_ATTRIBUTE_INFO
        );
        const surfaceByUwi = transformWellStylingDataToObject(
          surfaceDataInfo.data,
          [STYLING_API_KEY_MAPPER[WELL_SPOTS].dataKey],
          attributeKey
        );

        return {
          compressedSurfaceBubbleMapData: surface,
          surfaceBubbleMapValues: {
            ...surfaceDataInfo,
            data: surfaceByUwi,
          },
        };
      }),

    updateBottomBubbleMapDataInfo: (bottom) =>
      set((state) => {
        const attributeKey = state.layerStyles[BUBBLE_MAP][SIZE] as string;
        const bottomDataInfo = getCompressedDataInfo(
          bottom,
          attributeKey,
          COLOR_BY_ATTRIBUTE_INFO
        );
        const bottomByUwi = transformWellStylingDataToObject(
          bottomDataInfo.data,
          [STYLING_API_KEY_MAPPER[BOTTOM_WELL_SPOTS].dataKey],
          attributeKey
        );

        return {
          compressedBottomBubbleMapData: bottom,
          bottomBubbleMapValues: {
            ...bottomDataInfo,
            data: bottomByUwi,
          },
        };
      }),

    updateBubbleMapPartitionMinMax: () =>
      set((state) => {
        const isSurfaceSelected = useMapStore.getState().layers[0].isSelected;
        const isBottomSelected = useMapStore.getState().layers[1].isSelected;

        const { min: minSurface, max: maxSurface } =
          state.surfaceBubbleMapValues;
        const { min: minBottom, max: maxBottom } = state.bottomBubbleMapValues;

        let min = null;
        let max = null;
        if (isBottomSelected) {
          min = minBottom;
          max = maxBottom;
        } else if (isSurfaceSelected) {
          min = minSurface;
          max = maxSurface;
        }

        return {
          bubbleMapPartitionMinMax: { min, max },
        };
      }),
  };
};

export { resetWellBubbleMapSlice, wellBubbleMapSlice };
