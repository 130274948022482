import { noData } from "../../constants/constants";

export const useFieldFormatter = () => {
  const stringValidation = (data: string, showNoData = true) => {
    if (data) {
      return data;
    } else {
      return showNoData ? noData : "";
    }
  };

  return {
    stringValidation,
  };
};
