import { ReactElement, ReactNode, Ref, forwardRef } from "react";

import CloseIcon from "@mui/icons-material/Close";
import {
  Dialog,
  DialogContent,
  IconButton,
  Slide,
  Toolbar,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

interface FullScreenViewProps {
  open: boolean;
  handleClose?: () => void;
  showCloseIcon: boolean;
  header?: ReactNode;
  children: ReactNode;
  classname?: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

export const FullScreenView = ({
  open,
  handleClose,
  showCloseIcon,
  header,
  children,
  classname,
}: FullScreenViewProps) => {
  return (
    <Dialog
      className={`fullscreen-dialog ${classname}`}
      TransitionComponent={Transition}
      onClose={handleClose}
      open={open}
      fullScreen
    >
      {showCloseIcon && (
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Toolbar>
      )}

      {header && <>{header}</>}
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default FullScreenView;
