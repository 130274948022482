import { FC, PropsWithChildren, useMemo } from "react";

import { Button } from "@mui/material";

interface WellPanelSectionAccordionProps {
  onClick: (shouldExpandAll: boolean) => void;
  expandedAccordions: string[];
  totalItems: number;
}

const WellPanelSectionAccordion: FC<
  PropsWithChildren<WellPanelSectionAccordionProps>
> = ({ children, onClick, expandedAccordions, totalItems }) => {
  const shouldExpand: boolean = useMemo(() => {
    return Boolean(
      expandedAccordions.length < 2 && totalItems !== expandedAccordions.length
    );
  }, [expandedAccordions, totalItems]);

  return (
    <div className="well-panel-section-accordion-container">
      <Button
        disableRipple
        className="expand-all-button"
        onClick={() => onClick(shouldExpand)}
        variant="text"
      >
        {shouldExpand ? "EXPAND" : "COLLAPSE"} ALL
      </Button>

      {children}
    </div>
  );
};

export default WellPanelSectionAccordion;
