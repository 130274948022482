import { FC, MouseEvent, useCallback } from "react";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Badge, IconButton } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid-premium";

import { FilterHeaderIconProps } from "../../../types/grid";

const HeaderFilterIcon: FC<FilterHeaderIconProps> = (props) => {
  const { counter, field, onClick } = props;
  const apiRef = useGridApiContext();

  const toggleFilter = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      const { open, field: columnField } = apiRef.current.state.columnMenu;

      if (open && columnField === field) {
        apiRef.current.hideColumnMenu();
      } else {
        apiRef.current.showColumnMenu(field);
      }

      if (onClick) {
        onClick(apiRef.current.getColumnHeaderParams(field), event);
      }
    },
    [apiRef, field, onClick]
  );

  if (!counter) {
    return null;
  }

  const CustomIconButton = () => {
    return (
      <IconButton
        onClick={toggleFilter}
        color="default"
        size="small"
        tabIndex={-1}
      >
        <FilterAltIcon fontSize="small" />
      </IconButton>
    );
  };

  return (
    <>
      {counter > 1 ? (
        <Badge color={"default"}>
          <CustomIconButton />
        </Badge>
      ) : (
        counter === 1 && <CustomIconButton />
      )}
    </>
  );
};

export default HeaderFilterIcon;
