import { ChartType } from "../../types/charts/chartType/chartType";

//Plotly Tick date formatting
export const mmmYYYYTickFormat = "%b %Y";
export const YYYYTickFormat = "%Y";

//CHART TYPES
export const CHART_TYPES = {
  PRODUCTION_PLOT: "Production Plot",
  TYPE_CURVE: "Type Curve",
  BAR: "Bar",
  SCATTER_PLOT: "Scatter Plot",
} as const;

export const CHARTS_FIELDS_TO_CHART_TYPE_MAPPER: { [key: string]: string } = {
  [CHART_TYPES.PRODUCTION_PLOT]: "PRODUCTION_PLOT",
  [CHART_TYPES.TYPE_CURVE]: "TYPE_CURVE",
  [CHART_TYPES.SCATTER_PLOT]: "scatter",
};

export const ANALYSIS_PANE_CHART_LIST: ChartType[] = [
  CHART_TYPES.PRODUCTION_PLOT,
  CHART_TYPES.TYPE_CURVE,
  CHART_TYPES.SCATTER_PLOT,
];

export const CHART_SCALING_TYPE = {
  LINEAR: "linear",
  LOGARITHMIC: "logarithmic",
} as const;

export const CHART_AXIS = {
  LEFT: "left",
  RIGHT: "right",
} as const;

export const CHART_ACCUMULATION_METHOD = {
  MONTHLY: "MONTHLY",
  CUMULATIVE: "CUMULATIVE",
} as const;

export const CHART_DATA_SOURCE = {
  PRODUCTION: "PRODUCTION",
  PRODUCTION_FORECAST: "PRODUCTION_FORECAST",
  PRODUCTION_UNION_FORECAST: "PRODUCTION_UNION_FORECAST",
  INJECTION: "INJECTION",
  VENTFLARE: "VENTFLARE",
} as const;

export const CHART_DATA_STATE = {
  POST_SEARCH: "post_search",
  PRE_SEARCH: "pre_search",
  INTER_SEARCH: "inter_search",
} as const;

export const CHART_STATUS = {
  EXPANDED: "expanded",
  COLLAPSED: "collapsed",
} as const;

export const CHART_GROUP_BY_TYPE = {
  INDIVIDUAL_WELLS: "NONE",
  DEFAULT: "Default",
} as const;

export const AGGREGATION_TYPE: { [key: string]: string } = {
  [CHART_TYPES.PRODUCTION_PLOT]: "sum",
  [CHART_TYPES.TYPE_CURVE]: "average",
};

export const MAX_INDIVIDUAL_WELLS_COUNT = 10;
export const INDIVIDUAL_WELLS_BATCH_LABEL = " (First 10)";

export const CHART_ALL_OTHERS_KEY = "all others";

export const PERMIT_CHARTS: string[] = [CHART_TYPES.SCATTER_PLOT];
