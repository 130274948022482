import MVT from "ol/format/MVT";
import VectorTileSource from "ol/source/VectorTile";

import { ConstructSource } from "../../types/map";

import { tileWMTSFetcher } from "./layers/mvtUtils";

const constructSource = (args: ConstructSource) => {
  const { config, params, authState, projection } = args;
  const token = authState?.accessToken?.accessToken;

  if (token) {
    const { url: baseUrl } = config.endpoints.tgsGWCWMTS;

    let url = `${baseUrl}?`;
    for (const param in params) {
      url = url + param + "=" + params[param] + "&";
    }
    url = url.slice(0, -1);
    const source = new VectorTileSource({
      url,
      format: new MVT(),
      tileLoadFunction: (tile, src) => {
        tileWMTSFetcher(tile, src, token);
      },
      projection,
      wrapX: true,
    });
    return source;
  }
};

export { constructSource };
