import { useEffect, useMemo } from "react";

import { ShareLocation as ShareLocationIcon } from "@mui/icons-material";
import { Box, Chip, Typography } from "@mui/material";

import { UWI_UPLOAD_EXTENSIONS } from "../../../../constants/constants";

import useUWISearchStore from "../../../../store/search/uwiSearch/searchUWIStore";

import useUwiFileUploadHandler from "../../../../customHooks/search/uwi/useUwiFileUploadHandler";

import ButtonUpload from "../../../common/ButtonUpload";
import UWIUploadProgress from "./UWIUploadProgress";

import UwiUploadIcon from "../../../../assets/CustomIcons";

const UWIUploadBox = () => {
  const uploadedUWIFile = useUWISearchStore((state) => state.uploadedUWIFile);
  const uploadFileProgress = useUWISearchStore(
    (state) => state.uploadFileProgress
  );
  const updateUploadFileProgress = useUWISearchStore(
    (state) => state.updateUploadFileProgress
  );
  const isLoadingUwiFileUpload = useUWISearchStore(
    (state) => state.isLoadingUwiFileUpload
  );
  const isFileFormatNotAllowed = useUWISearchStore(
    (state) => state.isFileFormatNotAllowed
  );
  const searchUWIText = useUWISearchStore((state) => state.searchUWIText);
  const isAutoConvertedFileFromText = useUWISearchStore(
    (state) => state.isAutoConvertedFileFromText
  );

  const { handleUpload, handleRemoveUpload } = useUwiFileUploadHandler();

  const showChip = useMemo(
    () =>
      !isAutoConvertedFileFromText &&
      !!uploadedUWIFile &&
      !isFileFormatNotAllowed,
    [isAutoConvertedFileFromText, uploadedUWIFile, isFileFormatNotAllowed]
  );

  const isUploadDisabled = useMemo(
    () => !!searchUWIText || !!uploadedUWIFile || isLoadingUwiFileUpload,
    [searchUWIText, isLoadingUwiFileUpload, uploadedUWIFile]
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isLoadingUwiFileUpload && uploadFileProgress !== 99) {
      intervalId = setInterval(() => {
        updateUploadFileProgress(uploadFileProgress + 1);
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isLoadingUwiFileUpload, uploadFileProgress]);

  return (
    <div className="upload-container">
      <div className="uwi-search">
        <div className="savedSearch-panel-uwi">
          <ShareLocationIcon className="uwi-location-icon" />
          <Typography className="uwi-upload-label">UWIs</Typography>
        </div>
        <Box
          className="uwi-chip-container"
          padding={uploadedUWIFile !== null ? "0 10px" : undefined}
        >
          {showChip ? (
            <Chip
              classes={{ root: "chip-root", label: "chip-label" }}
              label={uploadedUWIFile?.name}
              onDelete={() => handleRemoveUpload()}
              deleteIcon={
                isLoadingUwiFileUpload ? (
                  <UWIUploadProgress progress={uploadFileProgress} />
                ) : undefined
              }
            />
          ) : (
            <div className="uwi-search-list">
              <Box>
                <Typography variant="h6">
                  Upload .TXT, .CSV file formats
                </Typography>
              </Box>
            </div>
          )}
        </Box>
        <div className="uwi-upload-buttons-container">
          <ButtonUpload
            label="LOAD UWI FILE"
            buttonIcon={<UwiUploadIcon fontSize="small" />}
            disabled={isUploadDisabled}
            allowedFileExtension={UWI_UPLOAD_EXTENSIONS}
            handleFileChange={(evt) => {
              const files = evt.target.files;
              if (!files || files.length === 0) return;
              handleUpload(files[0]);
            }}
            buttonClasses={{ root: "load-button load-uwi-btn" }}
            buttonClickCallback={() => handleRemoveUpload()}
          />
        </div>
      </div>
    </div>
  );
};

export default UWIUploadBox;
