import { FC } from "react";

import TooltipInfoIcon from "./icons/TooltipInfoIcon";

interface TooltipInfoProps {
  text: string;
  position?: "top" | "bottom" | "left" | "right";
}

const TooltipInfo: FC<TooltipInfoProps> = ({ text, position }) => {
  return (
    <div
      className={`tooltip-info`}
      data-position={position || "top"}
      data-tool-tip={text}
    >
      <TooltipInfoIcon />
    </div>
  );
};

export default TooltipInfo;
