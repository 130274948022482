import { Map } from "ol";

import { WellData } from "../../../types/common/wells";
import { AllGridWellDataObj, DataGridSelector } from "../../../types/grid";
import { Feature } from "../../../types/map/layers/carto";

import {
  DYNAMIC_BOTTOM_WELL_SPOTS,
  DYNAMIC_PERMIT_SPOTS,
  WELL_PATHS,
  WELL_STICKS,
} from "../../../constants/constants";

import {
  formatUWI12ByCountry,
  getCountryByUWI,
  getWellIDByLayerName,
} from "../../common/wells";
import {
  getFormattedWellNameAndNumber,
  getFullWellNameAndNumber,
} from "../../helper";

export const getUWIByProperties = (uwi: string) => {
  const country = getCountryByUWI(uwi);
  return formatUWI12ByCountry(uwi, country);
  // return obj.properties.UWI
  //   ? obj.properties.UWI?.slice(0, 12)
  //   : obj.properties.UWI10
  //   ? obj.properties?.UWI10
  //   : obj.properties.UWI12?.slice(0, 12);
};

export const getIdByHeaderKey = (wellHeaderPermitKey: string) =>
  parseInt(wellHeaderPermitKey.slice(1));

export const getWellNameByProperties: (
  obj: Feature,
  allGridDataByUWI?: AllGridWellDataObj
) => string | undefined = (obj, allGridDataByUWI) => {
  const key = obj?.properties?.ParentWellID + (obj?.properties?.UWI ?? "");

  if (allGridDataByUWI && Object.keys(allGridDataByUWI).length) {
    const well = allGridDataByUWI[key];
    const name = well?.WellName;
    const number = well?.WellNumber;

    return getFormattedWellNameAndNumber(name, number);
  }
  return key;
};

export const getUWI10or12ByProperties: (
  obj: Feature,
  layerName: string
) => string | undefined = (obj, layerName) => {
  switch (layerName) {
    case DYNAMIC_BOTTOM_WELL_SPOTS:
    case WELL_PATHS:
      return obj.properties.UWI12 ?? obj.properties.UWI?.slice(0, 12);
    case WELL_STICKS:
      return obj.properties.UWI?.slice(0, 12);
    default:
      return obj.properties.UWI10 ?? obj.properties.UWI?.slice(0, 10);
  }
};

export const getWellNameByPropertiesAndDataGrid: (
  obj: Feature,
  dataGridSelector: DataGridSelector,
  layerName: string
) => string | undefined = (obj, dataGridSelector, layerName) => {
  const { groupedByUWID10, groupedByUWID12 } = dataGridSelector;
  const shouldUseUWI12 = [
    DYNAMIC_BOTTOM_WELL_SPOTS,
    WELL_STICKS,
    WELL_PATHS,
  ].includes(layerName);

  const uwi = getUWI10or12ByProperties(obj, layerName) ?? "";

  const key = obj?.properties?.ParentWellID + uwi;

  const selector = shouldUseUWI12 ? groupedByUWID12 : groupedByUWID10;

  if (uwi && selector[key])
    return getFullWellNameAndNumber(
      selector[key].WellName,
      selector[key].WellNumber
    );
  return undefined;
};

export const getWellDataByPropertiesAndDataGrid: (
  obj: Feature,
  dataGridSelector: DataGridSelector,
  layerName: string
) => WellData | undefined = (obj, dataGridSelector, layerName) => {
  const {
    groupedByBottomWellboreID,
    groupedByParentWellID,
    bottomWellboreIDByPWID,
    byPermitID,
  } = dataGridSelector;
  const shouldUseBottomWellboreID = [
    DYNAMIC_BOTTOM_WELL_SPOTS,
    WELL_STICKS,
    WELL_PATHS,
  ].includes(layerName);

  if (obj?.properties) {
    const key = getWellIDByLayerName(layerName, obj.properties);
    if (!key) return undefined;

    if (layerName === DYNAMIC_PERMIT_SPOTS) {
      return byPermitID[key];
    }

    const selector = shouldUseBottomWellboreID
      ? groupedByBottomWellboreID
      : groupedByParentWellID;

    if (selector[key]) {
      if (shouldUseBottomWellboreID) {
        return selector[key];
      }

      if (obj.properties.ParentWellID)
        return {
          ...selector[key],
          groupedBWIDs:
            bottomWellboreIDByPWID[obj.properties.ParentWellID] ?? [],
        };
    }
  }
  return undefined;
};

export const getMapLayerGroupsByValue = (map: Map, value: string) => {
  return map
    ?.getLayers()
    .getArray()
    .map((layerGroup) => layerGroup.get(value));
};

export const onlyUnique = (value: any, index: number, array: any) => {
  return array.indexOf(value) === index;
};
