import { useEffect, useState } from "react";

import usePanelsStore from "../../store/panels/panelsStore";

export const useContainerDimensions = (myRef: React.MutableRefObject<any>) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const isOpenAppLevelNav = usePanelsStore((state) => state.isOpenAppLevelNav);
  const isOpenLeftSidePanel = usePanelsStore(
    (state) => state.isOpenLeftSidePanel
  );
  const isOpenAnalysisPanel = usePanelsStore(
    (state) => state.isOpenAnalysisPanel
  );

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef?.current?.offsetWidth ?? 0,
      height: myRef?.current?.offsetHeight ?? 0,
    });

    const handleResize = () => {
      setDimensions(getDimensions());
    };

    if (myRef?.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef, isOpenAppLevelNav, isOpenLeftSidePanel, isOpenAnalysisPanel]);

  return dimensions;
};
