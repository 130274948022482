import { useEffect, useRef } from "react";

import { Alert } from "component-library";

import { setTimeoutType } from "../../types/common/general";

import useAppModeStore from "../../store/common/appModeStore";
import useAlertPopupStore from "../../store/map/alert/alertStore";
import usePanelsStore from "../../store/panels/panelsStore";

const AlertBoxes = () => {
  const alertState = useAlertPopupStore((state) => state.alertState);

  const updateAlertState = useAlertPopupStore(
    (state) => state.updateAlertState
  );

  //Well Panel Alert
  const showWellPanelAlertMessage = usePanelsStore(
    (state) => state.showAlertMessage
  );
  const toggleAlertMessage = usePanelsStore(
    (state) => state.toggleAlertMessage
  );

  //Chart Export Alert
  const showExportMessage = usePanelsStore((state) => state.showExportMessage);
  const showExportPlotMessage = usePanelsStore(
    (state) => state.showExportPlotMessage
  );
  const toggleExportPlotMessage = usePanelsStore(
    (state) => state.toggleExportPlotMessage
  );
  const toggleExportMessage = usePanelsStore(
    (state) => state.toggleExportMessage
  );

  //UoM Alert Message
  const UoMAlertState = useAppModeStore((state) => state.UoMAlertState);
  const updateUoMAlertState = useAppModeStore(
    (state) => state.updateUoMAlertState
  );

  const setTimeOutAlertRef = useRef<setTimeoutType | null>(null);

  useEffect(() => {
    if (alertState?.message) {
      const timeout = alertState.timeout ?? 5000;
      if (setTimeOutAlertRef.current) {
        clearTimeout(setTimeOutAlertRef.current);
        setTimeOutAlertRef.current = null;
      }

      setTimeOutAlertRef.current = setTimeout(() => {
        updateAlertState(undefined);
      }, timeout);
    }
  }, [alertState]);

  return (
    <div className="alert-box">
      <Alert
        message={alertState?.message ?? ""}
        severity={alertState?.severity ?? "default"}
        isHidden={!Boolean(alertState?.message && alertState.severity)}
        onClose={() => {
          updateAlertState(undefined);
        }}
      />

      <Alert
        message="Maximum of 10 well panels are open at the same time."
        severity="warning"
        isHidden={!showWellPanelAlertMessage}
        onClose={() => {
          toggleAlertMessage(false);
        }}
      />

      <Alert
        message="Exporting Well Data to Excel"
        severity="success"
        isHidden={!showExportMessage}
        onClose={() => {
          toggleExportMessage(false);
        }}
      />
      <Alert
        message="Exporting Charts to Image"
        severity="info"
        isHidden={!showExportPlotMessage}
        onClose={() => {
          toggleExportPlotMessage(false);
        }}
      />

      {/* UoM Alert Messages */}
      <Alert
        message={UoMAlertState?.message ?? ""}
        severity={UoMAlertState?.severity ?? "default"}
        isHidden={!Boolean(UoMAlertState)}
        onClose={() => {
          updateUoMAlertState(undefined);
        }}
      />
    </div>
  );
};

export default AlertBoxes;
