import {
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";

import { Accordion as CLAccordion } from "component-library";

import { MapScaleBarUnit } from "../../types/map/scaleBar";

import { MAP_SCALE_BAR } from "../../constants/constants";
import { baseMapLayer } from "../../constants/map/layers";
import { MAP_SCALE_BAR_UNITS } from "../../constants/map/mapSettings";

import useMapStore from "../../store/map/mapStore";

import useSagaMap from "../../customHooks/map/useSagaMap";

import Accordion from "../common/Accordion";
import AccordionSection from "../common/AccordionSection";
import RadioButtonCheckedIcon from "../common/icons/RadioButtonCheckedIcon";
import RadioButtonUncheckedIcon from "../common/icons/RadioButtonUncheckedIcon";

interface CustomRadioLabelProps {
  title: string;
  imgSrc: string;
}

const CustomRadioLabel: React.FC<CustomRadioLabelProps> = ({
  title,
  imgSrc,
}) => (
  <div className="layer-image">
    <img alt={title} src={imgSrc} />
    <Tooltip title={title}>
      <Typography>{title}</Typography>
    </Tooltip>
  </div>
);

const BaseMapAccordion = () => {
  const { updateBaseMap } = useSagaMap();

  const layers = useMapStore((state) => state.layers);
  const toggleLayer = useMapStore((state) => state.toggleLayer);

  const baseMapSelected = useMapStore((state) => state.baseMapSelected);
  const setBaseMapSelected = useMapStore((state) => state.setBaseMapSelected);

  const mapScaleBarUnit = useMapStore((state) => state.mapScaleBarUnit);
  const updateMapScaleBarUnit = useMapStore(
    (state) => state.updateMapScaleBarUnit
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    updateBaseMap(event.target.value);
    setBaseMapSelected(event.target.value);
  };

  return (
    <CLAccordion title="Base Map Style" className="cl-custom-accordion">
      <AccordionSection
        title="Map Scale Bar"
        isSelected={
          !!layers.find((layer) => layer.name === MAP_SCALE_BAR)?.isSelected
        }
        onSelect={() => toggleLayer(MAP_SCALE_BAR)}
        hasCheckbox
      >
        <FormControl className="custom-select">
          <InputLabel id="map-scale-bar-unit">Measuring Unit</InputLabel>
          <Select
            labelId="map-scale-bar-unit"
            label="Measuring Unit"
            value={mapScaleBarUnit}
            onChange={(e) =>
              updateMapScaleBarUnit(e.target.value as MapScaleBarUnit)
            }
            MenuProps={{
              className: "custom-color-select-menu",
            }}
          >
            {MAP_SCALE_BAR_UNITS.map((unit) => (
              <MenuItem key={unit.key} value={unit.key}>
                {unit.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AccordionSection>
      <FormControl className="base-map-container">
        <RadioGroup value={baseMapSelected} onChange={handleChange}>
          {baseMapLayer.map((layer) => (
            <FormControlLabel
              key={layer.id}
              value={layer.id}
              control={
                <Radio
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={<RadioButtonCheckedIcon />}
                />
              }
              label={
                <CustomRadioLabel title={layer.title} imgSrc={layer.imgSrc} />
              }
            />
          ))}
        </RadioGroup>
      </FormControl>
    </CLAccordion>
  );
};

export default BaseMapAccordion;
