import { FC, SVGProps } from "react";

interface SearchMagnifyingGlassIconProps extends SVGProps<SVGAElement> {
  pathFill?: string;
}

const SearchMagnifyingGlassIcon: FC<SearchMagnifyingGlassIconProps> = ({
  width = "20",
  height = "15",
  fill = "none",
  pathFill = "#FFFFFF",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.666748 11.6966V10.0299H9.00008V11.6966H0.666748ZM0.666748 7.52995V5.86328H4.83341V7.52995H0.666748ZM0.666748 3.36328V1.69661H4.83341V3.36328H0.666748ZM16.1667 11.6966L12.9584 8.48828C12.6251 8.72439 12.2605 8.90147 11.8647 9.01953C11.4688 9.13759 11.0695 9.19661 10.6667 9.19661C9.51397 9.19661 8.53133 8.79036 7.71883 7.97786C6.90633 7.16536 6.50008 6.18273 6.50008 5.02995C6.50008 3.87717 6.90633 2.89453 7.71883 2.08203C8.53133 1.26953 9.51397 0.863281 10.6667 0.863281C11.8195 0.863281 12.8022 1.26953 13.6147 2.08203C14.4272 2.89453 14.8334 3.87717 14.8334 5.02995C14.8334 5.43273 14.7744 5.83203 14.6563 6.22786C14.5383 6.6237 14.3612 6.98828 14.1251 7.32161L17.3334 10.5299L16.1667 11.6966ZM10.6667 7.52995C11.3612 7.52995 11.9515 7.28689 12.4376 6.80078C12.9237 6.31467 13.1667 5.72439 13.1667 5.02995C13.1667 4.3355 12.9237 3.74523 12.4376 3.25911C11.9515 2.773 11.3612 2.52995 10.6667 2.52995C9.9723 2.52995 9.38203 2.773 8.89591 3.25911C8.4098 3.74523 8.16675 4.3355 8.16675 5.02995C8.16675 5.72439 8.4098 6.31467 8.89591 6.80078C9.38203 7.28689 9.9723 7.52995 10.6667 7.52995Z"
        fill={pathFill}
      />
    </svg>
  );
};

export default SearchMagnifyingGlassIcon;
