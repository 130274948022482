import { FC, SVGProps } from "react";

interface TotalVerticalDepthIconProps extends SVGProps<SVGAElement> {
  pathStroke?: string;
}

const TotalVerticalDepthIcon: FC<TotalVerticalDepthIconProps> = ({
  width = "12",
  height = "10",
  fill = "none",
  pathStroke = "#9C9C9C",
}) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 12 10"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.751953 9H10.752M2.25195 1H9.25195M1.25195 7.005L1.25695 6.9995M10.257 7.005L10.252 6.9995M1.75195 5.005L1.75695 4.9995M9.75695 5.005L9.75195 4.9995M2.25195 3.005L2.25695 2.9995M9.25695 3.005L9.25195 2.9995M5.75195 2.5V7.5M5.75195 2.5L5.00195 3.25M5.75195 2.5L6.50195 3.25M5.75195 7.5L4.25195 6M5.75195 7.5L7.25195 6"
        stroke={pathStroke}
        strokeWidth="0.75"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TotalVerticalDepthIcon;
