import { useEffect, useMemo, useRef } from "react";

import {
  SectionsRefType,
  WellInfoTabProps,
} from "../../../../types/panels/wellPanel/wellInfoTab";
import { WellPermitPanelSection } from "../../../../types/panels/wellPanel/wellPanel";

import config from "../../../../configs/appSettings";

import {
  WELL_PANEL_SECTION,
  WELL_PANEL_TAB,
} from "../../../../constants/panels/wellPanel/wellInfo";

import usePanelsStore from "../../../../store/panels/panelsStore";

import { wellPanelSectionFlags } from "../../../../utils/panel/wellPanel/sectionFlags";

import CustomTabPanel from "../../../common/CustomTabPanel";
import Casing from "../sections/Casing";
import CompletionData from "../sections/CompletionData";
import DeclineCurveParameters from "../sections/DeclineCurveParameters";
import Formation from "../sections/Formation";
import Header from "../sections/Header";
import Perforation from "../sections/Perforation";
import Permit from "../sections/Permit";
import PressureData from "../sections/PressureData";
import Statistics from "../sections/Statistics";
import Test from "../sections/Test";
import WellEconomics from "../sections/WellEconomics";

const WellInfoTab = ({
  hidden,
  wellSystem,
  activePage,
  groupedWellID,
  layer,
}: WellInfoTabProps) => {
  const sectionsRef = useRef<SectionsRefType>({
    [WELL_PANEL_SECTION.WELL_ECONOMICS_SECTION.key]: null,
  });
  const updateWellPanelActiveSectionByKey = usePanelsStore(
    (state) => state.updateWellPanelActiveSectionByKey
  );
  const parentPosition =
    document.getElementById("well-panel-wrapper")?.offsetTop ?? 0;
  const section = useMemo(() => {
    return wellPanelSectionFlags(wellSystem);
  }, [wellSystem]);

  const handleOnChange = (section: WellPermitPanelSection) => {
    updateWellPanelActiveSectionByKey(
      layer,
      groupedWellID,
      wellSystem.wellId,
      section
    );
  };

  //opening of well economics section can be triggered from footer
  useEffect(() => {
    if (wellSystem.pageSectionStatus.isWellEconomicsOpen) {
      const econDiv = document.getElementById(
        `well-economics-wrapper-${wellSystem.wellId}`
      )?.offsetTop;
      setTimeout(() => {
        if (econDiv)
          document
            .querySelector(`#well-panel-wrapper-${wellSystem.wellId}`)
            ?.scrollTo({ top: econDiv - parentPosition, behavior: "smooth" });
      }, 500);
    }
  }, [wellSystem.pageSectionStatus.isWellEconomicsOpen]);

  return (
    <CustomTabPanel
      hidden={hidden}
      name={WELL_PANEL_TAB.WELL_INFO_TAB}
      id={wellSystem.wellId}
    >
      <Header
        data={wellSystem.wellCardData}
        isExpanded={wellSystem.pageSectionStatus.isHeaderSectionOpen}
        onChange={(section) => handleOnChange(section)}
      />

      {section.hasStatistics && (
        <Statistics
          data={wellSystem.wellCardData}
          isExpanded={wellSystem.pageSectionStatus.isStatisticsSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}

      {section.hasForecast && (
        <DeclineCurveParameters
          data={wellSystem.wellCardData}
          isExpanded={wellSystem.pageSectionStatus.isDeclineCurveParametersOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}

      {section.hasPerforation && (
        <Perforation
          wellData={wellSystem}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          isExpanded={wellSystem.pageSectionStatus.isPerfSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
      {section.hasTesting && (
        <Test
          wellData={wellSystem}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          isExpanded={wellSystem.pageSectionStatus.isTestSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
      {section.hasDST && (
        <PressureData
          wellData={wellSystem}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          isExpanded={wellSystem.pageSectionStatus.isPressureDataOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}

      {section.hasCompletion && (
        <CompletionData
          wellData={wellSystem.wellCardData}
          isExpanded={wellSystem.pageSectionStatus.isCompletionSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
      {section.hasCasing && (
        <Casing
          wellData={wellSystem}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          isExpanded={wellSystem.pageSectionStatus.isCasingSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
      {section.hasFormation && (
        <Formation
          wellData={wellSystem}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          isExpanded={wellSystem.pageSectionStatus.isFormationSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
      {section.hasPermit && (
        <Permit
          wellData={wellSystem}
          activePage={activePage}
          groupedWellID={groupedWellID}
          layer={layer}
          isExpanded={wellSystem.pageSectionStatus.isPermitSectionOpen}
          onChange={(section) => handleOnChange(section)}
        />
      )}
      {config.isWellEconomicsEnabled && section.hasWellEconomics && (
        <WellEconomics
          wellData={wellSystem}
          activePage={activePage}
          layer={layer}
          groupedWellID={groupedWellID}
          isExpanded={wellSystem.pageSectionStatus.isWellEconomicsOpen}
          onChange={(section) => handleOnChange(section)}
          handleRef={(el) =>
            (sectionsRef.current[
              WELL_PANEL_SECTION.WELL_ECONOMICS_SECTION.key
            ] = el)
          }
        />
      )}
    </CustomTabPanel>
  );
};

export default WellInfoTab;
