import { create } from "zustand";

import { ExportMapStates } from "../../types/exports/exportMap/exportMapStore";

import { exportMapSlice } from "./exportMapSlice";

const useExportMapStore = create<ExportMapStates>()((...a) => ({
  ...exportMapSlice(...a),
}));

export default useExportMapStore;
