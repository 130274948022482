import { PermitPanelInfo } from "../../../types/panels/permitPanel/permitPanel";

import useExportPanelStore from "../../../store/exportPanel/exportPanelStore";
import usePanelsStore from "../../../store/panels/panelsStore";

const usePermitPanelHeader = () => {
  const selectedCardPermitIDs = usePanelsStore(
    (state) => state.selectedCardPermitIDs
  );
  const removeSelectedPermitCardByID = usePanelsStore(
    (state) => state.removeSelectedPermitCardByID
  );
  const removePermitPanelByKey = usePanelsStore(
    (state) => state.removePermitPanelByKey
  );

  const updateExportPermitData = useExportPanelStore(
    (state) => state.updateExportPermitData
  );

  const handleClosePermitPanel = (permitInfo: PermitPanelInfo) => {
    removePermitPanelByKey(permitInfo.wellHeaderPermitKey);

    if (selectedCardPermitIDs.includes(permitInfo.permitID)) {
      removeSelectedPermitCardByID(permitInfo.permitID);
    }
  };

  const handleOnExportPermitClick = (
    permitInfo: PermitPanelInfo,
    wellHeaderPermitKey: string
  ) => {
    if (permitInfo.permitSystemData?.permitData)
      updateExportPermitData({
        permitPanelActiveInfo: permitInfo.permitSystemData.permitData,
        permitPanelActiveWellHeaderPermitKey: wellHeaderPermitKey,
        isOpenExportPermitPanelDialog: true,
        permitPanelActiveRecordType: permitInfo.recordType,
      });
  };

  return {
    handleClosePermitPanel,
    handleOnExportPermitClick,
  };
};

export default usePermitPanelHeader;
