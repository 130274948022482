import { LayerObject } from "../../../types/map/hookTemp";
import { WellSelectedForWellPanel } from "../../../types/map/layers/carto";

import { BUBBLE_MAP } from "../../../constants/constants";
import { SELECTED_FOR_WELLCARD_MULTIPLIER } from "../../../constants/map/cartoStyles";

const getSelectedLayerNames = (layers: LayerObject[]) =>
  layers.filter((layer) => layer.isSelected).map((layer) => layer.name);

const isWellSelectedForWellPanel = ({
  f,
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
}: WellSelectedForWellPanel) => {
  const { BottomWellboreID, ParentWellID: PWID } = f.properties;

  // if surface, check if PWID is selected
  // if bottom, check if BottomWellboreID is selected
  if (
    (PWID && selectedWellCardPWIDs?.includes(PWID)) ||
    (BottomWellboreID && selectedWellCardBWIDs?.includes(BottomWellboreID))
  ) {
    return true;
  }

  return false;
};

const getWellCardSelectionMultiplier = ({
  f,
  selectedWellCardPWIDs,
  selectedWellCardBWIDs,
  layerName,
}: WellSelectedForWellPanel) => {
  const isSelected = isWellSelectedForWellPanel({
    f,
    selectedWellCardPWIDs,
    selectedWellCardBWIDs,
  });

  if (isSelected && layerName !== BUBBLE_MAP)
    return SELECTED_FOR_WELLCARD_MULTIPLIER;
  return 1;
};

export {
  getSelectedLayerNames,
  getWellCardSelectionMultiplier,
  isWellSelectedForWellPanel,
};
