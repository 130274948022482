import { FC, MouseEvent, useState } from "react";

import { MenuItem, Popover } from "@mui/material";
import { Theme, ThemeProvider, createTheme } from "@mui/material/styles";

import config from "../../configs/appSettings";

import { HELP, NEW_TAB } from "../../constants/constants";
import {
  DATA_CURRENCY_MENU,
  RELEASE_NOTES_TITLE,
} from "../../constants/dataCurrency";

import useDataCurrencyStore from "../../store/dataCurrencies/dataCurrencyStore";
import useReleaseNotesStore from "../../store/releaseNotes/releaseNotesStore";

import CustomCircularButton from "../common/CustomCircularButton";
import DataCurrencyTable from "./DataCurrency/DataCurrencyTable";
import InformationTabs from "./InformationTabs";

import { DataCurrencyIcon } from "../../assets/CustomIcons";

const theme: Theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          width: 34,
          height: 34,
          cursor: "pointer",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: "#121212",
          color: "#fff",
          cursor: "pointer",
          boxShadow:
            "0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%)",
          backgroundImage:
            "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
          width: 250,
          padding: "6px 0",
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontSize: 13,
          display: "flex",
          alignItems: "center",
          height: 32,
        },
      },
    },
  },
});

const Informations: FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const isOpenDataCurrencyTable = useDataCurrencyStore(
    (state) => state.isOpenDataCurrencyTable
  );

  const updateIsOpenDataCurrencyTable = useDataCurrencyStore(
    (state) => state.updateIsOpenDataCurrencyTable
  );

  const updateIsOpenReleaseNotes = useReleaseNotesStore(
    (state) => state.updateIsOpenReleaseNotes
  );
  const isOpenReleaseNotes = useReleaseNotesStore(
    (state) => state.isOpenReleaseNotes
  );

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    !isOpenDataCurrencyTable &&
      !isOpenReleaseNotes &&
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <ThemeProvider theme={theme}>
      <CustomCircularButton
        width={36}
        height={36}
        isActive={open || isOpenDataCurrencyTable || isOpenReleaseNotes}
        onClick={handleClick}
      >
        <DataCurrencyIcon
          fill="#2E2E2E"
          fillOpacity={open ? 1 : 0.7}
          viewBox="0 0 34 34"
        />
      </CustomCircularButton>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className="header-menu information"
      >
        <MenuItem
          onClick={() => {
            updateIsOpenDataCurrencyTable(true);
            handleClose();
          }}
        >
          {DATA_CURRENCY_MENU}
        </MenuItem>
        <MenuItem
          onClick={() => {
            updateIsOpenReleaseNotes(true);
            handleClose();
          }}
        >
          {RELEASE_NOTES_TITLE}
        </MenuItem>
        {config.isDownloadableMaterialsEnabled && (
          <MenuItem
            onClick={() => {
              window.open(config.DOWNLOADABLE_MATERIALS_URL, NEW_TAB);
              handleClose();
            }}
          >
            {HELP}
          </MenuItem>
        )}
        {/* Unused, but saving for reference */}
        {/* {config.isWDAHelpEnabled && (
          <MenuItem
            onClick={() => {
              window.gtag("event", "page_view", {
                page_title: config.roboHelpPageViewTitle,
                page_path: "/static/help/Log_in_to_Well_Data_Analytics.htm",
              });

              window.open(HELP_HTML_PATH, NEW_TAB);
              handleClose();
            }}
          >
            {HELP}
          </MenuItem>
        )}
        {config.isDataDictionaryEnabled && (
          <MenuItem
            onClick={() => {
              window.open(DATA_DICTIONARY_HTML_PATH, NEW_TAB);
              handleClose();
            }}
          >
            {DATA_DICTIONARY}
          </MenuItem>
        )} */}
      </Popover>
      {isOpenDataCurrencyTable && <DataCurrencyTable />}
      {isOpenReleaseNotes && <InformationTabs />}
    </ThemeProvider>
  );
};

export default Informations;
