import { useState } from "react";

import axios from "axios";

import {
  UploadShapeFilePayload,
  UploadShapeFileResponse,
} from "../../../types/map/customHooks/useUploadShapeFile";
import { Polygon } from "../../../types/map/gis/common";

import config from "../../../configs/appSettings";

import { getGeometryFromPolygonArray } from "../../../data/map/mapUtils";

const useUploadShapeFile = () => {
  const [data, setData] = useState<string | null>(null);
  const [error, setError] = useState<unknown>(null);
  const [isLoading, setIsLoading] = useState(false);

  const uploadShapeFile = async (polygons: Polygon) => {
    setIsLoading(true);
    setData(null);
    setError(null);

    const url = `${config.endpoints.wellService}api/shape-file`;

    const geometry = getGeometryFromPolygonArray(polygons);

    if (!geometry) {
      setIsLoading(false);
      return;
    }

    const body: UploadShapeFilePayload = { geometry };

    try {
      const response = await axios.post<UploadShapeFileResponse>(url, body, {
        ignoreResponseInterceptor: true,
      });

      const { shapeId } = response.data;
      setIsLoading(false);
      setData(shapeId);
    } catch (err: unknown) {
      const error = err as Error;
      console.debug("uploadShapeFile error", error);
      setError(error?.message);
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    data,
    error,
    uploadShapeFile,
  };
};

export default useUploadShapeFile;
